import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import { ReactComponent as accessManagementImg } from 'assets/img/menu/ic_settings.svg'
import { formatFIO } from 'app/core/utility/common'
import UserRequisitionBlock from './UserRequsitionBlock'
import UserRolesBlock from './UserRolesBlock'
import useErrors from '../../../hooks/useErrors'


export default function NewUser(props){

  const history = useHistory()

  const [fio, setFio] = useState({ lastName: null, firstName: null, middleName: null })
  const [isADUser, setIsADUser] = useState(false)

  useErrors()

  const items = [
    {
      disabled: false,
      ImgComponent: accessManagementImg,
      link: '/accessManagement/users'
    },
    {
      breadcrumbsTitle: true,
      disabled: false,
      headerTitle: `Учётная запись: ${formatFIO(fio)}`,
      id: 'accessManagement/users/create',
      link: '/accessManagement/users/create',
      name: 'userCreate',
      resourceRight: 'CREATE',
      title: 'Учетные записи'
    }
  ]

  return (
    <section className="user-card">
      <BreadcrumbsHeader customItems={{items}} history={history}/>
      <div className="user-card-area">
        <UserRequisitionBlock fio={fio} setFio={setFio} isADUser={isADUser} setIsADUser={setIsADUser}/>
        <UserRolesBlock isADUser={isADUser}/>
      </div>
    </section>
  )
}