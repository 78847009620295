import React from 'react'

import Input from 'app/components/ui/Input'
import TextArea from 'app/components/ui/textArea'
import DatePicker from 'app/components/ui/date'
import { CardState } from 'app/components/ui/card/cardMaterial'

import { notEmpty } from 'app/core/utility/validation'

export const cardLines = {
  name: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field card-name'
          label = 'Наименование'
          readOnly={cardState === CardState.view}
          value={editValue}
          onChange={value => onChange && onChange('name', value)}
          error={error}
        />
      )
    },
  },
  createDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, error } = props

      return (
        <DatePicker
          className='card-field card-create-date'
          label='Дата добавления'
          error={error}
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  description: {
    isRequired: () => false,
    renderEdit: (props) => {
      const { cardState, editValue, onChange, error } = props

      return (
        <TextArea 
          className='card-field card-description'
          label='Описание'
          readOnly={cardState === CardState.view}
          value={editValue} 
          error={error}
          onChange={value => onChange && onChange('description', value)}
        />
      )
    },
  },   
  
}