import React from "react";
import withTooltip from 'app/components/HOC/TooltipHOC'
import TagComponent from 'app/components/ui/tag'
import {useParams} from "react-router-dom";
import {formatDate} from "../../../core/utility/date";

const Tag = withTooltip(TagComponent)

export default function tableConfig({currentlyDeletingFiles, onFileDelete, urlPart, title, alias, fileRights}){

  return [
    { 
      title : 'Дата добавления',
      alias : 'createDate', 
      format : item => {
        const { createDate } = item
  
        return formatDate(createDate, 'dd.mm.yyyy')
      },
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 250
    },
    { 
      title : title || 'Вложения',
      alias : alias || 'content', 
      format : item => {
        return (
          (
            <div className="type-files">
              {currentlyDeletingFiles[item.guid] ? (
                <Tag
                  key={item.guid}
                  className='deleting-file-tag'
                  text={'Удаление...'}
                  tooltip={item.name}
                  item={item}
                />
              ) : (
                <Tag
                  key={item.guid}
                  text={item.name}
                  tooltip={item.name}
                  link={`api/v1/${urlPart}/${item.guid}`}
                  item={item}
                  onDelete={fileRights.DELETE 
                    ? () => onFileDelete([item]) 
                    : null}
                />
              )}
            </div>
          )
        )
      },
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : false, 
    }
  ]
}