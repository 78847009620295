import React from 'react'
import { flattenMenuContent } from '../navigation/navigationContent'


export const compileFirstBreadcrumbs = path => {
  const mainPageBC = {
    title: 'Главная',
    link: '/',
    headerTitle: 'Главная',
    disabled: true,
    breadcrumbsTitle: true
  }

  const bc = path.split('/').filter(item => !!item).reduce((acc, cur) => {
    const pathPart = '/' + cur;
    const prevPart = acc[acc.length-1];

    return [...acc, `${prevPart ? prevPart : ''}${pathPart}`]
  }, [])

  const rr = [ mainPageBC, ...bc]
         .filter(item=>!!flattenMenuContent[item])

  return rr
         .map((item) => {
            const { breadcrumbsTitle = true, disabled = false } = flattenMenuContent[item]
            return { ...flattenMenuContent[item], disabled, breadcrumbsTitle }
          })
}

export const getPageBreadcrumb = (path, title) => {
  let bcData = flattenMenuContent[path]
  const { title: bcTitle, link, headerTitle } = bcData || {}
  const currentBreadcrumb = bcData 
                            ? {
                                title: bcTitle,
                                link: link,
                                headerTitle: headerTitle,
                                disabled: false,
                                breadcrumbsTitle: true,
                            }
                            : {
                                title,
                                link: path,
                                headerTitle: title,
                                disabled: false,
                                breadcrumbsTitle: true,
                              }

  const compiled = compileFirstBreadcrumbs(path)
  const alreadyIn = compiled.find(item => {
    return item.link === currentBreadcrumb.link 
  })

  return {
    firstBreadcrumbs: alreadyIn || !title ? compiled : [ ...compiled, currentBreadcrumb ],
    current: currentBreadcrumb
  }
}

const renderBreadcrumb = ({ title, headerTitle, disabled, breadcrumbsTitle, lastPart, link },onClick) => {
  return breadcrumbsTitle ? (
    <div key ={link} className="br-item">
      <span className="item-separator">/</span>
      {disabled || lastPart ? (
          <span className="br-item__title">{headerTitle || title}</span>
        ) : (
          <span key = {link} className="br-item__title br-item__link" onClick={() => onClick(link)}>{headerTitle || title}</span>
        )
      }
    </div>
  ) : null
}

const breadcrumbs = props => {
  const { breadcrumbs, onClick } = props
  const { items } = breadcrumbs

  return null;

  return (
    <div className='breadcrumbs-container'>
      <div className="br-item">
        <span className="br-item__title">Главная</span>
      </div>
      {
        items.map((item, i) => {
          const lastPart = items.length === i + 1
      
          return (
            renderBreadcrumb({ ...item, lastPart }, onClick)
          )
        })
      }
    </div>
  )
}

export default breadcrumbs