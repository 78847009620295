import React, {useEffect, useRef, useState} from "react";
import withModal from "../../../HOC/ModalHOC";
import FunctionalCard from "../../../ui/card/FunctionalCard";
import useFunctionalCard, {CardState} from "../../../ui/card/FunctionalCard/useFunctionalCard";
import Button from 'app/components/ui/button/button'
import {checkDatesRange, notEmpty} from "../../../../core/utility/validation";
import EditForm from "./EditForm";
import {fileControlState} from "../../../ui/file/fileUploadControl";
import FileUploadControl from 'app/components/ui/file/fileUploadControl'
import {useParams} from "react-router-dom";
import {fileListAPI} from "../../../../core/utility/file";

const AddModalCard = withModal(FunctionalCard)
export default function AddModal({onCancel, onAdd, onAddFinish, certificationsTypes}){
  const get = () => Promise.resolve({
    data: {
      type: null,
      number: '',
      certificateStartDate: null,
      certificateExpirationDate: null,
    }})

  const idRef = useRef()
  const formData = {validationRules: certificateValidationRules, defaultState: defaultCertificateFormState}

  const {
    cardState,
    fieldsError,
    onFormChange,
    validate,
    commonDataForm
  } = useFunctionalCard({formData, get, parentEditState: true})

  const [dialogState, setDialogState] = useState(fileControlState.preUpload)
  const [files, setFiles] = useState([])
  const [certId, setCertId] = useState(null)

  const { skziId, versionId, buildId } = useParams()

  const onFileChange = (files) => {
    setFiles(files)
  }

  const uploadFinished = (result) => {
    setDialogState(fileControlState.uploadFinished)
    onAddFinish({data:result, item: {id: idRef.current}})
  }

  function sendFilesAfterAdd(response){
    const { data, errors, isError } = response || {}
    if(data && !isError){
      if(data.id){
        idRef.current = data.id
        const urlPart = `skzi/${skziId}/version/${versionId}/build/${buildId}/certificates/certificate/${data.id}/file/upload`

        const fileApi = fileListAPI(
          {},
          urlPart
        )
        uploader._upload && uploader._upload(fileApi)
      }
    }
  }
  const [uploader, setUploader] = useState({})

  return (
    <AddModalCard
      renderHeader = { () => <div className='modal__header'>Добавление сертификата соответствия</div> }
      cardState={CardState.edit}
      canEdit={true}
      editOnly={true}
      className="skzi-build__certificate-card"
      editState={true}
      onCancel={onCancel}
    >
      <div className="card-material__content">
        <EditForm
          certificationsTypes={certificationsTypes}
          cardState={cardState}
          formData={commonDataForm}
          serverFormViewModel={null}
          onChange={onFormChange}
          errorByFields={fieldsError}
        />
        <FileUploadControl
          setUploader={setUploader}
          className='add-type-dialog__file-area add-certificate__file-area'
          title='Добавить вложение'
          dialogState={dialogState}
          onFileChanged={onFileChange}
          // onUploadFinish={uploadFinished}
          onFileUploaded={uploadFinished}
          onAllFilesProgressComplete={() => {}}
        />
      </div>
      <div className="buttons-panel">
        <Button
          type='text'
          onClick={() => {
            if(validate()){
              return false
            }
            onAdd(commonDataForm).then(sendFilesAfterAdd)
          }}
        >
          <span className='button-title'>Сохранить</span>
        </Button>
        <Button
          type='text'
          onClick={onCancel}
        >
          <span className='button-title'>Отмена</span>
        </Button>
      </div>
    </AddModalCard>
  )
}



export const certificateValidationRules = {
  type: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  number: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  certificateStartDate: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  certificateExpirationDate: {
    isRequired: () => false,
    validation: (startDateVal, expirationDateVal) => {
      if (startDateVal) {
        return checkDatesRange(startDateVal, expirationDateVal);
      } else {
        return {
          valid: true,
          error: null,
        };
      }
    },
  },
}

export const defaultCertificateFormState = {
  commonDataForm : {
    type: {},
    number: '',
    certificateStartDate: null,
    certificateExpirationDate: null,
  },
  viewData: {
    type: {},
    number: '',
    certificateStartDate: null,
    certificateExpirationDate: null,
  }
}