import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'

import { cardLines } from './admittanceData'
import Card from 'app/components/ui/card/cardMaterial'

import Overlay from 'app/components/ui/overlay'
import ConfirmDialog from 'app/components/dialog/confirmDialog/'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import SignatureDialog, { isDigitalSignNeeded } from 'app/components/signature/SignatureDialog'

import { capitalize, serverlikeErrors, formatFIO } from 'app/core/utility/common'
import { formatDate } from 'app/core/utility/date'
import Table from 'app/components/ui/obsoleteTable/table';
import SignatureInfo from 'app/components/signature/signatureInfo/signatureInfo'
import { itemActions, sortDirection } from 'app/components/ui/constants'
import SignInfo from '../../signature/signatureInfo/signInfoTable'

import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'

import withAuth from 'app/components/HOC/AuthHOC'

import { breadcrumbsClearAction } from 'redux/actions/common/breadcrumbsAction'
import * as errorAction from 'redux/actions/common'
import * as admittanceCardAction from 'redux/actions/admittance/admittanceCardAction'
import * as signatureActions from 'redux/actions/common/signatureAction'
import SimpleFilesTable from 'app/components/fileTable/simpleFilesTable/simpleFilesTable'

class AdmittanceCard extends PureComponent {
  constructor(props){
    super(props)

    const { profile } = props

    this.state = { 
      tabTitle: 'Информация',
      sort: { 
        column: 'createDate',
        direction: sortDirection.desc
      },

    }
  }

  componentDidMount() {
    const { 
      admittanceCard,
      getAdmittanceAction,
      admittanceCardInProgress,
      breadcrumbsClearAction,
      match = {},
      location
    } = this.props
    const { pathname } = location
    const { params = {} } = match
    const { admittanceId } = params
    const { inProgress } = admittanceCard

    if (inProgress) {
      return
    }
   
    breadcrumbsClearAction()
    admittanceCardInProgress(true)
    getAdmittanceAction({ admittanceId, pathname})
  }

  componentWillUnmount () {
    const { 
      admittanceCardResetAction,
      clearErrorAction,
    } = this.props

    admittanceCardResetAction()
    clearErrorAction()
  }

  _onSubmit = async () => {
    const { profile, admittanceCard } = this.props
    const { commonDataForm } = admittanceCard

    if (isDigitalSignNeeded(profile, 'Admittance')) {
      this._onSignDialog(commonDataForm)
    } else {
      this._registerWithoutSign(commonDataForm)
    }
  }

  _onSign = async () => {
    const { 
      profile, 
      admittanceCard,
      admittanceCardSignAction
    } = this.props
    const { commonDataForm, admittanceData } = admittanceCard
    const { id: admittanceId } = admittanceData
    
    if (isDigitalSignNeeded(profile, 'Admittance')) {
      this.setState({ admittanceSign: true })
      this._onSignDialog(commonDataForm)
    } else {
      return admittanceCardSignAction({
        admittanceId,
        data: this._getSignatureRequestData(commonDataForm),
        signature: null
      })
    }
  }
  
  _registerWithoutSign = async (data) => {
    const { 
      admittanceCardInProgress,
      admittanceCardUpdateAction,
      admittanceCard
    } = this.props
    const { admittanceData } = admittanceCard
    const { id: admittanceId } = admittanceData
    const requestData = {
      admittanceId,
      data: this._getSignatureRequestData(data),
      signature: null
    }

    admittanceCardInProgress(true)
    admittanceCardUpdateAction(requestData)
  }

  _onSignDialog = (data) => {
    const { 
      setRequestData, 
      generateText, 
      admittanceCardInProgress
    } = this.props

    const signTextRequest = {
      ...this._getSignatureRequestData(data),
      signatureRequestType: 'Admittance'
    }
  
    setRequestData({...data});
    admittanceCardInProgress(true)
    generateText(signTextRequest);
  }

  _getSignatureRequestData = (data) => {
    const { name, userId, issueDate, skziInfo, signers } = data
    const { version } = skziInfo || {}
    const { id: versionId } = version || {}
    const { id } = userId || {}
    
    return { 
      name, 
      userId: id, 
      issueDate: formatDate(issueDate, 'yyyy-mm-dd'),
      skziVersionId: versionId,
      signers: signers.map(item => item.id)
    }
  }

  _onSignatureCancel = () => {
    const { resetSignature } = this.props

    this.setState({admittanceSign: false}, () => resetSignature() )
  }
  
  _afterSign = () => {
    const { 
      signature,
      admittanceCardInProgress,
      admittanceCardUpdateAction,
      admittanceCardSignAction,
      admittanceCard
    } = this.props
    const { commonDataForm, admittanceData } = admittanceCard
    const { id: admittanceId } = admittanceData
    const { textForSignature, signatureResult } = signature
    const { admittanceSign } = this.state
    
    const signatureToServer = {
      admittanceId,
      data: this._getSignatureRequestData(commonDataForm),
      signature: { data: textForSignature, signedData: signatureResult }
    }

    admittanceCardInProgress(true)
    if (admittanceSign ) {
      this.setState({ admittanceSign: false }, () => {
        return admittanceCardSignAction(signatureToServer)
      })
    } else {
      return admittanceCardUpdateAction(signatureToServer)
    }
  }

  _onCancel = () => {
    const { clearErrorAction, admittanceCardResetAction } = this.props

    admittanceCardResetAction()
    clearErrorAction()
  }

  _applyRightsToActions = () => {
    const { profile, admittanceCard } = this.props
    const { admittanceData } = admittanceCard
    const { signers = [] } = admittanceData
    const { userInfo } = profile || {}
    const { id: currentUserId } = userInfo || {}
    const commissionSigner = signers.find(item => {
      const { user: { id } } = item
      return id === currentUserId
    })
    const admittanceRights = getResourceRights(profile, RESOURCES.admittance)
    const canSign = commissionSigner && !commissionSigner.signature

    const firstGroup = []
    const secondGroup = []
    admittanceRights.VIEW_CARD && secondGroup.push(itemActions.xlsDownload.key)
    admittanceRights.UPDATE && canSign && firstGroup.push(itemActions.sign.key)

    return [firstGroup, secondGroup]
  }

  _onActionClick = (action, item) => {

    switch (action){
      case itemActions.sign.key:
        this._onSign()
        break
      default:
        break
    }
  }

  _getCardLinks = () => {
    const { admittanceCard } = this.props
    const { admittanceData } = admittanceCard
    const { id } = admittanceData

    return {
      [itemActions.xlsDownload.key]: `api/v1/admittance/xls/download?id=${id}`
    }
  }

  _onValidation = (errors) => {
    const { setErrorByKey } = this.props

    setErrorByKey(serverlikeErrors(errors))
  }

  _onFormChange = (key, value) => {
    const { admittanceCard, admittanceCardUpdateCommonForm, setErrorByKey, error } = this.props
    const { commonDataForm } = admittanceCard
    const { signers } = commonDataForm
    const { organization: signersOrg } = signers[0] || {}
    const { id: currentOrganizationId } = signersOrg || {}
    const { errorByFields }  = error || {}

    let newData = {[key]: value}
    if (key === 'userId' && signers.length) {
      const { organization } = value || {}
      const { id: valueOrgId } = organization || {}
      newData = !value || valueOrgId !== currentOrganizationId
                ? { [key]: value, signers: []}
                : newData
    }

    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))
    setErrorByKey(filteredErrors)
    admittanceCardUpdateCommonForm({ ...commonDataForm, ...newData })
  }

  _getTableOptions = () => {
    const { sort } = this.state
    const { column, direction } = sort

    return {
      sortable: {
        defaultColumn: column,
        defaultDirection: direction,
        onChange: (name, direction) => this._onSort(name, direction)
      },
      multipleSelect: {use: true, onCheck: this._onSelect},
      useActions: {use: false},
    }
  }

  _renderData = () => {
    const { admittanceCard, profile, error, match } = this.props
    const { params = {} } = match
    const { admittanceId } = params
    const { fieldsError } = error
    const { admittanceData, selected, viewData, commonDataForm, inProgress } = admittanceCard
    const { signers = [], canBeChanged } = admittanceData
    const admittanceRights = getResourceRights(profile, RESOURCES.admittance)
    const fileRights = getResourceRights(profile, RESOURCES.admittanceFiles)
    
    return (
      <section className='admittance-common-data'>
        <ExpandingBlock
          className='admittance-common-data__requisites'
          renderHeader= {() => 'Реквизиты'}
          initialState={true}
        >
          <Card
            className='admittance-data-card'
            profile={profile}
            formLines={cardLines}
            actions={this._applyRightsToActions()}
            onActionClick={this._onActionClick}
            dowloadUrls={this._getCardLinks()}
            canEdit={admittanceRights.UPDATE && canBeChanged}
            key={this._getAdmittanceKey(viewData)}
            data={viewData}
            formData={commonDataForm}
            onValidationError={this._onValidation}
            errorByFields={fieldsError}
            onChange={this._onFormChange}
            onSubmit={this._onSubmit}
            onCancel={this._onCancel}
            getObjectKeyFunc={this._getAdmittanceKey}
          />
          <SignInfo signers={signers}/>
        </ExpandingBlock>
        <ExpandingBlock
          className='admittance-types'
          renderHeader= {() => 'Вложения'}
          initialState={true}
        >
          {fileRights && <SimpleFilesTable
            entityId={admittanceId}
            downloadFilesUrl = {`admittance/${admittanceId}/content`}
            urlPartUpload={`admittance/${admittanceId}/content/add`}
            fileRights = {fileRights}
            serviceName = {'admittanceService'}
            deleteMethod = {'deleteAdmittanceFile'}
            getMethod = {'getAllAdmittanceFiles'}
          />}
        </ExpandingBlock>
      </section>
    )
  }

  _getAdmittanceKey = (data) => {
    const { name, userId, issueDate, skziInfo, signers } = data
    const { skzi, version } = skziInfo || {}
    const { id: skziId } = skzi || {}
    const { id: versionId } = version || {}
    const { id } = userId || {}

    return `${name}${skziId}${versionId}${id}${formatDate(issueDate, 'yyyy-mm-dd')}${signers.map(item => item.id).join('')}`
  }


  _renderModal = () => {
    const { 
      admittanceCard, 
      admittanceCardResetAction, 
      signature,
      profile,
    } = this.props
    const { confirmObject } = admittanceCard
    const { textForSignature } = signature || {}

    return (
      <Fragment>
        {textForSignature ? (
          <SignatureDialog
            className={'signature-form'}
            profile={profile}
            onCancel={this._onSignatureCancel}
            afterSignCallback={this._afterSign}
            renderHeader = {() => {
              return (
                <div className='sign__header'>
                  {'Изменение допуска к СКЗИ'}
                </div>
              )
            }}
        />) :null}
        {confirmObject ? 
          <ConfirmDialog
            {...confirmObject}
            onCancel={admittanceCardResetAction}
          /> : null
        }
      </Fragment>
    )
  }

  render() {
    const { admittanceCard, history } = this.props
    const { inProgress } = admittanceCard

    return (
      <section className='admittance-card'>
        {inProgress ? <Overlay /> : null}
        {this._renderModal()}
        <BreadcrumbsHeader />     
        {this._renderData()}
      </section>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default connect(
  mapStateToProps,
  { 
    ...errorAction,
    ...admittanceCardAction,
    ...signatureActions,
    breadcrumbsClearAction
  })(withAuth(AdmittanceCard))