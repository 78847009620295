/** @format */

import { useState, useEffect } from 'react';
import ExpandingBlock from 'app/components/ui/expandingBlock';
import CompoundForm from './compoundForm';
import RequisitesForm from './requisitesForm';
import BusinessEventPanel from '../businessEventPanel/CertificatePanel';
import ManagePanel from 'app/components/ui/managePanel/managePanel';
import styles from './certificate.module.scss';
import service from 'app/services/service';
import { Border } from 'app/components/ui/managePanel';

export default function CertificateData(props) {
  const { certificateId, certificate, keyDocumentId, CAUser = false } = props;
  const [cardData, setCardData] = useState();

  const initCertificateCardData = async () => {
    if (certificate) {
      setCardData({ ...certificate });
      return;
    }

    // TODO: keyDocumentId - временный костыль, нужный до объединения arm и УЦ, в дальнейшем должен остаться 1 api
    let response;
    if (keyDocumentId) {
      response = await service('certificateService', 'getArmCertificate', {
        keyDocumentId: keyDocumentId,
      });
    } else {
      response = await service('certificateService', CAUser ? 'getCACertificate' : 'getCertificate', {
        certificateId,
      });
    }
    const { data } = response;
    setCardData({ ...data });
  };

  useEffect(() => {
    initCertificateCardData();
  }, []);
  // TODO: Проверка на certificateId временный костыль, нужный до объединения arm и УЦ, в дальнейшем должен остаться 1 api
  return (
    <>
      <ExpandingBlock
        renderHeader={() => 'Реквизиты'}
        initialState={true}
        className={styles.expand}
      >
        {certificateId && (
          <ManagePanel borders={Border.Bottom}>
            <BusinessEventPanel certificateId={certificateId} certificate={{ ...cardData }} CAUser={CAUser} />
          </ManagePanel>
        )}
        <RequisitesForm {...cardData} />
      </ExpandingBlock>
      {certificateId && (
        <ExpandingBlock renderHeader={() => 'Состав'} initialState={true} className={styles.expand}>
          <CompoundForm {...cardData} />
        </ExpandingBlock>
      )}
    </>
  );
}

