import React from 'react'
import Group from 'app/components/ui/managePanel/group';
import BusinessEventTrigger from 'app/components/ui/managePanel/businessEventTrigger/businessEventTrigger';
import { ENTITY_TYPE } from 'app/components/ui/managePanel/businessEventTrigger/useBusinessEvent';
import { Image } from 'app/components/ui/Img';
import SendButton from 'app/components/skzi/EskziList/businessEventPanel/sendButton';
import {Delete} from "app/components/list/TopPanel/Delete";
import { stringPlural } from 'app/core/utility/common';
import service from 'app/services/service';
import RecordKDButton from './linkButton/RecordKDButton';
import { Type as MassImportType } from 'app/components/MassImports/enums';
import MassImportButton from 'app/components/MassImports/MassImportButton';
import ExportToExcelButton from 'app/components/exportToExcelButton';

const sendBusinessEvents = [
  {name: "Передача в Орган криптографической защиты"
  , eventCode: 'OkzReceiving'},
  {name: "Передача Обладателю конфиденциальной информации", eventCode: 'OkiReceiving'}
]

export default function BusinessEventPanel(props) {
  const { 
    globalOrgId,
    items,
    afterAction,
    filterForRequest,
    sorting,
    fieldsVisible,
    setSelectedAction,
    onInProgress,
    rights,
    massImportRights,
  } = props

  const [ selectedItem ] = items || []
  const { isStandard, replicationAllowed } = selectedItem || {}
  const disabled = items.length !== 1
                    || items[0].bizLocked
                    || !( isStandard && replicationAllowed )

  const deleteActionCallback = async () => {
    onInProgress(true);
    const response = await service('keyDocOkzService', 'deleteRange', items.map(i => i.id));
    onInProgress(false);
    return response;
  };

  return (
    <>
      <Group>
        <SendButton
          items={items}
          className='send-button'
          title='Передать'
          actionList={sendBusinessEvents}
          disabled={false}
          endpointType={'keyDocument'}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={['OkzReturn']}
          tooltip='Вернуть'
          endpointType={'keyDocument'}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.ArReturn}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={['OkzSendingOk', 'OkiSendingOk']}
          tooltip='Подтвердить получение'
          endpointType={'keyDocument'}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.Acceptation}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={['OkzReturnOk']}
          tooltip='Подтвердить возврат'
          endpointType={'keyDocument'}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.AcceptationOfReturn}
        />
      </Group>
      <Group>
        <RecordKDButton
          onInProgress={onInProgress}
          items={items}
          afterAction={afterAction}
        />
      </Group>
      <Group>
        { massImportRights.IMPORT && <MassImportButton type={MassImportType.OKZ_KEY_DOCUMENT} /> }
        <ExportToExcelButton
          id={globalOrgId}
          listFilterForRequest={filterForRequest}
          sorting={sorting}
          listFieldsVisibility={fieldsVisible}
          selected={items.map(i=>i.id)}
          serviceName='keyDocOkzService'
          onStart={() => onInProgress(true)}
          onFinish={() => onInProgress(false)}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode='Destruction'
          tooltip='Уничтожить'
          endpointType={'keyDocument'}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.Extermination}
        />
      </Group>
      <Group>
        <Delete
          items={items}
          deleteItemsAction={deleteActionCallback}
          afterAction = {afterAction}
          multiply={true}
          setSelectedAction = {setSelectedAction}
          dialog ={data => {
              const arr = ['выбранный ключевой документ', 'выбранные ключевые документы', 'выбранные ключевые документы']
              return `Вы уверены, что хотите удалить ${stringPlural(data.length, arr)}?`
          }}
        />
      </Group>
    </>
  )
}