import React from 'react'

import Input from 'app/components/ui/Input'
import Autocomplete, { Services, renderItem, renderInputValue }  from 'app/components/ui/autocomplete/autocomplete'
import DatePicker from 'app/components/ui/date'
import { formatFIO } from 'app/core/utility/common'
import { notEmpty, notEmptyCollection } from 'app/core/utility/validation'
import service from 'app/services/service'
import TagsPicker from 'app/components/ui/picker/tagPicker'
import SignatureInfo from 'app/components/signature/signatureInfo/signatureInfo'
import { CardState } from 'app/components/ui/card/cardMaterial'
import {USER_ACCOUNT_CARD_TYPE} from '../../ui/constants'

export const cardLines = {
  skziInfo: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, formData, editValue, onChange, error } = props
      const { hasIssuedEskzi } = formData || {}

      const isDisabled = (cardState, hasIssuedEskzi) => {
        switch (cardState) {
          case CardState.view:
          case CardState.create:
            return false
          case CardState.edit:
            return hasIssuedEskzi
        }
      }

      return (
        <Autocomplete 
          label = 'СКЗИ'
          className='card-field add-admittance-dialog__skzi'
          serviceType={Services.skziVersion} 
          readOnly={cardState === CardState.view}
          renderItem={renderItem.skziVersion}
          renderInputValue={renderInputValue.skziVersion}
          onSelect={value => onChange('skziInfo', value)}
          error={error}
          value={editValue}
          disabled={isDisabled(cardState, hasIssuedEskzi)}
        />
      )
    },
  },
  userId: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, formData, userEnabled = true, editValue, onChange, error } = props
      const { hasIssuedEskzi } = formData || {}

      const isDisabled = (cardState, hasIssuedEskzi) => {
        switch (cardState) {
          case CardState.view:
          case CardState.create:
            return false
          case CardState.edit:
            return hasIssuedEskzi || !userEnabled
        }
      }
      
      const autocompleteService = {
        serviceName: 'skziUserService', 
        serviceMethod: 'closedLimitedAutocomplete', 
        data: {returnClosed: false}
      }

      return (
        <Autocomplete 
          className='card-field add-admittance-dialog__current-user'
          label='Пользователь СКЗИ'
          readOnly={cardState === CardState.view}
          linkTarget={cardState === CardState.view && editValue && editValue.id && {targetType: USER_ACCOUNT_CARD_TYPE, id: editValue.id}}
          value={editValue}
          error={error}
          notStandardService={autocompleteService}
          serviceType={Services.skziUser} 
          onSelect={value => onChange && onChange('userId', value)} 
          renderItem={renderItem.eskziUser}
          renderInputValue={val => formatFIO(val)}
          disabled={isDisabled(cardState, hasIssuedEskzi)}
        />)
      },
  },
  id: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field add-admittance-dialog__admittanceId'
          label = '№ допуска'
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  status: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field add-admittance-dialog__status'
          label = 'Статус'
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  name: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field add-admittance-dialog__number'
          label = '№ заключения'
          value={editValue}
          onChange={value => onChange('name', value)}
          readOnly={cardState === CardState.view}
          error={error}
        />
      )
    },
  },
  issueDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <DatePicker
          className='card-field add-admittance-dialog__admittanceDate'
          label = 'Дата заключения'
          readOnly={cardState === CardState.view}
          error={error}
          value={editValue} 
          onChange={value => onChange('issueDate', value)}
        />
      )
    },
  },
  issuer: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props
      const { signature, user } = editValue || {}

      return (
        <div className='card-field add-admittance-dialog__issuer'>
          <Input 
            label = 'Автор'
            value={formatFIO(user)}
            readOnly={cardState === CardState.view}
            disabled={cardState === CardState.edit || cardState === CardState.create}
          />
          <SignatureInfo 
            signData={signature ? { ...signature, signer: user } : null}
          />
        </div>
      )
    },
  },
  createDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <DatePicker
          className='card-field add-admittance-dialog__create-date'
          label = 'Дата создания'
          error={error}
          value={editValue} 
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  signers: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { formData, cardState, editValue = [], onChange, error } = props
      const { userId } = formData
      const { organization } = userId || {}
      const { id: orgId } = organization || {}

      const pickerService = {
        serviceName: 'userService',
        serviceMethod: 'getUserByOrganizationId',
        data: {orgId}
      }

      return (
        <TagsPicker
          className='card-field add-admittance-dialog__otherSigners'
          label = 'Комиссия'
          error={error}
          readOnly={cardState === CardState.view}
          disabled={!userId}
          onChange={value => onChange('signers', value)}
          currentItems={editValue}
          serviceType={Services.user} 
          notStandardService={pickerService}
          renderTagName={item => formatFIO(item)}
          pickerHeaderText='Выбор участников комиссии'
        />
      )
    },
  }, 
  changeDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <DatePicker
          className='card-field add-admittance-dialog__change-date'
          label = 'Дата изменения'
          error={error}
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  lastUpdater: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue } = props
      const { signature, user } = editValue || {}

      return (
        <div className='card-field add-admittance-dialog__updater'>
          <Input 
            label = 'Изменено'
            value={formatFIO(user)}
            readOnly={cardState === CardState.view}
            disabled={cardState === CardState.edit || cardState === CardState.create}
          />
          <SignatureInfo 
            signData={signature ? { ...signature, signer: user } : null}
          />
        </div>
      )
    },
  },
}