import React, { useState } from 'react'
import {connect} from 'react-redux'

import MainCard, { CardButtons, cardMode, objectKey} from 'app/components/mainCard/cardComponent'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Tabs from 'app/components/ui/tabs/tabs'
import Overlay from 'app/components/ui/overlay'
import {cardLines} from './cardLines'
import {cardLinesExternal} from './cardLinesExternal'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import BusinessEventPanel from "./businessEventPanel/SkziPanel";
import ManagePanel from "app/components/ui/managePanel/managePanel";
import service from 'app/services/service'

import * as mainCardAction from 'redux/actions/card/cardActions'
import * as errorActions from 'redux/actions/common'
import * as eskziCardAction  from 'redux/actions/skziInstance/eskziCardAction'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import * as signatureAction from 'redux/actions/common/signatureAction'
import { getResourceRights } from 'app/core/auth/auth'
import { capitalize, updateCollectionByItemId } from 'app/core/utility/common'
import History from 'app/components/history'
import ServiceTab from  '../Eskzi/instanceData/ServiceTab'
import KeyDocuments from '../Eskzi/instanceData/keyDocuments'
import EskziEvents from "./Events"
import { EVENT_TYPE } from 'app/components/skzi/Card/Events/types'
import CardCertificationBlock from "./CardCertificationBlock";
import Licenses from '../Eskzi/instanceData/licenses'
import { ENTITY_TYPE } from "app/components/ui/managePanel/businessEventTrigger/useBusinessEvent"
import { useHistory, useParams } from 'react-router-dom'

const tabKeys = {
  maintenance: 'Обслуживание',
  information: 'Информация',
  events: 'Мероприятия',
  licenses: 'Лицензии',
  history: 'История',
  keydocs: 'Ключевые документы'
}


export const getEskziKey =  (data) => {
  const { 
    id: eskziId, 
    okz = {}, 
    eskziUser = {},
    skzi, 
    serialNumbers = [],
    receivedFrom,
    comment
  } = data || {}
  const { build } = skzi || {}
  const { id: buildId } = build || {}
  const { id: okzId } = okz
  const { id: eskziUserId = null } = eskziUser
  const sn = serialNumbers.map(item => ({id: item.id, number: data[item.id]}))

  return {
    id: eskziId,
    skziBuildId: buildId,
    okzId: okzId,
    eskziUserId: eskziUserId,
    serialNumbers: sn,
    receivedFrom: receivedFrom,
    comment: comment
  }
}

export const formUpdate = (key, value, props) => {
  const { 
    commonDataForm, 
    errorByFields, 
    cardUpdateAction, 
    setErrorByKey,
  } = props
  const { serialNumbers = [], isStandard, organizationType = {} } = commonDataForm || {}
  const errorKey = key === 'serialNumber' ? `${value.id}` : key
  let newData;

  if (key === 'skziVersionId') {
    const { content, isSoftwareSkzi, skzi, version, build } = value || {}

    newData = { 
      ...commonDataForm, 
      [key]: value,
      isStandard: !isSoftwareSkzi ? false : isStandard,
      skzi: value ? skzi : null, 
      version: value ? version : null, 
      build: value ? build : null,
      serialNumbers: content
    }
  } else if (key === 'serialNumber') {
    const newItem = { 
      ...serialNumbers.find(item => item.id === value.id), 
      number: value.value
    }
    newData = {
      ...commonDataForm,
      serialNumbers: updateCollectionByItemId(serialNumbers, newItem),
      [value.id]: value.value
    }
  } else if (key === 'organizationType') {
    newData = { 
      ...commonDataForm, 
      [key]: value,
      isStandard: value.id === 'Oki' ? false : isStandard
    }
  } 
  else {
    newData = { ...commonDataForm, [key]: value }
  }

  const filteredErrors = errorByFields.filter(err => err.field !== capitalize(errorKey))
  setErrorByKey(filteredErrors)
  cardUpdateAction(newData)
}

export const cardConfig = ({ 
    history,
    updateEskziCardAction,
    updateExternalOrgEskziAction,
    getExternalUserEskziAction,
    initEskziCardAction,
    breadcrumbsPushAction,
    isExternalUser,
  }) => {
  return ({
    cardLines: isExternalUser ? cardLinesExternal : cardLines,
    resource: isExternalUser ? RESOURCES.externalOrgEskzi : RESOURCES.eskzi,
    restrictEditByProp: () => !isExternalUser,
    getKey: data => objectKey(data, getEskziKey),
    customFormChangeHandler: formUpdate,
    signatureHeaderText: 'Изменение экземпляра',
    signatureServiceName: '',
    oldSignatureService: true,
    signatureEntity: '',
    updateAction: isExternalUser ? updateExternalOrgEskziAction : updateEskziCardAction,
    cardInitAction: isExternalUser
      ? getExternalUserEskziAction
      : initEskziCardAction,
    getSignatureData: getEskziKey,
    pageBreadcrumbTitle: (data) => {
      const {location} = history || {}
      const {pathname} = location || {}
      const {payload} = data || {}
      const {skzi} = payload || {}
      const {skzi: skziData, build} = skzi || {}
      const {name: skziName} = skziData || {}
      const {name: buildName} = build || {}

      if (!pathname) {
        return;
      }

      breadcrumbsPushAction(pathname, `${skziName} ${buildName}`)
    }
  })
}

const EskziCard = (props) => {
  const {
    isExternalUser,
    updateEskziCardAction,
    updateExternalOrgEskziAction,
    getExternalUserEskziAction,
    initEskziCardAction,
    breadcrumbsPopAction,
    breadcrumbsPushAction,
    eskziInProgress,
    inProgress,
    licenseData,
    commonDataForm,
    profile,
    location,
    isOkz,
    cardInProgressAction,
    viewData
  } = props
  const params = useParams();
  const { items: licenses } = licenseData || {}
  const { eskziId } = params
  const [ activeTab, setActiveTab ] = useState('Информация');
  const licenseRights = getResourceRights(profile, RESOURCES.skziLicense)
  const historyRights = getResourceRights(profile, RESOURCES.eskziHistory)
  const eskziRights = getResourceRights(profile, RESOURCES.eskzi)
  const { bizLocked, keyDocuments, technicalJournalAvailable,  } = commonDataForm || {}
  const [ triggerUpdate, setTriggerUpdate ] = useState( false );
  const history = useHistory();

  const { skziId, versionId, buildId } = commonDataForm && commonDataForm.skzi &&
    {
      skziId: commonDataForm.skzi.skzi.id,
      versionId: commonDataForm.skzi.version.id,
      buildId: commonDataForm.skzi.build.id
    } || {}

  const initCard = () => {
    cardInProgressAction(true)
    return initEskziCardAction(eskziId).then(e => {
      const tab = new URLSearchParams(location.search).get('tab')
      tab && tabKeys[tab] && setActiveTab(tabKeys[tab])
      cardInProgressAction(false)
      setTriggerUpdate(!triggerUpdate)
      return e
    })
  }

  const getEventsData = async () => {
    const getEventsMethodName = isExternalUser ? 'getExternalOrgEvents' : 'getActivities'
    const response = await service('skziService', getEventsMethodName, eskziId)

    return response
  }

  return (
    <section className={`main-card eskzi-card ${isExternalUser ? 'eskzi-card--external' : ''}`}>
      {inProgress ? <Overlay /> : null}
      <BreadcrumbsHeader />
      <Tabs 
        activeTab={activeTab} 
        onTabClick={(title) => {
          setActiveTab(title)
          const tabName = Object.keys(tabKeys).find(key => tabKeys[key] === title)
          history.push({
            search: `?tab=${tabName}`
          })
        }}
      >
        <section tabTitle={'Информация'} className="main-card__data">
          <ExpandingBlock
            renderHeader= {() => 'Реквизиты'}
            initialState={true}
          >
            <MainCard
              {...props}
              clearBeforeUnmount={false}
              //to do
              //разные компоненты не могут на одной странице
              //использовать warningObject из глобального стора
              //что-то надо делать с этим
              useWarning={false}
              // -----
              itemId={eskziId}
              config={cardConfig({
                eskziId,
                history,
                updateEskziCardAction,
                updateExternalOrgEskziAction,
                getExternalUserEskziAction,
                initEskziCardAction: initCard,
                breadcrumbsPopAction,
                breadcrumbsPushAction,
                isExternalUser,
              })}
            >
              <ManagePanel>
                <BusinessEventPanel
                  eskziRights={eskziRights}
                  items={viewData ? [viewData] : []}
                  eskziId={eskziId}
                  onInProgress={cardInProgressAction}
                  afterAction={isExternalUser ? getExternalUserEskziAction : initCard}
                  isExternalUser={isExternalUser}
                  isOkz={isOkz}
                />
              </ManagePanel>
              
            </MainCard>
            <CardCertificationBlock
              routeParams={{ skziId, versionId, buildId }}
              inProgressParent={inProgress}
            />
          </ExpandingBlock>
        </section>
        <EskziEvents 
          tabTitle={'Мероприятия'}
          getEventsData={getEventsData}
          isOkz={isOkz}
          eskziRights={eskziRights}
          afterAction={initCard}
          type={EVENT_TYPE.eskzi}
          triggerUpdate={triggerUpdate}
          id={eskziId}
          isExternalUser={isExternalUser}
          entityType={ENTITY_TYPE.eskzi}
        >
          <ManagePanel>
            <BusinessEventPanel
              eskziRights={eskziRights}
              items={viewData ? [viewData] : []}
              eskziId={eskziId}
              onInProgress={cardInProgressAction}
              afterAction={initCard}
              isExternalUser={isExternalUser}
              isOkz={isOkz}
            />
          </ManagePanel>
        </EskziEvents>
        <Licenses
          tabTitle={'Лицензии'} 
          licenseRights={licenseRights}
          itemId={eskziId}
          isExternalUser={isExternalUser}
          bizLocked={bizLocked}
        />
        { technicalJournalAvailable ? <KeyDocuments
          tabTitle={'Ключевые документы'}
          itemId={eskziId}
        /> : null }
        {
          technicalJournalAvailable ? <ServiceTab
            tabTitle={'Обслуживание'}
            isExternalUser={isExternalUser}
            skzi={commonDataForm}
          /> : null
        }
        {historyRights.VIEW_LIST ? <History
          tabTitle={'История'}
          serviceName={'skziInstanceService'}
          itemId={eskziId}
          inProgressCallback={eskziInProgress}
        /> : null}
      </Tabs>
    </section>
  )
}

export default connect(
  state =>({
    ...state.card, 
    ...state.eskziCard,
    profile: state.profile,
    signature: state.signature,
    error: state.error
  }),
  { 
    ...mainCardAction,
    ...eskziCardAction,
    ...breadcrumbsActions,
    ...signatureAction,
    ...errorActions
  })(EskziCard)
