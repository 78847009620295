/** @format */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import MainCard, { CardButtons, cardMode, objectKey } from 'app/components/mainCard/cardComponent';
import ExpandingBlock from 'app/components/ui/expandingBlock';
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader';
import { cardLines } from '../card/cardLines';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import * as cardAction from 'redux/actions/catalog/cardActions';
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction';
import { catalogEntityData } from './createCard';

export const cardConfig = ({
  history,
  updateSkziTypeAction,
  initSkziTypeAction,
  deleteSkziTypeAction,
  canBeChanged, 
  canBeRemoved
}) => ({
  cardLines: cardLines,
  buttons: canBeRemoved ? {
    [CardButtons.delete]: deleteSkziTypeAction,
  } : {},
  getKey: data => objectKey(data, catalogEntityData),
  resource: RESOURCES.skziType,
  updateAction: updateSkziTypeAction,
  cardInitAction: initSkziTypeAction,
  getSignatureData: catalogEntityData,
  restrictEditByProp: () => canBeChanged,
  getDeleteMessage: data => `Вы уверены, что хотите удалить элемент "${data.name}"?`,
  afterDelete: () => {
    history.push('/catalog/skziTypes');
  },
});

const CatalogCard = props => {
  const {
    updateSkziTypeAction,
    initSkziTypeAction,
    deleteSkziTypeAction,
    breadcrumbsPopAction,
    breadcrumbsPushAction,
    history,
    commonDataForm,
    match
  } = props;

  const { params = {} } = match
  const { id } = params
  const { name, canBeChanged, canBeRemoved } = commonDataForm || {};

  useEffect(() => {
    name && breadcrumbsPushAction(history.location.pathname, `${name}`);
    return () => {
      name && breadcrumbsPopAction();
    };
  }, [name]);

  return (
    <section className="main-card catalog-card">
      <BreadcrumbsHeader />
      <section className="main-card__data">
        <ExpandingBlock renderHeader={() => 'Реквизиты'} initialState={true}>
          <MainCard
            {...props}
            itemId={id}
            cardMode={cardMode.edit}
            config={cardConfig({
              history,
              updateSkziTypeAction,
              initSkziTypeAction,
              deleteSkziTypeAction,
              breadcrumbsPopAction,
              breadcrumbsPushAction,
              canBeChanged, 
              canBeRemoved
            })}
          ></MainCard>
        </ExpandingBlock>
      </section>
    </section>
  );
};

export default connect(state => ({ ...state.card, profile: state.profile }), {
  ...cardAction,
  ...breadcrumbsActions,
})(CatalogCard);
