/** @format */
import { Table } from 'app/components/list/Table/Table';
import Overlay from 'app/components/ui/overlay';
import { sortDirection } from 'app/components/ui/constants';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import Pagination from 'app/components/ui/pagination/serverSidePagination/pagination';
import { useJournal } from 'app/components/journals/useJournal';
import PageHeader from 'app/components/PageHeader/pageHeader';
import imgMap from 'app/components/ui/Img/imgMap';
import { Border, Group } from 'app/components/ui/managePanel';
import ManagePanel from 'app/components/ui/managePanel/managePanel';
import FilterTriggerPanel from 'app/components/filter/FilterTriggerPanel';
import Filter, { GlobalOrgType } from 'app/components/filter';
import { fieldsConfig, filtersConfig } from './pageConfig';
import { TableFieldSelect } from 'app/components/list/Table/TableFieldSelect';
import MainListContainer, {
  FilterWithTableContainer,
  ListDisplayVariant,
} from 'app/components/ui/MainListContainer/MainListContainer';
import styles from './list.module.scss';
import DownloadFileButton from 'app/components/downloadFileButton';
import CertificateRevocation from './revocation';
import CertificateSuspension from './suspension';
import CertificateRenewal from './renewal';

export default function Certificate() {
  const {
    baseTableLogic,
    itemSelectionLogic,
    paginationLogic,
    sortingLogic,
    filterLogic,
    globalOrgLogic,
    fieldSelectionLogic,
    filterClearLogic,
    profile,
    rights,
    inProgress,
    setSelectedAction,
    selectedObjects,
    refreshPage,
    fieldsVisible,
    filterOpened,
    toggleFilterState,
  } = useJournal({
    defaultSort: {
      column: 'creationDate',
      direction: sortDirection.desc,
    },
    serviceName: 'certificateAuthorityCertificateService',
    resource: RESOURCES.certificateAuthorityCertificate,
  });

  const downloadFilesIds = selectedObjects.map(i => i.id).join('&sn=');
  const downloadFilesUrl = `api/v1/cryptopro/acceptorcertificates/download?sn=${downloadFilesIds}`;

  return (
    <MainListContainer>
      {inProgress && <Overlay />}
      <PageHeader title="Удостоверяющий центр : Сертификаты" Img={imgMap.Sign} />
      <ManagePanel borders={Border.All}>
        {rights.UPDATE && (
          <Group>
            <CertificateRevocation
              item={selectedObjects[0]}
              disabled={selectedObjects.length !== 1}
              CAUser
            />
          </Group>
        )}
        {rights.UPDATE && (
          <Group>
            <CertificateSuspension
              item={selectedObjects[0]}
              disabled={selectedObjects.length !== 1}
              CAUser
            />
          </Group>
        )}
        {rights.UPDATE && (
          <Group>
            <CertificateRenewal
              item={selectedObjects[0]}
              disabled={selectedObjects.length !== 1}
              CAUser
            />
          </Group>
        )}
        <Group>
          <DownloadFileButton url={downloadFilesUrl} disabled={!selectedObjects.length} />
        </Group>
        <Group separate>
          <FilterTriggerPanel
            {...{
              ...filterLogic,
              ...filterClearLogic,
            }}
            toggle={toggleFilterState}
          />
        </Group>
        <Group separate>
          <TableFieldSelect
            {...fieldSelectionLogic}
            listFieldsVisible={fieldsVisible}
            fieldsConfig={fieldsConfig}
            changedFieldsVisibility
            settingsAsGear
          />
        </Group>
      </ManagePanel>
      <FilterWithTableContainer
        useContainer={filterOpened}
        listDisplayVariant={ListDisplayVariant.twoRowFilter}
      >
        <Filter
          className={styles.certificate}
          {...{
            ...filterLogic,
            ...globalOrgLogic,
            profile,
            inProgress,
            filtersConfig,
          }}
          globalOrgPosition={4}
          globalOrgType={GlobalOrgType.okiOnly}
          withGlobalOrg
        />
        <Table
          {...{
            ...baseTableLogic,
            ...itemSelectionLogic,
            ...sortingLogic,
            ...paginationLogic,
            setSelectedAction,
            inProgress,
            fieldsConfig,
            itemsSelectable: true,
            headerInfo: true,
            onItemClick: item => `/certificateAuthority/certificate/card/${item.id}`,
          }}
          listFieldsVisible={fieldsVisible}
          changedFieldsVisibility
        />
      </FilterWithTableContainer>
      <Pagination {...paginationLogic} />
    </MainListContainer>
  );
}

