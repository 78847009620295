import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import MainCard, { CardButtons, objectKey } from 'app/components/mainCard/cardComponent'
import { getDefaultWarningDialogProps } from 'app/components/dialog/helpers'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Tabs from 'app/components/ui/tabs/tabs'
import { cardLines } from './cardData'
import { formatDate } from 'app/core/utility/date'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { CARDAFTERCREATE } from 'redux/actions/actionTypes'
import  { okzData } from './createCard'
import Served from './servedOrganizations'
import * as mainCardAction from 'redux/actions/card/cardActions'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import { useHistory, useParams } from 'react-router-dom'
import ManagePanel from "app/components/ui/managePanel/managePanel";
import BusinessEventPanel from "./businessEventPanel/okzPanel";

export const cardConfig = ({ 
    history,
    updateOkzSchemeCard,
    initOkzSchemeCard,
    breadcrumbsPopAction,
    breadcrumbsPushAction
  }) => ({
  cardLines: cardLines,
  getKey: data => objectKey(data, okzData),
  resource: RESOURCES.okzScheme,
  signatureHeaderText: '',
  signatureServiceName: '',
  signatureEntity: '',
  updateAction: updateOkzSchemeCard,
  cardInitAction: initOkzSchemeCard,
  getDeleteMessage: data => `Вы уверены, что хотите удалить организацию ${data.name}?`,
  getSignatureData: okzData,
  pageBreadcrumbTitle: (action) => {
    const { location } = history || {}
    const { pathname } = location || {}
    const { payload } = action
    const { shortName, name } = payload || {}

    if( !pathname ) {
      return;
    }
    
    breadcrumbsPushAction(history.location.pathname, `${shortName || name}`)
  },
  getServerWarning: getDefaultWarningDialogProps,
})

const OkzCard = (props) => {
  const { 
    updateOkzSchemeCard,
    initOkzSchemeCard,
    breadcrumbsPopAction,
    breadcrumbsPushAction,
    commonDataForm,
    cardInProgressAction
  } = props

  const { shortName, name } = commonDataForm || {}
  const { id } = useParams();
  const [ activeTab, setActiveTab ] = useState('Информация');
  const [ servedOkz, setServedOkz ] = useState([]);
  const [ servedOki, setServedOki ] = useState([]);
  const [ title, setTitle ] = useState( shortName || name );
  const history = useHistory();

  useEffect(() => {
    title && breadcrumbsPushAction(history.location.pathname,`${title || ''}`)
    return () => {
      title && breadcrumbsPopAction()
    }
  }, [title])

  useEffect(() => {
    if(shortName || name) {
      setTitle(shortName || name) 
    }
  }, [shortName, name])

  return (
    <section className='main-card okz-sheme-card'>
      <BreadcrumbsHeader />
      <Tabs 
        activeTab={activeTab} 
        onTabClick={setActiveTab}
      >
        <section tabTitle={'Информация'} className="main-card__data">
          <ExpandingBlock
            renderHeader= {() => 'Реквизиты'}
            initialState={true}
          >
            <MainCard
              {...props}
              itemId={id}
              key={id}
              clearBeforeUnmount={true}
              config={cardConfig({
                history,
                updateOkzSchemeCard,
                initOkzSchemeCard: async (id) => {
                  const res = await initOkzSchemeCard(id)
                  const { servedOkz = [], servedOki = [] } = res && res.payload || {}
                  setServedOki(servedOki)
                  setServedOkz(servedOkz)
                  return res
                },
                breadcrumbsPopAction,
                breadcrumbsPushAction
              })}
            > 
              <ManagePanel>
                <BusinessEventPanel
                  okzId={id}
                  onInProgress={cardInProgressAction}
                />
              </ManagePanel>
            </MainCard>
          </ExpandingBlock>
        </section>
        <Served
          tabTitle={'Нижестоящие ОКЗ'}
          id={id}
          list={servedOkz}
          onUpdate={setServedOkz}
        >
        </Served>
        <Served
          tabTitle={'Организации'}
          id={id}
          list={servedOki}
          isOkz={false}
          onUpdate={setServedOki}
        >
        </Served>
        {/* <div
          tabTitle={'Экземпляры СКЗИ'}
          disabled={true}
        />
        <div
          tabTitle={'Лицензии'}
          disabled={true}
        /> */}
      </Tabs>
    </section>
  )
}

export default connect(
  state =>({...state.card, profile: state.profile}),
  { 
    ...mainCardAction,
    ...breadcrumbsActions
  })(OkzCard)