import React from 'react'

import Input from 'app/components/ui/Input'
import TextArea from 'app/components/ui/textArea'
import Autocomplete, { Services }  from 'app/components/ui/autocomplete/autocomplete'
import DatePicker from 'app/components/ui/date'
import { notEmpty } from 'app/core/utility/validation'

export const cardLines = {
  name: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { editValue, onChange, error } = props

      return (
        <Input
          className='build-name'
          label = 'Наименование'
          value={editValue}
          onChange={value => onChange('name', value)}
          error={error}
        />
      )
    },
  },
  certificate: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { editValue, onChange, error } = props

      return (
        <Input
          className='build-certificate'
          label = 'Сертификат'
          value={editValue}
          onChange={value => onChange('certificate', value)}
          error={error}
        />
      )
    },
  },
  classId: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { editValue, onChange, error } = props

      return (
        <Autocomplete 
          className='build-classId'
          label='Класс СКЗИ'
          onEmptySearch = {true} 
          serviceType={Services.skziClass} 
          onSelect={value => onChange && onChange('classId', value)} 
          error={error}
          value={editValue}
        />
      )
    },
  },
  certificateExpirationDate: {
    renderEdit: props => {
      const { editValue, onChange, error } = props

      return (
        <DatePicker 
          className='build-certificateExpirationDate'
          label='Срок действия сертификата'
          error={error}
          value={editValue} 
          onChange= {value => onChange && onChange('certificateExpirationDate', value)}
        />
      )
    },
  },
  description: {
    renderEdit: props => {
      const { editValue, onChange, error } = props

      return (
        <TextArea 
          className='build-description'
          label='Описание'
          value={editValue} 
          error={error}
          onChange={value => onChange('description', value)}
        />
      )
    },
  },
}