import { useDispatch } from "react-redux"
import {
  addToastAction,
  removeToastAction,
  removeAllToastsAction
} from "../../../../redux/actions/toast"

// TODO: нормально проверять закрываемость ошибки, либо самому метки ставить, либо сервер передаёт
function isErrorClosable(message){
  return (
    message.startsWith('Аккаунт временно заблокирован.')
    || message.startsWith('Аккаунт заблокирован')
    || message.startsWith('Пароль не соответствует')
  );
}

export default function useToast(){
  const dispatch = useDispatch()

  function addToast(message){
    const closable = isErrorClosable(message)
    dispatch(addToastAction({
      id: Math.floor((Math.random() * 1000) + 1),
      message,
      closable
    }))
  }

  function removeToast(toast){
    dispatch(removeToastAction(toast))
  }

  function removeAllToasts(){
    dispatch(removeAllToastsAction())
  }

  return {
    addToast,
    removeToast,
    removeAllToasts
  }
}