/** @format */

import React from 'react';
import { humanTextFileSize } from 'app/core/utility/file';
import { ReactComponent as CancelImg } from 'assets/img/commonVer2/close.svg';
import Button from 'app/components/ui/button/button';
import { FileErrorMessage, FileValidateState } from 'app/components/MassImports/enums';

import styles from './MassImportFileItem.module.scss';

export default function MassImportFileItem({ className, file, validator, progress, onCancel, error }) {
  const { blob: fileObject, fileKey } = file;
  const { name, size } = fileObject;
  const errors = [...validator(file), ...(error && error.errorMessage ? [error.errorMessage] : [])];

  return (
    <>
      <div className={`file-item ${errors && errors.length ? styles.fileItemHasErrors : ''} ${className || {}}`}>
        <div className="file-item__data">
          <div
            className={`file-item__name ${
              errors && errors.includes(FileValidateState.MISS_EXTENSION) ? styles.nameHasError : ''
            }`}
          >
            {name}
          </div>
          <div
            className={`file-item__size ${
              errors && errors.includes(FileValidateState.OVER_SIZE) ? styles.sizeHasError : ''
            }`}
          >
            ({humanTextFileSize(size)})
          </div>
        </div>
        <div className="file-item__progress">
          {progress && !error && (
            <div className="progress-background">
              <div className="current-progress" style={{ width: `${Math.floor(progress * 100)}%` }} />
            </div>
          )}
        </div>
        {(!progress || error) && (
          <div className="file-item__cancel">
            <Button className="file-item__cancel" type="image" onClick={() => onCancel(fileKey)}>
              <CancelImg className="button-image" />
            </Button>
          </div>
        )}
      </div>
      <ul className={styles.errors}>
        {errors.map(err => (
          <li>{FileErrorMessage[err] || err}</li>
        ))}
      </ul>
    </>
  );
}
