import React, {useMemo, useState} from "react";
import {Table as TableComponent} from 'app/components/list/Table/Table'
import service from "app/services/service";
import withEndlessScrollHOC from 'app/components/HOC/EndlessScrollHOC'
import useFileTable from "./useFileTable";
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import { sortDirection } from "app/components/ui/constants";
import AddFileDialog from "../addFilesDialog";
import withModal from "app/components/HOC/ModalHOC";
import ButtonComponent from 'app/components/ui/button/button'
import DownloadButton from '../downloadButton'
import { ReactComponent as DeleteImg } from 'assets/img/commonVer2/delete.svg'
import {ReactComponent as PlusImg} from 'assets/img/commonVer2/plus.svg'
import withTooltip from "app/components/HOC/TooltipHOC";
import ConfirmDialog, { dialogType } from "app/components/dialog/confirmDialog/";

const ModalFileDialog = withModal(AddFileDialog)
const Table = withEndlessScrollHOC(TableComponent)
const Button = withTooltip(ButtonComponent)

export default function SimpleFilesTable({
  entityId,
  downloadFilesUrl,
  urlPartUpload,
  fileRights,
  serviceName,
  deleteMethod,
  getMethod 
}) {

  const [ showAddFile, setShowAddFile ] = useState(false)
  const [ showConfirm, setShowConifrm ] = useState(false) 
  const {
    inProgress,
    sorting,
    selectedItems,
    selectedObjects,

    setSelectedAction,
    setSelectedObjects,
    sortListAction,
    onInProgress,
  } = useTableExtensions({
    defaultSort: {
      column: 'createDate',
      direction: sortDirection.desc,
    },
  });
  
  const {
    columns,
    onFileUploaded,
    changed, 
    onFileDelete
  } = useFileTable({
    deleteFileService: (items) => service(serviceName, deleteMethod, {entityId, items}),
    urlPart: downloadFilesUrl, 
    setSelected: setSelectedAction, 
    fileRights
  })

  return (
    <>
      <div className="file-table manage-panel">
        {fileRights.CREATE ? (
          <Button 
            className='buttons-element'
            type='primary'
            onClick={() => setShowAddFile(true)}
          >
            <PlusImg className='button-image button-image--left'></PlusImg>
              <span className='button-title'>Добавить</span>
          </Button>
        ) : null} 
        <div className="buttons-element">
          <DownloadButton
            selected={{items: selectedObjects}}
            itemId={entityId}
            urlPartDownload={downloadFilesUrl}
          />
        </div>
        {fileRights.DELETE ? (
          <div className="buttons-element">
            <Button 
              tooltip={'Удалить'}
              type='image'
              onClick={() => setShowConifrm(true)}
              disabled={!selectedItems.length}
            >
              <DeleteImg className='button-image'></DeleteImg>
            </Button>
          </div>
        ) : null}
      </div>
      <Table
        loadData = {({page, pageSize}) => service(serviceName, getMethod, {entityId, page, pageSize, ...sorting})}

        sortListAction = {sortListAction}
        sorting = {sorting} 
        selectedItems = {selectedItems} 
        selectedObjects = {selectedObjects}
        setSelectedAction = {setSelectedAction}
        setSelectedObjects = {setSelectedObjects}
        dependencies = {[sorting]}
        changedRows = {changed}
        
        onInProgress = {onInProgress}
        inProgress = {inProgress}
        fieldsConfig = { columns }
        itemsSelectable = { true }
        changedFieldsVisibility = { false }
      />
      {showAddFile && <ModalFileDialog
        urlPart={urlPartUpload}
        onCancel={() => setShowAddFile(false)}
        onFileUploaded={onFileUploaded}
      /> }
      {showConfirm ? 
        <ConfirmDialog
          type={dialogType.confirm}
          needIcon={true}
          title={`Выбранные файлы будут удалены: ${selectedObjects.map(item => `${item.name}`).join(', ')}?`}
          onSubmit={() => {
            setShowConifrm(false)
            onFileDelete(selectedObjects)
          }}
          onCancel={() => setShowConifrm(false)}
        /> : null }
    </>
  )
}
