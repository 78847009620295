import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { formatFIO } from 'app/core/utility/common'
import { getResourceRights } from 'app/core/auth/auth'
import { itemActions } from 'app/components/ui/constants'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { dialogType } from 'app/components/dialog/confirmDialog/'
import { cardInProgressAction } from '../../../../redux/actions/card'
import service from '../../../services/service'
import { dispatchLogic } from '../../../../redux/actions/common'
import {
  USERCARDBLOCKUSER,
  USERCARDRESETPASSWORD,
  USERCARDUNBLOCKUSER
} from '../../../../redux/actions/actionTypes'


export default function useUserBlockControl(props){

  const {
    id,
    viewData,
    fio,
    setFio,
    isADUser,
    dispatchStore,
    commonDataForm,
    onFormChange
  } = props

  const history = useHistory()
  const dispatch = useDispatch()

  const defaultModalState = {
    confirmDeleteObject: null,
    deletedInfoObject: null,
    resetPasswordInfoObject: null,
    blockInfoObject: null,
    unblockInfoObject: null,
  }

  const [isBlockedUser, setIsBlockedUser] = useState(true)
  const [modalState, setModalState] = useState(defaultModalState)

  const profile  = useSelector(({profile}) => profile)

  const rights = getResourceRights(profile, RESOURCES.user)
  const actions = []

  if(isADUser){
    rights.UPDATE && actions.push([itemActions.cardResetPasswordDisabled.key])
    rights.UPDATE && actions.push([isBlockedUser ? itemActions.cardUnblockUserDisabled.key : itemActions.cardBlockUserDisabled.key])
    rights.DELETE && actions.push([itemActions.cardDeleteUserDisabled.key])
  } else {
    rights.UPDATE && actions.push([itemActions.cardResetPassword.key])
    rights.UPDATE && actions.push([isBlockedUser ? itemActions.cardUnblockUser.key : itemActions.cardBlockUser.key])
    rights.DELETE && actions.push([itemActions.cardDeleteUser.key])
  }

  useEffect(() => {
    const {
      lastName,
      firstName,
      middleName
    } = viewData
    setFio({
      lastName,
      firstName,
      middleName
    })
    setIsBlockedUser(viewData.status === 'Заблокирован')
  }, [viewData])

  useEffect(() => {
    setIsBlockedUser(commonDataForm.status === 'Заблокирован')
  }, [commonDataForm])



  const onBack = () => {
    history.push('/accessManagement/users')
  }

  const onDelete = () => {
    setModalState((prevState) => {
        return {
          ...prevState,
          confirmDeleteObject: {
            type: dialogType.confirm,
            title: `Вы уверены, что хотите удалить учетную запись «${formatFIO(fio)}»?`
          },
        }
      }
    )
  }

  const onResetPassword = () => {
    dispatchStore(cardInProgressAction(true))
    service('userService', 'resetPassword', id)
      .then(response => {
        dispatchStore(cardInProgressAction(false))
        const { data, errors, isError } = response;
        if (!errors && !isError) {
          setModalState((prevState) => {
              return {
                ...prevState,
                resetPasswordInfoObject: {
                  type: dialogType.message,
                  title: `Пароль сброшен. Инструкция о восстановлении пароля отправлена пользователю`
                }
              }
            }
          )
        }
        return dispatchLogic(dispatch, USERCARDRESETPASSWORD, data, isError, errors)
      })
  }

  const onConfirmDelete = ( ) => {
    setModalState((prevState) => {
        return {
          ...prevState,
          confirmDeleteObject: null,
        }
      }
    )
    dispatchStore(cardInProgressAction(true))
    service('userService', 'delete', id)
      .then(response => {
        dispatchStore(cardInProgressAction(false))
        const { data, errors, isError } = response;
        if (!errors && !isError) {
          setModalState((prevState) => {
              return {
                ...prevState,
                deletedInfoObject: {
                  type: dialogType.success,
                  title: `Учетная запись успешно удалена`
                },
              }
            }
          )
        }
        return dispatchLogic(dispatch, USERCARDBLOCKUSER, data, isError, errors)
      })
  }

  const onUnblockUser = ( ) => {
    dispatchStore(cardInProgressAction(true))
    service('userService', 'unblockUser', id)
      .then(response => {
        dispatchStore(cardInProgressAction(false))
        const { data, errors, isError } = response;
        if (!errors && !isError) {
          setModalState((prevState) => {
              return {
                ...prevState,
                unblockInfoObject: {
                  type: dialogType.message,
                  title: `Пользователь разблокирован`
                },
              }
            }
          )
          onFormChange('status', 'Активен')
        }
        return dispatchLogic(dispatch, USERCARDUNBLOCKUSER, data, isError, errors)
      })
  }

  const onBlockUser = ( ) => {
    dispatchStore(cardInProgressAction(true))
    service('userService', 'blockUser', id)
      .then(response => {
        dispatchStore(cardInProgressAction(false))
        const { data, errors, isError } = response;
        if (!errors && !isError) {
          setModalState((prevState) => {
              return {
                ...prevState,
                blockInfoObject: {
                  type: dialogType.message,
                  title: `Пользователь заблокирован`
                },
              }
            }
          )
          onFormChange('status', 'Заблокирован')
        }
        return dispatchLogic(dispatch, USERCARDUNBLOCKUSER, data, isError, errors)
      })
  }
  const onActionClick = (action) => {
    switch (action){
      case itemActions.cardResetPassword.key:
        onResetPassword()
        break
      case itemActions.cardBlockUser.key:
        onBlockUser()
        break
      case itemActions.cardUnblockUser.key:
        onUnblockUser()
        break
      case itemActions.cardDeleteUser.key:
        onDelete()
        break
      default:
        break
    }
  }

  const userCardReset = () => {
    setModalState(defaultModalState)
  }

  return {
    modalState,
    actions,
    rights,
    onConfirmDelete,
    userCardReset,
    onBack,
    onActionClick
  }
}