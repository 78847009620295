import React, {useState} from 'react'

import { formatDate } from 'app/core/utility/date'
import certificateImg from 'assets/img/common/certificate-outline.svg'
import copyImg from 'assets/img/common/content-copy.svg'
import Card from 'app/components/ui/card/card'
import withModal from 'app/components/HOC/ModalHOC'
import { copyToClipboard } from 'app/core/utility/common'

const ModalCard = withModal(Card)

const CopyValueLine = (props) => {
  const { value, currentValueFieldName, currentValue, changeValueCallback } = props

  return (
    <div className={`serial-number serial-number--${currentValue ? 'copied' : ''}`}>
      <span className={`value`}>{value}</span>
      <img src={copyImg} alt="копировать значение" title='копировать' className={`clipboard`} onClick={async () => {
        await copyToClipboard(value)
        changeValueCallback({[currentValueFieldName]: true})
        setTimeout(() => changeValueCallback({[currentValueFieldName]: false}), 300)
      }}/>
    </div>
  )
}

const signatureCardLines = () => ({
  firstGroup: {
    content: {
      certificateFio: {
        title: () => {return 'ФИО'},
        renderValue: props => {
          const { value } = props
    
          return value
        },
      },
      signatureDate: {
        title: () => {return 'Дата подписания'},
        renderValue: props => {
          const { value } = props
          const dateString = formatDate(value, 'dd.mm.yyyy')
    
          return dateString
        },
      },
      status: {
        title: () => {return 'Сертификат на момент подписания'},
        renderValue: props => {
          return 'Валидный'
        },
      },
      email: {
        title: () => {return 'Email'},
        renderValue: props => {
          const { value } = props

          return value
        },
      },
      organization: {
        title: () => {return 'Организация'},
        renderValue: props => {
          const { value } = props
    
          return value
        },
      },
      serialNumber: {
        title: () => {return 'Серийный номер'},
        renderValue: props => {
          const { value, cardProp, changeCardProp } = props
          const { serialNumber } = cardProp || {}
    
          return (
            <div className={`serial-number serial-number--${serialNumber ? 'copied' : ''}`}>
              <span className={`value`}>{value}</span>
              <img src={copyImg} alt="копировать значение" title='копировать' className={`clipboard`} onClick={async () => {
                await copyToClipboard(value)
                changeCardProp({serialNumber: true})
                setTimeout(() => changeCardProp({serialNumber: false}), 300)
              }}/>
            </div>
          ) 
        },
      },
      validThrought: {
        title: () => {return 'Срок действия'},
        renderValue: props => {
          const { value } = props
          const { from, to } = value || {}
    
          return `c ${formatDate(from, 'dd.mm.yyyy')} по ${formatDate(to, 'dd.mm.yyyy')}`
        },
      },
      keyIdentifier: {
        title: () => {return 'Идентификатор ключа субъекта'},
        renderValue: props => {
          const { value, cardProp, changeCardProp } = props
          const { keyIdentifier } = cardProp || {}
    
          return (
            <CopyValueLine
              value = {value}
              currentValueFieldName = {'keyIdentifier'}
              currentValue = {keyIdentifier}
              changeValueCallback = {changeCardProp}
            />
          )
        },
      },
      authorityKeyIdentifier: {
        title: () => {return 'Идентификатор ключа центра сертификатов'},
        renderValue: props => {
          const { value, cardProp, changeCardProp } = props
          const { authorityKeyIdentifier } = cardProp || {}
    
          return (
            <CopyValueLine
              value = {value}
              currentValueFieldName = {'authorityKeyIdentifier'}
              currentValue = {authorityKeyIdentifier}
              changeValueCallback = {changeCardProp}
            />
          )
        },
      },
      issuer: {
        title: () => {return 'Издатель сертификата'},
        renderValue: props => {
          const { value } = props
    
          return value
        },
      },
    }
  }
})

export class SignatureInfo extends React.Component {

  render () {
    const { className, signatures, changeCurrentSignature, currentSignature, iconOnly } = this.props

    return signatures && signatures.length ? (
      <div className={`last-signature-area ${className || ''}`}>
        {currentSignature ? (
          <ModalCard
            data={currentSignature}
            formLines={signatureCardLines()}
            onCancel={() => changeCurrentSignature(null)}
            renderHeader = {() => {
              return (
                <div className='modal__header'>
                  {'Реквизиты электронной подписи'}
                </div>
              )
            }}
          />
        ) : null}
        {
          signatures.map((signature) => {
            const { signFio, signatureDate } = signature

            return (
              <div className={`signature-data signature-data--${iconOnly ? 'iconOnly' : ''}`} onClick={(e) => {
                  e.stopPropagation()
                  changeCurrentSignature(signature)
                }}>
                {iconOnly ? (
                  <div className='signature-data__date'>
                    <span className="date-time">{formatDate(signatureDate, 'hh:MM, dd.mm.yyyy')}</span>
                    <div className="signature-data__data">
                      Данные подписи
                      <img className='sign-image' src={certificateImg} alt={'Даные подписи'} title = {'Даные подписи'}/>
                    </div>
                  </div>
                ) : (
                  <div className='signature-data__date'>
                    <span className="date-time">{formatDate(signatureDate, 'hh:MM, dd.mm.yyyy')}</span>
                    <span className='fio'>{signFio}</span>
                  </div>
                )}
                {iconOnly ? null : (
                  <div className="signature-data__data">
                    Данные подписи
                    <img className='sign-image' src={certificateImg} alt={'Даные подписи'} title = {'Даные подписи'}/>
                  </div>
                )}
              </div>
            )
          })
        }
      </div>
    ) : null
  }
}