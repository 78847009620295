import React, { useState } from 'react'
import { connect } from 'react-redux'

import MainCard, {objectKey} from 'app/components/mainCard/cardComponent'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Tabs from 'app/components/ui/tabs/tabs'
import History from 'app/components/history'
import {cardLines} from './cardLines'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { keyCarrierObject, keyCarrierObjectKey } from './createCard'

import * as mainCardAction from 'redux/actions/card/cardActions'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import KeyDocuments from '../relativeKeyDocuments/keyDocuments'
import BusinessEventPanel from "./businessEventPanel/KeyCarrierPanel";
import ManagePanel from "app/components/ui/managePanel/managePanel";
import { useHistory, useParams } from 'react-router-dom'


export const cardConfig = ({ 
    history,
    updateKeyCarrier,
    initKeyCarrierCard,
    breadcrumbsPushAction,
    keyCarrierId
  }) => ({
  cardLines: cardLines,
  resource: RESOURCES.keyCarrier,
  getKey: data => objectKey(data, keyCarrierObject),
  signatureHeaderText: 'Изменение ключевого носителя',
  signatureServiceName: 'keyCarrierUpdate',
  signatureEntity: 'RegisterKc',
  xlsDownloadUrl: `/api/v1/keyCarrier/${keyCarrierId}/download/xls`,
  updateAction: updateKeyCarrier,
  cardInitAction: initKeyCarrierCard,
  getSignatureData: keyCarrierObject,
  pageBreadcrumbTitle: (action) => {
    const { location } = history || {}
    const { pathname } = location || {}
    const { payload } = action
    const { id } = payload || {}

    if( !pathname ) {
      return;
    }
    
    breadcrumbsPushAction(history.location.pathname, `Ключевой носитель №${id}`)
  }
})

const KeyCarrierCard = (props) => {
  const { 
    updateKeyCarrier,
    initKeyCarrierCard,
    breadcrumbsPopAction,
    breadcrumbsPushAction,
    cardInProgressAction,
    viewData
  } = props
  const { keyCarrierId } = useParams();
  const [ activeTab, setActiveTab ] = useState('Информация');
  const [ docModal, setDocModal ] = useState( false );
  const { keyDocuments } = viewData || {}
  const history = useHistory();

  const openDocModal = () => {
    setDocModal( true );
  }

  const closeDocModal = () => {
    setDocModal( false );
  }

  return (
    <section className=' key-carrier-card'>
      <BreadcrumbsHeader />
      <Tabs 
        activeTab={activeTab} 
        onTabClick={(title) => setActiveTab(title)}
      >
        <section tabTitle={'Информация'} className="key-carrier-card__data">
          <ExpandingBlock
            renderHeader= {() => 'Реквизиты'}
            initialState={true}
          >
            <MainCard
              {...props}
              itemId={keyCarrierId}
              config={cardConfig({
                history,
                updateKeyCarrier,
                initKeyCarrierCard,
                breadcrumbsPopAction,
                breadcrumbsPushAction,
                keyCarrierId
              })}
            >
              <ManagePanel>
                <BusinessEventPanel 
                  items={viewData ? [viewData] : []}
                  onInProgress={cardInProgressAction}
                  afterAction={async () => {
                    return await initKeyCarrierCard(keyCarrierId)
                  }}
                />
              </ManagePanel>
            </MainCard>
              
          </ExpandingBlock>
          <ExpandingBlock
            renderHeader= {() => 'Ключевые документы'}
            initialState={true}
          >
            <KeyDocuments
              keyCarrierId={keyCarrierId}
              keyDocuments={keyDocuments}
              keyCarrier={viewData}
              afterActionCallback = {async () => {
                return await initKeyCarrierCard(keyCarrierId)
              }}
            />
          </ExpandingBlock>
        </section>
        <History
          tabTitle={'История'}
          serviceName={'keyCarrierService'}
          itemId={keyCarrierId}
        />
      </Tabs>
    </section>
  )
}

export default connect(
  state =>({...state.card}),
  { 
    ...mainCardAction,
    ...breadcrumbsActions
  })(KeyCarrierCard)