import { formatDate } from 'app/core/utility/date';
import { FilterControlType } from 'app/components/filter/FilterEllement';


  export const fieldsConfig = [
    { 
      title : 'Дата создания',
      alias : 'creationDate', 
      format : item => formatDate(item.creationDate, 'dd.mm.yyyy hh:MM:ss'),
      visibleOnStart : true, 
      userSorting : true, 
      width : 200
    },
    { 
      title : 'Тип запроса',
      alias : 'type', 
      format : item => { 
        const { type } = item || {}
        const { name } = type || {}
        return name 
      },
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 200
    },
    { 
      title : 'Статус', 
      alias : 'status', 
      format : item => { 
        const { status } = item || {}
        const { name } = status || {}
        return name 
      },
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 200
    },
    { 
      title : 'Дата одобрения',
      alias : 'approvalDate', 
      format : item => formatDate(item.approvalDate, 'dd.mm.yyyy hh:MM:ss'),
      visibleOnStart : true, 
      userSorting : true, 
      width : 200
    },
    { 
      title : 'Сертификат', 
      alias : 'certificateSerialNumber', 
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 400
    },
    { 
      title : 'Комментарий', 
      alias : 'comment', 
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
    },
  ]

  export const filtersConfig = [
    {
      type : FilterControlType.autocomplete, 
      alias : 'type', 
      label : 'Тип запроса', 
      serviceType : 'certRequestType',
      extended : false,
      order : 2,
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'status', 
      label : 'Статус', 
      serviceType : 'certRequestStatus',
      extended : false,
      order : 3,
    },
    { 
      type : FilterControlType.dateRangePicker, 
      alias : 'creationDate', 
      label : 'Дата запроса',
      extended : false,
      order : 4,
    },
  ]