import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from "react-router-dom";
import {useSelector} from 'react-redux'
import {Table} from 'app/components/list/Table/Table';
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import Overlay from 'app/components/ui/overlay'
import { formatFIO, stringPlural, zeroLeft } from 'app/core/utility/common'
import {ReactComponent as OrgImg }  from 'assets/img/commonVer2/ic_domain.svg';
import {ReactComponent as OKZimg }  from 'assets/img/menu/ic_directory.svg';
import { ReactComponent as DeleteImg } from 'assets/img/commonVer2/delete.svg'
import DownloadButton from 'app/components/ui/button/downloadXlsButton'
import Marked from '../ui/marked';
import { formatDate } from 'app/core/utility/date';
import service from 'app/services/service';
import {useDispatch} from 'react-redux'
import { clearErrorAction, dispatchLogic } from 'redux/actions/common';
import Button from '../ui/button/button';
import {ReactComponent as PlusImg} from 'assets/img/commonVer2/plus.svg'
import { getResourceRights } from 'app/core/auth/auth';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import ConfirmDialog, { dialogType } from 'app/components/dialog/confirmDialog/';
import SimpleSearch from '../ui/search/SimpleSearch';
import withTooltip from '../HOC/TooltipHOC';
import { sortDirection } from '../ui/constants';
import { blockedColumn } from 'app/components/ui/obsoleteTable/columns';
import DeleteOrganizations from 'app/components/organizations/DeleteOrganizations/DeleteOrganizations';

const WithTooltipButton = withTooltip(Button)

const kcConfig = {
  fieldsConfig : [
    { 
      title : <><span className='pseudo-column'>{'№'}</span>{'Наименование'}</>,
      alias : 'name', 
      type: 'treeName',
      sortByValue : item => item.name,
      format : (item, search) => {
        return (
          <>
            {blockedColumn(item)}
            <OrgImg className='pseudo-column__img'/>
            <span className='pseudo-column__id'>{zeroLeft(item.id)}</span>
            <Marked text={item.name} search={search}/>
          </>
        )},
      visibleOnStart : true, 
      userSorting : true, 
    },
    { 
      title : 'Код',
      alias : 'code', 
      sortByValue : item => item.code,
      format : item => item && item.code || '',
      visibleOnStart : true, 
      userSorting : true, 
      width : 100 
    },
    { 
      title : 'Нижестоящие ОКЗ',
      alias : 'childrenCount', 
      sortByValue : item => Number(item.childrenCount),
      format : item => item.childrenCount || '',
      visibleOnStart : true, 
      userSorting : true, 
      width : 200 
    },
    { 
      title : 'Дата изменения',
      alias : 'updateDate', 
      sortByValue : item => {
        const { entity } = item
        const { updateDate } = entity || {}

        return updateDate ? new Date(updateDate).valueOf() : 0
      },
      format : item => formatDate(item.updateDate, 'dd.mm.yyyy'),
      visibleOnStart : true, 
      userSorting : true,
      width : 200
    }
  ],
}

const getData = async () => {
  return await service('okzTreeService', 'getAllTree', {})
}

const deleteSelected = async (data) => {
  return await service('okzTreeService', 'deleteRange', data)
}

const Tree = () => {
  const {
    onInProgress,
    syncFrontTreeListSortAction,
    sortListAction,
    sortListSetAction,
    setSelectedAction,
    onToggleExpandItem,
    onTreeUpdateValues,
    onTreeInitValues,
    inProgress,
    sorting,
    selectedItems,
    list,
    paginator,
    onRemoveValues,
  } = useTableExtensions({
    defaultSort: {
      column: 'updateDate',
      direction: sortDirection.desc,
    },
  });
  const { column, direction } = sorting
  const history = useHistory()

  const pageConfig = { 
    itemsPerPage : 9999999,
    filterMultiSelect : false,
    defaultSortDirection: sortDirection.desc,
    defaultSortByAlias: 'updateDate',
    onItemClick : item => { return `/catalog/okzScheme/card/${ item.id }` },    
  }
  
  const selectedList = useMemo(() => {
    return Object.keys(selectedItems)
            .map(item => selectedItems[item])
            .filter(item => item.selected)
  }, [selectedItems])
  const globalDispatch = useDispatch()
  const [search, setString] = useState('')
  const [searchResult, setSearchResult] = useState(0)

  const { profile, error: {errors} } = useSelector(({profile, error}) => ({profile, error}))

  const onCreateClick = useCallback(
    () => history.push('/catalog/okzScheme/create'), [],
  )

  useEffect( () => { 
    syncFrontTreeListSortAction( sorting, kcConfig.fieldsConfig, list )
  }, [ sorting ]);

  async function fetchData() {
    onInProgress(true)
    const response = await getData()
    const { data, errors, isError } = response;

    isError && dispatchLogic(globalDispatch, '', null, true, errors )
    !isError && onTreeInitValues({sorting, config: kcConfig.fieldsConfig, list: data})
    onInProgress(false)
  }
  
  useEffect(() => {
    fetchData()
  }, [])

  const fieldsConfig = kcConfig[ 'fieldsConfig' ];
  const schemeRights = getResourceRights(profile, RESOURCES.okzScheme)
  const warningMessage = `Вы уверены, что хотите удалить ${stringPlural(selectedList.length, ['выбранную организацию', 'выбранные организации', 'выбранные организации'])} ${selectedList.map(item => item.item ? item.item.name : item.name).join(', ')}? `
  const selectedIds = selectedList.map(i => i.item.id)

  return (
    <section className='okzi-tree-grid'>
      {inProgress ? <Overlay /> : null}
      {errors && errors.length ? 
        <ConfirmDialog
          needIcon ={true}
          type={dialogType.warning}
          messages={errors.map(err => err.errorMessage)}
          onCancel={() => clearErrorAction()(globalDispatch)}
        /> : null
      }
      <div className="skziRegistry__header">
        <OKZimg className='header-image'/>
        Справочники : Структура органов криптозащиты
      </div>
      <div className="manage-panel">
        <div className="manage-panel__buttons">
          {schemeRights.CREATE && 
            <Button
              className='create-button'
              type='primary'
              disabled={!!inProgress}
              onClick={onCreateClick}
            >
              <PlusImg className='button-image button-image--left'></PlusImg>
              <span className='button-title'>Создать</span>
            </Button>
          }
          <div className="buttons-element">
            <DownloadButton 
              tooltip={'Выгрузить в Excel'}
              items={selectedIds}
              isPostDownload={true}
              data={{
                filter:{
                  okzOnly: true,
                  conditions: [search],
                  sortBy: column, 
                  sortDirection: direction
                },
                ids:selectedList.map(i => i.item.id),
                columns: {
                    name:'Наименование',
                    code: 'Код',
                    inn: 'ИНН',
                    kpp: 'КПП',
                    ogrn: 'ОГРН/ОГРНИП',
                  }
                }
              }
              url={`dictionaries/okz/list/xslx`}
            />
          </div>
          {schemeRights.DELETE &&
            <div className="buttons-element">
              <DeleteOrganizations
                ids={selectedList.map(i => i.item.id)}
                onInProgress={onInProgress}
                afterAction={fetchData}
                />
            </div>
          }
        </div>
        <div className="manage-panel__filter">
          <SimpleSearch
            className='filter-item'
            placeholder=''
            label='Наименование ОКЗ'
            value={search}
            onChange={setString}
            disabled={inProgress}
            noHint
          />
        </div>
      </div>
      {search && <div className="manage-panel">
          Результаты поиска: {searchResult}
      </div>}
      <Table
        isTree
        itemsSelectable
        search={search}
        searchAlias={'name'}
        setSearchResult={setSearchResult}
        onToggleExpandItem={onToggleExpandItem}
        sortListAction={sortListAction} 
        sortListSetAction={sortListSetAction}
        paginator={paginator}
        sorting={sorting}
        selectedItems={selectedItems}
        setSelectedAction={setSelectedAction} 
        inProgress={inProgress}
        list={list || []}
        fieldsConfig = { fieldsConfig }
        defaultSortByAlias = { pageConfig.defaultSortByAlias } 
        onItemClick = { pageConfig.onItemClick }
      />
    </section>
  )
}

export default Tree