import React from 'react'
import { useSelector } from "react-redux"

import Input from 'app/components/ui/Input'
import { formatDate } from 'app/core/utility/date'
import service from 'app/services/service'
import { Services, SelectType } from 'app/components/ui/picker/picker'
import AutocompletePicker from 'app/components/ui/picker/autocompletePicker/autocompletePicker'

import { notEmpty, email } from 'app/core/utility/validation'
import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'

export const rolesValidationRules = {
  roles: [
    {
      role: {
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
      },
      dateFrom: {
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
      },
    }
  ]
}

export const validationRules = {
  lastName: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  firstName: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  login: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  snils: {
    isRequired: () => false,
    validation: value => {
      return true
    },
  },
  email: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  organization: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
}

export const validationRulesADUser = ({ snilsRequired }) => ({
  snils: {
    isRequired: () => snilsRequired || typeof snilsRequired === 'undefined',
    validation: value => {
      return (snilsRequired || typeof snilsRequired === 'undefined')
        ? notEmpty(value)
        : { 
          valid: true,
          error: null,
        };
    },
  },
  email: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
})

export const cardLines = {
  firstGroup: {
    content: {
      lastName: {
        title: () => 'Фамилия',
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
        renderValue: (props) => {
          const { value } = props

          return value
        },
        renderEdit: props => {
          const { editValue, onChange, error } = props

          return (
            <Input
              placeholder = 'Введите фамилию'
              value={editValue}
              onChange={value => onChange && onChange('lastName', value)}
              error={error}
            />
          )
        },
      },
      firstName: {
        title: () => 'Имя',
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
        renderValue: (props) => {
          const { value } = props

          return value
        },
        renderEdit: props => {
          const { editValue, onChange, error } = props

          return (
            <Input
              placeholder = 'Введите имя'
              value={editValue}
              onChange={value => onChange && onChange('firstName', value)}
              error={error}
            />
          )
        },
      },
      middleName: {
        title: () => 'Отчество',
        isRequired: () => false,
        renderValue: (props) => {
          const { value } = props

          return value
        },
        renderEdit: props => {
          const { editValue, onChange, error } = props

          return (
            <Input
              placeholder = 'Введите отчество'
              value={editValue}
              onChange={value => onChange && onChange('middleName', value)}
              error={error}
            />
          )
        },
      },
      login: {
        title: () => 'Логин',
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
        renderEdit: props => {
          const { editValue, onChange, error } = props

          return (
            <Input
              placeholder = 'Введите логин'
              value={editValue}
              onChange={value => onChange && onChange('login', value)}
              error={error}
            />
          )
        },
      },
      snils: {
        title: () => 'СНИЛС',
        isRequired: () => false,
        validation: value => {
          return true
        },
        renderEdit: props => {
          const { editValue, onChange, error } = props

          return (
              <Input
                pattern='^[0-9]{0,11}$'
                placeholder = 'Введите номер'
                value={editValue}
                onChange={value => onChange && onChange('snils', value)}
                error={error}
              />
          )
        },
      },
      email: {
        title: () => 'Email',
        isRequired: () => true,
        validation: value => {
          return notEmpty(value).valid ? email(value) : notEmpty(value)
        },
        renderEdit: props => {
          const { editValue, onChange, error } = props
          return (
            <Input
              placeholder = 'Введите адрес электронной почты'
              value={editValue}
              onChange={value => onChange && onChange('email', value)}
              error={error}
            />
          )
        },
      },
      organizationId: {
        title: () => {return 'Организация'},
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
        renderValue: props => {
          const { profile, onOrganizationNavigate, value = {} } = props
          const { name, shortName } = value
          const orgRights = getResourceRights(profile, RESOURCES.organization)

          return (
            <>
              {orgRights.VIEW_CARD ? (<div className='organization-link' onClick={() => {onOrganizationNavigate && onOrganizationNavigate(value)}}>{shortName || name}</div>) : (
                shortName || name
              ) }
            </>
          )
        },
        renderEdit: props => {
          const { editValue, onChange, error } = props
          const autompleteService = {
            serviceName: 'organizationService',
            serviceMethod: 'getServedByUserAutocomplete',
            data: {}
          }

          return (
              <AutocompletePicker
                serviceType={Services.organizations}
                selectType={SelectType.single}
                notStandardService={autompleteService}
                value={editValue}
                onSelectValue={value => onChange && onChange('organizationId', value)}
                error={error}
              />
            )
        },
      },
      createDate: {
        title: () => {return 'Дата регистрации'},
        renderValue: props => {
          const { value } = props
          const dateString = formatDate(value, 'dd.mm.yyyy')

          return dateString
        },
      },
      lastPasswordSaveTime: {
        title: () => 'Последняя смена пароля',
        renderValue: props => {
          const { value } = props
          const dateString = formatDate(value, 'dd.mm.yyyy')

          return dateString
        },
      },
      lastLoginTime: {
        title: () => 'Последний вход в систему',
        renderValue: props => {
          const { value } = props
          const dateString = formatDate(value, 'dd.mm.yyyy')

          return dateString
        },
      },
      lastSessionEndReason: {
        title: () => 'Причина последнего завершения сессии',
        renderValue: props => {
          const { value } = props

          return value
        },
      },
    }
  }
}