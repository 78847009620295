import {useCallback, useEffect, useState} from "react";
import {sortDirection} from "../../../ui/constants";
import useCertificationBlockConfig from "../useCertificationBlockConfig";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import service from "../../../../services/service";
import {dispatchLogic} from "../../../../../redux/actions/common";
import {arrayToIdHashMap} from "../../../../core/utility/common";


export default function useCertificationBlock({setChanged}){
  const [selected, setSelected] = useState({
    items: [],
    selectedHashMap: {}
  })

  const setSelectedData = (selected) => {
    if (Array.isArray(selected)) {
      setSelected({
        items: selected,
        selectedHashMap: arrayToIdHashMap(selected)
      })
    } else {
      setSelected(selected)
    }
  }

  const [certificationsTypes, setCertificationsTypes] = useState([])
  const [inProgress, setInProgress] = useState(false)
  const [currentlyDeletingFiles, setCurrentlyDeletingFiles] = useState({})
  const [sort, setSort] = useState({column: 'main', direction: sortDirection.desc})
  const { column, direction } = sort
  const buildColumns = useCertificationBlockConfig({currentlyDeletingFiles, onFileDelete})
  const { skziId, versionId, buildId } = useParams()
  const dispatch = useDispatch()

  function onFileDelete(guid, id){
    setCurrentlyDeletingFiles(prevFiles => (
      {
        ...prevFiles,
        [id]: prevFiles[id] ? {...prevFiles[id], [guid]: guid} : {[guid]: guid}
      }
    ))
    return service('versionService', 'deleteBuildCertificateFile', {skziId, versionId, buildId, guid, id})
      .then(response => {
        setChanged({deletedFile: {guid, id}})
      })
  }

  useEffect(() => {
    service('versionService', 'getCertificatesTypes').then(response => {
      const { data, errors, isError } = response;
      setCertificationsTypes(data)
      dispatchLogic(dispatch, 'MOCK', data, isError, errors)
      return response
    })
  }, [])

  const getTableOptions = () => {
    return {
      sortable: {
        defaultColumn: column,
        defaultDirection: direction,
        onChange: (name, direction) => onSort(name, direction)
      },
      multipleSelect: {use: true, onCheck: onSelect},
      useActions: {use: false},
    }
  }

  const onSort = (column, direction) => {
    setSort({ column, direction })
  }

  const onSelect = selectedData => {
    setSelected(selectedData)
  }

  const fileUploaded = (res) => {
    const { item = {}, data = {}, certId } = res || {}
    const { result = {} } = data

    setChanged({addedFile: {id: item.id || certId, fileResults:[{...data, ...result}]}})
  }

  return {
    sort,
    inProgress,
    selected,
    setSelected: setSelectedData,
    setInProgress,
    buildColumns,
    getTableOptions,
    certificationsTypes,
    onFileUploaded: fileUploaded,
  }

}