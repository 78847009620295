import React, { Component } from 'react'

import Autocomplete, { Services }  from 'app/components/ui/autocomplete/autocomplete'
import Picker from 'app/components/ui/picker/simplePicker'
import Button from 'app/components/ui/button/button'
import withModal from 'app/components/HOC/ModalHOC'
import { ReactComponent as SearchImg } from 'assets/img/commonVer2/search.svg';
import { InputType } from 'app/components/ui/Input'
import styles from './autocompletePicker.module.scss'

const ModalPicker = withModal(Picker)

export default class autocompletePicker extends Component {
  constructor(props){
    super(props)

    this.state = {
      showPicker: false
    }
  }

  _onCallPicker = () => {
    this.setState({showPicker: true})
  }

  _onHidePicker = () => {
    this.setState({showPicker: false})
  }

  _onItemSelect = (item) => {
    const { onSelectValue } = this.props

    onSelectValue(item && item.length ? item[0] : item)
    this.setState({showPicker: false})
  }

  _getModalHeaderTitle = () => {
    const { serviceType } = this.props

    switch (serviceType) {
      case Services.organizations:
      case Services.organizationOkzOnly:
        return 'Выбор организации'
      default: 
        return 'Выберите значение из списка'
    }
  }

  render() {
    const { 
      error, 
      value,
      label = false,
      placeholder = false,
      serviceType, 
      notStandardService, 
      selectType,
      notStandardPickerService,
      searchPlaceholder,
      tableColumns,
      needParentClass,
      disabled,
      className
    } = this.props 
    const { showPicker } = this.state

    return (
      <div className={`autocomplete-picker ${needParentClass ? className : ''}`}>
        <Autocomplete 
          {...this.props}
          type={InputType.picker}
          onEmptySearch = {true}
          placeholder={typeof label !== 'undefined' ? '' : typeof placeholder !== 'undefined' ? placeholder : 'Начните ввод или выберите из справочника'}
          pickerOpened={showPicker}
          serviceType={serviceType}
          notStandardService={notStandardService}
          onSelect={this._onItemSelect}
          error={error}
          value={value}
          forbidEmptySearch
          CustomImage={() => 
          <div className={styles.autocompletePickerCall}>
            <Button
              type = 'image'
              disabled={disabled}
              onClick={this._onCallPicker}
            >
              <SearchImg className='button-image'></SearchImg>
            </Button>
          </div>}
        />
        {showPicker ? (
          <ModalPicker
            className={styles.modalPicker}
            serviceType={serviceType}
            selectType={selectType}
            searchPlaceholder={searchPlaceholder}
            tableColumns={tableColumns}
            notStandardService={notStandardPickerService || notStandardService}
            onCancel={this._onHidePicker} 
            onSelect={this._onItemSelect}
            renderHeader = {() => {
              return (
                <div className='modal__header'>
                  {this._getModalHeaderTitle()}
                </div>
              )
            }}
          />
        ) : null}
      </div>
    )
  }
}




