import React from 'react'

import Input from 'app/components/ui/Input'
import LicenseInput from 'app/components/ui/licenseInput'
import Autocomplete, { Services, renderItem, renderInputValue }  from 'app/components/ui/autocomplete/autocomplete'
import DatePicker from 'app/components/ui/date'
import Number from 'app/components/ui/number/number'
import { notEmpty, licenseSpecialSymbolForbidden } from 'app/core/utility/validation'
import { ReactComponent as LockImg }  from 'assets/img/commonVer2/ic_lock_on.svg'
import withTooltip from 'app/components/HOC/TooltipHOC'
import { CardState } from 'app/components/ui/card/cardMaterial'
import {OKI_CARD_TYPE, ORG_CARD_TYPE} from '../../ui/constants'
import { formatFIOInitials } from 'app/core/utility/common'

const LockedImage = withTooltip(LockImg)

export const cardLines = {
  id: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, error } = props

      return (
        <Input 
          className='card-field license-card__id'
          label = '№'
          value={editValue || 'Черновик'}
          readOnly={true}
          disabled={cardState === CardState.edit || cardState === CardState.create}
          error={error}
        />
      )
    },
  },
  activeState: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, commonDataForm, onChange, error } = props
      const { name = '' } = editValue || {}
      const { bizLocked, bizLockAuthor } = commonDataForm || {}
      const tooltip = bizLocked ? `Заблокировал: ${formatFIOInitials(bizLockAuthor)}` : ''

      return (
        <Input 
          className='card-field license-card__activeState'
          label = 'Статус'
          value={name}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
          ForcedCustomImage = {() => {
            return bizLocked 
                    ? <LockedImage tooltip={tooltip} className='locked-image'/>
                    : null
          }}
        />
      )
    },
  },
  expirationDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return cardState === CardState.view && !editValue ? (
        <Input 
          className='card-field license-card__expirationDate'
          label = 'Срок действия'
          value={'Бессрочно'}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      ) : (
        <DatePicker
          className='card-field license-card__expirationDate'
          label = 'Срок действия'
          placeholder='Бессрочно'
          readOnly={cardState === CardState.view}
          onChange={value => onChange('expirationDate', value)}
          error={error}
          value={editValue} 
          hint={cardState === CardState.create ? 'Не заполняйте поле, если срок действия не ограничен' : ''}
        />
      )
    },
  },
  updateDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <DatePicker
          className='card-field license-card__updateDate'
          label = 'Дата изменения'
          error={error}
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  skziVersion: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Autocomplete 
          label = 'СКЗИ'
          className='card-field license-card__skziVersionId'
          serviceType={Services.skziVersion} 
          readOnly={cardState === CardState.view}
          renderItem={renderItem.skziVersion}
          renderInputValue={renderInputValue.skziVersion}
          onSelect={value => onChange('skziVersion', value)}
          error={error}
          value={editValue}
        />
      )
    },
  },
  type: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Autocomplete 
          className='card-field license-card__type'
          label='Тип'
          value={editValue}
          error={error}
          readOnly={cardState === CardState.view}
          serviceType={Services.skziLicenseType} 
          onSelect={value => onChange && onChange('type', value)} 
        />)
      },
  },
  key: {
    validation: value => {
      return licenseSpecialSymbolForbidden(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <LicenseInput 
          className='card-field license-card__key'
          label = 'Лицензионный ключ'
          value={editValue || ''}
          readOnly={cardState === CardState.view}
          onChange={value => onChange('key', value)}
          error={error}
        />
      )
    },
  },
  receivedFrom: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props
      const { name = '', shortName = '', id, okz } = editValue || {}
      const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE
      return (
        <Input 
          className='card-field license-card__receivedFrom'
          label = 'Получено от'
          linkTarget={cardState === CardState.view && id && {targetType, id}}
          value={name || shortName}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  sentTo: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props
      const { name = '', shortName= '', id, okz } = editValue || {}
      const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE

      return (
        <Input 
          className='card-field license-card__sentTo'
          label = 'Передано в'
          linkTarget={cardState === CardState.view && id && {targetType, id}}
          value={name || shortName}
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },  
  amount: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Number
          className='card-field license-card__amount'
          label = 'Всего'
          placeholder='Бесконечно'
          min={0}
          // max={99999999}
          readOnly={cardState === CardState.view}
          onChange={value => onChange && onChange('amount', value)}
          value={cardState === CardState.view ? editValue || 'Бесконечно' : editValue}
          error={error}
          hint={cardState === CardState.create ? 'Не заполняйте поле, если количество лицензий не ограничено' : ''}
        />
      )
    },
  },
  usedAmount: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Number
          className='card-field license-card__usedAmount'
          label = 'Используется'
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
          value={editValue}
        />
      )
    },
  },
  vacantAmount: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Number
          className='card-field license-card__vacantAmount'
          label = 'Свободно'
          readOnly={cardState === CardState.view}
          disabled={cardState === CardState.edit || cardState === CardState.create}
          value={editValue === 0 ? editValue : editValue || 'Бесконечно'}
        />
      )
    },
  },

  comment: {
    renderEdit: (props) => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field license-card__comment'
          label = 'Примечание'
          value={editValue} 
          readOnly={cardState === CardState.view}
          error={error}
          onChange={value => onChange && onChange('comment', value)}
        />
      )
    },
  },  
}