/** @format */
import React, { useMemo } from 'react';

import Button from 'app/components/ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import { ManagePanel, Group, Border } from 'app/components/ui/managePanel';
import BusinessEventTrigger from 'app/components/ui/managePanel/businessEventTrigger/businessEventTrigger';
import Complectation from 'app/components/list/TopPanel/Complectation';
import Issuer from './UserAccountItemIssuer';
import { useModal } from 'app/hooks/useModal';
import { getResourceRights } from 'app/core/auth/auth';
import { RESOURCES } from 'app/core/auth/resourceByPage';

export default ({ issuerButtonText, getIssuerConfig, endpointType, entityType, customActions }) =>
  ({ items, userId, setInProgress, onUpdate, profile, issueDisabled = false }) => {
    const rights = getResourceRights(profile, RESOURCES.userAccount);

    const { isOpen: pickerVisible, open: openPicker, close: closePicker } = useModal();

    const issuerConfig = useMemo(() => getIssuerConfig(userId), [userId]);

    const afterActions = {
      added: ({ data }) => data && onUpdate({ added: [data] }),
      deleted: ({ data }) => data && onUpdate({ deleted: [data.id] }),
      updated: ({ data }) => data && onUpdate({ updated: [data] }),
    };

    return (
      <>
        <ManagePanel borders={Border.Bottom}>
          {rights.UPDATE ? (
            <Group>
              <Button className="issue-button" type="primary" disabled={issueDisabled} onClick={openPicker}>
                <Img img={Image.SoftSending} className="icon" />
                <span className="button-title">{issuerButtonText}</span>
              </Button>
            </Group>
          ) : null}

          {customActions.map((actionGroup, gi) => (
            <Group key={`g_${gi}`}>
              {actionGroup.map((actionConfig, ai) => {
                const { eventCode, tooltip, changeType, buttonImage } = actionConfig;
                if (eventCode === 'Complectation') {
                  return <Complectation titul={tooltip} items={items} />;
                }
                return (
                  <BusinessEventTrigger
                    key={`bet_${ai}`}
                    items={items}
                    eventCode={eventCode}
                    tooltip={tooltip}
                    endpointType={endpointType}
                    entityType={entityType}
                    afterAction={afterActions[changeType]}
                    Img={buttonImage}
                  />
                );
              })}
            </Group>
          ))}
        </ManagePanel>

        <Issuer
          userId={userId}
          config={issuerConfig}
          setInProgress={setInProgress}
          pickerVisible={pickerVisible}
          onClose={closePicker}
          onCancel={closePicker}
          afterAction={afterActions.added}
        />
      </>
    );
  };
