/** @format */
import React from 'react';

import Img, { Image } from 'app/components/ui/Img';
import Severity from 'app/core/types/severity';

import styles from './Alert.module.scss';

const severityIcon = {
  [Severity.Warning]: Image.Alert,
  [Severity.Error]: Image.AlertTriangle,
  [Severity.Warning]: Image.Alert,
};

export default function Alert({ content, severity, showIcon, className }) {
  const scopedTextSlot = typeof content === 'function';
  return (
    <div className={`alert ${styles.alert} ${styles[severity]} ${className}`}>
      {showIcon && severityIcon[severity] && (
        <Img img={severityIcon[severity]} className={`icon ${styles.icon} ${styles[severity]}`} />
      )}
      {scopedTextSlot ? content({ severity }) : content}
    </div>
  );
}
