import React, {} from 'react';
import Checkbox from 'app/components/ui/checkbox';
import { ReactComponent as SortImg } from 'assets/img/commonVer2/sort_up.svg'
import ExpandButton from './ExpandButton'
import withTooltip from 'app/components/HOC/TooltipHOC';
import { sortDirection } from 'app/components/ui/constants';
import { ReactComponent as LockImg }  from 'assets/img/commonVer2/ic_lock_on.svg'
import { getClassName } from 'app/core/utility/jsx';

const LockImage = withTooltip(LockImg)
const ExpandAll = withTooltip(ExpandButton)

const SortImage = ( props ) => {
  return <SortImg className={`button-image ${props.invert ? '' : 'button-image--rotate180'}`}/>
}

const Colgroup = ({ isTree, fieldsConfig, itemsSelectable, itemsSelectableRadio }) => {
  const treeName = fieldsConfig.find( el => el.type === 'treeName')
  const columns = isTree ? fieldsConfig.filter( el => el.type !== 'treeName' && el.type !== 'treeBlocked') : fieldsConfig
  return (
    <colgroup className = "table__colgroup">
      { itemsSelectable && <col className = "colgroup__column colgroup__column--select" /> }
      { itemsSelectableRadio && <col className = "colgroup__column colgroup__column--select" /> }
      {
        treeName &&
         <col 
          key = { treeName.alias }
          className = "colgroup__column" 
          width = { treeName.width ? `${treeName.width}px` : "" }
        />
      }
      {
        isTree && Array(9).fill(null).map((item, i) => (
          <col 
            key = { treeName.alias + i}
            className = "colgroup__column" 
            width = { treeName.width ? `${treeName.width}px` : "" }
          />
        ))
      }
      {
        columns.map( el => 
            <col 
              key = { el.alias }
              className = "colgroup__column" 
              width = { el.width ? `${el.width}px` : "" }
            />)
      }
    </colgroup>
  );
}

const Td = ({ sorting, data, sortList, headerInfo, hideHeader, strictWidth }) => {
  const TitleIcon = withTooltip(data.iconTitle);
  const titleFn = typeof data.title === 'function' ? data.title : () => data.title;

  const setTitle = () => ( headerInfo ) ? ( data.titleInfo || titleFn() ) : titleFn();
  
  const sortingClassName = getClassName([
    'button', 'sorting', 'button--image', { 'sorting--inactive': sorting?.column !== data.alias }
  ]);

  const width = data.width ? { ...strictWidth ? { minWidth: data.width, maxWidth: data.width } : { minWidth: data.width }} : {};
  return (
    <th colSpan={data.colSpan} style={width} className="table-header__header-item">
      <div style={width} className={`header-item ${hideHeader ? 'header-item--hidden' : ''}`}>
        <div className={`header-item__title ${data.iconTitle ? 'header-item__title-icon' : ''}`}>
          {
            data.iconTitle
                ? <TitleIcon tooltip={setTitle()} />
                : setTitle()
          }
        </div>
        { data.userSorting && 
          <button 
            onClick = { () => { sortList( data.alias )  }}
            className = { sortingClassName } 
            type="submit">
            <SortImage 
              invert = { sorting?.column === data.alias && sorting?.direction === sortDirection.asc} 
            />
          </button>
        }
      </div>
    </th>);
}

export default ({ 
  isTree,
  fieldsConfig, 
  sortList, 
  sorting, 
  expandAllClick,
  expandAll,
  selectAll, 
  selectAllClick,
  itemsSelectable,
  itemsSelectableRadio,
  headerInfo,
  hideHeader,
  strictWidth,
}) => {
  const treeNameHeader = fieldsConfig.find(item => item.type==='treeName') || {}
  treeNameHeader.colSpan = 9
  const restOfFields = fieldsConfig.filter(item => item.type !== 'treeName' && item.type !== 'treeBlocked')

  return (
    <>
      <Colgroup isTree={isTree} fieldsConfig = { fieldsConfig } itemsSelectable = { itemsSelectable } itemsSelectableRadio = { itemsSelectableRadio } />
      <thead className = "table__table-header">
      <tr className = "table-header__table-row">
       { itemsSelectable &&
        <th className = "table-header__header-item" key = "checkbox">        
          <div className = "check-header-item">
          <Checkbox 
              onChange={ selectAllClick  }
              checked={ selectAll }
          />
          </div>
        </th>
        }
        {isTree && (
          <>
            <th className = "table-header__header-item table-header--toggle-all" key = "expandAll">
              <div className = "check-header-item">
                <ExpandAll 
                  tooltip={expandAll ? 'Свернуть все' : 'Развернуть все'}
                  expanded = { expandAll }
                  onClick = { expandAllClick }
                ></ExpandAll>
              </div>
            </th>
            <Td headerInfo = { headerInfo } data = { treeNameHeader } key = { treeNameHeader.alias } sortList = { sortList } sorting = { sorting } strictWidth = { strictWidth } hideHeader = { hideHeader } />
          </>
        )}
        { itemsSelectableRadio &&
        <th className = "table-header__header-item" key = "radio"><div className = "check-header-item"></div></th>
        }

        { restOfFields.map( el => 
          <Td headerInfo = { headerInfo } data = { el } key = { el.alias } sortList = { sortList } sorting = { sorting } strictWidth = { strictWidth } hideHeader = { hideHeader } />
        )}
        
      </tr>
      </thead>
    </>
  );
}