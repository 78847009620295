import { 
  BREADCRUMBSPUSHITEM,
  SKZIINSTANCERUNEVENT,
  SKZIINSTANCEONRUNEVENT,  // Убрать
  CLEARERROR,
  SIGNATURERESET,
  SKZIINSTANCERESETACTION,
  ERROR,
  SKZIINSTANCEDELETE,
  USERACCOUNTCARDGET,
  CARDSEREVERUPDATE,
  CARDINIT
} from '../actionTypes'
import service from 'app/services/service'
import { dispatchLogic } from '../common'
import { getPageBreadcrumb } from 'app/header/breadcrumbs'
import { downloadFileFormHandler } from 'app/core/utility/file'
import { sortDirection } from 'app/components/ui/constants'

let serviceName = '';
let currentRequestController;
const setCancelCallback = controller => { 
  currentRequestController = controller 
}

export const unmountAction = ( type, session ) => async dispatch => {
  serviceName = '';
  dispatch({ type : 'LIST_DROP' });
  currentRequestController && currentRequestController.abort && currentRequestController.abort();
  
  // TODO сделать нормально.
  // Пока не до конца разобрался как работает подпись и не смог уменьшить связанность компонентов. 
  // Для подстраховки дублирую функционал clearErrorAction resetSignature skziInstanceResetAction
  dispatch({ type: CLEARERROR, payload: null });
  dispatch({ type: SIGNATURERESET, payload: null });
  dispatch({ type: SKZIINSTANCERESETACTION, payload: null });
}

export const getUserAccountAction = (id, path) => dispatch => {
  service('userAccountService', 'getUA', id)
    .then(response => {
      const { data, errors, isError } = response;
      dispatch({ type : 'LIST_GET', payload : response.data && response.data.eskzis });
      return dispatchLogic(dispatch, USERACCOUNTCARDGET, data, isError, errors)
    })
}

export const bookingNextAction = (data, id) => dispatch => {
  dispatch({ type: 'LIST_IN_PROGRESS_ON' });  
  return service('skziInstanceService', 'issueEvent', data ).then((result) => {
    service('userAccountService', 'getUA', id)
      .then(response => {
        dispatch({ type: 'LIST_IN_PROGRESS_OFF' });  
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, USERACCOUNTCARDGET, data, isError, errors)
      })
    return result
  })
}

export const bookingAction = data => dispatch => {
  return service('skziInstanceService', 'bookEvent', data )
    .then( response => {
      const { data, errors, isError } = response;
      if (errors && errors.length) {
        dispatchLogic(dispatch, ERROR, {}, isError, errors);
        return false;
      }else{
        return response.data.id;
      }
    });
}

export const mountAction = ( type, path, title ) => dispatch => {
  serviceName = type + 'Service';
  // dispatch({
  //   type: BREADCRUMBSPUSHITEM,
  //   payload: { 
  //     ...getPageBreadcrumb(path, title ),
  //     forceUpdate: true
  //   }
  // })
}

export const onRunEventAction = (eskzi, action) => dispatch => {
  dispatch({
    type: SKZIINSTANCEONRUNEVENT,
    payload: {eskzi, action}
  })
}

export const saveSessionAction = ( type, session ) => dispatch => {
  const t = str => str.replace(/[A-Z]/g, letter => `_${letter}`).toUpperCase();
  let temp = {};
  for( let key in session ){
    temp[ t( key ) ] = session[ key ]; 
  }
  localStorage.setItem( type, JSON.stringify( temp ));
}

export const loadSessionAction = key => dispatch => {
  const session = JSON.parse( localStorage.getItem( key ) );
  for( key in session ){
    dispatch({ type : `LIST_LOAD_${ key }`, payload : session[key] });
  }

}

export const setIdAction = id => dispatch => {
  dispatch({ type : 'LIST_SET_ID', payload : id });
}

export const removeFilterItemAction = data => dispatch => {  // Функционал Tags
  // dispatch({ type : 'LIST_REMOVE_FILTER_ITEM', payload : data });
}

export const setVisibleFieldsAction = arr => dispatch => {
  dispatch({ type : 'LIST_SET_VISIBLE_FIELDS', payload : arr  });
}

export const toggleVisibleFieldAction = alias => dispatch => {
  dispatch({ type : 'LIST_TOGGLE_VISIBLE_FIELD', payload : alias  });
}

export const selectFieldAction = fieldName => dispatch => {
  dispatch({ type : 'LIST_SELECT_FIELD', payload : fieldName  });
}

export const unselectFieldAction = fieldName => dispatch => {
  dispatch({ type : 'LIST_UNSELECT_FIELD', payload : fieldName  });
}

export const inProgressOnAction = () => dispatch => {
  dispatch({ type: 'LIST_IN_PROGRESS_ON'  });  
}

// export const disableEventPanelAction = () => dispatch => {
//   dispatch({ type: 'DISABLE.ACTION.PANEL'  });  
// }

export const inProgressOffAction = () => dispatch => {
  dispatch({ type: 'LIST_IN_PROGRESS_OFF'});  
}

export const initPagginatorAction = data => dispatch => {
  dispatch({ type: 'LIST_INIT_PAGINATOR', payload: data  });  
}

export const setCurrentPageAction = data => dispatch => {
  dispatch({ type: 'LIST_PAGINATOR_SET_CURRENT_PAGE', payload: data  });
}

export const setSelectedAction = data => dispatch => {
  dispatch({ type: 'LIST_SET_SELECTED', payload: data  });
}

export const deselectItemAction = id => dispatch => {
  dispatch({ type: 'LIST_DESELECT_ITEM', payload: id  });
}

export const dropFiltersAction = () => dispatch => {
  dispatch({ type: 'LIST_FILTER_DROP' });
}

export const setFilterStateAction = data => dispatch => {
  dispatch({ type: 'LIST_FILTER_STATE', payload: data  });
}

export const setFullFilterStateAction = data => ({ type: 'LIST_FULL_FILTER_STATE', payload: data  })

export const setFilterForRequestAction = data => dispatch => {
  return dispatch({ type: 'LIST_FILTER_REQUEST', payload: data  });
}

export const frontSortActionLogic = ( sorting, config, list  ) => {
  if( !list ) return;
  const temp = config.find( el => el.alias === sorting.column ) || {};
  if( !temp ) return;
  const getValueForSorting = temp.sortByValue || temp.format
  if( !getValueForSorting ) return;
  const collator = new Intl.Collator("en");
  return list.sort((a, b) => {
    const [valueA, valueB] = [getValueForSorting(a), getValueForSorting(b)];
    return (
      collator.compare(valueA, valueB) *
      (sorting.direction === sortDirection.desc ? -1 : 1)
    );
  });
};

export const frontTreeSortActionLogic = (sorting, config, list) => {
  if( !list || !list.length ) return list;

  const { column, direction } = sorting || {}
  const temp = config.find( el => el.alias === column );
  if( !temp ) return list;

  const getValueForSorting = temp.sortByValue || temp.format 
  if( !getValueForSorting ) return list;

  const sort = (a, b) => {
    if ( getValueForSorting(a) > getValueForSorting( b ) ) {
      return direction === sortDirection.desc ? -1 : 1;
    }else{
      return direction === sortDirection.desc ? 1 : -1;
    }
  }

  const local = JSON.parse(JSON.stringify(list))

  const sortTraverse = (list) => {
    list.sort(sort)

    list.forEach(item => {
      item.children && sortTraverse(item.children)
    })
  }

  sortTraverse(local)
  return local
}

export const listSortInFrontAction = ( sorting, config, list  ) => dispatch => {
  dispatch({ 
    type : 'LIST_GET', 
    payload : frontSortActionLogic(sorting, config, list)
  });
}

// deprecated
export const sortListAction = fieldName => dispatch => {
  dispatch({ type: 'LIST_SORT_BY', payload: fieldName  });
}

export const sortListSetAction = data => dispatch => {
  dispatch({ type: 'LIST_SORT_BY_SET', payload: data  });
} 

export const downloadExcell = data => { // Старая
  return downloadFileFormHandler( () => service( serviceName, 'downloadExcell', data), data );
}

export const downloadAction = data => {
  return downloadFileFormHandler( () => service( data.serviceName || serviceName, 'downloadExcell', data), data );
}

export const test = ( arr ) => dispatch => {
   dispatch({ type : 'LIST_REMOVED_SELECTED', payload : arr })
}

export const listFromPropsAction = list => dispatch => {
  dispatch({ type : 'LIST_GET', payload : list });
}

export const listFromPropsSortingAction = ( sortField, sortDirection, list ) => dispatch => {
  // return new Promise( ( resolve ) => {
  //   dispatch({ type : 'LIST_CLIENT_SORTING', payload : requestParams });
  //   resolve();
  // })
}

export const runEventInUserTabAction = ( data, organizationId = '' ) => dispatch => {
  const itemId = data.data.eskziId;
  dispatch({ type: 'LIST_IN_PROGRESS_ON' });  
  return service('skziInstanceService', 'runEvent', data)
    .then(response => {
      const { data, errors, isError } = response;

      if (errors && errors.length) {
        dispatchLogic(dispatch, ERROR, {}, isError, errors);
      }  
    })
}

export const runEventKeyDocumentAction = ( data, organizationId = '', type = 'keyDocument' ) => dispatch => {  // НЕ испольуется
  const itemId = data.data.eskziId;
  dispatch({ type: 'LIST_IN_PROGRESS_ON' });  
  service('keyDocumentService', 'runEvent', data)
    .then(response => {
      dispatch({ type: 'LIST_IN_PROGRESS_OFF' });  
      const { data, errors, isError } = response;

      if (errors && errors.length) {
        dispatchLogic(dispatch, ERROR, {}, isError, errors);
      }  

      const getItemData = { itemId, organizationId };
      const serviceName = `${ type }Service`
      return service( serviceName, 'getItem', { getItemData, setCancelCallback })
      .then(response => {
        dispatch({ type: 'LIST_IN_PROGRESS_OFF'});  

        const { data, errors, isError } = response;
        // if( !response ) return;
        dispatch({ type: 'LIST_GET_ITEM', payload : data });  
      })

    })
}

export const runEventKeyDocumentInCardAction = ( data, organizationId = ''  ) => dispatch => {
  const itemId = data.data.eskziId;
  dispatch({ type: 'LIST_IN_PROGRESS_ON' });  
  service('keyDocumentService', 'runEvent', data)
    .then(response => {
      dispatch({ type: 'LIST_IN_PROGRESS_OFF' });  
      
      const { data, errors, isError } = response;

      if (errors && errors.length) {
        dispatchLogic(dispatch, ERROR, {}, isError, errors);
      }   
            
      dispatch({ type: CARDSEREVERUPDATE });  
    }).then( () => {
        service('keyDocumentService', 'getKeyDocument', itemId)
        .then(response => {
          const { data, errors, isError } = response;
          
          return dispatchLogic(dispatch, CARDINIT, data, isError, errors)
        })
    })
}

export const runEventKeyCarrierInCardAction = ( data, organizationId = ''  ) => dispatch => { // НЕ используется
  const itemId = data.data.eskziId;
  dispatch({ type: 'LIST_IN_PROGRESS_ON' });  
  service('keyCarrierService', 'runEvent', data)
    .then(response => {
      dispatch({ type: 'LIST_IN_PROGRESS_OFF' });  

      const { data, errors, isError } = response;

      if (errors && errors.length) {
        dispatchLogic(dispatch, ERROR, {}, isError, errors);
      }   

      dispatch({ type: CARDSEREVERUPDATE });  
    }).then( () => {
      service('keyCarrierService', 'getKeyCarrier', itemId)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDINIT, data, isError, errors)
      })
  })
}

export const runEventKeyCarrierAction = ( data, organizationId = ''  ) => dispatch => { // НЕ используется
  const itemId = data.data.eskziId;
  dispatch({ type: 'LIST_IN_PROGRESS_ON' });  
  service('keyCarrierService', 'runEvent', data)
    .then(response => {
      dispatch({ type: 'LIST_IN_PROGRESS_OFF' });  
      const { data, errors, isError } = response;

      if (errors && errors.length) {
        dispatchLogic(dispatch, ERROR, {}, isError, errors);
      }  

      const getItemData = { itemId, organizationId };
      return service( 'keyCarrierService', 'getItem', { getItemData, setCancelCallback })
      .then(response => {
        dispatch({ type: 'LIST_IN_PROGRESS_OFF'});  

        const { data, errors, isError } = response;
        // if( !response ) return;
        dispatch({ type: 'LIST_GET_ITEM', payload : data });  
      })

    })
}

export const runEventAction = data => dispatch => {
  dispatch({ type: 'LIST_IN_PROGRESS_ON' });  
  return service('skziInstanceService', 'runEvent', data)
    .then(response => {
      dispatch({ type: 'LIST_IN_PROGRESS_OFF' });  
      const { data, errors, isError } = response;

      if (errors && errors.length) {
        dispatchLogic(dispatch, ERROR, {}, isError, errors);
      }  
      
      return response;
    })
}

export const getItemAfterEventAction = ( data, organizationId = '' ) => dispatch => {
  if (data.journalObjects) {
    dispatch({ type: 'LIST_IN_PROGRESS_ON' });  
    return Promise.all(data.journalObjects.map(itemId => {
      const getItemData = { itemId, organizationId };
      return service( serviceName, 'getItem', { getItemData, setCancelCallback }).then(response => response.data)
    }))
    .then(data => {
      dispatch({ type: 'LIST_IN_PROGRESS_OFF'});  

      console.log(data);

      data.map(item => {
        dispatch({ type: 'LIST_GET_ITEM', payload : item });  
      })
    })
  }
  const itemId = data.data.eskziId;
  const getItemData = { itemId, organizationId };
  dispatch({ type: 'LIST_IN_PROGRESS_ON' });  
  return service( serviceName, 'getItem', { getItemData, setCancelCallback })
  .then(response => {
    const { data, errors, isError } = response;
    dispatch({ type: 'LIST_IN_PROGRESS_OFF'});  
    dispatch({ type: 'LIST_GET_ITEM', payload : data });  
  })
}


export const deleteItemsAction = ( arr, name = serviceName ) => dispatch => {
  if( !name ) return;
  return service( name, 'deleteRange', arr )
    .then( response => {
      dispatch({ type : 'LIST_REMOVED', payload : response.data });
      return response;
    })
}

export const deleteRangeFromListAction = ( arr ) => dispatch => {
  if( serviceName === '' ) return;
  return service( serviceName, 'deleteRange', arr )
  .then( response => {
    const { data, errors, isError } = response;
    if (errors && errors.length) {
      dispatch({ type : 'LIST_REMOVED_SELECTED_ERROR', payload : errors });
      dispatchLogic(dispatch, SKZIINSTANCERUNEVENT, data, isError, errors)
    }else{
      dispatchLogic(dispatch, 'LIST_REMOVED_SELECTED', data, isError, errors);
    }

  })
}

export const deleteFromListAction = id => dispatch => {
  if( serviceName === '' ) return;
  return service( serviceName , 'delete', id )
    .then(response => {
      const { errors, isError } = response;
      if (errors && errors.length) {
        const { data, errors, isError } = response;
        dispatch({ type : 'LIST_REMOVED_SELECTED_ERROR', payload : errors });
      }else{
        const data = [ id ];
        dispatchLogic(dispatch, 'LIST_REMOVED_SELECTED', data, isError, errors)
      }
    })
}

export const getItemAction = ( itemId ) => dispatch => {
  if( serviceName === '' ) return;
  return service( serviceName, 'getItem', { itemId, setCancelCallback })
    .then(response => {
      const { data, errors, isError } = response;
      return dispatchLogic(dispatch, 'LIST_GET_ITEM', data, isError, errors);
    })
}

export const getListAction = ({ filterData, column, direction, id, page,
  pageSize }) => dispatch => {
  if( serviceName === '' ) return;

  return service( serviceName, 'getAll', { filterData, column, direction, page, pageSize, id, setCancelCallback })
    .then(response => {
      const { data, errors, isError } = response;
      dispatchLogic(dispatch, 'LIST_GET', data, isError, errors) // TODO это должно быть в middleware
      return response
    })
}

// to do
// добавил это чтобы выбранные элементы не сбрасывались, 
// т.к. LIST_GET сбрасывает
// после рефакторинга и ухода от страниц-конфигов это все уйдет в небытие
export const getPageAction = ({ filterData, column, direction, id, page,
  pageSize }) => dispatch => {
  if( serviceName === '' ) return;

  return service( serviceName, 'getAll', { filterData, column, direction, page, pageSize, id, setCancelCallback })
    .then(response => {
      const { data, errors, isError } = response;
      dispatchLogic(dispatch, 'LIST_PAGINATION_GET', data, isError, errors) // TODO это должно быть в middleware
      return response
    })
}

export const setBreadcrumbs = ( path ) => dispatch => {
  dispatch({
    type: BREADCRUMBSPUSHITEM,
    payload: { 
      ...getPageBreadcrumb(path),
      forceUpdate: true
    }
  })
}