import React, { useEffect, useState } from 'react'
import SimpleSearch from 'app/components/ui/search/SimpleSearch'
import TableComponent from 'app/components/ui/obsoleteTable/table';
import Tag from 'app/components/ui/tag'
import Button from 'app/components/ui/button/button'
import service from 'app/services/service'
import { deleteArrayItemById, arrayToIdHashMap, excludeByItemId } from 'app/core/utility/common'
import withEndlessScroll from 'app/components/HOC/EndlessScrollHOC'
import { getDefaultSettings, SelectType } from './picker';
import './picker.scss';

const Table = withEndlessScroll(TableComponent)

// Это копия компонента Picker (src\app\components\ui\picker\picker.jsx), 
// существование этого компонента это трагедия
// В Picker используется Filter (src\app\components\filter\index.jsx), но 
// при сборке для прода минифицированной версии
// в ней появляется ошибка, которую не получилось отследить и поправить:
// Element type is invalid: expected a string (for built-in components) or 
// a class/function (for composite components) but got: undefined.
// Возможно где-то проблемы с импортом компонента Filter или внутри него,
// но только начиная с autocompletePicker (src\app\components\ui\picker\autocompletePicker\autocompletePicker.jsx)
// В автокомплитах пикер используется без сложных фильтров, которые 
// задаются конфигом, поэтому в этом компоненте фильтра нет, есть только
// SimpleSearch. Добавление Filter собственно и вызывает ошибку. А 
// использование Picker в других местах (не в autocompletePicker) с 
// конфигом и соответственно компонентом Filter почему-то ошибок не вызывает 
export default function SimplePicker (props) {

  const { 
    className, 
    error, 
    isModal, 
    onCancel, 
    selectType,
    serviceType,
    disabledItems, 
    needBasket = true, 
    renderTagName,
    tableColumns,
    onSelect,
    notStandardService,
    excludeItems,
    alreadySelected
  } = props

  const { columns, sort: defaultSort } = getDefaultSettings(serviceType, tableColumns)
  const [ sort, setSort ] = useState(defaultSort)
  const [ search, setSearch ] = useState('')
  const [ inProgress, setInProgress ] = useState(false)
  const [ actualSearch, setActualSearch ] = useState('')
  const [ selected, setSelected ] = useState({
    items: [],
    selectedHashMap: {}
  })
  const { items: selectedItems } = selected
  const disabledRows = arrayToIdHashMap(disabledItems)

  useEffect(() => {
    if (alreadySelected && alreadySelected.length) {
      setSelected({
        items: alreadySelected,
        selectedHashMap: arrayToIdHashMap(alreadySelected) 
      })
    }
  }, [])

  const getTableOptions = () => {
    const { column, direction } = sort
  
    return {
      sortable: {
        defaultColumn: column,
        defaultDirection: direction,
        onChange: (name, direction) => setSort({column: name, direction})
      },
      [selectType]: { onCheck: setSelected},
      useActions: { use: false }
    }
  }

  const removeBasketItem = (name, item) => {
    const { items } = selected
    const newSelected = deleteArrayItemById(items, item)
    
    setSelected({ 
        items: newSelected,
        selectedHashMap: arrayToIdHashMap(newSelected)
    })
  }

  const onApply = () => {
    const { items } = selected
    onSelect(items)
  } 
  
  const { serviceName, serviceMethod, data = {}} = notStandardService || {}
  const dataService = notStandardService
                      ? serviceName && serviceMethod
                        ? ({page, pageSize}) => service(serviceName,  serviceMethod, { term: search, page, pageSize, ...sort, ...data})
                        : notStandardService
                      : ({page, pageSize}) => service(serviceType, 'picker', { term: search, page, pageSize, ...sort}) 

  return (
    <div className={`picker ${className || ''}`}>
      <SimpleSearch
        placeholder={'Поиск'}
        value={search}
        onSearch={setActualSearch}
        onChange={setSearch}
        error={error}
        disabled={inProgress}
      />
      <Table
        className="picker__table"
        loadData = {dataService}
        mapResultFunc = {i => ({
          ...i,
          tableComponentRowDisabled: !!disabledRows[i.id]
        })}
        dependencies = {[sort, actualSearch]}
        columns={columns}
        selected={selected}
        excludeItems={excludeItems}
        options={getTableOptions()}
        onFetchingData={setInProgress}
        noBorders
      />
      {selectType === SelectType.multiple && needBasket ? (
        <div className='picker__basket'>
          {selectedItems.map((item, i) => <Tag key={i} text={renderTagName ? renderTagName(item) : item.shortName || item.name} item={item} onDelete={removeBasketItem}/>)}
        </div>
      ) : null}
      {isModal ? (
        <div className="dialog__footer">
          <Button
            className='footer__item'
            type = 'primary'
            onClick = {onApply}
            disabled={selectedItems ? !selectedItems.length : true}
          >
            <span className='button-title'>Выбрать</span>
          </Button>
          <Button
            className='footer__item'
            type = 'secondary'
            onClick = {onCancel}
          >
            <span className='button-title'>Отменить</span>
          </Button>
        </div>
      ) : null}
    </div>
  )
}