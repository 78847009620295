import { useEffect } from 'react'
import Input from 'app/components/ui/Input'
import Checkbox from 'app/components/ui/checkbox'

import { statuses } from 'app/components/Notification/allNotificationPage/notificationItem'
import { decapitalize, formatFIO } from 'app/core/utility/common'
import { formatDate } from 'app/core/utility/date'
import { getOrganizationName } from 'app/components/skzi/Eskzi/replication/exemplar/exemplarReplicationForm'
import { Table } from 'app/components/list/Table/Table'
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import { sortDirection } from 'app/components/ui/constants'
import ResultButtons from './resultButtons'

const WellKnownFieldType = {
  boolean: 'Boolean',
}

const getValue = (item) => {
  const {key, value} = item || {}

  switch (key) {
    case 'eskziUser':
    case 'performer':
     return formatFIO(value)
    case 'organization':
      return getOrganizationName(value)
    case 'distributiveRemovingDate':
    case 'transmittalLetterDate':
      
      return formatDate(value, 'dd.mm.yyyy')
    case 'hardware':
      const { serialNumber } = value
      return serialNumber
    default:
      return value
  }
}

const fieldsConfig = [
  { 
    title : '№',
    titleInfo : '№', 
    alias : 'id',
    format : item => item.id ,
    visibleOnStart : true, 
    visibleAlways : true,
    userSorting : true, 
    width : 50
  },
  { 
    title : 'Наименование',
    alias : 'name', 
    format : item => item.name,
    visibleOnStart : true, 
    visibleAlways : true,
    userSorting : true, 
  },
]

const MassOperationResult = (props) => {
  const {
    status,
    eventData,
    onCancel, 
    onSubmitSignEnhance,
    getSignRequestData,
    uid,
    type
  } = props

  const { eventTemplate, eventUnits } = eventData || {}
  const { model = [], id } = eventTemplate || {}

  const data = model.reduce((acc, cur) => {
    const interfaceCode = decapitalize(cur.key).replace(/(Id){1}$/g, '')
    const resignCode = decapitalize(cur.key)
    
    acc[interfaceCode] = {
      ...cur, 
      key: interfaceCode,
      value: eventData[interfaceCode]}

    acc[resignCode] = {
        ...cur, 
        key: resignCode,
        value: eventData[resignCode]}
    
    return acc
  }, {})

  const {
    syncFrontListSortAction,
    sortListAction,
    setSelectedAction,
    setSelectedObjects,
    sorting,
    list,
    selectedItems,
    selectedObjects,
  } = useTableExtensions({
    defaultSort: {
      column: 'id',
      direction: sortDirection.desc,
    },
  });

  useEffect(() => {
    const listData = eventUnits.map(item => {
      const { entity, isSuccessful } = item

      return {
        ...entity,
        isSelected: isSuccessful,
      }
    })
    const selected = listData.filter(i => i.isSelected)

    syncFrontListSortAction( sorting, fieldsConfig, listData )
    setSelectedAction(selected.map(item => item.id))
    setSelectedObjects(selected)
  }, [eventUnits])

  useEffect( () => { 
    list && list.length && syncFrontListSortAction( sorting, fieldsConfig, list )
  }, [sorting]);

  const successEntities = eventUnits
                            .filter(i => i.isSuccessful)
                            .map(i => i.entity.id)
  const needSign = status.id === statuses.doneWithErrors

  return (
    <>
      <div className='mass-operation-result'>
        <div className='mass-operation-result__content'>
          {Object.keys(data)
            // сервер присылает коды, которые нужны ему, а интерфейсу не нужны 
            .filter(code => {
              const reg = (/(Id){1}$/g)
              return !code.match(reg)
            })
            .map(dataKey => {
            const { title, key, type: fieldType } = data[dataKey] || {}
            const val = getValue(data[dataKey])
            
            switch (fieldType) {
              case WellKnownFieldType.boolean:
                return (
                  <Checkbox
                    id={key}
                    disabled
                    className={`card-field card-field--${key}`}
                    checked={val}
                  >
                    {title}
                  </Checkbox>
                )
              default:
                return (
                  <Input
                    id={key}
                    label={title}
                    placeholder=' '
                    disabled
                    absoluteInfo
                    className={`card-field card-field--${key}`}
                    value={val}
                  />
                );
            }
          })}

        </div>
        <div className="mass-operation-result__items">
          <Table
            list={list || []}
            setSelectedAction = {()=>{}}
            selectedItems = {selectedItems}
            selectedObjects = {selectedObjects}
            sortListAction = {sortListAction}
            sorting = {sorting} 
            itemsSelectable = { true }
            fieldsConfig = {fieldsConfig}
          />
        </div>
        <ResultButtons
          callback={() => onSubmitSignEnhance(getSignRequestData({
            data,
            successEntities,
            uid,
            type: type,
            eventTemplateId: id
          }))}
          needSign={needSign}
          onCancel={onCancel}
        />
      </div>
    </>
  )
}

export default MassOperationResult