import React, { useMemo } from 'react';
import {Table} from 'app/components/list/Table/Table';
import { TableFieldSelect } from 'app/components/list/Table/TableFieldSelect';
import Overlay from 'app/components/ui/overlay';
import { useJournal } from 'app/components/journals/useJournal';
import { sortDirection } from '../ui/constants';
import { getResourceRights } from 'app/core/auth/auth';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import Pagination from '../ui/pagination/serverSidePagination/pagination';
import PageHeader from 'app/components/PageHeader/pageHeader';
import imgMap from 'app/components/ui/Img/imgMap';
import { Border, Group } from "app/components/ui/managePanel";
import ManagePanel from "app/components/ui/managePanel/managePanel";
import ListManagePanel from './ListManagePanel';
import FilterTriggerPanel from 'app/components/filter/FilterTriggerPanel';
import MainListContainer, {
  FilterWithTableContainer,
  ListDisplayVariant,
} from "app/components/ui/MainListContainer/MainListContainer";
import Filter, { GlobalOrgType } from "app/components/filter";
import styles from './list.module.scss';
import config from './config'


export default function MaintenanceList() {
  const {
    // useListFilter
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    setVisibleFieldsAction,
    toggleVisibleFieldAction,
    dropFiltersAction,
    filterState,
    filterForRequest,
    fieldsVisible,
    filterFilled,
    filterOpened,
    toggleFilterState,
    onClearFilter,

    // useTableExtensions
    sortListAction,
    inProgress,
    sorting,
    selectedItems,
    list,
    setSelectedAction,

    // global
    profile,

    //pagination
    currentPage,
    perPage,
    pageCount,
    setCurrentPage,
    selectedObjects,
    setSelectedPagination,
    totalCount,

    //
    setGlobalOrg,
    globalOrgId,
    globalOrganization,
  } = useJournal({
    defaultSort: {
      column: 'updateDate',
      direction: sortDirection.desc,
    },
    serviceName: 'maintenanceService',
  });

  const rights = useMemo(() => getResourceRights(profile, RESOURCES.maintenance), [profile])
  
  return(
    <MainListContainer>
      {inProgress && <Overlay />}
      <PageHeader title='Акты и заключения : Заключения об эксплуатации СКЗИ' Img={imgMap.Conclusion} />
      <ManagePanel borders={Border.All}>
        <ListManagePanel
          rights={rights}
          filterForRequest={filterForRequest}
          sorting={sorting}
          fieldsVisible={fieldsVisible}
          globalOrgId={globalOrgId}
          items={selectedObjects}
          inProgress={inProgress}
        />
        <Group separate>
          <FilterTriggerPanel
            toggle={toggleFilterState}
            filterFilled={filterFilled}
            onClearFilter={onClearFilter}
            disabledClear={!filterState.length}
          />
        </Group>
        <Group separate>
          <TableFieldSelect
            listFieldsVisible={fieldsVisible}
            setVisibleFieldsAction={setVisibleFieldsAction}
            toggleVisibleFieldAction={toggleVisibleFieldAction}
            headerInfo={true}
            fieldsConfig={config.fieldsConfig}
            changedFieldsVisibility={true}
            settingsAsGear={true}
          />
        </Group>
      </ManagePanel>
      <FilterWithTableContainer
        useContainer={filterOpened}
        listDisplayVariant={ListDisplayVariant.trheeRowFilter}
      >
        <Filter
          className={styles.filter}
          inProgress={inProgress}
          setGlobalOrg={setGlobalOrg}
          globalOrganization={globalOrganization}
          setFilterStateAction={setFilterStateAction}
          setFilterForRequestAction={setFilterForRequestAction}
          setFullFilterStateAction={setFullFilterStateAction}
          filterState={filterState}
          filterFilled={filterFilled}
          profile={profile}
          globalOrgId={globalOrgId}
          filtersConfig={config.tablePageFiltersConfig}
          isOpened={filterOpened}
          toggleFilterState={toggleFilterState}
          globalOrgType={GlobalOrgType.all}
          withGlobalOrg
          globalOrgPosition = {4}
        />
        <Table
          {...{
            totalCount,
            list,
            sortListAction,
            sorting,
            selectedItems,
            selectedObjects,
            setSelectedAction,
            setSelectedPagination,
            inProgress,
          }}
          listFieldsVisible={fieldsVisible}
          fieldsConfig={config.fieldsConfig}
          itemsSelectable={true}
          changedFieldsVisibility={true}
          onItemClick={item => { 
            return `/acts/maintenance/card/${ item.id }` 
          }}
          headerInfo={true}
        />
      </FilterWithTableContainer>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageCount={pageCount}
        perPage={perPage}
      />
    </MainListContainer>
  );
}