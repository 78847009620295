import React from 'react'
import Input from 'app/components/ui/Input'
import service from 'app/services/service'
import { Services, SelectType } from 'app/components/ui/picker/picker'
import AutocompletePicker from 'app/components/ui/picker/autocompletePicker/autocompletePicker'
import { CardState } from 'app/components/ui/card/cardMaterial'
import useAuthenticationForm from '../../ActiveDirectorySettings/hooks/useAuthenticationForm'
import { formatDate } from 'app/core/utility/date'
import {OKI_CARD_TYPE, ORG_CARD_TYPE} from '../../ui/constants'

export default function UserForm(props){
  const {
    cardState,
    formData = {},
    serverFormViewModel,
    onChange,
    isADUser = false,
    errorByFields = {},
  } = props;

  const {
    getError,
    getFormDataValue
  } = useAuthenticationForm({formData, serverFormViewModel, errorByFields})

  const autocompleteService = {
    serviceName: 'organizationService',
    serviceMethod: 'getServedByUserAutocomplete',
    data: {}
  }

  const pickerService = {
    serviceName: 'organizationService',
    serviceMethod: 'getServedByUserPicker',
    data: {}
  }

  const organizationValue = getFormDataValue('organization')
  const {id, okz} = organizationValue || {}
  const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE
  return (
    <>
      <Input
        id="lastName"
        className="card-field card-last-name"
        label="Фамилия"
        readOnly={cardState === CardState.view}
        disabled={isADUser && cardState === CardState.edit}
        value={getFormDataValue('lastName')}
        onChange={value => onChange('lastName', value)}
        error={getError('lastName')}
      />
      <Input
        id="firstName"
        className="card-field card-first-name"
        label="Имя"
        readOnly={cardState === CardState.view}
        disabled={isADUser && cardState === CardState.edit}
        value={getFormDataValue('firstName')}
        onChange={value => onChange('firstName', value)}
        error={getError('firstName')}
      />
      <AutocompletePicker
        id="organization"
        label="Организация"
        className="card-organization"
        serviceType={Services.organizations}
        selectType={SelectType.single}
        notStandardService={autocompleteService}
        notStandardPickerService={pickerService}
        readOnly={cardState === CardState.view}
        disabled={isADUser && cardState === CardState.edit}
        value={organizationValue}
        onSelectValue={value => {
          onChange('organization', value)
        }}
        error={getError('organization')}
        linkTarget={cardState === CardState.view && organizationValue && organizationValue.id && {targetType, id}}
      />
      <Input
        id="status"
        className="card-field card-status"
        label="Статус"
        readOnly={cardState === CardState.view}
        disabled={cardState === CardState.edit || cardState === CardState.create}
        value={getFormDataValue('status')}
        onChange={value => onChange('status', value)}
        error={getError('status')}
      />
      <Input
        id="middleName"
        className="card-field card-middle-name"
        label="Отчество"
        readOnly={cardState === CardState.view}
        disabled={isADUser && cardState === CardState.edit}
        value={getFormDataValue('middleName')}
        onChange={value => onChange('middleName', value)}
        error={getError('middleName')}
      />
      <Input
        id="snils"
        className="card-field card-snils"
        label="СНИЛС"
        readOnly={cardState === CardState.view}
        value={getFormDataValue('snils')}
        onChange={value => onChange('snils', value)}
        error={getError('snils')}
      />
      <Input
        id="createDate"
        className="card-field card-create-date"
        label="Дата регистрации"
        readOnly={cardState === CardState.view}
        disabled={cardState === CardState.edit || cardState === CardState.create}
        value={formatDate(getFormDataValue('createDate'), 'dd.mm.yyyy')}
        onChange={value => onChange('createDate', value)}
        error={getError('createDate')}
      />
      <Input
        id="lastPasswordSaveTime"
        className="card-field card-last-password-save-time"
        label="Последняя смена пароля"
        readOnly={cardState === CardState.view}
        disabled={cardState === CardState.edit || cardState === CardState.create}
        value={formatDate(getFormDataValue('lastPasswordSaveTime'), 'dd.mm.yyyy')}
        onChange={value => onChange('lastPasswordSaveTime', value)}
        error={getError('lastPasswordSaveTime')}
      />
      <Input
        id="login"
        className="card-field card-login"
        label="Логин"
        readOnly={cardState === CardState.view}
        disabled={isADUser && cardState === CardState.edit}
        value={getFormDataValue('login')}
        onChange={value => onChange('login', value)}
        error={getError('login')}
      />
      <Input
        id="email"
        className="card-field card-email"
        label="Email"
        readOnly={cardState === CardState.view}
        disabled={isADUser && cardState === CardState.edit}
        value={getFormDataValue('email')}
        onChange={value => onChange('email', value)}
        error={getError('email')}
      />
      <Input
        id="lastLoginTime"
        className="card-field card-last-login-time"
        label="Последний вход"
        readOnly={cardState === CardState.view}
        disabled={cardState === CardState.edit || cardState === CardState.create}
        value={formatDate(getFormDataValue('lastLoginTime'), 'dd.mm.yyyy')}
        onChange={value => onChange('lastLoginTime', value)}
        error={getError('lastLoginTime')}
      />
      <Input
        id="lastSessionEndReason"
        className="card-field card-last-sessions-reason"
        label="Причина завершения сессии"
        readOnly={cardState === CardState.view}
        disabled={cardState === CardState.edit || cardState === CardState.create}
        value={getFormDataValue('lastSessionEndReason')}
        onChange={value => onChange('lastSessionEndReason', value)}
        error={getError('lastSessionEndReason')}
      />

      {getFormDataValue('externalPath') && <Input
        id="adPlace"
        className="card-field card-ad-place"
        label="Расположение записи в AD"
        readOnly={cardState === CardState.view}
        disabled={cardState === CardState.edit || cardState === CardState.create}
        value={getFormDataValue('externalPath')}
        onChange={value => onChange('externalPath', value)}
        error={getError('externalPath')}
      />}
    </>
  )
}