import React from 'react'

import Input from 'app/components/ui/Input'

import Autocomplete, { Services }  from 'app/components/ui/autocomplete/autocomplete'
import { formatFIO, formatFIOInitials } from 'app/core/utility/common'
import { notEmpty } from 'app/core/utility/validation'
import { getEskziName } from 'app/components/skzi/constants'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import DatePicker from 'app/components/ui/date'
import { CardState } from 'app/components/ui/card/cardMaterial'
import { JournalType } from 'app/components/keyDocuments/card/cardLines'
import DropDown, { YesNo } from 'app/components/ui/DropDown'
import { ORG_CARD_TYPE, SKZI_REGISRTY_CARD, USER_ACCOUNT_CARD_TYPE } from 'app/components/ui/constants'
import {OKI_CARD_TYPE} from "../../ui/constants";
import { ReactComponent as LockImg }  from 'assets/img/commonVer2/ic_lock_on.svg'
import withTooltip from 'app/components/HOC/TooltipHOC'
import TextArea from 'app/components/ui/textArea'

const LockedImage = withTooltip(LockImg)

export const cardLines = {

    id: {
      validation: value => {
        return true
      },
      renderEdit: props => {
        const {  cardState, editValue, error } = props
  
        return (
          <Input
            error={error} 
            className='card-field eskzi-card__id'
            label = '№'
            value={editValue || 'Черновик'}
            readOnly={ true }
            disabled={cardState === CardState.edit}
          />
        )
      },
    },
    skziVersionId: {
      validation: value => {
        return notEmpty(value)
      },
      renderEdit: props => {
        const { cardState, editValue, onChange, error } = props
  
        return (
          <Autocomplete 
            className='card-field eskzi-card__name'
            onEmptySearch = {true} 
            // placeholder='Выберите СКЗИ'
            label='Наименование'
            serviceType={Services.skziBuild} 
            onSelect={value => onChange('skziVersionId', value)} 
            error={error}
            value={editValue}
            readOnly={cardState !== CardState.create}
            disabled={cardState === CardState.edit}
            renderInputValue = {getEskziName}
            renderItem={(item) => {
              const name = getEskziName(item)
              
              return <span key={name}>{name}</span>
            }}
          />
        )
      },
    },
    skzi: {
      renderEdit: props => {
        const { cardState, commonDataForm, error } = props
        const { skziVersionId: allData } = commonDataForm || {}
        const { skzi, version } = allData || {}
        const { name: skziName = '' } = skzi || {}
        const { name: versionName = ''} = version || {}
        const name = skziName || versionName ? `${skziName} ${versionName}` : ''

        return (
          <Input
            error={error} 
            className='card-field eskzi-card__product'
            label = 'Продукт'
            linkTarget={cardState === CardState.view && skzi && skzi.id && {targetType: SKZI_REGISRTY_CARD, id: skzi.id}}
            value={name}
            readOnly={true}
            disabled={cardState === CardState.edit}
          />
        )
      },
    },
    status: {
      renderEdit: props => {
        const { cardState, editValue, commonDataForm, onChange, error } = props
        const { name = '' } = editValue || {}
        const { bizLocked, bizLockAuthor } = commonDataForm || {}
        const tooltip = bizLocked ? `Заблокировал: ${formatFIOInitials(bizLockAuthor)}` : ''

        return (
          <Input
            error={error} 
            className='card-field eskzi-card__status'
            label = 'Статус'
            value={name}
            readOnly={ true }
            disabled={cardState === CardState.edit}
            ForcedCustomImage = {() => {
              return bizLocked 
                      ? <LockedImage tooltip={tooltip} className='locked-image'/>
                      : null
            }}
          />
        )
      },
    },
    receivedFrom: {
      validation: value => {
        return notEmpty(value)
      },
      renderEdit: props => {
        const { cardState, editValue, onChange, error } = props

        return (
          <Input
            error={error} 
            className='card-field eskzi-card__okz'
            label = 'Получено от'
            value={editValue}
            readOnly={ true }
            disabled={cardState === CardState.edit}
          />
        )
      },
    },
    sentTo: {
      renderEdit: props => {
        const { cardState, editValue, onChange, error } = props
        const { name = '', okz, id } = editValue || {}
        const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE
        return (
          <Input
            error={error} 
            className='card-field eskzi-card__oki'
            label = 'Передано в'
            linkTarget={cardState === CardState.view && id && {targetType, id}}
            value={name}
            readOnly={ true }
            disabled={cardState === CardState.edit}
          />
        )
      },
    },
    serialNumber: {
      renderEdit: props => {
        const { cardState, editValue, onChange, error } = props

        return (
          <Input
            error={error} 
            className='card-field eskzi-card__exemplar'
            label = 'Основной № (Экземпляр)'
            value={editValue}
            readOnly={cardState !== CardState.create}
            disabled={cardState === CardState.edit}
          />
        )
      },
    },
    version: {
      renderEdit: props => {
        const { cardState, editValue, onChange, error, commonDataForm } = props
        const { skziVersionId } = commonDataForm || {}
        const { version } = skziVersionId || {}
        const { name: versionName  = ''} = version || {}

        return (
          <Input
            error={error} 
            className='card-field eskzi-card__version'
            label = 'Версия'
            value={versionName}
            readOnly={true}
            disabled={cardState === CardState.edit}
          />
        )
      },
    },
    build: {
      renderEdit: props => {
        const { cardState, editValue, onChange, error, commonDataForm } = props
        const { skziVersionId } = commonDataForm || {}
        const { build } = skziVersionId || {}
        const { name: buildName = ''} = build || {}

        return (
          <Input
            error={error} 
            className='card-field eskzi-card__build'
            label = 'Сборка'
            value={buildName}
            readOnly={true}
            disabled={cardState === CardState.edit}
          />
        )
      },
    },
    updateDate: {
      renderEdit: props => {
        const { cardState, editValue, onChange, error } = props
  
        return (
          <DatePicker
            className='card-field eskzi-card__change-date'
            label='Дата изменения' 
            readOnly={ true }
            disabled={cardState === CardState.edit}
            error={error}
            value={editValue} 
          />
        )
      },
    },
    eskziUser: {
      renderEdit: props => {
        const { cardState, editValue, onChange, error } = props

        return (
          <Input
            error={error} 
            className='card-field eskzi-card__eskziUser'
            label = 'Пользователь СКЗИ'
            linkTarget={cardState === CardState.view && editValue && editValue.id && {targetType: USER_ACCOUNT_CARD_TYPE, id: editValue.id}}
            value={formatFIO(editValue)}
            readOnly={ true }
            disabled={cardState === CardState.edit}
          />
        )
      },
    },
    isStandard: {
      renderEdit: props => {
        const { 
          cardState, 
          editValue, 
          onChange, 
          error,
          cardMode, 
          commonDataForm 
        } = props
        const { skziVersionId, organizationType = {} } = commonDataForm || {}
        const { isSoftwareSkzi } = skziVersionId || {}
        const disabled = cardMode === CardState.create ? organizationType.id === 'Oki' || !isSoftwareSkzi : false
        
        return (
          <DropDown
            className='card-field eskzi-card__isStandard'
            items={YesNo}
            error={error}
            active={editValue ? YesNo[0] : YesNo[1]}
            readOnly={cardMode !== CardState.create}
            disabled={ disabled || cardState === CardState.edit}
            onSelect={value => onChange('isStandard', value.id === YesNo[0].id)}
            label='Эталон'
          />
        )
      },
    },
    organizationType: {
      validation: value => {
        return true
      },
      renderEdit: props => {
        const { 
          cardState, 
          editValue, 
          onChange, 
          error, 
          cardMode, 
          commonDataForm 
        } = props
        const { organizationType = {}, skziVersionId } = commonDataForm || {}
        const { isSoftwareSkzi } = skziVersionId || {}
        const stringValue = editValue === 'All' 
                            ? 'Органа Криптографической защиты, Организации' 
                            : editValue === JournalType[0].id 
                              ? JournalType[0].title
                              : JournalType[1].title
        return cardMode === CardState.create ? (
          <DropDown
            className='card-field eskzi-card__jornal'
            items={JournalType}
            error={error}
            active={editValue === JournalType[0].id ? JournalType[0] : JournalType[1]}
            onSelect={value => onChange('organizationType', value.id === JournalType[0].id ? JournalType[0].id : JournalType[1].id)}
            label='Учет в журнале'
            readOnly={true}
            disabled={cardState === CardState.edit}
          />
        ) : (
          <Input 
            className='card-field eskzi-card__jornal'
            label='Учет в журнале'
            value={stringValue}
            readOnly={true}
            disabled={true}
          />
        )
      },
    }, 
    hardwareNumber: {
      validation: value => {
        return true
      },
      renderEdit: props => {
        const { cardState, editValue, onChange, error } = props
  
        return (
          <Input 
            className='card-field eskzi-card__hardwareNumber'
            label = 'Номер аппаратного средства'
            onChange={value => onChange('hardwareNumber', value)}
            value={editValue}
            error={error}
            readOnly={ true }
            disabled={cardState === CardState.edit}
          />
        )
      },
    },
    comment: {
      renderEdit: (props) => {
        const { cardState, editValue, onChange, error } = props
  
        return (
          <TextArea
            className='card-field eskzi-card__description'
            value={editValue} 
            label='Примечание'
            readOnly={cardState === CardState.view}
            error={error}
            onChange={value => onChange && onChange('comment', value)}
          />
        )
      },
    },  
    serialNumbers: {
      validation: value => {
        const main = value.find(item => item.main)
        const actualValue = main ? main.number : ''
        return notEmpty(actualValue)
      },
      renderEdit: props => {
        const { cardState, editValue = [], onChange, error } = props

        return (
          <ExpandingBlock
            className='card-field eskzi-card__serialNumbers'
            renderHeader= {() => 'Регистрационные номера элементов поставки'}
            initialState={true}
          >
            {editValue.map(item => {
              const { number, objectType, id, main } = item
              const { name } = objectType || {}

              return (
                <Input
                  error={main ? error : null}
                  key={id}
                  className='card-field eskzi-card__serialNumber'
                  label = {`${name}${main ? ' (Основной)' : ''}`}
                  value = {number}
                  onChange={value => {  
                      onChange('serialNumber', {id, value})
                    }
                  }
                  readOnly={ cardState === CardState.view }
                />
              )
            })}
          </ExpandingBlock>
        )
      },
    },
  }