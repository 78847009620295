import React from 'react'

import { ReactComponent as DownloadImg } from 'assets/img/commonVer2/download.svg'
import ButtonComponent from 'app/components/ui/button/button'
import withTooltip from '../HOC/TooltipHOC'

const Button = withTooltip(ButtonComponent)

export default function downloadButton(props) {

  const { selected, urlPartDownload } = props
  const { items } = selected

  const dowloadUrl = `api/v1/${urlPartDownload}/download?id=${items.map(item => item.id).join('&id=')}`

  return (items.length ? (
      <a href={dowloadUrl} download>
        <Button 
          tooltip={'Скачать'}
          type='image'
          onClick={null}
          disabled={!items.length}
        >
          <DownloadImg className='button-image'></DownloadImg>
        </Button>
      </a>
    ) : (
      <Button 
        tooltip={'Скачать'}
        type='image'
        onClick={null}
        disabled={true}
      >
        <DownloadImg className='button-image'></DownloadImg>
      </Button>
    )
  )
}