import React, { useState } from 'react'
import Picker, {SelectType}  from './picker'
import Tag from 'app/components/ui/tag'
import withModal from 'app/components/HOC/ModalHOC'
import { deleteArrayItemById } from 'app/core/utility/common'
import Button from 'app/components/ui/button/button'
import Error from './hintAndError'
import { ReactComponent as SearchImg } from 'assets/img/commonVer2/search.svg';

const ModalPicker = withModal(Picker)

const defaultState = {
  pickerOpened: false 
}

const TagPicker = ({ 
  className,
  label,
  readOnly = false,
  disabled = false,
  currentItems = [], 
  excludeItems = [],
  onChange,
  serviceType,
  notStandardService = null,
  pickerHeaderText,
  renderTagName,
  error
}) => {

  const [ tagPickerState, setTagPickerState ] = useState(defaultState)
  const { pickerOpened } = tagPickerState
  const { errorMessage } = error || {}

  const _onHidePicker = () => setTagPickerState({...tagPickerState, pickerOpened: false})
  const _onShowPicker = () => {
    !readOnly && setTagPickerState({...tagPickerState, pickerOpened: true})
  }
  const _onItemSelect = (items) => {

    onChange([...currentItems, ...items])
    setTagPickerState({ ...tagPickerState, pickerOpened: false })
  }

  const _onTagDelete = (text, item) => {
    !readOnly && onChange(deleteArrayItemById(currentItems, item))
  }

  const _renderHeader = () => {
    return (
      <div className='modal__header'>
        {pickerHeaderText || ''}
      </div>
    )
  }

  return (
    <div className={`tag-picker ${className || ''}${readOnly ? ' tag-picker--read-only': ''}${disabled ? ' tag-picker--disabled' : ''}`}>
      <div className={`tag-picker__control ${errorMessage ? 'tag-picker--error' : ''}`}>
        {label ? <div className="tag-picker__label">{label}</div> : null}
        <div className="tag-picker__tags">
          {currentItems.map((item) => 
            <Tag 
              key={item.id}
              text={renderTagName(item)}
              item={item}
              onDelete={_onTagDelete}
            />
          )}
        </div>
        <div className="tag-picker__management">
          <Button 
            type='image'
            onClick={_onShowPicker}
          >
            <SearchImg className='tag-picker__search-button'/>
          </Button>
        </div>
      </div>
      <Error
        error={error}
      />
      {pickerOpened ? (
        <ModalPicker
          serviceType={serviceType}
          notStandardService={notStandardService}
          selectType={SelectType.multiple}
          disabledItems={currentItems}
          excludeItems={excludeItems}
          onCancel={_onHidePicker} 
          onSelect={_onItemSelect}
          renderHeader={_renderHeader}
          renderTagName={renderTagName}
          error={error}
        />
      ) : null}
    </div>
  )
}

export default TagPicker