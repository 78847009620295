import React, {useEffect, useRef, useState} from "react";

const DropDownPanel = ({initState = false, onClosePanelHandler = null, children}) => {
    const [isActive, setActiveState] = useState(initState);
    const ref = useRef(null);

    const onCLickOutHandler = (evt) => {
        if (ref.current && !ref.current.contains(evt.target)) {
            setActiveState(false);
            onClosePanelHandler && onClosePanelHandler();
        }
    };

    useEffect(() => {
        window.addEventListener('click', onCLickOutHandler);

        return () => {
            window.removeEventListener('click', onCLickOutHandler);
        };
    }, []);

    return (
        <div ref={ref} className={`circle-progressbar-menu ${isActive ? "active" : "inactive"}`}>
            {children}
        </div>
    );
};

export default DropDownPanel

