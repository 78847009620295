import React, { useState } from 'react';
import PickerComponent, { SelectType, Services } from 'app/components/ui/picker/picker';
import withModal from 'app/components/HOC/ModalHOC';
import Button from 'app/components/ui/button/button';
import styles from './relativeKeyDoc.module.scss';
import {config} from './relativeKeyDocConfig'

const Picker = withModal(PickerComponent)

export default function LinkKeyDocButton(props) {
  const { 
    className, 
    disabled = false,
    keyCarrierId,
    onSubmitSignEnhance,
  } = props 

  const [ showPicker, setShowPicker ] = useState(false)

  const pickerService = {
    serviceName: 'keyDocumentBindingService',
    serviceMethod: 'getAll',
    data: {id: keyCarrierId}
  }

  const _onSelect = async (selected) => {
    const requestData = {
      keyCarrierId : keyCarrierId,
      keyInformation : [ ...selected.map(i => i.id) ],
      signatureRequestType: 'keyCarrierRecording'
    };
    
    const result = await onSubmitSignEnhance(requestData)
    const { isError, isWarn , errors = []} = result || {}
  
    // TODO
    // видимость пикера (setShowPicker) регулируется в этом компоненте.
    // Но из-за того, что он обернут в хок подписи, надо поднимать стейт
    // видимости пикера выше и передавать в пропс afterAction ( который 
    // есть у хока ) выключение видимости пикера.
    !isError && setShowPicker(false)
  }

  return (
    <>
      <Button 
        className = { className || ''}
        type='primary'
        disabled={disabled}
        onClick = { () => setShowPicker(true) }
      >
        <span className='button-title'>Добавить связь</span>
      </Button>
      {showPicker ? (
        <Picker
          className={styles.keyDocPicker}
          serviceType={Services.keyDocument}
          filterConfig={config.filterConfig}
          notStandardService={pickerService}
          selectType={SelectType.multiple}
          onCancel={() => setShowPicker(false)} 
          onSelect={_onSelect}
          renderTagName={item => `Серийный №${ item.serialNumber }`}
          renderHeader = {() => {
            return (
              <div className='modal__header'>
                {`Ключевые документы`}
              </div>
            )
          }}
        />
      ) : null}
    </>
  )
}
