/** @format */
import { FilterControlType } from 'app/components/filter/FilterEllement';

export default [
  {
    type: FilterControlType.multiselect,
    alias: 'asyncBizTaskCodes',
    label: 'Тип уведомлений',
    extended: false,
    autosearch: true,
    notStandardService: {
      serviceName: 'notificationService',
      serviceMethod: 'getEventCodes',
    },
    order: 2,
  },
  {
    type: FilterControlType.dateRangePicker,
    alias: 'startDate',
    label: 'Дата',
    autosearch: true,
    extended: false,
    order: 3,
  },
];
