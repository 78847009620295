import React, { useEffect } from 'react';
import { compose } from 'redux'
import withModal from 'app/components/HOC/ModalHOC';
import withSignature from "app/components/HOC/withSignatureHOC";
import ExemplarReplicationForm from './exemplarReplicationForm';
import FunctionalCard from 'app/components/ui/card/FunctionalCard';
import useFunctionalCard from 'app/components/ui/card/FunctionalCard/useFunctionalCard';
import useExemplarDialog from './useExemplarDialog';
import { formatSKZI } from 'app/components/destructionCertificate/destructionObjectSet';
import Overlay from 'app/components/ui/overlay';
import { useReplicateExemplar } from './useReplicateExemplar';
import {setErrorByKeyFormAction} from "redux/actions/common";
import useToast from 'app/components/ui/toast/useToast';

const ReplicateExemplarForm = ({
  initialState,
  validLicensesCount = 0,
  onCancel,
  onSubmitSignEnhance,
  getSignRequestData,
  generateTextErrors
}) => {

  const { id } = initialState || {}

  const {
    formData
  } = useExemplarDialog({
    initialState : {
      eskziId: id,
      etalonId: formatSKZI(initialState),
      availableCount: validLicensesCount,
      transmittalLetterNumber: '',
      transmittalLetterDate: null,
      replicationItems: [], // organizationId, copiesNumber, licenses,
    }
  })

  useEffect(() => {
    onFormChange('availableCount', validLicensesCount)
  }, [validLicensesCount])

  const {
    cardState,
    editState,
    setState,
    fieldsError,
    commonDataForm,
    validate,
    onFormChange,
    dispatchStore
  } = useFunctionalCard({formData, parentEditState: true})

  useEffect(() => {
    dispatchStore(setErrorByKeyFormAction(generateTextErrors))
  }, [generateTextErrors])
  
  const { addToast } = useToast();
  
  const { replicationItems } = commonDataForm ?? {};
  const replicationItemsValid = () => !!replicationItems?.length;

  return <>  
    <FunctionalCard
      cardState={cardState}
      showButtons
      editOnly
      onSubmit={() => {
        const validationError = validate();
        
        if (validationError){
          const { field } = validationError;
          const toastMessages = {
            TransmittalLetterDate: 'Укажите дату сопроводительного письма',
            ReplicationItems: 'Добавьте экземпляры для распространения',
          };
          toastMessages[field] && addToast(toastMessages[field]);          
          return;
        }
        
        onSubmitSignEnhance(getSignRequestData(commonDataForm));
      }}
      submitEnabled={replicationItemsValid}
      submitTitle='Распространить'
      onCancelForm={onCancel}
      setState={setState}
      className="exemplar-replicate-form"
      editState={editState}
      cancelEnabled={true}
    >
      <ExemplarReplicationForm
        eskziId={id}
        cardState={cardState}
        formData={commonDataForm}
        onChange={onFormChange}
        errorByFields={fieldsError}
      />
    </FunctionalCard>
  </>
}

const ReplicateExemplarComponent = compose(withSignature, withModal)(ReplicateExemplarForm)

const ReplicateExemplar = (props) => {
  const { initialState, afterAction, onCancel } = props || {}

  const {
    getSignRequestData,
    replicateExemplarCallback,
    inProgress,
    validLicensesCount
  } = useReplicateExemplar({
    onCancel,
    initialState
  })

  return (
    <>
      {inProgress ? <Overlay /> : null}
      <ReplicateExemplarComponent
        {...props}
        validLicensesCount={validLicensesCount}
        className='replicate-exemplar-component'
        actionCallback={replicateExemplarCallback}
        getSignRequestData={getSignRequestData}
        afterAction={afterAction}
        signType='Replication'
        signHeader='Распространить экземпляры'
        renderHeader = {() => {
          return (
            <div className='modal__header'>
              {'Распространение экземпляров в организации'}
            </div>
          )
        }}
      />
    </>
  )
}

export default ReplicateExemplar;