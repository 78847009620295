import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';

import Checkbox from '../checkbox'
import Radio from 'app/components/ui/radio'
import Actions from 'app/components/ui/actionsPanel'
import { sortDirection } from '../constants'
import { arrayToIdHashMap, excludeByItemId, deleteArrayItemById } from 'app/core/utility/common'
import plusImg from 'assets/img/common/square-plus.svg'
import minusImg from 'assets/img/common/square-minus.svg'
import Button from 'app/components/ui/button/button'
import EmptyListMessage from 'app/components/list/Table/EmptyListMessage'

import { ReactComponent as SortDownImg } from 'assets/img/commonVer2/sort_down.svg'
import { ReactComponent as SortUpImg } from 'assets/img/commonVer2/sort_up.svg'
import TotalCount from 'app/components/list/Table/TotalCount';

class table extends PureComponent {
  static get propTypes() {
    return {
      className: PropTypes.string,
      noBorders: PropTypes.bool,
      options: PropTypes.shape({
        sortable: PropTypes.shape({
          use: PropTypes.bool,
          defaultColumn: PropTypes.string,
          defaultDirection: PropTypes.number,
          onChange: PropTypes.func
        }),
        multipleSelect: PropTypes.shape({
          use: PropTypes.bool,
          onCheck: PropTypes.func
        }),
        onChildToggle: PropTypes.func,
        useActions: PropTypes.shape({
          use: PropTypes.bool,
          getActions: PropTypes.func,
          onCheck: PropTypes.func
        }),
        onRowAction: PropTypes.func
      }),
      selected: PropTypes.shape({
        items: PropTypes.array,
        selectedHashMap: PropTypes.object
      }),
      items: PropTypes.arrayOf(PropTypes.object),
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          renderTemplate: PropTypes.func,
          settings: PropTypes.shape({
            show: PropTypes.shape({
              useShow: PropTypes.bool,
              visible: PropTypes.bool,
              onRemove: PropTypes.func
            }),
            useSorting: PropTypes.bool,
            width: PropTypes.number,
            hAlign: PropTypes.string,
            vAlign: PropTypes.string,
          }),
        })
      ),
      disabled: PropTypes.bool,
      inProgress: PropTypes.bool
    };
  }

  constructor(props) {
    super(props)

    this.table = React.createRef();
    this.container = React.createRef()
    this.state = this._getState(props)

    // sticky rows behaviour
    this.observer = null
    this.dummyRows = []
    this.childPairsVisibility = {}
    // ---------------------
  }

  _getState = props => {
    const { options = {} } = props
    const { sortable = {} } = options
    const { defaultColumn, defaultDirection } = sortable

    return {
      focusedIndex: {  },
      sorting: {
        sortColumn: defaultColumn,
        sortDirect: defaultDirection
      },
    }
  }

  componentDidUpdate () {
    this._registerObserver()    
  }

  scrollToTop = () => {
    this.container.current.scrollTop = 0
  }

  // stucky rows behaviour
  _registerObserver = () => {
    // try to destroy observer
    this.observer && this.observer.disconnect()
    this.observer = null

    this.childPairsVisibility = {}
    this.observer = new IntersectionObserver(this._intersectHandler);

    this.dummyRows.forEach(rowId => {
      const firstChild = document.querySelector(`#firstdummyRowFor${rowId}`)
      const lastChild = document.querySelector(`#lastdummyRowFor${rowId}`)
      firstChild && this.observer.observe(firstChild);
      lastChild && this.observer.observe(lastChild);
    })
  }

  _intersectHandler = (entries, observer) => {
    entries.forEach((entry) => {
      let parentId = entry.target.id.replace('firstdummyRowFor', '')
      let parent = document.getElementById(`firstCitizenRow${parentId}`)

      if (parent) {
        if (entry.intersectionRatio === 0) {
          parent.classList.add('table-row--sticky')
          this.childPairsVisibility[parentId] = { ...this.childPairsVisibility[parentId], firstChildVisible: false }
        } else {
          this.childPairsVisibility[parentId] = { ...this.childPairsVisibility[parentId], firstChildVisible: true }
        }
        return 
      }

      parentId = entry.target.id.replace('lastdummyRowFor', '')
      parent = document.getElementById(`firstCitizenRow${parentId}`)

      if (parent) {
        if (entry.intersectionRatio === 0) {
          const rect = entry.target.getBoundingClientRect()
          rect.top < 300 // think about better way to find out if lastChild below/above bottom screen edge
            && this.childPairsVisibility[parentId] && !this.childPairsVisibility[parentId].firstChildVisible
            && parent.classList.remove('table-row--sticky')
          this.childPairsVisibility[parentId] = { ...this.childPairsVisibility[parentId], lastChildVisible: false }
        } else {
          this.childPairsVisibility[parentId] = { ...this.childPairsVisibility[parentId], lastChildVisible: true }
          parent.classList.add('table-row--sticky')
        }
      }
    });
  }

  _handlerSelectItem = (item) => {
    const { options } = this.props
    const { singleSelect = {} } = options
    const { onCheck } = singleSelect

    onCheck({ 
      items: [item], 
      selectedHashMap: {[item.id]: item }
    })
  }

  _handlerCheckItem = item => {
    const { options, selected = {} } = this.props
    const { items: selectedItems } = selected
    const { multipleSelect = {} } = options
    const { onCheck } = multipleSelect
    const index = selectedItems.findIndex(element => element.id === item.id)
    const newSelected = index >= 0 
                          ? deleteArrayItemById(selectedItems, item)
                          : [...selectedItems, item]

    onCheck({ items: newSelected, selectedHashMap: arrayToIdHashMap(newSelected) })
  }

  _onToggleSelectAll = () => {
    const { items, options = {}, selected = {} } = this.props
    const { items: selectedItems } = selected
    const { multipleSelect = {} } = options
    const { onCheck } = multipleSelect
    const newSelected = this._isAllChecked() 
                          ? excludeByItemId(selectedItems, items)
                          : [ 
                              ...excludeByItemId(selectedItems, items), 
                              ...items.filter(item => !item.tableComponentRowDisabled)
                            ]
                            
    onCheck({ items: newSelected, selectedHashMap: arrayToIdHashMap(newSelected)})
  }

  _handlerSort = column => {
    const { options = {} } = this.props
    const { sorting = {} } = this.state
    const { sortColumn, sortDirect } = sorting
    const { sortable = {} } = options
    const { onChange } = sortable
    const { settings = {}, alias } = column
    const { useSorting = false } = settings
    const direction = sortColumn === alias 
      ? sortDirect === sortDirection.asc ? sortDirection.desc : sortDirection.asc
      : sortDirection.desc

    useSorting && this.setState({ 
      sorting: { 
        sortColumn: alias, 
        sortDirect: direction 
      } 
    })

    onChange && onChange(alias, direction)
  }

  _handlerRowClick = ({item, index}) => {
    const { options = {}, disabled = false } = this.props
    const { tableComponentRowDisabled } = item
    const { onRowAction } = options

    if (disabled || tableComponentRowDisabled) {
      return
    }
    
    onRowAction && onRowAction(item)
  }

  _handlerRemove = column => {
    const { settings = {} } = column
    const { show: { onRemove } } = settings

    onRemove && onRemove(column)
  }

  _toggleChildren = (item) => {
    const { options = {} } = this.props
    const { onChildToggle } = options

    onChildToggle && onChildToggle(item)
  }

  _isAllChecked = () => {
    const { items, selected = {} } = this.props
    const { selectedHashMap= {} } = selected
    const checkableItems = items
                            .filter(item => !item.tableComponentRowDisabled)
    const checkableItemsHashMap = arrayToIdHashMap(checkableItems)

    return Object.keys(checkableItemsHashMap).reduce((acc, cur) => {
      return acc && selectedHashMap[cur]
    }, true)
  }

  _renderTableHeader = () => {
    const { columns, options = {} } = this.props
    const { multipleSelect = null, singleSelect = null, useActions = {} } = options
    const { use: actionUse } = useActions

    return (
      <tr className='table-header__table-row'>
        {multipleSelect ? this._renderMultipleSelect() : null}
        {singleSelect ? this._renderSingleSelect() : null}
        {columns.map(this._renderColumnHeader)}
        {actionUse ? (<th className='table-header__header-item actions-th'><div className='header-item'></div></th>) : null}
      </tr>
    )
  }

  _renderMultipleSelect = () => {
    const { disabled } = this.props

    return (
      <th className='table-header__header-item'>
        <div className='check-header-item'>
          <Checkbox 
            onChange={this._onToggleSelectAll}
            checked={this._isAllChecked()}
            disabled={disabled}
          />
        </div>
      </th>
    )
  }

  _renderSingleSelect = () => {
    return (
      <th className='table-header__header-item'>
        <div className="header-item"></div>
      </th>
    )
  }

  _renderColumnHeader = (column, index) => {
    const { sorting } = this.state
    const { inProgress } = this.props 
    const { sortColumn, sortDirect } = sorting
    const { title, settings = {}, alias } = column
    const { useSorting, show = {} } = settings
    const { visible = true } = show
    const sortCss = ['sorting']

    sortColumn !== alias && sortCss.push('sorting--inactive')
    const sortDesc = sortColumn === alias && sortDirect === sortDirection.desc;

    return visible ? (
      <th key={index} className='table-header__header-item'>
        <div className="header-item">
          <div 
            className={`header-item__title`}
          >
            {title}
          </div>
          { useSorting ? (
            <Button
              className={sortCss.join(' ')} 
              type = 'image'
              onClick={() => {
                this._handlerSort(column)
              }}
              disabled={inProgress}
            >
              {sortDesc ? (
                <SortDownImg className='button-image'/>
              ) : (
                <SortUpImg className='button-image'/>
              )}
            </Button>
           ) : null}
        </div>
      </th>
    ) : null
  }

  _renderColgroup = () => {
    const { columns, options = {} } = this.props
    const { multipleSelect = false, singleSelect = false, useActions = {} } = options
    const { use: actionUse } = useActions

    return (
      <colgroup className='table__colgroup'>
        {multipleSelect || singleSelect ? (<col className='colgroup__column colgroup__column--select'/>) : null}
        {columns.map(this._renderColgroupCol)}
        {actionUse ? (<col className='colgroup__column colgroup__column--actions' width='30px'/>) : null}
      </colgroup>
    )
  }

  _renderColgroupCol = (column, index) => {
    const { settings = {} } = column
    const { show = {}, width } = settings
    const { visible = true } = show

    return visible ? (
      <col key={index} className='colgroup__column' width = { width ? width + 'px' : ''} />
    ) : null;
  }


  _renderTableRow = (item, index) => {
    const { level, visible, parentId } = item

    if (!level) {
      this.currentFirstLevelRow = item
    }

    if (parentId && !visible) {
      return null
    }

    return level 
            ? this._renderChildTableRow(item, index) 
            : this._renderFirstCitizenTableRow(item, index)
  }

  _renderSelectCell = (item) => {
    const { options = {}, disabled = false, selected = {} } = this.props
    const { selectedHashMap = {}} = selected
    const { multipleSelect, singleSelect } = options
    const { id, tableComponentRowDisabled } = item

    const isSelected = !!selectedHashMap[id]


    const disabledRow = tableComponentRowDisabled || disabled

    return (
      <>
        { multipleSelect || singleSelect ? (
          <td className='table-row__table-check'>
            <div className='td-content'>
              {multipleSelect ? (
                <Checkbox
                  onChange={() => this._handlerCheckItem(item)}
                  checked={isSelected}
                  disabled={disabledRow}
                />
              ) : null}
              {singleSelect ? (
                <Radio
                  key={id}
                  className={``}
                  value={id}
                  checkedValue={selectedHashMap[id] ? selectedHashMap[id].id : ''}
                  onChange= {() => this._handlerSelectItem(item)}
                />
              ) : null}
            </div>
          </td>
        ) : null}
      </>
    )
  }

  _renderFirstCitizenTableRow = (item, index) => {
    const { columns, selected, options = {}, disabled = false } = this.props
    const { id, childrenToggle, tableComponentRowDisabled } = item
    const { useActions = {}, onRowAction } = options
    const { use: actionUse, getActions, onActionClick } = useActions
    const cssClass = ['table-body__table-row']
    const disabledRow = tableComponentRowDisabled || disabled
    const { selectedHashMap } = selected || {}
    const rowId = `firstCitizenRow${id}`

    selectedHashMap && selectedHashMap[id] && !disabledRow && cssClass.push('table-row--focused')
    onRowAction && cssClass.push('table-row--row-action')
    disabledRow && cssClass.push('table-row--disabled')
    childrenToggle && cssClass.push('table-row--sticky')
    
    return (
      <tr id={rowId} key={rowId} className={cssClass.join(' ')} onClick={() => this._handlerRowClick({item, index})}>
        {this._renderSelectCell(item)}
        {columns.map((column, index) => this._renderTD(item, column, index))}
        {actionUse ? (
          <td className='table-row__table-actions'>
            <div className='td-content'>
             <Actions 
                disabled={disabledRow} 
                actions={getActions && getActions(item)} 
                onClick={name => onActionClick(item, name)} 
              />
            </div>
          </td>
        ): null}
      </tr>
    )
  }

  _renderChildTableRow = (item, index) => {
    const { focusedIndex } = this.state
    const { items, columns, options = {}, disabled = false } = this.props
    const { multipleSelect = {}, useActions = {} } = options
    const { use: actionUse, getActions, onActionClick } = useActions
    const cssClass = ['table-body__table-child-row table-child-row']
    const disabledRow = item.tableComponentRowDisabled || disabled
    const colspan = multipleSelect && multipleSelect.use ? 2 : 1
    focusedIndex === index && !disabledRow && cssClass.push('table-child-row--focused')
    index%2 === 0 && cssClass.push('table-child-row--even')
    
    return (
      <>
        {!items[index-1].level ? this._renderChildDummyRow() : null}
        <tr key={index} className={cssClass.join(' ')} >
          <td colSpan={colspan} className={`table-child-row__table-child-data first-cell`}>
          </td>
          {columns.filter(item => item.alias !== 'index').map((column, index) => this._renderChildTD(item, column, index))}
          {actionUse ? (
            <td className='actions-td'>
              <Actions
                disabled={disabledRow}
                actions={getActions && getActions(item)}
                onClick={name => onActionClick(item, name)}
              />
            </td>
          ): null}
        </tr>
        {items.length === index+1 || !items[index+1].level ? this._renderChildDummyRow(false) : null}
      </>
    )
  }

  _renderChildDummyRow = (first = true) => {
    const { columns, options = {} } = this.props
    const { multipleSelect = {}, useActions = {} } = options
    const visibleColumns = columns.filter(col => {
      const { settings = {} } = col
      const { show = {} } = settings
      const { visible, useShow } = show

      return useShow ? visible : true
    })
    const { id } = this.currentFirstLevelRow
    let colspan = visibleColumns.length
    multipleSelect && multipleSelect.use && colspan++
    useActions && useActions.use && colspan++

    !this.dummyRows.find((item) => item === id) && this.dummyRows.push(id)

    return (
      <tr id={`${first ? 'first' : 'last'}dummyRowFor${id}`} className={`table-body__table-child-row table-child-row table-child-row--${first ? 'first' : 'last'}-child`}>
        <td className='table-width-td' colSpan={colspan}>
        </td>
      </tr>
    )
  }

  _renderChildTD = (item, column, index) => {
    const { alias, settings = {}, renderTemplate, renderChildTemplate, isCustomRender = false } = column
    const { level } = item
    const { show = {} } = settings
    const { visible = true } = show
    const isNameColumn = index === 0

    const cssClass = ['table-child-row__table-child-data table-child-data']
    isCustomRender && cssClass.push('table-child-data--no-padding')
    isNameColumn && cssClass.push(`table-child-data--level-${Math.min(level, 5)}`)
    const template = renderChildTemplate || renderTemplate
    
    return (
      visible ? (
        <td key={index} className={cssClass.join(' ')}>
          {template ? template(item) : item[alias]}
        </td>
      ) : null
    )
  }

  _renderTD = (item, column, index) => {
    const { alias, settings = {}, renderTemplate, isCustomRender = false } = column
    const { show = {}, hAlign, vAlign } = settings
    const { visible = true } = show
    const cssClass = ['table-row__table-data']
    const isNameColumn = index === 1
                          
    isCustomRender && cssClass.push('table-data--no-padding')
    hAlign === 'center' && cssClass.push('table-data--center')
    hAlign === 'right' && cssClass.push('table-data--right')
    vAlign === 'middle' && cssClass.push('table-data--middle')
    vAlign === 'bottom' && cssClass.push('table-data--bottom')
      
    return visible ? (
      <td key={index} className={cssClass.join(' ')}>
        <div className='td-content'>
          {isNameColumn && item.hasChildren ? (
            <table className='name-with-children'>
              <tbody>
                <tr>
                  <td className='children-toggle' onClick={() => {this._toggleChildren(item)}}>
                    <img src={item.childrenToggle ? minusImg : plusImg }></img>
                  </td>
                  <td className='children-name'>{renderTemplate ? renderTemplate(item) : item[alias]}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            renderTemplate ? renderTemplate(item) : item[alias]
          )}
        </div>
      </td>
    ) : null
  }

  render() {
    const { className, noBorders, tableId, items = [], disabled, inProgress, totalCount } = this.props

    return (
      <>
        <TotalCount
          totalCount={totalCount}
        />
        <div ref={this.container} className={`table-container ${className || ''} ${noBorders ? 'table-container--no-borders' : ''}`}>
          <table id={`tableId${tableId || 'tableId'}`} className='table' ref={this.table}>
            {this._renderColgroup()}
            { items.length ? (
              <tbody className='table__table-body'>
                {items.map(this._renderTableRow)}
              </tbody>
            ) : (
              <EmptyListMessage
                count={items.length || 0}
                inProgress={inProgress}
              />
            )}
            <thead className='table__table-header'>
              {this._renderTableHeader()}
            </thead>
          </table>
        </div>
      </>
    )
  }
}

export default table