import { sortDirection } from 'app/components/ui/constants'
import { formatFIO } from 'app/core/utility/common'

export const defaultSettings = {
  columns: [
    {
      title: '№',
      alias: 'id',
      settings: {
        useSorting: true,
        width: 140
      }
    },
    {
      title: 'Наименование',
      alias: 'name',
      settings: {
        useSorting: true,
      },
    },
    {
      title: 'Серийный номер',
      alias: 'serialNumber',
      settings: {
        useSorting: true,
      },
      renderTemplate: item => {
        const { serialNumber } = item

        return serialNumber || ''
      }
    },
    {
      title: '№ экземпляра',
      alias: 'identificationNumber',
      settings: {
        useSorting: true,
        width: 200
      }
    },
    {
      title: 'Пользователь',
      alias: 'eskziUser',
      settings: {
        useSorting: true,
      },
      renderTemplate: item => {
        const { eskziUser } = item

        return formatFIO(eskziUser)
      }
    },
  ],
  sort: { 
    column: 'id',
    direction: sortDirection.desc
  }
} 