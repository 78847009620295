import React from 'react'
import { ORG_CARD_TYPE, OKI_CARD_TYPE, USER_ACCOUNT_CARD_TYPE } from 'app/components/ui/constants';
import ListLink from 'app/components/ui/ListLink';
import { formatSKZI, organizationName } from 'app/components/list/Table/TableBody';
import { licenseKey } from 'app/components/list/Table/TableBody' 
import { formatDate } from 'app/core/utility/date';
import { FilterControlType } from 'app/components/filter/FilterEllement';
import { blockedColumn } from 'app/components/ui/obsoleteTable/columns';

export const LicenseConfig = {
  fieldsConfig : [
    { 
      alias : 'bizLocked',
      format : blockedColumn,
      visibleOnStart : true, 
      visibleAlways : true,
      width : 50 
    },
    { 
      title : '№',
      alias : 'id',
      format : item => item.id,
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 50
    },
    { 
      title : 'Лицензионный ключ',
      alias : 'key',
      format : item => licenseKey(item.key),
      visibleOnStart : true, 
      userSorting : true, 
      width : 250 
    },      
    { 
      title : 'СКЗИ',
      alias : 'SkziVersion', 
      format : item => formatSKZI( item.skziVersion  ),
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true,
    },
    
    { 
      title : 'Используется', 
      alias : 'usedAmount', 
      format : item => item.usedAmount,
      visibleOnStart : true, 
      userSorting : true, 
      width : 150
    },
    { 
      title : 'Свободно', 
      alias : 'vacantAmount', 
      format : item => { 
        if( item.vacantAmount === 0 ) return '0';
        return item.vacantAmount || 'Бесконечно'
      },
      visibleOnStart : true, 
      userSorting : true, 
      width : 150
    },
    { 
      title : 'Срок действия',
      alias : 'expirationDate', 
      format : item => item.expirationDate ? formatDate( item.expirationDate, 'dd.mm.yyyy' ) : 'Бессрочно', 
      visibleOnStart : true, 
      userSorting : true, 
      width : 200
    },  
    { 
      title : 'Действует', 
      alias : 'activeState', 
      format : item => item.activeState && item.activeState.name,
      visibleOnStart : true, 
      userSorting : true, 
      width : 150 
    },
    { 
      title : 'Передано в', 
      alias : 'sentTo',
      format : item => {
        const name = organizationName( item.sentTo )
        const { id, okz } = item.sentTo || {}
        const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE
        return name && id ? <ListLink
          text={name}
          targetType={targetType}
          id={id}
        /> : name || ''
      },
      visibleOnStart : true, 
      userSorting : true,
      width : 250
    },
    { 
      title : 'Дата изменения',
      alias : 'updateDate', 
      format : item => formatDate( item.updateDate, 'dd.mm.yyyy' ), 
      visibleOnStart : true, 
      userSorting : true,
      width : 200
    }, 
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'id', 
      label : '№',
      pattern: '^[0-9]*$',
      extended : false,
      order : 2
    },
    { 
      type : FilterControlType.simpleSearch,
      alias : 'key', 
      label : 'Лицензионный ключ',
      extended : false,
      order : 3,
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'skziVersionId', 
      label : 'СКЗИ', 
      serviceType : 'skziVersion',
      extended : false,
      order : 4
    },
    {
      type : FilterControlType.multiselect, 
      alias : 'activeStates', 
      label : 'Статус', 
      serviceType : 'activeStates',
      requestEnumFormat: true,
      extended : true,
      order : 6
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'type', 
      label : 'Вид', 
      serviceType : 'license',
      requestEnumFormat: true,
      extended : true,
      order : 7,
    },
    { 
      type : FilterControlType.dateRangePicker, 
      alias : 'expirationDate', 
      label : 'Срок действия',
      extended : true,
      order : 9,
    },
    {
      type : FilterControlType.dropDown, 
      alias : 'isUsed', 
      label : 'Состояние', 
      extended : true,
      order : 10,
      options: [
        { id: 'false', title: 'Свободные'},
        { id: 'true', title: 'Используются'}
      ]
    },
  ]
}

export const ExternalLicenseConfig = {
  fieldsConfig : [
    { 
      title : '№',
      alias : 'id',
      format : item => item.id,
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 50
    },
    { 
      title : 'Лицензионный ключ',
      alias : 'key',
      format : item => licenseKey(item.key),
      visibleOnStart : true, 
      userSorting : true, 
      width : 100 
    },      
    { 
      title : 'СКЗИ',
      alias : 'skzi', 
      format : item => formatSKZI( item.skziVersion  ),
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
    },
    { 
      title : 'Срок действия',
      alias : 'expirationDate', 
      format : item => item.expirationDate ? formatDate( item.expirationDate, 'dd.mm.yyyy' ) : 'Бессрочно', 
      visibleOnStart : true, 
      userSorting : true, 
      width : 250 
    },  
    { 
      title : 'Действует', 
      alias : 'activeState', 
      format : item => item.activeState && item.activeState.name,
      visibleOnStart : true, 
      userSorting : true, 
      width : 150 
    },
    { 
      title : 'Дата изменения',
      alias : 'updateDate', 
      format : item => formatDate( item.updateDate, 'dd.mm.yyyy' ), 
      visibleOnStart : true, 
      userSorting : true, 
    }, 
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'id', 
      label : '№',
      pattern: '^[0-9]*$',
      extended : false,
      order : 2
    },
    { 
      type : FilterControlType.simpleSearch,
      alias : 'key', 
      label : 'Лицензионный ключ',
      extended : false,
      order : 3,
    },
    {
      type : FilterControlType.multiselect, 
      alias : 'activeStates', 
      label : 'Статус', 
      serviceType : 'activeStates',
      requestEnumFormat: true,
      extended : true,
      order : 4
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'skziVersionId', 
      label : 'СКЗИ', 
      serviceType : 'skziVersion',
      extended : true,
      order : 5
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'type', 
      label : 'Вид', 
      serviceType : 'license',
      requestEnumFormat: true,
      extended : true,
      order : 6,
    },
    { 
      type : FilterControlType.dateRangePicker, 
      alias : 'expirationDate', 
      label : 'Срок действия',
      extended : true,
      order : 7,
    },
    {
      type : FilterControlType.dropDown, 
      alias : 'isUsed', 
      label : 'Состояние', 
      extended : true,
      order : 8,
      options: [
        { id: 'false', title: 'Свободные'},
        { id: 'true', title: 'Используются'}
      ]
    },
  ]
}
