/** @format */
import { formatDate } from 'app/core/utility/date';
import { FilterControlType } from 'app/components/filter/FilterEllement';
import { Image } from 'app/components/ui/Img';
import styles from './list.module.scss';
import ColoredChipText, { ChipType } from 'app/components/ui/coloredChipText';

const StatusColorMap = {
  Accepted: ChipType.accent, 
  Complete: ChipType.normal, 
  Denied: ChipType.normal, 
  Errored: ChipType.error, 
  Acknowledged: ChipType.accent, 
  Pending: ChipType.accent, 
  Queued: ChipType.success, 
  Rejected: ChipType.normal, 
}

const REQUEST_TYPE_IMAGE = {
  Revocation: Image.Stop,
  Certificate: Image.Sign,
  Suspense: Image.Pause,
  Renewal: Image.Play,
};

export const fieldsConfig = [
  {
    title: 'Дата создания',
    alias: 'creationDate',
    format: item => formatDate(item.creationDate, 'dd.mm.yyyy hh:MM:ss'),
    visibleOnStart: true,
    userSorting: true,
    width: 200,
  },
  {
    title: 'Вид запроса',
    alias: 'type',
    format: item => {
      const { type } = item || {};
      const { name, id } = type || {};
      const Image = REQUEST_TYPE_IMAGE[id];
      return (
        <div className={styles.requestTypeTitle}>
          <Image />
          {name}
        </div>
      );
    },
    visibleOnStart: true,
    visibleAlways: true,
    userSorting: true,
    width: 250,
  },
  {
    title: 'Имя пользователя',
    alias: 'userName',
    format: item => {
      return item.userName;
    },
    visibleOnStart: true,
    visibleAlways: true,
    userSorting: true,
  },
  {
    title: 'Статус запроса',
    alias: 'status',
    format: item => {
      const { status } = item || {};
      const { id, name } = status || {};
      return <ColoredChipText text={name} type={StatusColorMap[id]} />
    },
    visibleOnStart: true,
    visibleAlways: true,
    userSorting: true,
    width: 250,
  },
  {
    title: 'Дата рассмотрения',
    alias: 'approvalDate',
    format: item => formatDate(item.approvalDate, 'dd.mm.yyyy hh:MM:ss'),
    visibleOnStart: true,
    userSorting: true,
    width: 240,
  },
  {
    title: 'Примечание',
    alias: 'comment',
    visibleOnStart: true,
    userSorting: true,
  },
];

export const filtersConfig = [
  {
    type: FilterControlType.simpleSearch,
    alias: 'userName',
    label: 'Имя пользователя',
    extended: false,
    requestFormatIsArray: true,
    order: 1,
  },
  {
    type: FilterControlType.autocomplete,
    alias: 'type',
    label: 'Вид запроса',
    serviceType : 'certRequestType',
    extended: false,
    order: 2,
  },
  {
    type: FilterControlType.autocomplete,
    alias: 'status',
    label: 'Статус запроса',
    serviceType : 'certRequestStatus',
    extended: false,
    order: 3,
  },
  {
    type: FilterControlType.dateRangePicker,
    alias: 'creationDate',
    label: 'Дата создания',
    extended: true,
    order: 5,
  },
  {
    type: FilterControlType.dateRangePicker,
    alias: 'approvalDate',
    label: 'Дата рассмотрения',
    extended: true,
    order: 6,
  },
];

