import React, { Component } from 'react'
import { compose } from 'redux'

import withToggle from 'app/components/HOC/ToggleHOC'
import withDocumentClick from 'app/components/HOC/DocumentClickHOC'

import Button, { buttonTypes } from 'app/components/ui/button/button'
import { ReactComponent as ChevronImg } from 'assets/img/commonVer2/chevron.svg'
import PopupPanel from 'app/components/ui/popupPanelList'
import withTooltip, { Position } from 'app/components/HOC/TooltipHOC'

const ButtonWithTooltip = withTooltip(Button)

class ButtonMenu extends Component {

  render() {
    const { 
      className, 
      title, 
      toggle, 
      toggleStatus,
      type,
      LeftImage,
      tooltip,
      needChevron = true,
      closeOnMenuClick = true,
      disabled = false,
      tooltipPosition = Position.Bottom,
    } = this.props
    
    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { toggle: toggle, toggleStatus: toggleStatus })
    );
    
    return (
      <div className={`${className || ''} button-menu`}>
        <ButtonWithTooltip
          type = {type || 'primary'}
          onClick = {toggle}
          tooltip = {tooltip}
          tooltipPosition = {tooltipPosition}
          disabled = { disabled }
          className = {`${needChevron && type === buttonTypes.image ? 'button--select' : ''}`}
        >
          {LeftImage && (
            <LeftImage className='button-image button-image--left'/>
          )}
          {title && (<span className='button-title'>{title}</span>)}
          {needChevron && (<ChevronImg className='button-image button-image--right'/>)}
        </ButtonWithTooltip>
        {toggleStatus ? (
          <PopupPanel
            {...this.props}
            items={childrenWithProps}
            closeOnMenuClick={closeOnMenuClick}
          ></PopupPanel>
          ) : null}
      </div>
    )
  }
}

export default compose(withToggle, withDocumentClick)(ButtonMenu)