import React, { useMemo, useState } from 'react'
import ButtonMenu from 'app/components/ui/buttonMenu';
import { buttonTypes } from 'app/components/ui/button/button';
import BusinessEventTrigger from 'app/components/ui/managePanel/businessEventTrigger/businessEventTrigger';

import styles from "./sendButton.module.scss";

export default function SendButton(props) {
  const { 
    items, 
    className, 
    title, 
    actionList, 
    disabled,
    entityType,
    afterAction,
    endpointType
  } = props

  const [ currentEvent, setCurrentEvent ] = useState(null)
  const applySelectedToActions = actionList.filter(action => {
    return items.every(item => {
      const { availableActions = [] } = item
      return availableActions.some(i => i.code.includes(action.eventCode))
    })
  })

  return (
    <>
      <ButtonMenu
        className = {`left_side ${styles.dropdownMenu} ${className || ''}`}
        title = { title }
        type = { buttonTypes.secondary }
        disabled = { disabled
                    || items.length === 0 
                    || items.some(i => i.bizLocked)
                    || !applySelectedToActions.length }
      >
        { applySelectedToActions.map(( el ) =>
          <div className="business-event-item"
            onClick={() => {
              setCurrentEvent(el)
            }}
          >
            {el.name}
          </div>
        )}
      </ButtonMenu>
      {currentEvent ? <BusinessEventTrigger
        items={items}
        eventCode={currentEvent.eventCode}
        tooltip={currentEvent.name}
        endpointType={endpointType}
        entityType={entityType}
        afterAction={afterAction}
        initialShowForm={true}
        onClose={()=>setCurrentEvent(null)}
      /> : null}
    </>
  )
}