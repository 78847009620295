import React, {useState} from 'react'

import AuthenticationBlock from './elements/AuthenticationBlock'
import OrganizationBlock from './elements/OrganizationBlock'
import RoleBlock from './elements/RoleBlock'
import MappingBlock from './elements/MappingBlock'
import { ReactComponent as AdmittanceImg }  from 'assets/img/commonVer2/ic_folder.svg'
import SynchronizationBlock from './elements/SynchronizationBlock'
import {useLocation} from 'react-router-dom'
import {BREADCRUMBSPUSHITEM} from '../../../redux/actions/actionTypes'
import {getPageBreadcrumb} from '../../header/breadcrumbs'
import {useDispatch} from 'react-redux'
import useErrors from '../../hooks/useErrors'


export default function ActiveDirectorySettings(){

  const [ authenticationDataValid, setAuthenticationDataValid ] = useState(false)
  const [ organizationsDataValid, setOrganizationsDataValid ] = useState(false)
  const [ rolesDataValid, setRolesDataValid ] = useState(false)
  const [ mappingDataValid, setMappingDataValid ] = useState(false)

  useErrors()

  const location = useLocation()
  const { pathname } = location
  const dispatch = useDispatch()
  dispatch({
    type: BREADCRUMBSPUSHITEM,
    payload: {
      ...getPageBreadcrumb(pathname),
      forceUpdate: true
    }
  })

  const syncAvailable = authenticationDataValid && organizationsDataValid && rolesDataValid && mappingDataValid

  return (
    <section className="active-directory-settings">
      <div className="active-directory-settings__header list__header">
        <AdmittanceImg className='header-image' />
        Настройка AD
      </div>
      <div className="active-directory-settings-area">
        <AuthenticationBlock authenticationDataValid={authenticationDataValid} setAuthenticationDataValid={setAuthenticationDataValid}/>
        <OrganizationBlock authenticationDataValid={authenticationDataValid} setOrganizationsDataValid={setOrganizationsDataValid}/>
        <RoleBlock authenticationDataValid={authenticationDataValid} setRolesDataValid={setRolesDataValid}/>
        <MappingBlock authenticationDataValid={authenticationDataValid} setMappingDataValid={setMappingDataValid}/>
        <SynchronizationBlock available={syncAvailable} />
      </div>
    </section>
  )
}