import React, { useCallback, useEffect, useRef, useState } from "react";
import Tag from "app/components/ui/tag";
import styles from "./multiSelect.module.scss";
import { getClassName } from "app/core/utility/jsx";
import MoreChip from "../chips/moreChip/MoreChip";
import { useIsVisible } from "app/components/HOC/hooks/useIsVisible";
import withTooltip from "app/components/HOC/TooltipHOC";

const TooltipTag = withTooltip(Tag) 

const MAX_CHIPS_TO_SHOW = 1;
const MIN_CHIPS_TO_SHOW = 1;

export default function MultiSelect({
  values = [],
  formatChipTitle = (i) => i.name,
  disabled,
  error,
  onFocus,
  inputValue,
  onInputChange,
  onChipDelete,
  onTogglePopup,
  onShowChipChange,
  maxChipToShow = MAX_CHIPS_TO_SHOW
}) {
  const rightTriggerRef = useRef(null);
  const topTriggerRef = useRef(null);
  const bottomTriggerRef = useRef(null);
  const isRightOnScreen = useIsVisible({ ref: rightTriggerRef, initial: true });
  const isTopOnScreen = useIsVisible({ ref: topTriggerRef, initial: true });
  const isBottomOnScreen = useIsVisible({ ref: bottomTriggerRef, initial: true });
  const [chipsToShow, setChipsToShow] = useState(maxChipToShow);
  const [isScrolled, setIsScrolled] = useState(false);
  const initialMount = useRef(true);

  useEffect(() => {
    onShowChipChange(chipsToShow)
  }, [chipsToShow])

  useEffect(() => {
    if (isScrolled) {
      setIsScrolled(false);
      return;
    }

    if ((isTopOnScreen && isBottomOnScreen) && !isRightOnScreen) {
      setChipsToShow(Math.max(MIN_CHIPS_TO_SHOW, chipsToShow - 1))
    }
  }, [isRightOnScreen]);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
      return;
    }
    setIsScrolled(true);
  }, [isTopOnScreen, isBottomOnScreen]);

  return (
    <div className={getClassName([styles.topTrigger, styles.multiSelectContainer])}>
      <div
        ref={topTriggerRef}
        className={getClassName(["top-trigger", styles.topVisibilityTrigger])}
      ></div>
      <div className={getClassName([styles.multiSelect])}>
        {values.slice(0, chipsToShow).map((item, i) => (
          <TooltipTag
            className={i === 0 ? styles.firstChip : ''}
            tooltip={formatChipTitle(item)}
            key={item.id}
            text={formatChipTitle(item)}
            item={item}
            onDelete={() => onChipDelete(item)}
            wrap
          />
        ))}
        {values.length > chipsToShow ? (
          <MoreChip
            className={styles.chipsPopup}
            title={`Ещё ${values.length - chipsToShow}`}
            onTogglePopup={onTogglePopup}
          />
        ) : null}
      </div>
      <div
        ref={rightTriggerRef}
        className={getClassName([styles.rightTrigger, styles.rightVisibilityTrigger])}
      ></div>
      <div
        ref={bottomTriggerRef}
        className={getClassName([styles.bottomTrigger, styles.bottomVisibilityTrigger])}
      ></div>
    </div>
  );
}

