import React from 'react'
import { connect } from 'react-redux'

import MainCard, { cardMode, objectKey } from 'app/components/mainCard/cardComponent'
import { getDefaultWarningDialogProps } from 'app/components/dialog/helpers'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Tabs from 'app/components/ui/tabs/tabs'
import { cardLines } from './cardData'
import { formatDate } from 'app/core/utility/date'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { CARDAFTERCREATE } from 'redux/actions/actionTypes'

import * as mainCardAction from 'redux/actions/card/cardActions'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import { useHistory } from 'react-router-dom'

export const okzData = (data) => {
  const { 
    serviceOkz,
    name,
    shortName,
    ogrn,
    inn,
    kpp,
    code,
    description
  } = data || {}
  const { id } = serviceOkz || {}

  return { 
    serviceOkzId: id,
    name,
    shortName,
    ogrn,
    inn,
    kpp,
    code,
    description,
    okz: true,
  }
}

export const cardConfig = ({ 
    history,
    registerOkzSchemeOrganization,
    initOkzSchemeCreate,
    breadcrumbsPopAction,
    breadcrumbsPushAction
  }) => ({
  cardLines: cardLines,
  getKey: data => objectKey(data, okzData),
  resource: RESOURCES.okzScheme,
  signatureHeaderText: 'Создание ОКЗ',
  signatureServiceName: '',
  signatureEntity: '',
  updateAction: registerOkzSchemeOrganization,
  cardInitAction: initOkzSchemeCreate,
  onCancel: () => {history.push('/catalog/okzScheme')},
  afterUpdate: (requestResult) => {
    const { payload, type } = requestResult || {}
    const { id } = payload || {}

    if (type === CARDAFTERCREATE) {
      breadcrumbsPopAction()  
      history.push(`/catalog/okzScheme/card/${id}`)
    }
  },
  getSignatureData: okzData,
  pageBreadcrumbTitle: () => {
    breadcrumbsPushAction(history.location.pathname)
  },
  getServerWarning: getDefaultWarningDialogProps,
})

const CreateCard = (props) => {
  const { 
    registerOkzSchemeOrganization,
    initOkzSchemeCreate,
    breadcrumbsPopAction,
    breadcrumbsPushAction,
  } = props
  
  const history = useHistory();

  return (
    <section className='main-card okz-sheme-card'>
      <BreadcrumbsHeader />
      <Tabs 
        activeTab={'Информация'} 
        onTabClick={null}
      >
        <section tabTitle={'Информация'} className="main-card__data">
          <ExpandingBlock
            renderHeader= {() => 'Реквизиты'}
            initialState={true}
          >
            <MainCard
              {...props}
              config={cardConfig({
                history,
                registerOkzSchemeOrganization,
                initOkzSchemeCreate,
                breadcrumbsPopAction,
                breadcrumbsPushAction
              })}
              cardMode={cardMode.create}
              editOnOpen={true}
            > 
            </MainCard>
          </ExpandingBlock>
        </section>
        <div
          tabTitle={'Нижестоящие ОКЗ'}
          disabled={true}
        />
        <div
          tabTitle={'Организации'}
          disabled={true}
        />
        {/* <div
          tabTitle={'Экземпляры СКЗИ'}
          disabled={true}
        />
        <div
          tabTitle={'Лицензии'}
          disabled={true}
        /> */}
      </Tabs>
    </section>
  )
}

export default connect(
  state =>({...state.card}),
  { 
    ...mainCardAction,
    ...breadcrumbsActions
  })(CreateCard)