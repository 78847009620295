import React, { useMemo } from "react";
import Group from "app/components/ui/managePanel/group";
import ManagePanel from "app/components/ui/managePanel/managePanel";
import { useJournal } from "app/components/journals/useJournal";
import { sortDirection } from "app/components/ui/constants";
import { TableFieldSelect } from "app/components/list/Table/TableFieldSelect";
import { Table } from "app/components/list/Table/Table";
import Pagination from "app/components/ui/pagination/serverSidePagination/pagination";
import PageHeader from "../../PageHeader/pageHeader";
import Overlay from "app/components/ui/overlay";
import CreateCardButton from "app/components/skzi/EskziList/createCardButton";
import { useHistory } from "react-router-dom";
import BusinessEventPanel from "./businessEventPanel/OkzPanel";
import { Border } from "app/components/ui/managePanel";
import { OkzConfig as config } from "./pageConfig";
import { getResourceRights } from "app/core/auth/auth";
import { RESOURCES } from "app/core/auth/resourceByPage";
import MainListContainer, {
  FilterWithTableContainer,
  ListDisplayVariant,
} from "app/components/ui/MainListContainer/MainListContainer";
import FilterTriggerPanel from "app/components/filter/FilterTriggerPanel";
import styles from "./keyDocumentList.module.scss";
import Filter, { GlobalOrgType } from "app/components/filter";
import Images from 'app/components/ui/Img/imgMap';

export default function OkzList() {
  const history = useHistory();
  const {
    // useListFilter
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    setVisibleFieldsAction,
    toggleVisibleFieldAction,
    filterState,
    filterForRequest,
    fieldsVisible,
    filterFilled,
    onClearFilter,

    // useReducerTable
    onInProgress,
    sortListAction,
    inProgress,
    sorting,
    selectedItems,
    list,
    setSelectedAction,
    updateAfterMassOperation,

    // global
    profile,

    //pagination
    currentPage,
    perPage,
    pageCount,
    setCurrentPage,
    selectedObjects,
    setSelectedPagination,
    totalCount,

    //
    setGlobalOrg,
    globalOrgId,
    globalOrganization,
    filterOpened,
    toggleFilterState,
  } = useJournal({
    defaultSort: {
      column: "updateDate",
      direction: sortDirection.desc,
    },
    serviceName: "keyDocOkzService",
    useFirstOkz: true
  });

  const rights = useMemo(() => getResourceRights(profile, RESOURCES.eskzi), [profile]);
  const massImportRights = useMemo(() => getResourceRights(profile, RESOURCES.massImportKeyDocument), [profile]);

  return (
    <MainListContainer>
      {inProgress && <Overlay />}
      <PageHeader title='Ключевые документы : Орган криптозащиты' Img={Images.KeyDocument} />
      <ManagePanel borders={Border.All}>
        {rights.CREATE ? (
          <Group>
            <CreateCardButton
              inProgress={inProgress}
              title={"Зарегистрировать"}
              needCheckLicense='keyDocument'
              onClick={() => {
                history.push("/keyDocument/okz/create?type=okz");
              }}
            />
          </Group>
        ) : null}
        <BusinessEventPanel
          filterForRequest={filterForRequest}
          sorting={sorting}
          fieldsVisible={fieldsVisible}
          globalOrgId={globalOrgId}
          items={selectedObjects}
          rights={rights}
          massImportRights={massImportRights}
          afterAction={updateAfterMassOperation}
          setSelectedAction={setSelectedAction}
          onInProgress={onInProgress}
        />
        <Group separate>
          <FilterTriggerPanel
            toggle={toggleFilterState}
            filterFilled={filterFilled}
            onClearFilter={onClearFilter}
            disabledClear={!filterState.length}
          />
        </Group>
        <Group separate>
          <TableFieldSelect
            listFieldsVisible={fieldsVisible}
            setVisibleFieldsAction={setVisibleFieldsAction}
            toggleVisibleFieldAction={toggleVisibleFieldAction}
            headerInfo={true}
            fieldsConfig={config.fieldsConfig}
            changedFieldsVisibility={true}
            settingsAsGear={true}
          />
        </Group>
      </ManagePanel>
      <FilterWithTableContainer
        useContainer={filterOpened}
        listDisplayVariant={ListDisplayVariant.fourRowFilter}
      >
        <Filter
          className={styles.okzFilter}
          inProgress={inProgress}
          setGlobalOrg={setGlobalOrg}
          globalOrganization={globalOrganization}
          setFilterStateAction={setFilterStateAction}
          setFilterForRequestAction={setFilterForRequestAction}
          setFullFilterStateAction={setFullFilterStateAction}
          filterState={filterState}
          filterFilled={filterFilled}
          profile={profile}
          filtersConfig={config.tablePageFiltersConfig}
          isOpened={filterOpened}
          toggleFilterState={toggleFilterState}
          globalOrgId={globalOrgId}
          globalOrgPosition={4}
          withGlobalOrg
          globalOrgType={GlobalOrgType.okzOnly}
        />
        <Table
          {...{
            totalCount,
            list,
            sortListAction,
            sorting,
            selectedItems,
            selectedObjects,
            setSelectedAction,
            setSelectedPagination,
            inProgress,
          }}
          listFieldsVisible={fieldsVisible}
          fieldsConfig={config.fieldsConfig}
          itemsSelectable={true}
          changedFieldsVisibility={true}
          onItemClick={(item) => {
            return `/keyDocument/okz/card/${item.id}`;
          }}
          headerInfo={true}
        />
      </FilterWithTableContainer>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageCount={pageCount}
        perPage={perPage}
      />
    </MainListContainer>
  );
}