import React, { useState } from 'react';
import ReplicateExemplar from './exemplar';
import ReplicateEtalon from './etalon';
import ButtonMenuImage from 'app/components/ui/ButtonMenuImage';

import styles from './replicationButton.module.scss';

export const countLicenseOKZ = (licenses) => {
  if (!licenses || !licenses.length) {
    return 0
  }

  return licenses.length
}

const ReplicateButton = ({
  initialState,
  afterAction,
  disabled
}) => {
  const [ showExemplar, setShowExemplar ]  = useState(false)
  const [ showEtalon, setShowEtalon ]  = useState(false)

  return(
    <>
      <ButtonMenuImage
        title = { 'Распространить' }
        disabled = { disabled }
        img = { 'EtalonSqReplicationImg' }
        className = {`replication-button ${styles.replicationButton}`}
        needChevron = {true}
      >
        <div onClick={() => setShowExemplar(true)}>
          Распространение экземпляров
        </div>
        <div onClick={() => setShowEtalon(true)}>
          Распространение эталона
        </div>
      </ButtonMenuImage>
      {showExemplar ? (
        <ReplicateExemplar 
          initialState={initialState}
          afterAction={afterAction}
          onCancel={() => setShowExemplar(false)}
        />
      ) : null}
      {showEtalon ? (
        <ReplicateEtalon
          initialState={initialState}
          afterAction={afterAction}
          onCancel={() => setShowEtalon(false)}
        />
      ) : null}
    </>
  );
}

export default ReplicateButton;
