import React from 'react'

import Input from 'app/components/ui/Input'
import { notEmpty } from 'app/core/utility/validation'
import { formatDate } from 'app/core/utility/date'
import { getEskziName } from 'app/components/skzi/constants'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import DatePicker from 'app/components/ui/date'

export const cardLinesExternal = {

    id: {
      validation: value => {
        return true
      },
      renderEdit: props => {
        const {  editValue, error } = props
  
        return (
          <Input 
            className='card-field eskzi-card__id'
            label = '№'
            value={editValue || 'Черновик'}
            readOnly={true}
          />
        )
      },
    },
    name: {
      validation: value => {
        return notEmpty(value)
      },
      renderEdit: props => {
        const { cardState, editValue, onChange, error, commonDataForm } = props
        const { skzi } = commonDataForm || {}

        return (
          <Input 
            className='card-field eskzi-card__name'
            label = 'Наименование'
            value={getEskziName(skzi)}
            readOnly={true}
          />
        )
      },
    },
    skzi: {
      renderEdit: props => {
        const { cardState, editValue, onChange, error } = props
        const { 
          skzi , 
          version
        } = editValue || {}
        const { name: skziName } = skzi || {}
        const { name: versionName } = version || {}
        const name = `${skziName} ${versionName}`

        return (
          <Input 
            className='card-field eskzi-card__product'
            label = 'Продукт'
            value={name}
            readOnly={true}
          />
        )
      },
    },
    status: {
      renderEdit: props => {
        const { cardState, editValue, onChange, error } = props
        const { name = '' } = editValue || {}

        return (
          <Input 
            className='card-field eskzi-card__status'
            label = 'Статус'
            value={name}
            readOnly={true}
          />
        )
      },
    },
    okz: {
      renderEdit: props => {
        const { cardState, editValue, onChange, error } = props
        const { name = '' } = editValue || {}

        return (
          <Input 
            className='card-field eskzi-card__okz'
            label = 'Получено от'
            value={name}
            readOnly={true}
          />
        )
      },
    },
    oki: {
      renderEdit: props => {
        const { cardState, editValue, onChange, error } = props
        const { name = '' } = editValue || {}

        return (
          <Input 
            className='card-field eskzi-card__oki'
            label = 'Передано в'
            value={name}
            readOnly={true}
          />
        )
      },
    },
    serialNumber: {
      renderEdit: props => {
        const { cardState, editValue, onChange, error } = props

        return (
          <Input 
            className='card-field eskzi-card__exemplar'
            label = 'Основной № (Экземпляр)'
            value={editValue}
            readOnly={true}
          />
        )
      },
    },
    version: {
      renderEdit: props => {
        const { cardState, editValue, onChange, error, commonDataForm } = props
        const { skzi } = commonDataForm || {}
        const { version } = skzi || {}
        const { name: versionName  = ''} = version || {}

        return (
          <Input 
            className='card-field eskzi-card__version'
            label = 'Версия'
            value={versionName}
            readOnly={true}
          />
        )
      },
    },
    build: {
      renderEdit: props => {
        const { cardState, editValue, onChange, error, commonDataForm } = props
        const { skzi } = commonDataForm || {}
        const { build } = skzi || {}
        const { name: buildName = ''} = build || {}

        return (
          <Input 
            className='card-field eskzi-card__build'
            label = 'Сборка'
            value={buildName}
            readOnly={true}
          />
        )
      },
    },
    updateDate: {
      renderEdit: props => {
        const { cardState, editValue, onChange, error } = props
  
        return (
          <DatePicker
            className='card-field eskzi-card__change-date'
            label='Дата изменения' 
            readOnly={true}
            error={error}
            value={editValue} 
          />
        )
      },
    }, 
    serialNumbers: {
      renderEdit: props => {
        const { cardState, editValue = [], onChange, error } = props

        return (
          <ExpandingBlock
            className='card-field eskzi-card__serialNumbers'
            renderHeader= {() => 'Регистрационные номера элементов поставки'}
            initialState={true}
          >
            {editValue.map(item => {
              const { number, objectType, id } = item
              const { name } = objectType || {}

              return (
                <Input 
                  key={id}
                  className='card-field eskzi-card__serialNumber'
                  label = {name}
                  value={number}
                  readOnly={true}
                />
              )
            })}
          </ExpandingBlock>
        )
      },
    },
  }
