/** @format */

import React from 'react'
import Group from 'app/components/ui/managePanel/group';
import ReplicateButton from 'app/components/skzi/Eskzi/replication/replicationButton';
import BusinessEventTrigger from 'app/components/ui/managePanel/businessEventTrigger/businessEventTrigger';
import { ENTITY_TYPE } from 'app/components/ui/managePanel/businessEventTrigger/useBusinessEvent';
import { Image } from 'app/components/ui/Img';
import {Delete} from "app/components/list/TopPanel/Delete";
import JournalDownload from 'app/components/skzi/EskziList/JournalDownload';
import Complectation from 'app/components/list/TopPanel/Complectation';
import SendButton from 'app/components/skzi/EskziList/businessEventPanel/sendButton';
import service from 'app/services/service';
import { useHistory } from "react-router-dom";
import { getEskziName } from 'app/components/skzi/constants'
import DestructionFormBody from '../../DestructionFormBody';

const sendBusinessEvents = [
  { name: 'Передача в Орган криптографической защиты', eventCode: 'OkzReceiving' },
  { name: 'Передача Обладателю конфиденциальной информации', eventCode: 'OkiReceiving' },
];

export default function BusinessEventPanel(props) {
  const { items, onInProgress, eskziId, afterAction, isExternalUser, eskziRights, isOkz } = props;

  const history = useHistory();
  const [selectedItem] = items || [];
  const { isStandard, replicationAllowed } = selectedItem || {};
  const disabled = items.length !== 1 || items[0].bizLocked || !(isStandard && replicationAllowed);

  const deleteActionCallback = async () => {
    onInProgress(true);
    const response = await service(
      'skziAccountingService',
      'deleteRange',
      items.map(i => i.id)
    );
    onInProgress(false);
    return response;
  };

  const afterDeleteAction = () => {
    history.push('/skziAccounting');
  };

  const allHasChangeUser =
    items.length &&
    items.every(item => {
      const { availableActions = [] } = item;
      return availableActions.some(i => i.code === 'ChangeUser');
    });

  function afterActionCallback() {
    afterAction(eskziId);
  }

  return isExternalUser ? (
    <>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={['OkiSendingOk', 'OkzSendingOk']}
          tooltip="Подтвердить получение"
          endpointType={'skziAccounting'}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterActionCallback}
          Img={Image.Acceptation}
          isExternalUser={isExternalUser}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode="Return"
          tooltip="Вернуть"
          endpointType={'skziAccounting'}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterActionCallback}
          Img={Image.ArReturn}
          isExternalUser={isExternalUser}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={['OkiInstallation', 'ExternalOkiInstallation']}
          tooltip="Установить"
          endpointType={'skziAccounting'}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterActionCallback}
          Img={Image.DownloadCircle}
          isExternalUser={isExternalUser}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={['OkiWithdrawal', 'OkiWithdrawal_KD']}
          tooltip="Изъять"
          endpointType={'skziAccounting'}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterActionCallback}
          Img={Image.SoftReturn}
          isExternalUser={isExternalUser}
        />
      </Group>
      <Group>
        <Complectation titul="Комплектация" items={items} isExternal={isExternalUser} />
      </Group>
    </>
  ) : (
    <>
      {eskziRights.CREATE && isOkz ? (
        <Group>
          <ReplicateButton initialState={selectedItem} disabled={disabled} afterAction={afterAction} />
        </Group>
      ) : null}
      <Group>
        <SendButton
          items={items}
          className="send-button"
          title="Передать"
          actionList={sendBusinessEvents}
          disabled={false}
          entityType={ENTITY_TYPE.eskzi}
          endpointType={'skziAccounting'}
          afterAction={afterActionCallback}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={['OkzReturn']}
          tooltip="Вернуть"
          endpointType={'skziAccounting'}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterActionCallback}
          Img={Image.ArReturn}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={['OkiDeliveryOk']}
          tooltip="Получить экземпляр"
          endpointType={'skziAccounting'}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterActionCallback}
          Img={Image.Delivery}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={['OkiSendingOk', 'OkzSendingOk']}
          tooltip="Подтвердить получение"
          endpointType={'skziAccounting'}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterActionCallback}
          Img={Image.Acceptation}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={['OkzReturnOk']}
          tooltip="Подтвердить возврат"
          endpointType={'skziAccounting'}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterActionCallback}
          Img={Image.AcceptationOfReturn}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={['OkiWithdrawal', 'OkiWithdrawal_KD']}
          tooltip="Изъять"
          endpointType={'skziAccounting'}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterActionCallback}
          Img={Image.SoftReturn}
        />
        {!allHasChangeUser ? (
          <BusinessEventTrigger
            items={items}
            eventCode={['OkiDelivery']}
            tooltip="Выдать"
            endpointType={'skziAccounting'}
            entityType={ENTITY_TYPE.eskzi}
            afterAction={afterActionCallback}
            Img={Image.SoftSending}
          />
        ) : (
          <BusinessEventTrigger
            items={items}
            eventCode={['ChangeUser']}
            tooltip="Выдать другому пользователю"
            endpointType={'skziAccounting'}
            entityType={ENTITY_TYPE.eskzi}
            afterAction={afterActionCallback}
            Img={Image.SoftSending}
          />
        )}
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={['OkiInstallation']}
          tooltip="Установить"
          endpointType={'skziAccounting'}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterActionCallback}
          Img={Image.DownloadCircle}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={['OkiDestruction', 'OkzDestruction']}
          formBody={DestructionFormBody}
          tooltip="Уничтожить"
          endpointType={'skziAccounting'}
          entityType={ENTITY_TYPE.eskzi}
          afterAction={afterActionCallback}
          Img={Image.Extermination}
          formAlert={'При уничтожении объектов будут уничтожены все связанные элементы.'}
        />
      </Group>
      <Group>
        <Complectation titul="Комплектация" items={items} />
      </Group>
      <Group>
        <JournalDownload items={items} />
      </Group>
      <Group>
        <Delete
          items={items}
          deleteItemsAction={deleteActionCallback}
          afterAction={afterDeleteAction}
          setSelectedAction={() => {}}
          multiply
          dialog={items => {
            return `Вы уверены, что хотите удалить экземпляр СКЗИ «${getEskziName(items[0].skzi)}»?`;
          }}
        />
      </Group>
    </>
  );
}