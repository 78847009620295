/** @format */
import React, { Component, useEffect, useRef, useState } from 'react';

import PropType from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import MaskedInput from 'react-text-mask';
import ru from 'date-fns/locale/ru';

import Button, { buttonTypes } from 'app/components/ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import { keyCodes } from 'app/components/ui/constants';
import InputLinkControl from 'app/components/ui/InputLinkControl';
import InputWrapper from '../InputWrapper';
import { eventStop } from 'app/core/utility/common';
import { cssModuleClassNameGetter } from 'app/core/utility/jsx';
import { uuid } from 'app/core/utility/random';
import "./Input.scss";

import styles from './Input.module.scss';

const getClass = cssModuleClassNameGetter(styles);

export const InputType = {
  number: 'number',
  autocomplete: 'autocomplete',
  picker: 'picker',
  text: 'text',
  password: 'password',
  date: 'date',
  hybrid: 'hybrid',
  multiSelect: 'multiSelect'
};

export default function Input({
  id,
  name,
  className,
  type,
  inputType,
  disabled,
  readOnly,
  label,
  placeholder,
  value,
  pattern,
  error,
  hint,
  autofocus,
  CustomImage,
  ForcedCustomImage = null,
  noClearIcon,
  linkTarget,
  toggleStatus,
  caretPosition,
  autocomplete,
  slotPrepend,
  slotAppend,
  prependClippable,
  onChange,
  onSubmit,
  onFocus,
  onBlur,
  onArrowUp,
  onArrowDown,
  minDate,
  maxDate,
  noHint,
  absoluteInfo,
  ...extendedProps
}) {
  const commonProps = {
    id: id || uuid(),
    disabled,
    readOnly,
    placeholder:
      placeholder || typeof placeholder === 'string'
        ? placeholder
        : label || typeof label !== 'undefined'
        ? null
        : 'Введите текст',
    value: value || value === 0 ? value : '',
  };

  const finalType = inputType || type || InputType.text;

  const innerInputRef = useRef();

  const [caretPositionLocal, setCaretPositionLocal] = useState();
  const [valueLocal, setValueLocal] = useState();
  useEffect(() => {
    // Updating value from outside leads to caret jumping to the end of the string due to rerender,
    // so we have to restore its position
    if (valueLocal !== value) {
      return;
    }

    forceCaretPosition();
  }, [value]);

  const handleChange = e => {
    const { current } = innerInputRef;
    const innerValue = e.currentTarget.value;

    const matchesPattern = !pattern || new RegExp(pattern).test(innerValue);
    if (!matchesPattern) {
      return;
    }

    setValueLocal(innerValue);
    setCaretPositionLocal(current.selectionStart);
    onChange && onChange(innerValue, current.selectionStart);
  };

  const handleClear = () => {
    onChange && onChange('', 0, true);
  };

  const handleCalendarClick = () => {
    const { current } = innerInputRef;
    current && current.onInputClick();
  };

  const handleDateChange = date => {
    onChange && onChange(date);
  };

  const handleKeyDown = e => {
    const { current } = innerInputRef;
    switch (e.keyCode) {
      case keyCodes.EnterKeyCode:
        onSubmit && onSubmit(value);
        current.blur();
        break;
      case keyCodes.KeyUp:
        onArrowUp && onArrowUp();
        break;
      case keyCodes.KeyDown:
        onArrowDown && onArrowDown();
        break;
      default:
        break;
    }
  };

  const focusInnerInput = () => {
    const { current } = innerInputRef;
    current && current.focus();
  };

  const forceCaretPosition = () => {
    const { current } = innerInputRef;

    if (inputType && inputType !== InputType.text) {
      return;
    }

    if (finalType !== InputType.number && (caretPositionLocal !== undefined || caretPosition !== undefined)) {
      current.selectionStart = caretPositionLocal || caretPosition;
      current.selectionEnd = caretPositionLocal || caretPosition;
    }
  };

  const getAutocomplete = () => {
    const autocompleteValueConditionMap = [
      { value: autocomplete, condition: !!autocomplete },
      { value: 'username', condition: id === 'login' || id === 'username' },
      { value: 'current-password', condition: finalType === InputType.password },
      { value: 'off', condition: true },
    ];

    return autocompleteValueConditionMap.find(vc => vc.condition).value;
  };

  const handleFocus = e => {
    if (disabled) {
      return;
    }
    onFocus && onFocus();
  };

  const handleBlur = e => {
    onBlur && onBlur(value);
  };

  const renderInputControl = () => {
    const hasPrepend = !!slotPrepend;
    if (finalType === InputType.date) {
      registerLocale('ru', ru);
      const borderDate = new Date('1990-01-01T01:00:00');
      const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd.mm.yyyy', {
        minYear: 1990,
      });

      return (
        <div className={styles.datePicker}>
          <DatePicker
            {...commonProps}
            ref={innerInputRef}
            customInput={
              <MaskedInput
                pipe={autoCorrectedDatePipe}
                type="text"
                mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                keepCharPositions
                guide
                className={getClass(['control__input'], ['innerInput', { disabled, hasPrepend }])}
              />
            }
            selected={value && value < borderDate ? borderDate : value}
            minDate={minDate || new Date('1990-01-01T00:00:00')}
            maxDate={maxDate || new Date('2990-01-01T00:00:00')}
            isClearable={false}
            disabledKeyboardNavigation
            locale="ru"
            todayButton="Сегодня"
            dateFormat="dd.MM.yyyy"
            placeholderText={placeholder}
            placeholder={''}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            className={getClass([], ['innerInput', { hasPrepend }])}
            onChange={handleDateChange}
            onCalendarClose={handleBlur}
            autoComplete="off"
            portalId="root"
            popperModifiers={[
              {
                name: "preventOverflow",
                options: {
                  altAxis: true,
                  altBoundary: true,
                },
              },
            ]}
            //onCalendarOpen={handleFocus}
            // {...extendedProps}
          />
        </div>
      );
    }

    return (
      <input
        ref={innerInputRef}
        name={name || ''}
        type={finalType}
        autoFocus={autofocus}
        spellCheck="false"
        autoComplete={getAutocomplete()}
        className={getClass(['control__input'], ['innerInput', { disabled, hasPrepend }])}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onClick={eventStop}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...commonProps}
        //{...extendedProps}
      />
    );
  };

  const wrapperProps = { label, error, hint, prependClippable, noHint, absoluteInfo };

  const controlVisibility = {
    clearButton: !noClearIcon && (!!commonProps.value || commonProps.value === 0) && !(disabled || readOnly),
    calendarImage: !readOnly && finalType === InputType.date,
    customImage: !readOnly && !!CustomImage,
    forcedCustomImage: !!ForcedCustomImage,
    inputLinkControl: (readOnly || disabled) && linkTarget,
  };
  const inputControls = ({ inputHover }) => (
    <div className={styles.controlsWrapper} onMouseDown={e => e.preventDefault()}>
      {controlVisibility.clearButton && (
        <Button
          type={buttonTypes.imageSmall}
          className={getClass(['btnClear'], ['btnClear', { hidden: !controlVisibility.clearButton || !inputHover }])}
          onClick={handleClear}
        >
          <Img img={Image.CloseBorderless} />
        </Button>
      )}
      {controlVisibility.calendarImage && (
        <Button type={buttonTypes.imageSmall} className="control__custom-image" onClick={handleCalendarClick}>
          <Img img={Image.Calendar} />
        </Button>
      )}
      {controlVisibility.customImage && <CustomImage className="control__custom-image" />}
      {controlVisibility.forcedCustomImage && <ForcedCustomImage />}
      {controlVisibility.inputLinkControl && <InputLinkControl {...linkTarget} />}
    </div>
  );

  return (
    <InputWrapper
      {...commonProps}
      {...wrapperProps}
      slotPrepend={slotPrepend}
      slotAppend={({ inputHover }) => (
        <>
          {slotAppend}
          {inputControls({ inputHover })}
        </>
      )}
      className={className}
    >
      {renderInputControl()}
    </InputWrapper>
  );
}