import React, { useState } from 'react';
import PickerComponent, { SelectType, Services } from 'app/components/ui/picker/picker';
import withModal from 'app/components/HOC/ModalHOC';
import Button from 'app/components/ui/button/button';
import { ReactComponent as AddImg } from 'assets/img/commonVer2/ic_link_add.svg'

const Picker = withModal(PickerComponent)

export default function LinkKeyDocButton(props) {
  const { 
    className, 
    eskziId,
    disabled,
    onSubmitSignEnhance,
  } = props 

  const [ showPicker, setShowPicker ] = useState(false)

  const pickerService = {
    serviceName: 'licenseService',
    serviceMethod: 'pickerEskziLisenses',
    data: {eskziId: eskziId}
  }

  const _onSelect = async (selected) => {
    const requestData = {
      eskziId : eskziId,
      skziLicenses : [ ...selected.map(i => i.id) ],
      signatureRequestType: 'licenseAdd'
    };
    const result = await onSubmitSignEnhance(requestData)
    const { isError } = result || {}
    !isError && setShowPicker(false)
  }

  const tagName = (item) => `№${item.id} **${item.key ? item.key.slice(-4) : ''}`

  return (
    <>
      <Button 
        className = { className || ''}
        type='primary'
        disabled={disabled}
        onClick = { () => setShowPicker(true) }
      >
        <AddImg className='button-image button-image--left'></AddImg>
        <span className='button-title'>Добавить лицензию</span>
      </Button>
      {showPicker ? (
        <Picker
          serviceType={Services.skziLicenses}
          notStandardService={pickerService}
          selectType={SelectType.multiple}
          onCancel={() => setShowPicker(false)} 
          onSelect={_onSelect}
          renderTagName={tagName}
          renderHeader = {() => {
            return (
              <div className='modal__header'>
                {`Добавление лицензионного ключа`}
              </div>
            )
          }}
        />
      ) : null}
    </>
  )
}
