import React from "react";
import useCardCertificationBlock from "./useCardCertificationBlock";
import TableComponent from 'app/components/ui/obsoleteTable/table';
import ExpandingBlock from 'app/components/ui/expandingBlock'
import service from "app/services/service";
import withEndlessScrollHOC from 'app/components/HOC/EndlessScrollHOC'
const Table = withEndlessScrollHOC(TableComponent)

export default function CardCertificationBlock({inProgressParent, routeParams = {}}){

  const {
    sort,
    buildColumns,
    getTableOptions,
  } = useCardCertificationBlock(routeParams)
  const { skziId, versionId, buildId } = routeParams || {}
  return (
    <>
      <ExpandingBlock
        className='card__skzi-build-certificates'
        renderHeader= {() => 'Сертификаты соответствия'}
        initialState={true}
      >
        {skziId && <Table
          loadData = {({page, pageSize}) => service('versionService', 'getAllCertifications', {
            skziId,
            versionId,
            buildId,
            page, 
            pageSize,
            ...sort
          })}
          dependencies = {[sort]}
          columns={buildColumns}
          selected={[]}
          noBorders={true}
          options={getTableOptions()}
        />}
      </ExpandingBlock>
    </>
  )
}