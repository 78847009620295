import React, { Component } from 'react'
import Button from 'app/components/ui/button/button'
import {ReactComponent as DwnlBtn} from 'assets/img/commonVer2/download.svg'

export default class fileUploadButton extends Component {
  constructor(props){
    super(props)

    this.filer = React.createRef();
  }

  componentDidMount(){
    const { current } = this.filer

    current.addEventListener('change', this._getFile);
  }

  _showFileSelect = () => {
    if (this.filer && this.filer.current) {
      this.filer.current.click();
    }
  }

  _getFile = (input) => {
    const { onUploadStart, onUploadFinish } = this.props

    try {
      const reader = new FileReader();
      const blob = input.currentTarget.files && input.currentTarget.files[0];

      if (!blob) {
        return;
      }

      reader.onloadend = () => {
        this._uploadFile(blob)
      };

      onUploadStart && onUploadStart({ name: blob.name, progress: 0 })
      reader.readAsBinaryString(blob);
    } catch (error) {
      onUploadFinish && onUploadFinish()
    }
  };

  _uploadFile = async (file) => {
    const { 
      onError, 
      onFileUploaded, 
      onUploadUpdate,
      onUploadFinish,
      setCancelCallback,
      fileKey,
      fileApi 
    } = this.props
    const { uploadFile } = fileApi

    try {
      const result = await uploadFile(file, onUploadUpdate, setCancelCallback)
      const { data: axiosResult } = result || {}

      if (!axiosResult) {
        throw {
          axiosResult: {
            errors: [
            {
              errorMessage: 'Загрузка прервана пользователем'
            }],
            isError: true
          }, fileKey
        }
      }

      const { data } = axiosResult

      if (!axiosResult.isError) {
        onFileUploaded && onFileUploaded({... data})
      } else {
        throw {axiosResult, fileKey}
      }
    } catch (error) {
      onError && onError(error)
    } finally {
      onUploadFinish && onUploadFinish()
    }
  }

  render() {
    const { key, title } = this.props

    return (
      <>
        <Button
          className='upload-button'
          type='text'
          onClick={this._showFileSelect}
          {...this.props}
        >
          <DwnlBtn className='button-image'></DwnlBtn>
          <span className='button-title'>{title ? title : 'Загрузить'}</span>
        </Button>
        <div style={{ width: 0, height: 0, overflow: 'hidden' }}>
          <input 
            ref={this.filer} 
            type="file"
          />
        </div>
      </>
    )
  }
}

