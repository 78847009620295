import { 
  BREADCRUMBSPUSHITEM,
  TRAININGCOURSESGETALL, 
  TRAININGCOURSESDELETE, 
  TRAININGCOURSESINPROGRESS, 
  TRAININGCOURSESRESETACTION, 
  TRAININGCOURSESCLEARSTOREACTION,
  TRAININGCOURSESONDELETE, 
  TRAININGCOURSESPAGINATIONGET,
  TRAININGCOURSESONSELECT
} from '../actionTypes'

import service from 'app/services/service'
import { dialogType } from 'app/components/dialog/confirmDialog/'
import { dispatchLogic } from '../common'
import { getPageBreadcrumb } from 'app/header/breadcrumbs'	

export const trainingCoursesGetAction = ({ filterData, column, direction,page, pageSize }, path) => dispatch => {
  service('courseService', 'getAll', { filterData, column, direction, page, pageSize })
    .then(response => {
      const { data, dataCount, errors, isError } = response;

      return dispatchLogic(dispatch, TRAININGCOURSESGETALL, {data, dataCount}, isError, errors)
    })
    .then(() => {
      dispatch({
        type: BREADCRUMBSPUSHITEM,
        payload: { 
          ...getPageBreadcrumb(path),
          forceUpdate: true
        }
      })
    })
}

export const trainingCoursesPaginationAction = ({ activePage = 1, itemsPerPage = 10 }) => dispatch => {

  dispatch(
    {
      type: TRAININGCOURSESPAGINATIONGET,
      payload: { activePage, itemsPerPage }
    }
  )
}

export const trainingCoursesSelectAction = selectedData => dispatch => {
  return dispatch(
    {
      type: TRAININGCOURSESONSELECT,
      payload: selectedData
    }
  )
}

export const trainingCoursesDeleteAction = data => dispatch => {
  return service('courseService', 'deleteCourse', data.map(item => item.id))
    .then(response => {
      const { data: responseData , errors, isError } = response

      return dispatchLogic(dispatch, TRAININGCOURSESDELETE, responseData || data.map(item => item.id), isError, errors)
    })
}

export const trainingCoursesOnDeleteAction = data => dispatch => {
  const names = data.map(item => item.name).join(', ')

  dispatch({
    type: TRAININGCOURSESONDELETE,
    payload: { 
      confirmObject: {
        item: data,
        type: dialogType.confirm,
        title: `Вы уверены, что хотите удалить: «${names}»?`
      }
    }
  })
}

export const trainingCoursesInProgressAction = value => dispatch => {
  dispatch({
    type: TRAININGCOURSESINPROGRESS,
    payload: { inProgress: value }
  })
}

export const trainingCoursesResetAction = () => dispatch => {
  dispatch({
    type: TRAININGCOURSESRESETACTION,
    payload: null
  })
}

export const trainingCoursesClearStoreAction = () => dispatch => {
  dispatch({
    type: TRAININGCOURSESCLEARSTOREACTION,
    payload: null
  })
}
