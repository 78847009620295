import React from "react";
import { ReactComponent as PlusImg } from 'assets/img/commonVer2/plus.svg'
import { ReactComponent as AddFilesImg } from 'assets/img/commonVer2/clip.svg'
import { ReactComponent as DeleteImg } from 'assets/img/commonVer2/delete.svg'
import { ReactComponent as EditImg } from 'assets/img/commonVer2/edit_pen.svg'
import ConfirmDialog, { dialogType } from 'app/components/dialog/confirmDialog/'
import ButtonComponent from 'app/components/ui/button/button'
import EditModal from "./EditModal"
import AddModal from "./AddModal"
import EditFileModal from "./EditFileModal"
import useActionPanel from "./useActionPanel"
import withTooltip from "../../../HOC/TooltipHOC"

const Button = withTooltip(ButtonComponent)

export default function ActionPanel(props){

  const { selected, onFileUploaded, certificationsTypes } = props

  const {
    visibleDialogs,
    fileRights,
    setVisibleDialogs,
    onEditCertificate,
    onAddCertificate,
    onDeleteConfirm,
  } = useActionPanel(props)

  return (
    <div className="skzi-build-types manage-panel">
      {fileRights.CREATE && (
        <Button
          className='create-button'
          type='primary'
          onClick={() => setVisibleDialogs({...visibleDialogs, isShowAddDialog: true})}
        >
          <PlusImg className='button-image button-image--left' />
          <span className='button-title'>Добавить</span>
        </Button>
      )}
      {fileRights.UPDATE && (
        <div className="buttons-element">
          <Button
            type='image'
            tooltip={'Редактировать'}
            onClick={() => setVisibleDialogs({...visibleDialogs, isShowEditDialog: true})}
            disabled={selected.items.length !== 1}
          >
            <EditImg className='button-image' />
          </Button>
        </div>
      )}
      {fileRights.CREATE ? (
        <div className="buttons-element">
          <Button
            tooltip={'Добавить файлы'}
            type='image'
            onClick={() => setVisibleDialogs({...visibleDialogs, isShowFileDialog: true})}
            disabled={selected.items.length !== 1}
          >
            <AddFilesImg className='button-image' />
          </Button>
        </div>
      ) : null}
      {fileRights.DELETE && (
        <div className="buttons-element">
          <Button
            tooltip={'Удалить'}
            type='image'
            onClick={() => setVisibleDialogs({...visibleDialogs, isShowDeleteDialog: true})}
            disabled={!selected.items.length}
          >
            <DeleteImg className='button-image' />
          </Button>
        </div>
      )}
      <>
        {
          visibleDialogs.isShowDeleteDialog && (
            <ConfirmDialog
              item={selected.items}
              type={dialogType.confirm}
              onSubmit={onDeleteConfirm}
              title={`Выбранные сертификаты будут удалены: ${selected.items.map(item => `${item.type.name} № ${item.number}`).join(', ')}?`}
              onCancel={() => setVisibleDialogs({...visibleDialogs, isShowDeleteDialog: false})}
            />
          )
        }
        {
          visibleDialogs.isShowEditDialog && selected.items.length === 1  && (
            <EditModal
              certificationsTypes={certificationsTypes}
              item={selected.items[0]}
              onEdit={onEditCertificate}
              onEditFinish={onFileUploaded}
              onCancel={() => setVisibleDialogs({...visibleDialogs, isShowEditDialog: false})}
            />
          )
        }
        {
          visibleDialogs.isShowAddDialog  && (
            <AddModal
              certificationsTypes={certificationsTypes}
              onAddFinish={onFileUploaded}
              onAdd={onAddCertificate}
              onCancel={() => setVisibleDialogs({...visibleDialogs, isShowAddDialog: false})}
            />
          )
        }
        {
          visibleDialogs.isShowFileDialog && selected.items.length === 1  && (
            <EditFileModal
              certificationsTypes={certificationsTypes}
              onFileUploaded={onFileUploaded}
              item={selected.items[0]}
              onCancel={() => setVisibleDialogs({...visibleDialogs, isShowFileDialog: false})}
            />
          )
        }
      </>
    </div>
  )
}