import { 
BREADCRUMBSPUSHITEM,
SKZICARDGET, 
SKZICARDGETVERSIONS,
SKZIREGISTRYADDBUILDOBJECTTYPE,
SKZIREGISTRYDELETEBUILDOBJECTTYPE,
SKZIREGISTRYSETMAINBUILDOBJECTTYPE,
SKZICARDCLOSEFILESPICKER,
SKZICARDSUBMITFILESPICKER,
SKZICARDUPDATEFORMDATA,
SKZICARDINPROGRESS,
SKZICARDRESETACTION,
SKZICARDUPDATE,
SKZICARDDELETE,
SKZICARDONDELETE,
SKZICARDONADDVERSION,
SKZICARDUPDATEVERSIONDATA,
SKZICARDUPDATECREATEFORMDATA,
SKZICARDUPDATEEDITVERSIONFORMDATA,
SKZICARDVERSIONADD,
SKZICARDVERSIONDELETE,
SKZICARDVERSIONONDELETE,
SKZICARDVERSIONONBUILDADD,
SKZICARDUPDATEADDBUILDFORMDATA,
SKZICARDUPDATEEDITBUILDFORMDATA,
SKZICARDVERSIONBUILDADD,
SKZICARDBUILDONDELETE,
SKZICARDBUILDONEDIT,  
SKZICARDBUILDEDIT,    
SKZICARDBUILDDELETE,
SKZICARDBUILDONEDITFILES,
SKZICARDBUILDONFILESUPDATED,
SKZICARDONVERSIONEDIT,
SKZICARDONCANCEL,
SKZICARDONSELECT
} from '../actionTypes'

import service from 'app/services/service'
import { dispatchLogic } from '../common'
import { getPageBreadcrumb } from 'app/header/breadcrumbs'

export const getSkziAction = (id, path) => dispatch => {
  service('skziService', 'getSkzi', id)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, SKZICARDGET, data, isError, errors)
    }).
    then(action => {
      const { payload } = action
      const { name } = payload || {}

      return dispatch({
        type: BREADCRUMBSPUSHITEM,
        payload: getPageBreadcrumb(path, name)
      })
    })
}

export const getSkziVersionsAction = ({id, column, direction, page, pageSize}) => dispatch => {
  service('skziService', 'getSkziVersions', {id, column, direction, page, pageSize})
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, SKZICARDGETVERSIONS, data, isError, errors)
    })
}

export const updateSkziAction = data => dispatch => {
  service('skziService', 'updateSkzi', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, SKZICARDUPDATE, data, isError, errors)
    })
}

export const deleteSkziAction = data => dispatch => {
  const { id } = data 

  service('skziService', 'deleteSkzi', id)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, SKZICARDDELETE, data, isError, errors)
    })
}

export const updateVersionDataAction = ({skzi, version}) => dispatch => {
  service('versionService', 'editVersion', {skziId: skzi.id, versionData: version})
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, SKZICARDUPDATEVERSIONDATA, {skzi, version: data}, isError, errors)
    })
}

export const addVersionAction = ({ skziId, createVersionForm }) => dispatch => {
  service('versionService', 'addVersion', {skziId, versionData: createVersionForm})
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, SKZICARDVERSIONADD, data, isError, errors)
    })
}

export const skziVersionDeleteAction = ({skziId, items}) => dispatch => {

  service('versionService', 'deleteVersion', {skziId, items})
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, SKZICARDVERSIONDELETE, data, isError, errors)
    })
}

export const skziVersionOnDelete = (data) => dispatch => {
  dispatch({
    type: SKZICARDVERSIONONDELETE,
    payload: data
  })
}

export const skziVersionOnEditFiles = (data) => dispatch => {
  
  service('objectTypesService', 'autocomplete', '')
  .then(response => {
    const { data: objectTypes, errors, isError } = response;
    
    dispatchLogic(dispatch, SKZICARDBUILDONEDITFILES, {...data, objectTypes}, isError, errors)
  })
}

export const skziCardOnDelete = () => dispatch => {
  dispatch({
    type: SKZICARDONDELETE,
    payload: { 
    }
  })
}

export const skziCardOnSelect = selectedData => dispatch => {
  dispatch({
    type: SKZICARDONSELECT,
    payload: selectedData
  })
}

export const skziCardOnBuildAdd = (data) => dispatch => {
  dispatch({
    type: SKZICARDVERSIONONBUILDADD,
    payload: data
  })
}

export const skziCardAddBuildAction = ({skzi, buildData, version }) => dispatch => {
  service('versionService', 'addBuild', buildData)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, SKZICARDVERSIONBUILDADD, {skzi, build: data, version}, isError, errors)
    })
}

export const skziCardDeleteBuildAction = ({ skzi, build, version }) => dispatch => {
  const { id: buildId } = build

  service('versionService', 'deleteBuild', buildId)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, SKZICARDBUILDDELETE, { skzi, build, version }, isError, errors)
    })
}

export const skziCardEditBuildAction = ({ skzi, build, version }) => dispatch => {
  service('versionService', 'updateBuild', build)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, SKZICARDBUILDEDIT, { build: data, version, skzi}, isError, errors)
    })
} 

export const skziCardBuildFilesUpdated = (data) => dispatch => {
  dispatch({
    type: SKZICARDBUILDONFILESUPDATED,
    payload: data
  })
}

export const skziCardCloseFilePicker = (data) => dispatch => {
  dispatch({
    type: SKZICARDCLOSEFILESPICKER,
    payload: data
  })
}

export const skziCardSubmitFilePicker = (data) => dispatch => {
  dispatch({
    type: SKZICARDSUBMITFILESPICKER,
    payload: data
  })
}

export const skziBuildDeleteObjectType = (data) => dispatch => {
  const { skzi, build, version, objectType } = data

  service('versionService', 'removeObjectTypeFromBuild', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, SKZIREGISTRYDELETEBUILDOBJECTTYPE, { build: data, version, skzi, objectTypes: [objectType]}, isError, errors)
    })
}

export const setMainBuildObjectType = (data) => dispatch => {
  const { skzi, build, version, objectType } = data

  service('versionService', 'setMainBuildObjectType', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, SKZIREGISTRYSETMAINBUILDOBJECTTYPE, { build: data, version, skzi, objectTypes: [objectType]}, isError, errors)
    })
}

export const skziCardResetAction = () => dispatch => {
  dispatch({
    type: SKZICARDRESETACTION,
    payload: { 
    }
  })
}

export const skziCardOnBuildDeleteAction = ({skzi, build, version}) => dispatch => {
  dispatch({
    type: SKZICARDBUILDONDELETE,
    payload: {skzi, build, version}
  })
}

export const skziCardOnEditAction = ({skzi, build, version}) => dispatch => {
  const { certificateExpirationDate, class: buildClass } = build

  dispatch({
    type: SKZICARDBUILDONEDIT,
    payload: {
      skzi, 
      build: { 
        ...build, 
        certificateExpirationDate: certificateExpirationDate 
                                      ? new Date(certificateExpirationDate)
                                      : null,
        classId : buildClass                       
      }, 
      version
    }
  })
}

export const skziCardOnAddVersionAction = (skzi) => dispatch => {
  dispatch({
    type: SKZICARDONADDVERSION,
    payload: skzi
  })
}

export const skziCardInProgress = isInProgress => dispatch => {
  dispatch({
    type: SKZICARDINPROGRESS,
    payload: isInProgress
  })
}

export const skziCardOnCancelAction = () => dispatch => {
  dispatch({
    type: SKZICARDONCANCEL,
    payload: null
  })
}

export const updateCreateForm = (formData) => dispatch => {
  dispatch({
    type: SKZICARDUPDATECREATEFORMDATA,
    payload: { 
      ...formData
    }
  })
}

export const updateEditVersionForm = (formData) => dispatch => {
  dispatch({
    type: SKZICARDUPDATEEDITVERSIONFORMDATA,
    payload: { 
      ...formData
    }
  })
}

export const updateBuildForm = (formData) => dispatch => {
  dispatch({
    type: SKZICARDUPDATEADDBUILDFORMDATA,
    payload: { 
      ...formData
    }
  })
}

export const updateEditBuildForm = (formData) => dispatch => {
  dispatch({
    type: SKZICARDUPDATEEDITBUILDFORMDATA,
    payload: { 
      ...formData
    }
  })
}

export const updateCommonForm = (formData) => dispatch => {
  dispatch({
    type: SKZICARDUPDATEFORMDATA,
    payload: { 
      ...formData
    }
  })
}

export const skziCardOnVersionEdit = (data) => dispatch => {
  dispatch({
    type: SKZICARDONVERSIONEDIT,
    payload: data
  })
}