import React from 'react'
import { connect } from 'react-redux'

import MainCard, {objectKey} from 'app/components/mainCard/cardComponent'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Tabs from 'app/components/ui/tabs/tabs'
import {dialogType} from 'app/components/dialog/confirmDialog/'
import { cardLines } from './licenseData'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { CARDAFTERCREATE } from 'redux/actions/actionTypes'

import * as mainCardAction from 'redux/actions/card/cardActions'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import { getLicenseKey } from './card'
import { useHistory } from 'react-router-dom'

export const getWarningObject = (warningObject) => {
  const { error } = warningObject || {}
  const { errorMessage } = error || {}

  const messages = [
    errorMessage,
    'Продолжить?'
  ]

  return {
      messages: messages,
      type: dialogType.danger,
      header: 'Подтвердите выполнение операции',
    }
}

export const cardConfig = ({ 
    history,
    registerLicense,
    initLicenseCreate,
    breadcrumbsPopAction,
    breadcrumbsPushAction
  }) => ({
  cardLines: cardLines,
  getKey: data => objectKey(data, getLicenseKey),
  resource: RESOURCES.skziLicense,
  signatureHeaderText: 'Создание лицензии',
  signatureServiceName: 'licenseCreate',
  signatureEntity: 'SkziLicense',
  updateAction: registerLicense,
  cardInitAction: initLicenseCreate,
  getServerWarning: getWarningObject,
  onCancel: () => {history.push('/license')},
  afterUpdate: (requestResult) => {
    const { payload, type } = requestResult || {}
    const { id } = payload || {}

    if (type === CARDAFTERCREATE) {
      breadcrumbsPopAction()  
      history.push(`/license/card/${id}`)
    }
  },
  getSignatureData: getLicenseKey,
  pageBreadcrumbTitle: () => {
    breadcrumbsPushAction(history.location.pathname)
  }
})

const CreateCard = (props) => {
  const { 
    registerLicense,
    initLicenseCreate,
    breadcrumbsPopAction,
    breadcrumbsPushAction,
  } = props
  
  const history = useHistory();

  return (
    <section className='license-card'>
      <BreadcrumbsHeader />
      <Tabs 
        activeTab={'Информация'} 
        onTabClick={null}
      >
        <section tabTitle={'Информация'} className="license-card__data">
          <ExpandingBlock
            renderHeader= {() => 'Реквизиты'}
            initialState={true}
          >
            <MainCard
              {...props}
              config={cardConfig({
                history,
                registerLicense,
                initLicenseCreate,
                breadcrumbsPopAction,
                breadcrumbsPushAction
              })}
              editOnOpen={true}
            >
            </MainCard>
          </ExpandingBlock>
          <ExpandingBlock
            renderHeader= {() => 'Вложения'}
            disabled={true}
          >
          </ExpandingBlock>
        </section>
        <div
          tabTitle={'Экземпляры СКЗИ'}
          disabled={true}
        />
      </Tabs>
    </section>
  )
}

export default connect(
  state =>({...state.card}),
  { 
    ...mainCardAction,
    ...breadcrumbsActions
  })(CreateCard)