import React from 'react'
import Input from 'app/components/ui/Input'
import { CardState } from 'app/components/ui/card/cardMaterial'
import useAuthenticationForm from "../hooks/useAuthenticationForm";

export default function MappingForm(props){
  const {
    cardState,
    formData = {},
    serverFormViewModel,
    onChange,
    errorByFields = {},
  } = props;

  const {
    getError,
    getFormDataValue
  } = useAuthenticationForm({formData, serverFormViewModel, errorByFields})

  return (
    <>
      <Input
        id="surname"
        className="card-field card-mapping-surname"
        label="Фамилия"
        readOnly={cardState === CardState.view}
        value={getFormDataValue('surname')}
        onChange={value => onChange('surname', value)}
        error={getError('surname')}
      />
      <Input
        id="name"
        className="card-field card-mapping-name"
        label="Имя"
        readOnly={cardState === CardState.view}
        value={getFormDataValue('name')}
        onChange={value => onChange('name', value)}
        error={getError('name')}
      />
      <Input
        id="email"
        className="card-field card-mapping-email"
        label="Email"
        readOnly={cardState === CardState.view}
        value={getFormDataValue('email')}
        onChange={value => onChange('email', value)}
        error={getError('email')}
      />
      <Input
        id="snils"
        className="card-field card-mapping-snils"
        label="СНИЛС"
        readOnly={cardState === CardState.view}
        value={getFormDataValue('snils')}
        onChange={value => onChange('snils', value)}
        error={getError('snils')}
      />
      <Input
        id="patronymic"
        className="card-field card-mapping-patronymic"
        label="Отчество"
        readOnly={cardState === CardState.view}
        value={getFormDataValue('patronymic')}
        onChange={value => onChange('patronymic', value)}
        error={getError('patronymic')}
      />
    </>
  )
}