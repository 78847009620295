import React, {useState} from 'react'
import {useSelector} from "react-redux";
import Button from 'app/components/ui/button/button'
import withModal from 'app/components/HOC/ModalHOC'
import Input from "../ui/Input";
import Number from 'app/components/ui/number/number'

const initialFields = {
  programs: null,
  hardware: null,
  keyDoc: null,
}

const types = {
  programs: "Программные СКЗИ",
  hardware: "Аппаратно-программные (аппаратные) СКЗИ",
  keyDoc: "Ключевые документы",
}

function OrderDialog({onCancel, emailTo, subject, productName}){

  const [fields, setFields] = useState(initialFields)

  const currentUser = useSelector(({profile}) => profile.currentUser)

  const {organization} = currentUser

  function onChange(name, value){
    setFields({
      ...fields,
      [name]: value
    })
  }

  const [email, setEmail] = useState(`${currentUser.email}`)
  const [name, setName] = useState(`${currentUser.lastName} ${currentUser.firstName}`)


  const mailBody = `
    Добрый день! \n \n
    Компания ${organization.shortName || organization.name} ОГРН ${organization.ogrn} запрашивает дополнительные лицензии для ПО ${productName} в следующем количестве: \n
    \t${Object.keys(types).reduce((acc, type, index) => `${acc} ${types[type]}: ${fields[type] || '0'} \n\t`, '')}
    Контактное лицо - ${name}
    Электронная почта - ${email}
    `

  return (
    <div className="order-dialog">
      <div className="order-dialog__header">Заказ лицензий</div>
      <div className="order-dialog__block-header">
        Информация о компании
      </div>
      <div className="order-dialog__organization-info">
        <div className="order-dialog__table">
            <div className="order-dialog__table-column">Название компании</div>
            <div className="order-dialog__table-column">{organization.shortName || organization.name}</div>
            <div className="order-dialog__table-column">ОГРН</div>
            <div className="order-dialog__table-column">{organization.ogrn}</div>
            <div className="order-dialog__table-column">Почта</div>
            <Input
              id={email}
              className="order-dialog__table-column"
              label="email"
              value={email}
              onChange={setEmail}
            />
            <div className="order-dialog__table-column">Контактное лицо</div>
            <Input
              id={name}
              className="order-dialog__table-column"
              label="ФИО"
              value={name}
              onChange={setName}
            />
        </div>
      </div>
      <div className="order-dialog__block-header">
        Необходимые лицензии
      </div>
      <div className="order-dialog__licensing-card">
        <div className="order-dialog__table">
            {
              Object.keys(fields).map(el => (
                <>
                  <div className="order-dialog__table-column order-dialog__table-column__label">{types[el]}</div>
                  <Number
                    id={el}
                    className="order-dialog__table-column"
                    label="Количество"
                    min={0}
                    max={99999999}
                    value={fields[el]}
                    onChange={value => onChange(el, value)}
                  />
                </>

              ))
            }
        </div>
      </div>
      <div className="order-dialog__buttons-panel">
        <Button
          type='text'
          onClick={onCancel}
        >
          <span className='button-title'>Отмена</span>
        </Button>
        <Button
          type='text'
          onClick={(e) => {
            window.location = `mailto:${emailTo}?body=${encodeURIComponent(mailBody)}&subject=${subject}`;
            e.preventDefault();
          }}
          disabled={(!Object.values(fields).reduce((acc, el) => acc || !!el, false)) || !email || !name}
        >
          <span className='button-title'>Заказать лицензии</span>
        </Button>
      </div>
    </div>
  )
}

const OrderModal = withModal(OrderDialog)

export default OrderModal;