/** @format */

import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Overlay from 'app/components/ui/overlay';
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader';
import Tabs from 'app/components/ui/tabs/tabs';
import ExpandingBlock from 'app/components/ui/expandingBlock';
import MainCard, { objectKey } from 'app/components/mainCard/cardComponent';
import KeyDocuments from './keyDocuments';

import cardLines from './cardLines';
import hostEntityData from './hostEntityData';
import styles from './Host.module.scss';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import { initHostCard, updateHost } from 'redux/actions/card/cardActions';
import { breadcrumbsPushAction } from 'redux/actions/common/breadcrumbsAction';

const cardConfig = ({ history, dispatch }) => ({
  cardLines,
  getKey: data => objectKey(data, hostEntityData),
  resource: RESOURCES.host,
  cardInitAction: (...args) => initHostCard(...args)(dispatch),
  updateAction: (...args) => updateHost(...args)(dispatch),
  getSignatureData: hostEntityData,
  pageBreadcrumbTitle: action => {
    const { location } = history || {};
    const { pathname } = location || {};
    const { payload } = action;
    const { name } = payload || {};

    if (!pathname) {
      return;
    }

    breadcrumbsPushAction(history.location.pathname, name)(dispatch);
  },
});

const Host = props => {
  const history = useHistory();
  const { id: hostId } = useParams();
  const dispatch = useDispatch();
  const { cardInProgress } = props;
  const [activeTab, setActiveTab] = useState('Информация');
  const { viewData } = useSelector(state => state.card);
  const { keyDocuments } = viewData;

  return (
    <section className={`main-card host-card ${styles.hostCard}`}>
      {cardInProgress && <Overlay />}
      <BreadcrumbsHeader history={history} />
      <Tabs activeTab={activeTab} onTabClick={setActiveTab}>
        <section tabTitle={'Информация'} className="main-card__data">
          <ExpandingBlock renderHeader={() => 'Реквизиты'} initialState={true}>
            <MainCard
              {...props}
              itemId={hostId}
              config={cardConfig({
                history,
                dispatch,
              })}
            />
          </ExpandingBlock>
        </section>
        <section tabTitle={'Объекты учета'} className="main-card__data">
          <ExpandingBlock
            renderHeader={() => 'Ключевые документы'}
            initialState={true}
          >
            <KeyDocuments keyDocuments={keyDocuments} itemId={hostId} />
          </ExpandingBlock>
        </section>
      </Tabs>
    </section>
  );
};

export default Host;
