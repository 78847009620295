/** @format */

import React from 'react';
import { capitalize, decapitalize } from '../../../../core/utility/common';
import BusinessEventFieldComponent from 'app/components/skzi/Card/Events/EventsFieldComponent';
import { CardState } from 'app/components/ui/card/cardMaterial';
import { useSelector } from 'react-redux';
import ErrorPanel from 'app/components/error';
import { ErrorInfo } from 'app/components/Notification/allNotificationPage/notificationDetail/bulkOperationResult';
import Alert from 'app/components/ui/Alert';
import Severity from 'app/core/types/severity';

const fieldOrder = [
  'PerformerId',
  'EskziUserId',
  'OrganizationId',
  'HardwareId',
  'KeyDocumentCustom',
  'ReceivedFrom',
  'HardwareNumber',
  'DistributiveRemovingDate',
  'TransmittalLetterDate',
  'TransmittalLetterNumber',
  'PrintNumber',
  'UseDestructionCertificate',
  'Signers',
];

const types = {
  useDestructionCertificate: 'useDestructionCertificate',
  signers: 'signers',
};

const restrictedFields = {
  DestructKeyCarrier: true,
};

function getFormDataValue(formData, fieldName) {
  return formData[fieldName];
}

function getError(errorByFields, fieldName) {
  return errorByFields[capitalize(fieldName)];
}

export default function BusinessEventContent(props) {
  const {
    items,
    cardState,
    formData = {},
    model = [],
    eventCode,
    onChange,
    errorByFields = {},
    entityType,
    objectIds,
    organizationIds,
    isExternalUser,
    formAlert = '',
  } = props;

  const availableFields = model.reduce((acc, cur) => {
    if (!restrictedFields[cur.key]) {
      acc[cur.key] = cur;
    }
    return acc;
  }, {});

  const changeHandler = (type, value) => {
    if (type === types.useDestructionCertificate && !value) {
      onChange(types.signers, []);
    }
    onChange(type, value);
  };

  return (
    <>
      {formAlert ? <Alert severity={Severity.Warning} content={formAlert} className={'card-field card-alert'} /> : null}
      {fieldOrder
        .filter(i => availableFields[i])
        .map((el, index) => {
          const modelData = availableFields[el];
          const { key, title, readOnly, organizationType } = modelData;
          const type = decapitalize(key);
          return (
            <BusinessEventFieldComponent
              id={`${key}-${index}`}
              items={items}
              eventCode={eventCode}
              key={key}
              entityType={entityType}
              objectIds={objectIds}
              organizationIds={organizationIds}
              value={getFormDataValue(formData, type)}
              onChange={value => changeHandler(type, value)}
              error={getError(errorByFields, type)}
              type={type}
              title={title}
              readOnly={cardState === CardState.view || readOnly}
              viewCardState={cardState === CardState.view}
              organizationType={organizationType}
              isExternalUser={isExternalUser}
              formData={formData}
            />
          );
        })}
    </>
  );
}
