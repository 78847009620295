import React, { useEffect } from 'react';
import {
  eventDate,
  formatSKZITechnicalJournal,
} from 'app/components/list/Table/TableBody'
import { useParams } from "react-router-dom";
import ListLink from 'app/components/ui/ListLink'
import {Table} from 'app/components/list/Table/Table';
import { sortDirection } from 'app/components/ui/constants';
import Overlay from 'app/components/ui/overlay';
import { useJournal } from 'app/components/journals/useJournal';
import { formatDate } from 'app/core/utility/date';
import { KEY_DOCUMENT_CARD, E_SKZI_REGISRTY_CARD } from 'app/components/ui/constants';
import SignatureInfo from 'app/components/signature/signatureInfo/signatureInfo'
// import TechnicalJournalActionPanel from './TechnicalJournalActionPanel';
import Pagination from 'app/components/ui/pagination/serverSidePagination/pagination';
import TechnicalJournalActionPanel from 'app/components/journals/technicalJournal/TechnicalJournalActionPanel';
import service from 'app/services/service';
import ExpandingBlock from 'app/components/ui/expandingBlock'
import { Border, ManagePanel } from 'app/components/ui/managePanel';
import { signerFormat } from 'app/components/journals/technicalJournal/pageConfig';

const config = {
  fieldsConfig : [
    {
      title : '№',
      titleInfo : '№',
      alias : 'number',
      format : item => item.number,
      visibleOnStart : true,
      visibleAlways : true,
      userSorting : true,
      width : 50
    },
    {
      title : 'Дата',
      titleInfo: 'Дата',
      alias : 'actionDate',
      format : ({actionDate}) => formatDate(actionDate, 'dd.mm.yyyy'),
      visibleOnStart : true,
      visibleAlways : true,
      userSorting : true,
      width : 100
    },
    {
      title : 'Тип и серийные номера СКЗИ',
      titleInfo : 'Тип и серийные номера СКЗИ',
      alias : 'eskzi',
      format : item =>  {
        const name = item.eskzi ? formatSKZITechnicalJournal(item.eskzi.skzi, item.eskzi.serialNumber) : ''
        const { id } =  item.eskzi || {}

        return name && id ? <ListLink
          text={name}
          targetType={E_SKZI_REGISRTY_CARD}
          id={id}
        /> : name || ''
      },
      visibleOnStart : true,
      visibleAlways : true,
      userSorting : true,
      width : 400
    },
    {
      title : 'Записи по обслуживанию',
      titleInfo : 'Записи по обслуживанию',
      alias : 'actionName',
      format : item => item.actionName,
      visibleOnStart : true,
      userSorting : true,
      width : 250
    },
    {
      title : 'Тип ключевого документа',
      titleInfo : 'Тип ключевого документа',
      alias : 'keyDocumentType',
      format : item => {
        const { keyDocument, keyDocumentType } = item
        const { keyCarrier } = keyDocument || {}
        const { keyCarrierType } = keyCarrier || {}
        const { name } = keyCarrierType || {}

        return name || keyDocumentType || ''
      },
      visibleOnStart : true,
      userSorting : true,
      width : 200
    },
    {
      title : 'Серийный, криптографический номер и номер экземпляра ключевого документа',
      titleInfo : 'Серийный, криптографический номер и номер экземпляра ключевого документа',
      alias : 'keyDocument',
      format : item => {
        const name = item.keyDocument ? `${item.keyDocument.serialNumber} экз. № ${item.keyDocument.identificationNumber}` : ''
        const { id } = item.keyDocument || {}
        return name && id ? <ListLink
          text={name}
          targetType={KEY_DOCUMENT_CARD}
          id={id}
        /> : name || ''
      },
      visibleOnStart : true,
      userSorting : true,
      width : 300
    },
    {
      title : 'Номер разового ключевого носителя или зоны СКЗИ, в которую введены криптоключи',
      titleInfo : 'Номер разового ключевого носителя или зоны СКЗИ, в которую введены криптоключи',
      alias : 'keyCarrierNumber',
      format : item => {
        const { keyDocument, keyCarrierNumber } = item
        const { keyCarrier } = keyDocument || {}
        const { serialNumber } = keyCarrier || {}
        
        return serialNumber || keyCarrierNumber || ''
      },
      visibleOnStart : true,
      userSorting : true,
      width : 300
    },
    {
      title : 'Дата отметки об уничтожении',
      titleInfo : 'Дата отметки об уничтожении',
      alias : 'withdrawalDate',
      format : item => eventDate( item.withdrawal, item.destruction ) || '',
      visibleOnStart : true,
      userSorting : true,
      width : 200
    },
    {
      title: 'Подпись пользователя СКЗИ',
      alias: 'Подпись пользователя СКЗИ',
      width : 200,
      settings: {
        useSorting: false,
      },
      visibleOnStart: true,
      format: signerFormat
    },
    {
      title : 'Примечание',
      titleInfo: 'Примечание',
      alias : 'comments',
      width : 200,
      format : item => item.comments,
      visibleOnStart : false,
    }
  ]
}

export default function TechnicalJournal({skzi}) {
  const { eskziId } = useParams()
  const {
    // useListFilter
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    setVisibleFieldsAction,
    toggleVisibleFieldAction,
    filterState,
    filterForRequest,
    fieldsVisible,
    filterFilled,

    // useTableExtensions
    sortListAction,
    onInProgress,
    inProgress,
    sorting,
    selectedItems,
    list,
    setSelectedAction,

    // global
    profile,

    //pagination
    currentPage,
    perPage,
    pageCount,
    setCurrentPage,
    selectedObjects,
    setSelectedPagination,
    totalCount,

    //
    setGlobalOrg,
    globalOrgId,
    globalOrganization,
    getList,
  } = useJournal({
    defaultSort: {
      column: 'number',
      direction: sortDirection.desc,
    },
    customService: requestParams =>
      service('techJournalService', 'getAll', {
        ...requestParams,
        id: eskziId,
      }),
  });

  useEffect(() => {
    
    setVisibleFieldsAction(config.fieldsConfig.map(el => ({ 
      [ el.alias ]: el.title
    })))
  }, [])

  return(
    <div className="main-card__service">
      <ExpandingBlock
        renderHeader= {() => 'Журнал учета: Технический (аппаратный)'}
        className="eskzi-card__service-block"
        initialState={true}
      >
        <section className = {'technicalJournalReport list'}>
          {inProgress && <Overlay />}
          <ManagePanel borders={Border.All}>
            <TechnicalJournalActionPanel
              getList = {getList}
              filterForRequest = {filterForRequest}
              sorting = {sorting}
              fieldsVisible = {fieldsVisible}
              items = {selectedObjects}
              globalOrgId = {globalOrgId}
              inProgress = {inProgress}
              setProgress = {onInProgress}
              setSelectedAction = {setSelectedAction}
              setSelectedPagination = {setSelectedPagination}
              needOrganization = { false }
              skzi = {skzi}
            />
          </ManagePanel>
          <Table
            totalCount={totalCount}
            list = {list}
            listFieldsVisible = {fieldsVisible}
            sortListAction = {sortListAction}
            sorting = {sorting} 
            selectedItems = {selectedItems} 
            selectedObjects = {selectedObjects}
            setSelectedAction = {setSelectedAction}
            setSelectedPagination = {setSelectedPagination}
            inProgress = {inProgress}
            fieldsConfig = { config.fieldsConfig }
            itemsSelectable = { true }
            changedFieldsVisibility = { true }
            onItemClick = { null }
            headerInfo = { true }
          />
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageCount={pageCount}
            perPage={perPage}
          />
        </section>
      </ExpandingBlock>
    </div>

  );
}