/** @format */

import { CardState } from 'app/components/ui/card/cardMaterial';

import { InputType } from 'app/components/ui/Input';
import Button from 'app/components/ui/button/button';
import { ReactComponent as DeleteImage } from 'assets/img/commonVer2/delete.svg';
import { ReactComponent as PlusImage } from 'assets/img/commonVer2/plus.svg';
import { SelectType } from '../../ui/picker/picker';
import Autocomplete, { Services } from '../../ui/autocomplete/autocomplete';
import useRoleForm from '../hooks/useRoleForm';

export default function RoleForm(props) {
  const {
    cardState,
    formData = {},
    serverFormViewModel,
    onChange,
    errorByFields = {},
    onAdd,
    onRemove,
  } = props;

  const { autocompleteGroupsService, getAutocompleteRolesService, getFieldsetError, getFormDataValue } = useRoleForm({
    formData,
    serverFormViewModel,
    onAdd,
    errorByFields,
  });

  const selectedRoles = getFormDataValue('roles');
  const fields = !Array.isArray(selectedRoles)
    ? ''
    : selectedRoles.map((sr, index) => {
        return (
          <div className="card-role" key={index}>
            <div className="card-field card-group-path card-separator">
              <Autocomplete
                type={InputType.hybrid}
                onEmptySearch={false}
                renderInputValue={val =>
                  val ? (val.name ? val.name : val) : ''
                }
                placeholder={'Начните ввод или выберите из справочника'}
                label={'Наименование группы в AD'}
                serviceType={Services.activeDirectorySettingsService}
                getItemName={vel => vel.elementPath}
                selectType={SelectType.single}
                notStandardService={autocompleteGroupsService}
                absoluteInfo
                onSelect={value =>
                  onChange(
                    {
                      key: 'roles',
                      field: 'groupPath',
                    },
                    value
                      ? value.elementPath
                        ? value.elementPath
                        : value
                      : value,
                    index
                  )
                }
                error={getFieldsetError('roles', 'groupPath', index)}
                value={sr.groupPath}
                readOnly={cardState === CardState.view}
              />
            </div>
            <Autocomplete
              label="Роль в системе"
              className="card-role-name"
              serviceType={Services.userRoles}
              selectType={SelectType.single}
              notStandardService={getAutocompleteRolesService(sr)}
              value={sr.userRole}
              readOnly={cardState === CardState.view}
              absoluteInfo
              onSelect={value => {
                onChange(
                  {
                    key: 'roles',
                    field: 'userRole',
                  },
                  value,
                  index
                );
              }}
              error={getFieldsetError('roles', 'userRole', index)}
            />
            <Button
              className="card-management__item card-remove-role-button"
              type="image"
              notSubmit={true}
              onClick={() => onRemove('roles', index)}
              disabled={
                cardState === CardState.view ||
                (index === 0 && getFormDataValue('roles').length === 1)
              }
            >
              <DeleteImage />
            </Button>
          </div>
        );
      });
  return (
    <div className="card-roles-list">
      {fields}
      <Button
        notSubmit={true}
        className="card-management__item card-add-role-button"
        type="secondary"
        onClick={() => onAdd('roles', { groupPath: '', userRole: '' })}
        disabled={cardState === CardState.view}
      >
        <PlusImage className="button-image" />
        <span className="button-title">Добавить</span>
      </Button>
    </div>
  );
}
