import React from 'react'
import { ReactComponent as DownloadImg } from 'assets/img/commonVer2/excel.svg';
import ButtonComponent from 'app/components/ui/button/button'
import { downloadFilePOST } from 'app/core/serverRequest/request';
import { downloadFile } from 'app/core/utility/file';
import withTooltip from 'app/components/HOC/TooltipHOC';

const Button = withTooltip(ButtonComponent)

export default function downloadXlsButton(props) {
  const { 
    url, 
    items, 
    data, 
    isPostDownload = false, 
    inProgressAction,
    tooltip = '',
    Img,
    disabled
  } = props
  const dowloadUrl = `api/v1/${url}?id=${items.join('&id=')}`

  if (isPostDownload) {
    return (
      <Button 
        tooltip={tooltip}
        type='image'
        onClick={async () => {
          const { blob, fileName } = await downloadFilePOST(url, data)
          await downloadFile({blob, fileName})
          inProgressAction && inProgressAction(false)
        }}
        disabled={false}
      >
        <DownloadImg className='button-image'></DownloadImg>
      </Button>
    )
  }

  return ((!disabled && items.length) ? (
      <a href={dowloadUrl} download>
        <Button 
          tooltip={tooltip}
          type='image'
          onClick={null}
          disabled={disabled}
        >
          {Img && <Img className='button-image'></Img>}
          {!Img && <DownloadImg className='button-image'></DownloadImg>}
        </Button>
      </a>
    ) : (
      <Button 
        tooltip={tooltip}
        type='image'
        onClick={null}
        disabled={true}
      >
        {Img && <Img className='button-image'></Img>}
        {!Img && <DownloadImg className='button-image'></DownloadImg>}
      </Button>
    )
  )
}