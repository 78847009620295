import { connect } from 'react-redux';
import { 
  skziInstanceInProgressAction, 
  skziInstanceResetAction, 
  skziGetSkziBuildDataAction
} from 'redux/actions/skziInstance/skziInstanceAction'
import skziBuildService from 'app/services/skziBuildService'
import FilesDialog from 'app/components/skziRegistry/mainList/skziVersion/files/fileDialog';
import { getResourceRights } from 'app/core/auth/auth';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import withTooltip from 'app/components/HOC/TooltipHOC';
import ActionButtonImage from './ActionButtonImage';
import Overlay from 'app/components/ui/overlay';
const ButtonWithTooltip = withTooltip( ActionButtonImage );

const RenderDocumentsDialog = ({ 
  skziInstance, 
  profile, 
  close,
  skziInstanceInProgressAction 
}) => {

  const { fileData } = skziInstance
  
  const _onError = error => {
    console.log(error)
  }

  return fileData ? (
    <FilesDialog
      fileRights = { getResourceRights(profile, RESOURCES.files) }
      data = { fileData }
      downloadAllService = { skziBuildService.getBuildFiles }
      readOnly = { true }
      onError = { _onError }
      onCancel = {() => { close(); }}
      onFileActionStart={() => skziInstanceInProgressAction(true)}
      onFileActionFinish={() => skziInstanceInProgressAction(false)}
      renderHeader = {() => {
        return (
          <div className='modal__header'>
            Комплект поставки 
          </div>
        )
      }}
    />) : null
}



const Complectation = ({ 
  items, 
  titul,
  skziInstanceInProgressAction, 
  skziGetSkziBuildDataAction, 
  skziInstance, 
  profile, 
  skziInstanceResetAction,
  isExternal
}) => {

  let disabled = items.length !== 1;
  const { inProgress } = skziInstance || {}

  if( isExternal && items[0] && items[0].skzi ){
    if( items[0].skzi.documentationDisabled ){
      disabled = true;
    }
  }

  const _onClick = () => {
    if( items.length === 0 ) return;
    const item = items[0];
    skziInstanceInProgressAction(true)
    skziGetSkziBuildDataAction(item)
  }

  const close = () => {
    skziInstanceResetAction();
  }
  
  return( 
    <>
      {inProgress && <Overlay />}
      <ButtonWithTooltip img = "ComplectationImg" action = { _onClick } disabled = { disabled }   tooltip = { titul }  />
      <RenderDocumentsDialog   
            skziInstance = {skziInstance} 
            profile = { profile }
            close = { close }
            skziInstanceInProgressAction = {skziInstanceInProgressAction}/>
    </>
  )
}


const mapStateToProps = state => {
	return {
    skziInstance : state.skziInstance, 
    profile : state.profile
  };
}

const mapDispatchToProps = {
  skziInstanceInProgressAction,
  skziInstanceResetAction,
  skziGetSkziBuildDataAction,
  skziBuildService
}

export default connect( mapStateToProps, mapDispatchToProps )( Complectation );