/** @format */

import React from 'react';
import { useDispatch } from 'react-redux';
import Button from 'app/components/ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import withTooltip from 'app/components/HOC/TooltipHOC';
import { ActionCreator } from 'redux/actions/uploadProcessing/uploadProcessing';

const WithTooltipButton = withTooltip(Button);

export default function MassImportButton({ type, disabled, fileUploadCallBack }) {
  const dispatch = useDispatch();
  const onClickUploadButtonHandler = () => {
    dispatch(ActionCreator.setUploadModalFileTypeState(type));
    dispatch(ActionCreator.setUploadModalState(true));
    fileUploadCallBack && fileUploadCallBack(true);
  };

  return (
    <WithTooltipButton
      type={'image'}
      disabled={disabled}
      onClick={onClickUploadButtonHandler}
      tooltip="Загрузка из файла"
      className="btn-import"
    >
      <Img img={Image.FileUpload} />
    </WithTooltipButton>
  );
}
