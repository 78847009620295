import React, { useState, useEffect } from 'react'

import Portal from 'app/components/ui/portal/portal'
import withModal from 'app/components/HOC/ModalHOC'
import withToggle from 'app/components/HOC/ToggleHOC'
import SignatureTrigger from './signatureTrigger'
import SignatureDataComponent from './signatureData'

const SignatureData = withModal(SignatureDataComponent)

const SignatureInfo = ({signData, title, toggleStatus, toggle }) => {
  const { certificateInfo } = signData || {}
  return (
    <div className='signature-data-dialog'>
      
        {toggleStatus ? (
          <Portal>
            <div className='signature-data-dialog'>
              <SignatureData
                data={signData}
                onCancel={toggle}
                renderHeader = {() => {
                  return (
                    <div className='modal__header'>
                      {'Реквизиты электронной подписи'}
                    </div>
                  )
                }}
              />
            </div>
          </Portal>
        ) : null}
      <SignatureTrigger
        onClick={toggle}
        title={title}
        disabled={!certificateInfo}
      />
    </div>
  )
}

export default withToggle(SignatureInfo)