import React, { Component } from 'react'
import { connect } from 'react-redux'


import Overlay from 'app/components/ui/overlay'
import ConfirmDialog,  { dialogType } from 'app/components/dialog/confirmDialog'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import ButtonComponent from 'app/components/ui/button/button'

import { ReactComponent as PlusImg } from 'assets/img/commonVer2/plus.svg'
import { ReactComponent as DeleteImg } from 'assets/img/commonVer2/delete.svg'
import { ReactComponent as EditImg } from 'assets/img/commonVer2/edit_pen.svg'
import { ReactComponent as DownloadToXlsImg } from 'assets/img/commonVer2/excel.svg'

import { formatDate } from 'app/core/utility/date'
import TableComponent from 'app/components/ui/obsoleteTable/table';
import { capitalize, serverlikeErrors, formatFIO, stringPlural } from 'app/core/utility/common'
import SignatureDialog, { isDigitalSignNeeded, onOneClickActionSingDialog, oneClickActionAfterSign, getEventDataForServer } from 'app/components/signature/SignatureDialog'
import AddAdmittanceDialog from './addAdmittanceDialog'
import AddTraining from './addTrainingDialog'

import { itemActions, sortDirection } from 'app/components/ui/constants'

import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import withModal from 'app/components/HOC/ModalHOC'

import { 
  USERSKZIADMITTANCEADDADMITANCE,
  USERSKZIADMITTANCEUPDATEADMITANCE
 } from 'redux/actions/actionTypes'

import * as errorAction from 'redux/actions/common'
import * as userSkziAdmittanceAction from 'redux/actions/userAccount/userSkziAdmittanceAction'
import * as signatureActions from 'redux/actions/common/signatureAction'
import withTooltip from 'app/components/HOC/TooltipHOC'
import withEndlessScrollHOC from 'app/components/HOC/EndlessScrollHOC'
import service from 'app/services/service'

const Table = withEndlessScrollHOC(TableComponent)

const Button = withTooltip(ButtonComponent)
const ModalAddAdmittanceDialog = withModal(AddAdmittanceDialog)
const AddTrainingDialog = withModal(AddTraining)

class userSkziAccess extends Component {
  constructor(props){
    super(props)

    const { profile } = props
    // костыль - после обновления реакта 
    // до 16.14 перестала генериться ссылка
    this.fixRef = React.createRef()

    this.state = { 
      addAdmittanceDialog: null, 
      addTrainingDialog: null,
      changedAdm: {},
      changedTraining: {},
      admittanceSort: { 
        column: 'changeDate',
        direction: sortDirection.desc
      },
      trainingSort: { 
        column: 'skziVersion',
        direction: sortDirection.desc
      },
      admittanceColumns: [
        {
          title: 'Номер',
          alias: 'id',
          settings: {
            useSorting: true,
            width: 150
          },
        },
        {
          title: 'Номер заключения',
          alias: 'name',
          settings: {
            useSorting: true,
          },
        },
        {
          title: 'Дата выдачи',
          alias: 'issueDate',
          settings: {
            useSorting: true,
            width: 300
          },
          renderTemplate: item => {
            const { issueDate } = item
            
            return formatDate(issueDate, 'dd.mm.yyyy')
          }
        },
        {
          title: 'СКЗИ',
          alias: 'skziInfo',
          settings: {
            useSorting: true,
            width: 400
          },
          renderTemplate: item => {
            const { skziInfo } = item || {}
            const { skzi, version } = skziInfo || {}
            const { name: skziName = '' } = skzi || {}
            const { name: versionName = '' } = version || {}

            return  `${skziName} ${versionName}` 
          }
        },
        {
          title: 'Дата изменения',
          alias: 'changeDate',
          settings: {
            useSorting: true,
            width: 300
          },
          renderTemplate: item => {
            const { changeDate } = item
            
            return formatDate(changeDate, 'dd.mm.yyyy')
          }
        },
      ],
      trainingColumns: [
        {
          title: 'СКЗИ',
          alias: 'skziVersion',
          settings: {
            useSorting: true,
          },
        },
        {
          title: 'Дата добавления',
          alias: 'changeDate',
          settings: {
            useSorting: true,
            width: 300
          },
          renderTemplate: item => {
            const { changeDate } = item
            
            return formatDate(changeDate, 'dd.mm.yyyy')
          }
        },
      ]
    }
  }

  componentWillUnmount () {
    const { 
      userSkziAdmittanceSelectAdmittanceAction,
      userSkziAdmittanceSelectTrainingAction
    } = this.props

    userSkziAdmittanceSelectTrainingAction()
    userSkziAdmittanceSelectAdmittanceAction()
  }

  _getAdmittanceTableOptions = () => {
    const { admittanceSort } = this.state
    const { column, direction } = admittanceSort

    return {
      sortable: {
        defaultColumn: column,
        defaultDirection: direction,
        onChange: (name, direction) => this._onAdmittanceSort(name, direction)
      },
      multipleSelect: {use: true, onCheck: this._onAdmittanceSelect},
      useActions: {use: false},
      onRowAction: this._onCardNavigation
    }
  }

  _onCardNavigation = item => {
    const { history, profile } = this.props
    const { id } = item;

    const admittanceRights = getResourceRights(profile, RESOURCES.admittance)

    admittanceRights.VIEW_CARD && history.push(`/acts/admittance/card/${id}`)
  }
  
  _onAdmittanceSelect = selectedData => {
    const { userSkziAdmittanceSelectAdmittanceAction } = this.props
    userSkziAdmittanceSelectAdmittanceAction(selectedData)
  }

  _onTrainingSelect = selectedData => {
    const { userSkziAdmittanceSelectTrainingAction } = this.props
    userSkziAdmittanceSelectTrainingAction(selectedData)
  }

  _getTrainingTableOptions = () => {
    const { trainingSort } = this.state
    const { column, direction } = trainingSort

    return {
      sortable: {
        defaultColumn: column,
        defaultDirection: direction,
        onChange: (name, direction) => this._onTrainingSort(name, direction)
      },
      multipleSelect: {use: true, onCheck: this._onTrainingSelect},
      useActions: {use: false},
      onRowAction: this._onVersionCardNavigation
    }
  }

  _onVersionCardNavigation = (item) => {
    const { history, profile } = this.props
    const { skziVersionId, skziId } = item;
    const versionRights = getResourceRights(profile, RESOURCES.version)

    versionRights.VIEW_CARD && history.push(`/skziRegistry/accounting/skzi/${skziId}/version/${skziVersionId}?tabName=${encodeURIComponent('Обучающие курсы')}`)
  }

  _onAdmittanceSort = (column, direction) => {
    this.setState({ admittanceSort: { column, direction }})
  }

  _onTrainingSort = (column, direction) => {
    this.setState({ trainingSort: { column, direction }})
  }

  _registerWithoutSign = async (data) => {
    const { 
      userSkziAdmittanceAddAdmittance, 
      userSkziAdmittanceInProgressAction,
      userSkziAdmittanceUpdateAdmittance
    } = this.props
    const { admittanceId } = data
    const requestData = {
      admittanceId,
      data: this._getSignatureRequestData(data),
      signature: null
    }

    let addResult;
    userSkziAdmittanceInProgressAction(true)

    if (admittanceId) {
      addResult = await userSkziAdmittanceUpdateAdmittance(requestData)
    } else {
      addResult = await userSkziAdmittanceAddAdmittance(requestData)
    }
    
    this._finishAdmittanceAction(addResult)
  }

  _finishAdmittanceAction = (result) => {
    const { type, payload } = result
    const { id: admittanceId } = payload || {}
    const { current } = this.fixRef

    if (type === USERSKZIADMITTANCEADDADMITANCE
        || type === USERSKZIADMITTANCEUPDATEADMITANCE) {
      
      this.setState({ 
        addAdmittanceDialog: {id: admittanceId}
      }, () => {
          current._onFilesUpload()
          this.setState({ addAdmittanceDialog: null, changedAdm:{added:[payload]}})
      })
    }
  }

  _onSignDialog = (data) => {
    const { 
      setRequestData, 
      generateText, 
      userSkziAdmittanceInProgressAction
    } = this.props

    const signTextRequest = {
      ...this._getSignatureRequestData(data),
      signatureRequestType: 'Admittance'
    }
  
    setRequestData({...data});
    userSkziAdmittanceInProgressAction(true)
    generateText(signTextRequest);
  }

  _onTrainingAdd = async (skziVersion) => {
    const { 
      userSkziAdmittanceAddTraining,
      userSkziAdmittanceInProgressAction
    } = this.props
    const { currentUserAccount } = this.props
    const { id: userId } = currentUserAccount
    const { version } = skziVersion
    const { id: skziVersionId } = version

    userSkziAdmittanceInProgressAction(true)
    const result = await userSkziAdmittanceAddTraining({ userId, skziVersionId })
    const { payload, errors } = result || {}
    this.setState({ addTrainingDialog: false, changedTraining: {added:errors && errors.length ? [] : [payload]} })
  }

  _onFilesAdd = () => {
    const { current } = this.fixRef

    current._onFilesUpload()
    this.setState({ addAdmittanceDialog: null })
  }

  _onAdmittanceAdd = async (data) => {
    const { profile } = this.props

    if (isDigitalSignNeeded(profile, 'Admittance')) {
      this._onSignDialog(data)
    } else {
      this._registerWithoutSign(data)
    }
  }

  _getSignatureRequestData = (data) => {
    const { name, userId, issueDate, skziInfo, signers } = data
    const { version } = skziInfo || {}
    const { id: versionId } = version || {}
    const { id } = userId || {}
    
    return { 
      name, 
      userId: id,
      issueDate: formatDate(issueDate, 'yyyy-mm-dd'),
      skziVersionId: versionId,
      signers: signers.map(item => item.id)
    }
  }

  _onRunAdmittanceDelete = async () => {
    const { 
      userSkziAdmittance, 
      userSkziAdmittanceDeleteAdmittance,
      userSkziAdmittanceInProgressAction
    } = this.props
    const { selectedAdmittance } = userSkziAdmittance
    const { items } = selectedAdmittance

    this.setState({ deleteAdmittanceConfirm: null})
    userSkziAdmittanceInProgressAction(true)
    const result = await userSkziAdmittanceDeleteAdmittance(items)
    const { payload = [], type } = result || {}
    this.setState({changedAdm: {deleted: payload}})
  }

  _onRunTrainingDelete = async () => {
    const { 
      userSkziAdmittance, 
      userSkziAdmittanceInProgressAction,
      userSkziAdmittanceDeleteTraining
    } = this.props
    const { selectedTraining } = userSkziAdmittance
    const { items } = selectedTraining

    this.setState({ deleteTrainingConfirm: null})
    userSkziAdmittanceInProgressAction(true)
    const result = await userSkziAdmittanceDeleteTraining(items)
    const { payload = [], type } = result || {}
    this.setState({changedTraining: {deleted: payload}})
  }

  _afterSign = async () => {
    const { 
      signature,
      userSkziAdmittanceInProgressAction, 
      userSkziAdmittanceAddAdmittance, 
      userSkziAdmittanceUpdateAdmittance
    } = this.props
    const { textForSignature, signatureResult, requestData } = signature
    const { current } = this.fixRef
    const { admittanceId } = requestData
    
    const signatureToServer = {
      admittanceId,
      data: this._getSignatureRequestData(requestData),
      signature: { data: textForSignature, signedData: signatureResult }
    }
    
    userSkziAdmittanceInProgressAction(true)
    const result = await userSkziAdmittanceAddAdmittance(signatureToServer)
    this._finishAdmittanceAction(result)
  }

  _onClearError = () => {
    const { clearErrorAction } = this.props
    
    clearErrorAction()
  }

  _onAdmittanceDeleteConfirm = () => {
    const { userSkziAdmittance } = this.props
    const { selectedAdmittance } = userSkziAdmittance
    const { items } = selectedAdmittance

    this.setState({
      deleteAdmittanceConfirm: {
        type: dialogType.confirm,
        title: `Вы уверены, что хотите удалить ${stringPlural(items.length, ['допуск', 'допуска', 'допусков'] )}: «${items.map(item => item.name || item.id).join(', ')}»?`
      }
    })
  }

  _onTrainingDeleteConfirm = () => {
    const { userSkziAdmittance } = this.props
    const { selectedTraining } = userSkziAdmittance
    const { items } = selectedTraining

    this.setState({
      deleteTrainingConfirm: {
        type: dialogType.confirm,
        title: `Вы уверены, что хотите удалить: «${items.map(item => item.skziVersion).join(', ')}»?`
      }
    })
  }

  _renderModal = () => {
    const { 
      userSkziAdmittance,
      userSkziAdmittanceResetAction,
      resetSignature,
      signature, 
      error, 
      profile 
    } = this.props
    const { confirmObject } = userSkziAdmittance
    const { deleteAdmittanceConfirm, deleteTrainingConfirm, addAdmittanceDialog, addTrainingDialog } = this.state
    const { textForSignature } = signature || {}
    
    return (
      <>
        {deleteAdmittanceConfirm ? 
          <ConfirmDialog
            {...deleteAdmittanceConfirm}
            onSubmit={this._onRunAdmittanceDelete}
            onCancel={() => this.setState({deleteAdmittanceConfirm: null})}
          /> : null
        }
        {deleteTrainingConfirm ? 
          <ConfirmDialog
            {...deleteTrainingConfirm}
            onSubmit={this._onRunTrainingDelete}
            onCancel={() => this.setState({deleteTrainingConfirm: null})}
          /> : null
        }
        {addAdmittanceDialog ?
          <ModalAddAdmittanceDialog
            fixRef={this.fixRef}
            onCancel={() => this.setState({addAdmittanceDialog: false})}
            admittanceParams={{ ...addAdmittanceDialog }}
            onAdmittanceAdd={this._onAdmittanceAdd}
            onFilesAdd={this._onFilesAdd}
            profile={profile}
            error={error}
          /> : null}
        {addTrainingDialog ? (          
          <AddTrainingDialog
            onCancel={() => this.setState({addTrainingDialog: false})}
            onSubmit={this._onTrainingAdd}
            renderHeader = {() => {
              return (
                <div className='modal__header'>
                  {'Добавление обучения'}
                </div>
              )
            }}
          />
        ) : null}
        {textForSignature ? (
          <SignatureDialog
            className={'signature-form'}
            profile={profile}
            onCancel={resetSignature}
            afterSignCallback={this._afterSign}
            renderHeader = {() => {
              return (
                <div className='sign__header'>
                  {'Добавление допуска к СКЗИ'}
                </div>
              )
            }}
          />) :null}
        {confirmObject ? 
          <ConfirmDialog
            {...confirmObject}
            onCancel={userSkziAdmittanceResetAction}
          /> : null
        }
      </>
    )
  }

  _renderDownloadToXlsButton = () => {
    const { userSkziAdmittance, currentUserAccount } = this.props
    const { selectedAdmittance } = userSkziAdmittance
    const { id: userId } = currentUserAccount
    const { items } = selectedAdmittance
    const dowloadUrl = `api/v1/admittance/xls/download?userId=${userId}&id=${items.map(item => item.id).join('&id=')}`

    return (
      <div className="buttons-element">
        {items.length ? (
          <a href={dowloadUrl} download>
            <Button 
              className='delete-button'
              type='image'
            >
              <DownloadToXlsImg className='button-image'></DownloadToXlsImg>
            </Button>
          </a>
        ) : (
          <Button 
            className='delete-button'
            type='image'
            disabled={true}
          >
            <DownloadToXlsImg className='button-image'></DownloadToXlsImg>
          </Button>
        )}
      </div>
    )
  }

  render() {
    const { userSkziAdmittance, currentUserAccount, profile } = this.props
    const { id: userId } = currentUserAccount || {}
    const { inProgress, selectedTraining, selectedAdmittance } = userSkziAdmittance
    const { items: selectedAdmittanceItems } = selectedAdmittance
    const { items: selectedTrainingItems } = selectedTraining
    const { 
      admittanceColumns, 
      trainingColumns, 
      trainingSort, 
      admittanceSort,
      changedAdm,
      changedTraining 
    } = this.state

    const userAccountRights = getResourceRights(profile, RESOURCES.userAccount)

    return (
      <section className='user-skzi-access'>
        {inProgress ? <Overlay /> : null}
        {this._renderModal()}
        <ExpandingBlock
          className='table-management'
          renderHeader= {() => 'Допуски к СКЗИ'}
          initialState={true}
        >
          <div className="manage-panel">
            {userAccountRights.UPDATE ? (
              <Button 
                className='create-button'
                type='primary'
                onClick={() => this.setState({addAdmittanceDialog: {userInfo: currentUserAccount}})}
              >
                <PlusImg className='button-image button-image--left'></PlusImg>
                <span className='button-title'>Добавить</span>
              </Button>
            ) : null}
            {this._renderDownloadToXlsButton()}
            {userAccountRights.UPDATE ? (
              <div className="buttons-element">
                <Button 
                  tooltip={'Удалить'}
                  className='delete-button'
                  type='image'
                  onClick={this._onAdmittanceDeleteConfirm}
                  disabled={!selectedAdmittanceItems.length}
                >
                  <DeleteImg className='button-image'></DeleteImg>
                </Button>
              </div>
            ) : null}
          </div>
          <Table
            loadData = {({page, pageSize}) => service('courseService', 'getAllUserAdmittance',{userId, ...admittanceSort, page, pageSize })}
            dependencies = {[admittanceSort]}
            changedRows = {changedAdm}
            columns={admittanceColumns}
            selected={selectedAdmittance}
            noBorders={true}
            options={this._getAdmittanceTableOptions()}
          />
        </ExpandingBlock>
        <ExpandingBlock
          className='table-management'
          renderHeader= {() => 'Обучение СКЗИ'}
          initialState={true}
        >
          <div className="manage-panel">
            {userAccountRights.UPDATE ? (
              <Button 
                className='create-button'
                type='primary'
                onClick={() => this.setState({ addTrainingDialog: true })}
              >
                <PlusImg className='button-image button-image--left'></PlusImg>
                <span className='button-title'>Добавить</span>
              </Button>
            ) : null}
            {userAccountRights.UPDATE ? (
              <div className="buttons-element">
                <Button 
                  tooltip={'Удалить'}
                  className='delete-button'
                  type='image'
                  onClick={this._onTrainingDeleteConfirm}
                  disabled={!selectedTrainingItems.length}
                >
                  <DeleteImg className='button-image'></DeleteImg>
                </Button>
              </div>
            ) : null}
          </div>
          <Table
            loadData = {({page, pageSize}) => service('courseService', 'getAllUserTraining',{userId, ...trainingSort, page, pageSize })}
            dependencies = {[trainingSort]}
            columns={trainingColumns}
            changedRows={changedTraining}
            selected={selectedTraining}
            noBorders={true}
            options={this._getTrainingTableOptions()}
          />
        </ExpandingBlock>
      </section>
    )
  }
}


const mapStateToProps = state => { 
  const { profile, userSkziAdmittance, error,signature } = state

  return {
    profile,
    signature,
    userSkziAdmittance,
    error 
  }
}

export default connect(
  mapStateToProps,
  { 
    ...errorAction,
    ...userSkziAdmittanceAction,
    ...signatureActions
  })(userSkziAccess)