import React from 'react'

import { sortDirection } from 'app/components/ui/constants'
import { renderItem } from 'app/components/ui/autocomplete/autocomplete'
import { formatFIO } from 'app/core/utility/common'

export const defaultSettings = {
  columns: [
    {
      title: 'ФИО',
      alias: 'name',
      settings: {
        useSorting: true,
      },
      renderTemplate: item => {
        return formatFIO(item)
      }
    },
    {
      title: 'Организация',
      alias: 'organization',
      settings: {
        useSorting: true,
        width: 500
      },
      renderTemplate: item => {
        const { organization } = item
        
        return renderItem.organizations(organization)
      }
    },
  ],
  sort: { 
    column: 'name',
    direction: sortDirection.asc
  }
} 