import {FilterFlagName, LicenseType} from "app/components/MassImports/enums";

export const parseOrganizationControlValue = (data) => {
    if (!data) {
        return null;
    }

    const {name, id} = data;

    return {
        alias: 'organizationId',
        isExtendedSearchBlock: false,
        requestFormatIsString: true,
        valueFormatedString: name,
        valueForRequest: id,
    };
};

export const parseLicenseImportType = (type) => {
    if (!type) {
        return null;
    }

    return LicenseType[type.name];
};

export const convertPayloadFileId = (payload) => {
    payload.conditions.fileId = payload.fileId;
    return payload;
};
