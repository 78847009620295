import React, { PureComponent } from 'react'
import PropType from 'prop-types'
import { debounce, excludeByItemId, isEmptyObject } from 'app/core/utility/common'
import service from 'app/services/service'
import { formatFIO } from 'app/core/utility/common'
import { ReactComponent as ChevronImg } from 'assets/img/commonVer2/chevron.svg'
import { ReactComponent as LoadingImg }  from 'assets/img/commonVer2/ic_loading.svg'
import Input, {InputType} from 'app/components/ui/Input/Input'
import Overlay, { OVERLAYSIZE } from 'app/components/ui/overlay'
import { keyCodes } from 'app/components/ui/constants'
import globalConfig from 'app/core/config';
import Checkbox from 'app/components/ui/checkbox'
import MultiSelect from 'app/components/ui/multiSelect/MultiSelect'
import Button, { buttonTypes } from '../button/button'
import Tag from '../tag'
import PopupPanel from "app/components/ui/popupPanel/PopuPanel";
import styles from './autocomplete.module.scss'

export const Services = {
  instanceStatuses: 'instanceStatusesService',
  objectTypes: 'objectTypesService',
  skziTypes: 'skziTypesService',
  skziInstance: 'skziInstanceService',
  organizations: 'organizationService',
  skzi: 'skziService',
  skziVersion: 'skziVersionService',
  skziBuild: 'skziBuildService',
  user: 'userService',
  skziUser: 'skziUserService',
  skziClass: 'skziClassService',
  keyCarrierType: 'keyCarrierTypeService',
  keyCarrier: 'keyCarrierService',
  userRoles: 'userRolesService',
  organizationOkzOnly : 'organizationOkzOnlyService',
  skziLicenseType: 'licenseService',
  activeStates : 'activeStatesService',
  eskziUserStatus : 'eskziUserStatusService',
  okzScheme: 'okzScheme',
  volume: 'volumeService',
  activeDirectorySettingsService: 'activeDirectorySettingsService',
  isActiv : 'isActivService',
  keyDocument: 'keyDocumentService',
  producers : 'producersService',
  license: 'licenseService',
  organizationServed: 'organizationServedService',
  keyDocCertStates: 'keyDocCertStatesService',
  signStatus: 'signStatusService',
  signer: 'signerService',
  selfServiceStatus: 'selfServiceStatusService',
  userStatus: 'userStatusService',
  certRequestType: 'certRequestTypeService',
  certRequestStatus: 'certificateRequestService',
  certificateStatus: 'certificateStatusService'
}

const organizationView = org => {
  const { name, shortName, inn, ogrn, kpp } = org
  const safeShortName = (shortName || '').trim()
  
  return (
    <div key={name} className="organization-item">
      <span className="organization-item__name">{safeShortName || name || ''}</span>
      <div className="organization-item__details">
        {inn || kpp ? (
          <div className="details__row">
            {inn ? (
              <span className="row__item">
                <span className='item-title'>ИНН: </span>
                <span className='item-value'>{inn}</span>
              </span>
            ) : null}
            {inn && kpp ? (<span className="row__item">,</span>) : ''}
            {kpp ? (
              <span className="row__item">
                <span className='item-title'>КПП: </span>
                <span className='item-value'>{kpp}</span>
              </span>
            ) : null}
          </div>
        ) : null
        }
        {ogrn ? (
          <div className="details__row">
            <span className="row__item">
              <span className='item-title'>{`${ogrn.length === 15 ? 'ОГРНИП' : 'ОГРН'}: `}</span>
              <span className='item-value'>{ogrn}</span>
            </span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export const renderItem = {
  organizations: organizationView,
  okzScheme: organizationView,
  eskziUser: user => {
    const { id, organization } = user
    const { name = '' } = organization || {}

    return (
      <div key={id} className="user-item">
        <span className="user-item__name">{formatFIO(user)}</span>
        <span className="user-item__detail">{name}</span>
      </div>
    )
  },
  skziVersion: data => {
    const { skzi, version } = data || {}
    const { name: skziName = '' } = skzi || {}
    const { name: versionName = '' } = version || {}

    return (
      <div key={`${skziName} ${versionName}`} className="user-item">
        { `${skziName} ${versionName}` }
      </div>
    )
  },
  skziBuild : data => {
    const { build = {}, skziClass = {} } = data || {};
    const value = `${build.name || ''} ${skziClass.name || ''}`;
    return (
      <div key={`${value}`} className="user-item">
        { `${value}` }
      </div>
    )
  },
  eskzi: data => {
    const { skzi: skziData } = data || {}
    const { skzi, version, build } = skziData || {}
    const { name: skziName = '' } = skzi || {}
    const { name: versionName = '' } = version || {}
    const { name: buildName = '' } = build || {}

    return (
      <div key={`${skziName} ${versionName}${buildName}`} className="user-item">
        { `${skziName} ${versionName} ${buildName}` }
      </div>
    )
  }
}

export const renderInputValue = {
  organizations: data => {
    const { name, shortName } = data || {}
    return (shortName || '').trim() || name || ''
  },
  eskziUser: data => formatFIO(data),
  skziVersion: data => {
    const { skzi, version } = data || {}
    const { name: skziName = '' } = skzi || {}
    const { name: versionName = '' } = version || {}

    return  skziName || versionName ? `${skziName} ${versionName}` : ''
  },
  eskzi: data => {
    const { skzi: skziData } = data || {}
    const { skzi, version, build } = skziData || {}
    const { name: skziName = '' } = skzi || {}
    const { name: versionName = '' } = version || {}
    const { name: buildName = '' } = build || {}

    return  skziName || versionName ? `${skziName} ${versionName} ${buildName}` : ''
  },
  okzScheme: data => (data || {}).shortName || (data || {}).name,
  volume: val =>  val ?  (val.name ? val.name : val) : ''
}

class Autocomplete extends PureComponent {
  static get propTypes() {
    return {
      className: PropType.string,
      placeholder: PropType.string,
      onFocus: PropType.func,
      onEmptySearch: PropType.bool,
      value: PropType.any,
      linkTarget: PropType.string,
      error: PropType.object,
      renderItem: PropType.func,
      renderInputValue: PropType.func,
      getItemName: PropType.func,
      serviceType: PropType.string,
      notStandardService: PropType.any,
      onSelect: PropType.func,
      CustomImage: PropType.object,
      type: PropType.string
    };
  }

  constructor(props){
    super(props)

    this.state = this._getState(props)
    this.input = React.createRef()
    this.domNode = React.createRef()
    this.triggerId = `endless-scroll-trigger${Math.random()}`
  }

  _globalHandler = e => {
    const { itemsOpened } = this.state

    if (!this.domNode.current.contains(e.target)) {
      itemsOpened && this.setState({ itemsOpened : !itemsOpened })
    }
  }

  componentDidMount () {
    document.addEventListener('click', this._globalHandler)

    this.observer = new IntersectionObserver(([entry]) => { 
      this.setState({triggerVisible: entry.isIntersecting}) 
    })
  } 

  componentWillUnmount () {
    document.removeEventListener('click', this._globalHandler)
    this.observer.disconnect();
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      items,
      itemsOpened,
      inputValue,
      focused,
      inProgress,
      error: stateError,
      triggerVisible,
      allFetched,
      currentPage,
      firstRequest
    } = this.state

    if(focused && itemsOpened) {
      const trigger = document.getElementById(this.triggerId)
      trigger && this.observer.observe(trigger)
    }

    if (!itemsOpened && currentPage !== 1) {
      this.setState({currentPage: 1, items: []})
    }

    if (triggerVisible && !this.fetchingData && !allFetched) {
      this.fetchingData = true
      const nextPage = currentPage + 1
      this.setState({currentPage: nextPage}, () => {

        this._fetchData({
          // наверняка же захотят вернуть такое поведение
          // term: firstRequest ? '' : inputValue,
          term: inputValue,  
          page: nextPage, 
          pageSize: globalConfig.paginationPerPage
        })
      })
    } 
  }

  // need to refactor this component so it
  // work with this.props.value only, no local state
  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      triggerVisible,
      allFetched,
      currentPage,
      firstRequest,
      itemsOpened,
      focused,
      items
    } = this.state

    return this.setState({
      ...this._getState(nextProps), 
      triggerVisible,
      allFetched,
      currentPage,
      firstRequest,
      itemsOpened,
      focused,
      items
    })
  }

  _getState = props => {
    const { value, type, getItemKey } = props
    const isMultiselect = type === InputType.multiSelect
    const { inputValue = '' } = this.state || {}

    return {
      inputValue: isMultiselect ? inputValue : this._getInputText(value),
      selectedValue: value,
      inProgress: false,
      itemsOpened: false,
      items: [],
      selectedItemCount: 0,
      itemsRefs: [],
      triggerVisible: false,
      currentPage: 1,
      allFetched: false,
      firstRequest: true,
      selectedItems: value
    }
  }

  _getInputText = value => {
    const { renderInputValue } = this.props
    const { shortName, name = '' } = value || {}

    return renderInputValue ? renderInputValue(value) : shortName || name
  }

  _responseHandler = (response, term) => {
    const { excludeItems } = this.props
    const { focused, items, currentPage, inputValue = '' } = this.state

    if (term !== inputValue) {
      return
    }

    const { data, dataCount, errors = [], isError } = response ||{}
    const { detail = {} } = errors[0] || {}
    const { response: detailResponse } = detail
    const { errors: innerErrors = [] } = detailResponse || {}

    if (isError || !data) {
      this.setState({inProgress: false, error: innerErrors[0]})
      return
    }

    const allowedItems = excludeByItemId(data, excludeItems)
    this.fetchingData = false
    !focused && this.setState({ inProgress: false})
    focused && this.setState({ 
      items: [...items, ...this._mapDataToItems(allowedItems)], 
      inProgress: false, 
      itemsOpened: true, 
      allFetched: dataCount <= currentPage * globalConfig.paginationPerPage
    })
  }

  _search = debounce(
    async term => {
      this.setState({inProgress: true, currentPage: 1, items: []}, () => {
        this._fetchData({term, page: 1, pageSize: globalConfig.paginationPerPage})
      })
    }, 400
  )

  _fetchData = async ({term = '', page, pageSize}) => {
    const safeTerm = term || '';
    try {
      const { serviceType, notStandardService } = this.props
      if (notStandardService) {
        const { serviceName, serviceMethod, data = {}} = notStandardService
        const dataService = serviceName && serviceMethod
          ? ({page, pageSize}) => service(serviceName,  serviceMethod, { term: safeTerm.trim(), page, pageSize, ...data})
          : notStandardService

        // const api = await notStandardService()
        this._responseHandler(await dataService({page, pageSize}), term)
      } else {
        this._responseHandler(await service(serviceType, 'autocomplete', {term: safeTerm.trim(), page, pageSize}), term)
      }
    } catch (err) {
      console.error('autocomplete error:', err);
      throw new Error('Ошибка связи с сервером. Обратитесь к администратору')
    }
  }

  // get rig of this, use renderItem
  _mapDataToItems = data => {
    const { getItemName } = this.props

    if (getItemName){
      return data.map(item => ({ ...item, name: getItemName(item)}))
    }
    return [...data]
  }

  _onFocus = () => {
    const { forbidEmptySearch, pickerOpened, readOnly } = this.props
    const { inputValue, itemsOpened } = this.state

    if (readOnly) {
      return
    }

    this.setState({
      ...this.state,
      focused: true
    })

    !itemsOpened && !pickerOpened && !forbidEmptySearch && this._search(inputValue)
  }

  _onBlur = () => {
    const { selectedValue } = this.state
    const { type } = this.props

    if (type !== InputType.multiSelect) {
      // если не изменили значение (не выбрали новое из списка\сбросили), 
      // но редактировали инпут, перед уходом актуализируем текст
      this.setState ({inputValue: this._getInputText(selectedValue)})
    
      this.setState ({ 
        itemsOpened: false, 
        focused: false, 
        selectedItemCount: 0, 
        items: [] ,
        firstRequest: true,
      })
    }
  }

  _onArrowDown = () => {
    const { itemsRefs, items, itemsOpened, selectedItemCount } = this.state
    const selectedCount = Math.min(items.length, selectedItemCount + 1)
    const currentRef = itemsRefs[selectedCount - 1]

    if (items.length && itemsOpened) {

      this.setState({selectedItemCount: selectedCount})
      currentRef && currentRef.current && currentRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  _onSubmit = () => {
    const { items, itemsOpened, selectedItemCount } = this.state

    if (itemsOpened && items.length && selectedItemCount) {
      this.setState({}, () => {
        this._onSelect(items[selectedItemCount - 1])
      })
    }
  }

  _onArrowUp = () => {
    const { items, itemsRefs, itemsOpened, selectedItemCount } = this.state
    const selectedCount = Math.max(1, selectedItemCount - 1)
    const currentRef = itemsRefs[selectedCount - 1]

    if (items.length && itemsOpened) {

      this.setState({selectedItemCount: selectedCount})
      currentRef && currentRef.current && currentRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  _onMouseEnter = (index) => {
    this.setState({selectedItemCount: index + 1})
  }

  _onSelect = item => {
    const { onSelect, type } = this.props

    if (type === InputType.multiSelect) {
      this._onMultiSelectCheckItem(item)
      return
    }

    this.setState({ 
      inputValue: this._getInputText(item), 
      itemsOpened: false, 
      selectedValue: item 
    }, () => {
      onSelect && onSelect(item)
    })
  }

  _onChange = (value, caretPosition, isCleared) => {
    const { forbidEmptySearch, onSelect, type } = this.props
    const { inProgress, focused } = this.state

    this.setState({ 
      inputValue: value, 
      itemsOpened: false,
      firstRequest: false
    }, () => {
      if (isCleared) {
        this.setState({
          selectedValue: null,
          selectedItems: []
        })
        onSelect && onSelect(null)
        !forbidEmptySearch && focused && this._search('')
      } else {
        !forbidEmptySearch && value === '' && focused && this._search(value)
        value !== '' && focused && this._search(value)
        // value === '' && focused && onSelect && onSelect(null)
        value !== '' && focused && type === InputType.hybrid && onSelect && onSelect(value)
      }
    })
  }

  _onToggleChipPopup = (isOpened) => {
    this.setState({
      ...this.state,
      itemsOpened: isOpened ? false : this.state.itemsOpened,
      moreChipPanelOpened: isOpened
    })
  }

  _onShowChipChange = (chipsToShow) => {
    this.setState({
      ...this.state,
      chipsToShow,
    })
  }

  _onMultiSelectCheckItem = (item) => {
    const { onSelect, getItemKey } = this.props
    const { selectedItems } = this.state
    const selectedKey = getItemKey ? getItemKey(item) : item.id
    const selectedIndex = selectedItems.findIndex(i => 
      getItemKey 
      ? getItemKey(i) 
      : i.id === selectedKey)
    let updatedSelected;
    if (selectedIndex >= 0) {
      updatedSelected = [
        ...selectedItems.slice(0, selectedIndex),
        ...selectedItems.slice(selectedIndex + 1),
      ]
    } else {
      updatedSelected = [...selectedItems, item]
    }

    this.setState({selectedItems: updatedSelected}, () => {
      onSelect && onSelect(updatedSelected)
    })
  }

  _renderItem = (item, index) => {
    const { selectedItemCount, itemsRefs, selectedItems } = this.state
    const { renderItem, type, getItemKey } = this.props
    const { name, id } = item
    const itemRef = React.createRef();
    itemsRefs[index] = itemRef
    const cssClass = renderItem ? 'autocompete-item' : 'items__item'
    const itemKey = getItemKey ? getItemKey(item) : item.id
    const isMultiselect = type === InputType.multiSelect
    const isChecked = isMultiselect ? selectedItems.find(i => getItemKey ? getItemKey(i) : i.id === itemKey) : false
    const clickHandler = isMultiselect 
                          ? () => this._onMultiSelectCheckItem(item)
                          : () => this._onSelect(item)

    return (
      <div 
        ref={itemRef}
        key={id || name || index} 
        className={`${isMultiselect ? '' : cssClass} ${selectedItemCount === index + 1 ? `${cssClass}--selected` : ''}`} 
        onMouseDown={clickHandler}
        onMouseEnter={() => this._onMouseEnter(index)}
      >
        {isMultiselect ? (
          <Checkbox
            checked={isChecked}
          > 
            {renderItem ? renderItem(item) : name}
          </Checkbox> 
          ) : (
            renderItem ? renderItem(item) : name
          )
        }
      </div>
    )
  }

  _renderNoData = () => {
    return (
      <div className="items__no-data">
        Ничего не найдено
      </div>
    )
  }
  
  _renderArrow = () => {
    const { disabled } = this.props;
    return (
      <Button type={buttonTypes.imageSmall} disabled={disabled} onClick={this._onFocus}>
        <ChevronImg className="control__custom-image" />
      </Button>
    );
  };

  render() {
    const {
      className,
      error: propsError,
      linkTarget,
      CustomImage = this._renderArrow,
      children,
      type,
      disabled,
      maxChipToShow,
      formatChipTitle = i => i.name,
    } = this.props
    const {
      items,
      itemsOpened,
      inputValue,
      focused,
      inProgress,
      error: stateError,
      selectedValue,
      selectedItems,
      chipsToShow,
      moreChipPanelOpened
    } = this.state

    // to do
    // из-за того что в фильтрах (src\app\components\list\Filters\FilterEllement.jsx, 18 строка) 
    // сделано плохо и для незаполненных
    // приходит пустой объект, приходится костылить
    const needClearIcon = isEmptyObject(selectedValue)
                          ? false
                          : !!selectedValue

    const needMultiselectSlot = type === InputType.multiSelect && selectedItems && selectedItems.length
    const chipValues = Object.keys(selectedItems || {}).map(key => selectedItems[key])
    const moreChipToShow = chipValues.slice(chipsToShow)

    return (
      <div 
        className={`autocomplete ${className || ''} ${inProgress ? 'autocomplete--in-progress' : ''}`}
        ref={this.domNode}
      >
        <Input
          ref={this.input}
          {...this.props}
          prependClippable
          clearImageVisibleOnEmptyValue = {needClearIcon}
          error={propsError || stateError}
          onFocus={this._onFocus}
          onChange={this._onChange}
          value={inputValue || ''}
          onBlur={this._onBlur}
          CustomImage={type !== InputType.hybrid ? CustomImage : null}
          toggleStatus={itemsOpened}
          linkTarget={linkTarget}
          disabled={disabled}
          onArrowDown={this._onArrowDown}
          onArrowUp={this._onArrowUp}
          onSubmit={this._onSubmit}
          slotPrepend={needMultiselectSlot ? 
            <MultiSelect
              values={chipValues}
              onTogglePopup={this._onToggleChipPopup}
              onShowChipChange={this._onShowChipChange}
              onChipDelete={this._onMultiSelectCheckItem}
              maxChipToShow={maxChipToShow}
            /> : null
          }
        />
        { inProgress && focused && <div className={`autocomplete__items` } >
            <Overlay size={OVERLAYSIZE.small}/>
          </div>}
        { itemsOpened
          && focused 
          && (children || items.length)
          ? (<div className={`autocomplete__items` } >
              <div className="items">
                {items.map((item, index) => this._renderItem(item, index))}
                <div 
                  id= {this.triggerId}
                  className="endless-scroll-trigger">
                </div>
                {this.fetchingData && 
                  <div className="endless-scroll--loading" >
                    <LoadingImg/>
                  </div>
                }
                {children}
              </div>
            </div>) : null}
        {!itemsOpened 
          && moreChipPanelOpened 
          && moreChipToShow.length 
          ? <PopupPanel
              className={styles.moreChipPopup}
              onClose={() => this._onToggleChipPopup(false)}
              fullWidth
            >
            {moreChipToShow.map((item) => (
              <Tag
                key={item.id}
                text={formatChipTitle(item)}
                item={item}
                // setTimeout для правильной работы 
                // проверки клика вне области popupPanel
                onDelete={() => setTimeout(() => this._onMultiSelectCheckItem(item), 0)}
              />
            ))}
          </PopupPanel> : null}
      </div>
    )
  }
}

export default Autocomplete