import React from 'react';

import { FilterControlType } from 'app/components/filter/FilterEllement';
import { formatDate } from 'app/core/utility/date';
import { sortDirection } from 'app/components/ui/constants';
import styles from './keyCarrierTab.module.scss'
import { Services } from 'app/components/ui/autocomplete/autocomplete';

export default userId => ({
  bookingApi: 'keyCarrierSelect/bookEvent',
  issueApi: 'keyCarrierSelect/issueEvent',
  pickerConfig: {
    pickerService: {
      serviceName: 'keyCarrierSelectService',
      serviceMethod: 'getAll',
      data: { id: userId },
    },
    serviceType: Services.keyCarrier,
    modalHeader: 'Ключевые носители',
    fiterCssClass: styles.keyCarrierTabPicker,
    filterConfig: [
      {
        type: FilterControlType.simpleSearch,
        alias: 'id',
        label: '№',
        pattern: '^[0-9]*$',
        extended: false,
        order: 0,
      },
      {
        type: FilterControlType.simpleSearch,
        alias: 'serialNumbers',
        label: 'Серийный №',
        extended: false,
        requestFormatIsArray: true,
        order: 1,
      },
      {
        type: FilterControlType.simpleSearch,
        alias: 'factoryNumbers',
        label: 'Заводской №',
        extended: false,
        requestFormatIsArray: true,
        order: 3,
      },
    ],
    pickerColumns: {
      columns: [
        { 
          title : '№',
          titleInfo : '№', 
          alias : 'id',
          settings: {
            useSorting: true,
            width : 100
          },
        },
        {
          title: 'Серийный №',
          alias: 'serialNumber',
          settings: {
            useSorting: true,
          },
        },
        {
          title: 'Тип',
          alias: 'keyCarrierType',
          settings: {
            useSorting: true,
          },
          renderTemplate: item => {
            const { keyCarrierType } = item 
            const { name } = keyCarrierType || {}
            return name || ''
          }
        },
        {
          title: 'Дата создания',
          alias: 'createDate',
          settings: {
            useSorting: true,
            width: 200,
          },
          renderTemplate: item => formatDate(item.createDate, 'dd.mm.yyyy'),
        },
      ],
      sort: {
        column: 'updateDate',
        direction: sortDirection.desc,
      },
    },
  },
});
