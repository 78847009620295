import React, { Component } from 'react'

import { keyCodes } from '../ui/constants'
import Overlay from 'app/components/ui/overlay'
import { eventStop } from 'app/core/utility/common'

// Obsolete
const withModalObsolete = PassedComponent => {
 class Modal extends Component {

    componentDidMount(){
      const { onCancel, forceShow } = this.props

      window.onkeydown = (e) => {
        switch (e.code) {
          case keyCodes.Esc:
            !forceShow && onCancel && onCancel();
            break;
        }
      }
    }

    render() {
      const { 
        className, 
        onCancel, 
        renderHeader, 
        forceShow,
        isGlobalOverlay,
        inProgress,
        forwardedRef,
      } = this.props

      return (
        <div className={`modal-container ${className || ''} ${isGlobalOverlay ? 'modal-container--global' : ''}`}>
          <div className="modal-container__overlay" onClick={(e) => {
            eventStop(e)
            !forceShow && onCancel && onCancel()
          }}></div>
          <div className="modal-container__panel">
            {inProgress ? <Overlay size={'small'}/> : null}
            {/* ///TODO  */}
            {/* перенести верстку хедера модального окна (<div className='modal__header'>) сюда */}
            {renderHeader ? renderHeader() : null} 
            <PassedComponent 
              {...this.props}
              ref={forwardedRef}
              isModal={true}
            />
          </div>
        </div>
      )
    }
  }

  return React.forwardRef((props, ref) => {
    return <Modal {...props} forwardedRef={ref} />;
  });
}

export default withModalObsolete