import React, { useEffect, useMemo, useState } from 'react'
import { Table } from 'app/components/list/Table/Table'
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import { formatDate } from 'app/core/utility/date'
import LinkKeyDocButton from './linkKeyDocButton'
import BreakLinkButton from './breakLinkButton'
import withSignature from 'app/components/HOC/withSignatureHOC'
import { sortDirection } from 'app/components/ui/constants'
import Overlay from 'app/components/ui/overlay'
import service from 'app/services/service'
import ManagePanel from 'app/components/ui/managePanel/managePanel'
import Group from 'app/components/ui/managePanel/group'
import { eskziStatuses } from 'app/components/keyDocuments/relativeKeyCarrier/keyCarrier'

const LinkButton = withSignature(LinkKeyDocButton) 
const BreakLink = withSignature(BreakLinkButton)

const fieldsConfig = [
  { 
    title : '№',
    alias : 'id',
    sortByValue : item => item.id,
    format : item => item.id,
    visibleOnStart : true, 
    userSorting : true, 
    width : 50
  },
  { 
    title : 'Наименование',
    alias : 'name',
    sortByValue : item => item.name,
    format : item => item.name,
    visibleOnStart : true, 
    userSorting : true, 
  },      
  { 
    title : 'Серийный №',
    alias : 'serialNumber',
    sortByValue : item => item.serialNumber,
    format : item => item.serialNumber, 
    visibleOnStart : true, 
    userSorting : true, 
    width : 100 
  },      
  { 
    title : '№ экземпляра',
    alias : 'identificationNumber',
    sortByValue : item => item.identificationNumber,
    format : item => item.identificationNumber,
    visibleOnStart : true, 
    userSorting : true, 
    width : 100 
  },      
  { 
    title : 'Срок действия',
    alias : 'expirationDate',
    sortByValue : item => {
      const { expirationDate } = item

      return expirationDate ? new Date(expirationDate).valueOf() : 0
    },
    format : item => formatDate(item.expirationDate, 'dd.mm.yyyy'),
    visibleOnStart : true, 
    userSorting : true, 
    width : 100 
  },      
  { 
    title : 'Дата добавления',
    alias : 'createDate',
    sortByValue : item => {
      const { createDate } = item

      return createDate ? new Date(createDate).valueOf() : 0
    },
    format : item => formatDate(item.createDate, 'dd.mm.yyyy'),
    visibleOnStart : true, 
    userSorting : true, 
    width : 100 
  },      
]

export default function KeyDocuments(props) {
  const { 
    keyCarrierId,
    keyDocuments,
    keyCarrier,
    afterActionCallback
  } = props
  const { status, bizLocked } = keyCarrier || {}
  const { id: statusId } = status || {}

  const {
    inProgress,
    sorting,
    selectedItems,
    selectedObjects,

    setSelectedAction,
    setSelectedObjects,
    sortListAction,
    onInProgress,
    syncFrontListSortAction,
  } = useTableExtensions({
    defaultSort: {
      column: 'createDate',
      direction: sortDirection.desc,
    },
  });

  const resetAfterAction = async () => {
    await afterActionCallback()
    setSelectedObjects([])
    setSelectedAction([])
  }

  const linkAction = async (requestData) => {
    onInProgress(true)
    const response = await service('keyCarrierService', 'keyCarrierRecord', { requestData })
    const { isWarn, isError } = response || {}
    if (!isWarn && !isError) {
      await afterActionCallback()
    }
    
    onInProgress(false)
    return response
  }  

  const breakLinkAction = async (requestData) => {
    onInProgress(true)
    const response = await service('keyCarrierService', 'keyCarrierRemove', { requestData })
    const { isWarn, isError } = response || {}
    if (!isWarn && !isError) {
      await resetAfterAction()
    }

    onInProgress(false)
    return response
  }

  const list = useMemo(() => {
    return keyDocuments || []
  }, [keyDocuments])

  useEffect( () => { 
    syncFrontListSortAction( sorting, fieldsConfig, list )
  }, [ sorting, list ]);

  const forbiddenStatus = statusId === eskziStatuses.withdrawn
                          || statusId === eskziStatuses.destroyed
  const linkButtonDisabled = forbiddenStatus || bizLocked
  const breakLinkDisabled = selectedObjects.length !== 1 || statusId === eskziStatuses.destroyed || bizLocked

  return (
    <>
      {inProgress ? <Overlay /> : null}
      <div className='keyCarrier'>
        <ManagePanel>
          <Group>
            <LinkButton
              isText
              keyCarrierId={keyCarrierId}
              disabled={linkButtonDisabled}
              actionCallback={linkAction}
              signType='KeyCarrierRecording'
              signHeader='Добавление ключевого документа'
            />
          </Group>
          <Group>
            <BreakLink
              keyCarrierId={keyCarrierId}
              actionCallback={breakLinkAction}
              disabled={breakLinkDisabled}
              signType='KeyCarrierRecording'
              signHeader='Удаление ключевого документа'
              selectedKeyDocuments={selectedObjects}
            />
          </Group>
        </ManagePanel>
        <Table
          totalCount={list.length}
          list = {list}
          // sortListAction = {sortListAction}
          sorting = {sorting} 
          selectedItems = {selectedItems} 
          selectedObjects = {selectedObjects}
          setSelectedAction = {setSelectedAction}
          setSelectedObjects = {setSelectedObjects}
          sortListAction = {sortListAction}
          onItemClick = { item => { 
            const {okz} = item || {}
            return `/keyDocument/${okz ? 'okz': 'oki'}/card/${ item.id }` 
          } }
          fieldsConfig = { fieldsConfig }
          itemsSelectable = { true }
        />
      </div>
    </>
  )
}