/** @format */
import { downloadFileFormHandler } from 'app/core/utility/file';
import Button, { buttonTypes } from '../ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import withTooltip from '../HOC/TooltipHOC';
import service from 'app/services/service';
import styles from './downloadFileButton.module.scss';
const TooltippedButton = withTooltip(Button);

export default function DownloadFileButton({
  disabled,
  tooltip,
  api,
  onError,
  onFileActionStart,
  onFileActionFinish,
  url,
  img,
  type,
  title
}) {
  const { name, method, payload } = api || {};

  const handleDownload = (e) => {
    e.preventDefault()
    onFileActionStart && onFileActionStart();
    downloadFileFormHandler(data => service(name, method, data), payload, onError, onFileActionFinish);
  };

  const tooltipText = tooltip ?? type === buttonTypes.image ? 'Скачать' : ''

  return url && !disabled ? (
    <a href={url} download className={styles.link}>
      <TooltippedButton
        type={type ?? buttonTypes.image}
        tooltip={tooltipText}
        onClick={null}
        disabled={disabled}
      >
        <Img img={img ?? Image.Download} />
        {title && <div className={styles.title}>{title}</div>}
      </TooltippedButton>
    </a>
  ) : (
    <TooltippedButton
      type={type ?? buttonTypes.image}
      tooltip={tooltipText}
      onClick={handleDownload}
      disabled={disabled}
    >
      <Img img={img ?? Image.Download} />
      {title && <div className={styles.title}>{title}</div>}
    </TooltippedButton>
  );
}
