import React, { Component } from 'react'
import { connect } from 'react-redux'

import withModal from 'app/components/HOC/ModalHOC'
import { SignatureModal } from './SignatureModal'
import Button from 'app/components/ui/button/button'
import {generateText, setSignatureResult} from 'redux/actions/common/signatureAction'
import DropDown from 'app/components/ui/DropDown'
import ConfirmDialog, { dialogType } from 'app/components/dialog/confirmDialog/'
import preloaderSmall from 'assets/img/common/preloader-small.gif'
import { formatDate } from 'app/core/utility/date'
import { getCertificateEntry } from 'app/core/utility/common.js'
import { decapitalize } from 'app/core/utility/common'
import { SignatureFormat } from './types';

const signatureFormatMapping = {
  Cades_Bes: SignatureFormat.CADES_BES,
  Cades_T: SignatureFormat.CADES_T,
  Cades_XLongType1: SignatureFormat.CADES_X_LONG_TYPE_1,
};

const defaultCertLocalStorageKey = 'defaultCert';

export const isDigitalSignNeeded = (profile, action) => {
  if (!action) {
    return false
  }
  const { signatureSettings } = profile
  const { apiSettings } = signatureSettings || {}

  if (!apiSettings) {
    return true
  }

  const signSetting = apiSettings.find(item => item.requestType === action)

  return signSetting ? signSetting.signatureRequired : true
}

export const getTechJournalDataForServer = (eskzi, data) => {
  const { id: eskziId } = eskzi
  const { actionDate, comments, name, performerId, performer, signatureRequestType, id } = data
  const request = {
    comments,
    name,
    performerId: (performerId && performerId.id) || (performer && performer.id),
    signatureRequestType,
    eskziId,
    actionDate: formatDate(actionDate, 'yyyy-mm-dd')
  }
  if(id) {
    request.id = id
  }
  return request
}

export const techJournalActionSingDialog = (actionData, props) => {
  const { eskzi, data, verb } = actionData
  const {
    setRequestData,
    generateText,
    inProgressCallback
  } = props
  const mappedData = getTechJournalDataForServer(eskzi, data)
  const signTextRequest = {
    ...mappedData,
    signatureRequestType: verb
  }

  setRequestData && setRequestData({ eskzi, data });
  inProgressCallback && inProgressCallback(true)
  generateText && generateText(signTextRequest);
}

export const techJournalAfterSign = (signature, props) => {
  const { inProgressCallback, techJournalRunCallback } = props
  const { textForSignature, signatureResult, requestData } = signature
  const { eskzi, data } = requestData

  const signatureToServer = {
    data: { ...getTechJournalDataForServer(eskzi, data) },
    signature: { data: textForSignature, signedData: signatureResult }
  }
  
  inProgressCallback(true);
  return techJournalRunCallback(signatureToServer)
}

export const getEventDataForServer = (action, eskzi, data) => {  // Должно частично уехать в экшен. Частично - этажом выше.
  const { id: actionId, model } = action
  const { HardwareNumber = '', ReceivedFrom = '', EskziUserId, OrganizationId, TransmittalLetterDate = null, TransmittalLetterNumber = '', PerformerId , HardwareId, PrintNumber = '', DistributiveRemovingDate = null } = data
  const { id: eskziId } = eskzi || {}
  const { id: userId = null } = EskziUserId || {}
  const { id: orgId = null } = OrganizationId || {}
  const { id: hardwareId = null} = HardwareId || {}
  const { id: performerId } = PerformerId || {}
  const temlateFieldName = eskzi ? 'eventTemplateId' : 'journalActionDataId'

  const result = { 
    eskziUserId: userId, 
    organizationId: orgId, 
    hardwareId: hardwareId,
    hardwareNumber: HardwareNumber, 
    receivedFrom: ReceivedFrom,
    performerId: performerId,
    transmittalLetterDate: formatDate(TransmittalLetterDate, 'yyyy-mm-dd'), 
    distributiveRemovingDate: formatDate(DistributiveRemovingDate, 'yyyy-mm-dd'),
    transmittalLetterNumber: TransmittalLetterNumber,
    [temlateFieldName]: actionId,
    eskziId: eskziId,
    printNumber: PrintNumber
  }

  return model.reduce((acc, cur) => {
    return {
        ...acc, 
        [decapitalize(cur.key)]: result[decapitalize(cur.key)]
      }
  }, eskzi 
      ? { eskziId: eskziId, [temlateFieldName]: actionId } 
      : { [temlateFieldName]: actionId }
  )
}

export const getEventDataForServerFunctional = (action, eskzi, data) => {  // Должно частично уехать в экшен. Частично - этажом выше.
  const { id: actionId, model } = action
  const { hardwareNumber = '', receivedFrom = '', eskziUser, organization, transmittalLetterDate = null, transmittalLetterNumber = '', performer , hardware, printNumber = '', distributiveRemovingDate = null, signers = [] } = data
  const { id: eskziId } = eskzi || {}
  const { id: eskziUserId = null } = eskziUser || {}
  const { id: organizationId = null } = organization || {}
  const { id: hardwareId = null} = hardware || {}
  const { id: performerId } = performer || {}
  const temlateFieldName = eskzi ? 'eventTemplateId' : 'journalActionDataId'

  const result = {
    eskziUserId,
    organizationId,
    hardwareId,
    hardwareNumber,
    receivedFrom,
    performerId,
    transmittalLetterDate: formatDate(transmittalLetterDate, 'yyyy-mm-dd'),
    distributiveRemovingDate: formatDate(distributiveRemovingDate, 'yyyy-mm-dd'),
    transmittalLetterNumber,
    [temlateFieldName]: actionId,
    eskziId,
    printNumber,
    signers: signers.map(item => item.id)
  }

  return model.reduce((acc, cur) => {
      return {
          ...acc,
          [decapitalize(cur.key)]: result[decapitalize(cur.key)]
        }
    }, eskzi
      ? { eskziId: eskziId, [temlateFieldName]: actionId }
      : { [temlateFieldName]: actionId }
  )
}

export const onOneClickActionSingDialog = (eventData, props) => {
  const {action, eskzi, data} = eventData
  const {
    setRequestData,
    generateText,
    inProgressCallback,
    suppressWarn
  } = props
  const mappedData = getEventDataForServer(action, eskzi, data)
  const signTextRequest = {
    ...mappedData,
    signatureRequestType: 'FinishOneClickAction',
    suppressWarn: suppressWarn
  }

  setRequestData && setRequestData({ action, eskzi, data });
  inProgressCallback && inProgressCallback(true)
  generateText && generateText(signTextRequest);
}

export const oneClickActionAfterSign = (signature, props) => {
  const { inProgressCallback, oneClickActionRunCallback, suppressWarn } = props
  const { textForSignature, signatureResult, requestData } = signature
  const { action, eskzi, data } = requestData

  const signatureToServer = {
    data: { ...getEventDataForServer(action, eskzi, data) },
    signature: { data: textForSignature, signedData: signatureResult },
    suppressWarn: suppressWarn
  }
  
  inProgressCallback(true);
  return oneClickActionRunCallback(signatureToServer)
}

export class SignatureDialog extends Component {

  constructor(props) {
    super(props)

    this.state = {
      activeProvider: '',
      activeCert: '',
      defaultCert: null,
    }
  }
  
  componentDidMount() {
    this.setState({ defaultCert: JSON.parse(localStorage.getItem(defaultCertLocalStorageKey)) })
  }

  _onProviderSelect = (signatureProps, item) => {
    const { onChangeProvider } = signatureProps;
    const { value } = item || {};

    this.setState({ activeProvider: item }, () => {
      onChangeProvider && onChangeProvider({currentTarget: {value: value}})
    })
  }

  _onSigned = (data) => new Promise(() =>{
    // пока будут классовые компоненты, стейт подписи надо будет выставлять 
    // в глобальном сторе (setSignatureResult)
    const { setSignResult, setSignatureResult, afterSignCallback } = this.props;

    // если пришло в пропсах выставление стейта компонента (setSignResult),
    // то в глобальном сторе стейт менять не надо
    !setSignResult && setSignatureResult(data.signature.value);
    setSignResult && setSignResult(data.signature.value)

    afterSignCallback && afterSignCallback( data.signature );
  })

  _onCertSelect = (signatureProps, item, initial = false) => {
    const { onChangeCryptoProCert } = signatureProps;
    const { value } = item || {};

    this.setState({ activeCert: item }, () => {
      onChangeCryptoProCert && onChangeCryptoProCert({currentTarget: {value: value}})
    })
    
    if (!initial) {
      localStorage.setItem(defaultCertLocalStorageKey, JSON.stringify(item));
      this.setState({ defaultCert: item });
    }
  }

  _renderSignRequest = () => {
    return (
      <ConfirmDialog
        needCloseIcon = {false}
        type={dialogType.message}
        title={
          <div className='sign-plugin-request-data'>
            Получение данных криптопровайдера...
            <img className='loading-image' src={preloaderSmall}></img>
          </div>
        }
      />
    )
  }
  
  _renderSignFail = (signErrorMessage) => {
    const { onCancel } = this.props
    const message = signErrorMessage || 'Ошибка в работе криптопровайдера. Проверьте правильность установки плагина электронной подписи'

    return (
      <ConfirmDialog
        type={dialogType.warning}
        title={message}
        onCancel={onCancel}
      />
    )
  }

  _renderContent = (signatureProps) => {
    const { profile } = this.props
    const { restrictCertificatesBySnils, currentUser } = profile
    const { snils } = currentUser
    const { providers, signatureData, currentProvider } = signatureProps
    const provItems = Object.keys(providers).filter(item => item !== 'JINN_CLIENT').map(key => {
      let title = ''      

      switch (key) {
        case 'CRYPTO_PRO':
          title = 'КриптоПро'
          break;
        case 'JINN_CLIENT':
          title = 'JINN_CLIENT'
          break;
      }

      return {
        title: title,
        value: key,
        disabled: !providers[key],
        certList: providers[key] 
                  ? providers[key].certsList.map(item => ({
                                                  ...item,
                                                  title: item.name,
                                                  value: item.hash
                                                }))
                  : []
      }
    })

    const currentProv = provItems.find(item => item.value === currentProvider) || {}
    const { certList } = currentProv
    let currentCert = {}

    if (certList) {
      currentCert = Object.keys(providers).reduce((acc, val) => {
        return currentProvider === val
                ? certList.find(cert => cert.value === providers[val].currentCert)  
                : acc
      }, {})
    }

    const filteredCerts = restrictCertificatesBySnils 
                          ? (certList || []).filter(cert => {
                              const certSnils = getCertificateEntry(cert.subject, 'СНИЛС=')
                              
                              return snils ? snils === certSnils : false
                            })
                          : (certList || [])
    const filteredCertsWithInfo = filteredCerts.map(cert => {
        const { from, to, issuer } = cert || {};
        const certDate = from && to ? `${formatDate(from, 'dd.mm.yyyy')}—${formatDate(to, 'dd.mm.yyyy')}` : ' '
        const certIssuerPairsArr = issuer ? issuer.split(', ').map(str => str.split('=')) : []
        const certIssuerObj = Object.fromEntries(certIssuerPairsArr)
        const { CN } = certIssuerObj || {}
        return {...cert, title: `${cert.title} (${CN}, ${certDate})`}
      })

    return (
      <div>
        <div className={`card-line`}>
          <div className={`card-title`}>Подписать с использованием:</div>
          <div className={`card-value`}>
            <DropDown
              items = {provItems}
              active = {currentProv}
              onSelect= {value => this._onProviderSelect(signatureProps, value)}
              placeHolder={'Выберите провайдера'}
            />
          </div>
        </div>

        {signatureProps.currentProvider === 'CRYPTO_PRO' && signatureProps.providers.CRYPTO_PRO && signatureProps.signatureData && (
          <>
            <div className={`card-line`}>
              <div className={`card-title`}>Доступные сертификаты:</div>
              <div className={`card-value`}>
                <DropDown
                  items = {filteredCertsWithInfo}
                  active = {currentCert}
                  onSelect= {value => this._onCertSelect(signatureProps, value)}
                  placeholder={'Выберите сертификат'}
                />
              </div>
            </div>
            <div className={`card-line card-line--unset`}>
              <div className={`card-title card-title--top`}>Подписываемые данные:</div>
              <div className={`card-value card-value--top sign-data`}>
                {signatureData}
              </div>
            </div>
          </>
        )}
      </div>
    )
  }

  _renderSignError = (signatureProps) => {
    return signatureProps && signatureProps.signErrorMessage ? (
    <div className="error-label">{signatureProps.signErrorMessage}</div>
    ) : null
  }

  _renderDialog = 
     (isRenderProvidersRequest,
      isRenderProvidersFailure,
      isRenderSignatureForm,
      isRenderSubmitRequest,
      isRenderSubmitSuccess,
      isRenderSubmitFailure,
      isNextButtonEnabled,
      signatureProps,
      onNext) => {
      const { onCancel } = this.props
      const { defaultCert, activeCert } = this.state;
      
      if (isRenderSignatureForm && defaultCert && !activeCert) {
        this._onCertSelect(signatureProps, defaultCert, true);
      }

      if (isRenderSubmitFailure  && signatureProps) {
        return this._renderSignFail()
      }

      if (isRenderProvidersFailure && signatureProps) {
        return this._renderSignFail()
      }

      if (isRenderProvidersRequest && signatureProps) {
        return this._renderSignRequest()
      }

      let nextDisabled = !isNextButtonEnabled
      // if we want select another certificate after invalid signature server error
      if (isRenderSubmitRequest) {
        nextDisabled = false
      }

      return (
        <>
          {this._renderSignError(signatureProps)}
          <div className="card card--no-view-mode card__content sign-content">
            {isRenderSignatureForm && signatureProps && this._renderContent(signatureProps)}
            {/* think about what we should show after sign is complete and sign posted to server*/}
            {isRenderSubmitRequest && signatureProps && this._renderContent(signatureProps)}
            {isRenderSubmitSuccess}
            <div className="card__management">
              <Button disabled={nextDisabled}
                className='management__item'
                type='primary'
                onClick={onNext}
              >
                <span className='button-title'>Подписать</span>
              </Button>
              <Button
                className='management__item'
                type='secondary'
                onClick = {onCancel}
              >
                <span className='button-title'>Отменить</span>
              </Button>
            </div>
          </div>
        </>
      )
        
  }

  render() {
    const { signature, profile } = this.props
    const { signatureSettings } = profile
    const { type } = signatureSettings
    const signatureFormat = signatureFormatMapping[type] || SignatureFormat.CADES_BES;
    return (
      <SignatureModal 
      service={this._onSigned} 
      onSuccess={() => {}} 
      dataToSign={signature.textForSignature}
      signatureFormat={signatureFormat}>
        {(
          isRenderProvidersRequest,
          isRenderProvidersFailure,
          isRenderSignatureForm,
          isRenderSubmitRequest,
          isRenderSubmitSuccess,
          isRenderSubmitFailure,
          isNextButtonEnabled,
          signatureProps,
          onNext
        ) => {
          return this._renderDialog(
            isRenderProvidersRequest,
            isRenderProvidersFailure,
            isRenderSignatureForm,
            isRenderSubmitRequest,
            isRenderSubmitSuccess,
            isRenderSubmitFailure,
            isNextButtonEnabled,
            signatureProps,
            onNext)
          }}
      </SignatureModal>
    )
  }
}

const mapStateToProps = (state) => ({ ...state })

const component = connect(
  mapStateToProps,
  { 
    setSignatureResult,
    generateText,
  })(SignatureDialog)

export default withModal(component)