import React from "react"
import {capitalize, decapitalize} from "../../../../core/utility/common"
import EventFieldComponent from "./EventsFieldComponent"
import SignatureInfoFields from "./SignatureInfoFields"
import { CardState } from 'app/components/ui/card/cardMaterial'

export default function EventForm(props){
  const {
    entityType,
    cardState,
    formData = {},
    model = [],
    onChange,
    errorByFields = {},
    signatures,
    eventId,
    eskziId
  } = props;

  function editFieldName(fieldName) {
    return fieldName.substring(fieldName.length - 2) === 'Id'
      ? fieldName.substring(0, fieldName.length - 2)
      : fieldName
  }

  function getFormDataValue(fieldName){
    return formData[editFieldName(fieldName)]
  }

  function getError(fieldName){
    return errorByFields[capitalize(editFieldName(fieldName))] || errorByFields[capitalize(fieldName)]
  }

  return (
    <>
      {model.map((el, index) => {
        const { key, title, readOnly } = el
        const type = decapitalize(key)
        return (
          <EventFieldComponent
            id={`${key}-${eventId}-${index}`}
            eventId={eventId}
            key={key}
            value={getFormDataValue(type)}
            onChange={value => onChange(editFieldName(type), value)}
            error={getError(type)}
            type={type}
            entityType={entityType}
            title={title}
            readOnly={cardState === CardState.view || readOnly}
            viewCardState={cardState === CardState.view}
            organizationType={el.organizationType}
            objectIds={[eskziId]}
            formData={formData}
          />
        )
      })}
      {
        signatures && signatures.length > 0 &&
        <SignatureInfoFields
          eventId={eventId}
          signatures={signatures}
        />
      }
    </>
  )
}