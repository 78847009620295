import React from 'react'
import Card from 'app/components/ui/card/card'
import withModal from 'app/components/HOC/ModalHOC'
import {MaintenanceCardLines} from "./MaintenanceCardLines";
import useEditControl from "./hooks/useEditControl";
import ActionButtonImage from "app/components/list/TopPanel/ActionButtonImage";
import withTooltip from "app/components/HOC/TooltipHOC";
import { ReactComponent as EditImg } from 'assets/img/commonVer2/edit_pen.svg'

const ModalCard = withModal(Card)
const ButtonWithTooltip = withTooltip( ActionButtonImage );

export default function EditControl({disabled = false,
                                      inProgress,
                                      title,
                                      item,
                                      onSubmitSignEnhance,
                                      generateDataForRequest,
                                      cardFormLines= MaintenanceCardLines},
                                      skzi){

  const {
    rights,
    editFormData,
    fieldsError,
    editActionDialog,
    editDefaultFormData,
    onEditClick,
    onCancel,
    getActionKey,
    onValidation,
    onEditActionFormChange,
  } = useEditControl({item})

  return (
    <>
      <div  className="manage-panel__item">
        <ButtonWithTooltip img={'EditImg'} action={onEditClick} disabled={disabled || inProgress || !rights.UPDATE || !item}  tooltip={title} />
      </div>
      {editActionDialog ?
        <ModalCard
          className="technicalJournalReport__edit-event__data-card"
          formLines={cardFormLines({skzi: skzi})}
          data={editDefaultFormData}
          formData={editFormData}
          errorByFields={fieldsError}
          onValidationError={onValidation}
          onChange={onEditActionFormChange}
          onSubmit={() => onSubmitSignEnhance(generateDataForRequest())}
          renderHeader = {() => {
            return (
              <div className='modal__header'>
                Внести изменение
              </div>
            )
          }}
          onCancel={onCancel}
          editOnly={true}
          getObjectKeyFunc={getActionKey}
        /> : null
      }
    </>
  )
}
