import React, { Component, Fragment, version } from 'react'
import { connect } from 'react-redux'

import { sortDirection } from 'app/components/ui/constants'
import { ReactComponent as SkziRegistryImg }  from 'assets/img/menu/ic_registry.svg';
import { downloadFileByLink } from 'app/core/serverRequest/request' 
import SignatureDialog, { isDigitalSignNeeded } from 'app/components/signature/SignatureDialog'
import Autocomplete, { Services }  from 'app/components/ui/autocomplete/autocomplete'
import Overlay from 'app/components/ui/overlay'
import SkziVersionList from './skziVersionLIst'
import ConfirmDialog, {dialogType} from 'app/components/dialog/confirmDialog/'
import Pagination from 'app/components/ui/pagination/pagination'
import service from 'app/services/service'
// import DropDown from 'app/components/ui/dropDown'
import { formatDate } from 'app/core/utility/date'
import config from 'app/core/config'

import * as selfServiceRegistryAction from 'redux/actions/selfServiceRegistry/selfServiceRegistryAction'
import * as errorAction from 'redux/actions/common'
import * as signatureActions from 'redux/actions/common/signatureAction'

const versionStatuses = [
  { id: 'All', title: 'Все'},
  { id: 'AdmittanceExists', title: 'Есть допуск'},
]

class component extends Component {
  constructor(props){
    super(props)

    this.pagination = React.createRef();
    this.state = this._getState(props)
  }

  _getState = props => {
    const { paginationPerPage } = config
 
    return {
      filter: { 
        type: null, 
        producer: null, 
        status: versionStatuses[0]
      }, 
      pagination: { 
        itemsPerPage: paginationPerPage,
        activePage: 1
      },
      sort: { 
        column: 'name',
        direction: sortDirection.asc
      },
    }
  }

  componentDidMount () {
    const { selfServiceRegistry, location } = this.props
    const { inProgress } = selfServiceRegistry

    if (inProgress) {
      return
    }

    this._getPage({
      filterData: {
        ...this._getFilterStateForBackend(),
      }      
    })
  }

  _getFilterStateForBackend = () => {
    const { selfServiceRegistry } = this.props
    const { pagination } = this.state
    const { filter } = selfServiceRegistry
    const { type, producer, status } = filter
    const { id: producerId } = producer || {}
    const { id: typeId } = type || {}
    const { id: statusId } = status || {}
    
    return {
      skzi: typeId,
      producer: producerId,
      state: statusId,
      
    }
  }

  _onSearch = () => {
    this._getPage({ filterData: this._getFilterStateForBackend()})
  }

  _getPage = ({ filterData, column = 'name', direction = sortDirection.asc }) => {
    const { sort, pagination } = this.state
    const { activePage, itemsPerPage } = pagination
    const { selfServiceRegistryGetAction, selfServiceRegistryInProgressAction, location } = this.props
    const { pathname } = location
    selfServiceRegistryInProgressAction(true)
    selfServiceRegistryGetAction({ filterData, column, direction, page: activePage, pageSize: itemsPerPage }, pathname)
    activePage === 1 && this.pagination.current.resetActivePage()
  }

  _onCancel = () => {
    const { selfServiceRegistryResetAction } = this.props

    selfServiceRegistryResetAction()
  }

  _onPageChanged = ({ activePage, itemsPerPage }) => {
    this.setState({pagination: { activePage, itemsPerPage }}, () => {
      this._onSearch({pagination: { activePage, itemsPerPage }})
    })
  }

  _onFilterValueChanged = async ({key, value}) => {
    const { selfServiceRegistrySetFilterAction } = this.props
    const { pagination } = this.state
    this.setState({pagination: {...pagination, activePage: 1}})

    await selfServiceRegistrySetFilterAction({key, value}) 
    this._onSearch()
  }

  _onBuildsSort = (data) => {
    const { 
      selfServiceRegistryInProgressAction,
      selfServiceRegistryBuildsSortAction
    } = this.props

    // selfServiceRegistryInProgressAction(true)
    // selfServiceRegistryBuildsSortAction(data)
  }

  _onCoursesSort = (data) => {
    const { 
      selfServiceRegistryInProgressAction,
      selfServiceRegistryCoursesSortAction
    } = this.props

    // selfServiceRegistryInProgressAction(true)
    selfServiceRegistryCoursesSortAction(data)
  }

  _onCourseAssign = () => {
    const { 
      selfServiceRegistry, 
      selfServiceRegistryCourseAssignAction,
      selfServiceRegistryInProgressAction
    } = this.props
    const { assignCourseDialog } = selfServiceRegistry

    selfServiceRegistryInProgressAction(true)
    selfServiceRegistryCourseAssignAction(assignCourseDialog)
  }

  _onCourseAssignDialog = (versionId) => {
    const { 
      selfServiceRegistryOnCourseAssignAction
    } = this.props

    selfServiceRegistryOnCourseAssignAction(versionId)
  }

  _onExamRequest = versionId => {
    const { 
      selfServiceRegistryInProgressAction,
      selfServiceRegistryOnExamRequestAction
    } = this.props

    selfServiceRegistryInProgressAction(true)
    selfServiceRegistryOnExamRequestAction(versionId)
  }

  _getInstallSkziData = () => {
    const { selfServiceRegistry } = this.props
    const { getBuildDialog } = selfServiceRegistry
    const { skziBuildId, workstationNumber, printNumber } = getBuildDialog || {}
    
    return { 
      skziBuildId: skziBuildId,
      workstationNumber: workstationNumber,
      printNumber: printNumber,
      issueDate: formatDate(new Date(), 'yyyy-mm-dd')
    }
  }

  _getSignatureRequestData = () => {
    const { selfServiceRegistry } = this.props
    const { currentConclusion } = selfServiceRegistry
    const { id, issueDate } = currentConclusion || {}

    return { 
      eskziConclusionId: id, 
      actionDate: formatDate(issueDate, 'yyyy-mm-dd')
    }
  }

   _onGetBuildClick = async ({ buildId: skziBuildId, versionId } ) => {
    const { selfServiceRegistryGetBuildSetDataAction } = this.props

    await selfServiceRegistryGetBuildSetDataAction(skziBuildId ? {skziBuildId, versionId} : null)
   }
   
  _onGetBuildCancel = async () => {
    const { selfServiceRegistryGetBuildSetDataAction } = this.props

    await selfServiceRegistryGetBuildSetDataAction(null)
  }

  _onGetBuildSubmit = async (formData) => {
    const { selfServiceRegistry, selfServiceRegistryGetBuildSetDataAction } = this.props
    const { getBuildDialog } = selfServiceRegistry

    await selfServiceRegistryGetBuildSetDataAction({...getBuildDialog, ...formData})
    this._onCreateConclusion()
  }

  _onCreateConclusion = async () => {
    const { 
      selfServiceRegistryCreateConclusionAction,
      selfServiceRegistryInProgressAction
    } = this.props

    selfServiceRegistryInProgressAction(true)
    await selfServiceRegistryCreateConclusionAction(
            this._getInstallSkziData()
          )
    await this._onGetBuild()                    
  }

  _onGetBuild = async () => {
    const { 
      selfServiceRegistry, 
      profile,
      selfServiceRegistryInProgressAction,
    } = this.props
    const { currentConclusion } = selfServiceRegistry
    const { id, eskzi } = currentConclusion || {}

    // some error happened
    if (!id) {
      selfServiceRegistryInProgressAction(false)
      return
    }

    // already has conclusion
    if (eskzi) {
      this._finishDownload({payload: {skzi: eskzi}}, false)
      return
    }

    if (isDigitalSignNeeded(profile, 'InstallEskzi')) {
      this._onSignDialog(currentConclusion)
    } else {
      this._registerWithoutSign(currentConclusion)
    }
  }

  _onSignDialog = (data) => {
    const { 
      setRequestData, 
      generateText, 
      selfServiceRegistryInProgressAction
    } = this.props

    const signTextRequest = {
      ...this._getSignatureRequestData(data),
      signatureRequestType: 'InstallEskzi'
    }
  
    setRequestData({...data});
    selfServiceRegistryInProgressAction(true)
    generateText(signTextRequest);
  }
  
  _afterSign = async () => {
    const { 
      selfServiceRegistry,
      signature,
      selfServiceRegistryInProgressAction,
      selfServiceRegistrySkziInstallAction
    } = this.props
    const { getBuildDialog } = selfServiceRegistry
    const { textForSignature, signatureResult } = signature
    
    const signatureToServer = {
      data: this._getSignatureRequestData(getBuildDialog),
      signature: { data: textForSignature, signedData: signatureResult }
    }

    selfServiceRegistryInProgressAction(true)
    const createResult = await selfServiceRegistrySkziInstallAction(signatureToServer)
    this._finishDownload(createResult)
  }

  _finishDownload = async (eskziData, needBuildUpdate = true) => {
    const { selfServiceRegistryResetAction } = this.props
    const { payload } = eskziData || {}
    const { skzi: skziData } = payload || {}
    const { build, skzi, version } = skziData || {}
    const { id: buildId } = build || {}
    const { id: skziId } = skzi || {}
    const { id: versionId } = version || {}

    downloadFileByLink(`skzi/${skziId}/version/${versionId}/build/${buildId}/file/all`)
    await selfServiceRegistryResetAction()

    needBuildUpdate && this._onBuildsSort({skziId, versionId })
  }

  _registerWithoutSign = async (data) => {
    const { 
      selfServiceRegistryInProgressAction,
      selfServiceRegistrySkziInstallAction,
      selfServiceRegistry
    } = this.props
    const { getBuildDialog } = selfServiceRegistry

    const requestData = {
      data: this._getSignatureRequestData(getBuildDialog),
      signature: null
    }

    selfServiceRegistryInProgressAction(true)
    const createResult = await selfServiceRegistrySkziInstallAction(requestData)
    this._finishDownload(createResult)
  }

  _renderModal = () => {
    const { 
      selfServiceRegistry, 
    } = this.props

    const { 
      confirmObject, 
      assignCourseDialog, 
      examResultDialog,
      assignCouseResultDialog
    } = selfServiceRegistry

    return (
      <Fragment>
        {assignCourseDialog ? 
          <ConfirmDialog
            header='Получение допуска'
            type={dialogType.confirm}
            needIcon={false}
            messages={[
              'Для допуска к работе с СКЗИ необходимо самостоятельно изучить обучающие курсы и сдать экзамен сотруднику ОКЗ.',
              'Вы уверены, что хотите начать обучение?'
            ]}
            submitText='Назначить обучение'
            onSubmit={this._onCourseAssign}
            onCancel={this._onCancel}
          /> : null
        }
        {examResultDialog ? 
          <ConfirmDialog
            header='Запрос отправлен'
            type={dialogType.success}
            needIcon={false}
            messages={[
              'Ваше обращение отправлено сотруднику ОКЗ. Вам назначат дату и сообщат о месте проведения экзамена.',
              'Если информация об экзамене не поступит в течение нескольких дней, пожалуйста, свяжитесь с сотрудником ОКЗ для уточнения деталей.'
            ]}
            onCancel={this._onCancel}
          /> : null
        }
        {assignCouseResultDialog ? 
          <ConfirmDialog
            header='Обучение назначено'
            type={dialogType.success}
            needIcon={false}
            messages={[
              'Пожалуйста, изучите Обучающие курсы приложенные к версии СКЗИ.',
              'Когда будете уверены, что хорошо усвоили материал, обратитесь к сотруднику ОКЗ для сдачи экзамена.'
            ]}
            onCancel={this._onCancel}
          /> : null
        }
        {confirmObject ? 
          <ConfirmDialog
            {...confirmObject}
            onSubmit={this._onDelete}
            onCancel={this._onCancel}
          /> : null
        }
      </Fragment>
    )
  }

  _renderManagementPanel = () => {
    const { selfServiceRegistry, selfServiceRegistrySetFilterAction } = this.props
    const { inProgress, filter  } = selfServiceRegistry
    const { type, producer, status } = filter

    const autocompleteService = {
      serviceName: 'skziService', 
      serviceMethod: 'onlyProgramAutocomplete', 
      data: {
        producer
      }
    }

    return (
      <div className="selfServiceRegistry__manage-panel manage-panel">
        <div className="selfServiceRegistry__filter">
          <Autocomplete 
            className='filter-item'
            label = 'Производитель СКЗИ'
            onEmptySearch = {true} 
            serviceType={ Services.producers } 
            onSelect={value => this._onFilterValueChanged({key: 'producer', value: value})}
            value={producer}
            disabled={inProgress}
            noHint
          />
          <Autocomplete 
            className='filter-item'
            label = 'Продукт'
            onEmptySearch = {true} 
            serviceType={Services.skzi} 
            notStandardService={autocompleteService}
            onSelect={value => this._onFilterValueChanged({key: 'type', value: value})}
            value={type}
            noHint
          />
          {/* <DropDown
            items={versionStatuses}
            active={status}
            onSelect={value => this._onFilterValueChanged({key: 'status', value: value})}
            label='Состояние'
          /> */}
        </div>
      </div>
    )
  }

  render() {
    const {
      selfServiceRegistryInProgressAction, 
      selfServiceRegistry,
      profile
     } = this.props
    const { items, inProgress, pagination: { itemsPerPage, dataCount } } = selfServiceRegistry

    return (
      <section className='selfServiceRegistry'>
        {inProgress ? <Overlay /> : null}
        {this._renderModal()}
        <div className="selfServiceRegistry__header">
          <SkziRegistryImg className='header-image'></SkziRegistryImg>
          Реестр СКЗИ : Самообслуживание
        </div>
        {this._renderManagementPanel()}
        <SkziVersionList
          profile={profile}
          items={items}
          onGetBuildClick={this._onGetBuildClick}
          onCourceAssign={this._onCourseAssignDialog}
          onExamRequest={this._onExamRequest}
          inProgressAction={selfServiceRegistryInProgressAction}
        />
        <Pagination
          ref={this.pagination}
          itemsCount={ dataCount }
          itemsPerPage={ itemsPerPage }
          onPageChange={ this._onPageChanged }
        />
      </section>
    )
  }
}

const mapStateToProps = state => { 
  
  return { ...state }
}

export default connect(
  mapStateToProps,
  { 
    ...selfServiceRegistryAction,
    ...errorAction,
    ...signatureActions
  })(component)