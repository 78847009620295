import React from 'react'

import Input from 'app/components/ui/Input'
import TextArea from 'app/components/ui/textArea'
import DatePicker from 'app/components/ui/date'
import { formatDate } from 'app/core/utility/date'

import { notEmpty } from 'app/core/utility/validation'
import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import service from 'app/services/service'
import { Services, SelectType } from 'app/components/ui/picker/picker'
import AutocompletePicker from 'app/components/ui/picker/autocompletePicker/autocompletePicker'
import { CardState } from 'app/components/ui/card/cardMaterial'

export const cardLines = {
      id: {
        validation: value => {
          return true
        },
        renderEdit: props => {
          const { cardState, editValue, onChange, error } = props
    
          return (
            <Input 
              className='card-field user-account-card__id'
              label = 'Лицевой счет №'
              value={editValue}
              readOnly={cardState === CardState.view}
              disabled={cardState === CardState.edit || cardState === CardState.create}
            />
          )
        },
      },
      status: {
        validation: value => {
          return true
        },
        renderEdit: props => {
          const { cardState, editValue, onChange, error } = props
    
          return (
            <Input 
              className='card-field user-account-card__status'
              label = 'Статус'
              value={editValue}
              readOnly={cardState === CardState.view}
              disabled={cardState === CardState.edit || cardState === CardState.create}
            />
          )
        },
      },
      lastName: {
        title: () => 'Фамилия',
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
        renderValue: (props) => {
          const { value } = props 
    
          return value
        },
        renderEdit: props => {
          const { cardState, editValue, onChange, error } = props
    
          return (
            <Input 
              className='card-field user-account-card__last-name'
              label='Фамилия'
              placeholder = 'Фамилия'
              value={editValue}
              onChange={value => onChange && onChange('lastName', value)}
              error={error}
              readOnly={cardState === CardState.view}
            />
          )
        },
      },
      firstName: {
        title: () => 'Имя',
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
        renderValue: (props) => {
          const { value } = props 
    
          return value
        },
        renderEdit: props => {
          const { cardState, editValue, onChange, error } = props
    
          return (
            <Input 
              className='card-field user-account-card__name'
              label='Имя'
              placeholder = 'Имя'
              value={editValue}
              onChange={value => onChange && onChange('firstName', value)}
              error={error}
              readOnly={cardState === CardState.view}
            />
          )
        },
      },
      middleName: {
        title: () => 'Отчество',
        isRequired: () => false,
        renderValue: (props) => {
          const { value } = props 
    
          return value
        },
        renderEdit: props => {
          const { cardState, editValue, onChange, error } = props
    
          return (
            <Input 
              className='card-field user-account-card__middle-name'
              label='Отчество'
              placeholder = 'Отчество'
              value={editValue}
              onChange={value => onChange && onChange('middleName', value)}
              error={error}
              readOnly={cardState === CardState.view}
            />
          )
        },
      },
      snils: {
        title: () => 'СНИЛС',
        isRequired: () => false,
        validation: value => {
          return true
        },
        renderValue: (props) => {
          const { value } = props 
    
          return value
        },
        renderEdit: props => {
          const { cardState, editValue, onChange, error } = props
    
          return (
            <Input 
              className='card-field user-account-card__snils'
              label='СНИЛС'
              pattern='^[0-9]{0,11}$'
              placeholder = 'СНИЛС'
              value={editValue}
              onChange={value => onChange && onChange('snils', value)}
              error={error}
              readOnly={cardState === CardState.view}
            />
          )
        },
      },
      organization: {
        title: () => {return 'Организация'},
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
        renderValue: props => {
          const { profile, onOrganizationNavigate, value = {} } = props
          const { name, shortName } = value
          const orgRights = getResourceRights(profile, RESOURCES.organization)
    
          return ( 
            <>
              {orgRights.VIEW_CARD ? (<div className='organization-link' onClick={() => {onOrganizationNavigate && onOrganizationNavigate(value)}}>{shortName || name}</div>) : (
                shortName || name
              ) }
            </>
          )
        },
        renderEdit: props => {
          const { cardState, editValue, onChange, error } = props
          const autompleteService = {
            serviceName: 'organizationService',
            serviceMethod: 'getServedByUserAutocomplete',
            data: {}
          }

          const pickerService = {
            serviceName: 'organizationService',
            serviceMethod: 'getServedByUserPicker',
            data: {}
          }

          return (
              <AutocompletePicker
                className='card-field user-account-card__organization'
                needParentClass={true}
                label='Организация'
                serviceType={Services.organizations}
                selectType={SelectType.single}
                notStandardService={autompleteService}
                notStandardPickerService={pickerService}
                value={editValue}
                onSelectValue={value => onChange && onChange('organization', value)} 
                error={error}
                readOnly={cardState === CardState.view}
              />
            )
          },
      },
      position: {
        title: () => 'Должность',
        isRequired: () => false,
        renderValue: (props) => {
          const { value } = props 
    
          return value
        },
        renderEdit: props => {
          const { cardState, editValue, onChange, error } = props
    
          return (
            <Input 
              className='card-field user-account-card__position'
              label='Должность'
              pattern='^.{0,100}$'
              placeholder = 'Должность'
              value={editValue}
              onChange={value => onChange && onChange('position', value)}
              error={error}
              readOnly={cardState === CardState.view}
            />
          )
        },
      },
      accountCreateDate: {
        title: () => {return 'Дата открытия'},
        renderValue: props => {
          const { value } = props
          const dateString = formatDate(value, 'dd.mm.yyyy')
    
          return dateString
        },
        renderEdit: props => {
          const { cardState, editValue, onChange, error } = props
          const val = editValue ? new Date(editValue) : null
    
          return (
            <DatePicker 
              className='card-field user-account-card__create-date'
              label='Дата открытия'
              error={error}
              value={val} 
              onChange= {value => onChange && onChange('accountCreateDate', value)}
              readOnly={cardState === CardState.view}
            />
          )
        },
      },
      accountCloseDate: {
        title: () => {return 'Дата закрытия'},
        renderValue: props => {
          const { value } = props
          const dateString = formatDate(value, 'dd.mm.yyyy')
    
          return dateString
        },
        renderEdit: props => {
          const { cardState, editValue, onChange, error } = props
          const val = editValue ? new Date(editValue) : null
    
          return (
            <DatePicker 
              className='card-field user-account-card__close-date'
              label='Дата закрытия'
              error={error}
              value={val} 
              onChange= {value => onChange && onChange('accountCloseDate', value)}
              readOnly={cardState === CardState.view}
              disabled={cardState === CardState.edit || cardState === CardState.create}
            />
          )
        },
      },
      updateDate: {
        validation: value => {
          return true
        },
        renderEdit: props => {
          const { cardState, editValue, onChange, error } = props
    
          return (
            <DatePicker
              className='card-field user-account-card__change-date'
              label = 'Дата изменения'
              error={error}
              value={editValue}
              readOnly={cardState === CardState.view}
              disabled={cardState === CardState.edit || cardState === CardState.create}
            />
          )
        },
      },
      comments: {
        title: () => {return 'Примечание'},
        isRequired: () => false,
        isDescription: true,
        renderValue: (props) => {
          const { value } = props 
    
          return value
        },
        renderEdit: (props) => {
          const { cardState, editValue, onChange, error } = props
    
          return (
            <TextArea 
              className='card-field user-account-card__comments'
              label='Примечание'
              value={editValue} 
              error={error}
              onChange={value => onChange && onChange('comments', value)}
              readOnly={cardState === CardState.view}
            />
          )
        },
  }
}