import React, { useState } from 'react'
import { connect } from 'react-redux'


import MainCard, { CardButtons, objectKey } from 'app/components/mainCard/cardComponent'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Tabs from 'app/components/ui/tabs/tabs'
import History from 'app/components/history'
import { getResourceRights } from 'app/core/auth/auth'
import Overlay from 'app/components/ui/overlay'

import { cardLines } from './userAccountData'
import { RESOURCES } from 'app/core/auth/resourceByPage'

import * as mainCardAction from 'redux/actions/card/cardActions'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import * as errorActions from 'redux/actions/common';

import SkziInstancesTab from './SkziInstancesTab';
import KeyDocumentsTab from './KeyDocumentsTab';
import KeyCarriersTab from './KeyCarriersTab';
import UserSkziAccess from './userSkziAccess/userSkziAccess';
import ManagePanel from "app/components/ui/managePanel/managePanel";
import { BusinessEventPanel } from "./businessEventPanel/ListActionPanel";
import { useHistory, useParams } from 'react-router-dom'
import { formatDate } from 'app/core/utility/date'

export const getUserAccountKey =  (userAccountData) => {
  const {
    lastName = '',
    firstName = '',
    middleName = '',
    snils = '',
    accountCreateDate = '',
    organization = '',
    comments = '',
    position = '',
    id = '',
    status = '',
  } = userAccountData || {};
  const { id: orgId = '' } = organization || {};

  return {
    lastName: lastName,
    firstName: firstName,
    middleName: middleName,
    snils: snils,
    organizationId: orgId,
    position: position,
    accountCreateDate: formatDate(accountCreateDate, 'yyyy-mm-dd'),
    comments: comments,
    id: id,
    status: status,
  }
}

export const cardConfig = ({ 
    history,
    updateUserAccount,
    initUserAccountCard,
    breadcrumbsPushAction,
    userId
  }) => ({
  cardLines: cardLines,
  resource: RESOURCES.userAccount,
  getKey: data => objectKey(data, getUserAccountKey),
  signatureHeaderText: 'Изменение лицевого счёта',
  signatureServiceName: '',
  oldSignatureService: true,
  updateAction: updateUserAccount,
  cardInitAction: initUserAccountCard,
  getSignatureData: getUserAccountKey,
  pageBreadcrumbTitle: (action) => {
    const { location } = history || {}
    const { pathname } = location || {}

    if( !pathname ) {
      return;
    }
    
    breadcrumbsPushAction(history.location.pathname, `Лицевой счет №${userId}`)
  }
})

const UserAccountCard = (props) => {
  const {
    updateUserAccount,
    initUserAccountCard,
    breadcrumbsPopAction,
    breadcrumbsPushAction,
    cardInProgressAction,
    cardInProgress,
    viewData,
    profile = {},
    errorAction
  } = props
  
  const history = useHistory();
  const params = useParams();
  const { userId } = params
  const { organization, status } = viewData || {}
  const { id: orgId} = organization || {}
  const [ activeTab, setActiveTab ] = useState('Информация')
  const actionRights = getResourceRights(profile, RESOURCES.userAccount)
  const issueDisabled = status === "Закрыт"

  return (
    <section className='main-card user-account-card'>
      {cardInProgress ? <Overlay /> : null}
      <BreadcrumbsHeader />
      <Tabs 
        activeTab={activeTab} 
        onTabClick={setActiveTab}
      >
        <section tabTitle={'Информация'} className="main-card__data">
          <ExpandingBlock
            renderHeader= {() => 'Реквизиты'}
            initialState={true}
          >
            <MainCard
              {...props}
              itemId={userId}
              clearBeforeUnmount={false}
              config={cardConfig({
                history,
                updateUserAccount,
                initUserAccountCard,
                breadcrumbsPopAction,
                breadcrumbsPushAction,
                userId
              })}
              useWarning = {false}
            >
              <ManagePanel>
                <BusinessEventPanel
                  onInProgress={cardInProgressAction}
                  rights={actionRights}
                  inProgress={cardInProgress}
                  selectedItems={viewData ? [viewData].map(i => i.id) : []}
                  currentUA={viewData ? viewData : null}
                  selectedUA={viewData ? [viewData] : []}
                  afterAction={initUserAccountCard}
                />
              </ManagePanel>
            </MainCard>
          </ExpandingBlock>
        </section>
        <SkziInstancesTab
          tabTitle={'Экземпляры СКЗИ'}
          profile={profile}
          userId={userId}
          setInProgress={cardInProgressAction}
          issueDisabled={issueDisabled}
        />
        <KeyDocumentsTab
          tabTitle={'Ключевые документы'}
          profile={profile}
          userId={userId}
          userAccountOrgId={orgId}
          setInProgress={cardInProgressAction}
          issueDisabled={issueDisabled}
        />
        <KeyCarriersTab
          tabTitle={'Ключевые носители'}
          profile={profile}
          userId={userId}
          setInProgress={cardInProgressAction}
          issueDisabled={issueDisabled}
        />
        <UserSkziAccess
          tabTitle={'Допуск к СКЗИ'}
          currentUserAccount={viewData}
          history={history}
          profile={profile}
        />
        <History
          tabTitle={'История'}
          serviceName={'userAccountService'}
          itemId={userId}
          inProgressCallback={cardInProgressAction}
          errorCallback={errorAction}
        />
      </Tabs>
    </section>
  )
}

const mapStateToProps = state => {
  const { card, profile } = state

  return {
    ...card,
    profile: profile
  }
}

export default connect(
  mapStateToProps,
  { 
    ...mainCardAction,
    ...breadcrumbsActions,
    ...errorActions
  })(UserAccountCard)