/** @format */

import React, { useState } from 'react';

import { cssModuleClassNameGetter } from 'app/core/utility/jsx';

import styles from './InputWrapper.module.scss';

const getClass = cssModuleClassNameGetter(styles);

export default function InputWrapper({
  id,
  label,
  placeholder,
  disabled,
  readOnly,
  value,
  error,
  hint,
  multiline,
  children,
  slotPrepend,
  slotAppend,
  prependClippable,
  className,
  noHint = false,
  absoluteInfo = false
}) {
  const [isHovered, setIsHovered] = useState(false);
  const hasValue = (!!value || value === 0) && value.toString().length;
  const hasLabel = typeof label !== 'undefined';
  const hasPlaceholder = typeof placeholder === 'string';
  const hasPrepend = !!slotPrepend;

  return (
    <div
      className={getClass(
        [className],
        [
          'inputWrapper',
          {
            hasError: !!error,
            forceShiftLabel: hasValue || hasPlaceholder || hasPrepend,
            disabled,
            readOnly,
          },
        ]
      )}
      onMouseOver={isHovered ? null : () => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={styles.prenotch}></div>
      <div className={styles.notch}>
        <label htmlFor={id} className={getClass([], ['label', { multiline, empty: !hasLabel }])}>
          {label}
        </label>
      </div>
      <div className={styles.postnotch}></div>
      <div className={styles.main}>
        <div className={getClass([], ['prepend', { empty: !slotPrepend, multiline, clippable: prependClippable }])}>
          {slotPrepend}
        </div>
        <div className={getClass([], ['input', { disabled, readOnly, multiline }])}>{children}</div>
        <div className={getClass([], ['append', { empty: !slotAppend, disabled, multiline }])}>
          {typeof slotAppend == 'function' ? slotAppend({ inputHover: isHovered }) : slotAppend}
        </div>
      </div>
      {!noHint && (
        <div className={absoluteInfo ? styles.absoluteInfo : styles.info}>
          {error && error.errorMessage ? (
            <span className={styles.error}>{error.errorMessage}</span>
          ) : hint ? (
            <span className={styles.hint}>{hint}</span>
          ) : null}
        </div>
      )}
    </div>
  );
}
