import React, { useMemo } from "react";
import Group from "app/components/ui/managePanel/group";
import ManagePanel from "app/components/ui/managePanel/managePanel";
import { useJournal } from "app/components/journals/useJournal";
import { sortDirection } from "app/components/ui/constants";
import { TableFieldSelect } from "app/components/list/Table/TableFieldSelect";
import { Table } from "app/components/list/Table/Table";
import Pagination from "app/components/ui/pagination/serverSidePagination/pagination";
import PageHeader from "app/components/PageHeader/pageHeader";
import Overlay from "app/components/ui/overlay";
import BusinessEventPanel from "./ListActionPanel";
import { Border } from "app/components/ui/managePanel";
import Filter, { GlobalOrgType } from "app/components/filter";
import { LicenseConfig as config } from "./pageConfig";
import MainListContainer, {
  FilterWithTableContainer,
  ListDisplayVariant,
} from "app/components/ui/MainListContainer/MainListContainer";
import FilterTriggerPanel from "app/components/filter/FilterTriggerPanel";
import styles from "./licenseList.module.scss";
import Images from 'app/components/ui/Img/imgMap';
import { RESOURCES } from "app/core/auth/resourceByPage";
import { getResourceRights } from "app/core/auth/auth";

export default function LicenseList() {
  const {
    // useListFilter
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    setVisibleFieldsAction,
    toggleVisibleFieldAction,
    filterState,
    filterForRequest,
    fieldsVisible,
    filterFilled,
    onClearFilter,
    filterOpened,
    toggleFilterState,

    // useReducerTable
    onInProgress,
    sortListAction,
    inProgress,
    sorting,
    selectedItems,
    list,
    setSelectedAction,
    onRemoveValues,

    // global
    profile,

    //pagination
    currentPage,
    perPage,
    pageCount,
    setCurrentPage,
    selectedObjects,
    setSelectedPagination,
    totalCount,

    //
    setGlobalOrg,
    globalOrgId,
    globalOrganization,
  } = useJournal({
    defaultSort: {
      column: "updateDate",
      direction: sortDirection.desc,
    },
    serviceName: "licenseService",
  });

  const rights = useMemo(() => getResourceRights(profile, RESOURCES.skziLicense), [profile]);
  const massImportRights = useMemo(() => getResourceRights(profile, RESOURCES.massImportSkziLicense), [profile]);

  return (
    <MainListContainer>
      {inProgress && <Overlay />}
      <PageHeader title='Экземпляры CКЗИ : Лицензии' Img={Images.License} />
      <ManagePanel borders={Border.All}>
        <BusinessEventPanel {...{
          rights,
          massImportRights,
          items: selectedObjects,
          filterForRequest,
          sorting,
          fieldsVisible,
          globalOrgId,
          onInProgress,
          setSelectedAction,
          afterAction: onRemoveValues,
        }}/>
        <Group separate>
          <FilterTriggerPanel
            toggle={toggleFilterState}
            filterFilled={filterFilled}
            onClearFilter={onClearFilter}
            disabledClear={!filterState.length}
          />
        </Group>
        <Group separate>
          <TableFieldSelect
            listFieldsVisible={fieldsVisible}
            setVisibleFieldsAction={setVisibleFieldsAction}
            toggleVisibleFieldAction={toggleVisibleFieldAction}
            headerInfo={true}
            fieldsConfig={config.fieldsConfig}
            changedFieldsVisibility={true}
            settingsAsGear={true}
          />
        </Group>
      </ManagePanel>
      <FilterWithTableContainer
        useContainer={filterOpened}
        listDisplayVariant={ListDisplayVariant.threeRowFilter}
      >
        <Filter
          className={styles.licenseFilter}
          inProgress={inProgress}
          setGlobalOrg={setGlobalOrg}
          setFilterStateAction={setFilterStateAction}
          setFilterForRequestAction={setFilterForRequestAction}
          setFullFilterStateAction={setFullFilterStateAction}
          filterState={filterState}
          filterFilled={filterFilled}
          profile={profile}
          filtersConfig={config.tablePageFiltersConfig}
          isOpened={filterOpened}
          toggleFilterState={toggleFilterState}
          globalOrganization={globalOrganization}
          globalOrgPosition={4}
          withGlobalOrg
          globalOrgType={GlobalOrgType.all}
        />
        <Table
          {...{
            totalCount,
            list,
            sortListAction,
            sorting,
            selectedItems,
            selectedObjects,
            setSelectedAction,
            setSelectedPagination,
            inProgress,
          }}
          listFieldsVisible={fieldsVisible}
          fieldsConfig={config.fieldsConfig}
          itemsSelectable={true}
          changedFieldsVisibility={true}
          onItemClick={(item) => `/license/card/${item.id}`}
          headerInfo={true}
        />
      </FilterWithTableContainer>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageCount={pageCount}
        perPage={perPage}
      />
    </MainListContainer>
  );
}