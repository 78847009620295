/** @format */
import React from 'react';
import Picker, { SelectType } from 'app/components/ui/picker/picker';

import withModal from 'app/components/HOC/ModalHOC';
import withSignatureHOC from 'app/components/HOC/withSignatureHOC';
const ModalPicker = withModal(Picker);

const InnerPicker = ({
  onCancel,
  userId,
  onSubmitSignEnhance,
  prepareDataToSign,
  serviceType,
  pickerService,
  modalHeader,
  filterConfig,
  fiterCssClass,
  pickerColumns,
  pickerVisible,
}) => {
  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleSelect = async selected => {
    const [firstSelected] = selected || [];
    const { id } = firstSelected || {};
    const dataToSign = await prepareDataToSign(id, userId);
    if (dataToSign === null) {
      return;
    }

    onSubmitSignEnhance(dataToSign);
  };

  const modalProps = {
    onCancel: handleCancel,
    renderHeader: () => <div className="modal__header">{modalHeader}</div>,
  };

  return (
    pickerVisible && (
      <ModalPicker
        className={fiterCssClass}
        serviceType={serviceType}
        notStandardService={pickerService}
        filterConfig={filterConfig}
        tableColumns={pickerColumns}
        selectType={SelectType.single}
        onSelect={handleSelect}
        {...modalProps}
      />
    )
  );
};

export default withSignatureHOC(InnerPicker);
