import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Autocomplete, { renderInputValue, Services } from "../ui/autocomplete/autocomplete";
import Button from "../ui/button/button";
import Img from 'app/components/ui/Img'
import Image from 'app/components/ui/Img/imgMap'
import service from "app/services/service";
import ConfirmModal from "../ConfirmModal";
import { dispatchLogic } from "redux/actions/common";

export default function VolumeAutocomplete(props){

  const {
    selectedVolume,
    setSelectedVolume,
    gridFilterUid,
    children,
    onDeleteSelectedVolume,
    className="filter-item"
  } = props

  const listId = useSelector(({ listId}) => listId)

  const closureService = {
    serviceName: 'volumeService', 
    serviceMethod: 'autocomplete', 
    data: {
      organizationId: listId,
      gridFilterUid
    }
  }
  const [idToDelete, setIdToDelete] = useState(null)

  const dispatch = useDispatch()

  const onConfirm = () => {
    service('volumeService', 'deleteVolume', idToDelete)
      .then(response => {
        const {data, errors, isError} = response;
        if (!(errors && errors[0])) {
          setIdToDelete(null)
          selectedVolume && selectedVolume.id === idToDelete && onDeleteSelectedVolume()
        }
        dispatchLogic(dispatch, 'MOCK', data, isError, errors)
      })
  }

  return (
    <>
      <Autocomplete
        className={className}
        label="Том журнала"
        onEmptySearch={true}
        notStandardService={closureService }
        serviceType={ Services.volume}
        renderItem={ onDeleteSelectedVolume ? (data) => VolumeItemDeletable({...data, onClick: setIdToDelete }) : VolumeItem }
        renderInputValue = {renderInputValue.volume}
        onSelect={setSelectedVolume}
        value={selectedVolume}
        noHint
      >
        {children}
      </Autocomplete>
      {
        !!idToDelete && (
          <ConfirmModal
            onCancel={() => setIdToDelete(null)}
            header="Удалить том?"
            text="При этом все настройки тома будут удалены. Это не затронет экземпляры, входящие в удаляемый том."
          >
            <Button className='footer__item' type = 'danger'  onClick = { onConfirm } >
              <span className='button-title'>Удалить</span>
            </Button>
            <Button className='footer__item' type = 'secondary' onClick = {() => setIdToDelete(null)}>
              <span className='button-title'>Отмена</span>
            </Button>
          </ConfirmModal>
        )
      }
    </>
  )
}

function VolumeItemDeletable({id, name, onClick}){
  return (
    <div key={id} className="volume-item">
      <span className="volume-item__name">{name}</span>
      <Button
        type='image'
        onClick={() => onClick(id)}
        className="button-image"
      >
        <Img img={Image.Delete}  className='button-image'/>
      </Button>

    </div>
  )
}

function VolumeItem({id, name}){
  return (
    <div key={id} className="volume-item">
      {name}
    </div>
  )
}