import React from 'react'
import HistoryItem from './historyItem'
import withEndlessScroll from 'app/components/HOC/EndlessScrollHOC'
import styles from './history.module.scss';

function HistoryList({items}) {
  return (
    <div className={styles.list}>
      {items.map(item => <HistoryItem {...item} />)}
    </div>
  )
}

export default withEndlessScroll(HistoryList)