import React from "react";
import { formatFIO, stringPlural } from "app/core/utility/common";
import { formatDate } from "app/core/utility/date";
import { USER_ACCOUNT_CARD_TYPE } from "app/components/ui/constants";
import ListLink from "app/components/ui/ListLink";
import { FilterControlType } from "../filter/FilterEllement";

const config = {
  fieldsConfig: [
    {
      title: "№",
      alias: "name",
      userSorting: true,
      visibleOnStart: true,
    },

    {
      title: "Дата заключения",
      alias: "issueDate",
      format: (item) => formatDate(item.issueDate, "dd.mm.yyyy"),
      userSorting: true,
      visibleOnStart: true,
      width: 200,
    },

    {
      title: "СКЗИ",
      alias: "skziinfo",
      format: (item) =>
        item.skziInfo &&
        item.skziInfo.skzi &&
        item.skziInfo.skzi.name + " " + item.skziInfo.version.name,
      userSorting: true,
      visibleOnStart: true,
      visibleAlways: true,
      width: 280,
    },

    {
      title: "Пользователь СКЗИ",
      alias: "user",
      format: (item) => {
        return item.user ? (
          <ListLink
            text={formatFIO(item.user)}
            targetType={USER_ACCOUNT_CARD_TYPE}
            id={item.user.id}
          />
        ) : (
          formatFIO(item.user)
        );
      },
      userSorting: true,
      visibleAlways: true,
      visibleOnStart: true,
      width: 280,
    },

    {
      title: "Автор",
      alias: "issuer",
      format: (item) => item.issuer && formatFIO(item.issuer.user),
      userSorting: true,
      visibleOnStart: true,
      width: 280,
    },

    {
      title: "Статус",
      alias: "status",
      format: (item) => (item.isSigned ? "Подписан" : "На подписании"),
      userSorting: true,
      visibleOnStart: true,
      width: 200,
    },

    {
      title: "Дата изменения",
      alias: "changeDate",
      format: (item) => formatDate(item.changeDate, "dd.mm.yyyy"),
      userSorting: true,
      visibleOnStart: true,
      width: 200,
    },
  ],
  tablePageFiltersConfig: [
    {
      type: FilterControlType.simpleSearch,
      alias: "names",
      label: "№",
      extended: false,
      requestFormatIsArray: true,
      order: 2,
    },
    {
      type: FilterControlType.autocompletePicker,
      alias: "eskziUserId",
      label: "Пользователь СКЗИ",
      serviceType: "skziUser",
      extended: false,
      order: 3,
    },
    {
      type: FilterControlType.autocomplete,
      alias: "skziVersionId",
      label: "СКЗИ",
      serviceType: "skziVersion",
      extended: false,
      order: 4,
    },
    {
      type: FilterControlType.signerWithSignState,
      alias: "signerId",
      label: "Участник комиссии",
      serviceType: "signer",
      extended: true,
      order: 6,
    },
    {
      type: FilterControlType.dateRangePicker,
      alias: "courseDate",
      labelFrom: "Начало обучения",
      labelTo: "Окончание обучения",
      extended: true,
      order: 7,
    },
    {
      type: FilterControlType.autocomplete,
      alias: "signStatus",
      label: "Статус",
      serviceType: "signStatus",
      extended: true,
      order: 8,
    },
    {
      type: FilterControlType.autocompletePicker,
      alias: "authorId",
      label: "Автор",
      serviceType: "user",
      extended: true,
      order: 9,
    },
    {
      type: FilterControlType.dateRangePicker,
      alias: "issueDate",
      label: "Дата заключения",
      extended: true,
      order: 10,
    },
  ],
};

export default config;
