import React from 'react'
import { humanTextFileSize } from 'app/core/utility/file'
import { ReactComponent as CancelImg } from 'assets/img/commonVer2/close.svg'
import Button from 'app/components/ui/button/button'

export default function fileItem({className, file, progress, icon, error, onCancel}) {
  const { blob: fileObject, fileKey } = file
  const { name, size, fileSize } = fileObject
  const { errorMessage } = error || {}

  return (
    <div className={`file-item ${className || {}}`}>
      <div className="file-item__data">
        {icon}
        <div className="file-item__name">{name}</div>
        <div className="file-item__size">{size ? `(${humanTextFileSize(size)})` : fileSize ? fileSize :''}</div>
      </div>
      {error ? (
        <div className="file-item__error-message">{errorMessage}</div>
      ) : (
        <>
          <div className="file-item__progress">
            {progress ? (
              <div className="progress-background">
                <div className="current-progress" style={{width: `${Math.floor(progress * 100)}%`}}></div>
              </div>
            ) : null}
          </div>
          <div className="file-item__cancel">
            {onCancel && progress !== 1 ? (
              <Button
                className='file-item__cancel'
                type = 'image'
                onClick={() => onCancel(fileKey)}
              >
                <CancelImg className='button-image'/>
              </Button>
            ) : null}
          </div>
        </>
      )}
    </div>
  )
}
