import { 
  BREADCRUMBSPUSHITEM,
  SELFSERVICEREGISTRYGETALL,       
  SELFSERVICEREGISTRYINPROGRESS,     
  SELFSERVICEREGISTRYRESETACTION,    
  SELFSERVICEREGISTRYCLEARSTOREACTION,
  SELFSERVICEREGISTRYPAGINATIONGET,
  SELFSERVICEREGISTRYSETFILTERACTION,
  SELFSERVICEREGISTRYONBUILDSSORT,
  SELFSERVICEREGISTRYONCOURSESSORT,
  SELFSERVICEREGISTRYGETBUILDSETDATA,
  SELFSERVICEREGISTRYINSTALLSKZI,
  SELFSERVICEREGISTRYCREATECONCLUSION,
  SELFSERVICEREGISTRYONBUILDSUPDATE,
  SELFSERVICEREGISTRYCOURSEASSIGN,
  SELFSERVICEREGISTRYEXAMREQUEST,
  SELFSERVICEREGISTRYONCOURSEASSIGN
} from '../actionTypes'

import service from 'app/services/service'
import { dispatchLogic } from '../common'
import { getPageBreadcrumb } from 'app/header/breadcrumbs'	

export const selfServiceRegistryGetAction = ({ filterData, page, pageSize }, path) => dispatch => {
  service('selfServiceService', 'getAll', { filterData, page, pageSize })
    .then(response => {
      const { data, dataCount, errors, isError } = response;

      return dispatchLogic(dispatch, SELFSERVICEREGISTRYGETALL, {data, dataCount}, isError, errors)
    })
    .then(() => {
      dispatch({
        type: BREADCRUMBSPUSHITEM,
        payload: { 
          ...getPageBreadcrumb(path),
          forceUpdate: true
        }
      })
    })
}

export const selfServiceRegistryBuildsSortAction = (data) => dispatch => {
  const { skziId, versionId } = data
  service('versionService', 'getSelfServiceBuilds', data)
    .then(response => {
      const { data, errors, isError } = response;

      return dispatchLogic(dispatch, SELFSERVICEREGISTRYONBUILDSSORT, {skziId, versionId, data}, isError, errors)
    })
}

export const selfServiceRegistryCoursesSortAction = (data) => dispatch => {
  const { skziId, versionId } = data

  service('courseService', 'getVersionCourses', data)
    .then(response => {
      const { data, errors, isError } = response;

      return dispatchLogic(dispatch, SELFSERVICEREGISTRYONCOURSESSORT, {skziId, versionId, data}, isError, errors)
    })
}

export const selfServiceRegistryCreateConclusionAction = (data) => async (dispatch) => {

  return await service('selfServiceService', 'eskziСonclusion', data)
    .then(response => {
      const { data: responseData, errors, isError } = response;

      return dispatchLogic(dispatch, SELFSERVICEREGISTRYCREATECONCLUSION, responseData, isError, errors)
    })
}

export const selfServiceRegistrySkziInstallAction = (data) => dispatch => {

  return service('selfServiceService', 'eskziInstall', data)
    .then(response => {
      const { data: responseData, errors, isError } = response;

      return dispatchLogic(dispatch, SELFSERVICEREGISTRYINSTALLSKZI, responseData, isError, errors)
    })
}

export const selfServiceRegistryOnExamRequestAction = (data) => dispatch => {

  return service('selfServiceService', 'examRequest', data)
    .then(response => {
      const { data: responseData, errors, isError } = response;

      return dispatchLogic(dispatch, SELFSERVICEREGISTRYEXAMREQUEST, responseData, isError, errors)
    })
}

export const selfServiceRegistryCourseAssignAction = (data) => dispatch => {

  return service('selfServiceService', 'courseAssign', data)
    .then(response => {
      const { data: responseData, errors, isError } = response;

      return dispatchLogic(dispatch, SELFSERVICEREGISTRYCOURSEASSIGN, responseData, isError, errors)
    })
}


export const selfServiceRegistryOnCourseAssignAction = (versionId) => dispatch => {

  dispatch(
    {
      type: SELFSERVICEREGISTRYONCOURSEASSIGN,
      payload: versionId
    }
  )
}

export const selfServiceRegistryPaginationAction = ({ activePage = 1, itemsPerPage = 10 }) => dispatch => {

  dispatch(
    {
      type: SELFSERVICEREGISTRYPAGINATIONGET,
      payload: { activePage, itemsPerPage }
    }
  )
}

export const selfServiceRegistryInProgressAction = value => dispatch => {
  dispatch({
    type: SELFSERVICEREGISTRYINPROGRESS,
    payload: { inProgress: value }
  })
}

export const selfServiceRegistryGetBuildSetDataAction = value => dispatch => {
  return dispatch({
    type: SELFSERVICEREGISTRYGETBUILDSETDATA,
    payload: value
  })
}



export const selfServiceRegistryResetAction = () => dispatch => {
  dispatch({
    type: SELFSERVICEREGISTRYRESETACTION,
    payload: null
  })
}

export const selfServiceRegistrySetFilterAction = (data) => dispatch => {
  return dispatch({
    type: SELFSERVICEREGISTRYSETFILTERACTION,
    payload: data
  })
}

export const selfServiceRegistryClearStoreAction = () => dispatch => {
  dispatch({
    type: SELFSERVICEREGISTRYCLEARSTOREACTION,
    payload: null
  })
}
