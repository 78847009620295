/** @format */
import DownloadFileButton from '../downloadFileButton';
import { Image } from 'app/components/ui/Img';
import ConfirmDialog, { dialogMode, dialogType } from '../dialog/confirmDialog';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setNeedRefreshByRest } from 'redux/actions/notification';
import { dispatchLogic } from 'redux/actions/common';
import styles from './component.module.scss';

export default function ExportToExcelButton({
  listFilterForRequest,
  sorting,
  listFieldsVisibility,
  selected,
  id,
  serviceName,
  disabled,
  onStart,
  onFinish,
}) {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const columns = {};
  const validFields = listFieldsVisibility.filter(i => !i['bizLocked']);
  validFields.forEach(el => {
    const key = Object.keys(el)[0];
    columns[key] = el[key];
  });

  const api = {
    name: serviceName,
    method: 'downloadExcell',
    payload: {
      filter: {
        conditions: listFilterForRequest || {},
        sortBy: sorting.column,
        sortDirection: sorting.direction,
      },
      id: id,
      ids: selected,
      columns: columns,
    },
  };

  const onError = data => {
    const { isWarn, isError, errors } = data;

    if (isWarn) {
      setShowDialog(true);
      dispatch(setNeedRefreshByRest(true));
    } else {
      dispatchLogic(dispatch, 'MOCK', null, isError, errors);
    }
  };

  return (
    <>
      <DownloadFileButton
        disabled={disabled}
        tooltip="Выгрузить в Excel"
        api={api}
        onError={onError}
        onFileActionStart={onStart}
        onFileActionFinish={onFinish}
        img={Image.Excel}
      />
      {showDialog && (
        <ConfirmDialog
          mode={dialogMode.medium}
          type={dialogType.warning}
          header={`Создание файла отчета…`}
          onCancel={() => setShowDialog(false)}
        >
          <div className={styles.warningText}>
            По окончании процесса формирования файла отчета скачивание начнется автоматически.
            <br />
            Если файл окажется объемным, Вы получите уведомление с возможностью скачать файл.
            <br />
            <br />
            Обратите внимание, что отчеты с большим количеством записей могут формироваться
            длительное время (до нескольких часов).
          </div>
        </ConfirmDialog>
      )}
    </>
  );
}
