import React, { useEffect, useState } from 'react'
import Button from 'app/components/ui/button/button'
import { ReactComponent as SearchImg } from 'assets/img/commonVer2/search.svg';
import PickerComponent, { SelectType, Services } from 'app/components/ui/picker/picker';
import withModal from 'app/components/HOC/ModalHOC';
import { calculateLicenseCount } from './helpers';

const Picker = withModal(PickerComponent)

const LicensePicker = ({
  organizationId,
  onSelect,
  className,
  readOnly = false,
  disabled = false,
  error,
  alreadySelected,
  eskziId,
  excludeItems,
}) => {

  const [showPicker, setShowPicker] = useState(false)
  const [selectedState, setSelectedState] = useState()

  const { errorMessage } = error || {}

  const _onItemSelect = (items, e) => {
    const count = calculateLicenseCount(items)
    setSelectedState(count)
    onSelect({organizationId, items: items})
    toggleShow(e)
  }

  useEffect(() => {
    const hasAlreadySelected = !!alreadySelected?.[organizationId]?.length;    
    const count = hasAlreadySelected ? calculateLicenseCount(alreadySelected[organizationId]) : '';
    setSelectedState(count);
  }, [alreadySelected, organizationId]);

  const toggleShow = (e) => {
    e && e.preventDefault()
    e && e.stopPropagation()
    setShowPicker(!showPicker)
  }

  const pickerService = {
    serviceName: 'licenseService',
    serviceMethod: 'getUnusedEskziLicense',
    data: {eskziId: eskziId}
  }

  return (
    <div className={`license-picker ${className || ''}${readOnly ? ' license-picker--read-only': ''}${disabled ? ' license-picker--disabled' : ''}`}>
      <div className={`license-picker__control ${errorMessage ? 'license-picker--error' : ''}`}>

        <div className="license-picker__licenses">
         {selectedState || ''}
        </div>
        <div className="license-picker__management">
          <Button 
            type='image'
            disabled={disabled}
            onClick={toggleShow}
          >
            <SearchImg className='license-picker__search-button'/>
          </Button>
        </div>
      </div>
      {showPicker ? (
        <Picker
          serviceType={Services.skziLicenses}
          notStandardService={pickerService}
          selectType={SelectType.multiple}
          onCancel={toggleShow} 
          onSelect={_onItemSelect}
          alreadySelected={alreadySelected[organizationId]}
          excludeItems={excludeItems}
          renderTagName={item => `№${item.id} **${item.key?.slice(-4) ?? ''}`}
          renderHeader = {() => {
            return (
              <div className='modal__header'>
                {`Выбор лицензий`}
              </div>
            )
          }}
        />
      ) : null}
    </div>
  )
}

export default LicensePicker