/** @format */

import Input from 'app/components/ui/Input';
import DatePicker from 'app/components/ui/date';
import { formatFIO } from 'app/core/utility/common';
import { USER_ACCOUNT_CARD_TYPE } from 'app/components/ui/constants';

import styles from './certificate.module.scss';

export default function RequisitesForm(props) {
  const {
    serialNumber = null,
    creationDate = null,
    validityDate = null,
    status = null,
    recipientName = null,
    issuedBy = null,
    intendedFor = null,
  } = props;

  return (
    <div className={styles.requisites}>
      <Input
        className="card-field certificate-card__certificate"
        label="Сертификат"
        value={serialNumber}
        readOnly
      />
      <DatePicker
        className="card-field certificate-card__createDate"
        label="Дата изготовления"
        value={creationDate}
        readOnly
      />
      <DatePicker
        className="card-field certificate-card__endDate"
        label="Дата окончания действия"
        value={validityDate}
        readOnly
      />
      <Input
        className="card-field certificate-card__status"
        label="Статус"
        value={status?.name}
        readOnly
      />
      <Input
        className="card-field certificate-card__eskziUser"
        label="Кому выдан"
        readOnly
        value={recipientName}
      />
      <Input
        className="card-field certificate-card__receivedFrom"
        label="Кем выдан"
        value={issuedBy}
        readOnly
      />
      <Input
        className="card-field certificate-card__createdFor"
        label="Предназначен для"
        value={intendedFor}
        readOnly
      />
    </div>
  );
}
