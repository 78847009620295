import { 
  BREADCRUMBSPUSHITEM,
  SKZIREGISTRYGETALL, 
  SKZIREGISTRYGETSKZIDATA,
  SKZIREGISTRYDELETE, 
  SKZIREGISTRYINPROGRESS, 
  SKZIREGISTRYRESETACTION, 
  SKZIREGISTRYCLEARSTOREACTION,
  SKZIREGISTRYONDELETE, 
  SKZIREGISTRYPAGINATIONGET,
  SKZIREGISTRYONSELECT
} from '../actionTypes'
import service from 'app/services/service'
import { dialogType } from 'app/components/dialog/confirmDialog/'
import { dispatchLogic } from '../common'
import { getPageBreadcrumb } from 'app/header/breadcrumbs'	

export const skziRegistryGetAction = ({ filterData, column, direction, page, pageSize }, path) => dispatch => {
  service('skziService', 'getAll', { filterData, column, direction, page, pageSize })
    .then(response => {
      const { data, dataCount, errors, isError } = response;

      return dispatchLogic(dispatch, SKZIREGISTRYGETALL, {data, dataCount}, isError, errors)
    })
    .then(() => {
      dispatch({
        type: BREADCRUMBSPUSHITEM,
        payload: { 
          ...getPageBreadcrumb(path),
          forceUpdate: true
        }
      })
    })
}

export const getSkziRowDataAction = (id) => dispatch => {
  service('skziService', 'getSkzi', id)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, SKZIREGISTRYGETSKZIDATA, data, isError, errors)
    })
}

export const skziRegistryPaginationAction = ({ activePage = 1, itemsPerPage = 10 }) => dispatch => {

  dispatch(
    {
      type: SKZIREGISTRYPAGINATIONGET,
      payload: { activePage, itemsPerPage }
    }
  )
}

export const skziRegistrySelectAction = selectedData => dispatch => {
  return dispatch(
    {
      type: SKZIREGISTRYONSELECT,
      payload: selectedData
    }
  )
}



export const skziRegistryDeleteAction = data => dispatch => {
  return service('skziService', 'deleteSkzi', data.map(item => item.id))
    .then(response => {
      const { data: responseData , errors, isError } = response

      return dispatchLogic(dispatch, SKZIREGISTRYDELETE, responseData || data.map(item => item.id), isError, errors)
    })
}

export const skziRegistryOnDeleteAction = data => dispatch => {
  const names = data.map(item => item.name).join(', ')

  dispatch({
    type: SKZIREGISTRYONDELETE,
    payload: { 
      confirmObject: {
        item: data,
        type: dialogType.confirm,
        title: `Вы уверены, что хотите удалить: «${names}»?`
      }
    }
  })
}

export const skziRegistryInProgressAction = value => dispatch => {
  dispatch({
    type: SKZIREGISTRYINPROGRESS,
    payload: { inProgress: value }
  })
}

export const skziRegistryResetAction = () => dispatch => {
  dispatch({
    type: SKZIREGISTRYRESETACTION,
    payload: null
  })
}

export const skziRegistryClearStoreAction = () => dispatch => {
  dispatch({
    type: SKZIREGISTRYCLEARSTOREACTION,
    payload: null
  })
}
