import React from "react";
import withModal from "./HOC/ModalHOC";

const ConfirmModal = withModal(({header, text, children}) => {
  return (
    <>
      <div className='confirm-modal__header'>{header}</div>
      <div className = "modal__content confirm-modal__content">
        <span className='modal-message confirm-modal__message'>
          {text}
        </span>
        <div className="modal__footer confirm-modal__footer">
          {children}
        </div>
      </div>
    </>
  )
})

export default ConfirmModal