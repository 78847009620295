import React from "react";
import Autocomplete, { Services, renderItem } from "app/components/ui/autocomplete/autocomplete";
import SimpleSearch from "app/components/ui/search/SimpleSearch";
import { DateRangePicker } from "app/components/ui/date";
import Checkbox, { checkboxType } from "app/components/ui/checkbox";
import { formatFIO, isEmptyObject } from "app/core/utility/common";
import { formatDate } from "app/core/utility/date";
import { InputType } from "../ui/Input/Input";
import DropDown from "../ui/DropDown";
import SignerWithSignState from "./signerWithSignState/SignerWithSignState";
import AutocompletePicker from "app/components/ui/picker/autocompletePicker/autocompletePicker";
import { SelectType } from "../ui/picker/picker";
import SelfServiceStatus from "./selfServiceStatus/SelfServiceStatus";
import DatePicker from "../ui/date/DatePicker";

export const FilterControlType = {
  dateRangePicker: "DateRangePicker",
  autocomplete: "Autocomplete",
  multiselect: "Multiselect",
  checkbox: "Checkbox",
  simpleSearch: "SimpleSearch",
  dropDown: "DropDown",
  autocompletePicker: 'autocompletePicker',
  signerWithSignState: "signerWithSignState",
  selfServiceStatus: 'selfServiceStatus',
  datePicker: 'DatePicker'
};

const isAutosearch = (type) => {
  switch (type) {
    case FilterControlType.autocomplete:
    case FilterControlType.autocompletePicker:
    case FilterControlType.signerWithSignState:
    case FilterControlType.checkbox:
    case FilterControlType.datePicker:
    case FilterControlType.dateRangePicker:
      return true;

    default:
      return false;
  }
};

export default function FilterElement({
  autosearch, //  true/false
  type, //
  alias, //
  label, //
  labelTo,
  labelFrom,
  placeholder,
  requestFormatIsArray,
  requestEnumFormat, // когда сервер ожидает в теле запроса весь объект, а не только id, как в большинстве случаев
  selectedItem = {}, //
  serviceType,
  selectType = SelectType.single,
  filterConfig,
  disabled,
  onSearch,
  onChange,
  isExtendedSearchBlock,
  className,
  notStandartService = null,
  options,
  pattern,
}) {
  let render = null;
  if (type === "Autocomplete") {
    if (serviceType === "skziBuild") {
      render = renderItem.skziBuild;
    } else if (serviceType === "skziUser" || serviceType === "user") {
      render = renderItem.eskziUser;
    } else {
      render = renderItem[serviceType];
    }
  }

  const _onChangeSelect = (item) => {
    const obj = {
      ...item,
      valueForRequest: null,
      valueFormatedString: "",
    };

    if (item === null) {
      _onChange(obj);
      return;
    }

    if (requestEnumFormat) {
      obj.valueForRequest = item;
      obj.valueFormatedString = item.name;
    } else if (serviceType === "skziUser" || serviceType === "user") {
      obj.valueForRequest = item.id;
      obj.valueFormatedString = formatFIO(item);
    } else if (serviceType === "skzi") {
      obj.valueForRequest = item.id;
      obj.valueFormatedString = item.name;
    } else if (serviceType === "skziVersion") {
      obj.valueForRequest = item.version.id;
      obj.valueFormatedString = item.skzi.name + " " + item.version.name;
    } else if (serviceType === "organizations") {
      obj.valueForRequest = item.id;
      obj.valueFormatedString = item.name;
    } else if (serviceType === "skziBuild") {
      obj.valueForRequest = item.build.id;
      obj.valueFormatedString = item.build.name;
    } else if (serviceType === "instanceStatuses") {
      obj.valueForRequest = item.id;
      obj.valueFormatedString = item.name;
    } else if (serviceType === "activeStates") {
      obj.valueForRequest = { id: item.id, name: item.name };
      obj.valueFormatedString = item.name;
    } else if (serviceType === "keyCarrierType") {
      obj.valueForRequest = item.id;
      obj.valueFormatedString = item.name;
    } else if (serviceType === "eskziUserStatus") {
      obj.valueForRequest = item.id;
      obj.valueFormatedString = item.name;
    } else if (serviceType === "isActiv") {
      obj.valueForRequest = item.id + "";
      obj.valueFormatedString = item.name;
    } else if (serviceType === "keyDocument") {
      obj.valueForRequest = item.id + "";
      obj.valueFormatedString = item.name;
    } else if (serviceType === "producers") {
      obj.valueForRequest = item.id + "";
      obj.valueFormatedString = item.name;
    } else if (serviceType === "organizationServed") {
      obj.valueForRequest = item.id + "";
      obj.valueFormatedString = item.name;
    } else {
      obj.valueForRequest = item.id;
      obj.valueFormatedString = item.name;
    } 

    _onChange(obj);
  };

  const _onChangeMultiselect = items => {
    const safeItems = items || []
    const requestValue = requestEnumFormat 
                          ? safeItems
                          : safeItems.map(i => i.id)

    _onChange({
      valueForRequest: safeItems && safeItems.length ? requestValue : null,
      value: safeItems
    })
  }

  const _onChangeDate = (value) => {
    _onChange({
      valueForRequest: formatDate(value, "yyyy-mm-dd")
    });
  };

  const _onChangeDateRange = (value) => {
    let obj = {};

    if (!value.from && !value.to) {
      _onChange({
        valueForRequest: null,
        valueFormatedString: "",
      });
      return;
    }

    const valueForRequest = {
      start: formatDate(value.from, "yyyy-mm-dd"),
      end: formatDate(value.to, "yyyy-mm-dd"),
    };

    const valueFormatedString = `${label} с ${formatDate(value.from, "dd.mm.yyyy") || "-"} по ${
      formatDate(value.to, "dd.mm.yyyy") || "-"
    }`;

    obj = {
      valueForRequest,
      valueFormatedString,
    };

    _onChange(obj);
  };

  const _onChangeCheckbox = (value) => {
    _onChange({
      valueForRequest: value,
      valueFormatedString: value ? label : "",
    });
  };

  const _onChangeSimpleSarch = (value) => {
    _onChange({
      valueForRequest: value,
      valueFormatedString: value ? label : "",
    });
  };

  const _onChangeDropDown = (item) => {
    _onChange({
      ...item,
      valueForRequest: item ? item.id : null,
    });
  };

  const _onChangeSignerWithSignState = (item) => {
    const { signer, hasSign } = item || {};

    _onChange({
      ...item,
      valueForRequest: signer
        ? {
            signerId: signer.id,
            isSignerCompleteSign: hasSign ? hasSign.id : null,
          }
        : null,
    });
  };

  const _onChangeSelfServiceStatus = (item) => {
    const { status, skziVersionId } = item || {};
    const { id: statusId } = status || {}
    const { version } = skziVersionId || {}
    const { id: versionId} = version || {}

    _onChange({
      ...item,
      valueForRequest: status ? 
        {
          status: statusId || null,
          skziVersionId: versionId || null,
        } : null,
    });
  };

  const _onChange = (item) => {
    item.alias = alias;
    item.requestFormatIsArray = requestFormatIsArray;
    item.isExtendedSearchBlock = isExtendedSearchBlock;

    if (isAutosearch(type) || autosearch) {
      _onSearch(item);
    } else {
      onChange(item);
    }
  };

  const _onSearch = (item) => {
    onSearch(item);
  };

  if (type === FilterControlType.checkbox) {
    return (
      <div className={className || ""}>
        <Checkbox
          onChange={(e) => {
            _onChangeCheckbox(!selectedItem.valueForRequest);
          }}
          checked={selectedItem.valueForRequest}
          type={checkboxType.yesNo}
          disabled={disabled}
        >
          {label}
        </Checkbox>
      </div>
    );
  }

  if (type === FilterControlType.simpleSearch) {
    return (
      <SimpleSearch
        className={className || ""}
        key={alias}
        alias={alias}
        placeholder={placeholder}
        label={label}
        pattern={pattern}
        value={selectedItem.valueForRequest}
        onSearch={_onSearch}
        onChange={_onChangeSimpleSarch}
        disabled={disabled}
        noHint
      />
    );
  }

  if (type === FilterControlType.autocomplete) {
    return (
      <Autocomplete
        notStandardService={notStandartService}
        className={className || ""}
        label={label}
        onEmptySearch={true}
        serviceType={Services[serviceType]}
        renderItem={render}
        renderInputValue={(data) => {
          return data && data.valueFormatedString;
        }}
        onSelect={_onChangeSelect}
        value={selectedItem}
        disabled={disabled}
        noHint
      />
    );
  }

  if (type === FilterControlType.dateRangePicker) {
    const { start = "", end = "" } = selectedItem.valueForRequest || {};
    return (
      <DateRangePicker
        className={className || ""}
        label={label}
        labelFrom={labelFrom}
        labelTo={labelTo}
        isClearable={false}
        fromDate={start}
        toDate={end}
        onChangeFrom={_onChangeDateRange}
        onChangeTo={_onChangeDateRange}
        disabled={disabled}
        noHint
      />
    );
  }

  if (type === FilterControlType.datePicker) {
    return (
      <DatePicker
        className={className || ""}
        label={label}
        value={selectedItem.valueForRequest} 
        disabled={disabled}
        onChange={_onChangeDate}
        noHint
      />
    );
  }

  if (type === FilterControlType.multiselect) {
    return (
      <Autocomplete
        notStandardService={notStandartService}
        className={className || ""}
        label={label}
        type={InputType.multiSelect}
        serviceType={Services[serviceType]}
        renderItem={render}
        onSelect={_onChangeMultiselect}
        value={isEmptyObject(selectedItem) ? [] : selectedItem}
        disabled={disabled}
        noHint
      />
    );
  }

  if (type === FilterControlType.dropDown) {
    return (
      <DropDown
        className={className || ""}
        items={options}
        active={selectedItem}
        onSelect={_onChangeDropDown}
        label={label}
        noHint
      />
    );
  }

  if (type === FilterControlType.autocompletePicker) {
    return (
      <AutocompletePicker
        onSelectValue={_onChangeSelect}
        filterConfig={filterConfig}
        notStandardService={notStandartService}
        className={className || ""}
        label={label}
        serviceType={Services[serviceType]}
        selectType={selectType}
        value={selectedItem}
        noHint
      />
    );
  }

  if (type === FilterControlType.signerWithSignState) {
    return (
      <SignerWithSignState
        className={className || ""}
        onChange={_onChangeSignerWithSignState}
        serviceType={Services[serviceType]}
        value={selectedItem}
        noHint
      />
    );
  }

  if (type === FilterControlType.selfServiceStatus) {
    return (
      <SelfServiceStatus
        className={className || ""}
        onChange={_onChangeSelfServiceStatus}
        serviceType={Services[serviceType]}
        value={selectedItem}
        noHint
      />
    );
  }

  return <div className=''></div>;
};

