import React from 'react'
import Group from 'app/components/ui/managePanel/group';
import BusinessEventTrigger from 'app/components/ui/managePanel/businessEventTrigger/businessEventTrigger';
import { ENTITY_TYPE } from 'app/components/ui/managePanel/businessEventTrigger/useBusinessEvent';
import { Image } from 'app/components/ui/Img';
import {Delete} from "app/components/list/TopPanel/Delete";
import { stringPlural } from 'app/core/utility/common';
import service from 'app/services/service';
import { formatDate } from 'app/core/utility/date';
import RecordKDButton from './linkButton/RecordKDButton';
import { Type as MassImportType } from 'app/components/MassImports/enums';
import MassImportButton from 'app/components/MassImports/MassImportButton';
import ExportToExcelButton from 'app/components/exportToExcelButton';

const okiDeliveryOkData = {
  transmittalLetterDate: formatDate(new Date(), 'yyyy-mm-dd')
}

export default function BusinessEventPanel(props) {
  const { 
    globalOrgId,
    items,
    afterAction,
    filterForRequest,
    sorting,
    fieldsVisible,
    massImportRights,
    setSelectedAction,
    onInProgress,
  } = props

  const deleteActionCallback = async () => {
    onInProgress(true);
    const response = await service('keyDocOkiService', 'deleteRange', items.map(i => i.id));
    onInProgress(false);
    return response;
  };

  const allHasChangeUser = items.length && items.every(item => {
    const { availableActions = [] } = item
    return availableActions.some(i => i.code === 'ChangeUser')
  })

  return (
    <>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={['OkzReturn']}
          tooltip='Вернуть'
          endpointType={'keyDocument'}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.ArReturn}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={['OkiDeliveryOk']}
          tooltip='Получить экземпляр'
          endpointType={'keyDocument'}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.Delivery}
          needForm={false}
          defaultSignData={okiDeliveryOkData}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={['OkiSendingOk']}
          tooltip='Подтвердить получение'
          endpointType={'keyDocument'}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.Acceptation}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={['OkzReturnOk']}
          tooltip='Подтвердить возврат'
          endpointType={'keyDocument'}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.AcceptationOfReturn}
        />
      </Group>
      <Group>
        {!allHasChangeUser ? (
          <BusinessEventTrigger
            items={items}
            eventCode={['OkiDelivery']}
            tooltip='Выдать'
            endpointType={'keyDocument'}
            entityType={ENTITY_TYPE.keyDocument}
            afterAction={afterAction}
            Img={Image.SoftSending}
          />
        ) : (
          <BusinessEventTrigger
            items={items}
            eventCode={['ChangeUser']}
            tooltip='Выдать другому пользователю'
            endpointType={'keyDocument'}
            entityType={ENTITY_TYPE.keyDocument}
            afterAction={afterAction}
            Img={Image.SoftSending}
          />
        )}
        <BusinessEventTrigger
          items={items}
          eventCode={['OkiWithdrawal_KD']}
          tooltip='Изъять'
          endpointType={'keyDocument'}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.SoftReturn}
        />
      </Group>
      <Group>
        <RecordKDButton
          onInProgress={onInProgress}
          items={items}
          afterAction={afterAction}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode={['OkiInstallation']}
          tooltip='Установить'
          endpointType={'keyDocument'}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.DownloadCircle}
        />
        <BusinessEventTrigger
          items={items}
          eventCode={['OkiInstallationKeyDocument']}
          tooltip='Ввод в аппаратное СКЗИ'
          endpointType={'keyDocument'}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.Link}
        />
      </Group>
      <Group>
        { massImportRights.IMPORT && <MassImportButton type={MassImportType.OKI_KEY_DOCUMENT} /> }
        <ExportToExcelButton
          id={globalOrgId}
          listFilterForRequest={filterForRequest}
          sorting={sorting}
          listFieldsVisibility={fieldsVisible}
          selected={items.map(i=>i.id)}
          serviceName='keyDocOkiService'
          onStart={() => onInProgress(true)}
          onFinish={() => onInProgress(false)}
        />
      </Group>
      <Group>
        <BusinessEventTrigger
          items={items}
          eventCode='Destruction'
          tooltip='Уничтожить'
          endpointType={'keyDocument'}
          entityType={ENTITY_TYPE.keyDocument}
          afterAction={afterAction}
          Img={Image.Extermination}
        />
      </Group>
      <Group>
        <Delete
          items={items}
          deleteItemsAction={deleteActionCallback}
          afterAction = {afterAction}
          multiply={true}
          setSelectedAction = {setSelectedAction}
          dialog ={data => {
              const arr = ['выбранный ключевой документ', 'выбранные ключевые документы', 'выбранные ключевые документы']
              return `Вы уверены, что хотите удалить ${stringPlural(data.length, arr)}?`
          }}
        />
      </Group>
    </>
  )
}