import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'

import SignatureDialog, { isDigitalSignNeeded } from 'app/components/signature/SignatureDialog'
// import { disableEventPanelAction } from  'redux/actions/list/listActions';
import Card from 'app/components/ui/card/cardMaterial'
import Overlay from 'app/components/ui/overlay'
import { capitalize, serverlikeErrors, formatFIO } from 'app/core/utility/common'
import { itemActions, sortDirection } from 'app/components/ui/constants'
import ConfirmDialog, { dialogType } from 'app/components/dialog/confirmDialog/'
import { getResourceRights } from 'app/core/auth/auth'

import * as errorAction from 'redux/actions/common'
import * as mainCardAction from 'redux/actions/card/cardActions'
import * as signatureActions from 'redux/actions/common/signatureAction'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'

export const cardMode = {
  create: 'create',
  edit: 'edit'
}

export const CardButtons = {
  'copy' : 'copy',
  'download' : 'download',
  'delete': 'delete',
  replicate: 'replicate',
  sign: 'sign'
}

export const objectKey = (data, objectCreator) => {
  const dataObj = objectCreator(data)

  return Object.keys(dataObj).reduce((acc, cur) => {
    if (Array.isArray(dataObj[cur])) {
      return `${acc}${dataObj[cur].map(i => JSON.stringify(i)).join('')}`
    } else {
      return `${acc}${dataObj[cur]}`
    }
  }, '')
}

class CardComponent extends PureComponent {
  constructor(props){
    super(props)

    this.state = { suppressWarn : false, deleteObject: null }
  }

  async UNSAFE_componentWillMount () {
    const { 
      preInitData,
      cardInProgressAction,
      cardPreInitAction,
      location,
      config,
      itemId,
      clearBreadcrumbs = true,
      breadcrumbsClearAction
    } = this.props  
    const { 
      cardInitAction,
      pageBreadcrumbTitle
    } = config

    clearBreadcrumbs && await breadcrumbsClearAction()
    if (preInitData) {
      cardPreInitAction(this.props)
      pageBreadcrumbTitle && pageBreadcrumbTitle(preInitData)
    } else {
      cardInProgressAction(true)
      const result = cardInitAction && await cardInitAction(itemId, this.props)
      cardInProgressAction(false)
      pageBreadcrumbTitle && pageBreadcrumbTitle(result)
    }
  }

  componentWillUnmount() {
    const { 
      cardClearAction, 
      clearBeforeUnmount = true,
    } = this.props

    clearBeforeUnmount && cardClearAction()
  }

  _onFormChange = (key, value) => {
    const { 
      commonDataForm, 
      errorByFields, 
      cardUpdateAction, 
      setErrorByKey,
      config
    } = this.props
    const { customFormChangeHandler } = config
    
    if (customFormChangeHandler) {
      customFormChangeHandler(key, value, this.props)
    } else {
      const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))
      setErrorByKey(filteredErrors)
      cardUpdateAction({ ...commonDataForm, [key]: value })
    }
  }

  _onWarningSubmit = () => {
    this.setState({ suppressWarn: true },  () => {
      this._onSubmit()
      this._onClearWarning()
    })
  }

  _onServerWarningSubmit = () => {
    this.setState({ suppressWarn: true },  () => {
      this._onSubmit()
      this._onClearServerWarning()
    })
  }

  _onSubmit = async () => {
    const { profile, config, commonDataForm } = this.props
    const { 
      signatureEntity, 
      getWarningObject,
    } = config

    // clear frontend-warning
    getWarningObject && this._onClearWarning()

    if (isDigitalSignNeeded(profile, signatureEntity)) {
      this._onSignDialog()
    } else {
      this._registerWithoutSign(commonDataForm)
    }
  }

  _onWarningCheck = () => {
    const { config, setWarningAction } = this.props
    const { getWarningObject } = config

    if (getWarningObject) {
      setWarningAction(getWarningObject(this.props))
    } else {
      this._onSubmit()
    }
  }

  _onClearServerWarning = () => {
    const { clearErrorAction } = this.props

    clearErrorAction()
  }

  _onClearWarning = () => {
    const { clearWarningAction } = this.props
    
    clearWarningAction()
  }

  _getServerWarning = (warning) => {
    const { config } = this.props
    const { getServerWarning } = config

    return getServerWarning ? getServerWarning(warning) : {}
  }

  _getWarning = (warning) => {
    const { config } = this.props
    const { getWarningObject } = config

    return getWarningObject ? getWarningObject(warning) : {}
  }

  _onSignDialog = () => {
    const { 
      setRequestData, 
      generateText, 
      cardInProgressAction,
      config,
      commonDataForm
    } = this.props
    const { suppressWarn } = this.state
    const { signatureServiceName } = config
  
    setRequestData({
      ...commonDataForm,
      signatureControlType: 'Card'
    });
    cardInProgressAction(true)
    generateText(
      {
        ...this._getSignatureRequestData(commonDataForm),
        suppressWarn: suppressWarn
      },
      signatureServiceName || ''
    );
  }
  
  _registerWithoutSign = async (data) => {
    const { 
      cardInProgressAction,
      itemId,
      config
    } = this.props
    const { updateAction } = config
    const { suppressWarn } = this.state

    const requestData = {
      itemId,
      data: this._getSignatureRequestData(data),
      suppressWarn: suppressWarn,
      signature: null
    }

    cardInProgressAction(true)
    const result = await updateAction(requestData)
    this._finishUpdate(result)
  }

  _finishUpdate = (result) => {
    const { config, resetSignature } = this.props
    const { afterUpdate } = config

    this.setState({ suppressWarn: false }, () => resetSignature())
    afterUpdate && afterUpdate(result)
  }

  _onSignatureCancel = () => {
    const { resetSignature } = this.props

    this.setState({isSignCard: false}, () => resetSignature() )
  }

  _afterSign = async () => {
    const {
      signature,
      cardInProgressAction,
      config,
      itemId
    } = this.props
    const { isSignCard, suppressWarn } = this.state || {}
    const { updateAction, buttons } = config
    const { textForSignature, signatureResult, requestData } = signature
    
    const signatureToServer = {
      itemId,
      data: this._getSignatureRequestData(requestData),
      signature: { data: textForSignature, signedData: signatureResult },
      suppressWarn: suppressWarn
    }

    cardInProgressAction(true)
    if (isSignCard) {
      this.setState({isSignCard: false})
      buttons[CardButtons.sign] && buttons[CardButtons.sign](signatureToServer)
    } else {
      const result = await updateAction(signatureToServer)
      this._finishUpdate(result)
    }
  }

  _getSignatureRequestData = (data) => {
    const { config, itemId } = this.props
    const { getSignatureData, oldSignatureService, signatureEntity } = config

    return oldSignatureService 
            ? {
              ...getSignatureData(data),
              signatureRequestType: signatureEntity,
              itemId: itemId
            } : {
              ...getSignatureData(data),
              itemId: itemId
            }             
  }

  _onCancel = () => {
    const { 
      clearErrorAction, 
      cardResetAction,
      config 
    } = this.props
    const { onCancel } = config

    cardResetAction()
    clearErrorAction()
    onCancel && onCancel()
  }

  _disableEventPanel = () => {
    const { disableEventPanelAction } = this.props;
    disableEventPanelAction && disableEventPanelAction()
  }

  _onSignatureCancel = () => {
    const { resetSignature } = this.props

    resetSignature()
  }
  
  _onValidation = (errors) => {
    const { setErrorByKey } = this.props
  
    setErrorByKey(serverlikeErrors(errors))
  }
  
  _getCardLinks = () => {
    const { config } = this.props
    const { xlsDownloadUrl } = config
  
    return {
      [itemActions.xlsDownload.key]: xlsDownloadUrl
    }
  }

  _onKeyDocumentCopy = () => {
    const { config } = this.props
    const { resource, buttons = {} } = config

    const action = buttons[CardButtons.copy]
    action && action()
  }

  _onSignCard = () => {
    const { profile, config, itemId, commonDataForm, cardInProgressAction } = this.props
    const { signatureEntity, buttons } = config

    const requestData = {
      itemId,
      data: this._getSignatureRequestData(commonDataForm),
      signature: null
    }

    if (isDigitalSignNeeded(profile, signatureEntity)) {
      this.setState({isSignCard: true})
      this._onSignDialog()
    } else {
      buttons[CardButtons.sign] && cardInProgressAction(true)
      buttons[CardButtons.sign] && buttons[CardButtons.sign](requestData)
    }
  }

  _onDeleteConfirm = () => {
    const { commonDataForm, config } = this.props
    const { getDeleteMessage } = config || {}
    this.setState({deleteObject: {
      title: 'Подтвердите удаление',
      error: {
        errorMessage: getDeleteMessage(commonDataForm)
      },
      type: dialogType.confirm
    }})
  }

  _onDelete = async () => {
    const { config, itemId, cardInProgressAction } = this.props
    const { 
      signatureEntity, 
      buttons = {},
      afterDelete
    } = config
    const action = buttons[CardButtons.delete]
    
    this.setState({deleteObject: null})
    cardInProgressAction(true)
    const result = action && await action(itemId)
    const { payload, errors } = result || {}
    const { globalError, detail } = payload || {}
    const { response } = detail || {}
    const { errors: globalErrors } = response || {}
    
    if ((errors && errors.length)
       || (globalErrors && globalErrors.length) ) {
      return
    }
    action && afterDelete && afterDelete()
  }

  _onActionClick = (action) => {
    switch (action) {
      case itemActions.copy.key:
        this._onKeyDocumentCopy()
        break
      case itemActions.delete.key:
        this._onDeleteConfirm()
        break
      case itemActions.sign.key:
        this._onSignCard()
      default:
        break
    } 
  }
  
  _applyRightsToActions = () => {
    const { config, profile, signers = [] } = this.props
    const { signatureEntity, resource, buttons = {}} = config
    const { userInfo } = profile || {}
    const { id: currentUserId } = userInfo || {}
    const firstGroup = []
    const secondGroup = []
    const thirdGroup = []
    const rights = getResourceRights(profile, resource)
    const commissionSigner = signers.find(item => {
      const { user } = item || {}
      const { id } = user || {}
      return id === currentUserId
    })
    const canSign = commissionSigner && !commissionSigner.signature

    Object.keys(buttons).forEach(item => {
      switch (item) {
        case CardButtons.copy:
          rights.UPDATE && firstGroup.push(itemActions.copy.key)    
          break
        case CardButtons.delete:
          rights.DELETE && firstGroup.push(itemActions.delete.key)
          break
        case CardButtons.download:
          secondGroup.push(itemActions.xlsDownload.key)
          break
        case CardButtons.sign:
          canSign 
            && rights.UPDATE 
            && thirdGroup.push(itemActions.sign.key)
          break
        default:
          break
      }
      
    })

    return [firstGroup, secondGroup, thirdGroup]
  }

  render () {
    const { 
      profile,
      className,
      fieldsError, 
      commonDataForm,
      viewData,
      config,
      signature,
      cardInProgress,
      renderOneClickActions,
      cardMode,
      serverWarningObject,
      warningObject,
      useWarning = true,
      cardInProgressAction
    } = this.props
    const { deleteObject } = this.state
    const { 
      cardLines,
      getKey,
      signatureHeaderText,
      resource,
      restrictEditByProp
    } = config
    const { textForSignature, requestData } = signature || {}
    const { signatureControlType } = requestData || {}
    const rights = getResourceRights(profile, resource)
    const canEdit = restrictEditByProp ? restrictEditByProp(commonDataForm) && rights.UPDATE : rights.UPDATE

    return (
      <>
        {cardInProgress ? <Overlay /> : null}
        {signatureControlType === 'Card' && textForSignature ? (
          <SignatureDialog
            className={'signature-form'}
            profile={profile}
            onCancel={this._onSignatureCancel}
            afterSignCallback={this._afterSign}
            renderHeader = {() => {
              return (
                <div className='sign__header'>
                  {signatureHeaderText}
                </div>
              )
            }}
        />) :null}
        <Card
          {...this.props}
          className={`${className || ''}`}
          profile={profile}
          formLines={cardLines}
          actions={this._applyRightsToActions()}
          onActionClick={this._onActionClick}
          customActionProps={config.customActionProps}
          dowloadUrls={this._getCardLinks()}
          renderOneClickActions={renderOneClickActions}
          canEdit={canEdit}
          key={getKey(viewData)}
          data={viewData}
          formData={commonDataForm}
          onValidationError={this._onValidation}
          errorByFields={fieldsError}
          onChange={this._onFormChange}
          onSubmit={this._onWarningCheck}
          onCancel={this._onCancel}
          getObjectKeyFunc={getKey}
          cardMode={cardMode}
          disableEventPanel = { this._disableEventPanel }
          cardInProgressAction={cardInProgressAction}
        />
        {
          warningObject ? (
            <ConfirmDialog
              {...warningObject}
              onSubmit={this._onWarningSubmit}
              onCancel={this._onClearWarning}
            /> 
          ) : null
        }
        {deleteObject ?
          <ConfirmDialog
            needIcon={true}
            {...deleteObject}
            onSubmit={this._onDelete}
            onCancel={() => this.setState({deleteObject: null})}
          /> : null
        }
        {/* to do */}
        {/* надо избавляться от serverWarningObject здесь */}
        {/* когда кнопки в карточке отрефакторим, можно будет удалить, */}
        {/* все WarningObject будут обрабатываться в  src\app\components\HOC\withSignatureHOC.jsx*/}
        {
          useWarning && serverWarningObject ? (
            <ConfirmDialog
              {...this._getServerWarning(serverWarningObject)}
              onSubmit={this._onServerWarningSubmit}
              onCancel={this._onClearServerWarning}
            /> 
          ) : null
        }
      </>
    )
  }
  
}

const mapStateToProps = state => { 
  const { card, error, profile, signature } = state
  const { fieldsError, errorByFields, errorObject, warningObject } = error

  return {
    profile,
    signature,
    ...card,
    fieldsError,
    errorByFields,
    errorObject,
    serverWarningObject: warningObject
  }
 }

export default connect(
  mapStateToProps,
  { 
    ...errorAction,
    ...mainCardAction,
    ...signatureActions,
    ...breadcrumbsActions,
  })(CardComponent)