/** @format */

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import BreadcrumbsHeader from "app/components/breadcrumbs/breadcrumbsHeader";
import Tabs from "app/components/ui/tabs/tabs";

import {
  breadcrumbsPushAction,
  breadcrumbsPopAction,
} from "redux/actions/common/breadcrumbsAction";
import CertificateData from "./certificateData/certificateData";
import { useHistory, useParams } from "react-router-dom";
import styles from "./certificateCard.module.scss";

const CertificateCard = ({CAUser = false}) => {
  const { certificateId } = useParams();
  const [activeTab, setActiveTab] = useState("Информация");
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      breadcrumbsPushAction(
        history.location.pathname,
        `Сертификат №${certificateId}`,
        true
      )
    );
    return () => {
      dispatch(breadcrumbsPopAction());
    };
  }, []);

  return (
    <section className={styles.certificateCard}>
      <BreadcrumbsHeader />
      <Tabs activeTab={activeTab} onTabClick={(title) => setActiveTab(title)}>
        <section tabTitle={"Информация"} className="certificateCard__data">
          <CertificateData certificateId={certificateId} CAUser={CAUser}/>
        </section>
      </Tabs>
    </section>
  );
};

export default CertificateCard;
