import React, {useEffect, useRef, useState} from 'react';
import Container from "app/components/MassImports/Container";
import FileUploadControl from 'app/components/ui/file/fileUploadControl';
import Button, { buttonTypes } from 'app/components/ui/button/button';
import withModal from 'app/components/HOC/ModalHOC';
import withTooltip from 'app/components/HOC/TooltipHOC';
import {Link} from "app/components/ui/link/Link";
import OrganizationImportControl, { Scope } from "app/components/MassImports/OrganizationImportControl";
import {useUploadInit} from "app/components/MassImports/hooks/useUploadInit";
import service from "app/services/service";
import {useDispatch} from "react-redux";
import {ActionCreator} from "redux/actions/uploadProcessing/uploadProcessing";
import Autocomplete, {Services, renderItem, renderInputValue} from 'app/components/ui/autocomplete/autocomplete'
import KeyCarrierControl from "app/components/MassImports/KeyCarrierControl";
import DatePicker from 'app/components/ui/date/DatePicker/DatePicker';
import {FileErrorMessage, Type, Title} from "app/components/MassImports/enums";
import {getEnumTypeByString, validateFile, validateFiles} from "app/components/MassImports/utils";
import {v4 as uuidV4} from 'uuid';
import {formatDate} from "app/core/utility/date";
import {ImportDateFormat, OPTIONAL_FIELDS} from "app/components/MassImports/consts";
import {parseLicenseImportType} from 'app/components/MassImports/adapters';
import {Url} from "app/components/MassImports/enums";
import Img, { Image } from 'app/components/ui/Img';

import styles from './UploadModal.module.scss';

const WithModal = withModal(Container);
const TooltippedButton = withTooltip(Button);

export default function UploadModal({fileType}) {
    const dispatch = useDispatch();
    const [files, setFilesState] = useState([]);
    const [validateErrors, setValidateErrorsState] = useState([]);
    const fileControl = useRef(null);
    const [isSendButtonDisabled, setSendButtonDisabled] = useState(true);
    const [uploadPayload, setUploadPayloadState] = useState({});
    const [externalId, setExternalIdState] = useState(false);

    const [organizationControlValue, setOrganizationControlValueState] = useState(null);
    const [carrierTypeId, setCarrierTypeIdState] = useState(null);

    const [skziVersion, setSkziVersionState] = useState(null);
    const [producer, setProducerState] = useState(null);
    const [expirationDate, setExpirationDateState] = useState(null);
    const [type, setTypeState] = useState(null);

    const {
        title,
        fileApi
    } = useUploadInit(getEnumTypeByString(fileType));

    useEffect(() => {
        const producerId = producer && producer.id;

        if (producerId) {
            service('skziVersionService', 'versionByProducer', {producerId: producer.id})
                .then(res => {
                    const skziVersionSkziId = skziVersion && skziVersion.skzi && skziVersion.skzi.id;
                    const response = res && res.data;
                    const isSkziVersionNotEqual = skziVersionSkziId
                        && response
                        && !response.some(item => (item && item.skzi && item.skzi.id) === skziVersion.skzi.id);

                    if (isSkziVersionNotEqual) {
                        setSkziVersionState(null);
                    }
                });
        }
    }, [producer]);
    useEffect(() => {
        switch (fileType) {
            case Type.LICENSE:
                setUploadPayloadState({
                    SkziVersionId: skziVersion && skziVersion.version && skziVersion.version.id,
                    ExpirationDate: formatDate(expirationDate, ImportDateFormat),
                    type: parseLicenseImportType(type),
                });
                break;
            case Type.ORGANIZATION:
                setUploadPayloadState({});
                break;
            case Type.KEY_CARRIER:
                setUploadPayloadState({
                    OrganizationId: organizationControlValue && organizationControlValue.valueForRequest,
                    KeyCarrierTypeId: carrierTypeId && carrierTypeId.id
                });
                break;
            default:
                setUploadPayloadState({
                    OrganizationId: organizationControlValue && organizationControlValue.valueForRequest
                });
                break;
        }
    }, [
        organizationControlValue,
        carrierTypeId,
        skziVersion,
        producer,
        expirationDate,
        type
    ]);
    
    const isSomeControlInvalid = () => {
        const controls = Object.keys(uploadPayload)
            .filter(k => !OPTIONAL_FIELDS.includes(k))
            .map(k => uploadPayload[k]);
        return controls.some(item => Boolean((item === 0) ? item.toString() : item) === false);
    }
    
    useEffect(() => {
        if (files.length > 0) {
            const fileErrors = validateFiles(files);
            setValidateErrorsState(fileErrors);

            if (fileType !== Type.ORGANIZATION) {
                setSendButtonDisabled(isSomeControlInvalid() || (fileErrors.length > 0));
            } else {
                setSendButtonDisabled(fileErrors.length > 0);
            }
        } else {
            setSendButtonDisabled(true);
        }
    }, [uploadPayload, files]);
    useEffect(() => {
        setExternalIdState(uuidV4());
    }, []);

    const onDownloadTemplateHandler = async (evt) => {
        evt.preventDefault();
        service(
            'MassImports',
            'downloadTemplate',
            {importType: Url[getEnumTypeByString(fileType)]}
        );
    };
    const onUploadHandler = async () => {
        setSendButtonDisabled(true);
        const {current} = fileControl;
        await current._upload(null, uploadPayload);
    };
    const onChangeOrgControlHandler = (value) => setOrganizationControlValueState(value);
    const onChangeCarrierType = (value) => setCarrierTypeIdState(value);
    const uploadFinishedHandler = (response) => {
        const {errors, isError} = response.length && response[0] && response[0].axiosResult || {};

        if (!errors && !isError) {
            dispatch(ActionCreator.setFileSingleParsingId(response[0].axiosResult.data.id));
            dispatch(ActionCreator.setFileSingleParsing(true));
            dispatch(ActionCreator.setExternalIdState(externalId));
            onCancel();
        } else {
            const errorMessages = errors.map(item => item.errorMessage);
            setValidateErrorsState([...validateErrors, ...errorMessages]);
            setSendButtonDisabled(true);
            dispatch(ActionCreator.setExternalIdState(null));
        }
    };
    const onCancel = () => {
        dispatch(ActionCreator.setUploadModalState(false));
        dispatch(ActionCreator.setUploadModalFileTypeState(null));
        setSkziVersionState(null);
        setProducerState(null);
        setExpirationDateState(null);
        setTypeState(null);
        setSendButtonDisabled(true);
        setValidateErrorsState([]);
    };
    const onFileChange = (files) => {
        setValidateErrorsState([]);
        setFilesState(files);
    };
    
    const fileTypeToOrgAutocompleteScopeMapping = {
        [Type.OKZ_KEY_DOCUMENT]: Scope.Okz,
        [Type.OKZ_KEY_ESKZI]: Scope.Okz,
    };

    return <WithModal
        className={'modal-container--mass-import'}
        onCancel={onCancel}
    >
        <div className="add-file-dialog">
            <div className={`modal__header ${styles.header}`}>
                {title}
                <TooltippedButton 
                    type="image"
                    notSubmit
                    className=""
                    tooltip="Закрыть окно"
                    onClick={onCancel}
                >
                    <Img img={Image.CloseBorderless} />
                </TooltippedButton>
            </div>
            <div className="modal__content modal__content--imports">
                Выберите файл для загрузки {Title.UPLOAD_DESCRIPTION[getEnumTypeByString(fileType)]} в систему. Для корректного распознавания данных файл должен
                быть структурирован по определенному формату: <Link onClickHandler={onDownloadTemplateHandler}
                                                                    text={"Скачать шаблон"}
                                                                    isDownload
            />
            </div>
            {
                ((fileType === Type.USER_ACCOUNT)
                || ((fileType === Type.KEY_CARRIER))
                || (fileType === Type.OKI_KEY_DOCUMENT)
                || (fileType === Type.OKZ_KEY_DOCUMENT)
                || (fileType === Type.OKI_KEY_ESKZI)
                || (fileType === Type.OKZ_KEY_ESKZI))
                && <>
                    <OrganizationImportControl
                        scope={fileTypeToOrgAutocompleteScopeMapping[fileType]
                            ? fileTypeToOrgAutocompleteScopeMapping[fileType]
                            : Scope.All}
                        error={files.length > 0 && !organizationControlValue
                            ? { errorMessage: 'Выберите организацию, в которую будут загружены записи'}
                            : null }
                        onChangeHandler={onChangeOrgControlHandler}                        
                    />
                    <br/>
                </>
            }
            {
                (fileType === Type.KEY_CARRIER)
                && <>
                    <KeyCarrierControl
                        onChangeHandler={onChangeCarrierType}
                    />
                    <br/>
                </>
            }
            {
                (fileType === Type.LICENSE)
                && <div className={'license__wrap-control'}>
                    <Autocomplete
                        label='Производитель СКЗИ'
                        serviceType={Services.producers}
                        value={producer}
                        className='license__import-control add-license-dialog-data'
                        onSelect={setProducerState}
                    />
                    <Autocomplete
                        label="СКЗИ"
                        serviceType={Services.skziVersion}
                        notStandardService={{
                            serviceName: 'skziVersionService',
                            serviceMethod: 'versionByProducer',
                            data: {producerId: producer ? producer.id : ''}
                        }}
                        renderItem={renderItem.skziVersion}
                        renderInputValue={renderInputValue.skziVersion}
                        hint="СКЗИ, для которых будут загружены лицензии"
                        value={skziVersion}
                        className='license__import-control add-license-dialog-data'
                        onSelect={setSkziVersionState}
                    />
                    <Autocomplete
                        label='Тип лицензий'
                        serviceType={Services.skziLicenseType}
                        value={type}
                        className='license__import-control add-license-dialog-data'
                        onSelect={setTypeState}
                    />
                    <DatePicker
                        label="Срок действия лицензий"
                        hint="Оставьте поле пустым, если лицензии бессрочные"
                        value={expirationDate}                        
                        className='license__import-control'
                        onChange={setExpirationDateState}
                    />
                </div>
            }
            <FileUploadControl
                ref={fileControl}
                className='add-file-dialog__file-area'
                title='Добавить вложение'
                fileApi={fileApi}
                isMultiple={false}
                disabled={files.length}
                accept={'.xls, .xlsx'}
                isShowFileProgressInHeader={false}
                massImportFile
                validator={validateFile}
                onFileChanged={onFileChange}
                onUploadFinish={uploadFinishedHandler}
            />
            <div className={styles.buttonsPanel}>
                <Button
                    type={buttonTypes.primary}
                    disabled={isSendButtonDisabled}
                    caption="Загрузить"
                    onClick={onUploadHandler}
                />
                <Button
                    type={buttonTypes.text}
                    caption="Отмена"
                    onClick={onCancel}
                />
            </div>
        </div>
    </WithModal>;
};
