import React, { useEffect, useState, useRef, useMemo } from 'react';
import { formatDate } from 'app/core/utility/date';
import Checkbox from 'app/components/ui/checkbox';
import InfoPanel from 'app/components/ui/infoPanel';
import isStandardImg from 'assets/img/common/star.svg';
import { formatFIO } from 'app/core/utility/common';
import Radio from 'app/components/ui/radio';
import ExpandButton from './ExpandButton';
import { Link } from 'react-router-dom';

export const licenseKey = (item) => {
  return item ? `**${item.substr(-4)}` : '';
};

export const organizationName = (item) => {
  if (!item) return ' ';
  return item.shortName || item.name || '';
};

export const deliveryData = (item) => {
  const { delivery, deliveryOk } = item || {};
  const { data: deliveryOkData } = deliveryOk || {};
  const { data: deliveryData } = delivery || {};
  const { author, transmittalLetterDate: deliveryOkTransmittalLetterDate } =
    deliveryOkData || {};

  const { transmittalLetterDate, transmittalLetterNumber } = deliveryData || {};

  const deliveryStr = `${formatDate(transmittalLetterDate, 'dd.mm.yyyy')}${
    transmittalLetterNumber ? ` № ${transmittalLetterNumber}` : ''
  }`;
  const deliverOkStr = `, подписано электронно ${formatFIO(
    author
  )} ${formatDate(deliveryOkTransmittalLetterDate, 'dd.mm.yyyy')}`;

  return deliveryOk ? deliveryStr + deliverOkStr : deliveryStr;
};

export const eventLetter = (item = {}, documentName, noDate = false) => {
  if (!item.data) return '';
  const arr = [];
  if (!noDate) {
    item.data.transmittalLetterDate &&
      arr.push(formatDate(item.data.transmittalLetterDate, 'dd.mm.yyyy'));
  }
  item.data.transmittalLetterNumber && documentName && arr.push(documentName);
  item.data.transmittalLetterNumber &&
    arr.push(item.data.transmittalLetterNumber);
  return arr.join(' ');
};

export const serialNumbers = (serialNumbers = {}) => {
  return '1';
};

export const eventActionDate = (item = {}, item2) => {
  if (item2) {
    item = item2;
  }
  if (!item.data) return '';
  if (!item.data.actionDate) return '';
  return formatDate(item.data.actionDate, 'dd.mm.yyyy');
};

export const eventPerformer = (item = {}, item2) => {
  if (item2) {
    item = item2;
  }

  if (!item) return '';
  if (!item.data) return '';
  if (!item.data.performer && !item.data.eskziUser) return '';

  return formatFIO(item.data.performer || item.data.eskziUser || '');
};

export const destructionTeam = (destruction, withdrawal) => {
  const { data } = destruction || withdrawal || {};
  const { eskziDestructionCertificate, performer } = data || {};
  const { signers = [] } = eskziDestructionCertificate || {};
  const signersFio = signers.map((item) => formatFIO(item.user));

  return destruction
    ? eskziDestructionCertificate && signersFio && signersFio.length
      ? signersFio.join(', ')
      : formatFIO(performer)
    : formatFIO(performer);
};

export const destructionAct = (item) => {
  const { destruction, withdrawal } = item;
  // на беке автор может быть тут, а может внутри data
  const { data, author: anotherAuthor } = destruction || withdrawal || {};
  const { eskziDestructionCertificate, transmittalLetterNumber, author } =
    data || {};
  const { number } = eskziDestructionCertificate || {};
  const result = destruction
    ? eskziDestructionCertificate
      ? number
      : transmittalLetterNumber ||
        `Подписано электронно, подписал: ${formatFIO(anotherAuthor || author)}`
    : withdrawal
    ? transmittalLetterNumber ||
      `Подписано электронно, подписал: ${formatFIO(anotherAuthor || author)}`
    : '';

  return result;
};

export const eventDate = (item = {}, item2) => {
  if (item2) {
    item = item2;
  }

  if (!item.data || !item.data.transmittalLetterDate) return '';
  return formatDate(item.data.transmittalLetterDate, 'dd.mm.yyyy');
};

export const eventAddress = (item = {}) => {
  if (!item.data || !item.data.organization) return '';
  return item.data.organization.shortName || item.data.organization.name || '';
};

export const eventAddressId = (item = {}) => {
  if (!item.data || !item.data.organization) return '';
  return item.data.organization.id;
};

export const formatSKZITechnicalJournal = (
  skziItem = {},
  serialNumber = ''
) => {
  const arr = [];
  skziItem.skzi && arr.push(skziItem.skzi.name);
  skziItem.version && arr.push(skziItem.version.name);
  skziItem.build && arr.push(skziItem.build.name);
  skziItem.skziClass && arr.push(skziItem.skziClass.name);
  serialNumber && arr.push(serialNumber);

  return arr.join(' ');
};

export const eventAddressOkz = (item = {}) => {
  if (!item.data || !item.data.organization) return '';
  return item.data.organization.okz;
};

export const formatSKZI = (skziItem = {}, isStandart = false) => {
  const arr = [];
  let isStandartStr = '';
  skziItem.skzi && arr.push(skziItem.skzi.name);
  skziItem.version && arr.push(skziItem.version.name);
  skziItem.build && arr.push(skziItem.build.name);
  skziItem.skziClass && arr.push(skziItem.skziClass.name);

  if (isStandart) {
    isStandartStr = (
      <InfoPanel image={isStandardImg} className="standard">
        Эталон. Экземпляр для распространения{' '}
      </InfoPanel>
    );
  }

  return (
    <div className="skzi-name">
      <div className="skzi-title__name skzi-title__item">{arr.join(' ')}</div>
      {isStandartStr}
    </div>
  );
};

export const formatKeyDoc = (data = {}) => {
  if (!data) return '';
  const arr = [data.name];

  return (
    <div className="skzi-name">
      <div className="skzi-title__name skzi-title__item">{arr.join(' ')}</div>
    </div>
  );
};

const treeDepth = 10;

const Td = ({
  level,
  text,
  colSpan,
  format,
  selectedItems,
  onToggleTreeExpand,
  openChildrenData,
  openChildrenDataObject = {},
  openExtendData,
  type,
  openChildrenByDefault,
  tdText,
  openExtendDataItemId,
  id,
  width,
  children,
}) => {
  const _openExtendData = (event) => {
    openExtendData(id);
    event.stopPropagation();
  };

  const _openChildrenData = (event) => {
    openChildrenData(id);
    event.stopPropagation();
  };

  const _openTreeData = (event) => {
    onToggleTreeExpand(id);
    event.stopPropagation();
  };

  if (type === 'extendData') {
    if (openExtendDataItemId === id) {
      tdText = 'Скрыть';
    }

    return (
      <td className="table-row__table-data" onClick = {(e) => {
          e.preventDefault()
          _openExtendData(e)
        }
      }>
        <div className="td-content">{ ( text ) ? tdText : '' }</div>
      </td>
    );
  }

  if (type === 'childrenData') {
    // console.log(children, 'children children children');
    const childrenOpen = !openChildrenByDefault && openChildrenDataObject[id] || openChildrenByDefault && !openChildrenDataObject[id];
    return children && children.length ? (
      <td className="table-row__table-data">
        <ExpandButton
          expanded={childrenOpen}
          onClick={_openChildrenData}
        ></ExpandButton>
      </td>
    ) : (
      <td className="table-row__table-data"></td>
    );
  }

  if (type === 'treeName') {
    const expandDisabled = !(children && children.length);
    const expanded = expandDisabled
      ? false
      : selectedItems[id] && selectedItems[id].expanded;

    return (
      <td
        colSpan={colSpan}
        className={`table-row__table-data table-row--level${Math.min(
          treeDepth - 1,
          level
        )} table-row--tree-data`}
      >
        <div className="td-content">
          <ExpandButton
            expanded={expanded}
            onClick={_openTreeData}
            disabled={expandDisabled}
          ></ExpandButton>
          {text || ''}
        </div>
      </td>
    );
  }

  const style = width ? { minWidth: width, maxWidth: width } : {};
  
  return (
    <td className="table-row__table-data" style={style}>
      <div className="td-content">{text || ''}</div>
    </td>
  );
};

const ExtendDataTr = ({ item, config, colspan }) => {
  const contRef = useRef(null);
  useEffect(() => {
    contRef.current.classList.toggle('js_normal_height');
  }, []);

  if (!config[0]) return null;

  return (
    <tr className="extend_data animate zerro_height" ref={contRef}>
      <td colSpan={colspan}>
        {/* <div className="td-content"> */}
        {item[config[0].alias]}
        {/* </div> */}
      </td>
    </tr>
  );
};

const ChildrenDataTr = (props) => {
  const { config, colspan, parentSelected } = props;
  const { selectAsParent } = config;

  return config ? (
    <tr
      className={`table-body__table-row ${
        selectAsParent && parentSelected ? 'table-row--focused' : ''
      }`}
    >
      <td className="table-row__table-data" key="forParentExpand">
        <div className="td-content"></div>
      </td>
      <td className="table-row__table-data" colSpan={colspan}>
        <div className="td-child-content">{config.format(props)}</div>
      </td>
    </tr>
  ) : null;
};

const RowContent = ({
  search,
  level = 0,
  isTree,
  item,
  selectedItems,
  onToggleTreeExpand,
  openExtendData, // Клик на "подробнее"
  openExtendDataItemId, // Раскрывающаяся строка
  openChildrenDataObject, // Открытые подстроки
  openChildrenData, // Клик на развернуть данные детей
  fieldsConfig,
  blocked,
  strictWidth,
}) => {
  return (
    <>
      {isTree &&
        level !== 0 &&
        Array(Math.min(treeDepth - 1, level))
          .fill(null)
          .map((i) => (
            <td className="table-row__table-data td-content--empty-tree-cell"></td>
          ))}
      {fieldsConfig.map((el) => (
        <Td
          isTree={isTree}
          level={level}
          type={el.type}
          openChildrenByDefault = { el.openChildrenByDefault }
          selectedItems={selectedItems}
          onToggleTreeExpand={onToggleTreeExpand}
          colSpan={Math.max(0, treeDepth - level)}
          tdText={el.tdText && el.tdText()}
          openExtendDataItemId={openExtendDataItemId}
          openExtendData={openExtendData}
          openChildrenData={openChildrenData}
          openChildrenDataObject={openChildrenDataObject}
          id={item.id}
          format={el.format}
          children={item.children}
          text={el.format ? el.format(item, search) : item[el.alias]}
          key={el.alias}
          width={strictWidth ? el.width : undefined}
        />
      ))}
    </>
  );
};

const Tr = ({
  search,
  level = 0,
  isTree,
  item,
  onToggleTreeExpand, // клик на развернуть ветку дерева
  toggleSelectItem, // Клик на галку
  radioSelectItem, // Клик на радио
  openExtendData, // Клик на "подробнее"
  openExtendDataItemId, // Раскрывающаяся строка
  openChildrenDataObject, // Открытые подстроки
  openChildrenData, // Клик на развернуть данные детей
  selectedItems, // Массив айтемов с галками, для дерева объект
  fieldsConfig,
  blocked,
  clickOnItem, // Клик на айтем - переход в карточку редактирования.
  isShow,
  itemsSelectable, // Отрисовывать или нет чекбоксы
  itemsSelectableRadio, // Отрисовывать или нет радио
  strictWidth,
}) => {
  const link = useMemo(() => {
    return clickOnItem && item ? clickOnItem(item) : null;
  }, []);

  if (isShow === false) return null;

  const classNameArr = ['table-body__table-row', 'table-row--row-action'];

  const isSelected = isTree
    ? selectedItems[item.id] && selectedItems[item.id].selected
    : selectedItems.indexOf(item.id) !== -1;

  if (isSelected) {
    classNameArr.push('table-row--focused');
  }

  const _onClick = () => {
    clickOnItem(item);
  };

  const _toggleSelectItem = (event) => {
    toggleSelectItem(item.id);
  };

  const _radioSelectItem = () => {
    radioSelectItem(item.id);
  };
  
  const childrenDataFieldConfig = fieldsConfig.find( el => el.type === 'childrenData');
  const { openChildrenByDefault } = childrenDataFieldConfig || {};

  return (
    <>
    {/* FIXME Invalid markup: tbody cannot contain anchors as children */}
      {link ? <Link to={link} className={`table-row__a ${classNameArr.join(' ')} `}>
        {itemsSelectableRadio && (
          <td className="table-row__table-check">
            <div className="td-content">
              <Radio
                key={'selectItem'}
                className={``}
                value={item.id}
                checkedValue={selectedItems[0]}
                onChange={_radioSelectItem}
              />
            </div>
          </td>
        )}
        {itemsSelectable && (
          <td className="table-row__table-check" key="checkbox">
            <div className="td-content">
              <Checkbox onChange={_toggleSelectItem} checked={isSelected} />
            </div>
          </td>
        )}
        <RowContent
          search={search}
          level={level}
          isTree={isTree}
          item={item}
          onToggleTreeExpand={onToggleTreeExpand}
          selectedItems={selectedItems}
          openExtendData={openExtendData}
          openExtendDataItemId={openExtendDataItemId}
          openChildrenDataObject={openChildrenDataObject}
          openChildrenData={openChildrenData}
          fieldsConfig={fieldsConfig}
          blocked={blocked}
          strictWidth={strictWidth}
        />
        {/* </tr> */}
      </Link> :
      <tr className={`${classNameArr.join(' ')} `}>
        {itemsSelectableRadio && (
          <td className="table-row__table-check">
            <div className="td-content">
              <Radio
                key={'selectItem'}
                className={``}
                value={item.id}
                checkedValue={selectedItems[0]}
                onChange={_radioSelectItem}
              />
            </div>
          </td>
        )}
        {itemsSelectable && (
          <td className="table-row__table-check" key="checkbox">
            <div className="td-content">
              <Checkbox onChange={_toggleSelectItem} checked={isSelected} />
            </div>
          </td>
        )}
        <RowContent
          search={search}
          level={level}
          isTree={isTree}
          item={item}
          onToggleTreeExpand={onToggleTreeExpand}
          selectedItems={selectedItems}
          openExtendData={openExtendData}
          openExtendDataItemId={openExtendDataItemId}
          openChildrenDataObject={openChildrenDataObject}
          openChildrenData={openChildrenData}
          fieldsConfig={fieldsConfig}
          blocked={blocked}
          strictWidth={strictWidth}
        />
      </tr>}
      {openExtendDataItemId === item.id && (
        <ExtendDataTr
          config={fieldsConfig.filter((el) => el.type === 'extendData')}
          item={item}
          colspan={fieldsConfig.length + 1}
        />
      )}
      { !isTree && (!openChildrenByDefault && openChildrenDataObject[item.id] || openChildrenByDefault && !openChildrenDataObject[item.id]) && item.children && item.children.length 
        ? (item.children.map(item => (
              <ChildrenDataTr 
                config={childrenDataFieldConfig}
                item={item} 
                colspan={(fieldsConfig.length)}
                parentSelected={isSelected}
            />
          )))
        : null}
      {isTree &&
      item.children &&
      item.children.length &&
      selectedItems[item.id] &&
      selectedItems[item.id].expanded
        ? item.children.map((item, i) => (
            <Tr
              search={search}
              isTree={isTree}
              level={level + 1}
              parentId={item.id}
              isShow={true}
              clickOnItem={clickOnItem}
              key={item.id || item.number}
              item={item}
              toggleSelectItem={toggleSelectItem}
              radioSelectItem={radioSelectItem}
              onToggleTreeExpand={onToggleTreeExpand}
              openChildrenData={openChildrenData}
              openExtendData={openExtendData}
              openExtendDataItemId={openExtendDataItemId}
              openChildrenDataObject={openChildrenDataObject}
              selectedItems={selectedItems}
              fieldsConfig={fieldsConfig}
              blocked={blocked}
              itemsSelectable={itemsSelectable}
              itemsSelectableRadio={itemsSelectableRadio}
            />
          ))
        : null}
    </>
  );
};

export default ({
  search,
  isTree,
  list,
  selectedItems,
  toggleSelectItem,
  radioSelectItem,
  fieldsConfig,
  clickOnItem,
  itemsSelectable,
  itemsSelectableRadio,
  onToggleTreeExpand,
  strictWidth,
}) => {
  const [openExtendDataItemId, setOpenExtendDataItemId] = useState(null);
  const [openChildrenDataObject, setOpenChildrenData] = useState({});

  const openExtendData = (id) => {
    id = id === openExtendDataItemId ? null : id;
    setOpenExtendDataItemId(id);
  };

  const openChildrenData = (id) => {
    if (openChildrenDataObject[id]) {
      delete openChildrenDataObject[id];
    } else {
      openChildrenDataObject[id] = id;
    }
    setOpenChildrenData({ ...openChildrenDataObject });
  };

  const blocked = fieldsConfig.find((item) => item.type === 'treeBlocked');

  return (
    <tbody className="table__table-body">
      {list.map((item, i) => (
        <Tr
          search={search}
          isTree={isTree}
          isShow={true}
          clickOnItem={clickOnItem}
          key={item.id || item.number}
          item={item}
          toggleSelectItem={toggleSelectItem}
          radioSelectItem={radioSelectItem}
          onToggleTreeExpand={onToggleTreeExpand}
          openChildrenData={openChildrenData}
          openExtendData={openExtendData}
          openExtendDataItemId={openExtendDataItemId}
          openChildrenDataObject={openChildrenDataObject}
          selectedItems={selectedItems}
          fieldsConfig={fieldsConfig}
          blocked={blocked}
          itemsSelectable={itemsSelectable}
          itemsSelectableRadio={itemsSelectableRadio}
          strictWidth={!!i ? undefined : strictWidth}
        />
      ))}
    </tbody>
  );
};
