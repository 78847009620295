import React from 'react'
import Button from 'app/components/ui/button/button'

const CardAction = (props) => {
  const { CustomComponent, ImageComponent, actionKey, onClick, dowloadUrl, customActionProps, disabled = false } = props

  const onButtonClick = () => onClick(actionKey)

  if (CustomComponent) {
    return (
      <CustomComponent
        onClick={onButtonClick}
        disabled={disabled}
        {...customActionProps}
      />
    )
  }

  return (
    dowloadUrl ? (
      <a href={dowloadUrl} download>
        <Button 
          type='image'
          onClick={null}
          disabled={disabled}
        >
          <ImageComponent className='button-image'></ImageComponent>
        </Button>
      </a>
    ) : (
      <Button 
        type='image'
        onClick={onButtonClick}
        disabled={disabled}
        {...customActionProps}
      >
        <ImageComponent className='button-image'></ImageComponent>
      </Button>
    )
  )
}

export default CardAction