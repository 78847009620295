import React from 'react'
import { ORG_CARD_TYPE, OKI_CARD_TYPE, USER_ACCOUNT_CARD_TYPE } from 'app/components/ui/constants';
import ListLink from 'app/components/ui/ListLink';
import { formatSKZI, organizationName } from 'app/components/list/Table/TableBody';
import { formatFIO } from 'app/core/utility/common';
import { formatDate } from 'app/core/utility/date';
import { FilterControlType } from 'app/components/filter/FilterEllement';
import { blockedColumn } from 'app/components/ui/obsoleteTable/columns';

export const OkiConfig = {
  fieldsConfig : [
    { 
      alias : 'bizLocked',
      format : blockedColumn,
      visibleOnStart : true, 
      visibleAlways : true,
      width : 50 
    },
    { 
      title : '№',
      titleInfo : '№', 
      alias : 'id',
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 50 
    },
    { 
      title : 'Наименование',
      alias : 'skzi', 
      format : item => formatSKZI( item.skzi, item.isStandard === true ) ,
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true
    },
    { 
      title : 'Регистрационный №', 
      alias : 'serialNumber', 
      format : item => item.serialNumber ,
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 250
    },
    { 
      title : 'Получено от', 
      alias : 'receivedFrom', 
      format : item => item.receivedFrom ,
      visibleOnStart : true, 
      userSorting : true, 
      width : 350
    },
    { 
      title : 'Статус', 
      alias : 'status', 
      format : item => item.status && item.status.name,
      visibleOnStart : true, 
      userSorting : true,
      width : 220
    },
    { 
      title : 'Выдано', 
      alias : 'eskziUser', 
      format : item => {
        return item.eskziUser
          ? <ListLink
            text={formatFIO(item.eskziUser)}
            targetType={USER_ACCOUNT_CARD_TYPE}
            id={item.eskziUser.id}
          />
          : formatFIO(item.eskziUser)},
      visibleOnStart : true, 
      userSorting : true,
      width : 300
    },
    { 
      title : 'Дата изменения', 
      alias : 'updateDate', 
      visibleOnStart : true, 
      userSorting : true, 
      format : item =>( 
        <span className='skzi-expire-date'>{ formatDate( item.updateDate, 'dd.mm.yyyy' ) }</span>
        ),
        width: 200
    },
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'id', 
      label : '№',
      pattern: '^[0-9]*$',
      extended : false,
      order : 2
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'serialNumbers', 
      label : 'Регистрационный N',
      extended : false,
      order : 3,
      requestFormatIsArray : true,
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'receivedFrom', 
      label : 'Получено от',
      extended : true,
      order : 7,
    },   
    {
      type : FilterControlType.autocompletePicker, 
      alias : 'eskziUserId', 
      label : 'Выдано', 
      serviceType : 'skziUser',  
      multiSelect : false,
      extended : true,
      order : 8,
    },
    { 
      type : FilterControlType.dateRangePicker, 
      alias : 'registerDate', 
      label : 'Зарегистрирован',
      extended : true,
      order : 11,
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'producerId', 
      label : 'Производитель', 
      serviceType : 'producers',  
      multiSelect : false,
      extended : true,
      order : 5,
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'skziId', 
      label : 'Продукт', 
      serviceType : 'skzi',
      dependsOn: 'producerId',
      hardDepends: true,
      multiSelect : false,
      extended : true,
      order : 6
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'skziVersionId', 
      label : 'Версия', 
      serviceType : 'skziVersion',
      dependsOn: 'skziId',
      hardDepends: true,
      dependantDisabled: true,
      multiSelect : false,
      extended : true,
      order : 9
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'skziBuildId',
      label : 'Сборка / Модификация', 
      serviceType : 'skziBuild',
      dependsOn: 'skziVersionId',
      hardDepends: true,
      dependantDisabled: true,
      multiSelect : false,
      extended : true,
      order : 10
    },
    {
      type : FilterControlType.multiselect, 
      alias : 'statuses', 
      label : 'Статус', 
      serviceType : 'instanceStatuses',
      extended : true,
      order : 13
    },
    {
      type : FilterControlType.dropDown, 
      alias : 'hasLicenses', 
      label : 'Наличие лицензий', 
      extended : true,
      order : 14,
      options: [
        { id: 'true', title: 'С лицензиями'},
        { id: 'false', title: 'Без лицензий'}
      ]
    },
    { 
      type : FilterControlType.checkbox, 
      alias : 'onlyStandard', 
      label : 'Эталонные экземпляры',
      extended : true,
      order : 15,
    },
  ]
}

export const OkzConfig = {
  fieldsConfig : [
    { 
      alias : 'bizLocked',
      format : blockedColumn,
      visibleOnStart : true, 
      visibleAlways : true,
      width : 50 
    },
    { 
      title : '№',
      titleInfo : '№', 
      alias : 'id',
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 50 
    },
    { 
      title : 'Наименование',
      alias : 'skzi', 
      format : item => formatSKZI( item.skzi, item.isStandard === true ) ,
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true,
    },
    { 
      title : 'Регистрационный №', 
      alias : 'serialNumber', 
      format : item => item.serialNumber ,
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 250
    },
    { 
      title : 'Получено от', 
      alias : 'receivedFrom', 
      format : item => item.receivedFrom ,
      visibleOnStart : true, 
      userSorting : true, 
      width : 350
    },
    { 
      title : 'Статус', 
      alias : 'status', 
      format : item => item.status && item.status.name,
      visibleOnStart : true, 
      userSorting : true,
      width : 220
    },
    { 
      title : 'Передано в', 
      alias : 'sentTo', 
      format : item => {
        const name = organizationName( item.sentTo )
        const { id, okz } = item.sentTo || {}
        const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE
        return name && id ? <ListLink
          text={name}
          targetType={targetType}
          id={id}
        /> : name || ''
      },
      visibleOnStart : true, 
      userSorting : true, 
      width : 300
    },

    { 
      title : 'Дата изменения', 
      alias : 'updateDate', 
      visibleOnStart : true, 
      userSorting : true, 
      format : item =>( 
        <span className='skzi-expire-date'>{ formatDate( item.updateDate, 'dd.mm.yyyy' ) }</span>
        ),
        width: 200
    },
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'id', 
      label : '№',
      pattern: '^[0-9]*$',
      extended : false,
      order : 2
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'serialNumbers', 
      label : 'Регистрационный N',
      extended : false,
      order : 3,
      requestFormatIsArray : true,
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'receivedFrom', 
      label : 'Получено от',
      extended : true,
      order : 7,
    },   
    {
      type : FilterControlType.autocomplete, 
      alias : 'sentTo', 
      label : 'Передано в', 
      serviceType : 'organizationServed',  
      multiSelect : false,
      extended : true,
      order : 8,
    },
    { 
      type : FilterControlType.dateRangePicker, 
      alias : 'registerDate', 
      label : 'Зарегистрирован',
      extended : true,
      order : 11,
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'producerId', 
      label : 'Производитель', 
      serviceType : 'producers',  
      multiSelect : false,
      extended : true,
      order : 5,
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'skziId', 
      label : 'Продукт', 
      serviceType : 'skzi',
      dependsOn: 'producerId',
      hardDepends: true,
      multiSelect : false,
      extended : true,
      order : 6
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'skziVersionId', 
      label : 'Версия', 
      serviceType : 'skziVersion',
      dependsOn: 'skziId',
      hardDepends: true,
      dependantDisabled: true,
      multiSelect : false,
      extended : true,
      order : 9
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'skziBuildId',
      label : 'Сборка / Модификация', 
      serviceType : 'skziBuild',
      dependsOn: 'skziVersionId',
      hardDepends: true,
      dependantDisabled: true,
      multiSelect : false,
      extended : true,
      order : 10
    },
    {
      type : FilterControlType.multiselect, 
      alias : 'statuses', 
      label : 'Статус', 
      serviceType : 'instanceStatuses',
      extended : true,
      order : 13
    },
    {
      type : FilterControlType.dropDown, 
      alias : 'hasLicenses', 
      label : 'Наличие лицензий', 
      extended : true,
      order : 14,
      options: [
        { id: 'true', title: 'С лицензиями'},
        { id: 'false', title: 'Без лицензий'}
      ]
    },
    { 
      type : FilterControlType.checkbox, 
      alias : 'onlyStandard', 
      label : 'Эталонные экземпляры',
      extended : true,
      order : 15,
    },
  ]
}

export const ExternalOrgConfig = {
  fieldsConfig : [
    {
      alias : 'bizLocked',
      format : blockedColumn,
      visibleOnStart : true, 
      visibleAlways : true,
      width : 50 
    },
    { 
      title : '№',
      titleInfo : '№', 
      alias : 'id',
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 50 
    },
    { 
      title : 'Наименование',
      alias : 'skzi', 
      format : item => formatSKZI( item.skzi, item.isStandard === true ) ,
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 350
    },
    { 
      title : 'Регистрационный №', 
      alias : 'serialNumber', 
      format : item => item.serialNumber ,
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 250
    },
    { 
      title : 'Получено от', 
      alias : 'receivedFrom', 
      format : item => item.receivedFrom ,
      visibleOnStart : true, 
      userSorting : true, 
      width : 350
    },
    { 
      title : 'Статус', 
      alias : 'status', 
      format : item => item.status && item.status.name,
      visibleOnStart : true, 
      userSorting : true,
      width : 220
    },
    { 
      title : 'Дата изменения', 
      alias : 'updateDate', 
      visibleOnStart : true, 
      userSorting : true, 
      format : item =>( 
        <span className='skzi-expire-date'>{ formatDate( item.updateDate, 'dd.mm.yyyy' ) }</span>
        ),
        width: 200
    },
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'id', 
      label : '№',
      pattern: '^[0-9]*$',
      extended : false,
      order : 2
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'serialNumbers', 
      label : 'Регистрационный N',
      extended : false,
      order : 3,
      requestFormatIsArray : true,
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'receivedFrom', 
      label : 'Получено от',
      extended : true,
      order : 7,
    },   
    {
      type : FilterControlType.autocomplete, 
      alias : 'eskziUserId', 
      label : 'Выдано', 
      serviceType : 'skziUser',  
      multiSelect : false,
      extended : true,
      order : 8,
    },
    { 
      type : FilterControlType.dateRangePicker, 
      alias : 'registerDate', 
      label : 'Зарегистрирован',
      extended : true,
      order : 11,
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'producerId', 
      label : 'Производитель', 
      serviceType : 'producers',  
      multiSelect : false,
      extended : true,
      order : 5,
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'skziId', 
      label : 'Продукт', 
      serviceType : 'skzi',
      dependsOn: 'producerId',
      hardDepends: true,
      multiSelect : false,
      extended : true,
      order : 6
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'skziVersionId', 
      label : 'Версия', 
      serviceType : 'skziVersion',
      dependsOn: 'skziId',
      hardDepends: true,
      dependantDisabled: true,
      multiSelect : false,
      extended : true,
      order : 9
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'skziBuildId',
      label : 'Сборка / Модификация', 
      serviceType : 'skziBuild',
      dependsOn: 'skziVersionId',
      hardDepends: true,
      dependantDisabled: true,
      multiSelect : false,
      extended : true,
      order : 10
    },
    {
      type : FilterControlType.multiselect, 
      alias : 'statuses', 
      label : 'Статус', 
      serviceType : 'instanceStatuses',
      extended : true,
      order : 13
    },
    {
      type : FilterControlType.dropDown, 
      alias : 'hasLicenses', 
      label : 'Наличие лицензий', 
      extended : true,
      order : 14,
      options: [
        { id: 'true', title: 'С лицензиями'},
        { id: 'false', title: 'Без лицензий'}
      ]
    },
  ]
}