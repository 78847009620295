/** @format */

import { useState } from "react";
import Button, { buttonTypes } from "../ui/button/button";
import Img, { Image } from "../ui/Img";
import CreateForm from './createForm';

export default function CreateRequest({afterCreationCallback, title = 'Создать'}) {

  const [createOpened, setCreateOpened] = useState(false);
  const onCreateToggle = () => {
    setCreateOpened(!createOpened);
  };

  const afterCreate = () => {
    setCreateOpened(false)
    afterCreationCallback && afterCreationCallback()
  }

  return (
    <>
      <Button type={buttonTypes.primary} className="create-button" onClick={onCreateToggle}>
        <Img img={Image.Plus} />
        <span className="button-title">{title}</span>
      </Button>
      {createOpened && (
        <CreateForm 
          onCancel={onCreateToggle}
          afterCreationCallback={afterCreate}
        />
      )}
    </>
  );
}
