import React, { useEffect, useState } from 'react'
import Input from 'app/components/ui/Input'
import Button from 'app/components/ui/button/button'
import withModal from 'app/components/HOC/ModalHOC'
import { formatSKZI } from 'app/components/destructionCertificate/destructionObjectSet'
import { Table } from 'app/components/list/Table/Table'
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import { sortDirection } from 'app/components/ui/constants'
import { statuses } from 'app/components/Notification/allNotificationPage/notificationItem'
import ResultButtons from 'app/components/Notification/allNotificationPage/notificationDetail/resultButtons'

const fieldsConfig = [
  { 
    title : 'Наименование организации',
    alias : 'name', 
    format : item => {
      const { organization } = item || {}
      const { shortName, name, inn, ogrn } = organization || {}
      return `${shortName || name} (ИНН ${inn}; ОГРН/ОГРНИП ${ogrn})`
    },
    visibleOnStart : true, 
    visibleAlways : true,
    userSorting : true, 
  },
  {
    title : 'Лицензий для передачи / передано',
    alias : 'licenseToMove', 
    format : item => {
      const { licenseToMove } = item || {}
      const { moved = 0, actualMove = 0 } = licenseToMove || {}

      return (
        <Input
          label=""
          disabled={true}
          value={`${moved}/${actualMove}`}
        />
      )
    },
    visibleOnStart : true, 
    visibleAlways : true,
    userSorting : false, 
    width : 270 
  },
]

const ReplicationResult = (props) => {
  const {
    status,
    onCancel, 
    eskzi, 
    replicationItems,
    error,
    onSubmitSignEnhance,
    getSignRequestData,
    uid
  } = props

  const [commonData, setCommonData] = useState({orgCount: 0, licensesActual: 0, licensesCount: 0})

  const {
    syncFrontListSortAction,
    sortListAction,
    setSelectedAction,
    setSelectedObjects,
    sorting,
    list,
    selectedItems,
    selectedObjects,
  } = useTableExtensions({
    defaultSort: {
      column: 'name',
      direction: sortDirection.desc,
    },
  });

  useEffect(() => {
    const licenseData = replicationItems.reduce((acc, cur) => {
      const { licenses } = cur
      
      acc.orgCount++
      acc.licensesCount += licenses ? licenses.length : 0
      acc.licensesActual += licenses.filter(l => l.isSuccessful).length
      return acc
    }, {orgCount: 0, licensesActual: 0, licensesCount: 0})
    const listData = replicationItems.map(item => {
      const { organization, licenses } = item

      return {
        id: organization.entity.id,
        isSelected: organization.isSuccessful,
        organization: organization.entity,
        licenseToMove: {
          moved: licenses.length,
          actualMove: licenses.reduce((acc, cur) => {
            return acc + (cur.isSuccessful ? 1 : 0)
          }, 0)
        }
      }
    })
    const selected = listData.filter(i => i.isSelected)

    setCommonData(licenseData)
    syncFrontListSortAction( sorting, fieldsConfig, listData )
    setSelectedAction(selected.map(item => item.id))
    setSelectedObjects(selected)
  }, [replicationItems])
  
  useEffect( () => { 
    list && list.length && syncFrontListSortAction( sorting, fieldsConfig, list )
  }, [sorting]);

  const needSign = status.id === statuses.doneWithErrors

  return <div className="etalon-replicate-form etalon-replicate-form--result">
    <div className="card-material__content">
      <Input
        id="etalonId"
        className="card-field card-field--etalonId"
        label="Эталон для распространения"
        disabled={true}
        value={formatSKZI(eskzi)}
      />
    </div>
    <Table
      list={list || []}
      setSelectedAction = {()=>{}}
      selectedItems = {selectedItems}
      selectedObjects = {selectedObjects}
      sortListAction = {sortListAction}
      sorting = {sorting} 
      itemsSelectable = { true }
      fieldsConfig = {fieldsConfig}
    />
    <div className="total-info">
      <div className="total-info__item">Выбрано организаций: {commonData.orgCount}</div>  
      <div className="total-info__item">Лицензий для передачи / передано: {`${commonData.licensesCount}/${commonData.licensesActual}`}</div>
    </div>
    <ResultButtons
      callback={() => onSubmitSignEnhance(getSignRequestData({
        replicationItems,
        eskzi,
        uid
      }))}
      needSign={needSign}
      onCancel={onCancel}
    />
  </div>
}

export default withModal(ReplicationResult)