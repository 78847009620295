import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import ConfirmDialog from 'app/components/dialog/confirmDialog/'
import Button from 'app/components/ui/button/button'
import { downloadFileByLink } from 'app/core/serverRequest/request' 
import SignatureDialog, { isDigitalSignNeeded } from 'app/components/signature/SignatureDialog'
import { formatDate } from 'app/core/utility/date'

import * as selfServiceRegistryAction from 'redux/actions/selfServiceRegistry/selfServiceRegistryAction'
import * as errorAction from 'redux/actions/common'
import * as signatureActions from 'redux/actions/common/signatureAction'

class downoadBuildButton extends Component {
  
_getInstallSkziData = (formData) => {
  const { buildId } = formData || {}

  return { 
    skziBuildId: buildId,
  }
}

 _getSignatureRequestData = (currentConclusion) => {
  const { id, issueDate } = currentConclusion

  return { 
    eskziReservationId: id, 
    actionDate: formatDate(issueDate, 'yyyy-mm-dd')
  }
}

 _finishDownload = async (props, eskziData, needBuildUpdate = true) => {
  const { 
    selfServiceRegistryResetAction,
    afterDowloadAction,
    inProgressAction
  } = props
  const { payload } = eskziData || {}
  const { skzi: skziData } = payload || {}
  const { build, skzi, version } = skziData || {}
  const { id: buildId } = build || {}
  const { id: skziId } = skzi || {}
  const { id: versionId } = version || {}

  downloadFileByLink(`skzi/${skziId}/version/${versionId}/build/${buildId}/file/all`)
  await selfServiceRegistryResetAction()
  
  inProgressAction(false)
  needBuildUpdate && afterDowloadAction && afterDowloadAction({skziId, versionId })
}

 _afterSignAction = async () => {
  const { 
    selfServiceRegistry,
    signature,
    inProgressAction,
    selfServiceRegistrySkziInstallAction
  } = this.props
  const { textForSignature, signatureResult } = signature
  const { currentConclusion } = selfServiceRegistry
  
  const signatureToServer = {
    data: this._getSignatureRequestData(currentConclusion),
    signature: { data: textForSignature, signedData: signatureResult }
  }

  inProgressAction(true)
  const createResult = await selfServiceRegistrySkziInstallAction(signatureToServer)
  this._finishDownload(this.props, createResult)
}

 _onSignDialog = (props, currentConclusion) => {
  const { 
    selfServiceRegistry,
    setRequestData, 
    generateText, 
    inProgressAction
  } = props

  const signTextRequest = {
    ...this._getSignatureRequestData(currentConclusion),
    signatureRequestType: 'InstallEskzi'
  }

  setRequestData({...currentConclusion});
  inProgressAction(true)
  generateText(signTextRequest);
}

 _registerWithoutSign = async (props, currentConclusion) => {
  const { 
    inProgressAction,
    selfServiceRegistrySkziInstallAction,
  } = props

  const requestData = {
    data: this._getSignatureRequestData(currentConclusion),
    signature: null
  }

  inProgressAction(true)
  const createResult = await selfServiceRegistrySkziInstallAction(requestData)
  this._finishDownload(props, createResult)
}

 _onGetBuild = async (conclusionResult) => {
  const { 
    profile,
    inProgressAction,
  } = this.props
  const { payload } = conclusionResult
  const { id, eskzi } = payload || {}

  // some error happened
  if (!id) {
    inProgressAction(false)
    return
  }

  // already has conclusion
  if (eskzi) {
    this._finishDownload(this.props, {payload: {skzi: eskzi}}, false)
    return
  }

  if (isDigitalSignNeeded(profile, 'InstallEskzi')) {
    this._onSignDialog(this.props, payload)
  } else {
    this._registerWithoutSign(this.props, payload)
  }
}

_onCreateConclusion = async (formData) => {
  const { 
    selfServiceRegistryCreateConclusionAction,
    inProgressAction
  } = this.props

  inProgressAction(true)
  const result = await selfServiceRegistryCreateConclusionAction(
          this._getInstallSkziData(formData)
        )
        
  await this._onGetBuild(result)
}

_onGetBuildSubmit = async (formData) => {
  const { 
    selfServiceRegistryCreateConclusionAction,
    inProgressAction
  } = this.props
  const { buildId } = formData

  if(!buildId) {
    return
  }

  inProgressAction(true)
  const result = await selfServiceRegistryCreateConclusionAction(
          this._getInstallSkziData(formData)
        )
        
  await this._onGetBuild(result)
}

  render() {
    const { 
      className,
      clearErrorAction,
      lastBuild, 
      downloadText,
      versionId, 
      signature,
      error,
      profile,
      resetSignature
    } = this.props
    const { textForSignature } = signature || {}
    const { errorObject } = error || {}

    return (
      <>
        <Button 
          className={className || ''}
          type='primary'
          onClick={() => this._onGetBuildSubmit({buildId: lastBuild ? lastBuild.id : null, versionId: versionId})}
        >
          <span className='button-title'>{downloadText}</span>
        </Button>
        {textForSignature ? (
          <SignatureDialog
            className={'signature-form'}
            profile={profile}
            onCancel={resetSignature}
            afterSignCallback={this._afterSignAction}
            renderHeader = {() => {
              return (
                <div className='sign__header'>
                  {'Параметры установки экземпляра СКЗИ'}
                </div>
              )
            }}
        />) :null}
        {errorObject ?
          <ConfirmDialog
            {...errorObject}
            onCancel={() => {
              resetSignature()
              clearErrorAction()
            }}
          /> : null
        }
      </>
    )
  }
}


export default connect(
  state => ({ ...state }),
  {
    ...errorAction,
    ...selfServiceRegistryAction,
    ...signatureActions
  }
)(downoadBuildButton)