import { sortDirection } from 'app/components/ui/constants'
import { formatDate } from 'app/core/utility/date'

export const defaultSettings = {
  columns: [
    {
      title: '№',
      alias: 'id',
      settings: {
        useSorting: true,
        width: 240
      }
    },
    {
      title: 'Лицензионный ключ',
      alias: 'key',
      settings: {
        useSorting: true,
      },
      renderTemplate: item => {
        const { key } = item
  
        return `** ${key.slice(-4)}`
      }
    },
    {
      title: 'Свободно',
      alias: 'vacantAmount',
      settings: {
        useSorting: true,
        width: 240
      },
      renderTemplate: item => { 
        if( item.vacantAmount === 0 ) return '0';
        return item.vacantAmount || 'Бесконечно'
      },
    },
    {
      title: 'Срок действия',
      alias: 'expirationDate',
      settings: {
        useSorting: true,
        width: 240
      },
      renderTemplate: item => item.expirationDate ? formatDate( item.expirationDate, 'dd.mm.yyyy' ) : 'Бессрочно', 
    }, 
  ],
  sort: { 
    column: 'id',
    direction: sortDirection.desc
  }
} 