import React from 'react'

import Breadcrumbs from 'app/components/breadcrumbs/breadcrumbs'
import { useHistory } from 'react-router-dom'

export default function CardPageHeader(props) {
  const history = useHistory()

  return (
    <div className='breadcrumbs-header'>
      <Breadcrumbs
        {...props}
        history={history}
      >
      </Breadcrumbs>
    </div>
  )
}