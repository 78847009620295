import React, { useCallback, useState, useRef, useEffect } from 'react'
import { useIsVisible } from './hooks/useIsVisible'
import { ReactComponent as LoadingImg }  from 'assets/img/commonVer2/ic_loading.svg'
import globalConfig from 'app/core/config';
import useToast from "../ui/toast/useToast";
import { excludeByItemId, updateCollectionByAnotherCollection, updateCollectionByItemId } from 'app/core/utility/common';
import TotalCount from '../list/Table/TotalCount';

export default function withEndlessScrollHOC(PassedComponent){
  const EndlessScrollHOC = (props) => {
    const { 
      pageSize = globalConfig.paginationPerPage, 
      dependencies = [], 
      loadData,
      changedRows,
      selectedItems,
      selectedObjects,
      setSelectedAction,
      setSelectedObjects,
      mapResultFunc,
      excludeItems,
      onListChanged,
      onFetchingData
    } = props
    const [list, setList] = useState([])
    const [addedItems, setAdded] = useState([])
    const [currentPage, setCurrentPage ] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [actualTotalCount, setActualTotalCount] = useState(0)
    const [allFetched, setAllFetched ] = useState(false)
    const [fetchingData, setFetchingData ] = useState(false)
    const {addToast} = useToast()
    const triggerRef = useRef(null);
    const initialMount = useRef(true)
    const isOnScreen = useIsVisible({ref:triggerRef})

    useEffect(() => {
      onListChanged && onListChanged(list)
    }, [list])

    const onLoadData = async (currentPage, list, addedItems) => {
      setFetchingData(true)
      const result = await loadData({page: currentPage, pageSize})
      setFetchingData(false)
      const { data = [], dataCount = 0, isError, errors = [] } = result || {}
      const { detail } = errors[0] || {}
      const { response } = detail || {}
      const { errors: detailErrors = [] } = response || {}
      const error = detailErrors[0] || errors[0]
      setTotalCount(dataCount)
      if ( isError && error ) {
        error.errorMessage && addToast(error.errorMessage)
        setAllFetched(true)
      } else {
        // иногда надо убрать из ответа апи что-то
        const excluded = excludeItems ? excludeByItemId(data, excludeItems) : data 
        // иногда надо ответ от апи как-то изменить
        const mapped = mapResultFunc ? excluded.map(mapResultFunc) : excluded
        // вычитаем из ответа сервера то,
        // что пользователь только что добавил
        setList([...list, ...excludeByItemId(mapped, addedItems)])

        if ( dataCount <= pageSize * currentPage) 
        {
          setAllFetched(true)
        }
      }
    }
    
    useEffect(() => {
      if (isOnScreen && !fetchingData && !allFetched) {
        const nextPage = currentPage + 1
        setCurrentPage(nextPage)
        onLoadData(nextPage, list, addedItems)
      }
    }, [isOnScreen])

    // обновляем список при изменении\добавлении\удалении записей
    useEffect(() => {
      const {
        deletedFile,
        addedFile,
        updatedFile,
        deleted,
        added,
        updated
      } = changedRows || {}

      if ( deleted ) {
        const delObj = deleted.map(i => ({id: i}))
        const res = excludeByItemId(list, delObj)
        setList(res)
        setSelectedObjects && setSelectedObjects(excludeByItemId(selectedObjects, delObj))
        setTotalCount(totalCount - deleted.length)

        // to do
        // доделать setSelectedAction - наверно легче перейти на selectedObjects и забыть про selectedItems
        setSelectedAction && setSelectedAction([])
      } else if ( added ) {
        // доделать selected после добавления - выключить чек-бокс "выбраны все",
        setAdded([...addedItems, ...added])
        setList([...added, ...list])
        setTotalCount(totalCount + added.length)
      } else if ( updated ) {
        if (Array.isArray(updated)){
          setList(updateCollectionByAnotherCollection(list, updated))
        } else {
          setList(updateCollectionByItemId(list, updated))
        }
      /// TODO Refactor leaky abstraction below (endless scroll component should not handle specific row content)
      } else if ( addedFile || deletedFile || updatedFile ) {
        const { id, fileResults, guid, ...updatedFields } = addedFile || deletedFile || updatedFile || {}
        const affectedRow = list.find(i => i.id === id)
        if (affectedRow) {
          const { files = [] } = affectedRow
          const updatedRow = {
            ...affectedRow,
            files: deletedFile
                   ? files.filter(f => f.guid !== guid)
                   : updatedFile
                   ? files.map(f => f.guid === guid ? { ...f, ...updatedFields } : f)
                   : [...fileResults, ...files]
          }
          setList(updateCollectionByItemId(list, updatedRow))          
          setSelectedObjects && setSelectedObjects(selectedObjects.map(so => so.id === id ? updatedRow : so));
        }
      }
    }, [changedRows])
    // сбрасываем если уже загруженный список стал неактуальным
    useEffect(() => {
      if (initialMount.current === true) {
        initialMount.current = false
      } else {
        setAllFetched(false)
        setCurrentPage(1)
        setList([])
        setAdded([])
        onLoadData(1, [], [])
      }
    }, [...dependencies])

    useEffect(() => {
      onFetchingData && onFetchingData(fetchingData)
    }, [fetchingData])

    useEffect(() => {
      setActualTotalCount(Math.max(totalCount - (excludeItems ? excludeItems.length : 0), 0))
    }, [totalCount])

    return (
      <>
        {/* по идее этот компонент тут не должен быть,
          но из-за верстки не получилось в таблицу добавить */}
          <TotalCount
            totalCount={actualTotalCount}
          />
        <div className="endless-scroll-wrapper">
          <PassedComponent
            {...props}
            // to do
            // пока две таблицы в проекте будут два пропса
            items={list || []}
            list={list || []}
            dataCount={totalCount}
            inProgress={fetchingData}
          />
          {fetchingData && 
            <div className="endless-scroll-wrapper__loading" >
              <LoadingImg/>
            </div>
          }
          <div 
            ref={triggerRef} 
            className="endless-scroll-wrapper__trigger">
          </div>
        </div>
      </>
    )
  }
  
  return EndlessScrollHOC;
}