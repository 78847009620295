/** @format */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ButtonComponent, { buttonTypes } from 'app/components/ui/button/button';
import Img, { Image } from 'app/components/ui/Img';
import ConfirmDialog, { dialogType } from 'app/components/dialog/confirmDialog';
import Overlay from 'app/components/ui/overlay';
import { dispatchLogic } from 'redux/actions/common';
import service from 'app/services/service';
import { formatDate } from 'app/core/utility/date';
import styles from './style.module.scss';
import Autocomplete from 'app/components/ui/autocomplete/autocomplete';
import useToast from 'app/components/ui/toast/useToast';
import withTooltip from 'app/components/HOC/TooltipHOC';
import DatePicker from 'app/components/ui/date/DatePicker';

const Button = withTooltip(ButtonComponent);

export default function CertificateSuspension({
  afterActionCallback,
  item,
  disabled,
  CAUser = false,
}) {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const [inProgress, setInProgress] = useState(false);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [unholdAction, setUnholdAction] = useState(null);
  const [unholdDate, setUnholdDate] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [revocationDate, setRevocationDate] = useState(null);
  const onDialogToggle = () => {
    setDialogOpened(!dialogOpened);

    if (!dialogOpened) {
      setUnholdDate(null);
      setUnholdAction(null);
      setRevocationDate(null);
      setValidationError(null);
    }
  };

  const afterAction = () => {
    setDialogOpened(false);
    addToast('Запрос на приостановление создан');
    afterActionCallback && afterActionCallback();
  };

  useEffect(() => {
    const error =
      unholdDate && revocationDate && unholdDate < revocationDate
        ? {
            errorMessage: 'Должна быть позже даты начала приостановки',
            isError: true,
          }
        : null;

    setValidationError(error);
  }, [unholdDate, revocationDate]);

  const suspendCert = async () => {
    setInProgress(true);
    const response = await service(
      'certificateAuthorityCertificateService',
      CAUser ? 'caSuspension' : 'suspension',
      {
        certificateSn: item?.id,
        unholdAction: unholdAction?.id,
        revocationDate: formatDate(revocationDate, 'yyyy-mm-dd'),
        unholdDate: formatDate(unholdDate, 'yyyy-mm-dd'),
      }
    );
    const { isError, errors } = response || {};
    !isError && afterAction();
    isError && dispatchLogic(dispatch, 'MOCK', null, isError, errors);
    setInProgress(false);
  };

  const submitDisabled = validationError || !unholdDate || !unholdAction;

  return (
    <>
      {inProgress && <Overlay />}
      <Button
        type={buttonTypes.image}
        onClick={onDialogToggle}
        disabled={disabled || item?.status?.id !== 'Valid'}
        tooltip="Приостановить"
      >
        <Img img={Image.Pause} />
      </Button>
      {dialogOpened && (
        <ConfirmDialog
          header={`Приостановление сертификата`}
          type={dialogType.confirm}
          submitText="Приостановить"
          submitDisabled={submitDisabled}
          onSubmit={suspendCert}
          onCancel={onDialogToggle}
        >
          <DatePicker
            className={styles.date}
            label="Приостановить до"
            value={unholdDate}
            onChange={setUnholdDate}
            minDate={new Date(Date.now() + 24 * 60 * 60 * 1000)}
            error={validationError}
          />
          <div className={styles.unholdAction}>
            <Autocomplete
              label="Действие после окончания срока приостановки"
              notStandardService={{
                serviceName: 'certificateAuthorityCertificateService',
                serviceMethod: 'unholdActions',
              }}
              onSelect={setUnholdAction}
              value={unholdAction}
            />
          </div>
          <DatePicker
            className={styles.date}
            label="Дата начала приостановки"
            value={revocationDate}
            onChange={setRevocationDate}
            minDate={new Date(Date.now() + 24 * 60 * 60 * 1000)}
          />
        </ConfirmDialog>
      )}
    </>
  );
}

