import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {addToast} from '../../redux/actions/toast'
import {clearErrorAction} from '../../redux/actions/common'
import {errorTypes} from '../../redux/reducers/errorReducer'

export default function useErrors(){
  const errorObject = useSelector(({error: {errorObject}}) => errorObject)
  const dispatch = useDispatch()
  useEffect(() => {
    const title = errorObject && errorObject.title
    const type = errorObject && errorObject.realType
    if(title && type !== errorTypes.warning) {
      dispatch(addToast(title))
      dispatch(clearErrorAction())
    }
  }, [errorObject])
}