import React, { useEffect, useState } from 'react'
import SimpleSearch from 'app/components/ui/search/SimpleSearch'
import TableComponent from 'app/components/ui/obsoleteTable/table';
import Tag from 'app/components/ui/tag'
import Button from 'app/components/ui/button/button'
import service from 'app/services/service'
import { deleteArrayItemById, arrayToIdHashMap, excludeByItemId, intersectByItemId } from 'app/core/utility/common'
import { defaultSettings as organizationsSettings } from './entities/organizations'
import { defaultSettings as courseSettings } from './entities/trainingCourses'
import { defaultSettings as objectTypeSettings } from './entities/objectTypes'
import { defaultSettings as skziUserSettings } from './entities/skziUsers'
import { defaultSettings as userAccount } from './entities/userAccount'
import { defaultSettings as skziLicense } from './entities/skziLicense'
import { defaultSettings as eskzi } from './entities/eskzi'
import { defaultSettings as keyCarrier } from './entities/keyCarrier'
import { defaultSettings as keyDocument } from './entities/keyDocument'
import { defaultSettings as okz } from './entities/okzTree'
import { defaultSettings } from './entities/default'
import withEndlessScroll from 'app/components/HOC/EndlessScrollHOC'
import useListFilter from 'app/components/list/Filters/useListFilter'
import Filter from 'app/components/filter';
import './picker.scss';

const Table = withEndlessScroll(TableComponent)

export const Services = {
  instanceStatuses: 'instanceStatusesService',
  objectTypes: 'objectTypesService',
  skziTypes: 'skziTypesService',
  skziInstance: 'skziInstanceService',
  organizations: 'organizationService',
  skzi: 'skziService',
  skziBuild: 'skziBuildService',
  user: 'userService',
  skziUser: 'skziUserService',
  skziClass: 'skziClassService',
  keyCarrierType: 'keyCarrierTypeService',
  keyCarrier: 'keyCarrierService',
  keyDocument: 'keyDocumentService',
  userRoles: 'userRolesService',
  trainingCourses: 'courseService',
  skziLicenses: 'licenseService',
  okzTree: 'okzTreeService',
  signer: 'signerService',
}

export const SelectType = {
  single: 'singleSelect',
  multiple: 'multipleSelect'
}

export const getDefaultSettings = (serviceType, tableColumns) => {

  if (tableColumns) {
    return tableColumns
  }

  switch (serviceType) {
    case Services.organizations:
      return organizationsSettings
    case Services.objectTypes:
      return objectTypeSettings
    case Services.trainingCourses:
      return courseSettings
    case Services.skziUser:
    case Services.signer:
      return userAccount
    case Services.user:
      return skziUserSettings
    case Services.skziLicenses:
      return skziLicense
    case Services.skziInstance:
      return eskzi
    case Services.keyCarrier:
      return keyCarrier
    case Services.keyDocument:
      return keyDocument
    case Services.okzTree:
      return okz
    default:
      return defaultSettings
  }
}

const applyBtnEnabled = (alreadySelected, selectedItems) => {
  let enabled = true

  if (!(alreadySelected && alreadySelected.length)) {
    enabled = selectedItems ? selectedItems.length : false
  } else {
    const intersection = intersectByItemId(selectedItems,alreadySelected)
    enabled = !(selectedItems.length === alreadySelected.length
                && intersection.length === alreadySelected.length)
  }

  return enabled
}

export default function Picker (props) {

  const { 
    className, 
    error, 
    isModal, 
    onCancel, 
    selectType,
    serviceType,
    disabledItems, 
    needBasket = true, 
    renderTagName,
    filterConfig,
    tableColumns,
    onSelect,
    notStandardService,
    excludeItems,
    alreadySelected,
    searchPlaceholder = 'Поиск'
  } = props

  const {
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    dropFiltersAction,
    setVisibleFieldsAction,
    toggleVisibleFieldAction,
    filterState,
    filterForRequest,
    fieldsVisible,
    filterFilled
  } = useListFilter()

  const { columns, sort: defaultSort } = getDefaultSettings(serviceType, tableColumns)
  const [ sort, setSort ] = useState(defaultSort)
  const [ search, setSearch ] = useState('')
  const [ inProgress, setInProgress ] = useState(false)
  const [ actualSearch, setActualSearch ] = useState('')
  const [ selected, setSelected ] = useState({
    items: [],
    selectedHashMap: {}
  })
  const { items: selectedItems } = selected
  const disabledRows = arrayToIdHashMap(disabledItems)

  useEffect(() => {
    if (alreadySelected && alreadySelected.length) {
      setSelected({
        items: alreadySelected,
        selectedHashMap: arrayToIdHashMap(alreadySelected) 
      })
    }
  }, [])

  const getTableOptions = () => {
    const { column, direction } = sort
  
    return {
      sortable: {
        defaultColumn: column,
        defaultDirection: direction,
        onChange: (name, direction) => setSort({column: name, direction})
      },
      [selectType]: { onCheck: setSelected},
      useActions: { use: false }
    }
  }

  const removeBasketItem = (name, item) => {
    const { items } = selected
    const newSelected = deleteArrayItemById(items, item)
    
    setSelected({
      items: newSelected,
      selectedHashMap: arrayToIdHashMap(newSelected)
    })
  }

  const onApply = () => {
    const { items } = selected
    onSelect(items)
  }
  
  const { serviceName, serviceMethod, data = {}} = notStandardService || {}
  const dataService = notStandardService
                      ? serviceName && serviceMethod
                        ? ({page, pageSize}) => service(serviceName,  serviceMethod, { term: search, page, pageSize, ...sort, ...data, filterData:filterForRequest})
                        : notStandardService
                      : ({page, pageSize}) => service(serviceType, 'picker', { term: search, page, pageSize, ...sort}) 

  return (
    <div className={`picker`}>
      {filterConfig ? (
        <Filter
          className={className}
          inProgress = { inProgress }
          setFilterStateAction = { setFilterStateAction }
          setFilterForRequestAction = { setFilterForRequestAction }
          setFullFilterStateAction = { setFullFilterStateAction }
          filterState = { filterState }
          filtersConfig = { filterConfig }
          isModalFilter
        />
      ) : (<SimpleSearch
        placeholder={searchPlaceholder}
        value={search}
        onSearch={setActualSearch}
        onChange={setSearch}
        error={error}
        disabled={inProgress}
      />)}
      <Table
        className="picker__table"
        loadData = {dataService}
        mapResultFunc = {i => ({
          ...i,
          tableComponentRowDisabled: !!disabledRows[i.id]
        })}
        dependencies = {[sort, filterForRequest, actualSearch]}
        columns={columns}
        selected={selected}
        excludeItems={excludeItems}
        options={getTableOptions()}
        onFetchingData={setInProgress}
        noBorders
      />
      {selectType === SelectType.multiple && needBasket ? (
        <div className='picker__basket'>
          {selectedItems.map((item, i) => <Tag key={i} text={renderTagName ? renderTagName(item) : item.shortName || item.name} item={item} onDelete={removeBasketItem}/>)}
        </div>
      ) : null}
      {isModal ? (
        <div className="dialog__footer">
          <Button
            className='footer__item'
            type = 'primary'
            onClick = {onApply}
            disabled={!applyBtnEnabled(alreadySelected, selectedItems)}
          >
            <span className='button-title'>Выбрать</span>
          </Button>
          <Button
            className='footer__item'
            type = 'secondary'
            onClick = {onCancel}
          >
            <span className='button-title'>Отменить</span>
          </Button>
        </div>
      ) : null}
    </div>
  )
}