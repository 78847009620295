import React from 'react'

import { sortDirection } from 'app/components/ui/constants'

import InfoPanel from 'app/components/ui/infoPanel'
import isOkzImg from 'assets/img/common/key.svg'

export const defaultSettings = {
  columns: [
    {
      title: 'Наименование',
      alias: 'name',
      settings: {
        useSorting: true,
        //width: 600
      },
      renderTemplate: item => {
        const { shortName, name, okz, kpp, inn, ogrn } = item
  
        return ( 
          <div className={`organization-name-picker-value `}>
            <div className={`organization-name`}>
              <span className="full-name">{name}</span>
              {shortName ? (
                <span className="short-name">{shortName}</span>
              ) : null}
              <div className="organization-name__atributes atributes">
                {inn ? (
                  <span className="atributes__value">ИНН: {inn}</span>
                ) : null}
                {kpp ? (
                  <span className="atributes__value">КПП: {kpp}</span>
                ) : null}
                {ogrn ? (
                  <span className="atributes__value">ОГРН/ОГРНИП: {ogrn}</span>
                ) : null}
              </div>
            </div>
            {okz ? (
              <div className="tooltip-place">
                <InfoPanel
                  image={isOkzImg}
                  className = 'okz'
                >
                  Наличие Органа криптографической защиты
                </InfoPanel>
              </div>
            ) : <div className='okz'></div>}
          </div>
        ) 
      }
    },
    {
      title: 'Код',
      alias: 'code',
      settings: {
        useSorting: true,
      }
    },
  ],
  sort: { 
    column: 'name',
    direction: sortDirection.asc
  }
} 