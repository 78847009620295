import React, { useCallback } from 'react'
import Group from 'app/components/ui/managePanel/group';
import {DownloadButton} from "app/components/list/TopPanel/DownloadButton";
import {Delete} from "app/components/list/TopPanel/Delete";
import { stringPlural } from 'app/core/utility/common';
import service from 'app/services/service';
import Img from 'app/components/ui/Img/imgMap';
import Button from 'app/components/ui/button/button';
import { useHistory } from 'react-router-dom';

export default function ListManagePanel({
  filterForRequest,
  sorting,
  fieldsVisible,
  globalOrgId,
  items,
  afterAction,
  setSelectedAction,
  onInProgress,
  rights,
  inProgress
}) {

  const history = useHistory()
  const deleteItems = useCallback(async (list) => {
    onInProgress(true)
    const response = await service('userService', 'deleteRange', list) 
    onInProgress(false)
    return response
  } , [])

  return (
    <>
      {rights.CREATE ? (
        <Group>
          <Button
            className='create-button'
            type='primary'
            disabled={inProgress}
            onClick={() => history.push( `/accessManagement/users/create`)}
          >
            <Img.Plus className='button-image button-image--left'></Img.Plus>
            <span className='button-title'>Добавить</span>
          </Button>
        </Group>
      ) : null}
      <Group>
        <DownloadButton
          listFilterForRequest = {{...filterForRequest}}
          sorting = {sorting}
          listFieldsVisibility ={fieldsVisible}
          selected = {items.map(i => i.id)}
          titul="Выгрузить в Excel"
          type="DownloadButton"
          inProgress = { inProgress }
          serviceName = 'userService'
        />
      </Group>
      {rights.DELETE ? (
        <Group>
          <Delete
            items = {items} 
            dialog ={data => {
              const arr = ['выбранную учетную запись', 'выбранные учетные записи', 'выбранные учетные записи']
              return `Вы уверены, что хотите удалить ${stringPlural(data.length, arr)}?`
            }}
            multiply = {true}
            afterAction = { afterAction }
            deleteItemsAction = {deleteItems}
            setSelectedAction = {setSelectedAction}
            onInProgress = { onInProgress }
          />
        </Group>
      ) : null}
    </>
  )
}
