import React, { useState } from 'react'
import { connect } from 'react-redux'

import MainCard, {objectKey} from 'app/components/mainCard/cardComponent'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Tabs from 'app/components/ui/tabs/tabs'
import Overlay from 'app/components/ui/overlay'
import {cardLines} from './createLines'
import { RESOURCES } from 'app/core/auth/resourceByPage'

import * as mainCardAction from 'redux/actions/card/cardActions'
import * as eskziCardAction  from 'redux/actions/skziInstance/eskziCardAction'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import { formUpdate } from './card'
import { CardState } from 'app/components/ui/card/cardMaterial'
import { CARDAFTERCREATE } from 'redux/actions/actionTypes'
import withCheckLicenses from "../../HOC/withCheckLicenses";
import { useHistory } from 'react-router-dom'
import { formatDate } from 'app/core/utility/date'

export const getEskziKey = (formData) => {
  const { 
    serialNumbers = [], 
    receivedFrom, 
    organizationType = {},
    transmittalLetterDate,
    comment
  } = formData
  const { skziVersionId } = formData
  const { build } = skziVersionId || {}
  const { id: buildId } = build || {}

  return {
    ...formData, 
    receivedFrom: receivedFrom, 
    serialNumbers: serialNumbers.map(
      item => ({
        id: item.id,
        number: formData[item.id]
      })
    ),
    skziBuildId: buildId, 
    organizationType: organizationType,
    transmittalLetterDate: formatDate(transmittalLetterDate, 'yyyy-mm-dd'),
    comment: comment
  }
}

export const cardConfig = ({ 
    history,
    registerEskzi,
    initCreateEskziCard,
    breadcrumbsPushAction,
    breadcrumbsPopAction,
  }) => ({
  cardLines: cardLines,
  resource: RESOURCES.eskzi,
  getKey: data => objectKey(data, getEskziKey),
  customFormChangeHandler: formUpdate,
  signatureHeaderText: 'Добавление экземпляра',
  signatureServiceName: '',
  oldSignatureService: true,
  signatureEntity: 'RegisterEskzi',
  updateAction: registerEskzi,
  cardInitAction: initCreateEskziCard,
  getSignatureData: getEskziKey,
  onCancel: () => {history.goBack()},
  afterUpdate: (requestResult) => {
    const { payload, type } = requestResult || {}
    const { id, okz } = payload || {}

    if (type === CARDAFTERCREATE) {
      breadcrumbsPopAction()  
      history.push(`/${okz ? 'skziAccounting' : 'skziOrganization'}/EskziCard/${id}`)
    }
  },
  pageBreadcrumbTitle: () => {
    const { location } = history || {}
    const { pathname } = location || {}

    breadcrumbsPushAction(pathname)
  }
})

const EskziCard = (props) => {
  const { 
    registerEskzi,
    initCreateEskziCard,
    breadcrumbsPopAction,
    breadcrumbsPushAction,
    inProgress,
  } = props

  const history = useHistory();
  const { location } = history;
  const [ activeTab, setActiveTab ] = useState('Информация');

  return (
    <section className={`main-card eskzi-card eskzi-card--create`}>
      {inProgress ? <Overlay /> : null}
      <BreadcrumbsHeader />
      <Tabs 
        activeTab={activeTab} 
        onTabClick={(title) => setActiveTab(title)}
      >
        <section tabTitle={'Информация'} className="main-card__data">
          <ExpandingBlock
            renderHeader= {() => 'Реквизиты'}
            initialState={true}
          >
            <MainCard
              {...props}
              location={location}
              cardMode={CardState.create}
              editOnOpen={true}
              config={cardConfig({
                history,       
                initCreateEskziCard,
                registerEskzi,
                breadcrumbsPopAction,
                breadcrumbsPushAction,
              })}
            >
            </MainCard>
          </ExpandingBlock>
        </section>
        <div tabTitle={'Лицензии'} disabled={true}></div>
        <div tabTitle={'Мероприятия'} disabled={true}></div>
      </Tabs>
    </section>
  )
}

export default connect(
  state =>({
    ...state.card, 
    profile: state.profile,
    licenseType: "eskzi"
  }),
  { 
    ...mainCardAction,
    ...eskziCardAction,
    ...breadcrumbsActions
  })(withCheckLicenses(EskziCard))
