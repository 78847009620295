import React from 'react'
import Input from 'app/components/ui/Input'
import { notEmpty } from 'app/core/utility/validation'
import { CardState } from 'app/components/ui/card/cardMaterial'
import DatePicker from 'app/components/ui/date'

export const cardLines = {
  id: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field catalog-card__id'
          label = '№'
          value={editValue || 'Черновик'}
          readOnly={cardState === CardState.view}
          disabled={cardState !== CardState.view}
        />
      )
    },
  },
  createDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <DatePicker
          className='card-field catalog-card__createDate'
          label = 'Дата создания'
          error={error}
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState !== CardState.view}
        />
      )
    },
  },
  updateDate: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <DatePicker
          className='card-field catalog-card__updateDate'
          label = 'Дата изменения'
          error={error}
          value={editValue}
          readOnly={cardState === CardState.view}
          disabled={cardState !== CardState.view}
        />
      )
    },
  },
  name: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field catalog-card__name'
          label='Наименование'
          value={editValue}
          readOnly={cardState === CardState.view}
          onChange={value => onChange && onChange('name', value)}
          error={error}
        />
      )
    },
  },
  description: {
    renderEdit: (props) => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input 
          className='card-field catalog-card__description'
          label='Комментарий'
          value={editValue}
          readOnly={cardState === CardState.view}
          onChange={value => onChange && onChange('description', value)}
          error={error}
        />
      )
    },
  },
}