import React from "react";
import {useEffect, useState} from "react";
import {sortDirection} from "../../ui/constants";
import {useDispatch, useSelector} from "react-redux";
import service from "../../../services/service";
import {dispatchLogic} from "../../../../redux/actions/common";
import withTooltip from 'app/components/HOC/TooltipHOC'
import TagComponent from 'app/components/ui/tag'
import {formatDate} from "../../../core/utility/date";
const Tag = withTooltip(TagComponent)


export default function useCardCertificationBlock({ skziId, versionId, buildId }){

  const [sort, setSort] = useState({column: 'main', direction: sortDirection.desc})
  const { column, direction } = sort
  const buildColumns = useCertificationBlockConfig({ skziId, versionId, buildId })

  const getTableOptions = () => {
    return {
      sortable: {
        defaultColumn: column,
        defaultDirection: direction,
        onChange: (name, direction) => onSort(name, direction)
      },
      useActions: {use: false},
    }
  }

  const onSort = (column, direction) => {
    setSort({ column, direction })
  }


  return {
    sort,
    buildColumns,
    getTableOptions,
  }
}

function useCertificationBlockConfig({ skziId, versionId, buildId }){
  const urlPart = `api/v1/skzi/${skziId}/version/${versionId}/build/${buildId}/certificates`
  return [
    {
      title: 'Тип',
      alias: 'type',
      settings: {
        useSorting: true,
        width: 150
      },
      renderTemplate: item => {
        const {type} = item

        return type.name
      }
    },
    {
      title: 'Номер',
      alias: 'number',
      settings: {
        useSorting: true,
        width: 300
      },
      renderTemplate: item => item.number
    },
    {
      title: 'Начало действия',
      alias: 'certificateStartDate',
      settings: {
        useSorting: true,
        width: 300
      },
      renderTemplate: item => formatDate( item.certificateStartDate, 'dd.mm.yyyy' )
    },
    {
      title: 'Окончание действия',
      alias: 'certificateExpirationDate',
      settings: {
        useSorting: true,
        width: 300
      },
      renderTemplate: item => item.certificateExpirationDate ? formatDate( item.certificateExpirationDate, 'dd.mm.yyyy' ) : 'Бессрочно'
    },
    {
      title: 'Вложения',
      alias: 'documents',
      settings: {
        useSorting: false,
      },
      isCustomRender: true,
      renderTemplate: rowData => {
        const { files } = rowData
        return (
          (
            <div className="type-files">
              {files.map((item) => {
                   return (
                    <Tag
                      key={item.guid}
                      text={item.name}
                      tooltip={item.name}
                      link={`${urlPart}/certificate/${rowData.id}/file/${item.guid}`}
                      item={item}
                    />
                  )
                }
              )}
            </div>
          )
        )
      }
    },
  ]
}