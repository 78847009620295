import React from 'react'
import service from 'app/services/service'
import { useHistory } from "react-router-dom";
import { ReactComponent as DeleteImg } from 'assets/img/commonVer2/ic_link_off.svg'
import { ReactComponent as AddImg } from 'assets/img/commonVer2/ic_link_add.svg'
import Overlay from 'app/components/ui/overlay'
import SimpleSearch from 'app/components/ui/search/SimpleSearch';
import Button from 'app/components/ui/button/button';
import withTooltip from 'app/components/HOC/TooltipHOC';
import ExpandingBlock from 'app/components/ui/expandingBlock'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'app/components/list/Table/Table'
import Picker,  { SelectType, Services } from 'app/components/ui/picker/picker';
import withModal from 'app/components/HOC/ModalHOC';
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { getResourceRights } from 'app/core/auth/auth'
import Marked from 'app/components/ui/marked'
import ConfirmDialog, { dialogType } from 'app/components/dialog/confirmDialog/';
import useLinkedOrg from './useLinkedOrg';
import { sortDirection } from 'app/components/ui/constants';

const ModalPicker = withModal(Picker)
const WithTooltipButton = withTooltip(Button)

export const okzTableConfig = {
  pageConfig : { 
    itemsPerPage : 9999999,
    defaultSortByAlias : 'id',
    defaultSortDirection : sortDirection.desc,
    filterMultiSelect : false,
    changedFieldsVisibility : false,
    itemsSelectable : true,
    itemsSelectableRadio : false,      
  },
  fieldsConfig : [
    { 
      title : '№',
      titleInfo : '№', 
      alias : 'id',
      sortByValue : item => Number(item.id),
      visibleOnStart : true, 
      userSorting : true, 
      width : 50
    },
    { 
      title : 'Наименование',
      alias : 'name', 
      sortByValue : item => item.shortName || item.name,
      format : (item, search) => <Marked text={item.name} search={search}/>,
      visibleOnStart : true, 
      userSorting : true, 
    },
    { 
      title : 'Код',
      alias : 'code', 
      sortByValue : item => item.code,
      format : item => item.code,
      visibleOnStart : true, 
      userSorting : true, 
      width : 150
    },
    {
      title: 'ИНН',
      alias: 'inn',
      sortByValue : item => Number(item.inn || 0),
      format : item => item.inn,
      visibleOnStart : true, 
      userSorting : true, 
      width : 150 
    },
    {
      title: 'КПП',
      alias: 'kpp',
      sortByValue : item => Number(item.kpp || 0),
      format : item => item.kpp,
      visibleOnStart : true, 
      userSorting : true, 
      width : 150 
    },
    {
      title: 'ОГРН/ОГРНИП',
      alias: 'ogrn',
      sortByValue : item => Number(item.ogrn || 0),
      format : item => item.ogrn,
      visibleOnStart : true, 
      userSorting : true, 
      width : 200 
    },

  ],
}

const deleteSelected = async (data, isOkz) => {
  return await service('okzTreeService', isOkz ? 'deleteServedOkz':'deleteServedOrganizations', data)
}

const addServed = async (data, isOkz) => {
  return await service('okzTreeService', isOkz ? 'addServedOkz' : 'addServedOrganization', data)
}

const clearError = (dispatch) => {
  dispatch({type: 'CLEAR.ERROR'})
}

export default function OkzChildren({
  id,
  list: propsList,
  isOkz = true,
  onUpdate
}) {
  const globalDispatch = useDispatch()
  const history = useHistory()
  const profile = useSelector(({profile}) => profile)
  const schemeRights = getResourceRights(profile, RESOURCES.okzScheme)

  const {
    onAdd,
    onDelete,
    setSearch,
    search,
    showPicker, 
    setShowPicker,
    searchResult, 
    setSearchResult,
    errorToShow,
    sortListAction,
    sortListSetAction,
    setSelectedAction,
    inProgress,
    sorting,
    selectedItems,
    list,
    paginator,
    totalCount
  } = useLinkedOrg({
    id,
    isOkz,
    list: propsList, 
    add: addServed, 
    deleteSelected,
    onUpdate
  })

  const pickerService = {
    serviceName: 'okzTreeService',
    serviceMethod: isOkz ? 'servicePicker' : 'servedPicker',
    data: {}
  }

  const total = search ? searchResult : totalCount
  const removeLinkDisabled = !!inProgress 
                              || !selectedItems.length
                              || selectedItems.some(i => i.bizLocked)

  return (
    <section className="okz-sheme-card__served-okz">
      {inProgress ? <Overlay /> : null}
      <ExpandingBlock
        renderHeader= {() => isOkz ? 'Нижестоящие ОКЗ' : 'Обладатели конфиденциальной информации'}
        initialState={true}
      >
        <div className="manage-panel">
          <div className="manage-panel__buttons">
            {schemeRights.UPDATE && 
              <Button
                className='create-button'
                type='primary'
                disabled={!!inProgress}
                onClick={() => setShowPicker(true)}
              >
                <AddImg className='button-image button-image--left'></AddImg>
                <span className='button-title'>Добавить</span>
              </Button>
            }
            {schemeRights.UPDATE &&
              <div className="buttons-element">
                <WithTooltipButton 
                  className='delete-button'
                  tooltip='Отвязать'
                  type='image'
                  onClick={onDelete}
                  disabled={removeLinkDisabled}
                >
                  <DeleteImg className='button-image'></DeleteImg>
                </WithTooltipButton>
              </div>
            }
          </div>
          <div className="manage-panel__filter">
            <SimpleSearch
              className='filter-item'
              placeholder=''
              label='Наименование'
              value={search}
              onChange={setSearch}
              disabled={inProgress}
              noHint
            />
          </div>
        </div>
        <Table
          totalCount={total}
          search={search}
          searchAlias={'name'}
          setSearchResult={setSearchResult}
          sortListAction={sortListAction} 
          sortListSetAction={sortListSetAction}
          paginator={paginator}
          sorting={sorting}
          selectedItems={selectedItems}
          setSelectedAction={setSelectedAction} 
          inProgress={inProgress}
          list={list}
          fieldsConfig = { okzTableConfig.fieldsConfig }
          itemsSelectable = { true }
          itemsSelectableRadio = { okzTableConfig.pageConfig.itemsSelectableRadio }
          defaultSortByAlias = { okzTableConfig.pageConfig.defaultSortByAlias } 
          changedFieldsVisibility = { okzTableConfig.pageConfig.changedFieldsVisibility === true }
          onItemClick = { item => { 
            return item.okz ? `/catalog/okzScheme/card/${item.id}` : `/catalog/organization/card/${item.id}`
          } }
          headerInfo = { okzTableConfig.pageConfig.headerInfo === true }
        />  
      </ExpandingBlock>
      {showPicker &&
        <ModalPicker
          serviceType={isOkz ? Services.okzTree : Services.organizations}
          disabledItems={list}
          excludeItems={[{ id:id}]}
          notStandardService={pickerService}
          selectType={SelectType.multiple}
          onCancel={()=>setShowPicker(false)} 
          onSelect={onAdd}
          renderHeader = {() => {
            return (
              <div className='modal__header'>
                {isOkz ? 'Добавление нижестоящих ОКЗ' : 'Добавление организаций'}
              </div>
            )
          }}
        />
      }
    {errorToShow ? 
      <ConfirmDialog
        needIcon={true}
        type={dialogType.warning}
        error={errorToShow}
        onCancel={() => clearError(globalDispatch)}
      /> : null
    }
    </section>
  )
}
