/** @format */

import React from 'react';
import { connect } from 'react-redux';

import MainCard, { cardMode, objectKey } from 'app/components/mainCard/cardComponent';
import ExpandingBlock from 'app/components/ui/expandingBlock';
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader';
import { cardLines } from '../card/cardLines';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import * as cardAction from 'redux/actions/catalog/cardActions';
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction';
import { CARDAFTERCREATE } from 'redux/actions/actionTypes';

export const cardConfig = ({
  history,
  createSkziTypeAction,
  initCatalogEntityCreate,
  breadcrumbsPopAction,
  breadcrumbsPushAction,
}) => ({
  cardLines: cardLines,
  getKey: data => objectKey(data, catalogEntityData),
  resource: RESOURCES.skziType,
  updateAction: createSkziTypeAction,
  cardInitAction: initCatalogEntityCreate,
  getSignatureData: catalogEntityData,
  onCancel: () => {
    history.push('/catalog/skziTypes');
  },
  afterUpdate: requestResult => {
    const { payload, type } = requestResult || {};
    const { id } = payload || {};

    if (type === CARDAFTERCREATE) {
      breadcrumbsPopAction();
      history.push(`/catalog/skziTypes/card/${id}`);
    }
  },
  pageBreadcrumbTitle: () => {
    const { location } = history || {};
    const { pathname } = location || {};

    breadcrumbsPushAction(pathname);
  },
});

const CatalogCreateCard = props => {
  const {
    catalogType,
    createSkziTypeAction,
    initCatalogEntityCreate,
    breadcrumbsPopAction,
    breadcrumbsPushAction,
    history,
  } = props;

  return (
    <section className="main-card catalog-card">
      <BreadcrumbsHeader />
      <section className="main-card__data">
        <ExpandingBlock renderHeader={() => 'Реквизиты'} initialState={true}>
          <MainCard
            {...props}
            cardMode={cardMode.create}
            editOnOpen={true}
            config={cardConfig({
              catalogType,
              history,
              createSkziTypeAction,
              initCatalogEntityCreate,
              breadcrumbsPopAction,
              breadcrumbsPushAction,
            })}
          ></MainCard>
        </ExpandingBlock>
      </section>
    </section>
  );
};

export default connect(state => ({ ...state.card, profile: state.profile }), {
  ...cardAction,
  ...breadcrumbsActions,
})(CatalogCreateCard);

export const catalogEntityData = data => {
  const { name, description } = data || {};

  return {
    name,
    description,
  };
};
