import { 
  BREADCRUMBS,
  BREADCRUMBSCLICK,
  BREADCRUMBSPUSHITEM,
  BREADCRUMBSPOPITEM,
  BREADCRUMBS_CLEAR
 } from '../actionTypes'

import { flattenMenuContent } from 'app/navigation/navigationContent'

const compileFirstBreadcrumbs = path => {

  const bc = path.split('/').filter(item => !!item).reduce((acc, cur) => {
    const pathPart = '/' + cur;
    const prevPart = acc[acc.length-1];

    return [...acc, `${prevPart ? prevPart : ''}${pathPart}`]
  }, [])

  return bc
          .filter(item=>!!flattenMenuContent[item])
          .map((item) => ({ ...flattenMenuContent[item] }))
}

const getPageBreadcrumb = (path, title) => {
  let bcData = flattenMenuContent[path]
  const { title: bcTitle, link, headerTitle } = bcData || {}
  const currentBreadcrumb = bcData 
                            ? {
                                title: bcTitle,
                                link: link,
                                headerTitle: headerTitle,
                                disabled: false,
                                breadcrumbsTitle: true,
                              }
                            : {
                                title,
                                link: path,
                                headerTitle: title,
                                disabled: false,
                                breadcrumbsTitle: true,
                              }

  const compiled = compileFirstBreadcrumbs(path)
  const alreadyIn = compiled.find(item => {
    return item.link === currentBreadcrumb.link 
  })

  return {
    firstBreadcrumbs: alreadyIn || !title ? compiled : [ ...compiled, currentBreadcrumb ],
    current: currentBreadcrumb
  }
}

export const breadcrumbsAction = ({ componentPart }) => dispatch => {
  dispatch({
      type: BREADCRUMBS,
      payload: {
        componentPart: componentPart
      }
  })
}

export const breadcrumbsClickAction = (item) => dispatch => {
  dispatch({
      type: BREADCRUMBSCLICK,
      payload: item
  })
}

export const breadcrumbsPushAction = (path, title, forceUpdate = false) => dispatch => {
  dispatch({
      type: BREADCRUMBSPUSHITEM,
      payload: { 
        ...getPageBreadcrumb(path, title),
        forceUpdate
      }
  })
}

export const breadcrumbsPopAction = () => dispatch => {
  dispatch({
      type: BREADCRUMBSPOPITEM,
      payload: null
  })
}

export const breadcrumbsClearAction = () => dispatch => {
  return dispatch({
      type: BREADCRUMBS_CLEAR,
      payload: null
  })
}