import React from 'react'
import { ORG_CARD_TYPE, OKI_CARD_TYPE, USER_ACCOUNT_CARD_TYPE } from 'app/components/ui/constants';
import ListLink from 'app/components/ui/ListLink';
import { formatSKZI, organizationName } from 'app/components/list/Table/TableBody';
import { licenseKey } from 'app/components/list/Table/TableBody' 
import { formatDate } from 'app/core/utility/date';
import { FilterControlType } from 'app/components/filter/FilterEllement';
import { blockedColumn } from 'app/components/ui/obsoleteTable/columns';
import { RESOURCES } from 'app/core/auth/resourceByPage';

export const catalogType = {
  keyCarrierType: 'keyCarrierType',
  skziTypes: 'skziTypes',
  objectTypes: 'objectTypes',
  exemplarStatuses: 'exemplarStatuses',
};

export const getSettings = type => {
  switch (type) {
    case catalogType.keyCarrierType:
      return {
        resource: RESOURCES.keyCarrierType,
        service: 'keyCarrierTypeService',
        createLink: '/catalog/keyCarrierType/create',
        onRowClick: item => `/catalog/keyCarrierType/card/${item.id}`,
        title: 'Справочники: Типы ключевых носителей',
        config: keyCarrierConfig,
      };
    case catalogType.objectTypes:
      return {
        resource: RESOURCES.objectType,
        service: 'objectTypesService',
        createLink: '/catalog/objectTypes/create',
        onRowClick: item => `/catalog/objectTypes/card/${item.id}`,
        title: 'Справочники: Элементы комплекта СКЗИ',
        config: objectTypeConfig
      };
    case catalogType.skziTypes:
      return {
        resource: RESOURCES.skziType,
        service: 'skziTypesService',
        createLink: '/catalog/skziTypes/create',
        onRowClick: item => `/catalog/skziTypes/card/${item.id}`,
        title: 'Справочники: Типы СКЗИ',
        config: skziTypeConfig
      };
    case catalogType.exemplarStatuses:
      return {
        resource: RESOURCES.eskziStatus,
        service: 'exemplarStatusesService',
        createLink: '/catalog/exemplarStatuses/create',
        onRowClick: null,
        title: 'Справочники: Статусы экземпляров',
        config: exemplarStatusesConfig
      };     
  }
};

export const keyCarrierConfig = {
  fieldsConfig : [
    { 
      title : '№',
      alias : 'id',
      format : item => item.id,
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 50
    },
    { 
      title : 'Наименование',
      alias : 'name',
      format : item => item.name,
      visibleOnStart : true, 
      userSorting : true,
    },      
    { 
      title : 'Дата создания',
      alias : 'createDate', 
      format : item => formatDate( item.createDate, 'dd.mm.yyyy' ), 
      visibleOnStart : true, 
      userSorting : true, 
      width : 200
    },  
    { 
      title : 'Дата изменения',
      alias : 'updateDate', 
      format : item => formatDate( item.updateDate, 'dd.mm.yyyy' ), 
      visibleOnStart : true, 
      userSorting : true, 
      width : 200
    },  
  
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'id', 
      label : '№',
      pattern: '^[0-9]*$',
      extended : false,
      order : 2
    },
    { 
      type : FilterControlType.simpleSearch,
      alias : 'names', 
      label : 'Наименование',
      extended : false,
      requestFormatIsArray: true,
      order : 3,
    },
  ]
}

export const objectTypeConfig = {
  fieldsConfig : [
    { 
      title : '№',
      alias : 'id',
      format : item => item.id,
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 50
    },
    { 
      title : 'Наименование',
      alias : 'name',
      format : item => item.name,
      visibleOnStart : true, 
      userSorting : true,
    },      
    { 
      title : 'Дата создания',
      alias : 'createDate', 
      format : item => formatDate( item.createDate, 'dd.mm.yyyy' ), 
      visibleOnStart : true, 
      userSorting : true, 
      width : 200
    },  
    { 
      title : 'Дата изменения',
      alias : 'updateDate', 
      format : item => formatDate( item.updateDate, 'dd.mm.yyyy' ), 
      visibleOnStart : true, 
      userSorting : true, 
      width : 200
    },  
  
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'id', 
      label : '№',
      pattern: '^[0-9]*$',
      extended : false,
      order : 2
    },
    { 
      type : FilterControlType.simpleSearch,
      alias : 'names', 
      label : 'Наименование',
      extended : false,
      requestFormatIsArray: true,
      order : 3,
    },
  ]
}

export const skziTypeConfig = {
  fieldsConfig : [
    { 
      title : '№',
      alias : 'id',
      format : item => item.id,
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 50
    },
    { 
      title : 'Наименование',
      alias : 'name',
      format : item => item.name,
      visibleOnStart : true, 
      userSorting : true,
    },      
    { 
      title : 'Дата создания',
      alias : 'createDate', 
      format : item => formatDate( item.createDate, 'dd.mm.yyyy' ), 
      visibleOnStart : true, 
      userSorting : true, 
      width : 200
    },  
    { 
      title : 'Дата изменения',
      alias : 'updateDate', 
      format : item => formatDate( item.updateDate, 'dd.mm.yyyy' ), 
      visibleOnStart : true, 
      userSorting : true, 
      width : 200
    },  
  
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'id', 
      label : '№',
      pattern: '^[0-9]*$',
      extended : false,
      order : 2
    },
    { 
      type : FilterControlType.simpleSearch,
      alias : 'names', 
      label : 'Наименование',
      extended : false,
      requestFormatIsArray: true,
      order : 3,
    },
  ]
}

export const exemplarStatusesConfig = {
  fieldsConfig : [
    { 
      title : '№',
      alias : 'id',
      format : item => item.id,
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 50
    },
    { 
      title : 'Наименование',
      alias : 'name',
      format : item => item.name,
      visibleOnStart : true, 
      userSorting : true,
    },      
    { 
      title : 'Дата создания',
      alias : 'createDate', 
      format : item => formatDate( item.createDate, 'dd.mm.yyyy' ), 
      visibleOnStart : true, 
      userSorting : true, 
      width : 200
    },  
  ],
}