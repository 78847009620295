import React from 'react'

import { sortDirection } from 'app/components/ui/constants'
import { renderItem } from 'app/components/ui/autocomplete/autocomplete'
import { formatFIO } from 'app/core/utility/common'
import {getEskziName} from "../../../skzi/constants";

export const defaultSettings = {
  columns: [
    {
      title: '№',
      alias: 'id',
      settings: {
        useSorting: true,
      },
      renderTemplate: item => {
        return item.id
      }
    },
    {
      title: 'СКЗИ',
      alias: 'skzi',
      settings: {
        useSorting: true,
        width: 500
      },
      renderTemplate: item => {
        return item && getEskziName(item.skzi)
      }
    },
    {
      title: 'Серийный №',
      alias: 'serialNumber',
      settings: {
        useSorting: true,
        width: 500
      },
      renderTemplate: item => {
        return item.serialNumber
      }
    },
    {
      title: '№ апп. ср-ва',
      alias: 'hardwareNumber',
      settings: {
        useSorting: true,
        width: 500
      },
      renderTemplate: item => {
        return item.hardwareNumber
      }
    },
  ],
  sort: {
    column: 'id',
    direction: sortDirection.desc
  }
}