/** @format */

import { FilterControlType } from 'app/components/filter/FilterEllement';
import { AgentStatus } from './enums';

const filtersConfig = [
  {
    type: FilterControlType.simpleSearch,
    alias: 'name',
    label: 'АРМ',
    extended: false,
    requestFormatIsArray: false,
    order: 1,
  },
  {
    type: FilterControlType.simpleSearch,
    alias: 'eskziUser',
    label: 'Лицевой счет',
    extended: false,
    requestFormatIsArray: false,
    order: 2,
  },
  {
    type: FilterControlType.dropDown,
    alias: 'agentStatus',
    label: 'Статус агента',
    extended: false,
    order: 3,
    autosearch: true,
    options: Object.entries(AgentStatus).map(e => ({ id: e[0], title: e[1] })),
  },
  {
    type: FilterControlType.autocomplete,
    alias: 'organizationId',
    label: 'Организация',
    serviceType: 'organizations',
    multiSelect: false,
    extended: false,
    requestFormatIsArray: false,
    order: 4,
  },
];

export default filtersConfig;
