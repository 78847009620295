/** @format */

import { FilterControlType } from 'app/components/filter/FilterEllement';

export const config = {
  filterConfig: [
    {
      type: FilterControlType.simpleSearch,
      alias: 'id',
      label: '№',
      pattern: '^[0-9]*$',
      extended: false,
      order: 0,
    },
    {
      type: FilterControlType.simpleSearch,
      alias: 'serialNumbers',
      label: 'Серийный номер',
      extended: false,
      requestFormatIsArray: true,
      order: 1,
    },
    {
      type: FilterControlType.simpleSearch,
      alias: 'names',
      label: 'Наименование',
      extended: false,
      requestFormatIsArray: true,
      order: 2,
    },
    {
      type: FilterControlType.autocompletePicker,
      alias: 'eskziUserId',
      label: 'Пользователь',
      serviceType: 'skziUser',
      extended: false,
      order: 3,
    },
  ],
};
