/** @format */

import { useEffect, useMemo, useState } from 'react';
import { Table } from 'app/components/list/Table/Table';
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import { formatDate } from 'app/core/utility/date';
import { formatFIO, formatFIOInitials } from 'app/core/utility/common';
import { sortDirection } from 'app/components/ui/constants';
import Overlay from 'app/components/ui/overlay';

const fieldsConfig = [
  {
    title: '№',
    alias: 'id',
    sortByValue: item => item.id,
    format: item => item.id,
    visibleOnStart: true,
    userSorting: true,
    width: 50,
  },
  {
    title: 'Наименование',
    alias: 'name',
    sortByValue: item => item.name,
    format: item => item.name,
    visibleOnStart: true,
    userSorting: true,
  },
  {
    title: 'Серийный №',
    alias: 'serialNumber',
    sortByValue: item => item.serialNumber,
    format: item => item.serialNumber,
    visibleOnStart: true,
    userSorting: true,
    width: 100,
  },
  {
    title: '№ экземпляра',
    alias: 'identificationNumber',
    sortByValue: item => item.identificationNumber,
    format: item => item.identificationNumber,
    visibleOnStart: true,
    userSorting: true,
    width: 100,
  },
  {
    title: 'Ключевой носитель',
    alias: 'keyCarrier',
    sortByValue: item => item.keyCarrier?.serialNumber ?? '',
    format: item => {
      const { keyCarrier } = item;
      const { keyCarrierType, serialNumber = '' } = keyCarrier || {};
      const { name = '' } = keyCarrierType || {};

      return (
        <div className="key-carrier">
          <span>{`${name}`}</span>
          <br />
          <span>{`${serialNumber}`}</span>
        </div>
      );
    },
    visibleOnStart: true,
    userSorting: true,
  },
  {
    title: 'Лицевой счет',
    alias: 'eskziUser',
    sortByValue: item => formatFIO(item.eskziUser),
    format: item => formatFIOInitials(item.eskziUser),
    visibleOnStart: true,
    userSorting: true,
  },
  {
    title: 'Срок действия',
    alias: 'expirationDate',
    sortByValue: item => {
      const { expirationDate } = item;

      return expirationDate ? new Date(expirationDate).valueOf() : 0;
    },
    format: item => formatDate(item.expirationDate, 'dd.mm.yyyy'),
    visibleOnStart: true,
    userSorting: true,
    width: 100,
  },
  {
    title: 'Дата добавления',
    alias: 'createDate',
    sortByValue: item => {
      const { createDate } = item;

      return createDate ? new Date(createDate).valueOf() : 0;
    },
    format: item => formatDate(item.createDate, 'dd.mm.yyyy'),
    visibleOnStart: true,
    userSorting: true,
    width: 100,
  },
];

export default function KeyDocuments(props) {
  const { keyDocuments, itemId } = props;

  const { inProgress, sorting, sortListAction, syncFrontListSortAction } =
    useTableExtensions({
      defaultSort: {
        column: 'createDate',
        direction: sortDirection.desc,
      },
    });

  const [keyDoc, setKeyDoc] = useState([]);

  const list = useMemo(() => {
    return keyDoc || [];
  }, [keyDoc]);

  useEffect(() => {
    syncFrontListSortAction(sorting, fieldsConfig, list);
  }, [sorting, list]);

  useEffect(() => {
    keyDocuments?.length && setKeyDoc(keyDocuments);
  }, [itemId]);

  return (
    <>
      {inProgress ? <Overlay /> : null}
      <div className="keyCarrier">
        <Table
          totalCount={list.length}
          list={list}
          sorting={sorting}
          sortListAction={sortListAction}
          onItemClick={item => {
            const { okz } = item || {};
            return `/keyDocument/${okz ? 'okz' : 'oki'}/card/${item.id}`;
          }}
          fieldsConfig={fieldsConfig}
        />
      </div>
    </>
  );
}
