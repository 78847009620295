import React from "react";
import withModal from "../../../HOC/ModalHOC";
import FunctionalCard from "../../../ui/card/FunctionalCard";
import useFunctionalCard, {CardState} from "../../../ui/card/FunctionalCard/useFunctionalCard";
import Button from 'app/components/ui/button/button'
import {notEmpty} from "../../../../core/utility/validation";
import EditForm from "./EditForm";


const EditModalCard = withModal(FunctionalCard)
export default function EditModal({item, onCancel, onEdit, onEditFinish, certificationsTypes}){
  const get = () => Promise.resolve({
    data: {
      type: {
        id: item.type.id,
        title: item.type.name,
        value: item.type.id
      },
      number: item.number,
      certificateStartDate: item.certificateStartDate,
      certificateExpirationDate: item.certificateExpirationDate,
      id: item.id
    }})

  const formData = {validationRules: certificateValidationRules, defaultState: defaultCertificateFormState}

  const {
    cardState,
    fieldsError,
    onFormChange,
    validate,
    commonDataForm
  } = useFunctionalCard({formData, get, parentEditState: true})

  return (
    <EditModalCard
      renderHeader = { () => <div className='modal__header'>Редактирование сертификата соответствия</div> }
      cardState={CardState.edit}
      canEdit={true}
      editOnly={true}
      className="skzi-build__certificate-card"
      editState={true}
      onCancel={onCancel}
    >
      <div className="card-material__content">
        <EditForm
          certificationsTypes={certificationsTypes}
          cardState={cardState}
          formData={commonDataForm}
          serverFormViewModel={null}
          onChange={onFormChange}
          errorByFields={fieldsError}
        />
      </div>
      <div className="buttons-panel">
        <Button
          type='text'
          onClick={() => {
            if(validate()){
              return false
            }
            onEdit(commonDataForm)
          }}
        >
          <span className='button-title'>Сохранить</span>
        </Button>
        <Button
          type='text'
          onClick={onCancel}
        >
          <span className='button-title'>Отмена</span>
        </Button>
      </div>
    </EditModalCard>
  )
}



export const certificateValidationRules = {
  type: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  number: {
    isRequired: () => true,
    validation: value => {
      return notEmpty(value)
    },
  },
  certificateStartDate: {
      isRequired: () => true,
      validation: value => {
        return notEmpty(value)
      },
    },
}

export const defaultCertificateFormState = {
  commonDataForm : {
    type: {},
    number: '',
    certificateStartDate: null,
    certificateExpirationDate: null,
  },
  viewData: {
    type: {},
    number: '',
    certificateStartDate: null,
    certificateExpirationDate: null,
  }
}