import React, {useState} from "react";
import SignatureDataComponent from "../../../signature/signatureInfo/signatureData";
import {mapSignature} from "../../../dialog/oneClickActionFields";
import SignatureTrigger from "../../../signature/signatureInfo/signatureTrigger";
import withModal from 'app/components/HOC/ModalHOC'
import { formatDate } from 'app/core/utility/date'
import Input from 'app/components/ui/Input'

const SignatureData = withModal(SignatureDataComponent)

export default function SignatureInfoFields({eventId,signatures}) {

  const [currentSignature, setCurrentSignature] = useState(null)
  return(
    <>
      {currentSignature && (
        <SignatureData
          data={currentSignature}
          onCancel={() => setCurrentSignature(null)}
          renderHeader = {() => {
            return (
              <div className='modal__header'>
                {'Реквизиты электронной подписи'}
              </div>
            )
          }}
        />
      )}
      {
        signatures && signatures.length && signatures.map((sign, index) => {
          const { signFio, signatureDate } = mapSignature(sign)
          return (
            <div key={index} className="events-signature-info__block">
              <div className="events-signature-info__block__sign">
                <Input
                  id={`sign-fio-${eventId}`}
                  className="events-signature-info__block__sign__input card-field"
                  label="Подписано"
                  readOnly={true}
                  value={signFio}
                />
                <SignatureTrigger
                  onClick={() => setCurrentSignature(sign)}
                  disabled={!sign.certificateInfo}
                />
              </div>
              <Input
                id={`sign-date-${eventId}`}
                className="card-field signature-trigger-block__sign-date"
                label="Дата подписания"
                readOnly={true}
                value={formatDate(signatureDate, 'hh:MM, dd.mm.yyyy')}
              />
            </div>
          )
        })
      }
    </>
  )
}