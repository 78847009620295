import React, {useEffect, useState} from "react"
import {useDispatch} from "react-redux"
import {dispatchLogic} from "../../../../../redux/actions/common"
import Overlay from 'app/components/ui/overlay'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import EskziEventBlock from "./EskziEventBlock"

export default function EskziEvents({afterAction, type, getEventsData, children, triggerUpdate, id, isExternalUser, entityType}){
  const [events, setEvents] = useState([])
  const [inProgress, setInProgress] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    setInProgress(true)
    getEventsData()
    .then(response => {
      const { data, errors, isError } = response;
      dispatchLogic(dispatch, "MOCK", data, isError, errors)
      setInProgress(false)
      if (!isError) {
        // вот это конечно удручает
        // у нас для мероприятий с бека приходит модель (поле "model")
        // и данные (поле "data"), которые мы рисуем в карточке мероприятия
        // а тут в модели приходит поле signers, для которого 
        // данные мы должны брать в другом месте, не в "data", 
        // как для всех остальных
        const withSigners = data.map((event) => {
          return event.data?.eskziDestructionCertificate
                  ? {...event, data: {...event.data, signers: event.data.eskziDestructionCertificate.signers?.map(i => i.user)}}
                  : event
        })
        setEvents(withSigners)
      }
    })
  }, [triggerUpdate])

  return (
    <>
      {inProgress && <Overlay />}
      <ExpandingBlock
        className="eskzi-card__events"
        renderHeader= {() => 'Список мероприятий'}
        initialState={true}
      >
        {children}
        {
          events.map(event => <EskziEventBlock 
            setInProgress={setInProgress} 
            event={event} 
            key={event && event.id}
            eskziId={id}
            entityType={entityType}
            type={type}
            id={id}
            afterAction={afterAction}
            isExternalUser={isExternalUser}
          />)
        }
      </ExpandingBlock>
    </>
  )
}