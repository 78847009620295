/** @format */

import Group from 'app/components/ui/managePanel/group';
import DownloadFileButton from 'app/components/downloadFileButton/';
import { buttonTypes } from 'app/components/ui/button/button';
import { getResourceRights } from 'app/core/auth/auth';
import { useSelector } from 'react-redux';
import { RESOURCES } from 'app/core/auth/resourceByPage';
import CertificateSuspension from 'app/components/certificateAuthority/certificates/suspension';
import CertificateRenewal from 'app/components/certificateAuthority/certificates/renewal';
import CertificateRevocation from 'app/components/certificateAuthority/certificates/revocation';

export default function BusinessEventPanel({ certificate, CAUser = false }) {
  const profile = useSelector(({ profile }) => profile);
  const managementRights = getResourceRights(profile, CAUser ? RESOURCES.certificateAuthorityCertificate : RESOURCES.certRequest);

  return (
    <>
      <Group>
        <DownloadFileButton
          url={`/api/v1/cryptopro/${CAUser ? 'acceptorcertificates' : 'certificates'}/download?sn=${certificate?.id}`}
          title="Скачать"
          type={buttonTypes.primary}
        />
      </Group>
      {managementRights.UPDATE && (
        <>
          <Group>
            <CertificateRevocation item={certificate} CAUser={CAUser} />
          </Group>
          <Group>
            <CertificateSuspension item={certificate} CAUser={CAUser} />
          </Group>
          <Group>
            <CertificateRenewal item={certificate} CAUser={CAUser} />
          </Group>
        </>
      )}
    </>
  );
}

