/** @format */

import React from 'react';
import { useHistory } from 'react-router-dom';

import { Group } from 'app/components/ui/managePanel';
import Img, { Image } from 'app/components/ui/Img';
import { Delete } from 'app/components/list/TopPanel/Delete';
import ButtonComponent, { buttonTypes } from 'app/components/ui/button/button';
import withTooltip from 'app/components/HOC/TooltipHOC';
import { stringPlural } from 'app/core/utility/common';
import service from 'app/services/service';

const Button = withTooltip(ButtonComponent);

export default function BusinessEventPanel({ rights, items, afterAction, setSelectedAction, onInProgress }) {
  const history = useHistory();

  const onCreate = () => {
    history.push('/skziRegistry/accounting/create');
  };

  const onDelete = async () => {
    onInProgress(true);
    const response = await service(
      'skziService',
      'deleteSkzi',
      items.map(i => i.id)
    );
    onInProgress(false);
    return response;
  };

  return (
    <>
      <Group>
        {rights.CREATE && (
          <Button type={buttonTypes.primary} className="create-button" onClick={onCreate}>
            <Img img={Image.Plus} />
            <span className="button-title">Добавить</span>
          </Button>
        )}
      </Group>
      <Group>
        {rights.DELETE && (
          <Delete
            items={items.map(i => i.id)}
            deleteItemsAction={onDelete}
            afterAction={afterAction}
            multiply
            setSelectedAction={setSelectedAction}
            dialog={data => {
              const arr = ['выбранное СКЗИ', 'выбранные СКЗИ', 'выбранные СКЗИ'];
              return `Вы уверены, что хотите удалить ${stringPlural(data.length, arr)}?`;
            }}
          />
        )}
      </Group>
    </>
  );
}
