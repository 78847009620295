import React from 'react'
import Input, {InputType} from 'app/components/ui/Input'
import PasswordInput from 'app/components/ui/passwordInput'
import DropDown from 'app/components/ui/DropDown'
import { CardState } from 'app/components/ui/card/cardMaterial'
import {frequencyValues, resetValues} from '../formData/selectValues'
import useAuthenticationForm from '../hooks/useAuthenticationForm'

export default function AuthenticationForm(props){
  const {
    cardState,
    formData = {},
    serverFormViewModel,
    onChange,
    errorByFields = {},
  } = props;

  const {
    getError,
    getFormDataValue
  } = useAuthenticationForm({formData, serverFormViewModel, errorByFields})

  return (
    <>
      <Input
        id="serverAddress"
        className="card-field card-address"
        label="Адрес сервера"
        readOnly={cardState === CardState.view}
        value={getFormDataValue('serverAddress')}
        onChange={value => onChange('serverAddress', value)}
        error={getError('serverAddress')}
      />
      <Input
        id="serverPort"
        className="card-field card-port"
        label="Порт сервера"
        readOnly={cardState === CardState.view}
        value={getFormDataValue('serverPort')}
        onChange={value => onChange('serverPort', value)}
        error={getError('serverPort')}
        inputType={InputType.number}
      />
      <Input
        id="searchBase"
        className="card-field card-search-base"
        label="Корневой узел"
        readOnly={cardState === CardState.view}
        value={getFormDataValue('searchBase')}
        onChange={value => onChange('searchBase', value)}
        error={getError('searchBase')}
        hint="Пример заполнения: DC=contoso, DC=com"
      />
      <Input
        id="serverAccount"
        className="card-field card-server-account"
        label="Учётная запись в AD"
        readOnly={cardState === CardState.view}
        value={getFormDataValue('serverAccount')}
        onChange={value => onChange('serverAccount', value)}
        error={getError('serverAccount')}
      />
      <PasswordInput
        id="serverPassword"
        className="card-field card-password"
        label="Пароль"
        readOnly={cardState === CardState.view}
        value={getFormDataValue('serverPassword')}
        onChange={value => onChange('serverPassword', value)}
        error={getError('serverPassword')}
      />
      <DropDown
        id="refreshPeriod"
        className="card-field card-refresh-period"
        items={frequencyValues}
        active={getFormDataValue('refreshPeriod')}
        onSelect={value => onChange('refreshPeriod', value)}
        label="Частота обновления данных"
        readOnly={cardState === CardState.view}
        error={getError('refreshPeriod')}
      />
      <DropDown
        id="dropSessionPeriod"
        className="card-field card-drop-session-period"
        items={resetValues}
        active={getFormDataValue('dropSessionPeriod')}
        onSelect={value => onChange('dropSessionPeriod', value)}
        label="Сброс сессии при неактивности"
        readOnly={cardState === CardState.view}
        error={getError('dropSessionPeriod')}
      />
    </>
  )
}