import React, {useEffect, useState} from "react";
import withModal from "../../../../HOC/ModalHOC";
import service from "../../../../../services/service";
import {dispatchLogic} from "../../../../../../redux/actions/common";
import {useDispatch, useSelector} from "react-redux";
import {InputType} from "../../../../ui/Input";
import {renderInputValue, renderItem, Services} from "../../../../ui/autocomplete/autocomplete";
import Button from 'app/components/ui/button/button'
import Autocomplete from 'app/components/ui/autocomplete/autocomplete'
import ConfirmModal from "../../../../ConfirmModal";
import {addToast} from "../../../../../../redux/actions/toast";

const VolumeNameModal = withModal(({
  onCancel, 
  setSelectedVolume, 
  initName, 
  gridFilterUid, 
  closeMainModal,
  filterState,
  orgId
}) => {

  const [name, setName] = useState(initName)
  const [volumes, setVolumes] = useState([])
  const [volumeId, setVolumeId] = useState(null)
  const [isConfirmSave, setIsConfirmSave] = useState(false)

  const dispatch = useDispatch()

  useEffect(()=> {
    dispatch({type: 'LIST_IN_PROGRESS_ON'})
    service( 'volumeService', 'autocomplete', {term: '', organizationId: orgId, gridFilterUid } )
      .then(response => {
        const { data, errors, isError } = response;
        dispatch({type: 'LIST_IN_PROGRESS_OFF'})
        if (!(errors && errors[0])) {
          setVolumes(data)
        }
        // не использую редакс, нужно отправить в dispatchLogic, чтобы обработать возможные ошибки в обычном потоке
        dispatchLogic(dispatch, 'MOCK', data, isError, errors)
      })
  }, [])

  const listFilterStateForVolumeRequest = filterState.reduce((acc, el) => ({
    ...acc,
    [el.alias]: el
  }), {})

  const saveModal = () => {
    const existVolume = volumes.find(el => el.name === name)
    if(existVolume){
      setVolumeId(existVolume.id)
      setIsConfirmSave(true)
    } else {
      const data = {
        name,
        organizationId: orgId,
        gridFilterUid,
        value: listFilterStateForVolumeRequest
      }
      dispatch({type: 'LIST_IN_PROGRESS_ON'})
      service('volumeService', 'createVolume', data)
        .then(response => {
          const {data, errors, isError} = response;
          dispatch({type: 'LIST_IN_PROGRESS_OFF'})
          if (!(errors && errors[0])) {
            onCancel()
            setSelectedVolume(data)
            closeMainModal && closeMainModal()
            dispatch(addToast('Успешно сохранено'))
          }
          // не использую редакс, нужно отправить в dispatchLogic, чтобы обработать возможные ошибки в обычном потоке
          dispatchLogic(dispatch, 'MOCK', data, isError, errors)
        })
    }

  }

  const onConfirm = () => {
    const data = {
      id: volumeId,
      name,
      organizationId: orgId,
      gridFilterUid,
      value: listFilterStateForVolumeRequest
    }
    dispatch({type: 'LIST_IN_PROGRESS_ON'})
    service('volumeService', 'updateVolume', data)
      .then(response => {
        const {data, errors, isError} = response;
        dispatch({type: 'LIST_IN_PROGRESS_OFF'})
        if (!(errors && errors[0])) {
          onCancel()
          setIsConfirmSave(false)
          closeMainModal && closeMainModal()
          dispatch(addToast('Успешно обновлено'))
        }
        // не использую редакс, нужно отправить в dispatchLogic, чтобы обработать возможные ошибки в обычном потоке
        dispatchLogic(dispatch, 'MOCK', data, isError, errors)
      })
  }

  const closureService = {
    serviceName: 'volumeService',
    serviceMethod: 'autocomplete',
    data: {organizationId: orgId, gridFilterUid }
  }

  return (
    <>
      <div className='modal__header'>Сохранение тома журнала учета</div>
      <div className = "modal__content save-volume-modal">
        <span className='modal-message'>
          Введите название для создания нового тома или выберите уже существующий том из списка, чтобы перезаписать его параметры (при этом предыдущие параметры тома будут удалены).
        </span>
        <Autocomplete
          className="modal__autocomplete-volume"
          label="Наименование тома"
          type={InputType.hybrid}
          onEmptySearch={true}
          notStandardService={closureService}
          serviceType={ Services.volume}
          renderItem={ renderItem.volume }
          renderInputValue = {renderInputValue.volume}
          onSelect={ (val) => {
            setName(val ?  (val.name ? val.name : val) : '')
          }}
          value={name}
        >
        </Autocomplete>
        <div className="modal__footer">
          <Button className='footer__item' type = 'secondary' disabled={!name} onClick = { saveModal } >
            <span className='button-title'>Сохранить</span>
          </Button>
          <Button className='footer__item' type = 'secondary' onClick = { onCancel }>
            <span className='button-title'>Отмена</span>
          </Button>
        </div>
        {
          isConfirmSave && (
            <ConfirmModal
              onCancel={() => setIsConfirmSave(false)}
              header="Перезаписать том?"
              text="Вы сохраняете том журнала с уже существующим названием. При этом настройки существующего тома будут изменены на новые."
            >
              <Button className='footer__item' type = 'secondary'  onClick = { onConfirm } >
                <span className='button-title'>Подтвердить</span>
              </Button>
              <Button className='footer__item' type = 'secondary' onClick = { () => setIsConfirmSave(false) }>
                <span className='button-title'>Отмена</span>
              </Button>
            </ConfirmModal>
          )
        }
      </div>
    </>
  )
})

export default VolumeNameModal