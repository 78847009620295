import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'

import { cardLines } from './skziBuildData'
import Card from 'app/components/ui/card/cardMaterial'

import Overlay from 'app/components/ui/overlay'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Button from 'app/components/ui/button/button'
import TagComponent from 'app/components/ui/tag'
import { ReactComponent as DownloadImg } from 'assets/img/commonVer2/download.svg'
import { formatDate } from 'app/core/utility/date'
import Table from 'app/components/ui/obsoleteTable/table';
import { sortDirection } from 'app/components/ui/constants'
import DownloadButton from '../mainList/downoadBuildButton'
import Img from 'app/components/ui/Img'

import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'

import withAuth from 'app/components/HOC/AuthHOC'
import withTooltip from 'app/components/HOC/TooltipHOC'

import * as errorAction from 'redux/actions/common'
import * as skziBuildCardAction from 'redux/actions/skziRegistry/skziBuildCardAction'
import * as selfServiceRegistryAction from 'redux/actions/selfServiceRegistry/selfServiceRegistryAction'
import CardCertificationBlock from "../../skzi/Card/CardCertificationBlock";
import { ContentComponentType, HashStatus } from 'app/components/skziRegistry/enums'
import hashStatusIcons from 'app/components/skziRegistry/hashStatusIcons'

import styles from './skziBuildCard.module.scss';

const Tag = withTooltip(TagComponent)

const downloadButtonVisible = (props) => {
  const { skziBuildCard } = props || {}
  const { skziBuildData } = skziBuildCard || {}
  const { selfServiceState } = skziBuildData || {}
  
  switch (selfServiceState) {
    case 'HasAdmittance':
    case 'HasEskzi':
      return true
    default:
      return false
  }
}

class SkziCard extends PureComponent {
  constructor(props){
    super(props)

    const { profile } = props
    const fileRights = getResourceRights(profile, RESOURCES.files)

    this.state = { 
      tabTitle: 'Информация',
      sort: { 
        column: 'main',
        direction: sortDirection.desc
      },
      buildColumns: [
        {
          title: 'Тип элемента',
          alias: 'objectType',
          settings: {
            useSorting: true,
            width: 300
          },
          renderTemplate: item => {
            const { objectType } = item
            const { name } = objectType
            
            return name
          }
        },
        {
          title: 'Вложения',
          alias: 'documents',
          settings: {
            useSorting: false,
          },
          isCustomRender: true,
          renderTemplate: rowData => {
            const { files, objectType: { name: objectTypeName } } = rowData
            const { match } = this.props
            const { params = {} } = match
            const { skziId, versionId, buildId } = params
            const urlPart = `api/v1/skzi/${skziId}/version/${versionId}/build/${buildId}/file`

            return (
              <div className="type-files">
                {files.map((item) => { 
                  return (
                    <Tag
                      key={item.guid}
                      item={item}
                      tooltip={objectTypeName === ContentComponentType.InstallationPackage
                        ? `${item.hashStatus || HashStatus.NotSet}: ${item.name}`
                        : item.name
                      }
                      icon={objectTypeName === ContentComponentType.InstallationPackage &&
                        <Img
                          img={hashStatusIcons[item.hashStatus || HashStatus.NotSet]}
                          className={`${styles.fileTagIcon} ${styles[Object.keys(HashStatus).find(k => HashStatus[k] === item.hashStatus) || 'NotSet']}`} />
                      }
                      text={item.name}
                      link={!item.hashStatus || item.hashStatus === HashStatus.Ok || item.canBeDownloaded
                        ? `${urlPart}/${item.guid}`
                        : null}
                    />
                  );
                }
                )}
              </div>
            )
          }
        },
      ]
    }
  }

  componentDidMount() {
    const { skziBuildCard, getSelfServiceSkziBuildAction, skziBuildCardInProgress, match = {}, location} = this.props
    const { pathname, search } = location
    const { params = {} } = match
    const { buildId } = params
    const { inProgress } = skziBuildCard

    if (inProgress) {
      return
    }

    const searchParams = new URLSearchParams(search);
    const skziName = searchParams.get('skziName') ? decodeURI(searchParams.get('skziName')) : ''
    
    skziBuildCardInProgress(true)
    getSelfServiceSkziBuildAction({ buildId, pathname, skziData: {skziName: skziName}})
  }

  componentWillUnmount () {
    const { 
      skziBuildCardResetAction,
      clearErrorAction,
      skziBuildCardOnSelect
    } = this.props

    skziBuildCardOnSelect()
    skziBuildCardResetAction()
    clearErrorAction()
  }

  _onSort = (column, direction) => {
    const { skziBuildCardInProgress, skziBuildCardGetTypesAction, match } = this.props
    const { params = {} } = match || {}
    const { skziId, versionId, buildId } = params

    this.setState({ sort: { column, direction }}, () => {
      skziBuildCardInProgress(true);
      skziBuildCardGetTypesAction({skziId, versionId, buildId, column, direction })
    })
  }

  _onSelect = selectedData => {
    const { skziBuildCardOnSelect } = this.props
    skziBuildCardOnSelect(selectedData)
  }

  _getTableOptions = () => {
    const { sort } = this.state
    const { column, direction } = sort

    return {
      sortable: {
        defaultColumn: column,
        defaultDirection: direction,
        onChange: (name, direction) => this._onSort(name, direction)
      },
      multipleSelect: {use: true, onCheck: this._onSelect},
      useActions: {use: false},
    }
  }

  _renderDownloadButton = () => {
    const { skziBuildCard, match } = this.props
    const { selected } = skziBuildCard
    const { items } = selected
    const { params = {} } = match
    const { skziId, versionId, buildId } = params
    const canDownload = items.length
      && items.find(item => item.files && item.files.length)
      && items.every(item => (item.objectType && item.objectType.name !== ContentComponentType.InstallationPackage) || item.files.every(f => f.canBeDownloaded))

    const dowloadUrl = `api/v1/skzi/${skziId}/version/${versionId}/build/${buildId}/content/download?id=${items.map(item => item.objectType.id).join('&id=')}`

    return (canDownload ? (
        <a href={dowloadUrl} download>
          <Button 
            type='image'
            onClick={this._onFilesDownload}
            disabled={!items.length}
          >
            <DownloadImg className='button-image'></DownloadImg>
          </Button>
        </a>
      ) : (
        <Button 
          type='image'
          onClick={null}
          disabled={true}
        >
          <DownloadImg className='button-image'></DownloadImg>
        </Button>
      )
    )
  }

  _renderData = () => {
    const { skziBuildCard, skziBuildCardInProgress, profile, match } = this.props
    const { params = {} } = match
    const { skziId, versionId, buildId } = params
    const { buildColumns  } = this.state
    const { skziBuildData, selected, viewData, commonDataForm, inProgress } = skziBuildCard
    const { documentation = [], documentationDisabled } = skziBuildData
    const { skzi, skziVersion, name } = skziBuildData || {}
    const { name: versionName, id: versionIdFromFormData } = skziVersion || {}
    const { name: skziName } = skzi || {}
    const downloadText = `Скачать ${skziName} ${versionName} (${name})`

    return (
      <section className='skzi-build-common-data'>
        <ExpandingBlock
          renderHeader= {() => 'Реквизиты сборки'}
          initialState={true}
        >
          <div class="card-material__management">
            {downloadButtonVisible(this.props) ? (
              <DownloadButton
                lastBuild={skziBuildData}
                versionId={versionIdFromFormData}
                downloadText={downloadText}
                inProgressAction={skziBuildCardInProgress}
              />
            ) : null}
          </div>
          <Card
            className='skzi-build-data-card'
            profile={profile}
            formLines={cardLines}
            actions={[]}
            canEdit={false}
            key={this._getBuildKey(viewData)}
            data={viewData}
            formData={commonDataForm}
            getObjectKeyFunc={this._getBuildKey}
          />
        </ExpandingBlock>
        <CardCertificationBlock
            routeParams={{ skziId, versionId, buildId }}
            inProgressParent={inProgress}
        />
        {!documentationDisabled ? (
          <ExpandingBlock
            className='skzi-build-types'
            renderHeader= {() => 'Комплектация'}
            initialState={true}
          >
            <div className="skzi-build-types manage-panel">
              <div className="buttons-element">
                {this._renderDownloadButton()}
              </div>
            </div>
            <Table 
              items={documentation}
              columns={buildColumns}
              selected={selected}
              noBorders={true}
              options={this._getTableOptions()}
              disabled={inProgress}
            />
          </ExpandingBlock>
        ) : null}
      </section>
    )
  }

  _getBuildKey = (data) => {
    const { name, description, classId, certificateExpirationDate, certificate } = data
    const { id } = classId || {}

    const canEdit = `${name}${description}${id}${certificate}${formatDate(certificateExpirationDate, 'yyyy-mm-dd')}`

    return canEdit
  }

  render() {
    const { skziBuildCard, history } = this.props
    const { inProgress } = skziBuildCard

    return (
      <section className='self-service-skzi-build-card'>
        {inProgress ? <Overlay /> : null}
        <BreadcrumbsHeader />     
        {this._renderData()}
      </section>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default connect(
  mapStateToProps,
  { 
    ...errorAction,
    ...skziBuildCardAction,
    ...selfServiceRegistryAction,
  })(withAuth(SkziCard))