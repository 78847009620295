import { 
  BREADCRUMBSPUSHITEM,
  CARDUPDATE,
  CARDINIT,
  CARDPREINIT,
  CARDPREINITUPDATE,
  CARDRESET,
  CARDINPROGRESS,
  CARDSEREVERUPDATE,
  CARDAFTERCREATE,
  CARD_SET_WARNING,
  DISABLEACTIONPANEL,
  CLEAR_CARD,

  LICENSECARDGET,
  LICENSECARDUPDATE,
  LICENSECARDUPDATEFORMDATA,
  LICENSECARDINPROGRESS,
  LICENSECARDRESETACTION,
  LICENSECARDGETESKZI
  
} from '../actionTypes'
  
import service from 'app/services/service'
import { dispatchLogic } from '../common'
import { getPageBreadcrumb } from 'app/header/breadcrumbs'

  export const getLicenseAction = (licenseId) => dispatch => {
    return service('licenseService', 'getLicense', licenseId)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDINIT, data, isError, errors)
      })
  }

  export const deleteLicenseAction = (licenseId) => dispatch => {
    return service('licenseService', 'deleteLicense', licenseId)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CLEAR_CARD, data, isError, errors)
      })
  }
  

  export const getExternalUserLicenseAction = (licenseId) => dispatch => {
    return service('licenseService', 'getExternalUserLicense', licenseId)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDINIT, data, isError, errors)
      })
  }

  
  export const licenseCardUpdateAction = (data) => dispatch => {
    return service('licenseService', 'updateLicense', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        dispatchLogic(dispatch, CARDSEREVERUPDATE, data, isError, errors)
      })
  }

  export const licenseCardGetEskziAction = (data) => dispatch => {
    return service('licenseService', 'getEskzi', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        dispatchLogic(dispatch, LICENSECARDGETESKZI, data, isError, errors)
      })
  }

  export const licenseCardExtOrgGetEskziAction = (data) => dispatch => {
    return service('licenseService', 'extOrgGetEskzi', data)
      .then(response => {
        const { data, errors, isError } = response;
        
        dispatchLogic(dispatch, LICENSECARDGETESKZI, data, isError, errors)
      })
  }


  export const licenseCardResetAction = () => dispatch => {
    dispatch({
      type: LICENSECARDRESETACTION,
      payload: { 
      }
    })
  }
  
  export const licenseCardInProgress = isInProgress => dispatch => {
    dispatch({
      type: LICENSECARDINPROGRESS,
      payload: isInProgress
    })
  }
  
  export const licenseCardUpdateCommonForm = (formData) => dispatch => {
    dispatch({
      type: LICENSECARDUPDATEFORMDATA,
      payload: { 
        ...formData
      }
    })
  }