import React from "react"
import useFunctionalCard from "../../../ui/card/FunctionalCard/useFunctionalCard"
import FunctionalCard from "../../../ui/card/FunctionalCard"
import EventForm from "./EventForm"
import ExpandingBlock from 'app/components/ui/expandingBlock'
import SignatureDialog from 'app/components/signature/SignatureDialog'
import {useDispatch} from "react-redux"
import {resetSignature} from "../../../../../redux/actions/common/signatureAction"
import {useEventsFormData, useEventsSignature} from "./useEvents"
import {dataFormEqual} from "../../../../core/utility/common"
import RemoveEventButton from 'app/components/ui/RemoveEventButton/RemoveEventButton'


export default function EskziEventBlock({entityType, event, setInProgress, isExternalUser, eskziId, afterAction, type, id}){

  const { canBeChanged = true, canBeRolled = null } = event
  const formData = useEventsFormData(event)
  const dispatch = useDispatch()
  const updatedData = {...event.data}
  // если поле в базе пустое, то сервер это поле не указывает в объекте. Из-за этого - не корректно работает
  // метод проверки изменения формы
  // код ниже добавляет в объет поля, которые есть в модели - но отстутствуют в данных
  Object.keys(formData.defaultState.commonDataForm).forEach(key => {
    if(!updatedData[key]){
      updatedData[key] = ''
    }
  })
  const get = () => Promise.resolve({
    ...event,
    data: updatedData
  })

  const applyRightsToActions = () => {
    return !canBeChanged ? [] : ["edit", "delete"]
  }
 
  const {
    submitEnabled,
    onCancelForm,
    cardState,
    editState,
    setState,
    fieldsError,
    onFormChange,
    validate,
    dispatchStore,
    commonDataForm,
  } = useFunctionalCard({formData, get, dataFormEqual: dataFormEqual})

  const {
    errorObject,
    textForSignature,
    requestData,
    profile,
    signatureRequestType,
    onSubmit,
    afterSign
  } = useEventsSignature({
    commonDataForm,
    dispatchStore,
    setState,
    validate,
    event,
    setInProgress,
    isExternalUser,
    type,
    id
  })

  return(
    <>
      <ExpandingBlock
        className="eskzi-card__events-item"
        renderHeader= {() => event.name}
        initialState={false}
      >
        <FunctionalCard
          showButtons={true}
          cardState={cardState}
          canEdit={canBeChanged}
          editOnly={false}
          onSubmit={onSubmit}
          submitEnabled={submitEnabled}
          submitTitle="Сохранить"
          onCancelForm={onCancelForm} 
          setState={setState}
          className="eskzi-event-data-card"
          actions={applyRightsToActions()}
          editState={editState}
          cancelEnabled={true}
        >
          <div className="card-material__content">
            <EventForm
              eventId={event && event.id}
              signatures={event.signatures}
              cardState={cardState}
              formData={commonDataForm}
              model={event.model}
              onChange={onFormChange}
              errorByFields={fieldsError}
              eskziId={eskziId}
              entityType={entityType}
            />
          </div>
          {
            canBeRolled !== null ? (
              <RemoveEventButton
                toolbar
                eskziId={eskziId}
                eventId={event && event.id}
                disabled={!canBeRolled}
                afterAction={afterAction}
                setInProgress={setInProgress}
                type={type}
              />
            ) : null
          }
        </FunctionalCard>
      </ExpandingBlock>
      {
        signatureRequestType === 'JournalActionDataChanged'
        && !errorObject
        && textForSignature
        && requestData && requestData.event && requestData.event.id === event.id
        && (
          <SignatureDialog
            className={'signature-form'}
            profile={profile}
            onCancel={() => {
              dispatch(resetSignature())
            }}
            afterSignCallback={afterSign}
            renderHeader = {() => {
              return (
                <div className='sign__header'>
                  {event.name || ''}
                </div>
              )
            }}
          />
        )
      }
    </>
  )
}