import React, {useEffect} from "react"
import { CardState } from 'app/components/ui/card/cardMaterial'
import ActionFieldComponent from "./ActionFieldComponent";
import {capitalize, decapitalize} from "app/core/utility/common";
import {useSelector} from "react-redux";

export default function ActionForm(props){
  const {
    cardState,
    formData = {},
    model = [],
    onChange,
    errorByFields = {},
    eventId,
    fixedValues = {}
  } = props;

  function getFormDataValue(fieldName){
    return formData[fieldName]
  }

  function getError(fieldName){
    return errorByFields[capitalize(fieldName)]
  }
  const {userInfo} = useSelector(({profile})=> profile)
  useEffect(() => {
    onChange('eventTemplateId', eventId)
    onChange('performerId', userInfo)
    for (const [key, value] of Object.entries(fixedValues)) {
      onChange(key, value)
    }
  }, [])

  return (
    <>
      {model.map((el, index) => {
        const { key, title, readOnly } = el
        const type = decapitalize(key)
        return (
          <ActionFieldComponent
            fixedValues={fixedValues}
            formData={formData}
            id={`${key}-${eventId}-${index}`}
            key={key}
            value={getFormDataValue(type)}
            onChange={value => onChange(type, value)}
            error={getError(type)}
            type={type}
            title={title}
            readOnly={cardState === CardState.view || readOnly}
            organizationType={el.organizationType}
          />
        )
      })}
    </>
  )
}