import React from "react";
import { getClassName } from "app/core/utility/jsx";
import styles from "./signerWithSignState.module.scss";
import AutocompletePicker from "app/components/ui/picker/autocompletePicker/autocompletePicker";
import { SelectType } from "app/components/ui/picker/picker";
import DropDown from "app/components/ui/DropDown";
import FilterDash from "../filterDash/FilterDash";

export default function SignerWithSignState({
  className,
  onChange,
  value,
  serviceType,
  signerTitle = "Участник комиссии",
  noHint
}) {
  const { signer, hasSign } = value || {};

  const _onChangeHasSign = (val) => {
    onChange({
      ...value,
      hasSign: val,
    });
  };

  const _onChangeSigner = (val) => {
    const actualValue = val
      ? {
          ...value,
          signer: val,
        }
      : null;

    onChange(actualValue);
  };

  return (
    <div className={getClassName([styles.signWithState, className || ""])}>
      <AutocompletePicker
        serviceType={serviceType}
        selectType={SelectType.single}
        value={signer}
        onSelectValue={_onChangeSigner}
        label={signerTitle}
        noHint={noHint}
      />
      <FilterDash/>
      <DropDown
        items={YesNo}
        active={hasSign}
        onSelect={_onChangeHasSign}
        label='Подпись участника'
        disabled={!signer}
        noHint={noHint}
      />
    </div>
  );
}

const YesNo = [
  {
    id: false,
    title: "Нет",
  },
  {
    id: true,
    title: "Есть",
  },
];
