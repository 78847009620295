import { menuContent, flattenMenuContent } from '../../navigation/navigationContent'
import { arrayToIdHashMap } from 'app/core/utility/common'

export const AUTHTYPE = {
  ad: 'AD',
  poib: 'POIB',
  aokz: 'AOKZ'
}

export const RIGHTS = {
    viewList: 'VIEW_LIST_EXTENDED',
    viewCard: 'VIEW_CARD',
    viewShortList: 'VIEW_LIST',
    create: 'CREATE', 
    update: 'UPDATE',
    delete: 'DELETE',
    import: 'IMPORT',
    //report: 'REPORT',
    okzReport: 'OKZ_REPORT',
    okiReport: 'OKI_REPORT'
}

export const applyProfileToMenu = profile => {
  const { accessRights = [] } = profile || {}

  const mappedRights = accessRights.reduce((acc, item) => {
    const { resource, actions } = item

    acc[resource] = actions
    return acc
  }, {})

  const filterMenuByProfile = (menuItem) => {
    if (mappedRights[menuItem.resource]) {
      const actionRights = mappedRights[menuItem.resource].find(action => action.action === menuItem.resourceRight)

      return actionRights ? actionRights.accessAllowed : false 
    } else {
      return false
    }
  }

  const applyProfileRights = menuContent.filter(filterMenuByProfile)

  const withSubMenu = 
      menuContent.filter(menu => !!menu.submenu)
                  .reduce((acc, menu)=>{ 
                      const filtered = menu.submenu.filter(filterMenuByProfile)

                      return filtered.length 
                              ? [...acc, {...menu, submenu: filtered} ]
                              : acc
                  }, [])

  const appliedRightsMenu = arrayToIdHashMap([ ...applyProfileRights, ...withSubMenu ] )
  const menu = menuContent
                .map(item => appliedRightsMenu[item.id])
                .filter(item => item && !(item.skipIf && item.skipIf(item, appliedRightsMenu)))

  return menu
}

const getPageObject = path => {
  let menu = flattenMenuContent[path]
  
  if (menu) {
      return menu
  } else {
      const regexpMenu = Object.keys(flattenMenuContent).reduce((acc, key) => {
          const { regexp } = flattenMenuContent[key]
  
          return regexp ? [...acc, flattenMenuContent[key]] : acc
      }, [])

      menu = regexpMenu.find(menuItem => {
          const { regexp } = menuItem

          return new RegExp(regexp).test(path)
      })
  }
  
  return menu
}

export const locationName = path => {
  const menu = getPageObject(path)
  
  const { title, headerTitle } = menu || {}
  return title || headerTitle
}

export const getResourceRights = (profile, resource) => {
  return Object.keys(RIGHTS).reduce((acc, key) => {
    if (!profile.accessRights) {
      return { ...acc, [RIGHTS[key]]: false }
    }

    const rightObject = profile.accessRights.find(item => item.resource === resource)
    if (!rightObject) {
      return {
        ...acc, [RIGHTS[key]]: false
      }
    }

    const right = rightObject.actions.find(right => right.action === RIGHTS[key])
    
    return { ...acc, [RIGHTS[key]]: right ? right.accessAllowed : false }
  }, {})
}