import React, { Component } from 'react'

import withModal from 'app/components/HOC/ModalHOC'
import { fileListAPI } from 'app/core/utility/file'
import FileButton from 'app/components/ui/fileUploadButton'
import DropDown from 'app/components/ui/DropDown'
import Button, { buttonTypes } from 'app/components/ui/button/button'
import Radio from 'app/components/ui/radio'
import { ReactComponent as DwnlImg } from 'assets/img/commonVer2/download.svg'
import { ReactComponent as CloseImg } from 'assets/img/commonVer2/close.svg'
import { downloadFileFormHandler } from 'app/core/utility/file'
import FileField from './fileField'
import { excludeByItemId } from 'app/core/utility/common'

const fileTypes = {
  doc: 'documentation',
  install: 'installation'
}

class fileDialog extends Component {
  constructor(props){
    super(props)

    this.state = this._getState(props)
  }

  _getState = (props) => {
    const { data } = props
    const { skzi: { id: skziId}, version: { id: versionId}, build } = data
    const { documentation, installationPackage = null, id: buildId } = build || {}
    const urlPArt = `skzi/${skziId}/version/${versionId}/build/${buildId}/file`

    return {
      documentationFiles: documentation,
      installationPackageFiles: installationPackage ? [installationPackage] : [],
      urlPArt,
      progress: {},
      cancellation: {}
    }
  }

  _onFileUploadStart = (file, objectType) => {
    const { onFileUploadStart } = this.props

    onFileUploadStart()
    this._onFileUploaded(file, objectType, true)
  }

  _onFileUploaded = (uploadResult, objectType, isFakeUploadingFile) => {
    const { data, onFileUploadFinish } = this.props
    const { progress: stateProgress, cancellation: stateCancellation } = this.state
    const { build } = data
    const { documentation: documentationFiles } = build
    const objectTypefileGroup = documentationFiles.find(item => item.objectType.id === objectType.id)
    const newFiles = objectTypefileGroup
                      ? [ uploadResult, ...objectTypefileGroup.files.filter(item => item.key !== uploadResult.key)]
                      : [ uploadResult ]

    if (!isFakeUploadingFile) {
      delete stateProgress[uploadResult.key]
      delete stateCancellation[uploadResult.key]
      this.setState({ progress: stateProgress }, () => {
        onFileUploadFinish()
      })
    }

    this._onUpdateFiles(newFiles, objectType)
  }

  _onUpdateFiles = (files, objectType) => {
    const { onFilesUpdated, data } = this.props
    const { id: objectTypeId } = objectType
    const { build } = data
    const { documentation: documentationFiles } = build
    const objectTypefileGroupIndex = documentationFiles.findIndex(item => item.objectType.id === objectTypeId)
    let updatedFiles;

    if (objectTypefileGroupIndex > -1) {
      documentationFiles[objectTypefileGroupIndex].files = files
      updatedFiles = documentationFiles
    } else {
      updatedFiles = [{objectType, files}, ...documentationFiles]
    }
    
    const updatedDocumentData = {
      ...data,
      build: {
        ...build,
        documentation: updatedFiles
      },
    }

    onFilesUpdated(updatedDocumentData)
  }

  _onObjectTypeSelect = (value) => {
    this.setState({
      objectType: value,
    })
  }

  _onObjectTypeAdd = () => {
    const { onObjectTypeAdd, data } = this.props
    const { skzi, version, build } = data
    const { objectType } = this.state

    onObjectTypeAdd({skzi, version, build, objectTypes: [objectType]})
    this.setState({ objectType: null })
  }

  _onObjectTypeDelete = (objectType) => {
    const { onObjectTypeDelete, data } = this.props
    const { skzi, version, build } = data

    onObjectTypeDelete({skzi, version, build, objectType})
  }

  _onMainObjectTypeSet = (objectType) => {
    const { onMainObjectTypeSet, data } = this.props
    const { skzi, version, build } = data

    onMainObjectTypeSet({skzi, version, build, objectType})
  }

  _canDownloadAll = () => {
    const { data } = this.props
    const { build } = data
    const { documentation: documentationFiles } = build
    const hasDocFiles = documentationFiles.length 
                        && documentationFiles.reduce((acc, cur) => {
                          return acc || cur.files.length
                        }, false)

    return hasDocFiles
  }

  _onDownloadAll = () => {
    const { data, downloadAllService, onFileActionStart, onFileActionFinish, onError } = this.props
    const { skzi, version, build } = data || {}
    const { id: skziId } = skzi || {}
    const { id: versionId } = version || {}
    const { id: buildId } = build || {}

    onFileActionStart();
    downloadFileFormHandler(downloadAllService, {skziId, versionId, buildId}, onError, onFileActionFinish)
  }

  _renderFileGroup = (data) => {
    const { onError, onFileActionStart, onFileActionFinish, fileRights, forceShow, readOnly = false } = this.props
    const { progress, urlPArt, cancellation } = this.state
    const { objectType, files, main } = data
    const { name, id: objectTypeId } = objectType
    const filesWithProgress = files.map(file => 
      ({
        ...file,
        progress: progress[file.key],
        cancelUpload: cancellation[file.key]
      }))
    const uniqueKey = new Date().valueOf()
    const docfileAPI = fileListAPI(
      {
        objectTypeId
      },
      urlPArt
    )

    if (readOnly) {
      fileRights.CREATE = false
      fileRights.UPDATE = false
      fileRights.DELETE = false
    }

    return (
      <div className="object-type-group">
        <div className={`object-type-group__title group-element ${files.length ? 'title--separated' : ''}`}>
          <div className="object-title">
            {readOnly ? (
              <span className={`title`}>{name}</span>
            ) : (
              <Radio
                key={objectTypeId}
                className={`title`}
                value={objectTypeId}
                checkedValue={main ? objectTypeId : 0}
                onChange= {() => this._onMainObjectTypeSet(objectType)}
                disabled={forceShow}
              >
                {name}
              </Radio>
            )}
            {main ? <span className='tip'>(основной элемент)</span> : null}
          </div>
          {!readOnly ? (
            <>
              <FileButton
                key={`${uniqueKey}`} // fix same file name onChange firing
                onUploadUpdate={(progress) => this._onUploadUpdate(progress, uniqueKey)}
                setCancelCallback={(cancelCallback) => {this._onCancelCallbackSet(cancelCallback, uniqueKey)}}
                fileKey={uniqueKey}
                onUploadFinish={onFileActionFinish}
                onUploadStart={(file) => this._onFileUploadStart({...file, key: uniqueKey}, objectType )}
                onError={this._onFileUploadError}
                onFileUploaded={(result) => this._onFileUploaded({...result, key: uniqueKey}, objectType )}
                fileApi={docfileAPI}
                disabled = {!objectType}
                title={'Добавить файл...'}
              >
              </FileButton>
                {forceShow ? null : (
                  <Button
                    className='delete'
                    type = {buttonTypes.image}
                    onClick = {() => this._onObjectTypeDelete(objectType)}
                  >
                    <CloseImg className='button-image' title='Удалить'/>
                  </Button>
                )}
            </>
          ) : null}
        </div>
        <div className="object-type-group__files">
          <FileField
            key={`${name}${files.length}`}
            fileRights={fileRights}
            onFileActionStart={onFileActionStart}
            onFileActionFinish={onFileActionFinish}
            objectType={objectType}
            onError={onError}
            type={fileTypes.doc}
            fileList={filesWithProgress}
            fileApi={docfileAPI}
            urlPArt={urlPArt}
            onUpdateFile={this._onUpdateFiles}
            needUploadButton={false}
          />
        </div>
      </div>
    )
  }

  _onFileUploadError = (errorData) => {
    const { axiosResult, fileKey } = errorData
    const { onError, data } = this.props
    const { build } = data
    const { documentation: documentationFiles } = build
    
    const fileGroup = documentationFiles.find(item => {
      return item.files.find(file => file.key === fileKey)
    })

    onError(axiosResult)
    fileGroup && this._onUpdateFiles(fileGroup.files.filter(file => file.key !== fileKey), fileGroup.objectType)
  }
  
  _onUploadUpdate = (progress, uniqueKey) => {
    const { progress: allProgress = {}} = this.state

    this.setState({
      progress: {
        ...allProgress, 
        [uniqueKey]: progress
      }
    })
  }

  _onCancelCallbackSet = (callback, uniqueKey) => {
    const { cancellation: stateCancellation } = this.state

    this.setState({
      cancellation: {
        ...stateCancellation, 
        [uniqueKey]: callback
      }
    })
  }

  render() {
    const { data, fileRights, readOnly, forceShow: uploadInProgress } = this.props
    const { build, objectTypes } = data
    const { documentation: documentationFiles } = build
    const { objectType, urlPArt } = this.state
    const filteredTypes = excludeByItemId(objectTypes, documentationFiles.map(item => item.objectType))

    if (readOnly) {
      fileRights.CREATE = false
      fileRights.UPDATE = false
      fileRights.DELETE = false
    }

    return (
      <div className='file-dialog'>
        {fileRights.CREATE ? (
        <div className="file-field">
          <div className="file-field__group group">
            <div className="group__title">Добавление элемента поставки</div>
              <div className="group__management">
                <DropDown
                  items = {filteredTypes || []}
                  active = {objectType}
                  onSelect= {this._onObjectTypeSelect}
                  placeholder={'Выберите тип объекта'}
                />
                <Button 
                  className='create-button'
                  type='primary'
                  disabled = {!objectType || uploadInProgress}
                  onClick={this._onObjectTypeAdd}
                >
                  <span className='button-title'>Добавить</span>
                </Button>
              </div>
          </div>
        </div>) : null}
        <div className="file-field">
          <div className="file-field__group group group--package">
            <div className="group__title">Состав комплекта</div>
            <div className="group__files">
              {documentationFiles.map(this._renderFileGroup)}
              { !documentationFiles.length ?(<div className="group__no-data" style={{paddingBottom: '2px'}}>Нет элементов</div>) : null }
            </div>
          </div>
        </div>
        <a 
          className={`${!this._canDownloadAll() ? 'anchor--disabled' : ''}`}
          href={`api/v1/${urlPArt}/all`} 
          download
        >
          <Button
            className='download-all-button'
            type='secondary'
            disabled={!this._canDownloadAll()}
          >
            <DwnlImg className='button-image button-image--left'></DwnlImg>
            <span className='button-title'>Скачать все</span>
          </Button>
        </a>
      </div>
    )
  }
}

export default withModal(fileDialog)
