import React, { useState, useEffect, useRef, useContext } from 'react';
import { connect } from 'react-redux';
import ConfirmDialog from 'app/components/dialog/confirmDialog/';
import withTooltip from 'app/components/HOC/TooltipHOC';
import ActionButtonImage from './ActionButtonImage';
import {
  deleteItemsAction,
  setSelectedAction,
} from 'redux/actions/list/listActions';
import { excludeByItemId } from 'app/core/utility/common';
import Overlay from 'app/components/ui/overlay';

const ButtonWithTooltip = withTooltip(ActionButtonImage);

export const Delete = ({
  img,
  items,
  titul,
  dialog,
  multiply,
  deleteItemsAction,
  service,
  afterAction,
  customConfirm = false,
  setSelectedAction,
  parentDisabled = false,
  useCanBeRemoved,
  onInProgress,
}) => {
  let disabled = true;
  const [confirm, setConfirm] = useState({});

  if (items.length > 0 && multiply === true) {
    disabled = items.some(i => i && i.bizLocked) || false;
  }

  if (items.length === 1 && multiply !== true) {
    disabled = items[0].bizLocked || false;
  }

  const _onClick = () => {
    const message = dialog(items);
    const confirmObject = {
      title: message,
      type: 'confirm',
    };
    setConfirm(() => confirmObject);
  };

  const _customDeleteConfirm = () => {
    _deleteCancel();
    customConfirm && customConfirm();
  };

  const _deleteConfirm = () => {
    const arr = items.map((el) => el.id || el);
    _deleteCancel();
    onInProgress && onInProgress(true);
    deleteItemsAction(arr, service)
      .then((data) => {
        const { data: deletedArray } = { data: [], ...data };
        setSelectedAction && setSelectedAction(
          excludeByItemId(
            items.map((i) => (i.id ? i : { id: i })),
            deletedArray.map((i) => ({ id: i }))
          ).map((i) => i.id)
        );
        _deleteCancel();
        if (data.isError === true) {
          const { errors } = data;

          const message = errors.map((el) => {
            if (!el.errorMessage && el.detail && el.detail.response) {
              return el.detail.response.errors[0].errorMessage;
            }
            return el.errorMessage || '';
          });

          const confirmObject = {
            title: message,
            type: 'warning',
          };

          setConfirm(() => confirmObject);
        }
        return data;
      })
      .then((response) => {
        const { data = [] } = response;
        onInProgress && onInProgress(false);
        data.length && afterAction && afterAction(data);
        return data;
      });
  };

  const _deleteCancel = () => {
    const confirmObject = {
      title: '',
      type: '',
    };
    setConfirm(() => confirmObject);
  };

  const disabledState = useCanBeRemoved
    ? items.some((item) => !item.canBeRemoved)
      ? true
      : parentDisabled || disabled
    : parentDisabled || disabled;

  return (
    <>
      {(confirm.type === 'confirm' || confirm.type === 'warning') && (
        <ConfirmDialog
          {...confirm}
          onSubmit={customConfirm ? _customDeleteConfirm : _deleteConfirm}
          onCancel={_deleteCancel}
        />
      )}
      <ButtonWithTooltip
        img={img || 'DeleteImg'}
        action={_onClick}
        disabled={disabledState}
        tooltip={titul || 'Удалить'}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  deleteItemsAction,
  setSelectedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Delete);
