import { getClassName } from "app/core/utility/jsx";
import React from "react";
import styles from "./MainListContainer.module.scss";

export const MainListLayoutType = {
  full: 'full',
  filterAndTable: 'filterAndTable',
  tableOnly: 'tableOnly',
};

export const ListDisplayVariant = {
  twoRowFilter: "twoRowFilter",
  threeRowFilter: "threeRowFilter",
  fourRowFilter: "fourRowFilter",
  fiveRowFilter: "fiveRowFilter",
  sixRowFilter: "sixRowFilter",
};

export default function MainListContainer({ layoutType = MainListLayoutType.full, children }) {
  return <section className={getClassName([
    styles.mainListContainer,
    styles[layoutType],
  ])}>{children}</section>;
}

export function FilterWithTableContainer({ useContainer, children, listDisplayVariant }) {
  return (
    <div
      className={getClassName([
        styles[listDisplayVariant],
        { [styles.containerOn]: useContainer },
      ])}
    >
      {children}
    </div>
  );
}

