import React, { Component, Fragment } from 'react'
import { itemActions } from './constants'
import withToggle from '../HOC/ToggleHOC'
import {flatten} from 'app/core/utility/common'

class actionsPanel extends Component {
  constructor(props){
    super(props)

    this.triggerNode = React.createRef()
    this.panelNode = React.createRef()

    this.state = {
      vericalDirection: 'bottom',
      pagePaddingBottom: 100
    }
  }

  _globalHandler = e => {
    const { toggle, toggleStatus } = this.props;

    toggleStatus && toggle()
  }

  componentDidMount () {
    window.addEventListener('click', this._globalHandler)
  }

  componentWillUnmount () {
    window.removeEventListener('click', this._globalHandler)
  }

  componentDidUpdate () {
    if (!this.panelNode.current) {
      return
    }
    
    const boundaries = this.panelNode.current.getBoundingClientRect()
    const { vericalDirection: stateDirection, pagePaddingBottom } = this.state
    const desiredDirection = 
            boundaries.bottom > document.documentElement.clientHeight - pagePaddingBottom 
              ? 'top' 
              : 'bottom'

    desiredDirection !== 'bottom' && stateDirection !== desiredDirection && this.setState({ 
      vericalDirection: desiredDirection
    })
  }

  _handlerClick = name => {
    const { onClick } = this.props
    
    onClick && onClick(name)
  }

  _handlerTriggerClick = (e) => {
    // we don't want to move event further
    e.stopPropagation()
    // at the same time we want all other panels to be closed
    window.dispatchEvent(new Event('click'));
    
    const { toggle, disabled, actions = [] } = this.props
    const hasActions = actions && flatten(actions).length

    hasActions && !disabled && toggle && toggle()
  }

  _renderAction = (action, i) => {
    const { isSpecialAction, title, image } = action
    const actionName = isSpecialAction ? title : itemActions[action].name
    const actionImage = isSpecialAction ? image : itemActions[action].image
    
    return (
      <div key={i} className="actions-item" onClick={() => this._handlerClick(action)}>
        <div className='actions-item__logo'>
          {actionImage ? 
            (<img src={actionImage} alt={actionName} className="actions-item__image"/>) : null
          }
        </div>  
        <span className="actions-item__title">{actionName}</span>
      </div>
    )
  }

  _renderGroup = (actions) => {
    return (
      <div className='actions-group'>
        {actions.map(this._renderAction)}
      </div>
    )
  }

  _renderActions = (actions) => {
    if (Array.isArray(actions[0])) {
      return (
        <>
          {actions.filter(item => item.length).map(this._renderGroup)}
        </>
      )
    } else {
      return (
        <>
          {actions.map(this._renderAction)}
        </>
      )
    }
  }

  render() {
    const { 
      actions = [], //Object.keys(itemActions),
      toggleStatus
    } = this.props
    const { vericalDirection } = this.state
    const hasActions = actions && flatten(actions).length

    return (
      <div ref={this.triggerNode} className={`actions-container actions-container--${hasActions ? 'filled' : 'empty'} actions-container--${toggleStatus ? 'opened' : 'closed'}`} onClick={this._handlerTriggerClick} >
        {hasActions ? (
          <div ref={this.panelNode} className={`actions-panel actions-panel${toggleStatus ? '--opened' : '--closed'}  actions-panel--${vericalDirection}` }>
            {this._renderActions(actions)}
          </div>
        ) : null}
      </div>
    )
  }
}

export default withToggle(actionsPanel)