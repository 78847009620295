import React from 'react'
import Input from 'app/components/ui/Input'
import TextArea from 'app/components/ui/textArea'
import DatePicker from 'app/components/ui/date'
import { notEmpty } from 'app/core/utility/validation'
import AutocompletePicker from 'app/components/ui/picker/autocompletePicker/autocompletePicker'
import { renderItem, renderInputValue }  from 'app/components/ui/autocomplete/autocomplete'
import { defaultSettings as userAccountColumnSetting } from 'app/components/ui/picker/entities/userAccount'
import { defaultSettings as hardwareColumnSettings } from 'app/components/ui/picker/entities/hardware'
import {SelectType} from "app/components/ui/picker/picker";
import service from 'app/services/service'
import { getEskziName } from 'app/components/skzi/constants'

export const MaintenanceCardLines = ({skzi}) => ({
  firstGroup: {
    content: {
      eskzi: {
        title: () => 'Аппаратное СКЗИ',
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
        renderEdit: props => {
          const { editValue, onChange, error } = props
          const pickerService = {
            serviceName: 'skziService',
            serviceMethod: 'hardwareAutocompleteWithoutKDPicker',
            data: {}
          }

          const autocompleteService = {
            serviceName: 'skziService',
            serviceMethod: 'hardwareAutocompleteWithoutKD',
            data: {}
          }

          !editValue && skzi && onChange('eskzi', skzi)
          return (
            <AutocompletePicker
              id="card-skzi"
              placeholder = 'Аппаратное СКЗИ'
              className="card-field card-performerId"
              onEmptySearch = {true}
              notStandardService={autocompleteService}
              notStandardPickerService={pickerService}
              tableColumns={hardwareColumnSettings}
              selectType={SelectType.single}
              searchPlaceholder="Серийный №"
              onSelectValue={value => onChange('eskzi', value)}
              error={error}
              value={editValue}
              renderItem = {item => item && getEskziName(item.skzi) }
              renderInputValue={ item => item && getEskziName(item.skzi) }
              readOnly={!!skzi}
            />
          )
        },
      },
      name: {
        title: () => 'Наименование события',
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
        renderEdit: props => {
          const { editValue, onChange, error } = props

          return (
            <Input
              placeholder = 'Введите наименование'
              value={editValue}
              onChange={value => onChange && onChange('name', value)}
              error={error}
            />
          )
        },
      },
      performerId: {
        title: () => 'Исполнитель',
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
        renderEdit: props => {
          const { editValue, onChange, error, formData } = props
          const eskziId = formData.eskzi && formData.eskzi.id
          const needHint = skzi ? false : !eskziId

          const pickerService = {
            serviceName: 'skziInstanceService',
            serviceMethod: 'getPerformersPicker',
            data: {eskziId}
          }

          const autocompleteService = {
            serviceName: 'skziInstanceService',
            serviceMethod: 'getPerformers',
            data: {eskziId}
          }

          return (
            <AutocompletePicker
              id="card-performerId"
              placeholder = 'Введите ФИО'
              className="card-field card-performerId"
              onEmptySearch = {true}
              notStandardService={autocompleteService}
              notStandardPickerService={pickerService}
              tableColumns={userAccountColumnSetting}
              selectType={SelectType.single}
              searchPlaceholder="Поиск пользователей"
              onSelectValue={value => onChange && onChange('performerId', value)}
              error={error}
              value={editValue}
              renderItem = { item => renderItem.eskziUser(item) }
              renderInputValue={ item => renderInputValue.eskziUser(item) }
              disabled={needHint}
              readOnly={needHint}
              hint={needHint && 'Выберите СКЗИ'}
            />
          )
        },
      },
      actionDate: {
        title: () => 'Дата изменения',
        isRequired: () => true,
        validation: value => {
          return notEmpty(value)
        },
        renderEdit: props => {
          const { editValue, onChange, error } = props

          return (
            <DatePicker
              error={error}
              value={editValue}
              onChange= {value => onChange && onChange('actionDate', value)}
            />
          )
        },
      },
      comments: {
        title: () => 'Примечание',
        renderEdit: props => {
          const { editValue, onChange, error } = props

          return (
            <TextArea
              value={editValue}
              error={error}
              onChange={value => onChange && onChange('comments', value)}
            />
          )
        },
      },
    }
  }
})