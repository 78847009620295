/** @format */
import React, { useCallback, useEffect } from 'react';
import Portal from 'app/components/ui/portal/portal';
import { eventStop } from 'app/core/utility/common';
import styles from './modal.module.scss';
import { keyCodes } from 'app/components/ui/constants';

export default function withModal(Component) {
  return props => {
    const { onCancel } = props || {};

    const handlerOnKeyDown = useCallback(
      (e) => {
        switch (e.code) {
          case keyCodes.Esc:
            onCancel && onCancel();
            break;
        }
      },[],
    )
    
    useEffect(() => {
      document.addEventListener('keydown', handlerOnKeyDown);

      return () => {
        document.removeEventListener('keydown', handlerOnKeyDown);
      };
    }, []);

    return (
      <Portal>
        <div className={styles.container}>
          <div
            className={styles.overlay}
            onClick={e => {
              onCancel && onCancel();
            }}
          ></div>
          <div className={styles.panel}>
            <Component {...props} />
          </div>
        </div>
      </Portal>
    );
  };
}
