/** @format */
import { ENTITY_TYPE } from 'app/components/ui/managePanel/businessEventTrigger/useBusinessEvent';
import { Image } from 'app/components/ui/Img';

import getIssuerConfig from './issuerConfig';

export default {
  issuerButtonText: 'Выдать СКЗИ',
  getIssuerConfig,
  endpointType: 'eskzi',
  entityType: ENTITY_TYPE.eskzi,
  customActions: [
    [
      {
        eventCode: 'OkiWithdrawal',
        tooltip: 'Изъять',
        buttonImage: Image.SoftReturn,
        changeType: 'deleted',
      },
    ],
    [
      {
        eventCode: ['OkiInstallation'],
        tooltip: 'Установить',
        buttonImage: Image.DownloadCircle,
        changeType: 'updated',
      },
    ],
    [
      {
        eventCode: 'OkiDestruction',
        tooltip: 'Уничтожить экземпляр',
        buttonImage: Image.Extermination,
        changeType: 'deleted',
      },
    ],
    [
      {
        eventCode: 'Complectation',
        tooltip: 'Комплектация',
      },
    ],
  ],
};
