import React from 'react'
import { connect } from 'react-redux'

import Breadcrumb from './breadcrumb'
import Button from 'app/components/ui/button/button'
import { breadcrumbsClickAction } from 'redux/actions/common/breadcrumbsAction'

const onBreadcrumbClick = (path, history, breadcrumbsClickAction) => {
  breadcrumbsClickAction(path)
  history.push(path)
}

const breadcrumbs = props => {
  const { history, breadcrumbs, breadcrumbsClickAction, rootItem, customItems } = props
  const {items} = customItems || breadcrumbs
  const noSubmenuItems = items.filter(item =>!item.disabled)
  const { ImgComponent, link } = noSubmenuItems[0] || {}
  const withoutFirstItems = noSubmenuItems.slice(1)
  
  return (
    <div className='breadcrumbs'>
      <div className="first-item">
        {ImgComponent ? (
          <Button
            type = 'image'
            onClick = {() => onBreadcrumbClick(`${link}`, history, breadcrumbsClickAction)}
          >
            <ImgComponent className='button-image'/>
          </Button>
        ) : null}
      </div>
      {
        withoutFirstItems.map((item, i) => {
          const lastPart = withoutFirstItems.length === i + 1
      
          return (
            <Breadcrumb
              {...item}
              onClick={() => onBreadcrumbClick(`${item.link}`, history, breadcrumbsClickAction)}
              lastPart={lastPart}
            />
          )
        })
      }
    </div>
  )
}

const mapStateToProps = state => ({ 
  breadcrumbs: state.breadcrumbs,
})

export default connect(mapStateToProps, { breadcrumbsClickAction })(breadcrumbs)