import React from 'react';

import { formatDate } from 'app/core/utility/date';
import { formatSKZI, licenseKey } from 'app/components/list/Table/TableBody';
import ListLink from 'app/components/ui/ListLink';
import { MAINTENANCE_CARD } from 'app/components/ui/constants';

export default [
  {
    title: '№',
    titleInfo: '№',
    sortByValue: item => item.id,
    alias: 'id',
    visibleOnStart: true,
    userSorting: true,
    width: 50,
  },
  {
    title: 'Наименование',
    alias: 'skzi',
    sortByValue: item => {
      const skziItem = item.skzi;
      const arr = [];
      skziItem.skzi && arr.push(skziItem.skzi.name);
      skziItem.version && arr.push(skziItem.version.name);
      skziItem.build && arr.push(skziItem.build.name);
      skziItem.skziClass && arr.push(skziItem.skziClass.name);
      return arr.join('');
    },
    format: item => formatSKZI(item.skzi, item.isStandard === true),
    visibleOnStart: true,
    userSorting: true,
  },
  {
    title: 'Регистрационный №',
    alias: 'serialNumber',
    format: item => item.serialNumber,
    visibleOnStart: true,
    userSorting: true,
    width: 200,
  },

  {
    title: 'Статус',
    alias: 'status',
    format: item => item.status && item.status.name,
    visibleOnStart: true,
    userSorting: true,
  },
  {
    title: 'Дата регистрации',
    alias: 'createDate',
    visibleOnStart: true,
    userSorting: true,
    sortByValue: item => new Date(item.createDate).getTime(),
    format: item => (
      <span className="skzi-expire-date">
        {formatDate(item.createDate, 'dd.mm.yyyy')}
      </span>
    ),
    width: 140,
  },
  {
    title: 'Заключение о возможности эксплуатации',
    alias: 'allowUse',
    format: item => {
      const { allowUse } = item;
      const { id, number = '' } = allowUse || {};

      // я так и не понял почему реакт
      // считает что тут объект, хотя там строка
      const numberString = new String(number);

      return id ? (
        <ListLink text={numberString} targetType={MAINTENANCE_CARD} id={id} />
      ) : (
        numberString
      );
    },
    visibleOnStart: true,
    userSorting: true,
  },
  {
    title: 'Лицензия',
    alias: 'license',
    format: item => {
      const { licenses = [] } = item;
      return licenses.map(item => {
        return (
          <>
            {item.key ? licenseKey(item.key) : '**'}
            <br />
          </>
        );
      });
    },
    visibleOnStart: true,
    userSorting: true,
  },
];
