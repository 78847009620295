import React from 'react';
import { getClassName } from 'app/core/utility/jsx';
import styles from './group.module.scss';

/**
 *
 * @param {object} props
 * @param {JSX.Element} props.children Group content
 * @param {boolean} props.separate Put group to separate area
 * @param {boolean} props.sparse Sparse view
 * @returns {JSX.Element}
 */
export default function Group({ children, separate, sparse }) {
  return (
    <div
      className={getClassName([
        'buttons-manage-panel__group',
        styles.group,
        { [styles.separate]: separate, [styles.sparse]: sparse },
      ])}
    >
      {children}
    </div>
  );
}
