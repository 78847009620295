import React from 'react'
import { formatDate } from 'app/core/utility/date';
import { formatKeyDoc } from 'app/components/list/Table/TableBody';
import ListLink from 'app/components/ui/ListLink'
import { USER_ACCOUNT_CARD_TYPE } from 'app/components/ui/constants';
import { formatFIO } from 'app/core/utility/common';

export default [
  {
    title: '№',
    titleInfo: '№',
    sortByValue: item => item.id,
    alias: 'id',
    visibleOnStart: true,
    userSorting: true,
    width: 50,
  },
  {
    title: 'Наименование',
    alias: 'name',
    format: item => formatKeyDoc(item),
    visibleOnStart: true,
    visibleAlways: true,
    userSorting: true,
    width: 350,
  },
  {
    title: 'Серийный №',
    alias: 'serialNumber',
    format: item => item.serialNumber,
    visibleOnStart: true,
    visibleAlways: true,
    userSorting: true,
  },
  {
    title: '№ экземпляра',
    alias: 'identificationNumber',
    format: item => item.identificationNumber || '',
    visibleOnStart: true,
    userSorting: true,
  },
  {
    title: 'Статус',
    alias: 'status',
    format: item => item.status && item.status.name,
    visibleOnStart: true,
    userSorting: true,
  },
  {
    title: 'Дата регистрации',
    alias: 'createDate',
    format: item => formatDate(item.createDate, 'dd.mm.yyyy'),
    visibleOnStart: true,
    userSorting: true,
  },
  {
    title: 'Срок действия',
    alias: 'expirationDate',
    format: item => formatDate(item.expirationDate, 'dd.mm.yyyy'),
    visibleOnStart: true,
    userSorting: true,
  },
  { 
    title : 'Пользователь СКЗИ',
    alias : 'skziUser',
    format : item => {
      return item.eskziUser
        ? <ListLink
          text={formatFIO(item.eskziUser)}
          targetType={USER_ACCOUNT_CARD_TYPE}
          id={item.eskziUser.id}
        />
        : formatFIO(item.eskziUser)}
    ,
    visibleOnStart : true, 
    userSorting : true, 
  },    
  {
    title: 'Ключевой носитель',
    alias: 'keyCarrier',
    format: item => {
      const { keyCarrier } = item
      const { keyCarrierType, serialNumber = ''} = keyCarrier || {}
      const { name = '' } = keyCarrierType || {}

      return (
        <div className="key-carrier">
          <span>{`${name}`}</span>
          <br/>
          <span>{`${serialNumber}`}</span>
        </div>
      )
    },
    visibleOnStart: true,
    userSorting: true,
  },
]