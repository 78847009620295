import React from 'react'

import Input from 'app/components/ui/Input'
import TextArea from 'app/components/ui/textArea'
import Autocomplete, { Services }  from 'app/components/ui/autocomplete/autocomplete'
import DatePicker from 'app/components/ui/date'
import { notEmpty } from 'app/core/utility/validation'
import { CardState } from 'app/components/ui/card/cardMaterial'

export const cardLines = {
  name: {
    validation: value => {
      return notEmpty(value)
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Input
          className='build-name'
          label = 'Наименование'
          readOnly={cardState === CardState.view}
          value={editValue}
          onChange={value => onChange('name', value)}
          error={error}
        />
      )
    },
  },
  classId: {
    validation: value => {
      return true
    },
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <Autocomplete 
          className='build-classId'
          label='Класс СКЗИ'
          onEmptySearch = {true} 
          readOnly={cardState === CardState.view}
          serviceType={Services.skziClass} 
          onSelect={value => onChange && onChange('classId', value)} 
          error={error}
          value={editValue}
        />
      )
    },
  },
  createDate: {
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <DatePicker 
          className='build-createDate'
          label='Дата добавления'
          readOnly={cardState === CardState.view}
          error={error}
          value={editValue}
          disabled={cardState === CardState.edit || cardState === CardState.create}
        />
      )
    },
  },
  description: {
    renderEdit: props => {
      const { cardState, editValue, onChange, error } = props

      return (
        <TextArea 
          className='build-description'
          label='Описание'
          readOnly={cardState === CardState.view}
          value={editValue} 
          error={error}
          onChange={value => onChange('description', value)}
        />
      )
    },
  },
}