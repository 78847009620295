import React, { useEffect, useState } from 'react'
import withSignatureHOC from 'app/components/HOC/withSignatureHOC'
import BusinessEventForm from './businessEventForm'
import { compose } from 'redux'
import withModal from 'app/components/HOC/ModalHOC'
import { useBusinessEvent } from './useBusinessEvent'
import Overlay from '../../overlay'
import Button from '../../button/button'
import withTooltip from 'app/components/HOC/TooltipHOC'

const ButtonWithTooltip = withTooltip(Button)
const BusinessEventFormWithSign = compose(withSignatureHOC, withModal)(BusinessEventForm)

const onFinishEvent = ({
  setShowForm,
  onClose
}) => {
  setShowForm(false)
  onClose && onClose()
}

function BusinessEventTrigger(props) {
  const {
    className,
    Img,
    items,
    afterAction,
    eventCode,
    tooltip,
    onClose,
    initialShowForm = false,
    formAlert = '',
    // to do
    // после отказа от всех списков надо оставить 
    // только один пропс, который говорит с чем мы работаем: entityType
    // endpointType нужен для генерации подписи - 
    // src\app\services\signatureService.ts 
    endpointType,
    entityType,
    formBody,
    isExternalUser,
  } = props
  const [ item ] = items || []
  const { availableActions = [] } = item || {}
  const matchesEventCode = eventCode => avalableAction => Array.isArray(eventCode)
    ? eventCode.includes(avalableAction.code)
    : avalableAction.code.includes(eventCode);
  const viewModel = availableActions.find(matchesEventCode(eventCode))
  const { code, name } = viewModel || {}
  const [ showForm, setShowForm ] = useState(initialShowForm)
  const hasAvailableAction = eventCode => item => {
    const { availableActions = [] } = item
    return availableActions.some(matchesEventCode(eventCode))
  }
  const allPrerequisitesMet = items.length && items.every(hasAvailableAction(eventCode)) && items.every(i => !i.bizLocked);
  
  const {
    getSignRequestData,
    afterSignCallback,
    inProgress
  } = useBusinessEvent({
    onCancel: () => onFinishEvent({
      setShowForm,
      onClose
    }),
    endpointType,
    entityType
  })

  return (
    <div className={`business-event-trigger business-event-trigger--${code} ${className || ''}`}>
      {inProgress ? <Overlay /> : null}
      {
        Img ? (
          <ButtonWithTooltip 
            type='image'   
            onClick={() => setShowForm(true)}
            tooltip={tooltip}
            disabled={!allPrerequisitesMet}
          >
            <Img className='button-image'></Img>
          </ButtonWithTooltip>
        ) : null
      }
      {showForm ? <BusinessEventFormWithSign
        {...props}
        isExternalUser={isExternalUser}
        items={items || []}
        className={`business-event-trigger__form business-event-trigger__form--${code}`}
        actionCallback={afterSignCallback}
        getSignRequestData={getSignRequestData}
        afterAction={afterAction}
        signType='FinishOneClickAction'
        signHeader={name}
        formAlert={formAlert}
        renderHeader = {() => {
          return (
            <div className='modal__header'>
              {name}
            </div>
          )
        }}
        formBody={formBody}
        viewModel={viewModel}
        onCancel={() => onFinishEvent({
          setShowForm,
          onClose
        })}
      /> : null}
    </div>)
}

export default BusinessEventTrigger