import React, { Component } from 'react'

import Button from 'app/components/ui/button/button'
import { itemActions } from 'app/components/ui/constants'
// import { inProgressOnAction } from  'redux/actions/list/listActions';
import { ReactComponent as EditImg } from 'assets/img/commonVer2/edit_pen.svg'
import { ReactComponent as SaveImg } from 'assets/img/commonVer2/save.svg'
import { decapitalize, capitalize } from 'app/core/utility/common'
import CardActions from './cardActions'

export const CardState = {
  'edit' : 'edit',
  'view' : 'view',
  'create' : 'create'
}


export default class cardMaterial extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editState: props.editOnOpen
    }
  }

  _submitEnabled = () => {
    const { getObjectKeyFunc, data, formData, cardMode } = this.props

    if (!data || cardMode === CardState.create) {
      return true
    }

    return getObjectKeyFunc ? getObjectKeyFunc(data) !== getObjectKeyFunc(formData) : true
  }

  _onSubmit = () => {
    const { onSubmit } = this.props

    onSubmit()
  }

  _validate = () => {
    const { formData, formLines = {}, serverFormViewModel } = this.props
    const { onValidationError } = this.props

    const errors = Object.keys(formLines).reduce((acc, current) => {

      const validationResult = 
            formLines[decapitalize(current)]
              && formLines[decapitalize(current)].validation 
              && formLines[decapitalize(current)].validation(
                  formData[current], 
                  serverFormViewModel ? serverFormViewModel[current]: null
                 )

      return {
        ...acc,
        [capitalize(current)]: validationResult ? validationResult.error : null
      }
    }, {})

    onValidationError(errors)

    const hasErrors = Object.keys(errors).reduce((res, key) => {
      return res || errors[key]
    }, false)

    !hasErrors && this._onSubmit()
  }
  
  _onApply = () => {
    this._validate()
  }

  _onChange = (key, value, index) => {
    const { onChange } = this.props

    onChange && onChange(key, value, index)
  }

  _onCancel = () => {
    const { onCancel, formLines, onValidationError } = this.props

    const emptyErrors = Object.keys(formLines).reduce((acc, current) => {
      
      return {
        ...acc,
        [capitalize(current)]: null
      }
    }, {})

    onValidationError(emptyErrors)
    this.setState({editState : false})
    onCancel && onCancel()
  }

  _renderSaveButtons = () => {
    const { submitTitle } = this.props

    return (
      <>
        <Button
          className='card-management__item'
          type = 'primary'
          onClick = {this._onApply}
          disabled = {!this._submitEnabled()}
        >
          <SaveImg className='button-image' />
          <span className='button-title'>{submitTitle || 'Сохранить'}</span>
        </Button>
        <Button
          className='card-management__item'
          type = 'secondary'
          onClick = {this._onCancel}
        >
          <span className='button-title'>Отмена</span>
        </Button>
      </>
    )
  }

  _setEditStateTrue = () => {
    const { disableEventPanel } = this.props
    this.setState({editState : true})
    disableEventPanel && disableEventPanel();
  }

  _renderEditButtons = () => {
    const { editState } = this.state
    const { canEdit = false, actions, formData } = this.props
    const { bizLocked } = formData || {}

    if (!actions) {
      return this._renderSaveButtons()
    }

    if (!canEdit) {
      return null
    }

    return editState ? this._renderSaveButtons() : (
      <>
        {!bizLocked ? (<Button
          className='card-management__item'
          type = 'primary'
          onClick = { this._setEditStateTrue  }
        >
          <EditImg className='button-image' />
          <span className='button-title'>Редактировать</span>
        </Button>
        ) : null}
      </>
    )
  }

  _renderOneClickActions = () => {
    const { renderOneClickActions } = this.props

    return renderOneClickActions && renderOneClickActions()
  }

  _renderActions = () => {
    const { onActionClick, actions = [], dowloadUrls, editOnOpen, children, customActionProps = {}, commonDataForm } = this.props
    const { editState } = this.state
    const { bizLocked } = commonDataForm || {}

    if (editOnOpen) {
      return null
    }
    
    return (
      <>
        <CardActions
          onActionClick={onActionClick}
          actions={bizLocked ? [] : actions}
          dowloadUrls={dowloadUrls}
          customActionProps={customActionProps}
          disabled={editState}
        />
        {children}
      </>
    )
  }

  _renderCardButtons = () => {
    const { submitTitle } = this.props

    return  (
      <>
        <Button
          className='card-management__item'
          type = 'text'
          onClick = {this._onApply}
          disabled = {!this._submitEnabled()}
        >
          <span className='button-title'>{submitTitle || 'Сохранить'}</span>
        </Button>
        <Button
          className='card-management__item'
          type = 'text'
          onClick = {this._onCancel}
        >
          <span className='button-title'>Отмена</span>
        </Button>
      </>
    )
  }

  _renderContent = (cardState) => {
    const {
      formLines = {},
      errorByFields = {},
      formData = {},
      data = {},
      serverFormViewModel,
      onAdd = () => {},
      onRemove = () => {},
      cardInProgressAction = () => {},
    } = this.props
    const { cardProp } = this.state
    const actualFormLinesKeys = serverFormViewModel 
                              ? Object.keys(formLines).filter(item =>  serverFormViewModel[capitalize(item)])
                              : Object.keys(formLines)

    return (
      <>
        {
          actualFormLinesKeys.map(key => {
            const capitalizeKey = capitalize(key)
            const lineViewModel = serverFormViewModel ? serverFormViewModel[capitalizeKey] : null
            const props = {
              ...this.props,
              cardState: cardState,
              onAdd,
              onRemove,
              value: data[lineViewModel ? capitalizeKey : key],
              serverFieldViewModel: lineViewModel,
              editValue: formData[lineViewModel ? capitalizeKey : key],
              onChange: this._onChange,
              error: errorByFields[capitalizeKey],
              // we need some prop to update card for complex card lines
              changeCardProp: (value) => this.setState({cardProp: value}),
              cardProp,
              cardInProgressAction: cardInProgressAction,
            }

            return (
              <>
                {formLines[key].renderEdit(props, lineViewModel)}
              </> 
            )
          })
        }
      </>
    ) 
  }
  
  render() {
    const { editState } = this.state
    const { className, editOnly, editOnOpen, showButtons = true } = this.props
    // to do
    // refactor - pass CardState as prop
    // after complete moving to styleguide 2.0
    const cardState = editOnOpen && !editOnly
                      ? CardState.create
                      : editState
                        ? CardState.edit : CardState.view

    return (
      <aside className={`card-material card-material--${cardState} ${className || ''}`}>
        {showButtons && !editOnly ? (
          <div className="card-material__management">
            {this._renderEditButtons()}
            {this._renderActions()}
            {this._renderOneClickActions()}
          </div>
        ) : null}
        <div className="card-material__content">
          {this._renderContent(cardState)}
        </div>
        {showButtons && editOnly ? (
          <div className="card-material__edit-card-management">
            {this._renderCardButtons()}
          </div>
        ) : null}
      </aside>
    )
  }
}
