import React from 'react'
import { ORG_CARD_TYPE, OKI_CARD_TYPE, USER_ACCOUNT_CARD_TYPE } from 'app/components/ui/constants';
import ListLink from 'app/components/ui/ListLink';
import { formatSKZI, organizationName } from 'app/components/list/Table/TableBody';
import { formatFIO } from 'app/core/utility/common';
import { formatDate } from 'app/core/utility/date';
import { FilterControlType } from 'app/components/filter/FilterEllement';
import { blockedColumn } from 'app/components/ui/obsoleteTable/columns';

const getTargetType = item => item.oki && item.oki.id !== item.okz.id ? ORG_CARD_TYPE : OKI_CARD_TYPE;
export const Config = {
  fieldsConfig : [
    { 
      alias : 'bizLocked',
      format : blockedColumn,
      visibleOnStart : true, 
      visibleAlways : true,
      width : 50 
    },
    { 
      title : '№',
      alias : 'id',
      format : item => item.id,
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 50 
    },
    { 
      title : 'Серийный №',
      alias : 'serialNumber',
      format : item => item.serialNumber,
      visibleOnStart : true, 
      visibleAlways : true,
      userSorting : true, 
      width : 200 
    },      
    { 
      title : 'Заводской №',
      alias : 'factoryNumber',
      format : item => item.factoryNumber,
      visibleOnStart : true, 
      userSorting : true, 
      width : 200 
    },      
    { 
      title : 'Тип',
      alias : 'keyCarrierType',
      format : item => item.keyCarrierType && item.keyCarrierType.name,
      visibleOnStart : true, 
      userSorting : true, 
      width : 200 
    },      
    { 
      title : 'Разовый',
      alias : 'single',
      format : item => item.single ? 'Да' : 'Нет',
      visibleOnStart : true, 
      userSorting : true, 
      width : 200 
    },      
    { 
      title : 'Организация',
      alias : 'okz',
      format : item => {
        const name = organizationName( item.oki || item.okz )
        const { id } = item.oki || item.okz || {}
        const targetType = getTargetType(item)

        return name && id ? <ListLink
          text={name}
          targetType={targetType}
          id={id}
        /> : name || ''
      },
      visibleOnStart : true, 
      userSorting : true, 
      width : 200 
    },      
    { 
      title : 'Пользователь СКЗИ',
      alias : 'eskziUser',
      format : item => {
        return item.eskziUser
          ? <ListLink
            text={formatFIO(item.eskziUser)}
            targetType={USER_ACCOUNT_CARD_TYPE}
            id={item.eskziUser.id}
          />
          : formatFIO(item.eskziUser)}
      ,
      visibleOnStart : true, 
      userSorting : true, 
      width : 250
    },      
    { 
      title : 'Статус',
      alias : 'status',
      format : item => item.status && item.status.name,
      visibleOnStart : true, 
      userSorting : true, 
      width : 200
    },   
    { 
      title : 'Дата изменения',
      alias : 'updateDate',
      format : item => formatDate( item.updateDate, 'dd.mm.yyyy'),
      visibleOnStart : true, 
      userSorting : true,
    },       
  ],
  tablePageFiltersConfig : [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'id', 
      pattern: '^[0-9]*$',
      label : '№',
      extended : false,
      order : 2
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'serialNumbers', 
      label : 'Серийный №',
      extended : false,
      order : 3,
      requestFormatIsArray : true,
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'keyCarrierType', 
      label : 'Тип', 
      serviceType : 'keyCarrierType',  
      extended : false,
      order : 4,
    },
    {
      type : FilterControlType.simpleSearch, 
      alias : 'factoryNumbers', 
      label : 'Заводской №',
      extended : true,
      order : 6,
      requestFormatIsArray : true,
    },  
    {
      type : FilterControlType.dropDown, 
      alias : 'hasKeyDocuments', 
      label : 'Ключевая информация', 
      extended : true,
      order : 7,
      options: [
        { id: 'true', title: 'Записана'},
        { id: 'false', title: 'Не записана'}
      ]
    },
    {
      type : FilterControlType.dropDown, 
      alias : 'single', 
      label : 'Разовый', 
      extended : true,
      order : 8,
      options: [
        { id: 'true', title: 'Да'},
        { id: 'false', title: 'Нет'}
      ]
    },
    {
      type : FilterControlType.autocompletePicker, 
      alias : 'eskziUserId', 
      label : 'Выдано', 
      serviceType : 'skziUser',  
      extended : true,
      order : 9,
    },
    {
      type : FilterControlType.multiselect, 
      alias : 'statuses', 
      label : 'Статус', 
      serviceType : 'instanceStatuses',
      extended : true,
      order : 10
    },
    { 
      type : FilterControlType.dateRangePicker, 
      alias : 'registerDate', 
      label : 'Зарегистрирован',
      extended : true,
      order : 11,
    },
  ]
}
