/** @format */
import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Input from 'app/components/ui/Input';
import withToggle from 'app/components/HOC/ToggleHOC';
import { cssModuleClassNameGetter } from 'app/core/utility/jsx';

import { ReactComponent as ChevronImg } from 'assets/img/commonVer2/chevron.svg';
import Button, { buttonTypes } from '../button/button';
import PropType from 'prop-types';

import styles from './DropDown.module.scss';

const getClass = cssModuleClassNameGetter(styles);

export const YesNo = [
  {
    id: 'Yes',
    title: 'Да',
  },
  {
    id: 'No',
    title: 'Нет',
  },
];

class DropDown extends Component {
  static get propTypes() {
    return {
      className: PropTypes.string,
      placeholder: PropType.oneOfType([PropType.string, PropType.bool]),
      items: PropTypes.array,
      active: PropTypes.object,
    };
  }

  constructor(props) {
    super(props);
    this.state = { selectedItemCount: 0, itemsRefs: [] };
  }

  _onSelect = item => {
    const { toggle, toggleStatus, onSelect, active, value } = this.props;
    const { title: activeTitle } = active || {};
    const { title: selectedTitle, noData, disabled } = item || {};

    if (disabled) {
      return;
    }

    !noData && activeTitle !== selectedTitle && onSelect && onSelect(item);
    toggleStatus && toggle();
  };

  _onArrowDown = () => {
    const { items, toggleStatus } = this.props;
    const { itemsRefs, selectedItemCount } = this.state;
    const selectedCount = Math.min(items.length, selectedItemCount + 1);
    const currentRef = itemsRefs[selectedCount - 1];

    if (items.length && toggleStatus) {
      toggleStatus && this.setState({ selectedItemCount: selectedCount });
      currentRef && currentRef.current && currentRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  _onSubmit = () => {
    const { items, toggleStatus } = this.props;
    const { selectedItemCount } = this.state;

    if (toggleStatus && items.length && selectedItemCount) {
      this.setState({}, () => {
        this._onSelect(items[selectedItemCount - 1]);
      });
    }
  };

  _onChange = (_1, _2, isClear) => {
    if (isClear) {
      this._onSelect(null);
    }
  };

  _onArrowUp = () => {
    const { items, toggleStatus } = this.props;
    const { itemsRefs, selectedItemCount } = this.state;
    const selectedCount = Math.max(1, selectedItemCount - 1);
    const currentRef = itemsRefs[selectedCount - 1];

    if (items.length && toggleStatus) {
      this.setState({ selectedItemCount: selectedCount });
      currentRef && currentRef.current && currentRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  _onMouseEnter = index => {
    this.setState({ selectedItemCount: index + 1 });
  };

  _onExpand = () => {
    const { disabled, readOnly, toggle } = this.props;

    !(disabled || readOnly) && toggle();
  };

  _onBlur = () => {
    const { toggle, toggleStatus } = this.props;
    toggleStatus && toggle();
    this.setState({ selectedItemCount: 0 });
  };

  _renderArrow = () => {
    return (
      <Button type={buttonTypes.imageSmall} onClick={this._onExpand}>
        <ChevronImg className="control__custom-image" />
      </Button>
    );
  };

  render() {
    const { className, items, active, expandDirection, toggleStatus, disabled, error } = this.props;
    const { title: activeTitle } = active || {};
    const panelItems = items.length ? items : [{ title: 'нет данных', noData: true }];
    const { selectedItemCount, itemsRefs } = this.state;

    return (
      <div className={getClass(['drop-down', className, { 'drop-down--disabled': disabled }], ['dropDown'])}>
        <Input
          {...this.props}
          className=""
          error={error}
          onFocus={this._onExpand}
          value={activeTitle || ''}
          onBlur={this._onBlur}
          CustomImage={this._renderArrow}
          toggleStatus={toggleStatus}
          onArrowDown={this._onArrowDown}
          onArrowUp={this._onArrowUp}
          onSubmit={this._onSubmit}
          onChange={this._onChange}
        />
        {toggleStatus ? (
          <ul className={`drop-down__list list--${expandDirection || 'down'} `}>
            {panelItems.map((el, index) => {
              const { title, disabled } = el;
              const itemRef = React.createRef();
              itemsRefs[index] = itemRef;

              return (
                <li
                  key={title}
                  ref={itemRef}
                  className={`list-item ${title === activeTitle ? 'list-item--active' : ''} ${
                    disabled ? 'list-item--disabled' : ''
                  } 
                ${selectedItemCount === index + 1 ? 'list-item--selected' : ''}`}
                  onMouseDown={() => this._onSelect(el)}
                  onMouseEnter={() => this._onMouseEnter(index)}
                >
                  {title}
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    );
  }
}

export default compose(withToggle)(DropDown);
