import React, { useEffect, useState } from 'react'

import { useHistory } from "react-router-dom";
import ExpandingBlock from 'app/components/ui/expandingBlock'
import TableComponent from 'app/components/ui/obsoleteTable/table';
import TextArea from 'app/components/ui/textArea'
import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { formatDate } from 'app/core/utility/date'
import TrainingButton from './trainingButton'
import DownloadButton from './downoadBuildButton'
import { sortDirection } from 'app/components/ui/constants';
import withEndlessScrollHOC from 'app/components/HOC/EndlessScrollHOC'
import service from 'app/services/service'

const Table = withEndlessScrollHOC(TableComponent)

const downloadButtonVisible = (props) => {
  const { selfServiceState, lastBuild } = props

  if (!lastBuild) {
    return false
  }

  switch (selfServiceState) {
    case 'HasAdmittance':
    case 'HasEskzi':
      return true
    default:
      return false
  }
}

const buildColumns = [
  {
    title: 'Наименование',
    alias: 'name',
    settings: {
      useSorting: true,
    }
  },
  {
    title: 'Дата добавления',
    alias: 'createDate',
    settings: {
      useSorting: true,
      width: 400
    },
    renderTemplate: item => {
      const { createDate } = item
      
      return formatDate(createDate, 'dd.mm.yyyy')
    }
  },
]

const courseColumns = [
  {
    title: 'Наименование',
    alias: 'name',
    settings: {
      useSorting: true,
    }
  },
  {
    title: 'Вложения',
    alias: 'materialsCount',
    settings: {
      useSorting: true,
      width: 200
    }
  },
  {
    title: 'Активен',
    alias: 'isActive',
    settings: {
      useSorting: true,
      width: 200
    },
    renderTemplate: item => {
      const { isActive } = item
      
      return isActive ? 'Да' : 'Нет'
    }
  },
  {
    title: 'Дата изменения',
    alias: 'changeDate',
    settings: {
      useSorting: true,
      width: 300
    },
    renderTemplate: item => {
      const { changeDate } = item
      
      return formatDate(changeDate, 'dd.mm.yyyy')
    }
  },
]

const _getBuildsTableOptions = (onSort, history, props) => {
  const {profile, versionId, skziId, skziName } = props

  return {
    sortable: {
      defaultColumn: 'name',
      defaultDirection: sortDirection.desc,
      onChange: onSort
    },
    useActions: {use: false},
    onRowAction: (item) => {
      const buildRights = getResourceRights(profile, RESOURCES.build)
      buildRights.VIEW_CARD && history.push(`/skziRegistry/selfService/skzi/${skziId}/version/${versionId}/build/${item.id}?skziName=${encodeURI(skziName)}`);
    }
  }
}

const _getCoursesTableOptions = (onSort, history, profile) => {
  return {
    sortable: {
      defaultColumn: 'changeDate',
      defaultDirection: sortDirection.desc,
      onChange: onSort
    },
    useActions: {use: false},
    onRowAction: (item) => {
      const courseRights = getResourceRights(profile, RESOURCES.course)
      courseRights.VIEW_CARD && history.push(`/catalog/trainingCourses/card/${item.id}`);
    }
  }
}


export default (props) => {
  const { 
    profile,
    skziId,
    versionId,
    name,
    selfServiceState, 
    skzi, 
    builds, 
    onCourceAssign,
    onExamRequest,
    inProgressAction,
  } = props
  
  const { name: skziName, description } = skzi || {}
  const [changed, setChanged] = useState(null)
  const [downloadCount, setDownloadCount] = useState(0)
  const [downloadText, setDownloadText] = useState('')
  const [lastBuild, setLastBuild] = useState(null)
  const [buildSort, setBuildSort] = useState({
    column: 'id',
    direction: sortDirection.desc
  })
  const [coursesSort, setCoursesSort] = useState({
    column: 'changeDate',
    direction: sortDirection.desc
  })
  
  const onBuildsSort = (column) => {
    setBuildSort(prevState => {
      return {
        column: column,
        direction: prevState.direction === sortDirection.desc ? sortDirection.asc : sortDirection.desc
      }
    })
  }

  const onCoursesSort = (column) => {
    setCoursesSort(prevState => {
      return {
        column: column,
        direction: prevState.direction === sortDirection.desc ? sortDirection.asc : sortDirection.desc
      }
    })
  }

  // логика обновления данных после скачивания\установки
  useEffect(() => {
    const localFunc = async () => { 
      if (downloadCount !== 0) {
        // актуализируем список билдов
        lastBuild && setChanged({deleted: [lastBuild.id]})
  
        // обновляем последний билд
        inProgressAction(true)
        const response = await service(
                          'versionService', 
                          'getSelfServiceBuilds', 
                          {
                            skziId,
                            versionId,
                            column: 'id',
                            direction: sortDirection.desc,
                            page: 1,
                            pageSize: 1
                          })
        const {data, isError} = response || {}
        const [responseLastBuild] = data || []
        
        if (!isError) {
          setLastBuild(responseLastBuild)
          setDownloadText(responseLastBuild ? `Скачать ${skziName} ${name} (${responseLastBuild.name})` : '')
        } else {
          setLastBuild(null)
          setDownloadText('')
        }
  
        inProgressAction(false)
      } else {
        const last = builds.slice().sort((a, b) => b.id - a.id)[0]
        setLastBuild(last)
        setDownloadText(last ? `Скачать ${skziName} ${name} (${last.name})` : '')
      }
    }
    localFunc()
  }, [downloadCount])

  return (
    <ExpandingBlock
      className='skzi-version-list__skzi-version'
      renderHeader= {() => `${skziName} ${name}`}
    >
      <div className="skzi-version manage-panel">
        <TrainingButton
          selfServiceState={selfServiceState}
          versionId={versionId}
          onCourceAssign={onCourceAssign}
          onExamRequest={onExamRequest}
        />
        {downloadButtonVisible({selfServiceState, lastBuild})? (
          <DownloadButton
            lastBuild={lastBuild}
            versionId={versionId}
            downloadText={downloadText}
            inProgressAction={inProgressAction}
            afterDowloadAction={() => setDownloadCount(downloadCount + 1)}
          />) : null}
      </div>
      <TextArea 
        className='skzi-version-description'
        value={description} 
        label={'Описание'}
        readOnly={true}
      />
      <ExpandingBlock
        className='skzi-version-buils'
        renderHeader= {() => `Обучающие курсы`}
      >
        <Table
          loadData = {({page, pageSize}) => service('courseService', 'getVersionCourses', {skziId,versionId, ...coursesSort, page, pageSize})}
          dependencies = {[coursesSort]}
          columns={courseColumns}
          noBorders={true}
          options={_getCoursesTableOptions(onCoursesSort, useHistory(), profile)}
        />
      </ExpandingBlock>
      <ExpandingBlock
        className='skzi-version-buils'
        renderHeader= {() => `Сборки`}
      >
        <Table
          loadData = {({page, pageSize}) => service('versionService', 'getSelfServiceBuilds', {skziId, versionId, ...buildSort, page, pageSize})}
          dependencies = {[buildSort]}
          changedRows = {changed}
          columns={buildColumns}
          noBorders={true}
          options={_getBuildsTableOptions(onBuildsSort, useHistory(), { profile, versionId, skziId, skziName })}
        />
      </ExpandingBlock>
    </ExpandingBlock>
  )
}
