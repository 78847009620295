/** @format */

import Container from 'app/components/MassImports/Container';
import Button, { buttonTypes } from 'app/components/ui/button/button';
import { useEffect, useState } from 'react';
import withModal from 'app/components/HOC/ModalHOC';
import Img, { Image } from 'app/components/ui/Img';
import { Table } from 'app/components/list/Table/Table';
import { ActionCreator } from 'redux/actions/uploadProcessing/uploadProcessing';
import Overlay from 'app/components/ui/overlay';
import withEndlessScrollHOC from 'app/components/HOC/EndlessScrollHOC';
import service from 'app/services/service';
import useReducerTable from 'app/components/list/Table/useTableExtensions';
import { useDispatch } from 'react-redux';
import { FilterFileContent } from 'app/components/MassImports/FilterFileContent';
import Severity from 'app/core/types/severity';
import { useInitResultFile } from 'app/components/MassImports/hooks/useInitResultFile';
import { getEnumTypeByString } from 'app/components/MassImports/utils';
import { Url } from 'app/components/MassImports/enums';
import { FileImportRowStatus } from 'app/components/MassImports/enums';
import withTooltip, { Position } from 'app/components/HOC/TooltipHOC';
import { cssModuleClassNameGetter } from 'app/core/utility/jsx';

import commonModalStyles from '../modalCommon.module.scss';
import styles from './CreationResult.module.scss';

const getClass = cssModuleClassNameGetter(commonModalStyles);

const TableWithEndlessScroll = withEndlessScrollHOC(Table);
const GroupTableWithEndlessScroll = withEndlessScrollHOC(Table);
const WithModal = withModal(Container);
const TooltippedButton = withTooltip(Button);

const DownloadGroup = {
  All: 'all',
  Created: 'created',
  Duplicates: 'duplicates',
  Errors: 'errors',
};

export default function CreationResult({ parseFileInfo }) {
  const { fileId, fileType, fileStatus } = parseFileInfo;
  const dispatch = useDispatch();
  const [records, setRecords] = useState([]);
  const [downloadGroups, setDownloadGroups] = useState([]);
  const [isDisabledGroupDuplicates, setDisabledGroupDuplicates] =
    useState(false);
  const [isGroupDuplicates, setGroupDuplicates] = useState(false);
  const [totalCountDownloads, setTotalCountDownloads] = useState(0);

  const { config, duplicateConfig, importsPayload, setImportsPayload, title } =
    useInitResultFile(getEnumTypeByString(fileType), fileStatus);

  // Backend returns aggregate data only when the first page gets requested
  // (leaky abstraction for the sake of optimization),
  // this state setter wrapper keeps the aggregate data intact if the backend omits them
  const setRecordsOptimized = newData => setRecords({ ...records, ...newData });

  const {
    sortListAction,
    onToggleExpandItem,
    setSelectedAction,
    onTreeInitValues,
    selectedItems,
    inProgress,
    sorting,
  } = useReducerTable({
    defaultSort: {
      column: importsPayload && importsPayload.sortBy,
      direction: importsPayload && importsPayload.sortDirection,
    },
  });

  const onListChanged = list => {
    onTreeInitValues({ sorting, config: duplicateConfig.fieldsConfig, list });
  };

  const onChipsClickHandler = (type, val) => {
    if (type === DownloadGroup.All && !val) {
      return;
    }

    const downloadGroupAllSet = type === DownloadGroup.All && val;
    const updatedGroupDuplicatesValue =
      isGroupDuplicates && !(type === DownloadGroup.Duplicates && !val);
    setGroupDuplicates(updatedGroupDuplicatesValue);
    if (type === DownloadGroup.Duplicates) {
      setDisabledGroupDuplicates(!val);
    }

    setDownloadGroups(prevDownloadGroups => {
      const updatedDownloadGroupItems = prevDownloadGroups
        .filter(dg => dg.type !== DownloadGroup.All)
        .map(dg => ({
          ...dg,
          isActive:
            !downloadGroupAllSet && (dg.type === type ? val : dg.isActive),
        }));
      const updatedDownloadGroupAllItem = {
        ...prevDownloadGroups.find(dg => dg.type === DownloadGroup.All),
        isActive:
          downloadGroupAllSet ||
          updatedDownloadGroupItems.every(dg => !dg.isActive),
      };
      const downloadGroupItems = [
        updatedDownloadGroupAllItem,
        ...updatedDownloadGroupItems,
      ];
      const downloadGroupActiveState = Object.fromEntries(
        downloadGroupItems.map(dg => [dg.type, dg.isActive])
      );

      setImportsPayload(prevState => ({
        ...prevState,
        conditions: {
          ...prevState.conditions,
          groupDuplicates: updatedGroupDuplicatesValue,
          statuses: Object.entries({
            [DownloadGroup.Created]: FileImportRowStatus.IMPORTED,
            [DownloadGroup.Duplicates]: FileImportRowStatus.DUPLICATE,
            [DownloadGroup.Errors]: FileImportRowStatus.ERROR,
          })
            .filter(
              e =>
                downloadGroupActiveState[DownloadGroup.All] ||
                downloadGroupActiveState[e[0]]
            )
            .map(e => e[1]),
        },
      }));
      return downloadGroupItems;
    });
  };

  const onDownloadReportBtn = () => {
    service('MassImports', 'downloadReport', {
      fileId,
      importType: Url[getEnumTypeByString(fileType)],
    });
  };

  const onCloseClickButtonHandler = async () => {
    dispatch(ActionCreator.setParsedFileInfo(null));
  };

  const onLoadTableData = ({ page, pageSize }) =>
    service('MassImports', 'postImported', {
      payload: {
        ...importsPayload,
        conditions: { ...importsPayload.conditions, fileId },
        page,
        pageSize,
        sortDirection: sorting.direction,
        sortBy: sorting.column,
      },
      importType: Url[getEnumTypeByString(fileType)],
      cb: setRecordsOptimized,
    });

  useEffect(() => {
    const { created = 0, duplicates = 0, errorsCount = 0 } = records;
    const [hasCreated, hasDuplicates, hasErrors] = [
      created > 0,
      duplicates > 0,
      errorsCount > 0,
    ];
    const downloadGroupItems = [
      {
        type: DownloadGroup.All,
        text: `Все записи`,
        handler: onChipsClickHandler,
        isDisabled: !(hasCreated || hasDuplicates || hasErrors),
        isActive: downloadGroups.length
          ? downloadGroups.find(dg => dg.type === DownloadGroup.All).isActive
          : true,
      },
      {
        type: DownloadGroup.Created,
        text: `Уникальные: ${created || 0}`,
        handler: onChipsClickHandler,
        isDisabled: !hasCreated,
        isActive: downloadGroups.length
          ? downloadGroups.find(dg => dg.type === DownloadGroup.Created)
              .isActive
          : false,
      },
      {
        type: DownloadGroup.Duplicates,
        text: `Дубли: ${duplicates || 0}`,
        handler: onChipsClickHandler,
        isDisabled: !hasDuplicates,
        isActive: downloadGroups.length
          ? downloadGroups.find(dg => dg.type === DownloadGroup.Duplicates)
              .isActive
          : false,
      },
      {
        type: DownloadGroup.Errors,
        text: `Ошибки: ${errorsCount || 0}`,
        handler: onChipsClickHandler,
        isDisabled: !hasErrors,
        isActive: downloadGroups.length
          ? downloadGroups.find(dg => dg.type === DownloadGroup.Errors).isActive
          : false,
      },
    ];
    setDownloadGroups(downloadGroupItems);
    setTotalCountDownloads(created);

    const duplicatesVisible =
      downloadGroups.find(dg => dg.type === DownloadGroup.Duplicates)
        ?.isActive ||
      downloadGroups.find(dg => dg.type === DownloadGroup.All)?.isActive;
    setDisabledGroupDuplicates(!(duplicates && duplicatesVisible));
  }, [records]);

  return (
    <WithModal
      onCancel={onCloseClickButtonHandler}
      renderHeader={() => (
        <div className={commonModalStyles.header}>
          {title}
          <TooltippedButton
            type="image"
            notSubmit
            className="btn-close"
            tooltip="Закрыть окно"
            tooltipPosition={Position.BottomLeft}
            onClick={onCloseClickButtonHandler}
          >
            <Img img={Image.CloseBorderless} />
          </TooltippedButton>
        </div>
      )}
      isGlobalOverlay
      className={getClass(['import-load-result-modal', styles.modal], 'modal')}
    >
      {inProgress && importsPayload && duplicateConfig && config && <Overlay />}

      <FilterFileContent
        records={records}
        setPayload={setImportsPayload}
        payload={importsPayload}
        downloadsList={downloadGroups}
        isDisabledGroupDuplicates={isDisabledGroupDuplicates}
        headerText={`Успешно создано записей в системе: ${totalCountDownloads}`}
        headerState={Severity.Success}
        setGroupDuplicatesState={setGroupDuplicates}
        isGroupDuplicates={isGroupDuplicates}
      />
      {config && duplicateConfig && importsPayload && (
        <div className={'modal-container__body'}>
          {importsPayload.conditions.groupDuplicates ? (
            <GroupTableWithEndlessScroll
              fieldsConfig={duplicateConfig.fieldsConfig}
              inProgress={inProgress}
              itemsSelectable={false}
              changedFieldsVisibility={false}
              headerInfo
              itemsSelectableRadio={false}
              isTree
              dependencies={[sorting, importsPayload]}
              sorting={sorting}
              selectedItems={selectedItems}
              loadData={onLoadTableData}
              onToggleExpandItem={onToggleExpandItem}
              sortListAction={sortListAction}
              setSelectedAction={setSelectedAction}
              onListChanged={onListChanged}
            />
          ) : (
            <TableWithEndlessScroll
              sorting={sorting}
              selectedItems={[]}
              fieldsConfig={config.fieldsConfig}
              inProgress={inProgress}
              itemsSelectable={false}
              changedFieldsVisibility={false}
              headerInfo
              itemsSelectableRadio={false}
              dependencies={[sorting, importsPayload]}
              loadData={onLoadTableData}
              sortListAction={sortListAction}
            />
          )}
        </div>
      )}
      <div className={commonModalStyles.footer}>
        <Button
          type={buttonTypes.secondary}
          disabled={false}
          notSubmit
          caption="Скачать отчет"
          onClick={onDownloadReportBtn}
        >
          <Img img={Image.Excel} />
        </Button>
        <Button
          type={buttonTypes.primary}
          disabled={false}
          notSubmit
          caption="Закрыть"
          onClick={onCloseClickButtonHandler}
        />
      </div>
    </WithModal>
  );
}
