
import React from 'react'

import { capitalize, serverlikeErrors, formatFIO } from 'app/core/utility/common'
import { formatDate } from 'app/core/utility/date'
import Table from 'app/components/ui/obsoleteTable/table';
import SignatureInfo from 'app/components/signature/signatureInfo/signatureInfo'
import ExpandingBlock from 'app/components/ui/expandingBlock'

const signInfoColumns = [
  {
    title: 'ФИО участника',
    alias: 'createDate',
    settings: {
      useSorting: false,
    },
    renderTemplate: item => {
      const { user } = item
      
      return formatFIO(user)
    }
  },
  {
    title: 'Email',
    alias: 'Email',
    settings: {
      useSorting: false,
    },
    renderTemplate: item => {
      const { user } = item
      const { email = '' } = user || {}

      return email
    }
  },
  {
    title: 'Срок подписания',
    alias: 'deadLine',
    settings: {
      useSorting: false,
      width: 250
    },
    renderTemplate: item => {
      const { deadLine } = item

      return formatDate(deadLine, 'dd.mm.yyyy')
    }
  },
  {
    title: 'Статус',
    alias: 'status',
    settings: {
      useSorting: false,
      width: 250
    },
    renderTemplate: item => {
      const { signature } = item

      return signature ? 'Подписано' : 'На подписании'
    }
  },
  {
    title: 'Дата подписания',
    alias: 'signDate',
    settings: {
      useSorting: false,
      width: 250
    },
    renderTemplate: item => {
      const { signature } = item
      const { validationTime } = signature || {}

      return formatDate(validationTime, 'dd.mm.yyyy')
    }
  },
  {
    title: 'Подпись',
    alias: 'signature',
    settings: {
      useSorting: false,
    },
    isCustomRender: true,
    renderTemplate: item => {
      const { signature, user } = item
      const { certificateInfo } = signature || {}

      return certificateInfo ? (
        <SignatureInfo 
          signData={{ ...signature, signer: user }}
          title='Данные подписи'
        />
      ) : null
    }
  },
]


export default function SignInfo(props) {
  const { signers, inProgress=false} = props

  return (
    <ExpandingBlock
      className='sign-info'
      renderHeader= {() => 'Подписание комиссией'}
      initialState={true}
    >
      <Table 
        items={signers}
        columns={signInfoColumns}
        noBorders={true}
        options={{}}
        disabled={inProgress}
      />
    </ExpandingBlock>
  )
}
