import { useEffect, useMemo, useState } from 'react';
import { getResourceRights } from 'app/core/auth/auth';
import useListFilter, { getUrlFilterState } from '../list/Filters/useListFilter';
import useTableExtensions from '../list/Table/useTableExtensions';
import { clearErrorAction, dispatchLogic } from 'redux/actions/common';
import { useDispatch, useSelector } from 'react-redux';
import service from 'app/services/service';
import usePagination from '../ui/pagination/serverSidePagination/usePagination';
import { setNeedRefreshByRest } from "redux/actions/notification";
import { useHistory, useLocation } from 'react-router-dom';
import { savePageState, loadPageState } from 'app/core/localStorage/pageStateStore'


export const useJournal = ({
  defaultSort,
  serviceName,
  customService,
  defaultFilterState,
  defaultFilterForRequest,
  resource,
  useFirstOkz // https://jira.infosec.ru/browse/AOKZ-7952
              // поле FirstOkzOrganization, которое появится если пользователь только админ в оки организации. Используется в представлениях для Окз
}) => {
  const globalDispatch = useDispatch()
  const location = useLocation();
  const history = useHistory()
  const error = useSelector(({error}) => error)
  const profile = useSelector(({profile}) => profile)
  const rights = useMemo(() => resource ? getResourceRights(profile, resource) : {}, [profile, resource])
  const { userInfo, firstOkzOrganization } = profile || {};
  const { errorObject } = error || {};
  const {
    onInitValues,
    onInProgress,
    sortListAction,
    onRemoveValues,
    onItemUpdate,
    inProgress,
    sorting,
    selectedItems,
    list,
    setSelectedAction,
    setCurrentPageAction,
    initPagginatorAction,
    restoreLocalSorting
  } = useTableExtensions({
    defaultSort,
  });

  const {
    setCompleteFilterState,
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    dropFiltersAction,
    setVisibleFieldsAction,
    toggleVisibleFieldAction,
    restoreFilterFromLocalStorage,
    filterState,
    filterForRequest,
    fieldsVisible,
    filterFilled,
    setVolume,
    selectedVolume
  } = useListFilter({ defaultFilterState, defaultFilterForRequest });

  const {
    currentPage,
    perPage,
    pageCount,
    setPerPage,
    setTotalItems,
    setCurrentPage,
    selectedObjects,
    setSelectedPagination,
    setSelectedObjects,
    totalCount,
    needRefresh,
    setNeedRefresh,
    restoreCurrentPage
  } = usePagination({
    defaultTotalItems: 0,
    defaultCurrentPage: 1,
  });

  const { organization } = userInfo || {}
  const [ globalOrganization, setGlobalOrg ] = useState(useFirstOkz ?  firstOkzOrganization ?? organization : organization)
  const { id: globalOrgId } = globalOrganization || {}
  const [ filterOpened, setFilterOpened ] = useState(false)
  const [ localStateRestored, setLocalStateRestored ] = useState(false)
  const toggleFilterState = () => {
    setFilterOpened(!filterOpened)
  }

  const onClearFilter = () => {
    setVolume(null)
    setFullFilterStateAction([])
  }
  
  const savePageStateLocally = () => {
    savePageState({
      filterState, 
      filterForRequest, 
      sorting, 
      currentPage,
      localGlobalOrg: globalOrganization,
      volume: selectedVolume
    }, {profile, url: location.pathname})
  }

  const getList = async () => {
    const requestParams = {
      page: currentPage,
      pageSize: perPage,
      column: sorting.column,
      direction: sorting.direction,
      filterData: filterForRequest,
      id: globalOrgId,
    };

    if (globalOrgId) {
      onInProgress(true);
      const response = customService
        ? await customService(requestParams)
        : await service(serviceName, 'getAll', requestParams);
      const { data, dataCount = 0, errors, isError } = response;
      setTotalItems(isError ? 0 : dataCount);
      !isError && setLocalStateRestored(true)
      !isError && onInitValues(data);
      isError && dispatchLogic(globalDispatch, 'ERROR', data, isError, errors);
      onInProgress(false);

      // если получили пустую страницу, идем в начало списка
      if (
        !isError &&
        currentPage !== 1 &&
        dataCount !== 0 &&
        data &&
        data.length === 0
      ) {
        setCurrentPage(1);
        setNeedRefresh(true);
      }
    }
  }

  const handleUrlParams = () => {
    const filter = new URLSearchParams(location.search).get("filter");
    if (filter) {
      const filterData = getUrlFilterState(filter);
      history.replace()
      return filterData
    }
    return null
  }

  const restoreLocalState = (localData) => {
    const { localGlobalOrg, volume } = localData || {}

    setVolume(volume)
    localGlobalOrg && setGlobalOrg(localGlobalOrg)
    restoreFilterFromLocalStorage(localData)
    restoreCurrentPage(localData)
    restoreLocalSorting(localData)
  }
  
  // to do
  // убрать после того как уберем useEffect из компонентов (src\app\components\list\Table\Table.jsx, например),
  // сейчас там в useEffect объекты-зависимости (filterForRequest, sorting) меняются
  // из-за чего тут срабатывает лишний раз
  const filterString = JSON.stringify(filterForRequest);
  const sortString = JSON.stringify(sorting);

  useEffect(() => {
    if (localStateRestored) {
      setCurrentPage(1);
      setNeedRefresh(true);
    }
  }, [filterString, sortString, globalOrgId]);

  useEffect(() => {
    setSelectedObjects([]);
    setSelectedAction([]);
  }, [filterString, globalOrgId]);

  useEffect(() => {
    setNeedRefresh(true);
  }, [currentPage, perPage]);

  const clearError = () => {
    globalDispatch(clearErrorAction());
  };

  // обновляем выбранные при удалении записей
  const deleteItems = data => {
    if (data && data.length) {
      setSelectedPagination({ removed: data.map(i => ({ id: i, ...i })) });
      setNeedRefresh(true);
    }
  };

  const refreshPage = () => {
    setNeedRefresh(true)
  }

  const updateAfterMassOperation = () => {
    setSelectedObjects([])
    setSelectedAction([])
    setNeedRefresh(true)
    // обновление уведомлений по HTTP\REST без SignalR
    globalDispatch(setNeedRefreshByRest(true))
  }

  // обновляем выбранные при изменении записи
  const updateItem = data => {
    onItemUpdate(data);
    setSelectedPagination({ updated: [data] });
  };

  useEffect(() => {
    setVolume(null);
    setNeedRefresh(true);
  }, [globalOrgId]);

  useEffect(() => {
    const urlFilterData = handleUrlParams()
    const localData = loadPageState({profile, url: location.pathname})
    if (urlFilterData) {
      setFilterOpened(true)
      setCompleteFilterState(urlFilterData);
    } else if (localData) {
      restoreLocalState(localData)
    }
  }, []);
  
  useEffect(() => {
    if (needRefresh) {
      savePageStateLocally()
      setNeedRefresh(false);
      getList();
    }
  }, [needRefresh]);

  return {
    // первые объекты это группировка
    // для удобства пользования
    filterTriggerLogic: {
      toggle: toggleFilterState,
      filterFilled,
    },
    filterClearLogic: {      
      onClearFilter,
      disabled: !filterState.length,
    },
    filterLogic: {
      filterState,
      toggleFilterState,
      setFilterStateAction,
      filterForRequest,
      setFilterForRequestAction,
      setFullFilterStateAction,
      filterFilled,
      isOpened: filterOpened,
      dropFiltersAction,
    },
    paginationLogic: {
      currentPage,
      perPage,
      pageCount,
      setPerPage,
      setTotalItems,
      setCurrentPage,
      selectedObjects,
      setSelectedPagination,
      totalCount,
    },
    itemSelectionLogic: {
      selectedItems,
      setSelectedAction,
    },
    objectSelectionLogic: {      
      selectedObjects,
      setSelectedObjects,
    },    
    fieldSelectionLogic: {
      fieldsVisible,
      setVisibleFieldsAction,
      toggleVisibleFieldAction,
    },
    progressLogic: {
      inProgress,
      onInProgress,
    },
    sortingLogic: {      
      sorting,
      sortListAction,
    },
    baseTableLogic: {
      list,
      totalCount,
    },
    tableUpdateLogic: {
      onRemoveValues: deleteItems,
      onItemUpdate: updateItem,
      updateAfterMassOperation,
    },
    globalOrgLogic: {
      setGlobalOrg,
      globalOrgId,
      globalOrganization,
      selectedVolume,
    },
    errorsLogic: {
      errorObject,
      clearError,
    },
    
    rights,
    
    // useListFilter
    setFilterForRequestAction,
    setFullFilterStateAction,
    setFilterStateAction,
    dropFiltersAction,
    setVisibleFieldsAction,
    toggleVisibleFieldAction,
    filterState,
    filterForRequest,
    fieldsVisible,
    filterFilled,

    // useTableExtensions
    onInitValues,
    onInProgress,
    sortListAction,
    inProgress,
    sorting,
    selectedItems,
    list,
    setSelectedAction,
    setCurrentPageAction,
    initPagginatorAction,
    onRemoveValues: deleteItems,
    onItemUpdate: updateItem,
    updateAfterMassOperation,

    // global store
    errorObject,
    profile,

    //pagination
    currentPage,
    perPage,
    pageCount,
    setPerPage,
    setTotalItems,
    setCurrentPage,
    selectedObjects,
    setSelectedPagination,
    totalCount,

    // filter
    setGlobalOrg,
    globalOrgId,
    globalOrganization,
    filterOpened, 
    toggleFilterState,
    onClearFilter,
    setVolume,
    selectedVolume,
    
    refreshPage,
    clearError,
    // не знаю зачем getList наружу торчит,
    // по идее так не должно быть, запрос данных 
    // нигде кроме как внутри хука запускать не надо
    getList,
  };
};
