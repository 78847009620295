import Button from "app/components/ui/button/button"
import service from "app/services/service"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { dispatchLogic } from "redux/actions/common"
import Images from 'app/components/ui/Img/imgMap';
import withTooltip from 'app/components/HOC/TooltipHOC'

const ButtonWithTooltip = withTooltip(Button)

export default function CreateCardButton({needCheckLicense = false, onClick, title, inProgress}){

  const [hasLicenses, setHasLicenses] = useState(!needCheckLicense)
  const dispatch = useDispatch()

  useEffect( () => {
    if(!!needCheckLicense){
      const checkAvailableLicenses = async () => {
        const response = await service('licensingService', 'checkAvailableLicense')
        const {data, isError, errors} = response || {}
        if(response && data){
          setHasLicenses(needCheckLicense === 'eskzi' ? !!response.data.eskziLicenses : !!response.data.keyDocumentLicenses)
        }
        dispatchLogic(dispatch, 'MOCK', data, isError, errors )
      }
      checkAvailableLicenses();
    }
  }, [needCheckLicense])

  return (
    <ButtonWithTooltip className='create-button' type='primary' tooltip={!hasLicenses ? 'Недостаточно лицензий для регистрации нового ключевого документа' : ''} disabled={ inProgress || !hasLicenses } onClick={ onClick } >
      <Images.Plus className='button-image' />
      <span className='button-title'>{ title }</span>
    </ButtonWithTooltip>
  )
}