import {useState} from 'react'
import { notEmpty } from 'app/core/utility/validation';

const allItemsHaveEskzisToReplicate = replicationItems => {
  const failedOrgIds = replicationItems.filter(ri => !ri.copiesNumber).map(ri => ri.organizationId);
  const valid = !failedOrgIds.length;
  const error = valid 
                ? null 
                : {
                    errorCode: "E_EMPTY",
                    errorMessage: "Выберите экземпляры для распространения",
                    isError: true,
                    detail: failedOrgIds
                  }
  return { 
    valid,
    error,
  }
}

export default function useExemplarDialog({
  initialState
}) {

  const [formData, setFormData] = useState({
    validationRules,
    defaultState: initialState
  })

  return {
    formData,
  }
}

const validationRules = {
  transmittalLetterNumber: {
    validation: value => {
      return true;
    },
  },
  transmittalLetterDate: {
    validation: value => {
      return notEmpty(value);
    },
  },
  replicationItems: {
    validation: allItemsHaveEskzisToReplicate
  },
}