/** @format */

import React from 'react';
import { ORG_CARD_TYPE, OKI_CARD_TYPE, USER_ACCOUNT_CARD_TYPE } from 'app/components/ui/constants';
import ListLink from 'app/components/ui/ListLink';
import { formatFIO } from 'app/core/utility/common';
import { formatDate } from 'app/core/utility/date';
import { FilterControlType } from 'app/components/filter/FilterEllement';
import {
  formatSKZI,
  serialNumbers,
  eventLetter,
  eventDate,
  eventPerformer,
  destructionTeam,
  destructionAct,
  deliveryData,
  eventAddress,
  eventAddressId,
  eventAddressOkz,
} from 'app/components/list/Table/TableBody';

export const OkiConfig = {
  fieldsConfig: [
    {
      title: '№',
      titleInfo: '№',
      alias: 'number',
      format: item => item.number,
      visibleOnStart: true,
      visibleAlways: true,
      userSorting: true,
      width: 50,
    },
    {
      title: 'Наименование',
      titleInddfo: 'Наименование',
      alias: 'skzi',
      format: item => {
        if (!item.eskzi) return '';
        return formatSKZI(item.eskzi.skzi, item.eskzi.isStandard === true);
      },
      titleInfo: 'Наименование СКЗИ, эксплуатационной и технической документации к ним, ключевых документов ',
      visibleOnStart: true,
      visibleAlways: true,
      userSorting: true,
      width: 400,
    },
    {
      title: 'Регистрационный №',
      titleInfo:
        'Серийные номера СКЗИ, эксплуатационной и технической документации к ним, номера серий ключевых документов',
      alias: 'serialNumber',
      format: item => item.eskzi && item.eskzi.serialNumber,
      visibleOnStart: true,
      visibleAlways: true,
      userSorting: true,
      width: 350,
    },
    {
      title: 'Номера экземпляров (криптографические номера) ключевых документов',
      alias: 'serialNumbers',
      format: item => serialNumbers(item.eskzi && item.eskzi.serialNumbers),
      visibleOnStart: false,
      userSorting: false,
      width: 300,
    },
    {
      title: 'От кого получены',
      titleInfo:
        'От кого получены или Ф.И.О сотрудника органа криптографической защиты, изготовившего ключевые документы',
      alias: 'receivedFrom',
      format: item => item.receive && item.receive.data.receivedFrom,
      visibleOnStart: true,
      userSorting: false,
      width: 350,
    },
    {
      title: 'Документ о получении',
      titleInfo: 'Дата и номер сопроводительного письма о получении',
      alias: 'receiveLetter',
      format: item => eventLetter(item.receive, '№'),
      visibleOnStart: false,
      userSorting: false,
      width: 300,
    },
    {
      title: 'Выдано',
      titleInfo: 'Ф.И.О. пользователя СКЗИ, получившего СКЗИ',
      alias: 'delivery',
      format: item => {
        const { delivery } = item;
        const { data } = delivery || {};
        const { eskziUser } = data || {};
        const { id } = eskziUser || {};
        const name = formatFIO(eskziUser);

        return name && id ? <ListLink text={name} targetType={USER_ACCOUNT_CARD_TYPE} id={id} /> : name;
      },
      visibleOnStart: true,
      userSorting: false,
      width: 350,
    },
    {
      title: 'Дата получения',
      titleInfo: 'Дата и расписка в получении пользователем СКЗИ',
      alias: 'sendOkLetter',
      format: item => deliveryData(item),
      visibleOnStart: true,
      userSorting: false,
      width: 300,
    },
    {
      title: 'Подключение',
      titleInfo:
        'Ф.И.О. сотрудников органа криптографической защиты, пользователя СКЗИ, произведших подключение (установку)',
      alias: 'installation',
      format: item => eventPerformer(item.installation),
      visibleOnStart: false,
      userSorting: false,
      width: 350,
    },
    {
      title: 'Дата подключения',
      titleInfo: 'Дата подключения (установки) и подписи лиц, произведших подключение (установку) ',
      alias: 'installationDate',
      format: item => eventDate(item.installation),
      visibleOnStart: true,
      userSorting: false,
      width: 350,
    },
    {
      title: 'Номера аппаратных средств',
      titleInfo: 'Номера аппаратных средств, в которые установлены или к которым подключены СКЗИ ',
      alias: 'installationModels',
      format: item => {
        if (!item.installation) return '';
        return item.installation.data.hardwareNumber || '';
      },
      visibleOnStart: false,
      userSorting: false,
      width: 350,
    },
    {
      title: 'Дата уничтожения',
      titleInfo: 'Дата изъятия (уничтожения)',
      alias: 'destructionActionDate',
      format: item => eventDate(item.withdrawal, item.destruction),
      visibleOnStart: true,
      userSorting: false,
      width: 300,
    },
    {
      title: 'Произведшие изъятие',
      titleInfo:
        'Ф.И.О. сотрудников органа криптографической защиты, пользователя СКЗИ, произведших изъятие (уничтожение)',
      alias: 'destructionUser',
      format: item => destructionTeam(item.destruction, item.withdrawal),
      visibleOnStart: false,
      userSorting: false,
      width: 300,
    },
    {
      title: 'Акт об уничтожении',
      titleInfo: 'Номер акта или расписка об уничтожении',
      alias: 'destructionDate',
      format: item => destructionAct(item),
      visibleOnStart: true,
      userSorting: false,
      width: 350,
    },
    {
      title: 'Примечание',
      alias: 'comments',
      type: 'extendData',
      tdText: item => 'Подробнее',
      format: item => item.comments,
      visibleOnStart: false,
    },
  ],
  tablePageFiltersConfig: [
    {
      type: FilterControlType.simpleSearch,
      alias: 'id',
      label: '№',
      pattern: '^[0-9]*$',
      extended: false,
      order: 2,
    },
    {
      type: FilterControlType.simpleSearch,
      alias: 'serialNumbers',
      label: 'Регистрационный N',
      extended: false,
      order: 3,
      requestFormatIsArray: true,
    },
    {
      type: FilterControlType.simpleSearch,
      alias: 'receivedFrom',
      label: 'Получено от',
      extended: true,
      order: 7,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'eskziUserId',
      label: 'Выдано',
      serviceType: 'skziUser',
      multiSelect: false,
      extended: true,
      order: 8,
    },
    {
      type: FilterControlType.dateRangePicker,
      alias: 'registerDate',
      label: 'Зарегистрирован',
      extended: true,
      order: 11,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'producerId',
      label: 'Производитель',
      serviceType: 'producers',
      multiSelect: false,
      extended: true,
      order: 5,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'skziId',
      label: 'Продукт',
      serviceType: 'skzi',
      dependsOn: 'producerId',
      hardDepends: true,
      multiSelect: false,
      extended: true,
      order: 6,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'skziVersionId',
      label: 'Версия',
      serviceType: 'skziVersion',
      dependsOn: 'skziId',
      hardDepends: true,
      dependantDisabled: true,
      multiSelect: false,
      extended: true,
      order: 9,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'skziBuildId',
      label: 'Сборка / Модификация',
      serviceType: 'skziBuild',
      dependsOn: 'skziVersionId',
      hardDepends: true,
      dependantDisabled: true,
      multiSelect: false,
      extended: true,
      order: 10,
    },
    {
      type: FilterControlType.multiselect,
      alias: 'statuses',
      label: 'Статус',
      serviceType: 'instanceStatuses',
      extended: true,
      order: 13,
    },
    {
      type: FilterControlType.dropDown,
      alias: 'hasLicenses',
      label: 'Наличие лицензий',
      extended: true,
      order: 14,
      options: [
        { id: 'true', title: 'С лицензиями' },
        { id: 'false', title: 'Без лицензий' },
      ],
    },
    {
      type: FilterControlType.checkbox,
      alias: 'onlyStandard',
      label: 'Эталонные экземпляры',
      extended: true,
      order: 15,
    },
  ],
};

export const OkzConfig = {
  fieldsConfig: [
    {
      title: '№',
      titleInfo: '№',
      alias: 'number',
      format: item => item.number,
      visibleOnStart: true,
      visibleAlways: true,
      userSorting: true,
      width: 50,
    },
    {
      title: 'Наименование СКЗИ',
      alias: 'skzi',
      format: item => item.eskzi && formatSKZI(item.eskzi.skzi, item.eskzi.isStandard === true),
      titleInfo: 'Наименование СКЗИ, эксплуатационной и технической документации к ним, ключевых документов',
      visibleOnStart: true,
      visibleAlways: true,
      userSorting: true,
      width: 400,
    },
    {
      title: 'Серийные номера СКЗИ',
      titleInfo:
        'Серийные номера СКЗИ, эксплуатационной и технической документации к ним, номера серий ключевых документов',
      alias: 'serialNumber',
      format: item => item.eskzi && item.eskzi.serialNumber,
      visibleOnStart: true,
      visibleAlways: true,
      userSorting: true,
      width: 400,
    },
    {
      title: 'Номера экземпляров',
      titleInfo: 'Номера экземпляров (криптографические номера) ключевых документов',
      alias: 'serialNumbers',
      format: item => serialNumbers(item.eskzi.serialNumbers),
      visibleOnStart: false,
      userSorting: false,
      width: 300,
    },
    {
      title: 'Получено от',
      titleInfo:
        'От кого получены или Ф.И.О сотрудника органа криптографической защиты, изготовившего ключевые документы',
      alias: 'receivedFrom',
      format: item => (item.receive && item.receive.data.receivedFrom) || '',
      visibleOnStart: true,
      userSorting: false,
      width: 500,
    },
    {
      title: 'Документ о получении',
      titleInfo:
        'Дата и номер сопроводительного письма о получении или дата изготовления ключевых документов и расписка в изготовлении',
      alias: 'receiveLetter',
      format: item => eventLetter(item.receive, '№'),
      visibleOnStart: false,
      userSorting: false,
      width: 500,
    },
    {
      title: 'Кому разосланы (переданы)',
      alias: 'sendAddress',
      format: item => {
        const name = eventAddress(item.send);
        const id = eventAddressId(item.send);
        const okz = eventAddressOkz(item.send);
        const targetType = okz ? OKI_CARD_TYPE : ORG_CARD_TYPE;
        return name && id ? <ListLink text={name} targetType={targetType} id={id} /> : name || '';
      },
      visibleOnStart: true,
      userSorting: false,
      width: 350,
    },
    {
      title: 'Документ о передаче',
      titleInfo: 'Дата и номер сопроводительного письма о рассылке (передаче) ',
      alias: 'sendLetter',
      format: item => eventLetter(item.send, '№'),
      visibleOnStart: false,
      userSorting: false,
      width: 300,
    },
    {
      title: 'Подтверждение передачи',
      titleInfo: 'Дата и номер подтверждения или расписка в получении при рассылке (передаче)',
      alias: 'sendOkLetter',
      format: item => eventLetter(item.sendOk, '№'),
      visibleOnStart: true,
      userSorting: false,
      width: 300,
    },
    {
      title: 'Письмо о возврате',
      titleInfo: 'Дата и номер сопроводительного письма о возврате',
      alias: 'returnLetter',
      format: item => eventLetter(item.return, '№'),
      visibleOnStart: true,
      userSorting: false,
      width: 300,
    },
    {
      title: 'Подтверждение возврата',
      titleInfo: 'Дата и номер подтверждения возврата',
      alias: 'returnOkLetter',
      format: item => eventLetter(item.returnOk, '№'),
      visibleOnStart: true,
      userSorting: false,
      width: 300,
    },
    {
      title: 'Дата ввода в действие',
      alias: 'deliveryDate',
      format: item => {
        return formatDate(item.delivery && item.delivery.data.transmittalLetterDate, 'dd.mm.yyyy');
      },
      visibleOnStart: false,
      userSorting: false,
      width: 300,
    },
    {
      title: 'Дата вывода из действия',
      alias: 'withdrawalLetter',
      format: item => eventDate(item.withdrawal),
      visibleOnStart: false,
      userSorting: false,
      width: 300,
    },
    {
      title: 'Дата уничтожения',
      alias: 'destruction',
      format: item => eventDate(item.destruction),
      visibleOnStart: false,
      userSorting: false,
      width: 300,
    },
    {
      title: 'Номер акта или расписка об уничтожении ',
      alias: 'destructionOk',
      format: item => eventLetter(item.destruction, '№', true),
      visibleOnStart: false,
      userSorting: false,
      width: 300,
    },
    {
      title: 'Примечание',
      alias: 'comments',
      type: 'extendData',
      tdText: item => 'Подробнее',
      format: item => item.comments,
      visibleOnStart: false,
    },
  ],
  tablePageFiltersConfig: [
    {
      type: FilterControlType.simpleSearch,
      alias: 'id',
      label: '№',
      pattern: '^[0-9]*$',
      extended: false,
      order: 2,
    },
    {
      type: FilterControlType.simpleSearch,
      alias: 'serialNumbers',
      label: 'Регистрационный N',
      extended: false,
      order: 3,
      requestFormatIsArray: true,
    },
    {
      type: FilterControlType.simpleSearch,
      alias: 'receivedFrom',
      label: 'Получено от',
      extended: true,
      order: 7,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'sentTo',
      label: 'Передано в',
      serviceType: 'organizationServed',
      multiSelect: false,
      extended: true,
      order: 8,
    },
    {
      type: FilterControlType.dateRangePicker,
      alias: 'registerDate',
      label: 'Зарегистрирован',
      extended: true,
      order: 11,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'producerId',
      label: 'Производитель',
      serviceType: 'producers',
      multiSelect: false,
      extended: true,
      order: 5,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'skziId',
      label: 'Продукт',
      serviceType: 'skzi',
      dependsOn: 'producerId',
      hardDepends: true,
      multiSelect: false,
      extended: true,
      order: 6,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'skziVersionId',
      label: 'Версия',
      serviceType: 'skziVersion',
      dependsOn: 'skziId',
      hardDepends: true,
      dependantDisabled: true,
      multiSelect: false,
      extended: true,
      order: 9,
    },
    {
      type: FilterControlType.autocomplete,
      alias: 'skziBuildId',
      label: 'Сборка / Модификация',
      serviceType: 'skziBuild',
      dependsOn: 'skziVersionId',
      hardDepends: true,
      dependantDisabled: true,
      multiSelect: false,
      extended: true,
      order: 10,
    },
    {
      type: FilterControlType.multiselect,
      alias: 'statuses',
      label: 'Статус',
      serviceType: 'instanceStatuses',
      extended: true,
      order: 13,
    },
    {
      type: FilterControlType.dropDown,
      alias: 'hasLicenses',
      label: 'Наличие лицензий',
      extended: true,
      order: 14,
      options: [
        { id: 'true', title: 'С лицензиями' },
        { id: 'false', title: 'Без лицензий' },
      ],
    },
    {
      type: FilterControlType.checkbox,
      alias: 'onlyStandard',
      label: 'Эталонные экземпляры',
      extended: true,
      order: 15,
    },
  ],
};
