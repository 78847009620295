import React, {useMemo, useState} from "react";
import ExpandingBlock from 'app/components/ui/expandingBlock'
import TableComponent from 'app/components/ui/obsoleteTable/table';
import Overlay from 'app/components/ui/overlay'
import ActionPanel from "./ActionPanel";
import useCertificationBlock from "./useCertificationBlock";
import service from "app/services/service";
import { useParams } from "react-router-dom";
import withEndlessScrollHOC from 'app/components/HOC/EndlessScrollHOC'
const Table = withEndlessScrollHOC(TableComponent)

export default function CertificationBlock({inProgress: inProgressParent}){
  const [changed, setChanged] = useState(null)

  const {
    sort,
    inProgress,
    selected,
    setSelected,
    setInProgress,
    buildColumns,
    getTableOptions,
    certificationsTypes,
    onFileUploaded
  } = useCertificationBlock({setChanged})
  const { skziId, versionId, buildId } = useParams()

  return (
    <>
      {(inProgress && !inProgressParent) && <Overlay />}
      <ExpandingBlock
        className='skzi-build-certificates'
        renderHeader= {() => 'Сертификаты соответствия'}
        initialState={true}
      >
        <ActionPanel
          certificationsTypes={certificationsTypes}
          selected={selected}
          setSelected={setSelected}
          setInProgress={setInProgress}
          onFileUploaded={onFileUploaded}
          setChanged={setChanged}
        />
        {skziId && <Table
          loadData = {({page, pageSize}) => service('versionService', 'getAllCertifications', {skziId, versionId, buildId, page, pageSize, ...sort})}
          dependencies = {[sort]}
          changedRows = {changed}
          columns={buildColumns}
          setSelectedObjects={setSelected}
          selectedObjects={selected.items}
          selected={selected}
          noBorders={true}
          options={getTableOptions()}
          disabled={inProgress}
        />}
      </ExpandingBlock>
    </>
  )
}
