import React from 'react'
import { Table } from 'app/components/list/Table/Table'
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import { sortDirection } from 'app/components/ui/constants'
import Overlay from 'app/components/ui/overlay'
import service from 'app/services/service'
import withEndlessScrollHOC from 'app/components/HOC/EndlessScrollHOC'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import config from './tableSchema'

const TableComponent = withEndlessScrollHOC(Table)

export default function KeyDocuments({itemId}) {

  const {
    inProgress,
    sorting,
    sortListAction,
  } = useTableExtensions({
    defaultSort: {
      column: 'createDate',
      direction: sortDirection.desc,
    },
  });

  const dataService = ({page, pageSize}) => service('skziInstanceService', 'getInstanceKeyDocuments', {itemId, ...sorting, page, pageSize})

  return (
    <>
      {inProgress ? <Overlay /> : null}
      <ExpandingBlock
        className='eskzi-licenses'
        renderHeader= {() => 'Ключевые документы'}
        initialState={true}
      >
        <TableComponent
          loadData = {dataService}
          dependencies = {[sorting]}
          sorting = {sorting}
          sortListAction = {sortListAction}
          onItemClick = { item => { return `/keyDocument/oki/card/${ item.id }` } }
          fieldsConfig = { config }
          selectedItems = {[]} 
          selectedObjects = {[]}
        />
      </ExpandingBlock>
    </>
  )
}