import { 
  CARDUPDATE,
  CARDINIT,
  CARDPREINIT,
  CARDPREINITUPDATE,
  CARDRESET,
  CARDINPROGRESS,
  CARDSEREVERUPDATE,
  CARDAFTERCREATE,
  CARD_SET_WARNING,
  DISABLEACTIONPANEL,
  CLEAR_CARD,
  BREADCRUMBSPUSHITEM,
  ESKZICARDGET,
  ESKZICARDTABCLICK,
  ESKZICARDINPROGRESS,
  ESKZICARDSETERRORBYKEY,
  ESKZICARDONRUNEVENT,
  ESKZICARDRESETSTATE,
  ESKZICARDEVENTFORMCHANGED,
  ESKZICARDRUNEVENT,
  ESKZICARDSERVICEGETACTIONS  ,
  ESKZICARDSERVICEUPDATEACTION,
  ESKZICARDSERVICEADDACTION   ,
  ESKZICARDSERVICEDELETEACTION,
  ESKZICARDSERVICEONUPDATEACTION,
  ESKZICARDSERVICEONADDACTION,
  ESKZICARDSERVICERESETACTION,
  ESKZICARDSERVICEONADDFORMCHANGEDACTION,
  ESKZICARDSERVICEONUPDATEFORMCHANGEDACTION,
  ESKZICARDSERVICEONDELETEACTION,
  ESKZICARDGETLICENSES,
  ESKZICARDADDLICENSES,
  ESKZICARDDELETELICENSES
 } from '../actionTypes'

import service from 'app/services/service'
import { getPageBreadcrumb } from 'app/header/breadcrumbs'
import { dispatchLogic } from '../common'


  export const getExternalUserEskziAction = (id) => dispatch => {
    return service('skziService', 'getExternalOrgEskzi', id)
      .then(response => {
        const { data, errors, isError } = response;
        
        return dispatchLogic(dispatch, CARDINIT, data, isError, errors)
      })
  }

export const eskziGetAction = (id, path) => dispatch => {
  service('skziService', 'get', id)
    .then(response => {
      const { data, errors, isError } = response;
      return dispatchLogic(dispatch, ESKZICARDGET, data, isError, errors)
    })
    .then(action => {
      const { payload } = action || {}
      const { skzi } = payload || {}
      const { skzi: skziData, build } = skzi || {}
      const { name: skziName } = skziData || {}
      const { name: buildName } = build || {}

      dispatch({
        type: BREADCRUMBSPUSHITEM,
        payload: getPageBreadcrumb(path, `${skziName} ${buildName}`)
      })
    })
}

export const eskziInProgress = isInProgress => dispatch => {
  dispatch({
    type: ESKZICARDINPROGRESS,
    payload: isInProgress
  })
}


export const eskziTabClickAction = ({ primaryActive , secondaryActive = '' }) => dispatch => {
  dispatch({
    type: ESKZICARDTABCLICK,
    payload: { primaryActive, secondaryActive }
  })
}

export const eskziOnRunEventAction = (eskzi, action) => dispatch => {
  dispatch({
    type: ESKZICARDONRUNEVENT,
    payload: {eskzi, action}
  })
}

export const eskziResetAction = () => dispatch => {
  dispatch({
    type: ESKZICARDRESETSTATE,
    payload: null
  })
}

export const eskziEventFormChanged = (data) => dispatch => {
  dispatch({
    type: ESKZICARDEVENTFORMCHANGED,
    payload: data
  })
}

export const eskziCardServiceOnEditAction = (data) => dispatch => {
  dispatch({
    type: ESKZICARDSERVICEONUPDATEACTION,
    payload: data
  })
}
   
export const eskziCardServiceOnAddAction = (data) => dispatch => {
  dispatch({
    type: ESKZICARDSERVICEONADDACTION,
    payload: data
  })
}

export const eskziCardServiceResetAction = (data) => dispatch => {
  dispatch({
    type: ESKZICARDSERVICERESETACTION,
    payload: data
  })
}

export const eskziCardCreateActionFormAction = (data) => dispatch => {
  dispatch({
    type: ESKZICARDSERVICEONADDFORMCHANGEDACTION,
    payload: data
  })
}

export const eskziCardEditActionFormAction = (data) => dispatch => {
  dispatch({
    type: ESKZICARDSERVICEONUPDATEFORMCHANGEDACTION,
    payload: data
  })
}

export const eskziCardServiceOnDeleteAction = (data) => dispatch => {
  dispatch({
    type: ESKZICARDSERVICEONDELETEACTION,
    payload: data
  })
}

export const eskziRunEventAction = (data) => dispatch => {
  return service('skziInstanceService', 'runEvent', data)
    .then(response => {
      const { data, errors, isError, isWarn } = response;

      return dispatchLogic(dispatch, ESKZICARDRUNEVENT, data, isError, errors, isWarn)
    })
}

export const eskziCardServiceUpdateAction = (data) => dispatch => {
  return service('techJournalService', 'updateAction', data)
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, ESKZICARDSERVICEUPDATEACTION, data, isError, errors)
      return response
    })
}

export const eskziCardServiceAddAction = (data) => dispatch => {
  return service('techJournalService', 'addAction', data)
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, ESKZICARDSERVICEADDACTION, data, isError, errors)
      return response
    })
}

export const eskziCardServiceDeleteAction = (item) => dispatch => {
  service('techJournalService', 'deleteAction', item)
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, ESKZICARDSERVICEDELETEACTION, item, isError, errors)
    })
}

export const eskziCardServiceDeleteRangeAction = (items) => dispatch => {
  return service('techJournalService', 'deleteRangeAction', items)
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, 'MOCk', items, isError, errors)
      return response
    })
}

export const eskziCardServiceGetAction = (data) => dispatch => {
  service('techJournalService', 'getActions', data)
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, ESKZICARDSERVICEGETACTIONS, data, isError, errors)
    })
}

export const eskziCardGetLicenseAction = (data) => dispatch => {
  service('licenseService', 'getEskziLicense', data)
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, ESKZICARDGETLICENSES, data, isError, errors)
    })
}

export const eskziCardGetExtOrgLicenseAction = (data) => dispatch => {
  service('licenseService', 'getExtOrgEskziLicense', data)
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, ESKZICARDGETLICENSES, data, isError, errors)
    })
}

export const eskziCardAddLicenseAction = (data) => async dispatch => {
  return await service('licenseService', 'addEskziLicense', data)
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, ESKZICARDADDLICENSES, data, isError, errors)
    })
}

export const eskziCardDeleteLicenseAction = (data) => async dispatch => {
  return await service('licenseService', 'deleteEskziLicense', data)
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, ESKZICARDDELETELICENSES, data, isError, errors)
    })
}