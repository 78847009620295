import Delete from "app/components/list/TopPanel/Delete";
import React from "react";
import {stringPlural} from "app/core/utility/common";
import {useSelector} from "react-redux";
import {getResourceRights} from "app/core/auth/auth";
import {RESOURCES} from "app/core/auth/resourceByPage";

export default function DeleteControl({disabled = false, inProgress, items, onSubmitSignEnhance, generateDataForRequest}){
  const accessRights = useSelector(({profile}) => profile)
  const rights = getResourceRights( accessRights, RESOURCES.maintenanceRecords );
  return(
    <Delete
      parentDisabled={disabled || inProgress || !rights.DELETE || !items.every(item => item.maintenanceRecordId)}
      customConfirm={() => onSubmitSignEnhance(generateDataForRequest())}
      dialog={()=> {
        const removeArr = ['удалена', 'удалено', 'удалено']
        const recordArr = ['запись', 'записи', 'записей']
        return `Вы действительно хотите удалить записи по обслуживанию? Будет ${stringPlural(items.length, removeArr)} ${items.length} ${stringPlural(items.length, recordArr)}.`
      }}
      afterAction={() => onSubmitSignEnhance(generateDataForRequest())}
      multiply={true}
      inProgress = { inProgress }
      items = { items }
    />
  )
}