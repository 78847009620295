import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'

import ExpandingBlock from 'app/components/ui/expandingBlock'
import TableComponent from 'app/components/ui/obsoleteTable/table';
import Overlay from 'app/components/ui/overlay'

import { formatDate } from 'app/core/utility/date'
import { formatFIO } from 'app/core/utility/common'
import { sortDirection } from 'app/components/ui/constants'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { getResourceRights } from 'app/core/auth/auth'
import service from 'app/services/service'
import withEndlessScrollHOC from 'app/components/HOC/EndlessScrollHOC'
const Table = withEndlessScrollHOC(TableComponent)

const _getTableOptions = (props) => {
  const { sort } = props
  const { column, direction } = sort || {}

  return {
    sortable: {
      defaultColumn: column,
      defaultDirection: direction,
      onChange: (name, direction) => _onSort(props, name, direction)
    },
    // multipleSelect: {use: false},
    useActions: {use: false},
    onRowAction: (item) => _onCardNavigation(item,props)
  }
}

const _onCardNavigation = (item, props) => {
  const { history, profile } = props
  const { id, okz } = item;
  const skziRights = getResourceRights(profile, RESOURCES.eskzi)

  skziRights.VIEW_CARD && history.push(`/${okz ? 'skziAccounting' : 'skziOrganization'}/EskziCard/${id}`)
}

const _columns = (props) => ([
  {
    title: 'Наименование',
    alias: 'eskzi',
    settings: {
      useSorting: true,
      // width: 240
    },
    renderTemplate: item => {
      const { skzi: skziData } = item
      const { version, skzi  } = skziData || {}
      const { name: skziName } = skzi || {}
      const { name: versionName } = version || {}

      return `${skziName} ${versionName}`
    }
  },
  {
    title: 'Регистрационный номер',
    alias: 'serialNumber',
    settings: {
      useSorting: true,
      width: 240
    },
  },
  {
    title: 'Организация',
    alias: 'okz',
    settings: {
      useSorting: true,
      // width: 240
    },
    renderTemplate: item => {
      const { okz, oki } = item
      const { name, shortName } = oki || okz || {}

      return shortName || name
    }
  },
  {
    title: 'Статус',
    alias: 'status',
    settings: {
      useSorting: true,
      width: 240
    },
    renderTemplate: item => {
      const { status } = item
      const { name } = status || {}

      return name || ''
    }
  },
  {
    title: 'Выдано',
    alias: 'eskziUser',
    settings: {
      useSorting: true,
      width: 240
    },
    renderTemplate: item => {
      const { eskziUser } = item

      return formatFIO(eskziUser)
    }
  },
  {
    title: 'Дата изменения',
    alias: 'updateDate',
    settings: {
      useSorting: true,
      width: 240
    },
    renderTemplate: item => {
      const { createDate } = item

      return formatDate(createDate, 'dd.mm.yyyy')
    }
  },  
])

const _onSort = (props, column, direction) => {
  const { setSort } = props

  setSort({ column, direction })
}

const Eskzi = (props) => {
  const { isExternalUser, licenseId, inProgress } = props || {}

  const [ sort, setSort ] = useState({column: 'updateDate', direction: sortDirection.desc })

  const requestData = {itemId: licenseId, ...sort}
  const getAction = isExternalUser 
    ? ({page, pageSize}) => service('licenseService', 'extOrgGetEskzi', {page, pageSize, ...requestData}) 
    : ({page, pageSize}) => service('licenseService', 'getEskzi', {page, pageSize, ...requestData})

  return (
    <section className='license-eskzi'>
      {inProgress ? <Overlay /> : null}
      <ExpandingBlock
        className='license-common-data__requisites'
        renderHeader= {() => 'Связанные экземпляры СКЗИ'}
        initialState={true}
      >
        <Table
          loadData = {getAction}
          dependencies = {[sort]}
          columns={_columns(props)}
          noBorders={true}
          options={_getTableOptions({...props, setSort, sort})}
        />
      </ExpandingBlock>
    </section>
  )
}

const mapStateToProps = state => ({ ...state.licenseCard })

export default connect(
  mapStateToProps,
  { 
  })(Eskzi)