import React from 'react';
import styles from './button.module.scss';

export const buttonTypes = {
  primary: 'primary',
  secondary: 'secondary',
  danger: 'danger',
  warning: 'warning',
  text: 'text',
  navigation: 'navigation',
  image: 'image',
  imageSmall: 'image image-small',
}

export default function Button(props) {
  const { className, type, disabled, children, onClick, notSubmit, dense, caption } = props

  return (
    <button
      className={`button ${className || ''} ${(type || buttonTypes.primary).split(' ').map(t => `button--${t}`).join(' ')} ${ disabled ? 'button--disabled' : ''}`}
      disabled={disabled}
      type={notSubmit ? '' : 'submit'}
      onClick={(e) => {
        e.stopPropagation()
        !disabled && onClick && onClick(e)
      }}
    >
      {children}
      {caption && <span className={styles.caption}>{caption}</span>}
    </button>
    
  )
}
