import { sortDirection } from 'app/components/ui/constants'
import { formatFIO } from 'app/core/utility/common'
import { formatDate } from 'app/core/utility/date'

export const defaultSettings = {
  columns: [
    {
      title: '№',
      alias: 'id',
      settings: {
        useSorting: true,
        width: 100
      }
    },
    {
      title: 'Серийный номер',
      alias: 'serialNumber',
      settings: {
        useSorting: true,
        //width: 600
      },
      renderTemplate: item => {
        const { serialNumber } = item

        return serialNumber || ''
      }
    },
    {
      title: 'Тип',
      alias: 'keyCarrierType',
      settings: {
        useSorting: true,
        //width: 600
      },
      renderTemplate: item => {
        const { keyCarrierType } = item
        const { name } = keyCarrierType || {}

        return name || ''
      }
    },
    {
      title: 'Пользователь',
      alias: 'eskziUser',
      settings: {
        useSorting: true,
        //width: 600
      },
      renderTemplate: item => {
        const { eskziUser } = item

        return formatFIO(eskziUser)
      }
    },
    {
      title: 'Дата создания',
      alias: 'createDate',
      settings: {
        useSorting: true,
        width: 200,
      },
      renderTemplate: item => formatDate(item.createDate, 'dd.mm.yyyy'),
    },
  ],
  sort: { 
    column: 'id',
    direction: sortDirection.desc
  }
} 