/** @format */
import { arrayToIdHashMap, formatFIO } from 'app/core/utility/common';
import service from 'app/services/service';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorAction } from 'redux/actions/common';
import * as CryptoPro from '..';
import { buildKeyLengthList, getErrorObject, keyUsageTypes } from './utility';

const AT_KEYEXCHANGE = 1; //	Использование ключа для шифрования.
const AT_SIGNATURE = 2; // Использование ключа только для подписывания.
const CERTENROLL_INDEX_BASE = 0;
const PROV_DSS = 3;
const PROV_DSS_DH = 13;
const PROV_DH_SCHANNEL = 18;

const CRYPT_STRING_BASE64 = 1;
const CRYPT_EXPORTABLE = 1;
const CRYPT_USER_PROTECTED = 2;
const CONTEXT_USER = 1;
const CONTEXT_MACHINE = 2;

const XCN_CRYPT_UNKNOWN_INTERFACE = 0;
const XCN_NCRYPT_ALLOW_EXPORT_FLAG = 1;
const XCN_CRYPT_HASH_INTERFACE = 2;
const XCN_CRYPT_SECRET_AGREEMENT_INTERFACE = 4;
const XCN_CRYPT_SIGNATURE_INTERFACE = 5;

const XCN_NCRYPT_UI_NO_PROTECTION_FLAG = 0;
const XCN_NCRYPT_UI_PROTECT_KEY_FLAG = 1;

const CERT_DATA_ENCIPHERMENT_KEY_USAGE = 0x10;
const CERT_KEY_ENCIPHERMENT_KEY_USAGE = 0x20;
const CERT_NON_REPUDIATION_KEY_USAGE = 0x40;
const CERT_DIGITAL_SIGNATURE_KEY_USAGE = 0x80;

export default function useCreateCertificate({ afterCreationCallback }) {
  const profile = useSelector(({ profile }) => profile);
  const [pKey, setPKey] = useState(null);
  const [cspInfos, setCspInfos] = useState(null);
  const [pkcs10Request, setRequest] = useState(null);
  const [enroll, setEnroll] = useState(null);
  const [providerList, setProviderList] = useState([]);
  const [algorithmList, setAlgorithmList] = useState([]);
  const [keySizeList, setKeySizeList] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedAlgorithm, setSelectedAlgorithm] = useState(null);
  const [supportedKeySpec, setSupportedKeySpec] = useState();
  const [keySpec, setKeySpec] = useState();
  const [keySize, setKeySize] = useState();
  const [template, setTemplate] = useState();
  const [templateData, setTemplateData] = useState();
  const [comment, setComment] = useState();
  const [inProgress, setInProgress] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    initializePlugin();
  }, []);

  useEffect(() => {
    const setProviders = async () => {
      if (!cspInfos || !template) {
        return;
      }
      let providers;

      try {
        providers = await getCSPList();
      } catch (e) {
        dispatch(errorAction(getErrorObject(e.Number ?? e.message)));
        return;
      }

      setInProgress(true)
      const templateData = await service('certificateRequestService', 'templateInfo', template?.id);
      setInProgress(false)
      const { isError, data } = templateData;
      if (isError) {
        dispatch(errorAction(templateData));
        return
      }
      setTemplateData({...data, oid: data.id})
      
      const providerMap = arrayToIdHashMap(providers, 'type');
      if (providers && providers.length) {
        const titledProviders = data.csp.map(i => ({
          ...providerMap[i],
          title: providerMap[i]?.name,
        }));
        setProviderList(titledProviders);
        setSelectedProvider(titledProviders[0]);
      }
    };

    setProviders();
  }, [cspInfos, template]);

  useEffect(() => {
    if (!selectedProvider || !pKey) {
      return;
    }

    const setProviderForPKey = async provider => {
      const { name, type } = provider;
      await pKey.propset_ProviderName(name);
      await pKey.propset_ProviderType(type);
    };

    const getSupportedKeySpec = async () => {
      const providerInfo = await cspInfos.ItemByName(await pKey.ProviderName);

      return await providerInfo.KeySpec;
    };

    const handleProviderChange = async () => {
      await setProviderForPKey(selectedProvider);
      const supportedKeySpec = await getSupportedKeySpec();

      if (supportedKeySpec) {
        await setSupportedKeySpec(supportedKeySpec);
      }
    };

    handleProviderChange();
    handleKeySpecChange();
  }, [selectedProvider, pKey]);

  useEffect(() => {
    const setAlgorithms = async () => {
      if (!selectedProvider) {
        return;
      }

      const algorithms = await getHashAlgList();
      if (algorithms && algorithms.length) {
        const titledAlgorothms = algorithms.map(i => ({ ...i, title: i.name }));
        setAlgorithmList(titledAlgorothms);
        setSelectedAlgorithm(titledAlgorothms[0]);
      }
    };

    setAlgorithms();
  }, [selectedProvider]);

  useEffect(() => {
    if (!keySpec) {
      return;
    }

    handleKeySpecChange();
  }, [keySpec]);

  const handleKeySpecChange = async () => {
    const providerName = await pKey.ProviderName;
    const cspStatus = await cspInfos.GetCspStatusFromProviderName(
      providerName,
      keySpec & AT_KEYEXCHANGE || keySpec & AT_SIGNATURE
    );
    const algorithm = await cspStatus.CspAlgorithm;
    const dSize = await algorithm.DefaultLength;
    const sizeData = {
      minSize: await algorithm.MinLength,
      maxSize: await algorithm.MaxLength,
    };
    const sizeList = buildKeyLengthList(sizeData);
    const titledSizes = sizeList.map(i => ({ title: i, value: i }));
    setKeySizeList(titledSizes);
    setKeySize({ title: dSize, value: dSize });
  };

  const initializePlugin = async () => {
    setPKey(await createPrivateKey());
    setCspInfos(await createCspInformations());
    setRequest(await createRequestPkcs10());
    setEnroll(await createEnroll());
  };

  const createPrivateKey = async () => {
    try {
      return await CryptoPro.call('X509Enrollment.CX509PrivateKey');
    } catch (e) {
      dispatch(errorAction(getErrorObject(e.Number ?? e.message)));
    }
  };

  const createRequestPkcs10 = async () => {
    try {
      return await CryptoPro.call('X509Enrollment.CX509CertificateRequestPkcs10');
    } catch (e) {
      dispatch(errorAction(getErrorObject(e.Number ?? e.message)));
    }
  };

  const createEnroll = async () => {
    try {
      return await CryptoPro.call('X509Enrollment.CX509Enrollment');
    } catch (e) {
      dispatch(errorAction(getErrorObject(e.Number ?? e.message)));
    }
  };

  const createCspInformations = async () => {
    try {
      const infos = await CryptoPro.call('X509Enrollment.CCspInformations');
      await infos.AddAvailableCsps();
      return infos;
    } catch (e) {
      dispatch(errorAction(getErrorObject(e.Number ?? e.message)));
    }
  };

  const createObjectIds = async () => {
    return await CryptoPro.call('X509Enrollment.CObjectIds');
  };

  const createObjectId = async () => {
    return await CryptoPro.call('X509Enrollment.CObjectId');
  };

  const getCSPList = async () => {
    const count = await cspInfos.Count;
    const res = new Array();
    for (
      let nCSPIndex = CERTENROLL_INDEX_BASE;
      nCSPIndex < count + CERTENROLL_INDEX_BASE;
      nCSPIndex++
    ) {
      const cspInfo = await cspInfos.ItemByIndex(nCSPIndex);
      if (await cspInfo.LegacyCsp) {
        const keySpec = clarifyKeySpec(await cspInfo.Type, await cspInfo.KeySpec);
        res.push({ name: await cspInfo.Name, type: await cspInfo.Type, keySpec: keySpec });
      }
    }
    return res;
  };

  const getHashAlgList = async () => {
    const res = new Array();
    const providerInfo = await cspInfos.ItemByName(await pKey.ProviderName);
    const algorithms = await providerInfo.CspAlgorithms;
    const count = await algorithms.Count;
    for (let nIndex = CERTENROLL_INDEX_BASE; nIndex < count + CERTENROLL_INDEX_BASE; nIndex++) {
      const provAlgorithm = await algorithms.ItemByIndex(nIndex);
      if ((await provAlgorithm.Type) == XCN_CRYPT_HASH_INTERFACE) {
        try {
          const algorithmOid = await provAlgorithm.GetAlgorithmOid(0, 0);
          res.push({ name: await algorithmOid.FriendlyName, algid: await algorithmOid.Value });
        } catch (e) {
          // Иногда есть проблемы с криптопровайдером,
          // непонятно в чем именно, но получение информации об
          // алгоритме выбрасывает ошибку. Просто игнорим их, на тестовой 
          // странице криптопро так и сделано 
          console.log(`algorithm data read error, objid= ${provAlgorithm.objid}`);
          console.error(e);
        }
      }
    }
    return res;
  };

  function clarifyKeySpec(type, keySpec) {
    const isCspException = [PROV_DSS, PROV_DSS_DH, PROV_DH_SCHANNEL].includes(type);

    return isCspException ? AT_SIGNATURE : keySpec;
  }

  const sendRequestToServer = async pkcs10 => {
    if (!pkcs10) {
      return;
    }

    const result = await service('certificateRequestService', 'create', {
      rawRequest: pkcs10,
      userComment: comment,
    });
    const { isError } = result;

    if (isError) {
      dispatch(errorAction(result));
    } else {
      afterCreationCallback && afterCreationCallback();
    }
  };

  const handleCreateRequest = async () => {
    let pkcs10;
    const normalizedkeySpec =
      keySpec === keyUsageTypes.signature ? keyUsageTypes.signature : keyUsageTypes.keyExchange;

    const initResult = await initRequest(normalizedkeySpec);
    if (initResult !== 0) {
      initializePlugin();
      return pkcs10;
    }

    await initKeyUsage(normalizedkeySpec);
    await initEnhancedKeyUsage();
    await initDistinguishedName();
    await initTemplate();
    await initHashAlgorithm();
    await enroll.InitializeFromRequest(pkcs10Request);

    try {
      pkcs10 = await enroll.CreateRequest(CRYPT_STRING_BASE64);
    } catch (e) {
      console.log('generateRequest error', e);
      dispatch(errorAction(getErrorObject(e.Number ?? e.message)));
      initializePlugin();
    }

    return pkcs10;
  };

  const initRequest = async keySpec => {
    await pKey.propset_ProviderName(selectedProvider.name);
    await pKey.propset_ProviderType(selectedProvider.type);
    await pKey.propset_KeySpec(keySpec);
    await pKey.propset_KeyProtection(XCN_NCRYPT_UI_NO_PROTECTION_FLAG);
    await pKey.propset_ExportPolicy(
      templateData.privateKeyExportableKey ? XCN_NCRYPT_ALLOW_EXPORT_FLAG : 0
    );

    await pKey.propset_Length(parseInt(keySize.value));
    // bMachine должно браться из template, но бек это пока не отдает
    // const context = bMachine ? CONTEXT_MACHINE : CONTEXT_USER;
    await pKey.propset_MachineContext(false); //bMachine

    try {
      await pkcs10Request.InitializeFromPrivateKey(CONTEXT_USER, pKey, '');
    } catch (e) {
      console.log('initRequest error', e);
      dispatch(errorAction(getErrorObject(e.Number ?? e.message)));
      return e.Number;
    }

    return 0;
  };

  const initEnhancedKeyUsage = async () => {
    if (!templateData?.keyPurpose?.length) {
      return;
    }

    const enhanced = await CryptoPro.call('X509Enrollment.CX509ExtensionEnhancedKeyUsage');
    const cObjectIds = await createObjectIds();
    templateData?.keyPurpose.forEach(async oid => {
      const cObjectId = await createObjectId();
      await cObjectId.InitializeFromValue(oid);
      await cObjectIds.Add(cObjectId);
    });
    await enhanced.InitializeEncode(cObjectIds);
    (await pkcs10Request.X509Extensions).Add(enhanced);
  };

  const initKeyUsage = async keySpec => {
    const extension = await CryptoPro.call('X509Enrollment.CX509ExtensionKeyUsage');

    if (keySpec === AT_SIGNATURE) {
      await extension.InitializeEncode(
        CERT_DIGITAL_SIGNATURE_KEY_USAGE | CERT_NON_REPUDIATION_KEY_USAGE
      );
    } else if (keySpec === AT_KEYEXCHANGE) {
      await extension.InitializeEncode(
        CERT_KEY_ENCIPHERMENT_KEY_USAGE | CERT_DATA_ENCIPHERMENT_KEY_USAGE
      );
    } else {
      await extension.InitializeEncode(
        CERT_KEY_ENCIPHERMENT_KEY_USAGE |
          CERT_DATA_ENCIPHERMENT_KEY_USAGE |
          CERT_DIGITAL_SIGNATURE_KEY_USAGE |
          CERT_NON_REPUDIATION_KEY_USAGE
      );
    }

    (await pkcs10Request.X509Extensions).Add(extension);
  };

  const initDistinguishedName = async () => {
    if (!profile?.userInfo) {
      return;
    }
    const { organization, snils } = profile?.userInfo
    const { name, inn, ogrn } = organization || {}
    const CN = `CN="${formatFIO(profile?.userInfo)}"`
    const O = name ? `2.5.4.10="${name.replace(/"/g, "\"\"")}"` : null
    const INNLE = inn ? `1.2.643.100.4="${inn}"` : null //1.2.643.3.131.1.1
    const OGRN = ogrn ? `1.2.643.100.1="${ogrn}"` : null
    const SNILS = snils ? `1.2.643.100.3="${snils}"` : null
    const data = [CN, SNILS, O, INNLE, OGRN].filter(i => !!i).join(';')

    const distinguishedName = await CryptoPro.call('X509Enrollment.CX500DistinguishedName');
    await distinguishedName.Encode(data);
    await pkcs10Request.propset_Subject(distinguishedName);
  };

  const initTemplate = async () => {
    if (!templateData) {
      return;
    }

    const { oid, schemaVersion, minorRevision } = templateData;
    const templateObject = await CryptoPro.call('X509Enrollment.CX509ExtensionTemplate');
    const objectId = await CryptoPro.call('X509Enrollment.CObjectId');
    await objectId.InitializeFromValue(oid);
    await templateObject.InitializeEncode(objectId, schemaVersion, minorRevision);
    (await pkcs10Request.X509Extensions).Add(templateObject);
  };

  const initHashAlgorithm = async () => {
    if (!selectedAlgorithm?.algid) {
      return;
    }
    const objectId = await CryptoPro.call('X509Enrollment.CObjectId');
    await objectId.InitializeFromValue(selectedAlgorithm.algid);
    pkcs10Request.propset_HashAlgorithm(objectId);
  };

  const createRequest = async () => {
    try {
      const pkcs10 = await handleCreateRequest();
      await sendRequestToServer(pkcs10);
    } catch (error) {
      console.error('create request error:', error);
      initializePlugin();
    }
  };

  return {
    providerList,
    algorithmList,
    supportedKeySpec,
    selectedProvider,
    selectedAlgorithm,
    template,
    comment,
    keySize,
    keySizeList,
    inProgress,
    setSelectedAlgorithm,
    setSelectedProvider,
    setKeySpec,
    setTemplate,
    setComment,
    setKeySize,
    createRequest,
    setInProgress
  };
}