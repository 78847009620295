import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'


import MainCard, { CardButtons, objectKey } from 'app/components/mainCard/cardComponent'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Tabs from 'app/components/ui/tabs/tabs'
import History from 'app/components/history'
import { getResourceRights } from 'app/core/auth/auth'
import EskziEvents from 'app/components/skzi/Card/Events'
import { EVENT_TYPE } from 'app/components/skzi/Card/Events/types'
import EskziData from './eskziCard'
import Overlay from 'app/components/ui/overlay'
import BusinessEventPanel from "./businessEventPanel/keyDocumentPanel";
import ManagePanel from "app/components/ui/managePanel/managePanel";
import service from 'app/services/service'
import { useDispatch } from "react-redux"
import { dispatchLogic } from "redux/actions/common"
import CertificateData from "app/components/certificates/card/certificateData/certificateData";

import {cardLines} from './cardLines'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { keyDocumentObject } from './createCard'
import { ENTITY_TYPE } from "app/components/ui/managePanel/businessEventTrigger/useBusinessEvent"

import * as mainCardAction from 'redux/actions/card/cardActions'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import KeyCarrier from '../relativeKeyCarrier/keyCarrier'
import { useHistory, useParams } from 'react-router-dom'


export const cardConfig = ({ 
    history,
    updateKeyDocument,
    initKeyDocumentCard,
    breadcrumbsPushAction,
    initKeyDocumentCopyCard,
    keyDocumentId,
    customActionProps
  }) => ({
  cardLines: cardLines,
  buttons: {
    [CardButtons.copy]: async () => {
      await initKeyDocumentCopyCard(keyDocumentId)
      history.push('/keyDocument/oki/create')
    },
    // [CardButtons.download]: null,
    // [CardButtons.delete]: null
  },
  resource: RESOURCES.keyDocument,
  getKey: data => objectKey(data, keyDocumentObject),
  signatureHeaderText: 'Изменение ключевого документа',
  signatureServiceName: 'keyDocumentUpdate',
  signatureEntity: 'RegisterKd',
  xlsDownloadUrl: `/api/v1/keyDocument/${keyDocumentId}/download/xls`,
  updateAction: updateKeyDocument,
  cardInitAction: initKeyDocumentCard,
  getSignatureData: keyDocumentObject,
  pageBreadcrumbTitle: (action) => {
    const { location } = history || {}
    const { pathname } = location || {}
    const { payload } = action
    const { id } = payload || {}

    if( !pathname ) {
      return;
    }
    
    breadcrumbsPushAction(history.location.pathname, `Ключевой документ №${id}`)
  },
  customActionProps: customActionProps
})

const KeyDocumentCard = (props) => {
  const { 
    initKeyDocumentCopyCard,
    updateKeyDocument,
    initKeyDocumentCard,
    breadcrumbsPopAction,
    breadcrumbsPushAction,
    cardInProgressAction,
    cardInProgress,
    viewData,
    profile = {},
    isOkz,
    commonDataForm
  } = props
  const { keyDocumentId } = useParams();
  const { hardware, keyCarrier, serialNumber, certificate } = viewData || {}
  const [ activeTab, setActiveTab ] = useState('Информация')
  const actionRights = getResourceRights(profile, RESOURCES.keyDocActions)
  const [ triggerUpdate, setTriggerUpdate ] = useState( false );
  const history = useHistory();

  const getEventsData = async () => {
    const response = await service('keyDocumentService', 'getEvents', keyDocumentId)
    return response
  }

  const eventAfterAction = async () => {
    cardInProgressAction(true)
    const response = await initKeyDocumentCard(keyDocumentId)
    cardInProgressAction(false)
    setTriggerUpdate(!triggerUpdate)
    return response
  }

  const [hasLicenses, setHasLicenses] = useState(false)
  const dispatch = useDispatch()
  const avalibleLicense = async () => {
    const response = await service('licensingService', 'checkAvailableLicense')
    const {data, isError, errors} = response || {}
    if(response && data){
      setHasLicenses(!!response.data.keyDocumentLicenses)
    }
    dispatchLogic(dispatch, 'MOCK', data, isError, errors )
  }
  const customActionProps = {
    copy: { 
      disabled: !hasLicenses,
      tooltip: hasLicenses ? 'Скопировать' : 'Недостаточно лицензий для регистрации нового ключевого документа',
    }
  }

  useEffect( () => {
    avalibleLicense()
  }, [])

  return (
    <section className='main-card key-document-card'>
      {cardInProgress ? <Overlay /> : null}
      <BreadcrumbsHeader />
      <Tabs 
        activeTab={activeTab} 
        onTabClick={setActiveTab}
      >
        <section tabTitle={'Информация'} className="main-card__data">
          <ExpandingBlock
            renderHeader= {() => 'Реквизиты'}
            initialState={true}
          >
            <MainCard
              {...props}
              itemId={keyDocumentId}
              clearBeforeUnmount={false}
              config={cardConfig({
                history,
                updateKeyDocument,
                initKeyDocumentCard,
                breadcrumbsPopAction,
                breadcrumbsPushAction,
                initKeyDocumentCopyCard,
                keyDocumentId,
                customActionProps
              })}
              useWarning = {false}
            >
              <ManagePanel>
                <BusinessEventPanel
                  items={viewData ? [viewData] : []}
                  keyDocumentId={keyDocumentId}
                  onInProgress={cardInProgressAction}
                  afterAction={eventAfterAction}
                />
              </ManagePanel>
            </MainCard>
          </ExpandingBlock>
          <ExpandingBlock
            renderHeader= {() => 'Ключевой носитель'}
            initialState={true}
          >
            <KeyCarrier
              keyDocumentId = { keyDocumentId } 
              keyCarrier = {keyCarrier}
              keyDocument = { commonDataForm }
              afterActionCallback = {async () => {
                return await initKeyDocumentCard(keyDocumentId)
              }}
            />
          </ExpandingBlock>
        </section>
        {certificate && (
          <section tabTitle={"Сертификат"} className="certificateCard__data">
            <CertificateData certificate={certificate} />
          </section>
        )}
        {actionRights.VIEW_LIST ? 
          <EskziEvents 
            tabTitle={'Мероприятия'}
            getEventsData={getEventsData}
            eskziRights={actionRights}
            afterAction={eventAfterAction}
            type={EVENT_TYPE.keyDocument}
            triggerUpdate={triggerUpdate}
            id={keyDocumentId}
            entityType={ENTITY_TYPE.keyDocument}
          >
            <ManagePanel>
              <BusinessEventPanel
                items={viewData ? [viewData] : []}
                keyDocumentId={keyDocumentId}
                onInProgress={cardInProgressAction}
                afterAction={eventAfterAction}
              />
            </ManagePanel>
          </EskziEvents>
          : null
        }
        {hardware ? (
          <EskziData
            history={history}
            tabTitle={'Экземпляр'}
            profile={profile}
            eskzi={hardware}
          />
        ) : null}
        <History 
          tabTitle={'История'}
          serviceName={'keyDocumentService'}
          itemId={keyDocumentId}
        />
      </Tabs>
    </section>
  )
}

const mapStateToProps = state => {
  const { card, profile } = state

  return {
    ...card,
    profile: profile
  }
}

export default connect(
  mapStateToProps,
  { 
    ...mainCardAction,
    ...breadcrumbsActions
  })(KeyDocumentCard)