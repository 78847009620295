import { formatSKZI } from 'app/components/list/Table/TableBody'
import { sortDirection } from 'app/components/ui/constants'
import { formatFIO } from 'app/core/utility/common'
import { formatDate } from 'app/core/utility/date'


export const defaultSettings = {
  columns: [
    {
      title: 'Наименование',
      alias: 'skzi',
      settings: {
        useSorting: true,
        //width: 600
      },
      renderTemplate: item => {
        const { skzi, isStandard } = item

        return formatSKZI(skzi, isStandard)
      }

    },
    {
      title: 'Регистрационный номер',
      alias: 'serialNumber',
      settings: {
        useSorting: true,
        //width: 600
      },
      renderTemplate: item => {
        const { serialNumber } = item

        return serialNumber || ''
      }
    },
    {
      title: 'Пользователь',
      alias: 'eskziUser',
      settings: {
        useSorting: true,
        //width: 600
      },
      renderTemplate: item => {
        const { eskziUser } = item

        return formatFIO(eskziUser)
      }
    },
    {
      title: 'Дата изменения',
      alias: 'updateDate',
      settings: {
        useSorting: true,
        //width: 600
      },
      renderTemplate: item => {
        const { updateDate } = item

        return formatDate(updateDate, 'dd.mm.yyyy')
      }
    }
  ],
  sort: { 
    column: 'updateDate',
    direction: sortDirection.desc
  }
} 