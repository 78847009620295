import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'

import Tabs from 'app/components/ui/tabs/tabs'
import { cardLines } from './skziData'
import Card from 'app/components/ui/card/cardMaterial'

import Overlay from 'app/components/ui/overlay'
import ConfirmDialog from 'app/components/dialog/confirmDialog/'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import { ReactComponent as PlusImg } from 'assets/img/commonVer2/plus.svg'
import { ReactComponent as DeleteImg } from 'assets/img/commonVer2/delete.svg'
import { ReactComponent as WindowImg } from 'assets/img/commonVer2/new_window.svg'
import ButtonComponent, { buttonTypes } from 'app/components/ui/button/button'

import History from 'app/components/history'
import { arrayToIdHashMap, capitalize, serverlikeErrors } from 'app/core/utility/common'
import { formatDate } from 'app/core/utility/date'
import TableComponent from 'app/components/ui/obsoleteTable/table';
import { itemActions, sortDirection } from 'app/components/ui/constants'
import {cardLines as newVersionLines} from './newVersionDialog'

import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import withAuth from 'app/components/HOC/AuthHOC'
import withModal from 'app/components/HOC/ModalHOC'

import * as skziRegistryAction from 'redux/actions/skziRegistry/skziRegistryAction'
import * as errorAction from 'redux/actions/common'
import * as skziCardAction from 'redux/actions/skziRegistry/skziCardAction'
import ButtonMenu from 'app/components/ui/buttonMenu'
import { orgType } from '../skziVersionCard/skziVersionCard'
import withTooltip from 'app/components/HOC/TooltipHOC'
import withEndlessScrollHOC from 'app/components/HOC/EndlessScrollHOC'
import service from 'app/services/service'

const Button = withTooltip(ButtonComponent)
const ModalCard = withModal(Card)
const Table = withEndlessScrollHOC(TableComponent)

class SkziCard extends PureComponent {
  constructor(props){
    super(props)

    this.state = { 
      tabTitle: 'Информация',
      sort: { 
        column: 'name',
        direction: sortDirection.desc
      },
      versionColumns: [
        {
          title: 'Наименование',
          alias: 'name',
          settings: {
            useSorting: true,
          }
        },
        {
          title: 'Количество сборок',
          alias: 'buildsCount',
          settings: {
            useSorting: true,
          }
        },
        {
          title: 'Дата добавления',
          alias: 'createDate',
          settings: {
            useSorting: true,
            width: 200
          },
          renderTemplate: item => {
            const { createDate } = item
            
            return formatDate(createDate, 'dd.mm.yyyy')
          }
        },
        {
          title: 'Экземпляры СКЗИ',
          alias: 'eskzisCount',
          settings: {
            useSorting: true,
          },
          isCustomRender: true,
          renderTemplate: item => {
            const { eskzisCount, id, name } = item

            return (
              <div className="eskzi-navigation">
                <span className='eskzi-count'>{eskzisCount}</span>
                <ButtonMenu
                  title={'Экземпляры'}
                  type={buttonTypes.secondary}
                  LeftImage={WindowImg}
                >
                  {orgType.map(item => {
                    return (
                      <div key={item.type} className="report-item" onClick={
                        (e) => {
                        e.stopPropagation() 
                        this._onClick(item.type, {id, name})
                      }}>
                        <span className="report-item__title">{item.title}</span>
                      </div>
                    )
                  })}
                </ButtonMenu>
              </div>
            )
          }
        },
      ]
    }
  }

  componentDidMount() {
    const { skziCard, getSkziAction, skziCardInProgress, match = {}, location } = this.props
    const { pathname } = location
    const { params = {} } = match
    const { id } = params
    const { inProgress } = skziCard

    if (inProgress) {
      return
    }
   
    skziCardInProgress(true)
    getSkziAction(id, pathname)
  }

  componentWillUnmount () {
    const { 
      skziCardResetAction,
      clearErrorAction,
      skziCardOnSelect
    } = this.props

    skziCardOnSelect()
    skziCardResetAction()
    clearErrorAction()
  }

  _onOrganizationNavigate = (value) => {
    const { history, skziCardResetAction } = this.props
    const { id, okz } = value || {}

    id && history.push(`/catalog/${okz ? 'okzScheme' : 'organization'}/card/${id}`)
  }

  _onClick = async (type, version) => {
    const { skziCard, history, match } = this.props
    const { skziData } = skziCard
    const { id, name, producer } = skziData || {}
    const { id: producerId, name: producerName } = producer || {}

    const searchFilter = {
      skziId: {id, name},
      skziVersionId: version,
      producerId: {id: producerId, name: producerName}
    }
    const urlPart = orgType.find(item => item.type === type).urlPart

    history.push(`/${urlPart}?filter=${encodeURIComponent(JSON.stringify(searchFilter))}`)
  }

  _onSubmit = () => {
    const { skziCard, updateSkziAction, skziCardInProgress } = this.props
    const { commonDataForm, skziData } = skziCard
    const { name, description, shortDescription, producerId: { id: prId }, skziTypeId: { id: typeId } } = commonDataForm

    const requestBody = {
      id: skziData.id,
      name: name,
      description: description,
      skziTypeId: typeId,
      producerId: prId,
      shortDescription
    }

    skziCardInProgress(true)
    updateSkziAction(requestBody)
  }

  _onCancel = () => {
    const { skziCardOnCancelAction, skziCardResetAction } = this.props

    skziCardResetAction()
    skziCardOnCancelAction()
  }

  _onTabClick = (title) => {
    this.setState({ tabTitle: title})
  }

  _applyRightsToActions = () => {
    const { profile } = this.props
    const actions = []
    const skziRights = getResourceRights(profile, RESOURCES.skzi)

    skziRights.UPDATE && actions.push(itemActions.edit.key)
    skziRights.DELETE && actions.push(itemActions.delete.key)

    return actions
  }

  _onDelete = () => {
    const { skziCardOnDelete } = this.props

    skziCardOnDelete && skziCardOnDelete()
  }

  _onRunDelete = () => {
    const { skziCard, deleteSkziAction, skziCardResetAction, skziCardInProgress } = this.props
    const { skziData } = skziCard

    skziCardResetAction()
    skziCardInProgress(true)
    deleteSkziAction(skziData)
  }

  _onValidation = (errors) => {
    const { setErrorByKey } = this.props

    setErrorByKey(serverlikeErrors(errors))
  }

  _onFormChange = (key, value) => {
    const { skziCard, updateCommonForm, setErrorByKey, error } = this.props
    const { commonDataForm } = skziCard
    const { errorByFields }  = error || {}
     
    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))
    setErrorByKey(filteredErrors)
    updateCommonForm({ ...commonDataForm, [key]: value })
  }

  _onSort = (column, direction) => {
    this.setState({ sort: { column, direction }})
  }

  _onSelect = selectedData => {
    const { skziCardOnSelect } = this.props
    skziCardOnSelect(selectedData)
  }

  _onDeleteSelected = data => {
    const { skziCardOnSelect } = this.props

    skziCardOnSelect({
      items: data,
      selectedHashMap: arrayToIdHashMap(data)
    })
  }

  _onVersionDeleteConfirm = () => {
    const { skziVersionOnDelete, skziCard } = this.props
    const { selected } = skziCard
    const { items } = selected

    skziVersionOnDelete(items)
  }

  _onCloseCard = () => {
    const { skziCardResetAction, clearErrorAction } = this.props

    skziCardResetAction()
    clearErrorAction()
  }

  _onVersionCreate = () => {
    const { skziCard, addVersionAction, skziCardInProgress, match } = this.props 
    const { createVersionForm } = skziCard
    const { params = {} } = match || {}
    const { id } = params

    skziCardInProgress(true)
    addVersionAction({skziId: id, createVersionForm})
  }

  _onCreateVersionFormChange = (key, value) => {
    const { skziCard, updateCreateForm, setErrorByKey, error: { errorByFields }  } = this.props
    const { createVersionForm } = skziCard
    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))

    updateCreateForm({ ...createVersionForm, [key]: value })
    setErrorByKey(filteredErrors)
  }

  _onVersionAdd = () => {
    const {history, match} = this.props
    const { params = {} } = match || {}
    const { id } = params

    history.push(`/skziRegistry/accounting/skzi/${id}/version/create`)

  }

  _onVersionDelete = async () => {
    const { skziCard, skziCardInProgress, skziCardResetAction, errorAction } = this.props
    const { skziData: { id }, selected } = skziCard
    const { items } = selected

    skziCardInProgress(true);
    const response = await service('versionService', 'deleteVersion', { skziId: id, items})
    const { data, errors, isError } = response;
    skziCardResetAction()
    errorAction(response)
    this.setState({changed: {deleted: data}})
  }

  _onCardNavigation = item => {
    const { history, profile, skziCard } = this.props
    const { skziData: { id } } = skziCard
    const { id: versionId } = item;

    const versionRights = getResourceRights(profile, RESOURCES.version)

    versionRights.VIEW_CARD && history.push(`/skziRegistry/accounting/skzi/${id}/version/${versionId}`)
  }

  _getTableOptions = () => {
    const { sort } = this.state
    const { column, direction } = sort

    return {
      sortable: {
        defaultColumn: column,
        defaultDirection: direction,
        onChange: (name, direction) => this._onSort(name, direction)
      },
      multipleSelect: {use: true, onCheck: this._onSelect},
      useActions: {use: false},
      onRowAction: this._onCardNavigation
    }
  }

  _getSkziKey = (skziData) => {
    const { name, producerId, skziTypeId, description, shortDescription } = skziData || {}
    const { id: prId } = producerId || {}
    const { id: typeId } = skziTypeId || {}
    
    return  `${name}|${prId}|${typeId}|${description}${shortDescription}`
  }

  _renderData = () => {
    const { skziCard, profile, skziCardInProgress, errorAction, error, match } = this.props
    const { fieldsError } = error
    const { tabTitle, versionColumns, sort, changed } = this.state
    const { column, direction } = sort
    const { selected, viewData, commonDataForm, inProgress } = skziCard
    const { items } = selected
    const { params = {} } = match || {}
    const { id: skziId } = params
    const versionRights = getResourceRights(profile, RESOURCES.version)
    const skziRights = getResourceRights(profile, RESOURCES.skzi)

    return (
      <Tabs 
        activeTab={tabTitle} 
        onTabClick={this._onTabClick}>
          <section tabTitle={'Информация'} className='skzi-common-data'>
            <ExpandingBlock
              renderHeader= {() => 'Реквизиты СКЗИ'}
              initialState={true}
            >
              <Card
                className='skzi-data-card'
                profile={profile}
                formLines={cardLines}
                actions={this._applyRightsToActions()}
                canEdit={skziRights.UPDATE}
                key={this._getSkziKey(viewData)}
                data={viewData}
                formData={commonDataForm}
                onValidationError={this._onValidation}
                // onActionClick={this._onDelete}
                errorByFields={fieldsError}
                onChange={this._onFormChange}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
                getObjectKeyFunc={this._getSkziKey}
              />
            </ExpandingBlock>
            <ExpandingBlock
              className='skzi-versions'
              renderHeader= {() => 'Версии'}
              initialState={true}
            >
              <div className="skzi-versions__management manage-panel">
                {versionRights.CREATE ? (
                  <Button 
                    className='create-button'
                    type='primary'
                    onClick={this._onVersionAdd}
                  >
                    <PlusImg className='button-image button-image--left'></PlusImg>
                      <span className='button-title'>Добавить</span>
                  </Button>
                ) : null}
                {versionRights.DELETE ? (
                  <div className="buttons-element">
                    <Button 
                      tooltip={'Удалить'}
                      className='delete-button'
                      type='image'
                      onClick={this._onVersionDeleteConfirm}
                      disabled={!items.length}
                    >
                      <DeleteImg className='button-image'></DeleteImg>
                    </Button>
                  </div>
                ) : null}
              </div>

              {skziId && <Table
                loadData = {({page, pageSize}) => service('skziService', 'getSkziVersions', {id: skziId, column, direction, page, pageSize})}
                dependencies = {[sort]}
                changedRows = {changed}
                columns={versionColumns}
                selectedObjects={items}
                setSelectedObjects={this._onDeleteSelected}
                selected={selected}
                noBorders={true}
                options={this._getTableOptions()}
              />}
            </ExpandingBlock>
          </section>
          <History
            tabTitle={'История'}
            serviceName={'skziService'}
            itemId={skziId}
            inProgressCallback={skziCardInProgress}
            errorCallback={errorAction}
          />
      </Tabs>
    )
  }

  _renderModal = () => {
    const { skziCard, skziCardResetAction, error } = this.props
    const { addVersionDialog, confirmObject, deletedInfoObject, deleteVersionConfirmObject, createVersionForm, defaultCreateVersionForm} = skziCard
    const { fieldsError } = error || {}
    const { title: confirmTitle, type: confirmType, item: confirmItem } = confirmObject || {}
    const { title: deleteVersionTitle, type: deleteVersionType, item: deleteVersionItem } = deleteVersionConfirmObject || {}

    return (
      <Fragment>
        {addVersionDialog ?
          <ModalCard
            className='create-version-modal-card modal-card'
            formLines={newVersionLines}
            data={defaultCreateVersionForm}
            formData={createVersionForm}
            errorByFields={fieldsError}
            onValidationError={this._onValidation}
            onChange={this._onCreateVersionFormChange}
            onSubmit={this._onVersionCreate}
            renderHeader = {() => {
              return (
                <div className='modal__header'>
                  Добавление новой версии
                </div>
              )
            }}
            onCancel={this._onCloseCard}
            editOnly={true}
            getObjectKeyFunc={data => `${data.name}${data.description}`}
          /> : null
        }
        {deleteVersionConfirmObject ? 
          <ConfirmDialog
            needCloseIcon = {false}
            title={deleteVersionTitle}
            type={deleteVersionType}
            item={deleteVersionItem}
            onSubmit={this._onVersionDelete}
            onCancel={skziCardResetAction}
          /> : null
        }
        {confirmObject ? 
          <ConfirmDialog
            needCloseIcon = {false}
            title={confirmTitle}
            type={confirmType}
            item={confirmItem}
            onSubmit={this._onRunDelete}
            onCancel={skziCardResetAction}
          /> : null
        }
      </Fragment>
    )
  }

  render() {
    const { skziCard, history } = this.props
    const { inProgress } = skziCard

    return (
      <section className='skzi-card'>
        {inProgress ? <Overlay /> : null}
        {this._renderModal()}
        <BreadcrumbsHeader />
        {this._renderData()}
      </section>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default connect(
  mapStateToProps,
  { 
    ...skziRegistryAction,
    ...errorAction,
    ...skziCardAction
  })(withAuth(SkziCard))