import { ReactComponent as Acceptation } from "assets/img/actionPanel/ic_acceptation.svg";
import { ReactComponent as AcceptationOfReturn } from "assets/img/actionPanel/ic_acceptation-ofreturn.svg";
import { ReactComponent as AccountDownload } from 'assets/img/commonVer2/ic_acc_download.svg';
import { ReactComponent as AccessManagement }  from 'assets/img/menu/ic_settings.svg'
import { ReactComponent as Admittance } from "assets/img/menu/ic_admittance.svg";
import { ReactComponent as Alert } from "assets/img/commonVer2/ic_alert.svg";
import { ReactComponent as AlertTriangle } from "assets/img/commonVer2/ic_alert_triangle.svg";
import { ReactComponent as ArReturn } from "assets/img/actionPanel/ic_ar_return.svg";
import { ReactComponent as ArSending } from "assets/img/actionPanel/ic_ar_sending.svg";
import { ReactComponent as BreakChain } from "assets/img/commonVer2/ic_link_off.svg";
import { ReactComponent as Broom } from 'assets/img/commonVer2/broom.svg';
import { ReactComponent as Calendar } from "assets/img/commonVer2/calendar.svg";
import { ReactComponent as Check } from 'assets/img/commonVer2/ic_check.svg';
import { ReactComponent as CheckCircle } from 'assets/img/commonVer2/ic_check_circle.svg';
import { ReactComponent as Chevron } from 'assets/img/commonVer2/chevron.svg';
import { ReactComponent as Clip } from 'assets/img/commonVer2/clip.svg';
import { ReactComponent as Close } from "assets/img/actionPanel/ic_close.svg";
import { ReactComponent as CloseBorderless } from "assets/img/commonVer2/close.svg";
import { ReactComponent as CloseCircle } from 'assets/img/commonVer2/close_circle.svg';
import { ReactComponent as Complectation } from "assets/img/actionPanel/ic_complectation.svg";
import { ReactComponent as Conclusion } from "assets/img/menu/ic_conclusion.svg";
import { ReactComponent as Copy } from 'assets/img/commonVer2/ic_copy.svg';
import { ReactComponent as Dashboard } from "assets/img/menu/dashboard.svg";
import { ReactComponent as Delete } from "assets/img/commonVer2/delete.svg";
import { ReactComponent as Delivery } from "assets/img/commonVer2/ic_confirm_delivery.svg";
import { ReactComponent as Download } from 'assets/img/commonVer2/download.svg';
import { ReactComponent as DownloadCircle } from "assets/img/actionPanel/ic_download_circle.svg";
import { ReactComponent as DownloadToXls } from "assets/img/commonVer2/excel.svg";
import { ReactComponent as Edit } from "assets/img/commonVer2/edit_pen.svg";
import { ReactComponent as Error } from "assets/img/commonVer2/close_circle.svg";
import { ReactComponent as Etalon } from "assets/img/actionPanel/ic_etalon_sq.svg";
import { ReactComponent as EtalonSqReplication } from "assets/img/actionPanel/ic_etalon_sq_replication.svg";
import { ReactComponent as Excel } from "assets/img/commonVer2/excel.svg";
import { ReactComponent as Extermination } from "assets/img/actionPanel/ic_extermination.svg";
import { ReactComponent as FileUpload } from 'assets/img/commonVer2/ic_file_upload.svg';
import { ReactComponent as Geolocation } from 'assets/img/actionPanel/ic_geo_outline.svg';
import { ReactComponent as HardwareSKZI } from "assets/img/actionPanel/ic_apparat_SKZI.svg";
import { ReactComponent as Journal } from "assets/img/actionPanel/ic_journal.svg";
import { ReactComponent as KeyCarrier } from "assets/img/menu/ic_key_carrier.svg";
import { ReactComponent as KeyDocument } from "assets/img/menu/ic_keydocument.svg";
import { ReactComponent as Loading } from 'assets/img/commonVer2/ic_loading.svg';
import { ReactComponent as License } from "assets/img/menu/ic_key.svg";
import { ReactComponent as Link } from "assets/img/actionPanel/ic_link.svg";
import { ReactComponent as Lock }  from 'assets/img/commonVer2/ic_lock_on.svg';
import { ReactComponent as Main } from 'assets/img/commonVer2/main_circle.svg'
import { ReactComponent as Minimize } from 'assets/img/commonVer2/minimize.svg';
import { ReactComponent as NewWindow } from "assets/img/commonVer2/new_window.svg";
import { ReactComponent as Okz } from "assets/img/menu/ic_directory.svg";
import { ReactComponent as OkzReport } from "assets/img/menu/ic_catalog.svg";
import { ReactComponent as Pause } from "assets/img/commonVer2/pause.svg";
import { ReactComponent as Play } from "assets/img/commonVer2/play.svg";
import { ReactComponent as Plus } from "assets/img/commonVer2/plus.svg";
import { ReactComponent as Question } from "assets/img/commonVer2/ic_question.svg";
import { ReactComponent as Registry } from 'assets/img/menu/ic_registry.svg';
import { ReactComponent as Save } from 'assets/img/commonVer2/save.svg';
import { ReactComponent as Search } from "assets/img/commonVer2/search.svg";
import { ReactComponent as Sign } from 'assets/img/commonVer2/ic_sign.svg'
import { ReactComponent as SoftReturn } from "assets/img/actionPanel/ic_soft_return.svg";
import { ReactComponent as SoftSending } from "assets/img/actionPanel/ic_soft_sending.svg";
import { ReactComponent as Stop } from "assets/img/commonVer2/stop.svg";
import { ReactComponent as Upload } from 'assets/img/commonVer2/ic_upload.svg';
import { ReactComponent as UploadCircle } from "assets/img/actionPanel/ic_upload_circle.svg";
import { ReactComponent as UserAccounts } from "assets/img/menu/ic_userslist.svg";

export default {
  Acceptation,
  AcceptationOfReturn,
  AccessManagement,
  AccountDownload,
  Admittance,
  Alert,
  AlertTriangle,
  ArReturn,
  ArSending,
  BreakChain,
  Broom,
  Calendar,
  Check,
  CheckCircle,
  Chevron,
  Clip,
  Close,
  CloseBorderless,
  CloseCircle,
  Complectation,
  Conclusion,
  Copy,
  Dashboard,
  Delete,
  Delivery,
  Download,
  DownloadCircle,
  DownloadToXls,
  Edit,
  Error,
  Etalon,
  EtalonSqReplication,
  Excel,
  Extermination,
  FileUpload,
  Geolocation,
  HardwareSKZI,
  Journal,
  KeyCarrier,
  KeyDocument,
  Loading,
  License,
  Link,
  Lock,
  Main,
  Minimize,
  NewWindow,
  Okz,
  OkzReport,
  Pause,
  Play,
  Plus,
  Question,
  Registry,
  Save,
  Search,
  Sign,
  SoftReturn,
  SoftSending,
  Stop,
  Upload,
  UploadCircle,
  UserAccounts,
};
