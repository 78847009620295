import React from 'react'
import { connect } from 'react-redux'

import MainCard, {objectKey} from 'app/components/mainCard/cardComponent'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import Tabs from 'app/components/ui/tabs/tabs'
import { cardLines } from './cardLines'
import { formatDate } from 'app/core/utility/date'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import { CARDAFTERCREATE } from 'redux/actions/actionTypes'

import * as mainCardAction from 'redux/actions/card/cardActions'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import { useHistory } from 'react-router-dom'

export const keyCarrierObject = (data) => {
  const { 
    serialNumber,
    keyCarrierType,
    factoryNumber,
    comment,
    single
  } = data
  const { id = 0 } = keyCarrierType || {}

  return {
    serialNumber: serialNumber,
    keyCarrierTypeId: id,
    factoryNumber: factoryNumber,
    comment: comment,
    single: single
  }
}


export const cardConfig = ({ 
    history,
    registerKeyCarrier,
    initKeyCarrierCreate,
    breadcrumbsPopAction,
    breadcrumbsPushAction
  }) => ({
  cardLines: cardLines,
  getKey: data => objectKey(data, keyCarrierObject),
  resource: RESOURCES.keyCarrier,
  signatureHeaderText: 'Создание ключевого носителя',
  signatureServiceName: '',
  oldSignatureService: true,
  signatureEntity: 'RegisterKc',
  updateAction: registerKeyCarrier,
  cardInitAction: initKeyCarrierCreate,
  onCancel: () => {history.push('/keyDocument/keyCarrier')},
  afterUpdate: (requestResult) => {
    const { payload, type } = requestResult || {}
    const { id } = payload || {}

    if (type === CARDAFTERCREATE) {
      breadcrumbsPopAction()  
      history.push(`/keyDocument/keyCarrier/card/${id}`)
    }
  },
  getSignatureData: keyCarrierObject,
  pageBreadcrumbTitle: () => {
    breadcrumbsPushAction(history.location.pathname)
  }
})

const CreateCard = (props) => {
  const { 
    registerKeyCarrier,
    initKeyCarrierCreate,
    breadcrumbsPopAction,
    breadcrumbsPushAction,
  } = props
  
  const history = useHistory();

  return (
    <section className=' key-carrier-card'>
      <BreadcrumbsHeader />
      <Tabs 
        activeTab={'Информация'} 
        onTabClick={null}
      >
        <section tabTitle={'Информация'} className="key-carrier-card__data">
          <ExpandingBlock
            renderHeader= {() => 'Реквизиты'}
            initialState={true}
          >
            <MainCard
              {...props}
              config={cardConfig({
                history,
                registerKeyCarrier,
                initKeyCarrierCreate,
                breadcrumbsPopAction,
                breadcrumbsPushAction
              })}
              editOnOpen={true}
            > 
              
            </MainCard>
          </ExpandingBlock>
          <ExpandingBlock
            renderHeader= {() => 'Ключевые документы'}
            disabled={true}
          >
          </ExpandingBlock>
        </section>
        <div
          tabTitle={'История'}
          disabled={true}
        />
      </Tabs>
    </section>
  )
}

export default connect(
  state =>({...state.card}),
  { 
    ...mainCardAction,
    ...breadcrumbsActions
  })(CreateCard)