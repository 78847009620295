import {ReactComponent as DocumentImg }  from 'assets/img/menu/ic_directory.svg';
import {ReactComponent as skziAccountingImg }  from 'assets/img/menu/ic_catalog.svg';
import { ReactComponent as AdmittanceImg }  from 'assets/img/menu/ic_admittance.svg';
import {ReactComponent as skziRegistryImg }  from 'assets/img/menu/ic_registry.svg';
import {ReactComponent as userAccountsImg }  from 'assets/img/menu/ic_userslist.svg';
import {ReactComponent as keyDocumentImg }  from 'assets/img/menu/ic_keydocument.svg';
import {ReactComponent as accessManagementImg }  from 'assets/img/menu/ic_settings.svg'
import {ReactComponent as licenseImg }  from 'assets/img/menu/ic_key.svg'
import { ReactComponent as ConclusionImg }  from 'assets/img/menu/ic_conclusion.svg';
import { ReactComponent as KeyCarrierImg }  from 'assets/img/menu/ic_key_carrier.svg';
import { ReactComponent as ExterminationImg }  from 'assets/img/actionPanel/ic_extermination.svg';
import { Image } from 'app/components/ui/Img';

// перестало собираться приложение из-за прекрёстных импортов auth -> navigationContent -> auth
const RIGHTS = {
    viewList: 'VIEW_LIST_EXTENDED',
    viewCard: 'VIEW_CARD',
    viewShortList: 'VIEW_LIST',
    create: 'CREATE',
    update: 'UPDATE',
    delete: 'DELETE',
    import: 'IMPORT',
    //report: 'REPORT',
    okzReport: 'OKZ_REPORT',
    okiReport: 'OKI_REPORT'
}

const noMenuPages = [
    {
        id: 'MenuSection/SkziAccounting',
        link: '#/',
        name: 'home',
        title: 'Экземпляры СКЗИ',
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'skziAccounting/EskziCard/',
        link: 'skziAccounting/EskziCard/',
        name: 'skziInstance',
        title: 'Экземпляры СКЗИ',
        regexp: new RegExp('skziAccounting/EskziCard/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard //'VIEW_CARD'
    },
    {
        id: 'skziOrganization/EskziCard/',
        link: 'skziOrganization/EskziCard/',
        name: 'skziInstance',
        title: 'Экземпляры СКЗИ',
        regexp: new RegExp('skziOrganization/EskziCard/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard //'VIEW_CARD'
    },
    {
        id: 'skziRegistry/accounting/skziCard/',
        link: 'skziRegistry/accounting/skziCard/',
        name: 'skzi',
        title: 'Реестр сертифицированных СКЗИ',
        regexp: new RegExp('skziRegistry/accounting/skziCard/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard //'VIEW_CARD'
    },
    {
        id: 'skziAccounting/create',
        link: '/skziAccounting/create',
        name: 'skziInstanceCreate',
        title: 'Экземпляры СКЗИ',
        headerTitle: 'Регистрация экземпляра СКЗИ',
        breadcrumbsTitle : true,
        resourceRight: RIGHTS.create // 'SAVE'
    },
    {
        id: 'skziOrganization/create',
        link: '/skziOrganization/create',
        name: 'skziInstanceCreate',
        title: 'Экземпляры СКЗИ',
        headerTitle: 'Регистрация экземпляра СКЗИ',
        breadcrumbsTitle : true,
        resourceRight: RIGHTS.create // 'SAVE'
    },
    {
        id: 'userAccounts/create',
        link: '/userAccounts/create',
        name: 'userAccountCreate',
        title: 'Лицевые счета пользователей СКЗИ',
        headerTitle: 'Создать лицевой счет пользователя',
        breadcrumbsTitle : true,
        resourceRight: RIGHTS.create // 'SAVE'
    },
    {
        id: 'userAccounts/card/',
        link: '/userAccounts/card/',
        name: 'userAccountCard',
        title: 'Лицевые счета пользователей СКЗИ',
        breadcrumbsTitle : false,
        regexp: new RegExp('userAccounts/card/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard // 'VIEW_CARD'
    },
    {
        id: 'accessManagement/users/create',
        link: '/accessManagement/users/create',
        name: 'userCreate',
        title: 'Учетные записи',
        headerTitle: 'Создать учетную запись',
        breadcrumbsTitle : true,
        resourceRight: RIGHTS.create // 'SAVE'
    },
    {
        id: 'accessManagement/users/card/',
        link: '/accessManagement/users/card/',
        name: 'userCard',
        title: 'Учетные записи',
        headerTitle: 'Создать учетную запись',
        breadcrumbsTitle : true,
        regexp: new RegExp('accessManagement/users/card/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard // 'VIEW_CARD'
    },
    {
        id: 'keyDocument/okz/create',
        link: '/keyDocument/okz/create',
        name: 'keyDocumentCreate',
        title: 'Ключевые документы',
        headerTitle: 'Регистрация ключевого документа',
        breadcrumbsTitle : true,
        resourceRight: RIGHTS.create // 'SAVE'
    },
    {
        id: 'keyDocument/oki/create',
        link: '/keyDocument/oki/create',
        name: 'keyDocumentCreate',
        title: 'Ключевые документы',
        headerTitle: 'Регистрация ключевого документа',
        breadcrumbsTitle : true,
        resourceRight: RIGHTS.create // 'SAVE'
    },
    {
        id: 'keyDocument/card/',
        link: '/keyDocument/card/',
        name: 'keyDocumentCard',
        title: 'Ключевой документ',
        breadcrumbsTitle : false,
        regexp: new RegExp('keyDocument/card/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard // 'SAVE'
    },
    {
        id: '/skziRegistry/accounting/create',
        link: '/skziRegistry/accounting/create',
        name: 'skziRegistryCreate',
        title: 'Реестр сертифицированных СКЗИ',
        headerTitle: 'Добавление СКЗИ',
        breadcrumbsTitle : true,
        resourceRight: RIGHTS.create // 'SAVE'
    },
    {
        id: 'trainingCourses/create',
        link: '/catalog/trainingCourses/create',
        name: 'trainingCoursesCreate',
        title: 'Обучающие курсы',
        headerTitle: 'Добавить курс',
        breadcrumbsTitle: true,
        resourceRight: RIGHTS.create // 'SAVE'
    },
    {
        id: 'trainingCourses/card',
        link: '/catalog/trainingCourses/card/',
        name: 'trainingCoursesCard',
        title: 'Обучающие курсы',
        breadcrumbsTitle : false,
        regexp: new RegExp('catalog/trainingCourses/card/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard // 'SAVE'
    },
    {
        id: 'organization/create',
        link: '/catalog/organization/create',
        name: 'organizationCreate',
        title: 'Организации',
        headerTitle: 'Создать организацию',
        breadcrumbsTitle : true,
        resourceRight: RIGHTS.create // 'SAVE'
    },
    {
        id: 'okzScheme/create',
        link: '/catalog/okzScheme/create',
        name: 'okzSchemeCreate',
        title: 'Организации',
        headerTitle: 'Создать организацию',
        breadcrumbsTitle : true,
        resourceRight: RIGHTS.create // 'SAVE'
    },
    {
        id: 'organization/card',
        link: '/catalog/organization/card/',
        name: 'organizationCard',
        title: 'Организации',
        breadcrumbsTitle : false,
        regexp: new RegExp('catalog/organization/card/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard // 'SAVE'
    },
    {
        id: '/trialEnd',
        link: '/trialEnd',
        name: 'trialEnd',
        title: 'Доступ запрещен',
        breadcrumbsTitle : false,
    },
    {
        id: '/unauthorized',
        link: '/unauthorized',
        name: 'unauthorized',
        title: 'Доступ запрещен',
        breadcrumbsTitle : false,
    },
    {
        id: '/connectionError',
        link: '/connectionError',
        name: 'connectionError',
        title: 'Ошибка соединения',
        breadcrumbsTitle : false,
    },
    {
        id: '/notFound',
        link: '/notFound',
        name: '/notFound',
        title: 'Страница не найдена',
        breadcrumbsTitle : false,
    },
    {
        id: '/serverError',
        link: '/serverError',
        name: '/serverError',
        title: 'Ошибка',
        breadcrumbsTitle : false,
    },
    {
        id: '/acts/admittance/create',
        link: '/acts/admittance/create',
        name: 'admittanceCreate',
        title: 'Допуски к СКЗИ',
        headerTitle: 'Добавление допуска к СКЗИ',
        breadcrumbsTitle : true,
        resourceRight: RIGHTS.create // 'SAVE'
    },
    {
        id: 'admittance/card/',
        link: '/acts/admittance/card/',
        name: 'admittanceCard',
        title: 'Заключение о допуске',
        breadcrumbsTitle : false,
        regexp: new RegExp('admittance/card/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard // 'SAVE'
    },
    {
        id: 'license/create',
        link: '/license/create',
        name: 'licenseCreate',
        title: 'Лицензии СКЗИ',
        headerTitle: 'Добавление лицензии СКЗИ',
        breadcrumbsTitle : true,
        resourceRight: RIGHTS.create // 'SAVE'
    },
    {
        id: 'maintenance/create',
        link: '/acts/maintenance/create',
        name: 'maintenanceCreate',
        title: 'Заключения об эксплуатации',
        breadcrumbsTitle : true,
        headerTitle: 'Добавление заключения об эксплуатации',
        resourceRight: RIGHTS.viewCard // 'SAVE'
    },
    {
        id: 'keyDocument/keyCarrier/create',
        link: '/keyDocument/keyCarrier/create',
        name: 'keyCarrierCreate',
        title: 'Ключевые носители',
        headerTitle: 'Регистрация ключевого носителя',
        breadcrumbsTitle: true,
        resourceRight: RIGHTS.viewCard // 'SAVE'
    },
    {
        id: 'destructioncertificate/create',
        link: '/acts/destructioncertificate/create',
        name: 'destructionCertificateCreate',
        title: 'Акты уничтожения',
        headerTitle: 'Добавление акта уничтожения',
        breadcrumbsTitle: true,
        resourceRight: RIGHTS.viewCard // 'SAVE'
    },
    {
        id: 'destructioncertificate/card',
        link: '/acts/destructioncertificate/card',
        name: 'destructionCertificateCard',
        title: 'Акты уничтожения',
        breadcrumbsTitle : false,
        regexp: new RegExp('destructioncertificate/card/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard // 'SAVE'
    },
    {
        id: 'keyDocument/keyCarrier/card/',
        link: '/keyDocument/keyCarrier/card',
        name: 'keyCarrierCard',
        title: 'Ключевые носители',
        breadcrumbsTitle : false,
        regexp: new RegExp('keyDocument/keyCarrier/card/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard // 'SAVE'
    },
    {
        id: 'license/card/',
        link: '/license/card/',
        name: 'licenseCard',
        title: 'Лицензии СКЗИ',
        breadcrumbsTitle : false,
        regexp: new RegExp('license/card/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard // 'SAVE'
    },
    {
        id: 'maintenance/card/',
        link: '/acts/maintenance/card/',
        name: 'maintenanceCard',
        title: 'Заключение об эксплуатации',
        breadcrumbsTitle : false,
        regexp: new RegExp('maintenance/card/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard // 'SAVE'
    },
    {
        id: 'keyCarrierType/create',
        link: '/catalog/keyCarrierType/create',
        name: 'keyCarrierTypeCreate',
        title: 'Новая запись',
        headerTitle: 'Новая запись',
        breadcrumbsTitle: true,
        resourceRight: RIGHTS.create
    },
    {
        id: 'keyCarrierType/create',
        link: '/catalog/keyCarrierType/create',
        name: 'keyCarrierTypeCreate',
        title: 'Новая запись',
        headerTitle: 'Новая запись',
        breadcrumbsTitle: true,
        resourceRight: RIGHTS.create
    },
    {
        id: 'skziTypes/create',
        link: '/catalog/skziTypes/create',
        name: 'skziTypesCreate',
        title: 'Новая запись',
        headerTitle: 'Новая запись',
        breadcrumbsTitle: true,
        resourceRight: RIGHTS.create 
    },
    {
        id: 'objectTypes/create',
        link: '/catalog/objectTypes/create',
        name: 'objectTypesCreate',
        title: 'Новая запись',
        headerTitle: 'Новая запись',
        breadcrumbsTitle: true,
        resourceRight: RIGHTS.create
    },
    {
        id: 'objectTypes/card/',
        link: '/catalog/objectTypes/card/',
        name: 'objectTypesCard',
        title: '',
        breadcrumbsTitle : false,
        regexp: new RegExp('catalog/objectTypes/card/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard
    },
    {
        id: 'skziTypes/card/',
        link: '/catalog/skziTypes/card/',
        name: 'skziTypesCard',
        title: '',
        breadcrumbsTitle : false,
        regexp: new RegExp('catalog/skziTypes/card/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard 
    },
    {
        id: 'keyCarrierType/card/',
        link: '/catalog/keyCarrierType/card/',
        name: 'keyCarrierTypeCard',
        title: '',
        breadcrumbsTitle : false,
        regexp: new RegExp('catalog/keyCarrierType/card/\\d{1,}.{0,}'),
        resourceRight: RIGHTS.viewCard 
    },
]


const accessSubmenu = [
    {
        id: 'Host/Entity',
        resource: 'Host/Entity',
        linkPart: '/hosts',
        name: 'hosts',
        title: 'Агентская сеть',
        headerTitle: 'Агентская сеть',
        resourceRight: RIGHTS.viewList,
        ImgComponent: Image.AccessManagement,
    },
    {
        id: 'User/Entity',
        resource: 'User/Entity',
        linkPart: '/users',
        name: 'users',
        title: 'Учетные записи',
        headerTitle: 'Учетные записи',
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'Settings/Password',
        resource: 'Settings/Password',
        linkPart: '/passwordPolicy',
        name: 'passwordPolicy',
        title: 'Парольные политики',
        headerTitle: 'Парольные политики',
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'Settings/Licensing',
        resource: 'EskziLicenseManagement',
        linkPart: '/licensing',
        name: 'licensing',
        title: 'Лицензирование',
        headerTitle: 'Лицензирование',
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'AdSettings',
        resource: 'AdSettings',
        linkPart: '/activeDirectorySettings',
        name: 'activeDirectorySettings',
        title: 'Настройка AD',
        headerTitle: 'Настройка AD',
        resourceRight: RIGHTS.create
    },

]


const actsSubmenu = [
    {
        id: '/acts/admittance',
        resource: 'Admittance/Entity',
        linkPart: '/admittance',
        name: 'Admittance/Entity',
        title: 'Допуски к СКЗИ',
        ImgComponent: AdmittanceImg,
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'maintenance',
        resource: 'EskziUsageCertificate/Entity',
        linkPart: '/maintenance',
        name: 'maintenance',
        title: 'Заключения об эксплуатации СКЗИ',
        ImgComponent: ConclusionImg,
        resourceRight: RIGHTS.viewList // 'SAVE'
    },
    {
        id: 'EskziDestructionCertificate/Entity',
        resource: 'EskziDestructionCertificate/Entity',
        linkPart: '/destructioncertificate',
        name: 'destructionCertificate',
        title: 'Акты уничтожения',
        ImgComponent: ExterminationImg,
        resourceRight: RIGHTS.viewList
    },
]

const keyDocumentSubmenu = [
    {
        id: 'KeyDocumentOKZ',
        resource: 'KeyDocument/Entity/Okz',
        linkPart: '/okz',
        name: 'KeyDocumentOKZ',
        title: 'Орган криптозащиты',
        ImgComponent: keyDocumentImg,
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'KeyDocumentOKI',
        resource: 'KeyDocument/Entity',
        linkPart: '/oki',
        name: 'KeyDocumentOKI',
        title: 'Организации',
        ImgComponent: keyDocumentImg,
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'keyCarrier/Entity',
        resource: 'KeyCarrier/Entity',
        linkPart: '/keyCarrier',
        name: 'keyCarrier',
        title: 'Носители',
        ImgComponent: KeyCarrierImg,
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'Certificate/Request',
        resource: 'CryptoproCA/Certificates',
        linkPart: '/certRequest',
        name: 'CertificateRequest',
        title: 'Запросы на сертификат',
        ImgComponent: keyDocumentImg,
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'Certificates',
        resource: 'CryptoproCA/Certificates',
        linkPart: '/certificates',
        name: 'Certificates',
        title: 'Сертификаты',
        ImgComponent: keyDocumentImg,
        resourceRight: RIGHTS.viewList
    },
]

const exemplarsSubmenu = [
    {
        id: 'Eskzi/Entity/Okz',
        resource: 'Eskzi/Entity/Okz',
        linkPart: '/skziAccounting',
        name: 'skziAccounting',
        title: 'Орган криптозащиты',
        ImgComponent: skziAccountingImg,
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'Eskzi/Entity',
        resource: 'Eskzi/Entity',
        linkPart: '/skziOrganization',
        name: 'skziOrganization',
        title: 'Организации',
        ImgComponent: skziAccountingImg,
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'SkziLicense/Entity',
        resource: 'SkziLicense/Entity',
        linkPart: '/license',
        name: 'license',
        title: 'Лицензии',
        ImgComponent: licenseImg,
        resourceRight: RIGHTS.viewList
    },
    // {
    //     id: 'SkziLicense/Entity',
    //     resource: 'SkziLicense/Entity',
    //     linkPart: '/license',
    //     name: 'license',
    //     title: 'Лицензии',
    //     ImgComponent: licenseImg,
    //     resourceRight: RESOURCES.extOrgSkziLicense
    // },
]

const skziSubmenu = [
    {
        id: 'exemplar/Okz',
        resource: 'Eskzi/Entity',
        linkPart: '/okz',
        name: 'okz',
        title: 'Экземпляры для ОКЗ',
        resourceRight: RIGHTS.okzReport
    },
    {
        id: 'exemplar/Oki',
        resource: 'Eskzi/Entity',
        linkPart: '/oki',
        name: 'oki',
        title: 'Экземпляры для Организаций',
        resourceRight: RIGHTS.okiReport
    },
    {
        id: 'exemplars/keyDocumentOkz',
        resource: 'KeyDocument/Entity',
        linkPart: '/keyDocumentOkz',
        name: 'keyDocumentOkz',
        title: 'Ключевые документы для ОКЗ',
        resourceRight: RIGHTS.okzReport
    },
    {
        id: 'exemplars/keyDocumentOki',
        resource: 'KeyDocument/Entity',
        linkPart: '/keyDocumentOki',
        name: 'keyDocumentOki',
        title: 'Ключевые документы для Организаций',
        resourceRight: RIGHTS.okiReport
    }, 
    {
        id: 'exemplars/keyCarrierOkz',
        resource: 'Eskzi/Entity',
        linkPart: '/keyCarrierOkz',
        name: 'keyCarrierOki',
        title: 'Ключевые носители для ОКЗ',
        resourceRight: RIGHTS.okzReport
    },
    {
        id: 'exemplars/keyCarrierOki',
        resource: 'Eskzi/Entity',
        linkPart: '/keyCarrierOki',
        name: 'keyCarrierOki',
        title: 'Ключевые носители для Организаций',
        resourceRight: RIGHTS.okiReport
    },
    {
        id: 'exemplars/technicalJournalOki',
        resource: 'Eskzi/TechnicalJournal',
        linkPart: '/technicalJournalOki',
        name: 'technicalJournalOki',
        title: 'Технический (аппаратный)',
        resourceRight: RIGHTS.okiReport
    },
]


const skziRegistrySubmenu = [
    {
        id: 'skziRegistry/accounting',
        resource: 'Skzi/Entity',
        linkPart: '/accounting',
        name: 'skziRegistry/accounting',
        title: 'Каталог продуктов',
        headerTitle: 'Каталог продуктов',
        ImgComponent: skziRegistryImg,
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'skziRegistry/selfService',
        resource: 'Skzi/SelfService',
        linkPart: '/selfService',
        name: 'skziRegistry/selfService',
        title: 'Самообслуживание',
        headerTitle: 'Самообслуживание',
        ImgComponent: skziRegistryImg,
        resourceRight: RIGHTS.viewList
    }
]

const certificateAuthoritySubmenu = [
    {
        id: 'AcceptorCertificates/Request',
        resource: 'CryptoproCA/AcceptorCertificates',
        linkPart: '/request',
        name: 'AcceptorCertificatesRequest',
        title: 'Запросы',
        ImgComponent: Image.Sign,
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'AcceptorCertificates/certificate',
        resource: 'CryptoproCA/AcceptorCertificates',
        linkPart: '/certificate',
        name: 'AcceptorCertificates',
        title: 'Сертификаты',
        ImgComponent: Image.Sign,
        resourceRight: RIGHTS.viewList
    },
]

const catalogSubmenu = [
    // {
    //     id: 'MenuSection/organizations',
    //     linkPart: '/organizations',
    //     name: 'organizations',
    //     title: 'Подразделения',
    // },
    {
        id: 'Course/Entity',
        resource: 'Course/Entity',
        linkPart: '/trainingCourses',
        name: 'trainingCourses',
        title: 'Обучающие курсы',
        headerTitle: 'Обучающие курсы',
        ImgComponent: userAccountsImg,
        resourceRight: RIGHTS.viewList
    },

    {
        id: 'Organization/Entity',
        resource: 'Organization/Entity',
        linkPart: '/organization',
        name: 'organization',
        title: 'Организации',
        headerTitle: 'Организации',
        ImgComponent: DocumentImg,
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'Organization/OkzScheme',
        resource: 'Organization/OkzScheme',
        linkPart: '/okzScheme',
        name: 'okzScheme',
        title: 'Структура органов криптозащиты',
        ImgComponent: DocumentImg,
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'SkziType/Entity',
        resource: 'SkziType/Entity',
        linkPart: '/skziTypes',
        name: 'skziTypes',
        title: 'Типы СКЗИ',
        ImgComponent: DocumentImg,
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'EskziStatus/Entity',
        resource: 'EskziStatus/Entity',
        linkPart: '/exemplarStatuses',
        name: 'EskziStatus',
        title: 'Статусы экземпляров',
        ImgComponent: DocumentImg,
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'KeyCarrierType/Entity',
        resource: 'KeyCarrierType/Entity',
        linkPart: '/keyCarrierType',
        name: 'keyCarrierType',
        title: 'Типы ключевых носителей',
        ImgComponent: DocumentImg,
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'ObjectType/Entity',
        resource: 'ObjectType/Entity',
        linkPart: '/objectTypes',
        name: 'objectTypes',
        title: 'Элементы комплекта СКЗИ',
        ImgComponent: DocumentImg,
        resourceRight: RIGHTS.viewList
    },
    // {
    //     id: 'EskziStatus/Entity',
    //     resource: 'EskziStatus/Entity',
    //     linkPart: '/instanceStatuses',
    //     name: 'instanceStatuses',
    //     title: 'Статусы экземпляра',
    //     resourceRight: RIGHTS.viewList
    // },
    // {
    //     id: 'MenuSection/EventTemplates',
    //     linkPart: '/eventTemplates',
    //     name: 'eventTemplates',
    //     title: 'Шаблоны мероприятий и задач',
    // },
    // {
    //     id: 'MenuSection/BusinessProcess',
    //     linkPart: '/businessProcess',
    //     name: 'businessProcess',
    //     title: 'Бизнес-процессы',
    // },
    // {
    //     id: 'MenuSection/Calendar',
    //     linkPart: '/calendar',
    //     name: 'calendar',
    //     title: 'Производственный календарь',
    // },
    // {
    //     id: 'MenuSection/GroupPermissions',
    //     linkPart: '/groupPermissions',
    //     name: 'groupPermissions',
    //     title: 'Групповые разрешения',
    // }
]

const menuContent = [
    {
        id: 'Dashboard',
        resource: 'Dashboard',
        link: '#/',
        name: 'Dashboard',
        title: 'Главная страница',
        headerTitle: 'Главная страница',
        ImgComponent: Image.Dashboard,
        resourceRight: RIGHTS.viewCard
    },
    {
        id: 'Eskzi/Entity/ExternalOki',
        resource: 'Eskzi/Entity/ExternalOki',
        link: '#/extorgeskzi',
        name: 'extorgeskzi',
        title: 'Экземпляры СКЗИ',
        ImgComponent: skziAccountingImg,
        resourceRight: RIGHTS.viewList,
        skipIf: (_, itemsMap) => itemsMap['Eskzi/Entity'] && itemsMap['Eskzi/Entity'].submenu
            && itemsMap['Eskzi/Entity'].submenu.some(si => si.id === 'Eskzi/Entity')
    },
    {
        id: 'SkziLicense/Entity/ExternalOki',
        resource: 'SkziLicense/Entity/ExternalOki',
        link: '#/extorglicense',
        name: 'extorglicense',
        title: 'Лицензии',
        ImgComponent: licenseImg,
        resourceRight: RIGHTS.viewList,
        skipIf: (_, itemsMap) => itemsMap['Eskzi/Entity'] && itemsMap['Eskzi/Entity'].submenu
            && itemsMap['Eskzi/Entity'].submenu.some(si => si.id === 'SkziLicense/Entity')
    },
    // {
    //     id: 'MenuSection/Main',
    //     link: '#/',
    //     name: 'home',
    //     title: 'Главная',
    //     img: homeImg,
    //     notAffectedByRole: true
    // },
    {
        id: 'skziRegistry',
        link: '#/skziRegistry',
        name: 'skziRegistry',
        title: 'Реестр СКЗИ',
        ImgComponent: skziRegistryImg,
        disabled: true,
        submenu: skziRegistrySubmenu
    },
    {
        id: 'Eskzi/Entity',
        link: '#',
        name: 'Eskzi/Entity',
        title: 'Экземпляры СКЗИ',
        ImgComponent: skziAccountingImg,
        submenu: exemplarsSubmenu,
        disabled: true,
    },
    // {
    //     id: 'MenuSection/Tasks',
    //     link: '#/tasks',
    //     name: 'Tasks',
    //     title: 'Задачи',
    //     img: tasksImg,
    // },
    // {
    //     id: 'MenuSection/Incidents',
    //     link: '#/incidents',
    //     name: 'incidents',
    //     title: 'Инциденты',
    //     img: inzidentImg,
    // },
    // {
    //     id: 'MenuSection/Users',
    //     link: '#/users',
    //     name: 'users',
    //     title: 'Пользователи',
    //     img: usersImg,
    // },
    // {
    //     id: 'MenuSection/Reports',
    //     link: '#/reports',
    //     name: 'reports',
    //     title: 'Отчеты',
    //     img: reportImg,
    // },
    // {
    //     id: 'KeyDocument/Entity',
    //     resource: 'KeyDocument/Entity',
    //     link: '#/keyDocument',
    //     name: 'keyDocument',
    //     title: 'Ключевые документы',
    //     ImgComponent: keyDocumentImg,
    //     resourceRight: RIGHTS.viewList
    // },
    {
        id: 'KeyDocument/Entity',
        link: '#/keyDocument',
        name: 'keyDocument',
        title: 'Ключевые документы',
        ImgComponent: keyDocumentImg,
        submenu: keyDocumentSubmenu,
        disabled: true
    },
    {
        id: 'acts',
        link: '#/acts',
        name: 'acts',
        title: 'Акты и заключения',
        ImgComponent: AdmittanceImg,
        submenu: actsSubmenu,
        disabled: true
    },
    {
        id: 'EskziUser/Entity',
        resource: 'EskziUser/Entity',
        link: '#/userAccounts',
        name: 'userAccounts',
        title: 'Лицевые счета',
        headerTitle: 'Лицевые счета',
        ImgComponent: userAccountsImg,
        resourceRight: RIGHTS.viewList
    },
    {
        id: 'certificateAuthority',
        link: '#/certificateAuthority',
        name: 'certificateAuthority',
        title: 'Удостоверяющий центр',
        ImgComponent: Image.Sign,
        submenu: certificateAuthoritySubmenu,
        disabled: true
    },
    {
        id: 'exemplars',
        link: '#/exemplars',
        name: 'exemplars',
        title: 'Журналы учета',
        ImgComponent: skziAccountingImg,
        submenu: skziSubmenu,
        disabled: true
    },
    // {
    //     id: 'Eskzi/Entity',
    //     resource: 'Eskzi/Entity',
    //     link: '#/skziAccounting',
    //     name: 'skziAccounting',
    //     title: 'Экземпляры СКЗИ',
    //     ImgComponent: skziAccountingImg,
    //     resourceRight: RIGHTS.viewList
    // },

    {
        id: 'catalog',
        link: '#/catalog',
        name: 'catalog',
        title: 'Справочники',
        ImgComponent: DocumentImg,
        disabled: true,
        submenu: catalogSubmenu
    },
    {
        id: 'accessManagement',
        link: '#/accessManagement',
        name: 'accessManagement',
        title: 'Администрирование',
        ImgComponent: accessManagementImg,
        disabled: true,
        submenu: accessSubmenu
    },
];

const isActive = (path, link) => {
  
    const [ menuLink, submenuLink ] = link.replace(/#\//, '')
                                            .split('/')
                                            .filter(i => i)
    const [ pathMenuPart, pathSubmenuPart ] = path.replace(/#\//, '')
                                                    .split('/')
                                                    .filter(i => i)
    
    /// TODO
    // пункт меню "Экземпляры СКЗИ" сделан неправильно,
    // поэтому надо поменять все ссылки в меню 
    // и все упоминания в проекте на:
    // Экземпляры CКЗИ : Орган криптозащиты  -  #/eskzi/okz
    // Экземпляры CКЗИ : Организации -          #/eskzi/oki
    // Экземпляры CКЗИ : Лицензии -             #/eskzi/license
    // для карточек - экземпляр для организаций -#/eskzi/okz/card 
    // и т.д.
    const eskziMenuPath = ['license', 'skziAccounting', 'skziOrganization']
    if (eskziMenuPath.includes(pathMenuPart)) {
        return menuLink === '#' || menuLink === pathMenuPart
    }

    if (menuLink === pathMenuPart) {
        return submenuLink ? submenuLink === pathSubmenuPart : true
    }

    return false
};

const menuFlatten = () => {
    return [...menuContent, ...noMenuPages].reduce((acc, current) => {
        const { id, title, link, submenu = false } = current
        const linkKey = link.replace('#', '')

        const mappedSubMenu = submenu && submenu.reduce((acc, current) => {
            const { linkPart } = current
            const linkKey = link.replace('#', '') + linkPart

            return {...acc, [linkKey]: { ...current, link: linkKey }}
        }, {});

        return mappedSubMenu
          ? {...acc, ...mappedSubMenu, [linkKey]: { id, title, link: linkKey, disabled: true }}
          : {...acc, [linkKey]: { ...current, link: linkKey }}
    }, {});
}

const flattenMenuContent = menuFlatten();

export {
    isActive,
    flattenMenuContent,
    menuContent
};