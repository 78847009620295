import {
  BREADCRUMBSPUSHITEM,
  SKZIVERSIONCARDGET,
  SKZIVERSIONCARDUPDATEFORMDATA,
  SKZIVERSIONCARDINPROGRESS,
  SKZIVERSIONCARDRESETACTION,
  SKZIVERSIONCARDUPDATEADDBUILDFORMDATA,
  SKZIVERSIONCARDVERSIONBUILDADD,
  SKZIVERSIONCARDBUILDONDELETE,
  SKZIVERSIONCARDBUILDDELETE,
  SKZIVERSIONCARDVERSIONONBUILDADD,
  SKZIVERSIONCARDONSELECT,
  SKZIVERSIONCARDGETVERSIONBUILDS,
  SKZIVERSIONCARDONCOURSESELECT,
  SKZIVERSIONCARDGETVERSIONCOURSES,
  SKZIVERSIONCARDCOURSEONDELETE,
  SKZIVERSIONCARDCOURSEDELETE,
  SKZIVERSIONCARDCOURSEADD,
  SKZI_VERSION_CARD_CLEAR_FORM,
  SKZI_VERSION_CARD_ADD,
  SKZIVERSIONCARDUPDATE
} from '../actionTypes'

import service from 'app/services/service'
import { dispatchLogic } from '../common'
import { getPageBreadcrumb } from 'app/header/breadcrumbs'

export const getSkziVersionAction = ({skziId, versionId, pathname}) => dispatch => {
  service('versionService', 'getVersion', {skziId, versionId})
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, SKZIVERSIONCARDGET, data, isError, errors)
    })
    .then(action => {
      const { payload } = action
      const { name } = payload || {}

      return dispatch({
        type: BREADCRUMBSPUSHITEM,
        payload: getPageBreadcrumb(pathname, `Версия ${name}`)
      })
    })
}

export const skziVersionCardUpdateAction = ({skziId, versionData}) => dispatch => {
  service('versionService', 'editVersion', {skziId, versionData})
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, SKZIVERSIONCARDUPDATE, data, isError, errors)
    })
}

export const skziVersionCardClearFormAction = () => {
  return ({
    type: SKZI_VERSION_CARD_CLEAR_FORM,
    payload: {
    }
  })
}

export const skziVersionCardAddAction = ({ skziId, createVersionForm }) => dispatch => {
  return service('versionService', 'addVersion', {skziId, versionData: createVersionForm})
    .then(response => {
      const { data, errors, isError } = response;

      dispatchLogic(dispatch, SKZI_VERSION_CARD_ADD, data, isError, errors)
      return response
    })
}

export const skziVersionCardOnSelect = selectedData => dispatch => {
  dispatch({
    type: SKZIVERSIONCARDONSELECT,
    payload: selectedData
  })
}

export const skziVersionCardOnCourseSelect = selectedData => dispatch => {
  dispatch({
    type: SKZIVERSIONCARDONCOURSESELECT,
    payload: selectedData
  })
}

export const skziVersionCardAddBuildAction = (data) => dispatch => {
  service('versionService', 'addBuild', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, SKZIVERSIONCARDVERSIONBUILDADD, data, isError, errors)
    })
}

export const skziVersionCardAddCourseAction = (data) => dispatch => {
  service('courseService', 'addCourseToVersion', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, SKZIVERSIONCARDCOURSEADD, data, isError, errors)
    })
}

export const skziVersionCardDeleteBuildAction = (builds) => dispatch => {

  service('versionService', 'deleteBuildRange', builds)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, SKZIVERSIONCARDBUILDDELETE, data, isError, errors)
    })
}

export const skziVersionCardDeleteCoursesAction = (courseData) => dispatch => {

  service('courseService', 'deleteVersionCourseRange', courseData)
    .then(response => {
      const { data, errors, isError } = response;
      
      dispatchLogic(dispatch, SKZIVERSIONCARDCOURSEDELETE, data, isError, errors)
    })
}

export const skziVersionCardGetBuildsAction = (data) => dispatch => {
  service('versionService', 'getBuilds', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, SKZIVERSIONCARDGETVERSIONBUILDS, data, isError, errors)
    })
}

export const skziVersionCardGetCoursesAction = (data) => dispatch => {
  service('courseService', 'getVersionCourses', data)
    .then(response => {
      const { data, errors, isError } = response;
      
      return dispatchLogic(dispatch, SKZIVERSIONCARDGETVERSIONCOURSES, data, isError, errors)
    })
}

export const skziVersionCardResetAction = () => dispatch => {
  dispatch({
    type: SKZIVERSIONCARDRESETACTION,
    payload: { 
    }
  })
}

export const skziVersionCardOnBuildDeleteAction = (builds) => dispatch => {
  dispatch({
    type: SKZIVERSIONCARDBUILDONDELETE,
    payload: builds
  })
}

export const skziVersionCardOnCourseDeleteAction = (courses) => dispatch => {
  dispatch({
    type: SKZIVERSIONCARDCOURSEONDELETE,
    payload: courses
  })
}


export const skziVersionCardInProgress = isInProgress => dispatch => {
  dispatch({
    type: SKZIVERSIONCARDINPROGRESS,
    payload: isInProgress
  })
}

export const skziVersionCardUpdateBuildForm = (formData) => dispatch => {
  dispatch({
    type: SKZIVERSIONCARDUPDATEADDBUILDFORMDATA,
    payload: { 
      ...formData
    }
  })
}

export const skziVersionCardOnAddBuildAction = () => dispatch => {
  dispatch({
    type: SKZIVERSIONCARDVERSIONONBUILDADD,
    payload: null
  })
}

export const skziVersionCardUpdateCommonForm = (formData) => dispatch => {
  dispatch({
    type: SKZIVERSIONCARDUPDATEFORMDATA,
    payload: { 
      ...formData
    }
  })
}