import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import Tabs from 'app/components/ui/tabs/tabs'
import { cardLines } from './skziVersionData'
import Card from 'app/components/ui/card/cardMaterial'

import Overlay from 'app/components/ui/overlay'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import { capitalize, serverlikeErrors } from 'app/core/utility/common'
import withAuth from 'app/components/HOC/AuthHOC'
import * as errorAction from 'redux/actions/common'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import * as skziVersionCardAction from 'redux/actions/skziRegistry/skziVersionCardAction'
import { addToast } from 'redux/actions/toast'
import withRoutingProps from 'app/components/HOC/RoutingPropsHOC'

export const orgType = [
  {type: 'okz', title : 'ОКЗ', urlPart: 'skziAccounting'},
  {type: 'oki', title : 'Организации', urlPart: 'skziOrganization'}
]

class SkziCard extends PureComponent {
  constructor(props){
    super(props)

    this.state = {
      tabTitle: 'Информация'
    }
  }

  componentDidMount() {
    const { skziVersionCard, clearErrorAction, skziVersionCardClearFormAction, breadcrumbsPushAction,  skziVersionCardInProgress, location } = this.props
    const { pathname } = location
    const { inProgress } = skziVersionCard
    const { search } = location

    const searchParams = new URLSearchParams(search);
    const tabName = searchParams.get('tabName')

    if (inProgress) {
      return
    }
   
    if (tabName) {
      this.setState({tabTitle: tabName})
    }

    skziVersionCardInProgress(true)
    skziVersionCardClearFormAction()
    breadcrumbsPushAction(pathname, 'Добавление новой версии')
    clearErrorAction()
  }

  componentWillUnmount () {
    const { 
      skziVersionCardResetAction, 
      clearErrorAction,
      skziVersionCardOnSelect,
      skziVersionCardOnCourseSelect,
      breadcrumbsPopAction
    } = this.props

    breadcrumbsPopAction()
    skziVersionCardOnSelect()
    skziVersionCardOnCourseSelect()
    skziVersionCardResetAction()
    clearErrorAction()
  }

  _onSubmit = () => {
    const { skziVersionCard, skziVersionCardAddAction, skziVersionCardInProgress, params, history, addToast } = this.props
    const { skziId } = params
    const { commonDataForm, skziVersionData } = skziVersionCard
    const { name, description } = commonDataForm

    const createVersionForm = {
      id: skziVersionData.id,
      name: name,
      description: description,
    }

    skziVersionCardInProgress(true)
    skziVersionCardAddAction({skziId, createVersionForm}).then(response => {
      const { data, isError } = response;
      if(!isError && data && data.id){
        addToast('Запись успешно добавлена')
        history.push(`/skziRegistry/accounting/skzi/${skziId}/version/${data.id}`)
      }
    })
  }

  _onCancel = () => {
    const { clearErrorAction, skziVersionCardResetAction, history, params } = this.props
    const { skziId } = params
    skziVersionCardResetAction()
    clearErrorAction()
    history.push(`/skziRegistry/accounting/skziCard/${skziId}`)
  }

  _onTabClick = (title) => {
    this.setState({ tabTitle: title})
  }

  _onDelete = () => {
    const { skziCardOnDelete } = this.props

    skziCardOnDelete && skziCardOnDelete()
  }

  _onValidation = (errors) => {
    const { setErrorByKey } = this.props

    setErrorByKey(serverlikeErrors(errors))
  }

  _onFormChange = (key, value) => {
    const { skziVersionCard, skziVersionCardUpdateCommonForm, setErrorByKey, error } = this.props
    const { commonDataForm } = skziVersionCard
    const { errorByFields }  = error || {}

    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))
    setErrorByKey(filteredErrors)
    skziVersionCardUpdateCommonForm({ ...commonDataForm, [key]: value })
  }

  _onClearError = () => {
    const { clearErrorAction } = this.props
    
    clearErrorAction()
  }

  _renderData = () => {
    const { skziVersionCard, profile, error } = this.props
    const { fieldsError } = error
    const { tabTitle } = this.state
    const {viewData, commonDataForm } = skziVersionCard

    return (
      <Tabs 
        activeTab={tabTitle} 
        onTabClick={this._onTabClick}>
          <section tabTitle={'Информация'} className='skzi-version-common-data'>
            <ExpandingBlock
              renderHeader= {() => 'Реквизиты версии'}
              initialState={true}
            >
              <Card
                className='skzi-version-data-card'
                profile={profile}
                formLines={cardLines}
                data={viewData}
                formData={commonDataForm}
                editOnOpen={true}
                onValidationError={this._onValidation}
                errorByFields={fieldsError}
                onChange={this._onFormChange}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
              />
            </ExpandingBlock>
            <ExpandingBlock
              className='skzi-version-buils'
              renderHeader= {() => 'Сборки / модификации'}
              initialState={false}
              disabled
            />
          </section>
          <section tabTitle={'Обучающие курсы'} className='skzi-version-common-data' disabled/>
      </Tabs>
    )
  }

  render() {
    const { skziVersionCard } = this.props
    const { inProgress } = skziVersionCard

    return (
      <section className='skzi-version-card'>
        {inProgress ? <Overlay /> : null}
        <BreadcrumbsHeader />
        {this._renderData()}
      </section>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default connect(
  mapStateToProps,
  {
    addToast,
    ...breadcrumbsActions,
    ...errorAction,
    ...skziVersionCardAction
  })(withAuth(withRoutingProps(SkziCard)))