import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import Overlay from 'app/components/ui/overlay'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import {cardLines} from '../admittanceCard/admittanceData'
import SignatureDialog, { isDigitalSignNeeded } from 'app/components/signature/SignatureDialog'
import Card from 'app/components/ui/card/cardMaterial'
import { formatDate } from 'app/core/utility/date'

import withAuth from 'app/components/HOC/AuthHOC'
import { capitalize, serverlikeErrors } from 'app/core/utility/common'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import * as createAdmittanceActions from 'redux/actions/admittance/createAdmittanceAction'
import * as errorActions from 'redux/actions/common'
import * as signatureActions from 'redux/actions/common/signatureAction'

import { ADMITTANCECREATE } from 'redux/actions/actionTypes'

class createAdmittance extends Component {

  UNSAFE_componentWillMount () {
    const { 
      breadcrumbsPushAction,
      location,
      createAdmittanceInitFormData,
      profile
    } = this.props
    const { pathname } = location
    const { userInfo } = profile

    createAdmittanceInitFormData({
      signers: [],
      lastUpdater: {user: userInfo},
      issuer: {user: userInfo}
    })
    breadcrumbsPushAction(pathname)
  }

  componentWillUnmount () {
    this._onClearForm()
  }

  _onCancel = () => {
    const { history } = this.props
    this._onClearForm()

    history.push('/acts/admittance')
  }

  _onSubmit = async () => {
    const { profile, createAdmittance } = this.props
    const { formData } = createAdmittance

    if (isDigitalSignNeeded(profile, 'Admittance')) {
      this._onSignDialog(formData)
    } else {
      this._registerWithoutSign(formData)
    }
  }

  _onSignDialog = (data) => {
    const { 
      setRequestData, 
      generateText, 
      createAdmittanceInProgressAction
    } = this.props

    const signTextRequest = {
      ...this._getSignatureRequestData(data),
      signatureRequestType: 'Admittance'
    }
  
    setRequestData({...data});
    createAdmittanceInProgressAction(true)
    generateText(signTextRequest);
  }

  _registerWithoutSign = async (data) => {
    const { 
      createAdmittanceInProgressAction,
      registerAdmittance,
    } = this.props
    const requestData = {
      data: this._getSignatureRequestData(data),
      signature: null
    }

    createAdmittanceInProgressAction(true)
    const createResult = await registerAdmittance(requestData)
    this._finishCreation(createResult)
  }

  _afterSign = async () => {
    const { 
      signature,
      createAdmittanceInProgressAction,
      registerAdmittance,
      createAdmittance
    } = this.props
    const { formData } = createAdmittance
    const { textForSignature, signatureResult } = signature
    
    const signatureToServer = {
      data: this._getSignatureRequestData(formData),
      signature: { data: textForSignature, signedData: signatureResult }
    }

    createAdmittanceInProgressAction(true)
    const createResult = await registerAdmittance(signatureToServer)
    this._finishCreation(createResult)
  }

  _getSignatureRequestData = (data) => {
    const { name, userId, issueDate, skziInfo, signers } = data
    const { version } = skziInfo || {}
    const { id: versionId } = version || {}
    const { id } = userId || {}
    
    return { 
      name, 
      userId: id, 
      issueDate: formatDate(issueDate, 'yyyy-mm-dd'),
      skziVersionId: versionId,
      signers: signers.map(item => item.id)
    }
  }

  _finishCreation = (createResult) => {
    const { 
      breadcrumbsPopAction, 
      history 
    } = this.props
    const { payload, type } = createResult || {}
    const { id } = payload || {}

    if (type === ADMITTANCECREATE) {
      breadcrumbsPopAction()  
      history.push(`/acts/admittance/card/${id}`)
    }
  }

  _updateFormData = (key, value) => {
    const { createAdmittanceUpdateFormData, setErrorByKey, error: { errorByFields }, createAdmittance } = this.props
    const { formData } = createAdmittance
    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))
    const { signers } = formData
    const { organization: signersOrg } = signers[0] || {}
    const { id: currentOrganizationId } = signersOrg || {}

    let newData = {[key]: value}
    if (key === 'userId' && signers.length) {
      const { organization } = value || {}
      const { id: valueOrgId } = organization || {}
      newData = !value || valueOrgId !== currentOrganizationId
                ? { [key]: value, signers: []}
                : newData
    }

    createAdmittanceUpdateFormData(newData)
    setErrorByKey && setErrorByKey(filteredErrors)
  }

  _onValidation = (errors) => {
    const { setErrorByKey } = this.props

    setErrorByKey && setErrorByKey(serverlikeErrors(errors))
  }

  _onClearForm = () => {
    const { admittanceCreateClearForm } = this.props

    admittanceCreateClearForm()
  }

  _renderModal = () => {
    const { 
      resetSignature,
      signature,
      profile
    } = this.props
    const { textForSignature } = signature || {}

    return (
      <Fragment>
        {textForSignature ? (
          <SignatureDialog
            className={'signature-form'}
            profile={profile}
            onCancel={resetSignature}
            afterSignCallback={this._afterSign}
            renderHeader = {() => {
              return (
                <div className='sign__header'>
                  {'Добавление допуска к СКЗИ'}
                </div>
              )
            }}
        />) :null}
      </Fragment>
    )
  }

  _renderData = () => {
    const { createAdmittance, profile, error } = this.props
    const { formData } = createAdmittance
    const { fieldsError } = error || {}

    return (
      <section className='admittance-common-data'>
        <ExpandingBlock
          renderHeader= {() => 'Реквизиты СКЗИ'}
          initialState={true}
        >
          <Card
            className='admittance-data-card'
            profile={profile}
            formLines={cardLines}
            editOnOpen={true}
            formData={formData}
            onValidationError={this._onValidation}
            errorByFields={fieldsError}
            onChange={this._updateFormData}
            onSubmit={this._onSubmit}
            onCancel={this._onCancel}
          />
          <ExpandingBlock
            className='admittance-common-data__sign-info'
            renderHeader= {() => 'Подписание комиссией'}
            disabled={true}
          >
          </ExpandingBlock>
        </ExpandingBlock>
        <ExpandingBlock
          className='admittance-versions'
          renderHeader= {() => 'Вложения'}
          disabled={true}
        />
      </section>
    )
  }

  render() {
    const { createAdmittance, history } = this.props
    const { inProgress } = createAdmittance

    return (
      <section className='create-admittance'>
        {inProgress ? <Overlay /> : null}
        {this._renderModal()}
        <BreadcrumbsHeader />
        {this._renderData()}
      </section>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default connect(
  mapStateToProps,
  { 
    ...breadcrumbsActions,
    ...createAdmittanceActions,
    ...errorActions,
    ...signatureActions
  })(withAuth(createAdmittance))