import React, { useEffect } from 'react';

import service from 'app/services/service';
import { Table as BaseTable } from 'app/components/list/Table/Table';
import { sortDirection as SortDirection } from 'app/components/ui/constants';
import withEndlessScrollHOC from 'app/components/HOC/EndlessScrollHOC';
import useTableExtensions from 'app/components/list/Table/useTableExtensions';

const Table = withEndlessScrollHOC(BaseTable);

const UserAccountItemsTable = ({
  schema,
  serviceName,
  methodName,
  userId,
  userAccountOrgId,
  changedRows,
  selectedObjects,
  setSelectedObjects,
  onListChanged,
  itemCardCallback,
}) => {
  const {
    sorting,
    selectedItems,
    sortListAction,
    setSelectedAction,
  } = useTableExtensions({
    defaultSort: {
      column: 'id',
      direction: SortDirection.desc,
    },
  });

  const requestParams = {
    filterData: {
      eskziUserId: +userId,
      userAccountOrgId: userAccountOrgId
    },
  };

  const loadData = pagination =>
    service(serviceName, methodName, {
      ...requestParams,
      ...pagination,
      ...sorting,
    });

  const endlessScrollProps = {
    changedRows,
    dependencies: [sorting],
    onListChanged,
    loadData,
  };

  const tableAddonProps = {
    sorting,
    selectedItems,
    selectedObjects,
    sortListAction,
    setSelectedAction,
    setSelectedObjects,
  };

  return (
    <Table
      {...endlessScrollProps}
      {...tableAddonProps}
      fieldsConfig={schema}
      itemsSelectable={true}
      changedFieldsVisibility={false}
      onItemClick={itemCardCallback}
    />
  );
};

export default UserAccountItemsTable;