import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import Overlay from 'app/components/ui/overlay'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import {cardLines} from '../skziCard/skziData'
import Tabs from 'app/components/ui/tabs/tabs'
import Card from 'app/components/ui/card/cardMaterial'

import withAuth from 'app/components/HOC/AuthHOC'
import withRoutingProps from 'app/components/HOC/RoutingPropsHOC'
import { capitalize, serverlikeErrors } from 'app/core/utility/common'
import * as breadcrumbsActions from 'redux/actions/common/breadcrumbsAction'
import * as createSkziActions from 'redux/actions/skziRegistry/createSkziAction'
import * as errorActions from 'redux/actions/common'
import { SKZICREATE } from 'redux/actions/actionTypes'

class createSkzi extends Component {

  async UNSAFE_componentWillMount () {
    const { breadcrumbsClearAction, breadcrumbsPushAction, location } = this.props
    const { pathname } = location
    
    await breadcrumbsClearAction()
    breadcrumbsPushAction(pathname)
  }

  componentWillUnmount () {
    this._onClearForm()
  }

  _onCancel = () => {
    const { history } = this.props
    this._onClearForm()

    history.push('/skziRegistry/accounting')
  }

  _onSubmit = async () => {
    const { createSkzi: { formData }, registerSkzi, createSkziInProgressAction, createSkziResetAction, breadcrumbsPopAction, history } = this.props
    const { producerId, skziTypeId, name, description, shortDescription } = formData
    const { id: prId } = producerId
    const { id: typeId } = skziTypeId
    const requestBody = {
      id: 0,
      name: name,
      description: description,
      shortDescription: shortDescription,
      skziTypeId: typeId,
      producerId: prId
    }

    createSkziResetAction()
    createSkziInProgressAction(true)
    const createResult = await registerSkzi(requestBody)
    const { payload, type } = createResult || {}
    const { id } = payload || {}

    if (type === SKZICREATE) {
      breadcrumbsPopAction()  
      history.push(`/skziRegistry/accounting/skziCard/${id}`)
    }
  }

  _updateFormData = (key, value) => {
    const { createSkziUpdateFormData, setErrorByKey, error: { errorByFields } } = this.props
    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))

    createSkziUpdateFormData && createSkziUpdateFormData({key, value})
    setErrorByKey && setErrorByKey(filteredErrors)
  }

  _onValidation = (errors) => {
    const { setErrorByKey } = this.props

    setErrorByKey && setErrorByKey(serverlikeErrors(errors))
  }

  _onNavigate = () => {
    const { history, createSkziResetAction } = this.props

    createSkziResetAction && createSkziResetAction()
    history.push(`/skziRegistry/accounting`)
  }

  _onClearForm = () => {
    const { skziCreateClearForm } = this.props

    skziCreateClearForm && skziCreateClearForm()
  }

  _renderData = () => {
    const { createSkzi, profile, error } = this.props
    const { formData } = createSkzi
    const { fieldsError } = error || {}

    return (
      <Tabs 
        activeTab={'Информация'} 
        onTabClick={this._onTabClick}>
          <section tabTitle={'Информация'} className='skzi-common-data'>
            <ExpandingBlock
              renderHeader= {() => 'Реквизиты СКЗИ'}
              initialState={true}
            >
              <Card
                className='skzi-data-card'
                profile={profile}
                formLines={cardLines}
                editOnOpen={true}
                formData={formData}
                onValidationError={this._onValidation}
                errorByFields={fieldsError}
                onChange={this._updateFormData}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
              />
            </ExpandingBlock>
            <ExpandingBlock
              className='skzi-versions'
              renderHeader= {() => 'Версии'}
              disabled={true}
            />
          </section>
          <div
            tabTitle={'История'}
            disabled={true}
          />
      </Tabs>
    )
  }

  render() {
    const { createSkzi } = this.props
    const { inProgress } = createSkzi

    return (
      <section className='create-skzi'>
        {inProgress ? <Overlay /> : null}
        <BreadcrumbsHeader />
        {this._renderData()}
      </section>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default connect(
  mapStateToProps,
  { 
    ...breadcrumbsActions,
    ...createSkziActions,
    ...errorActions
  })(withAuth(withRoutingProps(createSkzi, ['history', 'location'])))