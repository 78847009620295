import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'

import Tabs from 'app/components/ui/tabs/tabs'
import { cardLines } from './skziVersionData'
import Card from 'app/components/ui/card/cardMaterial'

import Overlay from 'app/components/ui/overlay'
import ConfirmDialog from 'app/components/dialog/confirmDialog/'
import ExpandingBlock from 'app/components/ui/expandingBlock'
import BreadcrumbsHeader from 'app/components/breadcrumbs/breadcrumbsHeader'
import { ReactComponent as PlusImg } from 'assets/img/commonVer2/plus.svg'
import { ReactComponent as DeleteImg } from 'assets/img/commonVer2/delete.svg'
import { ReactComponent as WindowImg } from 'assets/img/commonVer2/new_window.svg'
import ButtonComponent from 'app/components/ui/button/button'
import service from 'app/services/service'
import { arrayToIdHashMap, capitalize, serverlikeErrors } from 'app/core/utility/common'
import { formatDate } from 'app/core/utility/date'
import TableComponent from 'app/components/ui/obsoleteTable/table';
import Picker, {Services, SelectType} from 'app/components/ui/picker/picker'
import { itemActions, sortDirection } from 'app/components/ui/constants'
import {cardLines as newVersionLines} from './newBuildDialog'

import { getResourceRights } from 'app/core/auth/auth'
import { RESOURCES } from 'app/core/auth/resourceByPage'
import withAuth from 'app/components/HOC/AuthHOC'
import withModal from 'app/components/HOC/ModalHOC'
import ButtonMenu from 'app/components/ui/buttonMenu'
import { buttonTypes } from 'app/components/ui/button/button'

import * as errorAction from 'redux/actions/common'
import * as skziVersionCardAction from 'redux/actions/skziRegistry/skziVersionCardAction'
import withTooltip from 'app/components/HOC/TooltipHOC'
import withEndlessScrollHOC from 'app/components/HOC/EndlessScrollHOC'
import withRoutingProps from 'app/components/HOC/RoutingPropsHOC'

const Button = withTooltip(ButtonComponent)
const ModalCard = withModal(Card)
const ModalPicker = withModal(Picker)
const Table = withEndlessScrollHOC(TableComponent)

export const orgType = [
  {type: 'okz', title : 'ОКЗ', urlPart: 'skziAccounting'},
  {type: 'oki', title : 'Организации', urlPart: 'skziOrganization'}
]

class SkziCard extends PureComponent {
  constructor(props){
    super(props)

    this.state = { 
      tabTitle: 'Информация',
      sort: { 
        column: 'name',
        direction: sortDirection.desc
      },
      sortCourses: { 
        column: 'changeDate',
        direction: sortDirection.desc
      },
      buildColumns: [
        {
          title: 'Наименование',
          alias: 'name',
          settings: {
            useSorting: true,
          }
        },
        {
          title: 'Класс СКЗИ',
          alias: 'class',
          settings: {
            useSorting: true,
          },
          renderTemplate: item => {
            const { class: classData } = item
            const { name = '' } = classData || {}
            
            return name
          }
        },
        {
          title: 'Дата добавления',
          alias: 'createDate',
          settings: {
            useSorting: true,
            width: 300
          },
          renderTemplate: item => {
            const { createDate } = item
            
            return formatDate(createDate, 'dd.mm.yyyy')
          }
        },
        {
          title: 'Экземпляры СКЗИ',
          alias: 'eskzisCount',
          settings: {
            useSorting: true,
            width: 300
          },
          isCustomRender: true,
          renderTemplate: item => {
            const { eskzisCount, id, name } = item

            return (
              <div className="eskzi-navigation">
                <span className='eskzi-version-count'>{eskzisCount}</span>
                <ButtonMenu
                  title={'Экземпляры'}
                  type={buttonTypes.secondary}
                  LeftImage={WindowImg}
                >
                  {orgType.map(item => {
                    return (
                      <div key={item.type} className="report-item" onClick={
                        (e) => {
                        e.stopPropagation() 
                        this._onClick(item.type, {id, name})
                      }}>
                        <span className="report-item__title">{item.title}</span>
                      </div>
                    )
                  })}
                </ButtonMenu>
              </div>
            )
          }
        },
      ],
      courseColumns: [
        {
          title: 'Наименование',
          alias: 'name',
          settings: {
            useSorting: true,
          }
        },
        {
          title: 'Вложения',
          alias: 'materialsCount',
          settings: {
            useSorting: true,
            width: 200
          }
        },
        {
          title: 'Активен',
          alias: 'isActive',
          settings: {
            useSorting: true,
            width: 200
          },
          renderTemplate: item => {
            const { isActive } = item
            
            return isActive ? 'Да' : 'Нет'
          }
        },
        {
          title: 'Дата изменения',
          alias: 'changeDate',
          settings: {
            useSorting: true,
            width: 300
          },
          renderTemplate: item => {
            const { changeDate } = item
            
            return formatDate(changeDate, 'dd.mm.yyyy')
          }
        },
      ]
    }
  }

  _onClick = async (type, build) => {
    const { 
      skziVersionCard,
      skziVersionCardInProgress,
      history,
      params
    } = this.props
    const { skziId, versionId } = params
    const { skziVersionData } = skziVersionCard 
    const { name: versionName } = skziVersionData || {}
    const { id: buildId, name: buildName} = build || {}

    skziVersionCardInProgress(true)
    const skziData = await service('skziService', 'getSkzi', skziId)
    const { data } = skziData
    const { name: skziName, producer } = data || {}
    const { id: producerId, name: producerName } = producer || {}
    skziVersionCardInProgress(false)

    const searchFilter = {
      skziId: {id: skziId, name: skziName},
      skziBuildId: {id: buildId, name: buildName },
      skziVersionId: {id: versionId, name: versionName},
      producerId: {id: producerId, name: producerName}
    }

    const urlPart = orgType.find(item => item.type === type).urlPart
    history.push(`/${urlPart}?filter=${encodeURIComponent(JSON.stringify(searchFilter))}`)
  }   

  componentDidMount() {
    const { skziVersionCard, getSkziVersionAction, skziVersionCardInProgress, params, location } = this.props
    const { pathname } = location
    const { skziId, versionId } = params
    const { inProgress } = skziVersionCard
    const { search } = location
    const searchParams = new URLSearchParams(search);
    const tabName = searchParams.get('tabName')

    if (inProgress) {
      return
    }
   
    if (tabName) {
      this.setState({tabTitle: tabName})
    }

    skziVersionCardInProgress(true)
    getSkziVersionAction({ skziId, versionId, pathname })
  }

  componentWillUnmount () {
    const { 
      skziVersionCardResetAction, 
      clearErrorAction,
      skziVersionCardOnSelect,
      skziVersionCardOnCourseSelect
    } = this.props

    skziVersionCardOnSelect()
    skziVersionCardOnCourseSelect()
    skziVersionCardResetAction()
    clearErrorAction()
  }

  _onSubmit = () => {
    const { skziVersionCard, skziVersionCardUpdateAction, skziVersionCardInProgress, params } = this.props
    const { skziId } = params
    const { commonDataForm, skziVersionData } = skziVersionCard
    const { name, description } = commonDataForm

    const versionData = {
      id: skziVersionData.id,
      name: name,
      description: description,
    }

    skziVersionCardInProgress(true)
    skziVersionCardUpdateAction({skziId, versionData})
  }

  _onCancel = () => {
    const { clearErrorAction, skziVersionCardResetAction } = this.props

    skziVersionCardResetAction()
    clearErrorAction()
  }

  _onTabClick = (title) => {
    this.setState({ tabTitle: title})
  }

  _applyRightsToActions = () => {
    const { profile } = this.props
    const actions = []
    const skziRights = getResourceRights(profile, RESOURCES.skzi)

    skziRights.UPDATE && actions.push(itemActions.edit.key)
    skziRights.DELETE && actions.push(itemActions.delete.key)

    return actions
  }

  _onDelete = () => {
    const { skziCardOnDelete } = this.props

    skziCardOnDelete && skziCardOnDelete()
  }

  _onValidation = (errors) => {
    const { setErrorByKey } = this.props

    setErrorByKey(serverlikeErrors(errors))
  }

  _onFormChange = (key, value) => {
    const { skziVersionCard, skziVersionCardUpdateCommonForm, setErrorByKey, error } = this.props
    const { commonDataForm } = skziVersionCard
    const { errorByFields }  = error || {}

    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))
    setErrorByKey(filteredErrors)
    skziVersionCardUpdateCommonForm({ ...commonDataForm, [key]: value })
  }

  _onSort = (column, direction) => {
    this.setState({ sort: { column, direction }})
  }

  _onCourseSort = (column, direction) => {
    this.setState({ sortCourses: { column, direction }})
  }

  _onDeleteSelectedBuilds = (data) => {
    const { skziVersionCardOnSelect } = this.props

    skziVersionCardOnSelect({
      items: data,
      selectedHashMap: arrayToIdHashMap(data)
    })
  }

  _onSelect = selectedData => {
    const { skziVersionCardOnSelect } = this.props

    skziVersionCardOnSelect(selectedData)
  }

  _onCourseSelect = selectedData => {
    const { skziVersionCardOnCourseSelect } = this.props

    skziVersionCardOnCourseSelect(selectedData)
  }

  _onBuildDeleteConfirm = () => {
    const { skziVersionCardOnBuildDeleteAction, skziVersionCard } = this.props
    const { selectedBuilds } = skziVersionCard
    const { items } = selectedBuilds

    skziVersionCardOnBuildDeleteAction(items)
  }

  _onShowCoursePicker = () => {
    this.setState({ showCoursePicker: true })
  }

  _onCourseDeleteConfirm = () => {
    const { skziVersionCardOnCourseDeleteAction, skziVersionCard } = this.props
    const { selectedCourses } = skziVersionCard
    const { items } = selectedCourses

    skziVersionCardOnCourseDeleteAction(items)
  }

  _onRunBuildDelete = async () => {
    const { 
      errorAction, 
      skziVersionCard, 
      skziVersionCardInProgress, 
      skziVersionCardResetAction 
    } = this.props
    const { selectedBuilds } = skziVersionCard
    const { items } = selectedBuilds

    skziVersionCardInProgress(true)
    const response = await service('versionService', 'deleteBuildRange', items)
    const { data, errors, isError } = response;
    skziVersionCardResetAction()
    errorAction(response)
    this.setState({changedBuilds: {deleted: data}})
  }

  _onRunCourseDelete = async () => {
    const { 
      errorAction,
      skziVersionCard, 
      skziVersionCardResetAction, 
      skziVersionCardInProgress, 
      getSkziVersionAction,
      params,
      location
    } = this.props
    const { selectedCourses } = skziVersionCard
    const { items } = selectedCourses
    const { skziId, versionId } = params
    const { pathname } = location

    skziVersionCardInProgress(true)
    const response = await service('courseService', 'deleteVersionCourseRange', {skziId, versionId, items: items.map(item => item.id)})
    const { data, errors, isError } = response;
    skziVersionCardResetAction()
    errorAction(response)
    getSkziVersionAction({ skziId, versionId, pathname })
    !isError && this.setState({changedCourses: {deleted: data}})
  }
 
  _onBuildCreate = () => {
    const { skziVersionCard, skziVersionCardAddBuildAction, skziVersionCardInProgress, params } = this.props 
    const { createBuildForm } = skziVersionCard
    const { classId, certificateExpirationDate } = createBuildForm
    const { id } = classId || {}
    const { versionId } = params

    skziVersionCardInProgress(true)
    skziVersionCardAddBuildAction({
      ...createBuildForm,
      skziVersionId: versionId,
      certificateExpirationDate: formatDate(certificateExpirationDate, 'yyyy-mm-dd'),
      classId: id || null,
    })
  }

  _onCreateBuildFormChange = (key, value) => {
    const { skziVersionCard, skziVersionCardUpdateBuildForm, setErrorByKey, error: { errorByFields }  } = this.props
    const { createBuildForm } = skziVersionCard
    const filteredErrors = errorByFields.filter(err => err.field !== capitalize(key))

    skziVersionCardUpdateBuildForm({ ...createBuildForm, [key]: value })
    setErrorByKey(filteredErrors)
  }

  _onBuildAdd = () => {
    const {history, params} = this.props
    const { skziId, versionId } = params
    history.push(`/skziRegistry/accounting/skzi/${skziId}/version/${versionId}/build/create`)
  }

  _onCourseAdd = async (items) => {
    const { 
      errorAction,
      skziVersionCardInProgress,
      skziVersionCardResetAction,
      getSkziVersionAction,
      params,
      location
    } = this.props
    const { skziId, versionId } = params
    const { pathname } = location
    this._onHidePicker()
    skziVersionCardInProgress(true)
    const response = await service('courseService', 'addCourseToVersion', {skziId, versionId, items: items.map(item => item.id)})
    const { data, isError } = response;
    skziVersionCardResetAction()
    errorAction(response)
    getSkziVersionAction({ skziId, versionId, pathname })
    !isError && this.setState({changedCourses: {added: data}})
    this._onHidePicker()
  }

  _onHidePicker = () => {
    this.setState({ showCoursePicker: false })
  }

  _onCardNavigation = item => {
    const { history, profile, params } = this.props
    const { skziId, versionId } = params
    const { id: buildId } = item;

    const buildRights = getResourceRights(profile, RESOURCES.build)

    buildRights.VIEW_CARD && history.push(`/skziRegistry/accounting/skzi/${skziId}/version/${versionId}/build/${buildId}`)
  }

  _onCourseCardNavigation = item => {
    const { history, profile } = this.props
    const { id: courseId } = item;
    const courseRights = getResourceRights(profile, RESOURCES.course)

    courseRights.VIEW_CARD && history.push(`/catalog/trainingCourses/card/${courseId}`)
  }

  _onCloseCard = () => {
    const { skziVersionCardResetAction, clearErrorAction } = this.props

    skziVersionCardResetAction()
    clearErrorAction()
  }

  _getTableOptions = () => {
    const { sort } = this.state
    const { column, direction } = sort

    return {
      sortable: {
        defaultColumn: column,
        defaultDirection: direction,
        onChange: (name, direction) => this._onSort(name, direction)
      },
      multipleSelect: {use: true, onCheck: this._onSelect},
      useActions: {use: false},
      onRowAction: this._onCardNavigation
    }
  }

  _onDeleteSelectedCourses = (data) => {
    const { skziVersionCardOnCourseSelect } = this.props

    skziVersionCardOnCourseSelect({
      items: data,
      selectedHashMap: arrayToIdHashMap(data)
    })
  }

  _getCourseTableOptions = () => {
    const { sortCourses } = this.state
    const { column, direction } = sortCourses

    return {
      sortable: {
        defaultColumn: column,
        defaultDirection: direction,
        onChange: (name, direction) => this._onCourseSort(name, direction)
      },
      multipleSelect: {use: true, onCheck: this._onCourseSelect},
      useActions: {use: false},
      onRowAction: this._onCourseCardNavigation
    }
  }

  _getSkziKey = (skziVersionData) => {
    const { name, producerId, skziTypeId, description, shortDescription } = skziVersionData || {}
    const { id: prId } = producerId || {}
    const { id: typeId } = skziTypeId || {}
    
    return  `${name}|${prId}|${typeId}|${description}${shortDescription}`
  }

  _renderData = () => {
    const { skziVersionCard, profile, error, params } = this.props
    const { skziId, versionId } = params
    const { fieldsError } = error
    const { changedBuilds, changedCourses, sort, sortCourses, tabTitle, buildColumns, courseColumns } = this.state
    const { selectedBuilds, selectedCourses, viewData, commonDataForm } = skziVersionCard
    const { items: selectedBuildsItems } = selectedBuilds
    const { items: selectedCourseItems } = selectedCourses
    const buildRights = getResourceRights(profile, RESOURCES.build)
    const versionRights = getResourceRights(profile, RESOURCES.version)

    return (
      <Tabs 
        activeTab={tabTitle} 
        onTabClick={this._onTabClick}>
          <section tabTitle={'Информация'}    className='skzi-version-common-data'>
            <ExpandingBlock
              renderHeader= {() => 'Реквизиты версии'}
              initialState={true}
            >
              <Card
                className='skzi-version-data-card'
                profile={profile}
                formLines={cardLines}
                actions={this._applyRightsToActions()}
                canEdit={versionRights.UPDATE}
                key={this._getSkziKey(viewData)}
                data={viewData}
                formData={commonDataForm}
                onValidationError={this._onValidation}
                errorByFields={fieldsError}
                onChange={this._onFormChange}
                onSubmit={this._onSubmit}
                onCancel={this._onCancel}
                getObjectKeyFunc={this._getSkziKey}
              />
            </ExpandingBlock>
            <ExpandingBlock
              className='skzi-version-buils'
              renderHeader= {() => 'Сборки / модификации'}
              initialState={true}
            >
              <div className="skzi-version-buils manage-panel">
                {buildRights.CREATE ? (
                  <Button 
                    className='create-button'
                    type='primary'
                    onClick={this._onBuildAdd}
                  >
                    <PlusImg className='button-image button-image--left'></PlusImg>
                      <span className='button-title'>Добавить</span>
                  </Button>
                ) : null}
                {buildRights.DELETE ? (
                  <div className="buttons-element">
                    <Button 
                      tooltip={'Удалить'}
                      className='delete-button'
                      type='image'
                      onClick={this._onBuildDeleteConfirm}
                      disabled={!selectedBuildsItems.length}
                    >
                      <DeleteImg className='button-image'></DeleteImg>
                    </Button>
                  </div>
                ) : null}
              </div>
              {skziId && versionId && <Table
                loadData = {({page, pageSize}) => service('versionService', 'getBuilds', {skziId, versionId, page, pageSize, ...sort})}
                dependencies = {[sort]}
                columns={buildColumns}
                selected={selectedBuilds}
                changedRows = {changedBuilds}
                selectedObjects={selectedBuildsItems}
                setSelectedObjects={this._onDeleteSelectedBuilds}
                noBorders={true}
                options={this._getTableOptions()}
              />}
            </ExpandingBlock>
          </section>
          <section tabTitle={'Обучающие курсы'} className='skzi-version-common-data'>
            <ExpandingBlock
              className='skzi-version-buils'
              renderHeader= {() => 'Обучающие курсы'}
              initialState={true}
            >
              <div className="skzi-version-buils manage-panel">
                {versionRights.UPDATE ? (
                  <Button 
                    className='create-button'
                    type='primary'
                    onClick={this._onShowCoursePicker}
                  >
                    <PlusImg className='button-image button-image--left'></PlusImg>
                    <span className='button-title'>Добавить</span>
                  </Button>
                ) : null}
                {versionRights.UPDATE ? (
                  <div className="buttons-element">
                    <Button 
                      tooltip={'Удалить'}
                      className='delete-button'
                      type='image'
                      onClick={this._onCourseDeleteConfirm}
                      disabled={!selectedCourseItems.length}
                    >
                      <DeleteImg className='button-image'></DeleteImg>
                    </Button>
                  </div>
                ) : null}
              </div>
              {skziId && versionId && <Table
                loadData = {({page, pageSize}) => service('courseService', 'getVersionCourses', {skziId, versionId, page, pageSize, ...sortCourses})}
                dependencies = {[sortCourses]}
                columns={courseColumns}
                selected={selectedCourses}
                changedRows = {changedCourses}
                selectedObjects={selectedCourseItems}
                setSelectedObjects={this._onDeleteSelectedCourses}
                noBorders={true}
                options={this._getCourseTableOptions()}
              />}
            </ExpandingBlock>
          </section>
      </Tabs>
    )
  }

  _getVersionBuildKey = (data) => {
    const { name, description, classId, certificateExpirationDate, certificate } = data
    const { id } = classId || {}

    return `${name}${description}${id}${certificate}${formatDate(certificateExpirationDate, 'yyyy-mm-dd')}`
  }

  _renderModal = () => {
    const { skziVersionCard, skziVersionCardResetAction, error } = this.props
    const { showCoursePicker } = this.state
    const { skziVersionData, addBuildDialog, confirmObject, deleteBuildConfirm, createBuildForm, emptyCreateBuildForm, deleteCourseConfirm } = skziVersionCard
    const { courses } = skziVersionData
    const { fieldsError } = error || {}

    return (
      <Fragment>
        {showCoursePicker ? 
          <ModalPicker
            serviceType={Services.trainingCourses}
            excludeItems={courses}
            selectType={SelectType.multiple}
            onCancel={this._onHidePicker} 
            onSelect={this._onCourseAdd}
            renderHeader = {() => {
              return (
                <div className='modal__header'>
                  {'Добавление обучающего курса'}
                </div>
              )
            }}
          /> : null}
        {addBuildDialog ?
          <ModalCard
            className='create-build-modal-card modal-card'
            formLines={newVersionLines}
            data={emptyCreateBuildForm}
            formData={createBuildForm}
            errorByFields={fieldsError}
            onValidationError={this._onValidation}
            onChange={this._onCreateBuildFormChange}
            onSubmit={this._onBuildCreate}
            renderHeader = {() => {
              return (
                <div className='modal__header'>
                  Добавление сборки/модификации
                </div>
              )
            }}
            onCancel={this._onCloseCard}
            editOnly={true}
            getObjectKeyFunc={this._getVersionBuildKey}
          /> : null
        }
        {deleteBuildConfirm ? 
          <ConfirmDialog
            {...deleteBuildConfirm}
            onSubmit={this._onRunBuildDelete}
            onCancel={skziVersionCardResetAction}
          /> : null
        }
        {deleteCourseConfirm ? 
          <ConfirmDialog
            {...deleteCourseConfirm}
            onSubmit={this._onRunCourseDelete}
            onCancel={skziVersionCardResetAction}
          /> : null
        }
        {confirmObject ? 
          <ConfirmDialog
            {...confirmObject}
            onCancel={skziVersionCardResetAction}
          /> : null
        }
      </Fragment>
    )
  }

  render() {
    const { skziVersionCard, history } = this.props
    const { inProgress } = skziVersionCard

    return (
      <section className='skzi-version-card'>
        {inProgress ? <Overlay /> : null}
        {this._renderModal()}
        <BreadcrumbsHeader />
        {this._renderData()}
      </section>
    )
  }
}

const mapStateToProps = state => ({ ...state })

export default connect(
  mapStateToProps,
  { 
    ...errorAction,
    ...skziVersionCardAction
  })(withAuth(withRoutingProps(SkziCard)))