import React, {useRef} from 'react'
import { CardState } from 'app/components/ui/card/cardMaterial'
import Autocomplete, { Services }  from 'app/components/ui/autocomplete/autocomplete'
import DatePicker from 'app/components/ui/date'
import Input from 'app/components/ui/Input'
import Button from 'app/components/ui/button/button'
import {ReactComponent as DeleteImage} from 'assets/img/commonVer2/delete.svg'
import { ReactComponent as PlusImage }  from 'assets/img/commonVer2/plus.svg'
import {SelectType} from '../../ui/picker/picker'
import useRolesForm from '../hooks/useRolesForm'

export default function RolesForm(props){

  const {
    cardState,
    formData = {},
    serverFormViewModel,
    onChange,
    errorByFields = {},
    onAdd,
    onRemove,
  } = props

  const {
    getFormDataValue,
    getFieldsetError
  } = useRolesForm({formData, serverFormViewModel, onAdd, errorByFields})

  const endDateRef = useRef()

  const fields = Array.isArray(getFormDataValue('roles')) ? getFormDataValue('roles').map((el, index) => {

    return(
      <div className="card-role" key={index}>
        <div className="card-role-name card-role-separator">
          <Autocomplete
            onEmptySearch = {true}
            label="Роль в системе"
            serviceType={Services.userRoles}
            selectType={SelectType.single}
            value={el.role}
            readOnly={cardState === CardState.view}
            absoluteInfo
            onSelect={value => {
              onChange({
                key: 'roles',
                field: 'role'
              }, value, index)
            }}
            error={getFieldsetError('roles','role', index) || getFieldsetError('roles','roleId', index)}
          />
        </div>
        <DatePicker
          label="Дата начала действия"
          className="card-role-date-from"
          error={getFieldsetError('roles','dateFrom', index)}
          value={el.dateFrom}
          absoluteInfo
          onChange= {value => onChange({
            key: 'roles',
            field: 'dateFrom'
          }, value, index)}
          readOnly={cardState === CardState.view}
        />
        {
          cardState === CardState.view && !!!el.dateTill ?
            (
              <Input
                className="card-role-date-till"
                label="Дата окончания действия"
                value="Бессрочно"
                readOnly={true}
                absoluteInfo
                hint="Оставьте поле пустым, если роль выдается бессрочно"
              />
            ) :
            (
              <DatePicker
                ref={endDateRef}
                label="Дата окончания действия"
                className="card-role-date-till"
                error={getFieldsetError('roles', 'dateTill', index)}
                value={el.dateTill}
                absoluteInfo
                onChange={value => onChange({
                  key: 'roles',
                  field: 'dateTill'
                }, value, index)}
                readOnly={cardState === CardState.view}
                placeholder="Бессрочно"
                hint="Оставьте поле пустым, если роль выдается бессрочно"
              />
            )
        }
        <Button
          className="card-management__item card-remove-role-button"
          type="image"
          notSubmit={true}
          onClick={() => onRemove('roles', index)}
          disabled={(cardState === CardState.view)}
        >
          <DeleteImage />
        </Button>
      </div>
    )
  }) : ''
  return (
    <div className="card-roles-list">
      {fields}
      <Button
        notSubmit={true}
        className="card-management__item card-add-role-button"
        type="secondary"
        onClick={() => onAdd('roles', {role: '', dateFrom: '', dateTill: ''})}
        disabled={cardState === CardState.view}
      >
        <PlusImage className="button-image" />
        <span className="button-title">Добавить</span>
      </Button>
    </div>
  )
}