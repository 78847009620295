import { sortDirection } from 'app/components/ui/constants'

export const defaultSettings = {
  columns: [
    {
      title: 'Наименование',
      alias: 'name',
      settings: {
        useSorting: false,
      },
    }
  ],
  sort: { 
    column: 'name',
    direction: sortDirection.asc
  }
} 