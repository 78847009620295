import { FilterControlType } from 'app/components/filter/FilterEllement';

export default [
    {
      type : FilterControlType.simpleSearch, 
      alias : 'names', 
      label : 'Поиск по наименованию',
      extended : false,
      requestFormatIsArray: true,
      order : 2
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'producers', 
      label : 'Производитель', 
      serviceType : 'producers',  
      multiSelect : false,
      extended : false,
      requestFormatIsArray: true,
      order : 3,
    },
    {
      type : FilterControlType.autocomplete, 
      alias : 'types', 
      label : 'Тип', 
      serviceType : 'skziTypes',  
      multiSelect : false,
      extended : false,
      requestFormatIsArray: true,
      order : 4,
    },
  ];