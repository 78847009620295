import React, { Component } from 'react'
import { compose } from 'redux'

import withToggle from 'app/components/HOC/ToggleHOC'
import withDocumentClick from 'app/components/HOC/DocumentClickHOC'

import PopupPanel from 'app/components/ui/popupPanelList'
import ActionButtonImage from "../list/TopPanel/ActionButtonImage";
import withTooltip from "../HOC/TooltipHOC";
const ButtonWithTooltip = withTooltip( ActionButtonImage );


class ButtonMenu extends Component {

  render() {
    const {
      className,
      title,
      toggle,
      toggleStatus,
      type,
      img,
      needChevron = false,
      closeOnMenuClick = true,
      disabled = false
    } = this.props

    const childrenWithProps = React.Children.map(this.props.children, child =>
      React.cloneElement(child, { toggle: toggle, toggleStatus: toggleStatus })
    );

    return (
      <div className={`${className || ''} button-menu`}>
        <ButtonWithTooltip needChevron={needChevron} img = { img } action = { toggle } disabled = { disabled  }  tooltip = { title } />
        {toggleStatus ? (
          <PopupPanel
            {...this.props}
            items={childrenWithProps}
            closeOnMenuClick={closeOnMenuClick}
          ></PopupPanel>
        ) : null}
      </div>
    )
  }
}
const ButtonMenuImage = compose(withToggle, withDocumentClick)(ButtonMenu)

export default ButtonMenuImage