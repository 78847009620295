import React, { useEffect, useState, useRef, useMemo } from 'react';
import { compose } from 'redux'
import Input from 'app/components/ui/Input'
import {Table as TableComponent} from 'app/components/list/Table/Table';

import withModal from 'app/components/HOC/ModalHOC';
import withSignature from "app/components/HOC/withSignatureHOC";
import SaveButtons from 'app/components/ui/card/FunctionalCard/SaveButtons';
import SimpleSearch from 'app/components/ui/search/SimpleSearch';
import service from 'app/services/service';
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import { sortDirection } from 'app/components/ui/constants';
import useToast from "app/components/ui/toast/useToast";
import withEndlessScrollHOC from 'app/components/HOC/EndlessScrollHOC'
import { formatSKZI } from 'app/components/destructionCertificate/destructionObjectSet';
import Overlay from 'app/components/ui/overlay';
import { useReplicateEtalon } from './useReplicateEtalon';
import LicensePicker from '../licensePicker';
import { countLicenseOKZ } from '../replicationButton';

const Table = withEndlessScrollHOC(TableComponent)

const ReplicateEtalonForm = ({
  initialState,
  disabled,
  onCancel,
  onSubmitSignEnhance,
  getSignRequestData,
  generateTextErrors,
  validLicensesCount,
}) => {

  const { id } = initialState || {}

  const {
    sortListAction,
    inProgress,
    sorting,
    selectedItems,
    setSelectedAction,
    selectedObjects,
    setSelectedObjects,
  } = useTableExtensions({
    defaultSort: {
      column: 'name',
      direction: sortDirection.asc,
    },
  });

  const {addToast} = useToast()
  const [search, setSearch] = useState('')
  const [initSearchValue, setInitSearchValue] = useState('')
  const [selectedLicenses, setSelectedLicenses] = useState({})
  const [selectedPickerLicenses, setSelectedPickerLicenses] = useState([])
  const [total, setTotal] = useState({})
  const [totalCount, setTotalCount] = useState(0)

  const onSearch = () => {
    setInitSearchValue(search)
  }

  const onPickerSelect = (data) => {
    const updatedSelected = {...selectedLicenses, [data.organizationId]: data.items}
    setSelectedLicenses(updatedSelected)
    setSelectedPickerLicenses([
      ...selectedPickerLicenses,
      ...data.items
    ])
  }

  useEffect(() => {
    const total = selectedObjects.map(i => {
      return {
        organizationId: i.id,
        licenses: selectedLicenses[i.id]
      }
    })
    setTotal(total)
    const count = total.reduce((acc, cur) => {
      acc = acc + countLicenseOKZ(cur.licenses)     
      return acc
    }, 0)
    setTotalCount(count)
  }, [selectedObjects, selectedLicenses])

  useEffect(() => {
    const error = generateTextErrors && generateTextErrors[0]
    if (error) {
      addToast(error.errorMessage)
    }
  }, [generateTextErrors])

  const fieldsConfig = useMemo(() => {
    return [
      { 
        title : 'Наименование организации',
        alias : 'name', 
        format : item => {
          const { shortName, name, inn, ogrn } = item
          return `${shortName || name} (ИНН ${inn}; ОГРН/ОГРНИП ${ogrn})`
        },
        visibleOnStart : true, 
        visibleAlways : true,
        userSorting : true, 
         
      },
      {
        title : 'Лицензий для передачи',
        alias : 'licenseToMove', 
        format : item => {
          const excluded = Object.keys(selectedLicenses).reduce((acc, cur) => {
            if (item.id !== +cur) {
              return [...acc, ...selectedLicenses[cur]]
            }
            return acc
          }, [])

          return (
            <LicensePicker
              eskziId = {id}
              organizationId = {item.id}
              onSelect = {onPickerSelect}
              alreadySelected = {selectedLicenses}
              excludeItems={excluded}
            />
          )
        },
        visibleOnStart : true, 
        visibleAlways : true,
        userSorting : false, 
        width : 300 
      },
    ]
  }, [selectedLicenses])

  return <div className="etalon-replicate-form">
    <div className="card-material__content">
      <Input
        id="etalonId"
        className="card-field card-field--etalonId"
        label="Эталон для распространения"
        disabled={true}
        value={formatSKZI(initialState)}
      />
      <Input
        id="availableCount"
        className="card-field card-field--availableCount"
        label="Доступно лицензий"
        disabled={true}
        value={validLicensesCount || '0'}
      />
      <SimpleSearch
        id="orgSearch"
        className='card-field card-field--orgSearch'
        placeholder='Организация'
        value={search}
        onChange={setSearch}
        onSearch={onSearch}
      />
    </div>
    <Table
      loadData = {({page, pageSize}) => service('organizationService', 'getOkz', {eskziId: id, term: initSearchValue, page, pageSize, ...sorting})}
      sortListAction = {sortListAction}
      sorting = {sorting} 
      selectedItems = {selectedItems} 
      selectedObjects = {selectedObjects}
      setSelectedAction = {setSelectedAction}
      setSelectedObjects = {setSelectedObjects}
      dependencies = {[sorting, initSearchValue]}
      inProgress = {inProgress}
      fieldsConfig = {fieldsConfig}
      itemsSelectable = { true }
    />
    <div className="total-info">
      <div className="total-info__item">Выбрано организаций: {selectedObjects.length || 0}</div>  
      <div className="total-info__item">Лицензий для передачи: {totalCount}</div>
    </div>
    <div className="card-material__edit-card-management">
      <SaveButtons
        onSubmit={() => onSubmitSignEnhance(getSignRequestData(total))}
        onCancelForm={onCancel}
        submitTitle='Распространить'
        submitDisabled={!selectedObjects.length}
      />
    </div>
  </div>
}

const ReplicateEtalonComponent = compose(withSignature, withModal)(ReplicateEtalonForm)

const ReplicateEtalon = (props) => {
  const { 
    afterAction, 
    onCancel, 
    initialState,
  } = props
  const { id } = initialState || {}
  const {
    getSignRequestData,
    replicateEtalonCallback,
    inProgress,
    validLicensesCount
  } = useReplicateEtalon({
    onCancel,
    eskziId: id
  })

  return <>
    {inProgress ? <Overlay /> : null}
    <ReplicateEtalonComponent
    {...props}
    validLicensesCount={validLicensesCount}
    className='replicate-etalon-component'
    actionCallback={replicateEtalonCallback}
    getSignRequestData={getSignRequestData}
    afterAction={afterAction}
    signType='Replication'
    signHeader='Распространить эталон'
    renderHeader = {() => {
      return (
        <div className='modal__header'>
          {'Распространение эталона'}
          <div className='replicate-exemplar-info'>При создании и передаче копий эталона в ОКЗ Вы можете передать лицензии (в рамках доступного объема).</div>
        </div>
      )
    }}
  />
  </>
}

export default ReplicateEtalon;