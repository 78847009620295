import React from "react";
import withModal from "../../../HOC/ModalHOC";
import FunctionalCard from "../../../ui/card/FunctionalCard";
import useFunctionalCard, {CardState} from "../../../ui/card/FunctionalCard/useFunctionalCard";
import Button from 'app/components/ui/button/button'
import {notEmpty} from "../../../../core/utility/validation";
import EditForm from "./EditForm";
import AddFileDialog from "../addFilesDialog";
import {useParams} from "react-router-dom";


const ModalFileDialog = withModal(AddFileDialog)
export default function EditFileModalMain({item, onCancel, onFileUploaded, certificationsTypes}){

  const { type } = item || {}
  const { id = 0, name = '' } = type || {}

  const get = () => Promise.resolve({
    data: {
      type: {
        id: id,
        title: name,
        value: id
      },
      number: item.number,
      certificateStartDate: item.certificateStartDate,
      certificateExpirationDate: item.certificateExpirationDate,
      id: item.id
    }})
  const { skziId, versionId, buildId } = useParams()
  const formData = {validationRules: certificateValidationRules, defaultState: defaultCertificateFormState}
  const urlPart = `skzi/${skziId}/version/${versionId}/build/${buildId}/certificates/certificate/${item.id}/file/upload`
  const {
    cardState,
    fieldsError,
    onFormChange,
    commonDataForm
  } = useFunctionalCard({formData, get, parentEditState: true})

  return (
    <ModalFileDialog
      className="skzi-build__certificate-card"
      onCancel={onCancel}
      urlPart={urlPart}
      onFileUploaded={(data) => onFileUploaded({item, data})}
    >
      <div className="card-material__content">
        <EditForm
          certificationsTypes={certificationsTypes}
          cardState={cardState}
          formData={commonDataForm}
          serverFormViewModel={null}
          onChange={onFormChange}
          errorByFields={fieldsError}
          disabled={true}
        />
      </div>
    </ModalFileDialog>
  )
}



export const certificateValidationRules = {
    type: {
      isRequired: () => true,
      validation: value => {
        return notEmpty(value)
      },
    },
    number: {
      isRequired: () => true,
      validation: value => {
        return notEmpty(value)
      },
    },
  certificateStartDate: {
      isRequired: () => true,
      validation: value => {
        return notEmpty(value)
      },
    },
}

export const defaultCertificateFormState = {
  commonDataForm : {
    type: {},
    number: '',
    certificateStartDate: null,
    certificateExpirationDate: null,
  },
  viewData: {
    type: {},
    number: '',
    certificateStartDate: null,
    certificateExpirationDate: null,
  }
}