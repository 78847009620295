import React from 'react'

const popupPanelList = props => {
  const { items, expandDirection, toggle, closeOnMenuClick } = props

  return (
    <ul className={`drop-down__list list--${expandDirection || 'down'} `}>
      {items.map((el, i) => {
        const { props } = el
        const { onClick, children, disabled } = props

        return (
          <li
            key={i} 
            className={`list-item ${disabled ? 'list-item--disabled' : '' }`}
            onClick={(e) => { 
              onClick && onClick(e)
              closeOnMenuClick && toggle() 
            }}
          >{children}</li>
        )
      })}
    </ul>
  )
}


export default popupPanelList