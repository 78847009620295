import React, { useEffect, useMemo, useState } from 'react'
import { Table } from 'app/components/list/Table/Table'
import useTableExtensions from 'app/components/list/Table/useTableExtensions';
import { formatDate } from 'app/core/utility/date'
import LinkKeyDocButton from './linkKeyDocButton'
import BreakLinkButton from './breakLinkButton'
import withSignature from 'app/components/HOC/withSignatureHOC'
import { sortDirection } from 'app/components/ui/constants'
import Overlay from 'app/components/ui/overlay'
import service from 'app/services/service'
import ManagePanel from 'app/components/ui/managePanel/managePanel'
import Group from 'app/components/ui/managePanel/group'
import { DownloadButton } from 'app/components/list/TopPanel/DownloadButton';
import withEndlessScrollHOC from 'app/components/HOC/EndlessScrollHOC'
import ExpandingBlock from 'app/components/ui/expandingBlock'

import styles from './licenses.module.scss';

const LinkButton = withSignature(LinkKeyDocButton) 
const BreakLink = withSignature(BreakLinkButton)
const TableComponent = withEndlessScrollHOC(Table)

const fieldsConfig = [
  { 
    title : '№',
    alias : 'id',
    format : item => item.id,
    visibleOnStart : true, 
    userSorting : true, 
    width : 100
  },
  { 
    title : 'Лицензионный ключ',
    alias : 'key',
    format : item => {
      const { key = '' } = item

      return `** ${key.slice(-4)}`
    },
    visibleOnStart : true, 
    userSorting : true, 
  },      
  { 
    title : 'Срок действия',
    alias : 'expirationDate',
    format : item => item.expirationDate ? formatDate( item.expirationDate, 'dd.mm.yyyy' ) : 'Бессрочно', 
    visibleOnStart : true, 
    userSorting : true, 
    width : 240 
  },       
  { 
    title : 'Действует',
    alias : 'activeState',
    format : item => {
      const { activeState } = item
      const { name = ''} = activeState || {}
      
      return name
    },
    visibleOnStart : true, 
    userSorting : true, 
    width : 240
  },      
  { 
    title : 'Примечание',
    alias : 'comment',
    visibleOnStart : true, 
    userSorting : true, 
  }, 
  { 
    title : 'Дата изменения',
    alias : 'updateDate',
    format : item => formatDate(item.createDate, 'dd.mm.yyyy'),
    visibleOnStart : true, 
    userSorting : true, 
    width : 240 
  },      
]

export default function Licenses(props) {
  const { 
    licenseRights,
    itemId,
    isExternalUser,
    bizLocked
  } = props

  const {
    inProgress,
    sorting,
    selectedItems,
    selectedObjects,

    setSelectedAction,
    setSelectedObjects,
    sortListAction,
    onInProgress,
  } = useTableExtensions({
    defaultSort: {
      column: 'createDate',
      direction: sortDirection.desc,
    },
  });

  const [changed, setChanged] = useState(null)
  const linkAction = async (requestData) => {
    onInProgress(true)
    
    const response = await service('licenseService', 'addEskziLicense', requestData)

    const { data, isWarn, isError } = response || {}
    if (!isWarn && !isError) {
      setChanged({added: data.map(i => ({...i.skziLicense}))})
    }

    onInProgress(false)
    return response
  }  

  const breakLinkAction = async (requestData) => {
    const { data } = requestData 
    const { skziLicenses } = data || {}

    onInProgress(true)
    const response = await service('licenseService', 'deleteEskziLicense', requestData)
    const { isWarn, isError } = response || {}

    if (!isWarn && !isError) {
      setChanged({deleted: skziLicenses})
      setSelectedAction([])
      setSelectedObjects([])
    } else {

    }

    onInProgress(false)
    return response
  }

  const dataService = isExternalUser 
                        ? ({page, pageSize}) => service('licenseService', 'getExtOrgEskziLicense', {itemId, ...sorting, page, pageSize}) 
                        : ({page, pageSize}) => service('licenseService', 'getEskziLicense', {itemId, ...sorting, page, pageSize})

  const listFieldsVisibility = fieldsConfig.map(i => {
    return {
      [i.alias] : i.title
    }
  })

  const licenseNavigateUrl = isExternalUser 
                              ? '/extorglicense/card/'
                              : '/license/card/'

  return (
    <>
      {inProgress ? <Overlay /> : null}
      <ExpandingBlock
        className={styles.eskziLicenses}
        renderHeader= {() => 'Связанные лицензии'}
        initialState={true}
      >
        <ManagePanel>
            {licenseRights.CREATE ? ( 
              <Group>
                <LinkButton
                  isText
                  eskziId={itemId}
                  disabled={bizLocked}
                  actionCallback={linkAction}
                  signType='SkziLicense'
                  signHeader='Добавление лицензионного ключа'
                />
              </Group>
            ) : null}
            {licenseRights.DELETE ? ( 
              <Group>
                <BreakLink
                  eskziId={itemId}
                  actionCallback={breakLinkAction}
                  disabled={selectedObjects.length === 0 || bizLocked}
                  signType='SkziLicense'
                  signHeader='Удаление лицензионного ключа'
                  selectedLicenses={selectedObjects}
                />
              </Group>
            ): null}
            <Group>
              <DownloadButton
                id = {itemId} 
                listFieldsVisibility={listFieldsVisibility}
                sorting = {sorting}
                selected = {selectedItems}
                titul="Выгрузить в Excel"
                serviceName ={isExternalUser 
                  ? 'eskziExtOrgLicensesService' 
                  : 'eskziLicensesService'}
              />
            </Group>
          </ManagePanel>
          <TableComponent
            loadData = {dataService}
            mapResultFunc = {i => ({
              ...i,
              ...i.skziLicense
            })}
            dependencies = {[sorting]}
            changedRows = {changed}
            sorting = {sorting}
            selectedItems = {selectedItems} 
            selectedObjects = {selectedObjects}
            setSelectedAction = {setSelectedAction}
            setSelectedObjects = {setSelectedObjects}
            sortListAction = {sortListAction}
            onItemClick = { item => { return `${licenseNavigateUrl}${ item.id }` } }
            fieldsConfig = { fieldsConfig }
            itemsSelectable = { true }
          />
      </ExpandingBlock>
    </>
  )
}